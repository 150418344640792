import { combineReducers } from 'redux';

// Import reducers
import table from './cognito_providers_panel_reducer';
import form from './cognito_providers_form_reducer';

export default combineReducers({
	table,
	form
});
