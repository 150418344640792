import React from 'react';

// Import helpers
import { renderFields } from 'components/utilities/form/renderer/renderFields';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = () => (
	<>{BASE_FIELDS.map((item, index) => renderFields({ item, index }))}</>
);

export default Fields;
