import React, { useContext } from 'react';
import { sortableContainer } from 'react-sortable-hoc';

// Import context
import { ProductsContext } from '../ProductsProvider';

// Import components
import SortableItem from './SortableItem';

const SortableListSection = () => {
	const { selectedData, handleSortEnd, handleRemoveItem } = useContext(
		ProductsContext
	);

	const sortStart = () => {
		const sortContainer = document.querySelectorAll('.sortable-sections');
		sortContainer.forEach((item) => {
			item.classList.add('drag');
		});
	};

	const SortableContainer = sortableContainer(({ handleRemoveItem }) => (
		<ul className="sortable-sections">
			{selectedData.map(({ id, name, label }, index) => (
				<SortableItem
					key={id}
					index={index}
					value={{ name, label }}
					handleRemoveItem={handleRemoveItem}
					id={id}
				/>
			))}
		</ul>
	));

	return (
		<>
			{selectedData.length ? (
				<SortableContainer
					onSortEnd={handleSortEnd}
					onSortStart={sortStart}
					lockAxis="y"
					distance={3}
					helperClass="sortable-sections__li drag"
					handleRemoveItem={handleRemoveItem}
				/>
			) : null}
		</>
	);
};
export default SortableListSection;
