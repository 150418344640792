import React from 'react';
import { bool, func, string } from 'prop-types';
import { Modal, Table, Button } from 'antd';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './info_columns';

// Import components
import { FetchError } from 'components/common/layout';
import Loader from 'components/common/loaders/Loader';

// Import custom hook
import useModalInfo from './useModalInfo';

const ModalInfo = ({ visible, toggle, title, videoSessionId }) => {
	const { t } = useTranslation();

	const { loading, data, fetchError } = useModalInfo(visible, videoSessionId);

	const handleRowKey = (records) => data.findIndex((item) => item === records);
	return (
		<Modal
			title={title}
			open={visible}
			onOk={toggle}
			onCancel={toggle}
			footer={[
				<Button key="ok" type="primary" onClick={toggle}>
					{t('common:buttons.ok')}
				</Button>
			]}
			destroyOnClose={true}
			width={768}
		>
			{fetchError && <FetchError />}
			<Loader isLoaded={!loading}>
				<Table
					rowKey={handleRowKey}
					columns={createColumns}
					bordered
					dataSource={data}
					pagination={false}
					size="small"
				/>
			</Loader>
		</Modal>
	);
};

ModalInfo.propTypes = {
	visible: bool.isRequired,
	toggle: func.isRequired,
	title: string.isRequired,
	videoSessionId: string.isRequired
};

export default ModalInfo;
