import i18n from 'i18n';

export const BASE_FIELDS = ({ isEdit, drmPlaceholder }) => [
	{
		name: 'format',
		type: 'select',
		label: i18n.t('common:materials_table.form.format_label'),
		required: true,
		disabled: isEdit,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'platform',
		type: 'select',
		label: i18n.t('common:materials_table.form.platform_label'),
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		allowNull: true
	},
	{
		name: 'asset_url',
		type: 'text',
		label: i18n.t('common:materials_table.form.asset_url_label'),
		placeholder: i18n.t('common:materials_table.form.asset_url_placeholder'),
		required: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'drm_key',
		type: 'text',
		label: i18n.t('common:materials_table.form.drm_key_label'),
		placeholder: drmPlaceholder,
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'iv',
		type: 'textarea',
		label: i18n.t('common:materials_table.form.iv_label'),
		placeholder: i18n.t('common:materials_table.form.iv_label_placeholder'),
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'drm_params_template_key',
		type: 'custom-names',
		mode: '',
		label: i18n.t('common:materials_table.form.drm_params_template_key'),
		placeholder: i18n.t('common:materials_table.form.drm_params_template_key'),
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		isVisible: isEdit
	},
	{
		name: 'local_limited',
		type: 'checkbox',
		label: i18n.t('common:materials_table.form.local_limited'),
		placeholder: i18n.t('common:materials_table.form.local_limited'),
		required: false,
		labelColumn: 3,
		inputColumn: 9
	}
];
