import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import services from 'services/services';

// Components
import MassChangesElementsList from 'components/utilities/mass_changes/elements_list';
import PermanentDeleteButtons from './PermanentDeleteButtons';
import {
	Wraper,
	Ibox,
	IboxTitle,
	IboxContentBase
} from 'components/common/layout';

// Helpers
import {
	getProductsUuids,
	removeItemFromSelected
} from 'components/views/mass_changes/mass_changes_availability/helpers';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

const MassChangesPermanentDelete = () => {
	const { t } = useTranslation();
	const { state } = useLocation();
	const history = useHistory();

	// component state
	const [products, setProducts] = useState(state?.selection);
	const [isSubmitting, setSubmitting] = useState(false);

	const hasSelections = products?.length > 0;

	const handleRemoveItemFromState = (id) =>
		removeItemFromSelected({ id, products, setProducts });

	const handlePermanentDelete = async () => {
		try {
			setSubmitting(true);
			// get array of selected products uuids
			const vods_uuids = getProductsUuids(products);

			await services.delete(`vod/massDelete`, { data: { vods_uuids } });

			handleOpenNotification({
				message: t('messages:notifications.deleted'),
				description: t('mass_changes:permanent_delete.success_message'),
				type: 'success'
			});

			// redirect after success
			handleRedirect();
		} catch (error) {
			setSubmitting(false);

			handleOpenNotification({
				message: t('messages:notifications.error'),
				description: t('mass_changes:permanent_delete.error_message'),
				type: 'error'
			});
		}
	};

	const handleOpenNotification = ({ message, description, type }) =>
		notificationHandler(message, description, type, 3);

	const handleRedirect = () => history.push('/panel/vod');

	const isButtonDisabled = isSubmitting || !hasSelections;

	return (
		<>
			<Wraper error={false}>
				<Ibox error={false}>
					<IboxTitle title={t('mass_changes:tabs.permanent_delete')} />
					<IboxContentBase isLoaded={true}>
						{hasSelections && (
							<MassChangesElementsList
								selection={products}
								handleRemoveItem={handleRemoveItemFromState}
								productsTitle={t('mass_changes:permanent_delete.productsTitle')}
								showSeparator={false}
							/>
						)}
						<PermanentDeleteButtons
							isButtonDisabled={isButtonDisabled}
							onDelete={handlePermanentDelete}
							onCancel={handleRedirect}
						/>
					</IboxContentBase>
				</Ibox>
			</Wraper>
		</>
	);
};

export default MassChangesPermanentDelete;
