import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import utilities
import { Toggle } from 'components/utilities';

// Import components
import ModalInfo from './trackings_info/ModalInfo';

// Import helpers
import { setFormLink } from 'components/helpers/link_selector';

export const createColumns = () => [
	{
		Header: i18n.t('trackings:table_columns.timestamp'),
		accessor: 'timestamp',
		sortable: false,
		width: 150
	},
	{
		Header: i18n.t('trackings:table_columns.duration'),
		accessor: 'duration',
		sortable: false,
		width: 150,
		Cell: ({
			row: {
				_original: { duration, sessionId }
			}
		}) => (
			<Toggle>
				{({ on, toggle }) => (
					<>
						<span
							onClick={toggle}
							style={{ color: '#1890ff', cursor: 'pointer' }}
						>
							{duration}
						</span>
						<ModalInfo
							videoSessionId={sessionId}
							title={i18n.t('trackings:modal_info.title')}
							visible={on}
							toggle={toggle}
						/>
					</>
				)}
			</Toggle>
		)
	},
	{
		Header: i18n.t('trackings:table_columns.productId'),
		accessor: 'productId',
		sortable: false,
		className: 'scroll-cell',
		Cell: ({
			row: {
				_original: { productId, productType, title }
			}
		}) => setFormLink({ uuid: productId, type: productType, title })
	},
	{
		Header: i18n.t('trackings:table_columns.provider'),
		accessor: 'provider',
		sortable: false,
		width: 150
	},
	{
		Header: i18n.t('trackings:table_columns.subscriberLogin'),
		accessor: 'subscriberLogin',
		sortable: false,
		className: 'scroll-cell',
		Cell: ({
			row: {
				_original: { subscriberId, subscriberLogin }
			}
		}) => (
			<Link to={`/panel/subscribers/edit/${subscriberId}/metadata`}>
				{subscriberLogin}
			</Link>
		)
	},
	{
		Header: i18n.t('trackings:table_columns.system'),
		accessor: 'system',
		sortable: false
	},
	{
		Header: i18n.t('trackings:table_columns.player'),
		accessor: 'player',
		sortable: false
	}
];
