import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Import actions
import {
	clearState,
	clearPanelState,
	updatePreviousLocation
} from 'store/actions';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

// Import components
import { MainTemplate } from 'components/common/templates';
import Nav from './nav/Nav';
import Footer from './footer/Footer';
import TopHeader from './top_header/TopHeader';
import Routes from 'components/routes/Routes';

class Main extends React.Component {
	static propTypes = {
		history: PropTypes.object.isRequired,
		clearState: PropTypes.func.isRequired,
		clearPanelState: PropTypes.func.isRequired,
		updatePreviousLocation: PropTypes.func.isRequired,
		signOut: PropTypes.shape({
			loading: PropTypes.bool.isRequired,
			error: PropTypes.bool.isRequired
		}).isRequired
	};

	componentDidUpdate(prevProps) {
		const {
			location,
			clearState,
			clearPanelState,
			updatePreviousLocation,
			signOut: { error }
		} = this.props;
		// Change location ,clear state to initial state
		if (location.pathname !== prevProps.location.pathname) {
			// Scroll window to top
			window.scrollTo(0, 0);
			// Dispatch an clearState action in clear folder
			clearState();

			const menuSection = location.pathname.split('/')[2];
			const prevMenuSection = prevProps.location.pathname.split('/')[2];
			// Dispatch an clearPanelState action in clear folder
			if (menuSection !== prevMenuSection) {
				clearPanelState();
			}

			// Dispatch an updatePreviousLocation action in location folder
			updatePreviousLocation(prevProps.location);
		}

		// If there is an error during sign out, show notification
		if (error !== prevProps.signOut.error) {
			notificationHandler(
				'Sign out error',
				'An error occurred during sign out',
				'error',
				8
			);
		}
	}

	render() {
		return (
			<MainTemplate>
				<Nav />

				<div id="page-wrapper" className="gray-bg">
					<TopHeader />
					<Routes />
					<Footer />
				</div>
			</MainTemplate>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		signOut: state.auth.signOut
	};
};

export default connect(mapStateToProps, {
	clearState,
	clearPanelState,
	updatePreviousLocation
})(Main);
