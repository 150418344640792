import React from 'react';
import i18n from 'i18next';

// Import components
import EpisodeForm from '../../episodes_form/EpisodeForm';
import VodMaterials from '../../../common/materials/VodMaterials';
import VodPrices from 'components/views/vod/vod_prices/VodPrices';
import VodAvailability from 'components/views/vod/vod_availability/VodAvailability';
import Payments from 'components/views/payments/Payments';
import EpisodesImages from 'components/views/vod/vod_file/EpisodesImages';
import VodEncoding from 'components/views/vod/vod_encoding/VodEncoding';
import VodPacketsAssigned from 'components/views/vod/vod_packets_assigned/VodPacketsAssigned';
import VodProductiveSTB from 'components/views/vod/vod_productive_stb/VodProductiveSTB';
import VodSectionsAssigned from '../../../vod_sections_assigned/VodSectionsAssigned';
import VodCatalogsAssigned from '../../../vod_catalogs_assigned/VodCatalogsAssigned';
import SystemSettingsForm from 'components/utilities/system_settings/SystemSettingsForm';

export const episodesTabs = [
	{
		name: i18n.t(`common:tabs.metadata`),
		path: 'metadata',
		disableOnCreate: false,
		component: <EpisodeForm />
	},
	{
		name: i18n.t('common:tabs.system_settings'),
		path: 'system_settings',
		disableOnCreate: false,
		component: <SystemSettingsForm />
	},
	{
		name: i18n.t(`common:tabs.pictures`),
		path: 'pictures',
		disableOnCreate: true,
		component: <EpisodesImages />
	},
	{
		name: i18n.t(`common:tabs.materials`),
		path: 'materials',
		disableOnCreate: true,
		component: <VodMaterials />
	},
	{
		name: i18n.t(`common:tabs.availability`),
		path: 'availability',
		disableOnCreate: true,
		component: <VodAvailability />
	},
	{
		name: i18n.t(`common:tabs.prices`),
		path: 'prices',
		disableOnCreate: true,
		component: <VodPrices />
	},
	{
		name: i18n.t(`common:tabs.payments`),
		path: 'payments',
		disableOnCreate: true,
		component: <Payments />
	},
	{
		name: i18n.t('common:tabs.encoding'),
		path: 'encoding',
		disableOnCreate: true,
		component: <VodEncoding />
	},
	{
		name: i18n.t('common:tabs.packets_assigned'),
		path: 'packets_assigned',
		disableOnCreate: true,
		component: <VodPacketsAssigned />
	},
	{
		name: i18n.t('common:tabs.product_sections_assigned_statically'),
		path: 'sections_assigned',
		disableOnCreate: true,
		component: <VodSectionsAssigned fetchUrlParams="/static?" />
	},
	{
		name: i18n.t('common:tabs.product_sections_assigned_stb'),
		path: 'sections_assigned_stb',
		disableOnCreate: true,
		component: <VodSectionsAssigned fetchUrlParams="?platform=STB&" />
	},
	{
		name: i18n.t('common:tabs.product_catalogs_assigned'),
		path: 'catalogs_assigned',
		disableOnCreate: true,
		component: <VodCatalogsAssigned />
	},
	{
		name: i18n.t('common:tabs.productive_stb'),
		path: 'productization_stb',
		disableOnCreate: true,
		component: <VodProductiveSTB />
	}
];
