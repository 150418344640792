import React from 'react';

// Import components
import DeleteButton from 'components/utilities/delete_button/DeleteButton';
import EditButton from 'components/utilities/edit_button/EditButton';

// Import translation
import i18n from 'i18next';

// Import utilities
import ScheduleActivity from 'components/utilities/schedule_activity/ScheduleActivity';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('availability:table_columns.active'),
		accessor: 'active',
		width: 75,
		className: 'text-center ',
		Cell: ({
			row: {
				_original: { id, active }
			}
		}) => <ScheduleActivity id={id} isActive={active} />
	},
	{
		Header: i18n.t('availability:table_columns.since'),
		accessor: 'since'
	},
	{
		Header: i18n.t('availability:table_columns.till'),
		accessor: 'till'
	},
	{
		Header: i18n.t('availability:table_columns.created_at'),
		accessor: 'created_at'
	},
	{
		Header: i18n.t('common:table_columns.system'),
		accessor: 'system'
	},
	{
		Header: i18n.t('sections:table_columns.edit'),
		width: 80,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { product_type, product_uuid, id, system_id }
			}
		}) => (
			<EditButton
				productType={product_type}
				productUuid={product_uuid}
				id={id}
				productSystem={system_id}
				isReadOnly={isReadOnly}
			/>
		)
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id, products, system_id }
			}
		}) => (
			<DeleteButton
				id={id}
				products={products}
				productSystem={system_id}
				onSelectItem={handleSelectItem}
				isReadOnly={isReadOnly}
			/>
		)
	}
];
