// Import translation
import i18n from 'i18next';

export default ({ range }) => {
	const errors = {};

	if (!range || !range.length) {
		errors.range = i18n.t('availability:validation.range_required');
	}

	return errors;
};
