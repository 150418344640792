// Import helpers
import { BLUEONLINE_MAIN_SYSTEM } from 'components/helpers/variables';
import { convertArrayToValuePairsByKeyNames } from 'store/actions/helpers_actions/convert_helpers';

export const getCurrentSystemWithChildren = ({
	systems = [],
	currentSystem
}) => {
	const currentSystemSuggestion = systems.filter(
		({ name }) => name === currentSystem
	);

	const currentSystemWithChildren =
		currentSystem === BLUEONLINE_MAIN_SYSTEM
			? systems
			: currentSystemSuggestion;

	const convertedSystems = convertArrayToValuePairsByKeyNames(
		currentSystemWithChildren,
		'name',
		'name'
	);

	return convertedSystems;
};
