import React from 'react';

// Import translation
import i18n from 'i18next';

// import components
import { IngesterDataModal } from 'components/views/vod/vod_encoding/components';
import { Tag } from 'antd';

// Import helpers
import { setColorByType } from '../helpers';

export const columns = [
	{
		Header: i18n.t('ingester:ingester_history:table_columns.type'),
		accessor: 'type',
		sortable: false,
		width: 120,
		Cell: ({
			row: {
				_original: { type }
			}
		}) => <Tag color={setColorByType(type)}>{type}</Tag>
	},
	{
		Header: i18n.t('ingester:ingester_history:table_columns.status'),
		accessor: 'status',
		width: 120,
		sortable: false
	},
	{
		Header: i18n.t('ingester:ingester_history:table_columns.message'),
		accessor: 'message',
		sortable: false
	},
	{
		Header: i18n.t('ingester:ingester_history:table_columns.created_at'),
		accessor: 'created_at',
		sortable: false
	},
	{
		accessor: 'data',
		sortable: false,
		width: 120,
		Cell: ({
			row: {
				_original: { data }
			}
		}) => <IngesterDataModal data={data} />
	}
];
