import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import MassChangesElementsList from 'components/utilities/mass_changes/elements_list';

// Helpers
import { getProductsUuids, removeItemFromSelected } from './helpers';

// Default field values
import { defaultFieldValues } from './fields/input_fields';

const MassChangesAvailability = () => {
	const { t } = useTranslation();
	const { state } = useLocation();
	const history = useHistory();

	// component state
	const [products, setProducts] = useState(state?.selection);

	const hasSelections = products?.length > 0;

	const handleOnSubmit = ({ isEdit, itemID }) => async ({ ...values }) => {
		// get array of selected products uuids
		const vods_uuids = getProductsUuids(products);

		const resources = { ...values, vods_uuids };

		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'vod/massActiveChange',
			redirect: false,
			notificationName: 'systems'
		});
	};

	const handleRemoveItem = (id) =>
		removeItemFromSelected({ id, products, setProducts });

	const formInitialValues = { ...defaultFieldValues };

	return (
		<FormTemplate
			fetchFormResources={[]}
			iboxContentTitle={t('mass_changes:tabs.systems_availability')}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={formInitialValues}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields submitting={submitting} />
							{hasSelections && (
								<MassChangesElementsList
									selection={products}
									handleRemoveItem={handleRemoveItem}
									productsTitle={t(
										'mass_changes:change_availability.productsTitle'
									)}
								/>
							)}
							<FormButtons
								isButtonDisabled={submitting || !hasSelections}
								path="vod"
								buttonText={t('common:buttons.submit')}
								cancelButtonDisabled={false}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

export default MassChangesAvailability;
