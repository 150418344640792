import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH PROMOTION_PRODUCTS LIST ********************
export const fetchPromotionPacketsList = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_PROMOTION_PACKETS_LIST_LOADING,
			payload: true
		});

		const { data } = await services.get(`/promotions/${id}/packets`);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_PROMOTION_PACKETS_LIST_SUCCESS,
			payload: data.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_PROMOTION_PACKETS_LIST_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
