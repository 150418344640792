import React from 'react';
import { Form } from 'react-final-form';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import helpers
import { convertProductType } from '../helpers';
import { mapAvailabilityProductTypeName } from 'components/helpers/name_mapping';
import { setProperAction } from './helpers';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const AvailabilityFormTemplate = () => {
	const { t } = useTranslation();

	const history = useHistory();
	const { type, productID: productUuid } = useParams();

	// STATE
	// map type for proper Redux data acccess
	const mappedType = mapAvailabilityProductTypeName(type);
	const dispatch = useDispatch();
	const prevPath = useSelector((store) => store.previous_location.pathname);
	const formData = useSelector((store) => store[mappedType].availability.form);

	const { edit: initialValues, error, isLoaded } = formData;

	// Remove "/panel/" from prevPath;
	const redirectPath = prevPath.slice(7);

	const dateFormat = 'YYYY-MM-DD HH:mm:ss';

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		// ------- Prepare Resources -------
		// convert range to since and till
		const [since, till] = values.range;
		const sinceFormated = moment(since).format(dateFormat);
		const tillFormated = moment(till).format(dateFormat);

		const productResources = {
			product_type: convertProductType(type)
		};

		productResources.id = !isEdit ? itemID : null;
		productResources.product_uuid = !isEdit ? productUuid : itemID;

		// create final resources
		const resources = {
			...productResources,
			since: sinceFormated,
			till: tillFormated
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'timetables/availability',
			redirectPath,
			redirectToPath: true,
			apiSlug: 'update'
		});
	};

	// ------ Initial Values ------
	const { since, till } = initialValues;
	// properly format range values from since and till data
	const range = [moment(since), moment(till)];

	const formInitialValues = {
		...initialValues,
		range
	};

	return (
		<FormTemplate
			fetchResourceToEdit={(id, additionalResources) =>
				dispatch(setProperAction(type)(id, additionalResources))
			}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={formInitialValues}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields submitting={submitting} />

							<FormButtons
								isButtonDisabled={submitting}
								path={redirectPath}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

export default AvailabilityFormTemplate;
