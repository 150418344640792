import React from 'react';
import i18n from 'i18next';

// Import components
import { Button } from 'reactstrap';

// Import helpers
import { handleDownloadButtonClick } from './helpers';

export const columns = [
	{
		title: i18n.t('topology:columns.id'),
		dataIndex: 'id',
		key: 'id',
		align: 'center',
		width: 30
	},
	{
		title: i18n.t('topology:columns.filename'),
		dataIndex: 'filename',
		key: 'filename',
		align: 'center',
		width: 120
	},
	{
		title: i18n.t('topology:columns.name'),
		dataIndex: 'name',
		key: 'name',
		align: 'center',
		width: 120
	},
	{
		title: i18n.t('topology:columns.state'),
		dataIndex: 'state',
		key: 'state',
		align: 'center',
		width: 120
	},
	{
		title: i18n.t('topology:columns.created_at'),
		dataIndex: 'created_at',
		key: 'created_at',
		align: 'center',
		width: 120
	},
	{
		title: i18n.t('topology:columns.updated_at'),
		dataIndex: 'updated_at',
		key: 'updated_at',
		align: 'center',
		width: 120
	},
	{
		title: i18n.t('topology:columns.download'),
		dataIndex: '',
		align: 'center',
		width: 120,
		render: (text, record) => {
			const { id, filename } = record;

			return (
				<Button onClick={() => handleDownloadButtonClick({ filename, id })}>
					{i18n.t('common:download_file')}
				</Button>
			);
		}
	}
];
