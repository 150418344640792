import React, { createContext, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

// Import helpers
import { MEDIA_TYPES } from 'components/helpers';

const { VOD, SERIES, SEASON, EPISODE, CHANNEL, CATCHUP, BOX } = MEDIA_TYPES;

const TYPES_TO_SEARCH = [VOD, SERIES, SEASON, EPISODE, CHANNEL];

export const DeeplinkContext = createContext();

const DeeplinkContextProvider = ({ children, input, close }) => {
	const { t } = useTranslation();
	const productSelectRef = useRef();

	const [productType, setProductType] = useState(null);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [catchupDate, setCatchupDate] = useState(null);
	const [catchupProgram, setCatchupProgram] = useState(null);

	const isCatchup = productType === CATCHUP;
	const productSearch = TYPES_TO_SEARCH.includes(productType);

	const shouldAllowToGenerate = useMemo(() => {
		if (productSearch) return !!selectedProduct;
		else if (productType === CATCHUP) return !!catchupDate && !!catchupProgram;
		return !!productType;
	}, [
		productType,
		selectedProduct,
		catchupDate,
		catchupProgram,
		productSearch
	]);

	const mapProductToMediaType = () => {
		switch (productType) {
			case VOD:
				return 'movies';
			default:
				return productType;
		}
	};

	const generateDeeplink = () => {
		switch (productType) {
			case BOX:
				return 'profil_box';
			case CHANNEL:
				return `channel_uuid_${selectedProduct?.uuid}`;
			case CATCHUP:
				return `catchup_uuid_${catchupProgram?.uuid}`;
			default:
				return `vod_${mapProductToMediaType()}_uuid_${selectedProduct?.uuid}`;
		}
	};

	const handleUpdateValue = () => {
		input.onChange(generateDeeplink());
		notification.success({
			message: t('product_select:notifications.deeplink_created'),
			placement: 'bottom'
		});
		close();
	};

	return (
		<DeeplinkContext.Provider
			value={{
				productSelectRef,
				productType,
				isCatchup,
				productSearch,
				handleUpdateValue,
				shouldAllowToGenerate,
				setProductType,
				setSelectedProduct,
				catchupDate,
				catchupProgram,
				setCatchupDate,
				setCatchupProgram,
				selectedProduct
			}}
		>
			{children}
		</DeeplinkContext.Provider>
	);
};

DeeplinkContextProvider.propTypes = {
	children: PropTypes.node,
	input: PropTypes.object,
	close: PropTypes.func
};

export default DeeplinkContextProvider;
