import i18n from 'i18next';

export default ({
	external_uid,
	title,
	cities,
	npvr_active,
	npvr_source_id
}) => {
	const errors = {};
	const isNpvrIdMissing = npvr_active && !npvr_source_id;

	// ------------------ External_uid ------------------
	if (!external_uid) {
		errors.external_uid = i18n.t(
			'channels:form.validation.external_uid_required'
		);
	} else if (external_uid < 1) {
		errors.external_uid = i18n.t(
			'channels:form.validation.external_uid_positive'
		);
	}

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('channels:form.validation.title_required');
	}

	// ------------------ Cities ------------------
	if (cities) {
		const isCitiesHasWhiteSpace = /\s/g.test(cities);
		if (isCitiesHasWhiteSpace) {
			errors.cities = i18n.t('channels:form.validation.cities');
		}
	}

	// ------------------ NPVR ------------------
	if (isNpvrIdMissing) {
		errors.npvr_source_id = i18n.t(
			'channels:form.validation.npvr_source_id_required'
		);
	}

	return errors;
};
