import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

// Import helpers
import { convertPrice } from 'components/helpers/convert';
import { SUBSCRIBER_PACKETS_PAYMENT_STATUS } from 'components/helpers/variables';

const { PENDING } = SUBSCRIBER_PACKETS_PAYMENT_STATUS;

export const createProvisioningColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('subscribers:table_columns.title'),
		accessor: 'title',
		sortable: false,
		Cell: ({
			row: {
				_original: { uuid, title }
			}
		}) => (
			<Link to={`/panel/provisioning/edit/${uuid}/metadata`} title={title}>
				{title}
			</Link>
		)
	},
	{
		Header: i18n.t('subscribers:table_columns.activated_at'),
		accessor: 'attached_at',
		width: 200
	},
	{
		Header: i18n.t('subscribers:table_columns.assigned'),
		accessor: 'assigned',
		sortable: false,
		width: 200,
		className: 'text-center',
		Cell: ({
			row: {
				_original: { assigned }
			}
		}) => <ActiveIcon active={assigned} />
	},
	{
		Header: i18n.t('subscribers:table_columns.expires_at'),
		accessor: 'expires_at',
		width: 200
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		sortable: false,
		width: 60,
		className: 'text-center',
		show: !isReadOnly,
		Cell: ({
			row: {
				_original: { assigned, uuid }
			}
		}) => (
			<>
				{assigned && (
					<button
						onClick={() => handleSelectItem(uuid)}
						className="btn btn-xs btn-primary"
					>
						<i className="fa fa-trash" />
					</button>
				)}
			</>
		)
	}
];

export const createSubscriptionsColumns = () => [
	{
		Header: i18n.t('subscribers:table_columns.subscriptions_title'),
		accessor: 'product_title',
		sortable: false,
		Cell: ({
			row: {
				_original: { product_uuid, name }
			}
		}) => <Link to={`/panel/main/edit/${product_uuid}/metadata`}>{name}</Link>
	},
	{
		Header: i18n.t('subscribers:table_columns.subscription_period'),
		accessor: '',
		Cell: ({
			row: {
				_original: {
					status,
					expires_on,
					billing_period_end_on,
					billing_period_start_on
				}
			}
		}) => (
			<span>
				{status !== PENDING &&
					`${billing_period_start_on} - ${expires_on || billing_period_end_on}`}
			</span>
		)
	},
	{
		Header: i18n.t('subscribers:table_columns.payment_status'),
		accessor: 'payment_status',
		Cell: ({
			row: {
				_original: { payment_status }
			}
		}) => (
			<span className={`${payment_status}--text-color`}>
				{i18n.t(`subscribers:payment_status.${payment_status}`)}
			</span>
		)
	},
	{
		Header: i18n.t('subscribers:table_columns.status'),
		accessor: 'status',
		Cell: ({
			row: {
				_original: { status }
			}
		}) => (
			<span className={`${status}--text-color`}>
				{i18n.t(`subscribers:subscription_status.${status}`)}
			</span>
		)
	},
	{
		Header: i18n.t('subscribers:table_columns.final_price'),
		accessor: 'price',
		Cell: ({
			row: {
				_original: { price }
			}
		}) => <span>{convertPrice(price)}</span>
	}
];
