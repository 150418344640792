import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';

const Ibox = ({ children, className = '', error = false }) => (
	<div className={`ibox ${className}`}>
		{error && (
			<Alert
				showIcon
				message="Error"
				type="error"
				description="An error has occurred and the data can not be retrieved"
				className="m-b-md"
			/>
		)}
		{children}
	</div>
);

Ibox.propTypes = {
	error: PropTypes.bool,
	className: PropTypes.string
};

export default Ibox;
