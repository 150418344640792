import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

// Import translation
import { withTranslation } from 'react-i18next';

// Import global helpers
import {
	createTableOptions,
	prepareDefaultSortedData,
	TABLE_LENGTH
} from 'components/helpers/table';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';
import CustomPagination from 'components/utilities/table/pagination/CustomPagination';

class ReactServerSideTable extends React.Component {
	static propTypes = {
		fetchResourcesAction: PropTypes.func.isRequired,
		resourcesForFetchAction: PropTypes.any,
		resources: PropTypes.shape({
			data: PropTypes.array,
			deleteItem: PropTypes.object,
			options: PropTypes.shape({
				pages: PropTypes.number,
				filters: PropTypes.object
			}),
			loading: PropTypes.bool
		}).isRequired,
		notificationDeleteSuccessTxt: PropTypes.string,
		notificationDeleteErrorTxt: PropTypes.string,
		itemId: PropTypes.string,
		defaultSorted: PropTypes.array,
		tableType: PropTypes.string
	};

	static defaultProps = {
		selectItemColumns: [],
		notificationDeleteSuccessTxt: '',
		notificationDeleteErrorTxt: '',
		defaultSorted: [],
		resources: {
			deleteItem: {
				success: false,
				error: false
			}
		},
		itemId: null,
		resourcesForFetchAction: null,
		tableType: ''
	};

	state = {
		columns: [...this.props.columns],
		defSorted: prepareDefaultSortedData(
			this.props.defaultSorted,
			this.props.resources?.options?.sorted
		),
		pageSize: TABLE_LENGTH
	};

	componentDidUpdate(prevProps) {
		const { deleteItem, columns, isRefresh } = this.props.resources;
		const {
			resources: {
				deleteItem: prevDeleteItem,
				columns: prevColumns,
				isRefresh: prevIsRefresh
			}
		} = prevProps;

		const shouldRefetch =
			columns.length !== prevColumns.length || (isRefresh && !prevIsRefresh);

		// Compare prevProps columns length to current prop columns length and check if table has default sorted
		if (shouldRefetch) {
			// We need to reload table after first load when we have already columns to get sorted data
			this.handleOnFetchData(this.refReactTable.state);
		}
		// Compare prevProps success to current prop success
		if (prevDeleteItem.success === false && deleteItem.success === true) {
			this.fetchTableDataHandler(false);
		}
		// Compare prevProps error to current prop error
		if (prevDeleteItem.error !== deleteItem.error) {
			this.deleteTableItemHandler();
		}
	}

	fetchTableDataHandler = (isRestricted = true) => {
		// Fetch table resources
		this.handleOnFetchData(this.refReactTable.state, undefined, isRestricted);
		// Add success notification
		notificationHandler(
			this.props.t('messages:notifications.deleted'),
			this.props.notificationDeleteSuccessTxt,
			'success'
		);
	};

	deleteTableItemHandler = () =>
		// Add error notification
		notificationHandler(
			this.props.t('messages:notifications.error'),
			this.props.notificationDeleteErrorTxt,
			'error'
		);

	// Fetch table data
	handleOnFetchData = async (state, _, isRestricted = true) => {
		const {
			fetchResourcesAction,
			itemId,
			resourcesForFetchAction,
			tableType,
			resources: {
				options: { sorted: reduxSorted, page }
			}
		} = this.props;

		// Cancel extra request on pagination
		if (isRestricted && state.page !== 0 && state.page === page) return;

		// Create options
		const options = createTableOptions(state, reduxSorted, tableType);

		// Fetch resources
		fetchResourcesAction(options, itemId, resourcesForFetchAction);
	};

	handleTableSize = (value) => {
		this.setState({ pageSize: value });
	};

	render() {
		const {
			columns,
			getTrProps,
			t,
			resources: {
				data,
				options: { pages, filters, page, total_results: totalResults },
				loading
			}
		} = this.props;

		return (
			<ReactTable
				PaginationComponent={(props) => (
					<CustomPagination
						tableProps={props}
						totalResults={totalResults}
						onChangeTableSize={this.handleTableSize}
					/>
				)}
				ref={(refReactTable) => (this.refReactTable = refReactTable)}
				manual
				data={data}
				columns={[...columns]}
				pages={pages}
				page={page}
				filtered={filters}
				showPageSizeOptions={false}
				pageSize={this.state.pageSize}
				loading={loading}
				defaultSorted={this.state.defSorted}
				noDataText={t('common:no_data')}
				previousText={t('common:table_buttons.prev')}
				nextText={t('common:table_buttons.next')}
				className="-striped"
				onFetchData={this.handleOnFetchData}
				getTrProps={getTrProps}
				showPagination={!!pages}
				showPaginationTop={!!pages}
				minRows={0}
			/>
		);
	}
}

export default withTranslation()(ReactServerSideTable);
