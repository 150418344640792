import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH SYSTEM SETTINGS ********************
export const fetchSystemSettings = (id, { type }) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_SYSTEM_SETTINGS_LOADING
		});

		const { data } = await services.get(`/${type}/${id}/systemFields`);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SYSTEM_SETTINGS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SYSTEM_SETTINGS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
