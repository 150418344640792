import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchMainAvailability, deleteMainAvailability } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { getLocationType } from 'components/helpers/convert';

// Import columns
import { createColumns } from './main_availability_columns';

// Import components
import { TableTemplate } from 'components/common/templates';

const MainAvailability = ({
	error,
	fetchMainAvailability,
	deleteMainAvailability,
	resources,
	match: {
		params: { id }
	},
	location: { pathname }
}) => {
	const { t } = useTranslation();
	const locationType = getLocationType(pathname);

	return (
		// Dispatch fetchMainAvailability, deleteMainAvailability action in main_availability
		<TableTemplate
			itemId={id}
			error={error}
			type={`main/${id}/availability`}
			typeText="availability"
			addLinkText={t('availability:buttons.add')}
			resources={resources}
			columns={(handleSelectItem, isReadOnly) =>
				createColumns(handleSelectItem, isReadOnly, locationType)
			}
			fetchResourcesAction={fetchMainAvailability}
			deleteItemAction={deleteMainAvailability}
			notificationDeleteSuccessTxt={t(
				'availability:notification.delete_success'
			)}
			notificationDeleteErrorTxt={t('availability:notification.delete_error')}
		></TableTemplate>
	);
};

MainAvailability.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchMainAvailability: PropTypes.func.isRequired,
	deleteMainAvailability: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	})
};

const mapStateToProps = ({ main: { availability } }) => ({
	error: availability.table.error,
	resources: availability.table
});

export default compose(
	connect(mapStateToProps, { fetchMainAvailability, deleteMainAvailability }),
	withRouter
)(MainAvailability);
