import { combineReducers } from 'redux';
// Import reducers
import assets_table from './ingester_assets/ingester_assets_panel_reducer';
import encoding_table from './ingester_encoding/ingester_encoding_panel_reducer';
import stats from './ingester_stats/ingester_stats_reducer';
import video_assets_params from './video_assets_params/video_assets_params_reducer';
import history_table from './ingester_history/ingester_history_panel_reducer';
import assets_items_table from './ingester_assets_items/ingester_assets_items_panel_reducer';

export default combineReducers({
	assets_table,
	encoding_table,
	history_table,
	assets_items_table,
	stats,
	video_assets_params
});
