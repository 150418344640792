import i18n from 'i18next';

export const BASE_FIELDS = ({ isCountyDisabled }) => [
	{
		name: 'local_channel',
		label: i18n.t('local_channels:form.fields.channel_name'),
		placeholder: i18n.t('local_channels:form.fields.channel_name'),
		type: 'fetch-select',
		required: true,
		id: 'local_channel',
		url: `/channel/list?columns[22][search][value]=1`,
		selectName: 'title',
		selectValue: 'uuid'
	},
	{
		name: 'voivodeship',
		label: i18n.t('local_channels:form.fields.voivodeship'),
		placeholder: i18n.t('local_channels:form.fields.voivodeship'),
		type: 'custom-names',
		required: true,
		mode: ''
	},
	{
		name: 'counties',
		type: 'custom-names',
		label: i18n.t('local_channels:form.fields.county'),
		placeholder: i18n.t('local_channels:form.fields.county'),
		required: true,
		disabled: isCountyDisabled
	},
	{
		name: 'position',
		label: i18n.t('local_channels:form:fields.position'),
		type: 'number',
		required: true
	}
];
