import React from 'react';
import i18n from 'i18n';
import { Col, FormGroup } from 'reactstrap';
import { Select } from 'antd';
import PropTypes from 'prop-types';

import { FieldInfo, FieldError, Label } from 'components/common/inputs';

/**
 * MultiSelect Input with custom onChange function. OnChange function can clear value of other field in the form.
 * Input designed for purpose of fetching data for providers_id field in series and seasons form, but can be used in other locations. USE WITH CAUTION!
 * When value is changed additional data is fetched to use in another field. Another field value is cleared (optionally)
 */
const MultiSelectLabelCustomChange = ({
	input,
	id,
	label,
	required,
	placeholder,
	labelColumn,
	inputColumn,
	isEditDisabled,
	mode,
	showSearch,
	children,
	meta: { touched, error, submitError },
	onSearch,
	onChangeAction, // action that will be called on onChange
	currentValues, //current form values
	clearFieldName, // clear value of field with given name
	fieldInfo,
	...otherProps
}) => {
	const handleOnChange = (value) => {
		onChangeAction(value);
		if (clearFieldName) {
			currentValues[clearFieldName] = null;
		}
		return input.onChange(value);
	};

	return (
		<FormGroup row className={`${touched && error && 'has-error'}`}>
			{isEditDisabled && <div className="form-group__disabled">&nbsp;</div>}
			<Label
				id={id}
				columnWidth={labelColumn}
				required={required}
				content={label}
			/>
			<Col sm={inputColumn}>
				<Select
					{...input}
					{...otherProps}
					showSearch
					value={input.value || []}
					mode={mode}
					style={{ width: '100%' }}
					placeholder={placeholder}
					className="m-b-sm"
					onSearch={onSearch}
					onChange={handleOnChange}
				>
					{children}
				</Select>
				<FieldError
					error={touched && (error || submitError)}
					inputName={input.name}
					content={error || submitError}
				/>
				<FieldInfo info={fieldInfo} />
			</Col>
		</FormGroup>
	);
};

MultiSelectLabelCustomChange.defaultProps = {
	onSearch: () => {},
	onChangeAction: () => {},
	placeholder: i18n.t('inputs:defaults.multiselectcustomchange'),
	labelColumn: 2,
	inputColumn: 10,
	isEditDisabled: false,
	mode: 'multiple',
	showSearch: false
};

MultiSelectLabelCustomChange.propTypes = {
	input: PropTypes.object,
	id: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	placeholder: PropTypes.string,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	isEditDisabled: PropTypes.bool,
	mode: PropTypes.string,
	children: PropTypes.array,
	meta: PropTypes.object,
	onChangeAction: PropTypes.func,
	onSearch: PropTypes.func,
	otherProps: PropTypes.object,
	showSearch: PropTypes.bool,
	fieldInfo: PropTypes.string
};

export default MultiSelectLabelCustomChange;
