import React from 'react';
import i18n from 'i18next';

// Import components
import ChannelsForm from '../../channels_form/ChannelsForm';
import ChannelsImages from '../../channels_file/ChannelsImages';
import Epg from '../epg/EPG';
import ChannelsMaterials from '../materials/ChannelsMaterials';
import ChannelsAvailability from '../../channels_availability/ChannelsAvailability';
import ChannelsSystemSettings from '../../channels_contexts/ChannelsSystemSettings';
import ChannelsPacketsAssigned from '../../channels_packets_assigned/ChannelsPacketsAssigned';

export const channelsTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <ChannelsForm />
	},
	{
		name: i18n.t('channels:tabs.system_settings'),
		path: 'system_settings',
		disableOnCreate: true,
		component: <ChannelsSystemSettings />
	},
	{
		name: i18n.t('common:tabs.epg'),
		path: 'epg',
		disableOnCreate: true,
		component: <Epg />
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <ChannelsImages />
	},
	{
		name: i18n.t('common:tabs.materials'),
		path: 'materials',
		disableOnCreate: true,
		component: <ChannelsMaterials />
	},
	{
		name: i18n.t('common:tabs.availability'),
		path: 'availability',
		disableOnCreate: true,
		component: <ChannelsAvailability />
	},
	{
		name: i18n.t('common:tabs.packets_assigned'),
		path: 'packets_assigned',
		disableOnCreate: true,
		component: <ChannelsPacketsAssigned />
	}
];
