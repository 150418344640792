import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup } from 'reactstrap';
import { Input } from 'antd';

// Import helpers
import { FieldInfo, FieldError, Label } from 'components/common/inputs';

const TextareaLabel = ({
	input,
	id,
	label,
	rows,
	labelColumn,
	inputColumn,
	resize,
	meta: { touched, error, submitError },
	fieldInfo,
	warningInfo,
	required,
	maxLength,
	...inputProps
}) => {
	return (
		<FormGroup row className={`${touched && error ? 'has-error' : ''} `}>
			<Label
				id={id}
				columnWidth={labelColumn}
				required={required}
				content={label}
			/>
			<Col sm={inputColumn}>
				<Input.TextArea
					id={id}
					{...input}
					{...inputProps}
					showCount={!!maxLength}
					maxLength={maxLength}
				/>
				<FieldError
					error={touched && (error || submitError)}
					inputName={input.name}
					content={error || submitError}
				/>
				<FieldInfo info={fieldInfo} />
			</Col>
		</FormGroup>
	);
};

TextareaLabel.defaultProps = {
	required: false,
	labelColumn: 2,
	inputColumn: 10,
	resize: 'vertical'
};

TextareaLabel.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	rows: PropTypes.string,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	resize: PropTypes.string,
	meta: PropTypes.object,
	fieldInfo: PropTypes.string,
	warningInfo: PropTypes.string,
	required: PropTypes.bool
};

export default TextareaLabel;
