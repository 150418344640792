import React from 'react';
import { object, string, number, bool } from 'prop-types';

// Import components
import {
	Col,
	FormGroup,
	Label,
	InputGroupAddon,
	InputGroup,
	Button
} from 'reactstrap';

// Import hooks
import useInputLabelButtonSyncLogic from './utils/useInputLabelButtonSyncLogic';

// Import helpers
import { REQUIRED_MARK } from 'components/helpers/variables';

const InputLabelButtonSync = ({
	input,
	id,
	label,
	labelColumn = 2,
	inputColumn = 10,
	children,
	meta: { touched, error },
	buttonText,
	requiredField = false,
	relatedField
}) => {
	const { handleClick } = useInputLabelButtonSyncLogic(relatedField);

	return (
		<FormGroup
			data-testid={`form-group-${input.name}`}
			row
			className={`${touched && error && 'has-error'}`}
		>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label} {requiredField && REQUIRED_MARK}
			</Label>
			<Col sm={inputColumn}>
				<InputGroup className="h-100">
					<InputGroupAddon addonType="prepend">
						<Button onClick={handleClick} style={{ height: '100%' }}>
							{buttonText}
						</Button>
					</InputGroupAddon>
				</InputGroup>
			</Col>
			{children}
		</FormGroup>
	);
};

InputLabelButtonSync.propTypes = {
	input: object.isRequired,
	label: string.isRequired,
	id: string.isRequired,
	labelColumn: number,
	inputColumn: number,
	children: object,
	meta: object,
	buttonText: string.isRequired,
	requiredField: bool,
	relatedField: string
};

export default InputLabelButtonSync;
