import React from 'react';
import PropTypes from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { Button } from 'reactstrap';
/**
 * Functional component that returns two buttons able to add new video to vod (main | preview)
 * @param {string} uuid - id of vod
 * @param {bool} isMainDisabled - this prop can disable add main video button
 * @param {bool} isPreviewDisabled - this prop can disable add preview video button
 * @param {func} addVideoToVod - dispatches action creator (POST add video to product)
 * @param {bool} isLoaded - disables both buttons if table content is not yet loaded
 */
const VodAddMaterialsButtons = ({
	uuid,
	isMainDisabled = false,
	isPreviewDisabled = false,
	addVideoToVod,
	isLoaded
}) => {
	const { t } = useTranslation();

	// dispatch action
	const handleAddVideo = (videoType) => {
		addVideoToVod(uuid, videoType);
	};
	return (
		<>
			<Button
				onClick={() => handleAddVideo('main')}
				disabled={isMainDisabled || !isLoaded}
				className="m-b-sm m-r-sm"
			>
				{t('vod:buttons.add_video')}
			</Button>
			<Button
				onClick={() => handleAddVideo('preview')}
				disabled={isPreviewDisabled || !isLoaded}
				className="m-b-sm m-r-sm"
			>
				{t('vod:buttons.add_preview')}
			</Button>
		</>
	);
};
VodAddMaterialsButtons.propTypes = {
	uuid: PropTypes.string.isRequired,
	isMainDisabled: PropTypes.bool.isRequired,
	isPreviewDisabled: PropTypes.bool,
	addVideoToVod: PropTypes.func.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

export default VodAddMaterialsButtons;
