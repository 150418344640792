import services from 'services/services';
import * as types from '../../types';
import { fetchProviderNamesById } from 'store/actions';

// Import helpers
import { checkFetchErrors } from '../../helpers_actions/convert_helpers';

// ******************** FETCH SERIES TO EDIT ********************
export const fetchSeries = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_SERIES_LOADING,
			payload: true
		});

		const response = await services.get(`/vod/series/${id}`);

		// fetch provider names for form combobox if there is provider_id
		if (response.data.provider_id) {
			fetchProviderNamesById(response.data.provider_id)(dispatch);
		}

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SERIES_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SERIES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
