import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

// Import actions
import { dictionarySubSystems, fetchAuthUserDetails } from 'store/actions';

// Import hooks
import useSystem from 'hooks/useSystem';

// Import variables
import { COOKIE_USER_SYSTEM } from 'components/helpers/variables';

const useSelectSystem = () => {
	const dispatch = useDispatch();
	const subSystems = useSelector(({ dictionary }) => dictionary.subSystems);

	const { currentSystem } = useSystem();

	const handleChangeSystem = (system) => {
		Cookies.set(COOKIE_USER_SYSTEM, system, { expires: 365 });
		dispatch(fetchAuthUserDetails());
	};

	useEffect(() => {
		dispatch(dictionarySubSystems());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		onChangeSystem: handleChangeSystem,
		currentSystem,
		subSystems
	};
};

export default useSelectSystem;
