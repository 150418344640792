import services from 'services/services';
import * as types from '../../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from 'store/actions/helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH INGESTER_ASSETS - TABLE DATA ********************
export const fetchIngesterAssets = (options) => async (dispatch, getState) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_INGESTER_ASSETS_LOADING,
			payload: true
		});

		const {
			ingester: {
				assets_table: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `/ingester/assets?${filterUrlQuery}${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex
		)}`;

		const { data: ingesterAssets } = await services.get(url);

		// Calculate pages
		const pages = Math.ceil(ingesterAssets.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_INGESTER_ASSETS_SUCCESS,
			payload: { ...ingesterAssets, pages }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_INGESTER_ASSETS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
// ******************** FETCH INGESTER_ASSETS - VOD DETAILS DATA ********************
export const fetchIngesterAssetsVodDetails = (vodId) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_INGESTER_ASSETS_VOD_DETAILS_LOADING,
			payload: true
		});

		const url = `/ingester/assets?order[0][column]=1&order[0][dir]=asc&start=0&product_uuid=${vodId}`;

		const { data: ingesterAssets } = await services.get(url);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_INGESTER_ASSETS_VOD_DETAILS_SUCCESS,
			payload: ingesterAssets
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_INGESTER_ASSETS_VOD_DETAILS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** REFRESH INGESTER ASSETS ********************
export const refreshIngesterAssets = () => (dispatch) =>
	dispatch({
		type: types.REFRESH_INGESTER_ASSETS
	});

// ******************** SET SEARCH QUERY ********************
export const setIngesterAssetsQuery = (query) => ({
	type: types.SET_INGESTER_ASSETS_SEARCH_QUERY,
	payload: query
});
