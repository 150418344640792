import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH ACTIVITY TO EDIT ********************
export const fetchActivity = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_ACTIVITY_LOADING,
			payload: true
		});

		const response = await services.get(`/activity/${id}`);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_ACTIVITY_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_ACTIVITY_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
