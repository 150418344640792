import i18n from 'i18next';

export const MARKETING_CAMPAIGNS_FILTER_FIELDS = [
	{
		name: 'notification_title',
		label: i18n.t('filters:fields.title'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'status',
		label: i18n.t('filters:fields.status'),
		type: 'select',
		required: false,
		showSearch: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'campaign_start',
		label: i18n.t('filters:fields.campaign_start'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	},
	{
		name: 'campaign_end',
		label: i18n.t('filters:fields.campaign_end'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	},
	{
		name: 'notification_end',
		label: i18n.t('filters:fields.notification_end'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	}
];
