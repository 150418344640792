// Import translation
import i18n from 'i18next';

// Import variables
import { SUBSCRIBER } from 'components/helpers/variables';

export const BASE_FIELDS = ({ invoiceType }) => [
	{
		name: 'subscriber_id',
		type: 'number',
		disabled: true,
		placeholder: i18n.t('subscribers:fields.subscriber_id'),
		label: i18n.t('subscribers:fields.subscriber_id'),
		required: true
	},
	{
		name: 'type',
		type: 'text',
		disabled: true,
		placeholder: i18n.t('subscribers:fields.type'),
		label: i18n.t('subscribers:fields.type')
	},
	{
		name: 'firstname',
		type: 'text',
		disabled: true,
		placeholder: i18n.t('subscribers:fields.firstname'),
		label: i18n.t('subscribers:fields.firstname'),
		isVisible: invoiceType === SUBSCRIBER.INDIVIDUAL
	},
	{
		name: 'lastname',
		type: 'text',
		disabled: true,
		placeholder: i18n.t('subscribers:fields.lastname'),
		label: i18n.t('subscribers:fields.lastname'),
		isVisible: invoiceType === SUBSCRIBER.INDIVIDUAL
	},
	{
		name: 'company_name',
		type: 'text',
		disabled: true,
		placeholder: i18n.t('subscribers:fields.company_name'),
		label: i18n.t('subscribers:fields.company_name'),
		isVisible: invoiceType !== SUBSCRIBER.INDIVIDUAL
	},
	{
		name: 'vat_number',
		type: 'text',
		disabled: true,
		placeholder: i18n.t('subscribers:fields.vat_number'),
		label: i18n.t('subscribers:fields.vat_number'),
		isVisible: invoiceType !== SUBSCRIBER.INDIVIDUAL
	},
	{
		name: 'street',
		type: 'text',
		disabled: true,
		placeholder: i18n.t('subscribers:fields.street'),
		label: i18n.t('subscribers:fields.street')
	},
	{
		name: 'building',
		type: 'text',
		disabled: true,
		placeholder: i18n.t('subscribers:fields.building_number'),
		label: i18n.t('subscribers:fields.building_number')
	},
	{
		name: 'apartment',
		type: 'text',
		disabled: true,
		placeholder: i18n.t('subscribers:fields.apartment'),
		label: i18n.t('subscribers:fields.apartment')
	},
	{
		name: 'city',
		type: 'text',
		disabled: true,
		placeholder: i18n.t('subscribers:fields.city'),
		label: i18n.t('subscribers:fields.city')
	},
	{
		name: 'zip',
		type: 'text',
		disabled: true,
		placeholder: i18n.t('subscribers:fields.zip'),
		label: i18n.t('subscribers:fields.zip')
	}
];
