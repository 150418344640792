import React from 'react';
import { array, bool } from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

// Import
import {
	BASE_FIELDS,
	CCUR_FIELDS,
	ATENDE_FIELDS,
	ATEME_FIELDS
} from './input_fields';

// Import utils
import { renderFields } from 'components/utilities/form';

const Fields = ({ submitting, genres, subdivisions, isNpvrActive }) => {
	const { t } = useTranslation();

	const selectResources = {
		genres: genres.map(({ name }) => name),
		subdivisions
	};

	return (
		<React.Fragment>
			<h3>{t('common:form.base_info')}</h3>
			<div className="hr-line-solid m-b-xl">&nbsp;</div>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
			<h3>{t('common:form.ccur_info')}</h3>
			<div className="hr-line-solid m-b-xl">&nbsp;</div>
			{CCUR_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}
			<h3>{t('common:form.atende_settings')}</h3>
			<div className="hr-line-solid m-b-xl">&nbsp;</div>
			{ATENDE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}
			<h3>{t('common:form.ateme_settings')}</h3>
			<div className="hr-line-solid m-b-xl">&nbsp;</div>
			{ATEME_FIELDS({ isNpvrActive }).map((item, index) =>
				renderFields({ item, index, submitting })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: bool.isRequired,
	genres: array,
	subdivisions: array,
	isNpvrActive: bool
};

export default Fields;
