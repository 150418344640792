import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	cover: {
		data: [],
		isLoaded: false,
		error: false
	},
	poster: {
		data: [],
		isLoaded: false,
		error: false
	},
	gallery: {
		data: [],
		isLoaded: false,
		error: false
	},
	title: {
		data: [],
		isLoaded: false,
		error: false
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** FETCH VOD'S COVER FILES ******************
			case types.FETCH_VOD_COVER_FILES_LOADING:
				draft.cover.data = [];
				draft.cover.isLoaded = false;
				draft.cover.error = false;
				return;

			case types.FETCH_VOD_COVER_FILES_SUCCESS:
				draft.cover.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.cover.isLoaded = true;
				return;

			case types.FETCH_VOD_COVER_FILES_ERROR:
				draft.cover.isLoaded = true;
				draft.cover.error = true;
				return;

			// ****************** FETCH VOD'S POSTER FILES ******************
			case types.FETCH_VOD_POSTER_FILES_LOADING:
				draft.poster.data = [];
				draft.poster.isLoaded = false;
				draft.poster.error = false;
				return;

			case types.FETCH_VOD_POSTER_FILES_SUCCESS:
				draft.poster.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.poster.isLoaded = true;
				return;

			case types.FETCH_VOD_POSTER_FILES_ERROR:
				draft.poster.isLoaded = true;
				draft.poster.error = true;
				return;

			// ****************** FETCH VOD'S GALLERY FILES ******************
			case types.FETCH_VOD_GALLERY_FILES_LOADING:
				draft.gallery.data = [];
				draft.gallery.isLoaded = false;
				draft.gallery.error = false;
				return;

			case types.FETCH_VOD_GALLERY_FILES_SUCCESS:
				draft.gallery.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.gallery.isLoaded = true;
				return;

			case types.FETCH_VOD_GALLERY_FILES_ERROR:
				draft.gallery.isLoaded = true;
				draft.gallery.error = true;
				return;

			// ****************** FETCH VOD'S TITLE FILES ******************
			case types.FETCH_VOD_TITLE_FILES_LOADING:
				draft.title.data = [];
				draft.title.isLoaded = false;
				draft.title.error = false;
				return;

			case types.FETCH_VOD_TITLE_FILES_SUCCESS:
				draft.title.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.title.isLoaded = true;
				return;

			case types.FETCH_VOD_TITLE_FILES_ERROR:
				draft.title.isLoaded = true;
				draft.title.error = true;
				return;

			default:
				return state;
		}
	});
