import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchSystemsChannelsProducts } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import ProductsSelect from 'components/utilities/products_select/ProductsSelect';

// Import products tabs
import { channels } from './helpers/tabs';

const SystemChannelsProducts = ({
	data,
	error,
	isLoaded,
	fetchSystemsChannelsProducts
}) => {
	const { t } = useTranslation();
	const tabs = [channels];

	const createSubmitData = (selectedData) => {
		const selection = { channels: [] };

		selection.channels = selectedData.map(({ uuid }) => ({
			uuid,
			available: true
		}));

		return selection;
	};

	return (
		<ProductsSelect
			api="channel/system/updateAvailability"
			title={t('systems:channels')}
			tabs={tabs}
			openModalText={t('systems:buttons.add_channel')}
			buttonSubmitText={t('common:buttons.submit')}
			notificationName="products"
			productsTitle={t('systems:channels_products_title')}
			initialData={data}
			error={error}
			isLoaded={isLoaded}
			fetchResourcesAction={fetchSystemsChannelsProducts}
			createSubmitData={createSubmitData}
			isSystem
		/>
	);
};

SystemChannelsProducts.propTypes = {
	data: PropTypes.array.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	fetchSystemsChannelsProducts: PropTypes.func.isRequired
};

const mapStateToProps = ({ systems: { channels } }) => ({
	data: channels.data,
	isLoaded: channels.isLoaded,
	error: channels.error
});

export default connect(mapStateToProps, { fetchSystemsChannelsProducts })(
	SystemChannelsProducts
);
