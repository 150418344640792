import i18n from 'i18next';

export const defaultFieldValues = {
	is_active: false
};

export const BASE_FIELDS = [
	{
		name: 'is_active',
		label: i18n.t('providers:fields.is_active'),
		type: 'checkbox'
	},
	{
		name: 'name',
		label: i18n.t('providers:fields.name'),
		type: 'text',
		required: true
	},
	{
		name: 'immutable_name',
		label: i18n.t('providers:fields.immutable_name'),
		type: 'text',
		required: true
	},
	{
		name: 'ingesting_path',
		label: i18n.t('providers:fields.ingesting_path'),
		type: 'text'
	}
];
