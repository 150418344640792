import React from 'react';
import useProductisationStatistics from './useProductisationStatistics';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { Link } from 'react-router-dom';

// Import variables
import { VOD_STATUS_VALUES } from 'components/helpers/variables';

const { ALL } = VOD_STATUS_VALUES;

function ProductisationStatistics() {
	const { t } = useTranslation();
	const { productisationStatisticsData } = useProductisationStatistics();

	return (
		<div className="statistics__wrapper">
			<h2>{t('statistics:titles.productisation')}</h2>
			<table className="statistics__table">
				<tbody>
					{productisationStatisticsData.map(([key, values]) => {
						return (
							<tr key={key}>
								<td>{t(`vod:productive_stb:columns.${key}`)}</td>
								<td className="d-flex flex-column ">
									{values.map((data) => {
										const [count, type] = Object.values(data);
										const productisationVodFilterQuery = `productisation_group=${key}&productisation_status=${type}&`;
										return (
											<div
												key={type}
												className="d-flex justify-content-between align-items-center "
											>
												<Link
													to={{
														pathname: '/panel/vod',
														state: {
															vodFilters: {
																filterUrlQuery: productisationVodFilterQuery,
																// filterFormValues is needed to set proper filter button state
																// it doesn't have effect on data inside filter form
																filterFormValues: {
																	productisationVodFilterQuery
																},
																filterStatus: ALL
															}
														}
													}}
												>{`${type}`}</Link>
												<p>{count}</p>
											</div>
										);
									})}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

export default ProductisationStatistics;
