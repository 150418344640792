import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { VerificationContext } from '../VerificationContext';

// Import helpers
import { VERIFICATION } from 'components/helpers/variables';

// Import components
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Error } from '../styles';
import DocumentPreview from './DocumentPreview';
import DocumentDetails from 'components/views/subscribers/subscribers_verification/components/DocumentDetails';

function VerificationDocumentPreview() {
	const { t } = useTranslation();
	const { document, extension, fileName, status, isLoaded } = useContext(
		VerificationContext
	);

	const renderDocumentPreview = () => {
		switch (status) {
			case VERIFICATION.NOTHING_TO_VERIFY:
				return (
					<div className="document_preview">
						<div className="document_preview_center">
							<Error>
								<ExclamationCircleTwoTone />
								<p>{t('verification:error.no_document')}</p>
							</Error>
						</div>
					</div>
				);

			case VERIFICATION.IN_PROGRESS:
				return (
					<DocumentPreview
						url={document}
						type={extension}
						fileName={fileName}
					/>
				);

			default:
				return <DocumentDetails />;
		}
	};

	return <section>{isLoaded && renderDocumentPreview()}</section>;
}

export default VerificationDocumentPreview;
