// Import products tabs
import {
	vod,
	channel,
	provisioning,
	collections,
	allPackets
} from 'components/utilities/products_select/helpers/tabs';

// Import helpers
import { PACKETS_CONFIGURATOR } from 'components/helpers/variables';

export const getPacketsProductTabs = (packet) => {
	const tabs = [vod, channel, provisioning, collections];
	if (packet?.type === PACKETS_CONFIGURATOR.MAIN && packet?.is_bundle)
		tabs.push(allPackets);
	return tabs;
};
