import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
	fetchVodMaterials,
	updateVodMaterials,
	updateVodMaterialsError,
	deleteVodMaterials,
	deleteVodMaterialsError,
	addVideoToVod
} from 'store/actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Import helpers
import { VOD_MATERIALS_LIMIT } from 'components/helpers/variables';

// Import columns
import { createColumns } from 'components/utilities/table/materials_table/materials_columns';

// Import components
import MaterialsTable from 'components/utilities/table/materials_table/MaterialsTable';
import {
	Ibox,
	IboxTitle,
	IboxContentBase,
	Wraper
} from 'components/common/layout';
import VodSubtitles from '../../vod_subtitles/VodSubtitles';
import { Empty } from 'antd';
import VodAddMaterialsButtons from 'components/common/buttons/add_materials_buttons/VodAddMaterialsButtons';

// Import translation
import { useTranslation } from 'react-i18next';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

const VodMaterials = ({
	fetchVodMaterials,
	updateVodMaterials,
	updateVodMaterialsError,
	deleteVodMaterials,
	deleteVodMaterialsError,
	addVideoToVod,
	error,
	isLoaded,
	main,
	preview,
	match
}) => {
	// fetch VOD materials on mount
	const uuid = match.params.id;
	React.useEffect(() => {
		// Dispatch fetchVodMaterials actions in vod_materials
		fetchVodMaterials(uuid);
		// eslint-disable-next-line
	}, []);

	const { canEditVideo } = useAuthCheck;

	const { t } = useTranslation();

	// disable Add Main video button if there is already main video created
	const isMainDisabled = main.length !== 0;

	return (
		<Wraper lg="12" error={error}>
			<>
				{canEditVideo && (
					<VodAddMaterialsButtons
						isLoaded={isLoaded}
						isMainDisabled={isMainDisabled}
						uuid={uuid}
						addVideoToVod={addVideoToVod}
					/>
				)}
				{isLoaded &&
					(main.length || preview.length ? (
						<>
							{main.map((resource) => (
								<Ibox key={resource.id}>
									<IboxTitle title={t('common:materials_table.main_title')} />
									<IboxContentBase isLoaded={isLoaded}>
										<div className="h-150">
											<MaterialsTable
												columns={createColumns}
												resources={resource}
												updateMaterials={updateVodMaterials}
												updateMaterialsError={updateVodMaterialsError}
												deleteMaterials={deleteVodMaterials}
												deleteMaterialsError={deleteVodMaterialsError}
												materialsLimit={VOD_MATERIALS_LIMIT}
											/>
											<VodSubtitles
												materialsType="main"
												videoId={resource.id}
											/>
										</div>
									</IboxContentBase>
								</Ibox>
							))}
							{preview.map((resource) => (
								<Ibox key={resource.id}>
									<IboxTitle
										title={t('common:materials_table.preview_title')}
									/>
									<IboxContentBase isLoaded={isLoaded}>
										<div className="h-150">
											<MaterialsTable
												columns={createColumns}
												resources={resource}
												updateMaterials={updateVodMaterials}
												updateMaterialsError={updateVodMaterialsError}
												deleteMaterials={deleteVodMaterials}
												deleteMaterialsError={deleteVodMaterialsError}
												materialsLimit={VOD_MATERIALS_LIMIT}
											/>
											<VodSubtitles
												materialsType="preview"
												videoId={resource.id}
											/>
										</div>
									</IboxContentBase>
								</Ibox>
							))}
						</>
					) : (
						<Empty description={t('common:no_data')} className="m-t-md" />
					))}
			</>
		</Wraper>
	);
};

const mapStateToProps = ({ vod: { materials } }) => ({
	error: materials.error,
	isLoaded: materials.isLoaded,
	main: materials.main.data,
	preview: materials.preview.data
});

VodMaterials.propTypes = {
	fetchVodMaterials: PropTypes.func.isRequired,
	updateVodMaterials: PropTypes.func.isRequired,
	updateVodMaterialsError: PropTypes.func.isRequired,
	deleteVodMaterials: PropTypes.func.isRequired,
	deleteVodMaterialsError: PropTypes.func.isRequired,
	addVideoToVod: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	main: PropTypes.array.isRequired,
	preview: PropTypes.array.isRequired,
	match: PropTypes.object.isRequired
};

export default compose(
	connect(mapStateToProps, {
		fetchVodMaterials,
		updateVodMaterials,
		updateVodMaterialsError,
		deleteVodMaterials,
		deleteVodMaterialsError,
		addVideoToVod
	}),
	withRouter
)(VodMaterials);
