import { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import axios from 'axios';

// Import helpers
import { validateRequirements } from './helpers';
import {
	RASTER_IMAGE_MIMES,
	IMAGE_MIMES,
	URL_REGEX,
	convertBytesToHumanReadable
} from 'components/helpers';

// Import hooks
import useImageReducer, { actions } from './useImageReducer';

const regex = new RegExp(URL_REGEX);

const useImageUrl = ({
	debounceValue = 800,
	input,
	requirements = [],
	value
}) => {
	const [url, setUrl] = useState(value);
	const [
		{ isLoading, isValid, rules, metadata, error },
		dispatch
	] = useImageReducer(validateRequirements(requirements));

	const debouncedValidation = debounce(async (url) => {
		try {
			if (!regex.test(url)) {
				dispatch({ type: actions.NO_VALID_URL });
				return;
			}

			const { data: blob } = await axios.get(url, { responseType: 'blob' });
			const isOfImageType = IMAGE_MIMES.some((type) => type === blob.type);
			const isRasterImage = RASTER_IMAGE_MIMES.some(
				(type) => type === blob.type
			);

			if (!isOfImageType) {
				dispatch({ type: actions.NO_VALID_URL });
				return;
			}

			const size = convertBytesToHumanReadable(blob.size);
			let metadata = {
				blob,
				isOfImageType,
				size,
				rawSize: blob.size,
				mime: blob.type,
				width: null,
				height: null,
				ratio: null
			};

			// No width, height and ratio for vector images
			if (isRasterImage) {
				const bmp = await createImageBitmap(blob);
				metadata.height = bmp.height;
				metadata.width = bmp.width;
				metadata.ratio = (bmp.width / bmp.height).toFixed(2);
				bmp.close();
			}

			const rules = validateRequirements(requirements, { ...metadata, url });
			dispatch({
				type: actions.SUCCESS,
				payload: {
					rules,
					metadata
				}
			});
		} catch (error) {
			if (!error.response) {
				dispatch({ type: actions.CORS });
			} else if (error.response.status === 404) {
				dispatch({ type: actions.NOT_FOUND });
			} else {
				dispatch({ type: actions.NO_VALID_URL });
			}
		}
	}, debounceValue);

	const validateUrl = useCallback(
		(url) => {
			dispatch({ type: actions.LOADING });
			debouncedValidation(url);
		},
		//eslint-disable-next-line
		[debouncedValidation]
	);

	useEffect(() => {
		if (value) {
			validateUrl(value);
		}
		//eslint-disable-next-line
	}, [value]);

	const onChange = async (e) => {
		const value = e.target.value;
		setUrl(value);
		input.onChange(value);
		validateUrl(value);
	};

	return {
		url,
		onChange,
		isLoading,
		isValid,
		metadata,
		rules,
		error
	};
};

export default useImageUrl;
