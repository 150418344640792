import i18n from 'i18next';

export const defaultFieldValues = {
	period: '1'
};

export const BASE_FIELDS = [
	{
		name: 'period',
		label: i18n.t('subscribers:fields.period'),
		type: 'number',
		required: true,
		fieldInfo: ''
	},
	{
		name: 'packet_uuid',
		label: i18n.t('subscribers:fields.provisioning_uuid'),
		placeholder: i18n.t('subscribers:fields.provisioning_uuid'),
		type: 'fetch-select',
		required: true,
		fieldInfo: '',
		id: 'packet_uuid',
		url: `/packet/list?type=provision`,
		selectName: 'title',
		selectValue: 'uuid'
	}
];
