// Import translation
import i18n from 'i18next';

// convert location type to proper API type
export const convertLocationType = (
	location,
	isEpg = false,
	isAvailability = false,
	isPrices = false,
	isCouponsSystemSettings = false
) => {
	if (isEpg) {
		return 'channel/programs';
	} else if (isAvailability) {
		return 'timetables/availability';
	} else if (isPrices) {
		return 'timetables/prices';
	} else if (isCouponsSystemSettings) {
		return 'promotions';
	} else {
		switch (location) {
			case 'provisioning':
				return 'packet';

			case 'main':
				return 'packet';

			case 'packets':
				return 'packet';

			case 'channels':
				return 'channel';

			case 'sections':
				return 'section';

			case 'sections_groups':
				return 'section/group';

			case 'categories':
				return 'products/genres';

			case 'limits':
				return 'player/limits';

			case 'subscribers':
				return 'subscriber';

			case 'systems':
				return 'system';

			case 'marketing_campaigns':
				return 'subscribers/campaigns';

			default:
				return location;
		}
	}
};

// get name from response data (response data is different for different product types)
export const getName = (data, type) => {
	switch (type) {
		case 'vod':
			return data.metadata.title;

		case 'packet':
			return data.title;

		case 'channel':
			return data.title;

		case 'section': //section needs to show both name and label since name can be an empty string
			const name = data.name;
			const label = data.label;
			const sectionName = `${i18n.t(
				'sections:bread_crumbs.section_name'
			)}: ${name} | ${i18n.t('sections:bread_crumbs.section_label')}: ${label}`;
			return sectionName;

		case 'section/group':
			return data.name;

		case 'channel/programs':
			return data.title;

		case 'subscribers/campaigns':
			return data?.data?.notification_title;

		default:
			return '';
	}
};

// checks if product is eligible for displaing its name
export const checkProperType = (type) => {
	switch (type) {
		case 'vod':
		case 'packet':
		case 'channel':
		case 'section':
		case 'section/group':
		case 'channel/programs':
		case 'menu_stb':
		case 'subscribers/campaigns':
			return true;
		default:
			return false;
	}
};

// checks if product has eligible subtype for displaing web link
export const checkProperSubtype = (subtype) => {
	let isProperSubtype = false;
	switch (subtype) {
		case 'vod':
			return (isProperSubtype = true);
		case 'series':
			return (isProperSubtype = true);
		default:
			return isProperSubtype;
	}
};

// convert dashboard subtype to web subtype (movies instead of vod)
// may need additional choices in future
export const convertToWebSubtype = (subtype) => {
	switch (subtype) {
		case 'vod':
			return 'movies';

		default:
			return subtype;
	}
};

export const translateSubtypes = (subtype) => {
	switch (subtype) {
		case 'vod':
			return 'filmy';
		case 'series':
			return 'seriale';
		case 'season':
			return 'sezon';
		case 'episode':
			return 'odcinek';

		default:
			break;
	}
};
