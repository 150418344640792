import { configureStore } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

// Import reducers
import reducers from './reducers';

// Import queries
import ip from 'store/query/ip';

// Import variables
import { COOKIE_USER_TOKEN } from 'components/helpers/variables';

export default configureStore({
	reducer: reducers,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(ip.middleware),
	preloadedState: {
		auth: {
			authenticate: { roles: [], user: null, currentSystem: null },
			errorMessage: false,
			signOutRedirect: false,
			isAuthenticated: Boolean(Cookies.get(COOKIE_USER_TOKEN)),
			isDetailsLoading: true,
			signOut: {
				loading: false,
				error: false
			},
			resetPassword: {
				checkHash: {
					isHashValid: false,
					loading: true
				},
				sendResetEmail: {
					loading: false,
					isSuccess: false,
					error: false
				},
				setNewPassword: {
					isSuccess: false,
					loading: false
				}
			}
		}
	}
});
