// Import translation
import i18n from 'i18next';

export const defaultFieldValues = {
	logins: []
};

export const BASE_FIELDS = [
	{
		name: 'logins',
		label: i18n.t('promotions:form:fields.logins'),
		type: 'textarea',
		required: false,
		fieldInfo: i18n.t('promotions:form:fields_info.logins')
	}
];
