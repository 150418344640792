import { connect } from 'react-redux';
import {
	fetchPromotionInForm,
	fetchPromotionInProductsList,
	fetchPromotionOutForm,
	fetchPromotionOutProductsList
} from 'store/actions';

// Import helpers
import { PROMOTION_PRODUCTS_IN } from 'components/helpers/variables';

/**
 * Function that passes proper render props to generic components based on product type (IN | OUT)
 * It passes actions and store values needed by children components
 */
function PromotionProductsWrapper({
	fetchPromotionInForm,
	fetchPromotionInProductsList,
	fetchPromotionOutForm,
	fetchPromotionOutProductsList,
	products_list_out,
	products_form_out,
	products_list_in,
	products_form_in,
	productType,
	children
}) {
	// check if product type is in or out
	const isProductIn = productType === PROMOTION_PRODUCTS_IN;

	// Objects with promotion products actions
	const productInActions = {
		fetchListAction: fetchPromotionInProductsList,
		fetchFormAction: fetchPromotionInForm
	};
	const productOutActions = {
		fetchListAction: fetchPromotionOutProductsList,
		fetchFormAction: fetchPromotionOutForm
	};

	// Objects with promotion products store
	const productInStore = {
		productListStore: products_list_in,
		productFormStore: products_form_in
	};
	const productOutStore = {
		productListStore: products_list_out,
		productFormStore: products_form_out
	};

	// bundle object that passes action and store based on product type
	const childrenActionAndStore = {
		childActions: isProductIn ? productInActions : productOutActions,
		childStore: isProductIn ? productInStore : productOutStore
	};

	return children(childrenActionAndStore, productType);
}

const mapStateToProps = ({
	promotions: {
		promotions_products: {
			products_list_out,
			products_form_out,
			products_list_in,
			products_form_in
		}
	}
}) => {
	return {
		products_list_out: {
			data: products_list_out.data,
			isLoaded: products_list_out.isLoaded,
			error: products_list_out.error
		},
		products_form_out: {
			initialValues: products_form_out.edit,
			error: products_form_out.error,
			isLoaded: products_form_out.isLoaded
		},
		products_list_in: {
			data: products_list_in.data,
			isLoaded: products_list_in.isLoaded,
			error: products_list_in.error
		},
		products_form_in: {
			initialValues: products_form_in.edit,
			error: products_form_in.error,
			isLoaded: products_form_in.isLoaded
		}
	};
};

export default connect(mapStateToProps, {
	fetchPromotionInForm,
	fetchPromotionInProductsList,
	fetchPromotionOutForm,
	fetchPromotionOutProductsList
})(PromotionProductsWrapper);
