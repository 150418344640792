import styled from 'styled-components';
import { SyncOutlined } from '@ant-design/icons';

export const TableWrapper = styled.fieldset`
	width: 100%;
`;

export const SyncButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 2rem;
`;

export const StyledSyncOutlined = styled(SyncOutlined)`
	color: white;
	font-size: 1.5rem;
	margin-right: 1rem;
	display: flex;
	align-items: center;
`;
