import React from 'react';
import i18n from 'i18next';

// Import components
import OperatorsForm from '../../operators_form/OperatorsForm';
import ActivityOperator from '../../activity_operator/ActivityOperator';

export const operatorsTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <OperatorsForm />
	},
	{
		name: i18n.t('common:tabs.activity'),
		path: 'activity_operator',
		disableOnCreate: true,
		component: <ActivityOperator />
	}
];
