// Import translation
import i18n from 'i18next';

export const providers = {
	title: i18n.t('common:products_tabs.provider'),
	apiUrl: `/products/providers/list?`,
	type: 'providerSystem'
};

export const channels = {
	title: i18n.t('common:products_tabs.live'),
	apiUrl: `/channel/list?`,
	type: 'channelSystem'
};
