import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import utilites
import { renderFields } from 'components/utilities/form';
import InfoTitle from 'components/utilities/info/InfoTitle';

// Import fields
import { BASE_FIELDS, SENDING_METHODS_FIELDS } from './input_fields';

const Fields = ({ submitting, platforms, systems }) => {
	const { t } = useTranslation();

	const selectResources = {
		platforms,
		systems
	};

	return (
		<React.Fragment>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
			<div className="hr-line-solid">&nbsp;</div>
			<InfoTitle
				title={t('notifications:form.sending_ways_info')}
				className="mb-4"
			/>
			{SENDING_METHODS_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	platforms: PropTypes.array.isRequired,
	systems: PropTypes.array.isRequired
};

export default Fields;
