import i18n from 'i18next';

export const BASE_FIELDS = [
	{
		name: 'device_sn_id',
		label: i18n.t('subscribers:devices_sn.form.fields.device_sn_id'),
		placeholder: i18n.t('subscribers:devices_sn.form.fields.device_sn_id'),
		type: 'fetch-select',
		id: 'device_sn_id',
		required: true,
		url: '/subscriber/serialnumbers/list',
		selectName: 'serial_number',
		selectValue: 'id'
	}
];
