import React, { useContext } from 'react';

// Imports components
import { Table } from 'antd';
import { VerificationContext } from 'components/views/subscribers/subscribers_verification/VerificationContext';

import { createColumns } from 'components/views/subscribers/subscribers_verification/helpers/document_details_column';

const DocumentDetails = () => {
	const { fileName, extension, status, verifiedAt, verifiedBy } = useContext(
		VerificationContext
	);

	const dataSource = [{ fileName, extension, status, verifiedAt, verifiedBy }];

	return (
		<Table
			columns={createColumns}
			bordered
			dataSource={dataSource}
			pagination={false}
			size="small"
		/>
	);
};

export default DocumentDetails;
