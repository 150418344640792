import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { signOut } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { Button } from 'reactstrap';

const SignOut = ({ signOut }) => {
	const { t } = useTranslation();
	// Dispatch an  signOut action in auth folder
	const handleLogOut = () => signOut();
	return (
		<Button onClick={handleLogOut} className="btn__signout m-l-md">
			<i className="fa fa-sign-out" /> {t('login:sign_up.sign_out')}
		</Button>
	);
};

SignOut.propTypes = {
	signOut: PropTypes.func.isRequired
};

export default connect(null, { signOut })(SignOut);
