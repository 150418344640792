import React from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import helpers
import {
	FETCH_AGREEMENT_METADATA_API,
	FETCH_AGREEMENT_VERSION_API
} from 'components/helpers/api';

// Import utilities
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';
import { messageInfo } from 'components/utilities/message';

// Import Components
import { Button } from 'antd';

export const createColumns = () => {
	return [
		{
			Header: i18n.t('subscribers:consents.table_columns.agreement_version_id'),
			accessor: 'agreement_version_id',
			sortable: true,
			headerClassName: 'text-center',
			className: 'text-center',
			Cell: ({
				row: {
					_original: { agreement_version_id, agreement_id }
				}
			}) => {
				const url = FETCH_AGREEMENT_VERSION_API.replace(
					':agreement_id',
					agreement_id
				).replace(':agreement_version_id', agreement_version_id);

				return <Link to={url}>{agreement_version_id}</Link>;
			}
		},
		{
			Header: i18n.t('subscribers:consents.table_columns.agreement_id'),
			accessor: 'agreement_id',
			sortable: true,
			headerClassName: 'text-center',
			className: 'text-center',
			Cell: ({
				row: {
					_original: { agreement_id }
				}
			}) => {
				const url = FETCH_AGREEMENT_METADATA_API.replace(
					':agreement_id',
					agreement_id
				);
				return <Link to={url}>{agreement_id}</Link>;
			}
		},
		{
			Header: i18n.t('subscribers:consents.table_columns.agreement_type'),
			accessor: 'type',
			sortable: true,
			headerClassName: 'text-center',
			className: 'text-center'
		},
		{
			Header: i18n.t('subscribers:consents.table_columns.confirmed'),
			accessor: 'confirmed',
			sortable: true,
			headerClassName: 'text-center',
			className: 'text-center',
			Cell: ({
				row: {
					_original: { confirmed }
				}
			}) => <ActiveIcon active={confirmed} />
		},
		{
			Header: i18n.t('subscribers:consents.table_columns.created_at'),
			accessor: 'created_at',
			sortable: true,
			headerClassName: 'text-center',
			className: 'text-center'
		},
		{
			Header: i18n.t('subscribers:consents.table_columns.updated_at'),
			accessor: 'updated_at',
			sortable: true,
			headerClassName: 'text-center',
			className: 'text-center'
		},
		{
			Header: i18n.t('subscribers:consents.table_columns.agreement_content'),
			accessor: 'description',
			sortable: true,
			headerClassName: 'text-center',
			className: 'text-center',
			Cell: ({
				row: {
					_original: { action, description }
				}
			}) => {
				const content = (
					<span dangerouslySetInnerHTML={{ __html: parse(description) }}></span>
				);

				const onClickHandler = () =>
					messageInfo({
						title: action,
						message: content,
						type: 'info',
						width: '600px'
					});

				return (
					<Button type="primary" onClick={onClickHandler}>
						{i18n.t(
							'subscribers:consents.table_columns.agreement_content_show'
						)}
					</Button>
				);
			}
		}
	];
};
