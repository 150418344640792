import services from 'services/services';
import * as types from '../types';
import mime from 'mime-types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';
import {
	FETCH_SUBSCRIBER_VERIFICATION_DOCUMENT,
	FETCH_SUBSCRIBER_VERIFY,
	FETCH_SUBSCRIBER_UNVERIFY,
	FETCH_SUBSCRIBER_VERIFICATION_DOCUMENT_DETAILS
} from 'components/helpers/api';
import { VERIFICATION } from 'components/helpers/variables';

// ******************** FETCH SUBSCRIBER'S DOCUMENT ********************
export const fetchSubscriberDocument = (id) => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_SUBSCRIBER_DOCUMENT_LOADING,
			payload: true
		});

		const { data: documentDetails } = await services.get(
			FETCH_SUBSCRIBER_VERIFICATION_DOCUMENT_DETAILS.replace(':id', id)
		);

		const status = documentDetails.identity_verification_state;

		if (status === VERIFICATION.IN_PROGRESS) {
			// Download a document
			const response = await services.get(
				FETCH_SUBSCRIBER_VERIFICATION_DOCUMENT.replace(':id', id),
				{ responseType: 'blob' }
			);

			if (response.data) {
				const extension = mime.extension(response.headers?.['content-type']);
				const fileName = `${id}_verification_document.${extension}`;

				const file = new Blob([response.data], {
					type: response.headers?.['content-type']
				});
				const url = URL.createObjectURL(file);

				dispatch({
					type: types.FETCH_SUBSCRIBER_DOCUMENT_SUCCESS,
					payload: {
						document: url,
						fileName,
						extension,
						status
					}
				});
			} else {
				// Lack of any document is also a success
				dispatch({
					type: types.FETCH_SUBSCRIBER_DOCUMENT_SUCCESS,
					payload: {
						document: null,
						fileName: '',
						extension: '',
						status
					}
				});
			}
		} else {
			dispatch({
				type: types.FETCH_SUBSCRIBER_VERIFICATION_DOCUMENT_DETAILS_SUCCESS,
				payload: {
					status,
					fileName: documentDetails.file,
					extension: documentDetails.extension,
					verifiedAt: documentDetails.identity_verified_at,
					verifiedBy: documentDetails.identity_verified_by
				}
			});
		}
	} catch (error) {
		if (error.response && error.response.status === 404) {
			// 404 means there is no document uploaded by this user, which is still allowed
			dispatch({
				type: types.FETCH_SUBSCRIBER_DOCUMENT_SUCCESS,
				payload: {
					document: null,
					fileName: '',
					extension: '',
					status: VERIFICATION.NOTHING_TO_VERIFY
				}
			});
		} else {
			dispatch({
				type: types.FETCH_SUBSCRIBER_DOCUMENT_ERROR
			});
		}
	}
};

// ******************** VERIFY SUBSCRIBER'S DOCUMENT ********************
export const verifySubscriber = (id) => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_SUBSCRIBER_DOCUMENT_VERIFY_LOADING,
			payload: true
		});

		const { data } = await services.put(
			FETCH_SUBSCRIBER_VERIFY.replace(':id', id)
		);

		dispatch({
			type: types.FETCH_SUBSCRIBER_DOCUMENT_VERIFY_SUCCESS,
			payload: data?.success
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SUBSCRIBER_DOCUMENT_VERIFY_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** UNVERIFY SUBSCRIBER'S DOCUMENT ********************
export const unverifySubscriber = (id) => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_SUBSCRIBER_DOCUMENT_UNVERIFY_LOADING,
			payload: true
		});

		const { data } = await services.put(
			FETCH_SUBSCRIBER_UNVERIFY.replace(':id', id)
		);

		dispatch({
			type: types.FETCH_SUBSCRIBER_DOCUMENT_UNVERIFY_SUCCESS,
			payload: data?.success
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SUBSCRIBER_DOCUMENT_UNVERIFY_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
