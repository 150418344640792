import React from 'react';
import PropTypes from 'prop-types';

// Import utils
import { renderFields } from 'components/utilities/form';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import helpers
import { MEDIA_TYPES_VALUES, MESSAGE_TYPES_VALUES } from 'components/helpers';

const Fields = ({ submitting, messageType, isEdit }) => {
	const selectResources = {
		message_type: MESSAGE_TYPES_VALUES,
		media_type: MEDIA_TYPES_VALUES
	};

	return (
		<React.Fragment>
			<div className="hr-line-solid">&nbsp;</div>
			{BASE_FIELDS({ messageType, isEdit }).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	messageType: PropTypes.string,
	isEdit: PropTypes.bool
};

export default Fields;
