export const checkAssignedProducts = ({ products, assignedProducts }) => {
	const newAssignedProducts = assignedProducts.map((product) => {
		const { product_title, product_uuid } = product;
		const title = product_title ?? product_uuid;
		const uuid = product_uuid;

		return { ...product, title, uuid, assigned: true };
	});

	const newProducts = products.map((product) => {
		const isProductAssigned = assignedProducts.some(
			(assignedProduct) => assignedProduct.product_uuid === product.uuid
		);

		if (isProductAssigned) {
			return { ...product, assigned: true };
		}

		return { ...product, assigned: false };
	});

	const result = [...newProducts, ...newAssignedProducts];

	// Remove duplicates
	return [...new Set(result.map((a) => a.uuid))].map((id) =>
		result.find(({ uuid }) => uuid === id)
	);
};
