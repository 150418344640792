// ******************** PROMOTIONS DATA TABLE ********************
export const FETCH_PROMOTIONS_LOADING = 'FETCH_PROMOTIONS_LOADING';
export const FETCH_PROMOTIONS_SUCCESS = 'FETCH_PROMOTIONS_SUCCESS';
export const FETCH_PROMOTIONS_ERROR = 'FETCH_PROMOTIONS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_PROMOTIONS_SEARCH_QUERY = 'SET_PROMOTIONS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_PROMOTIONS_TABLE_SORTING = 'SAVE_PROMOTIONS_TABLE_SORTING';

// ******************** DELETE PROMOTION ********************
export const DELETE_PROMOTION_LOADING = 'DELETE_PROMOTION_LOADING';
export const DELETE_PROMOTION_SUCCESS = 'DELETE_PROMOTION_SUCCESS';
export const DELETE_PROMOTION_ERROR = 'DELETE_PROMOTION_ERROR';

// ******************** FETCH PROMOTION ********************
export const FETCH_PROMOTION_LOADING = 'FETCH_PROMOTION_LOADING';
export const FETCH_PROMOTION_SUCCESS = 'FETCH_PROMOTION_SUCCESS';
export const FETCH_PROMOTION_ERROR = 'FETCH_PROMOTION_ERROR';

// ******************** FETCH PROMOTION USERS LIST ********************
export const FETCH_PROMOTION_USERS_LIST_LOADING =
	'FETCH_PROMOTION_USERS_LIST_LOADING';
export const FETCH_PROMOTION_USERS_LIST_SUCCESS =
	'FETCH_PROMOTION_USERS_LIST_SUCCESS';
export const FETCH_PROMOTION_USERS_LIST_ERROR =
	'FETCH_PROMOTION_USERS_LIST_ERROR';

// ******************** FETCH PROMOTION_OUT_PRODUCTS LIST ********************
export const FETCH_PROMOTION_OUT_PRODUCTS_LIST_LOADING =
	'FETCH_PROMOTION_OUT_PRODUCTS_LIST_LOADING';
export const FETCH_PROMOTION_OUT_PRODUCTS_LIST_SUCCESS =
	'FETCH_PROMOTION_OUT_PRODUCTS_LIST_SUCCESS';
export const FETCH_PROMOTION_OUT_PRODUCTS_LIST_ERROR =
	'FETCH_PROMOTION_OUT_PRODUCTS_LIST_ERROR';

// ******************** FETCH PROMOTION_OUT_PRODUCTS FORM ********************
export const FETCH_PROMOTION_OUT_PRODUCTS_FORM_LOADING =
	'FETCH_PROMOTION_OUT_PRODUCTS_FORM_LOADING';
export const FETCH_PROMOTION_OUT_PRODUCTS_FORM_SUCCESS =
	'FETCH_PROMOTION_OUT_PRODUCTS_FORM_SUCCESS';
export const FETCH_PROMOTION_OUT_PRODUCTS_FORM_ERROR =
	'FETCH_PROMOTION_OUT_PRODUCTS_FORM_ERROR';

// ******************** FETCH PROMOTION_IN_PRODUCTS LIST ********************
export const FETCH_PROMOTION_IN_PRODUCTS_LIST_LOADING =
	'FETCH_PROMOTION_IN_PRODUCTS_LIST_LOADING';
export const FETCH_PROMOTION_IN_PRODUCTS_LIST_SUCCESS =
	'FETCH_PROMOTION_IN_PRODUCTS_LIST_SUCCESS';
export const FETCH_PROMOTION_IN_PRODUCTS_LIST_ERROR =
	'FETCH_PROMOTION_IN_PRODUCTS_LIST_ERROR';

// ******************** FETCH PROMOTION_IN_PRODUCTS FORM ********************
export const FETCH_PROMOTION_IN_PRODUCTS_FORM_LOADING =
	'FETCH_PROMOTION_IN_PRODUCTS_FORM_LOADING';
export const FETCH_PROMOTION_IN_PRODUCTS_FORM_SUCCESS =
	'FETCH_PROMOTION_IN_PRODUCTS_FORM_SUCCESS';
export const FETCH_PROMOTION_IN_PRODUCTS_FORM_ERROR =
	'FETCH_PROMOTION_IN_PRODUCTS_FORM_ERROR';

// ******************** FETCH PROMOTION PACKETS LIST ********************
export const FETCH_PROMOTION_PACKETS_LIST_LOADING =
	'FETCH_PROMOTION_PACKETS_LIST_LOADING';
export const FETCH_PROMOTION_PACKETS_LIST_SUCCESS =
	'FETCH_PROMOTION_PACKETS_LIST_SUCCESS';
export const FETCH_PROMOTION_PACKETS_LIST_ERROR =
	'FETCH_PROMOTION_PACKETS_LIST_ERROR';
