// API
export const GET_STATISTICS_PROVIDERS_API = '/stats/providers';
export const GET_STATISTICS_PRODUCTISATION_API = '/stats/productisation';
export const GET_STATISTICS_CATCHUP_API = '/stats/catchup';

// Queries
export const QUERY_STATISTICS_PROVIDERS_DATA =
	'QUERY_STATISTICS_PROVIDERS_DATA';
export const QUERY_STATISTICS_PRODUCTISATION_DATA =
	'QUERY_STATISTICS_PRODUCTISATION_DATA';
export const QUERY_STATISTICS_CATCHUP_DATA = 'QUERY_STATISTICS_CATCHUP_DATA';
