import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	setVodPacketsAssignedQuery,
	fetchVodPacketsAssigned
} from 'store/actions';

// Import columns
import { createColumns } from './vod_packets_assigned_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';

const VodPacketsAssigned = ({
	error,
	setVodPacketsAssignedQuery,
	fetchVodPacketsAssigned,
	resources,
	match: {
		params: { id }
	}
}) => {
	return (
		<PanelPageTemplate
			itemId={id}
			title="Lista"
			error={error}
			type="packets_assigned"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchVodPacketsAssigned}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setVodPacketsAssignedQuery}
					value={resources.options.filters.query}
				/>
			</div>
		</PanelPageTemplate>
	);
};

VodPacketsAssigned.propTypes = {
	error: PropTypes.bool.isRequired,
	setVodPacketsAssignedQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchVodPacketsAssigned: PropTypes.func.isRequired
};

const mapStateToProps = ({ vod }) => {
	return {
		error: vod.packets_assigned.error,
		resources: vod.packets_assigned
	};
};

export default compose(
	connect(mapStateToProps, {
		setVodPacketsAssignedQuery,
		fetchVodPacketsAssigned
	}),
	withRouter
)(VodPacketsAssigned);
