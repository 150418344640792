import React from 'react';
import PropTypes from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utils
import { renderFields } from 'components/utilities/form';

import { BASE_FILEDS } from './input_fields';

const Fields = ({ submitting, categories: selectResources, childId }) => {
	const { t } = useTranslation();

	const resources = selectResources
		.filter((item) => item.id !== childId)
		.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
		.map((item) => ({ name: item.name, value: item.id }));
	resources.unshift({
		name: t('categories:fields.parentId'),
		value: '',
		disabled: true
	});
	return (
		<React.Fragment>
			{BASE_FILEDS.map((item, index) =>
				renderFields({
					item,
					index,
					submitting,
					selectResources: {
						parentId: resources
					}
				})
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	categories: PropTypes.array.isRequired,
	childId: PropTypes.string.isRequired
};

export default Fields;
