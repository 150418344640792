import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { Button } from 'antd';
import { Col, FormGroup } from 'reactstrap';

// Import translation
import { useTranslation } from 'react-i18next';

/**
 * Functional component rendering submit and cancel buttons for filter drawer form
 * @param {func} handleOnCancel - function handling canceling filter form
 * @param {bool} submitting - function handling canceling filter form
 * @returns {*}
 */
const FilterFormButtons = ({ handleOnCancel, handleClear, submitting }) => {
	const { t } = useTranslation();
	return (
		<FormGroup row className="m-t-md">
			<Col className="d-flex justify-content-start">
				<Button onClick={handleClear}>
					{t('filters:buttons.clear_filters')}
				</Button>
			</Col>
			<Col xs="3" className="d-flex justify-content-end">
				<Button
					className="m-r-sm"
					type="default"
					htmlType="button"
					onClick={handleOnCancel}
				>
					{t('common:buttons.cancel')}
				</Button>
			</Col>
			<Col xs="2" className="d-flex justify-content-end">
				<Button
					disabled={submitting}
					type="primary"
					htmlType="submit"
					loading={submitting}
				>
					{t('common:buttons.submit')}
				</Button>
			</Col>
		</FormGroup>
	);
};

FilterFormButtons.propTypes = {
	handleOnCancel: PropTypes.func.isRequired,
	handleClear: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired
};

export default FilterFormButtons;
