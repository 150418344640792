import i18n from 'i18next';

export const SUBSCRIBERS_FILTER_FIELDS = [
	{
		name: 'login',
		label: i18n.t('filters:fields.login'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'business_id',
		label: i18n.t('filters:fields.business_id'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'source',
		label: i18n.t('filters:fields.source'),
		type: 'custom-names',
		required: false,
		mode: 'multiple',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'system',
		label: i18n.t('filters:fields.system'),
		type: 'custom-names',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'last_login',
		label: i18n.t('filters:fields.last_login'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	},
	{
		name: 'created_at',
		label: i18n.t('filters:fields.created_at'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	},
	{
		name: 'updated_at',
		label: i18n.t('filters:fields.updated_at'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	}
];
