import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH DEVICES - TABLE DATA ********************
export const fetchDevicesSn = (options) => async (dispatch, getState) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_DEVICES_SN_LOADING,
			payload: true
		});

		const {
			devices_sn: {
				table: {
					columns,
					options: { startIndex: startTableIndex, page }
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		let url = `/subscriber/serialnumbers/list?${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex,
			true
		)}`;

		const { data } = await services.get(url);
		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_DEVICES_SN_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_DEVICES_SN_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET SEARCH QUERY ********************
export const setDevicesSnQuery = (query) => ({
	type: types.SET_DEVICES_SN_SEARCH_QUERY,
	payload: query
});
