import React from 'react';
import {
	string,
	number,
	func,
	oneOfType,
	node,
	arrayOf,
	shape,
	bool
} from 'prop-types';

// Import hooks
import useCSVUploader from './helpers/useCSVUploader';

import { Upload, Button } from 'antd';

import { FileTextOutlined } from '@ant-design/icons';
import { Col } from 'reactstrap';

const CSVUploader = ({
	children,
	input: { onChange: setFileState },
	id,
	inputColumn,
	meta: { submitSucceeded, callBackFn, isLoading },
	...inputProps
}) => {
	const { uploadProps } = useCSVUploader({
		setFileState,
		submitSucceeded,
		callBackFn
	});
	return (
		<Col sm={inputColumn}>
			<Upload {...uploadProps} {...inputProps} accept={inputProps?.fileType}>
				<Button icon={<FileTextOutlined />} loading={isLoading}>
					{inputProps?.placeholder}
				</Button>
			</Upload>
		</Col>
	);
};

CSVUploader.defaultProps = {
	inputColumn: 10
};

CSVUploader.propTypes = {
	input: shape({
		onChange: func.isRequired
	}),
	id: string.isRequired,
	inputColumn: number,
	children: oneOfType([node, arrayOf(node)]),
	meta: shape({
		submitSucceeded: bool,
		callBackFn: func.isRequired,
		isLoading: bool
	})
};

export default CSVUploader;
