import i18n from 'i18next';

export const MARKETING_CAMPAIGNS_TARGET_FILTER_FIELDS = [
	{
		name: 'subscriber_id',
		label: i18n.t('filters:fields.subscriber_id'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'external_id',
		label: i18n.t('filters:fields.external_id'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'status',
		label: i18n.t('filters:fields.status'),
		type: 'select',
		required: false,
		showSearch: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'target_type',
		label: i18n.t('filters:fields.type'),
		type: 'select',
		required: false,
		showSearch: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'target_system',
		label: i18n.t('filters:fields.system'),
		type: 'select',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'target_platforms',
		label: i18n.t('filters:fields.platform'),
		type: 'select',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'notification_read_status',
		label: i18n.t(
			'marketing_campaigns:targets:columns.notification_read_status'
		),
		type: 'checkbox',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'message_read_status',
		label: i18n.t('marketing_campaigns:targets:columns.message_read_status'),
		type: 'checkbox',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	}
];
