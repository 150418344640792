import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Validate
import validate from './validation';

// Import components
import Fields from './fields/Fields';
import { FormButtons } from 'components/common/buttons';
import { notificationHandler } from 'components/utilities/notifications';
import { IboxContentTitle } from 'components/common/layout';

// Import sevices
import services from 'services/services';

// Import variables
import { TOPOLOGY_FILES } from 'components/helpers/variables';

const TopologyUpload = ({ onSuccessUpload }) => {
	const { t } = useTranslation();

	const handleOnSubmit = () => async (values) => {
		// Submit the form with field values
		let formData = new FormData();
		formData.append('topology', values.topology_file[0]);

		try {
			await services.post('cognito/topology/upload', formData);
			notificationHandler(
				t('messages:notifications.edited'),
				`${t('messages:notifications.successfuly_edited')} ${t(
					'names:notification_names.topology'
				)}`
			);

			window.scrollTo(0, 0);
			onSuccessUpload(TOPOLOGY_FILES.TOPOLOGY);
		} catch (error) {
			const {
				response: {
					data: { errors }
				}
			} = error;

			const message = errors.join(', ');

			notificationHandler(t('common:error'), message, 'error', '20000');
		}
	};

	return (
		<IboxContentTitle title={t('topology:tabs.upload_topology')}>
			<Form
				onSubmit={handleOnSubmit()}
				validate={validate}
				render={({ handleSubmit, submitting }) => (
					<form onSubmit={handleSubmit}>
						<Fields submitting={submitting} />

						<FormButtons
							isButtonDisabled={submitting}
							buttonText={t('common:buttons.submit')}
						/>
					</form>
				)}
			/>
		</IboxContentTitle>
	);
};

TopologyUpload.propTypes = {
	onSuccessUpload: PropTypes.func.isRequired
};

export default TopologyUpload;
