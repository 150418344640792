import React from 'react';
import { NavLink } from 'react-router-dom';

const NavLinkItem = ({ link }) => (
	<li className="nav__item">
		<NavLink
			activeClassName="nav__link--active"
			className="nav__link"
			to={link.path}
		>
			<i className={link.icon} />
			<span className="nav-label">{link.name}</span>
		</NavLink>
	</li>
);

export default NavLinkItem;
