import React from 'react';
import i18n from 'i18next';

// Import components
import LimitsForm from '../../limits_form/LimitsForm';
import LimitsProducts from '../../limits_products/LimitsProducts';
import SystemSettingsForm from 'components/utilities/system_settings/SystemSettingsForm';

export const limitsTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <LimitsForm />
	},
	{
		name: i18n.t('common:tabs.system_settings'),
		path: 'system_settings',
		disableOnCreate: true,
		component: <SystemSettingsForm />
	},
	{
		name: i18n.t('common:tabs.products'),
		path: 'products',
		disableOnCreate: true,
		component: <LimitsProducts />
	}
];
