import i18n from 'i18next';

export const LIMITS_SYSTEM_SETTINGS_FIELDS = {
	defaultFieldValues: {
		active: false
	},
	fields: [
		{
			name: 'active',
			label: i18n.t('common:form.active'),
			type: 'checkbox'
		}
	]
};
