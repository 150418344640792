// ******************** COGNITO_PROVIDERS DATA TABLE ********************
// -------------------- FETCH COGNITO PROVIDER TABLE DATA --------------------
export const FETCH_COGNITO_PROVIDERS_LOADING =
	'FETCH_COGNITO_PROVIDERS_LOADING';
export const FETCH_COGNITO_PROVIDERS_SUCCESS =
	'FETCH_COGNITO_PROVIDERS_SUCCESS';
export const FETCH_COGNITO_PROVIDERS_ERROR = 'FETCH_COGNITO_PROVIDERS_ERROR';
// -------------------- DELETE COGNITO PROVIDER --------------------
export const DELETE_COGNITO_PROVIDER_LOADING =
	'DELETE_COGNITO_PROVIDER_LOADING';
export const DELETE_COGNITO_PROVIDER_SUCCESS =
	'DELETE_COGNITO_PROVIDER_SUCCESS';
export const DELETE_COGNITO_PROVIDER_ERROR = 'DELETE_COGNITO_PROVIDER_ERROR';

// ******************** FORM ********************
// -------------------- FETCH COGNITO PROVIDER --------------------
export const FETCH_COGNITO_PROVIDER_LOADING = 'FETCH_COGNITO_PROVIDER_LOADING';
export const FETCH_COGNITO_PROVIDER_SUCCESS = 'FETCH_COGNITO_PROVIDER_SUCCESS';
export const FETCH_COGNITO_PROVIDER_ERROR = 'FETCH_COGNITO_PROVIDER_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_COGNITO_PROVIDERS_SEARCH_QUERY =
	'SET_COGNITO_PROVIDERS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_COGNITO_PROVIDERS_TABLE_SORTING =
	'SAVE_COGNITO_PROVIDERS_TABLE_SORTING';
