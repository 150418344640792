// function determines if nav item should be rendered based on user roles and his system
export const isNavItemRendered = ({
	userRoles,
	link,
	currentSystem,
	systemTreeDepth
}) => {
	const { roles, allowedSystems, maxSystemTreeDepth = 3 } = link;

	const isProperSystem = allowedSystems?.some(
		(system) => system === currentSystem
	);

	// If array of allowedSystems is not passed, any system is allowed,
	// If array has some systems, we have to check if currentSystem is in allowedSystems
	const isAllowedSystem = !allowedSystems || isProperSystem;

	const isProperRoles = userRoles.some((role) => roles.includes(role));

	const isAllowedMaxSystemTreeDepth = systemTreeDepth <= maxSystemTreeDepth;

	const isProperRolesAndSystem =
		isProperRoles && isAllowedSystem && isAllowedMaxSystemTreeDepth;

	return isProperRolesAndSystem;
};
