export const convertMainToValuePairs = (resources) => {
	return resources.map(({ title, uuid }) => ({
		name: title,
		value: uuid
	}));
};

export const convertEntriesToValuePairs = (resources) => {
	return resources.map((item) => ({
		name: item[0],
		value: item[1]
	}));
};
