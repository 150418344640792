import React from 'react';
import { bool } from 'prop-types';

// Import utilities
import { renderFields } from 'components/utilities/form';

import { BASE_FIELDS } from './input_fields';

const Fields = ({ submitting, values, metaData }) => (
	<>
		{BASE_FIELDS({ values, metaData }).map((item, index) =>
			renderFields({ item, index, submitting, metaData })
		)}
	</>
);

Fields.propTypes = {
	submitting: bool.isRequired
};

export default Fields;
