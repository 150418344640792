import React from 'react';
import Tabs from 'components/utilities/tabs/Tabs';

// Import helpers
import { promotionsTabs } from './helpers/index';

// Import hooks
import usePromotionsTabs from './usePromotionsTabs';

const PromotionsTabs = () => {
	const { isCreateTab, checkIsTabDisabled } = usePromotionsTabs();

	return <Tabs tabs={promotionsTabs({ isCreateTab, checkIsTabDisabled })} />;
};

export default PromotionsTabs;
