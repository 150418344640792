import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';
import {
	escapeQuotes,
	convertCSVData
} from 'store/actions/helpers_actions/convert_helpers';

// ******************** FETCH CSV DATA ********************
export const getCSVData = (url) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.GET_CSV_DATA_LOADING,
			payload: true
		});

		const {
			data: { data }
		} = await services.get(url);

		const csvData = data?.data ?? data;
		const convertedData = convertCSVData(csvData);
		const escapedQuotesData = escapeQuotes(convertedData);

		dispatch({
			type: types.GET_CSV_DATA_SUCCESS,
			payload: escapedQuotesData
		});
	} catch (error) {
		dispatch({
			type: types.GET_CSV_DATA_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** CLEAR CSV DATA ********************
export const clearCSVData = () => (dispatch) => {
	return dispatch({
		type: types.CLEAR_CSV_DATA
	});
};
