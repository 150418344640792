// Import hooks
import useAuthSystemCheck from 'hooks/useAuthSystemCheck';

// Import variables
import { CORE_PATHS } from 'components/helpers/variables';

const { LIMITS, CATEGORIES } = CORE_PATHS;

const useDeleteButton = ({ products, productSystem, sourceSystem }) => {
	const {
		checkUserCanEditProduct,
		currentSystem,
		locationType,
		isCurrentSystemMain
	} = useAuthSystemCheck();

	const isProductsEmpty = products === 0;

	const isLimitsLocation = locationType === LIMITS;
	const isCategoriesLocation = locationType === CATEGORIES;

	const checkSourceSystem = () => {
		// If source system is other than product system means that the product has been inherited
		// We can't delete inherited products
		const isProperSystem = sourceSystem === productSystem;

		return !sourceSystem || isProperSystem;
	};

	const checkUserDeletePermission = () => {
		if (!isProductsEmpty) {
			const isProperLocation = isLimitsLocation || isCategoriesLocation;

			const canUserEditProduct = checkUserCanEditProduct({
				currentSystem,
				productSystem
			});

			const canUserDeleteProduct = canUserEditProduct && checkSourceSystem();

			return isProperLocation ? isCurrentSystemMain : canUserDeleteProduct;
		}

		return false;
	};

	return { canUserDeleteProduct: checkUserDeletePermission() };
};

export default useDeleteButton;
