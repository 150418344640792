import React from 'react';
import PropTypes from 'prop-types';

/**
 * Functional component that renders tick or cross in table columns.
 * Based on active value
 * @param {bool} active - value passed from table data
 */
function ActiveIcon({ active }) {
	return active ? (
		<i
			className="fa fa-check"
			style={{
				fontSize: '16px',
				color: '#13ce66'
			}}
			aria-hidden="true"
		></i>
	) : (
		<i
			className="fa fa-times"
			style={{
				fontSize: '16px',
				color: '#676a6c'
			}}
			aria-hidden="true"
		></i>
	);
}

ActiveIcon.propTypes = {
	active: PropTypes.oneOfType([
		PropTypes.bool.isRequired,
		PropTypes.number.isRequired
	])
};

export default ActiveIcon;
