import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

// Import utils
import { renderFields } from 'components/utilities/form';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({ photos, submitting }) => {
	return (
		<React.Fragment>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}

			{photos && (
				<Row>
					{photos.map(({ url, type }, index) => (
						<Col key={`${type}-${index}`} xl={2} lg={3} md={4} sm={4} xs={6}>
							<img
								className="img-fluid"
								src={url}
								alt="Preview"
								style={{ marginBottom: '1rem' }}
							/>
						</Col>
					))}
				</Row>
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	photos: PropTypes.array
};

export default Fields;
