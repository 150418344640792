import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

// Import styles
import './styles/index.scss';

const ImageMetadata = ({ metadata, requirements = [] }) => {
	const { t } = useTranslation();
	const { size, rawSize } = metadata;
	const isOverRecommendedSize = rawSize > 1000000; // 1MB

	return (
		<div className="image-metadata-container">
			<div className="image-metadata-info">
				<ul className="image-metadata-requirements-list">
					{requirements.map(({ label, severity, info }) => (
						<Tooltip placement="bottom" title={info} key={label}>
							<li
								className={`image-metadata-requirements-list-item ${severity}`}
							>
								{label}
							</li>
						</Tooltip>
					))}
				</ul>
				{size && (
					<span
						className="image-metadata-size"
						style={{ color: isOverRecommendedSize ? 'orange' : 'inherit' }}
					>
						{isOverRecommendedSize
							? t('common:inputs.imageUrl.over_recommended_size', { size })
							: size}
					</span>
				)}
			</div>
		</div>
	);
};

ImageMetadata.propTypes = {
	metadata: PropTypes.object,
	requirements: PropTypes.array
};

export default React.memo(ImageMetadata);
