import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchTrackings } from 'store/actions';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import DatePickers from './DatePickers';

// Import columns
import { createColumns } from './trackings_columns';

// Import translation
import { useTranslation } from 'react-i18next';

const Trackings = ({
	error,
	resources,
	fetchTrackings,
	match: {
		params: { id }
	}
}) => {
	const { t } = useTranslation();

	return (
		<PanelPageTemplate
			itemId={id}
			title={t('common:list')}
			error={error}
			type="trackings"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchTrackings}
		>
			<DatePickers />
		</PanelPageTemplate>
	);
};

Trackings.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchTrackings: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string })
	})
};

const mapStateToProps = ({ trackings: { table } }) => ({
	error: table.error,
	resources: table
});

export default compose(
	connect(mapStateToProps, {
		fetchTrackings
	}),
	withRouter
)(Trackings);
