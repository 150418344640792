export const getPropertiesData = ({ data }) => {
	const customProperty = data?.CustomProperties?.CustomProperty;

	const customProperties = customProperty?.map((item, index) => {
		const { href, value } = getKeyNames(index);

		return {
			[href]: item.href,
			[value]: item.Value
		};
	});

	const customPropertiesReduce = customProperties.reduce((obj, item, index) => {
		const { href, value } = getKeyNames(index);

		obj[href] = item[href];

		if (item[value]) {
			obj[value] = [item[value]];
		}

		return obj;
	}, {});

	const selectedProperties = customProperties.reduce((acc, item, index) => {
		return [...acc, item[`href_${index}`]];
	}, []);

	return { customProperties: customPropertiesReduce, selectedProperties };
};

const getKeyNames = (index) => {
	const href = `href_${index}`;
	const value = `value_${index}`;

	return { href, value };
};
