import services from 'services/services';
import * as types from '../types';

// Import helpers
import { convertSystemChannelsProducts } from './helpers';

// ******************** FETCH SYSTEM CHANNELS PRODUCTS ********************
export const fetchSystemsChannelsProducts = (system) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_SYSTEM_CHANNELS_PRODUCTS_LOADING
		});

		const {
			data: { data }
		} = await services.get(`/channel/list?system=${system}`);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SYSTEM_CHANNELS_PRODUCTS_SUCCESS,
			payload: convertSystemChannelsProducts(data)
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SYSTEM_CHANNELS_PRODUCTS_ERROR
		});
	}
};
