// -----------------  FETCH SETTINGS -----------------
export const FETCH_SETTINGS_LOADING = 'FETCH_SETTINGS_LOADING';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_ERROR = 'AUTH_USER_ERROR';

// ******************** FILES ********************
// --------------------  LOGO --------------------
export const FETCH_SETTINGS_LOGO_FILES_LOADING =
	'FETCH_SETTINGS_LOGO_FILES_LOADING';
export const FETCH_SETTINGS_LOGO_FILES_SUCCESS =
	'FETCH_SETTINGS_LOGO_FILES_SUCCESS';
export const FETCH_SETTINGS_LOGO_FILES_ERROR =
	'FETCH_SETTINGS_LOGO_FILES_ERROR';
