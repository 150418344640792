import React from 'react';

// Import components
import { Collapse } from 'reactstrap';

// Import utilities
import { Toggle } from 'components/utilities';

// Import translation
import i18n from 'i18next';

const title = i18n.t('vod:productization_note.title');
const requirements = [
	i18n.t('vod:productization_note.availability'),
	i18n.t('vod:productization_note.status')
];

/**
 * Functional compontent thath returns vod productization requirements.
 * Title and list of requirements
 */
const ProductizationNote = () => {
	return (
		<Toggle>
			{({ on, toggle }) => (
				<>
					<h4 onClick={toggle} className="productization-note__header">
						<i className="fa fa-info productization-note__header__icon m-r-md"></i>
						{title}
					</h4>
					<Collapse isOpen={on} className="m-t-sm">
						<div className="productization-note">
							<ul>
								{requirements.map((requirement, index) => (
									<li className="productization-note__item" key={index}>
										{requirement}
									</li>
								))}
							</ul>
						</div>
					</Collapse>
				</>
			)}
		</Toggle>
	);
};

export default ProductizationNote;
