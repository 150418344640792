import services from 'services/services';
import * as types from '../types';

// ******************** FETCH OPERATORS ROLES ********************
export const fetchOperatorsRoles = () => async (dispatch) => {
	const { data } = await services.get(`operator/listRoles`);

	dispatch({
		type: types.FETCH_OPERATOR_ROLES,
		payload: data
	});
};
