import { combineReducers } from 'redux';
import table from './tags_table_reducer';
import form from './tags_form_reducer';
import categoriesTags from './tags_categories_reducer';

export default combineReducers({
	table,
	form,
	categoriesTags
});
