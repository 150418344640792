import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchCognitoCategory } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import logic
import useCognitoForm from './useCognitoForm';

// Import helpers
import { convertFormValues, getIsEmptyText } from './helpers';

// Import hooks
import useSelectSystem from 'components/layouts/top_header/SelectSystem/useSelectSystem';

const CognitoForm = ({
	history,
	fetchCognitoCategory,
	error,
	isLoaded,
	initialValues
}) => {
	const { t } = useTranslation();
	const { currentSystem } = useSelectSystem();

	const {
		customProperties,
		customPropertiesValues,
		parent_uuid,
		section_id,
		initialSectionId,
		redirectPath,
		sectionUrl,
		parentSystem
	} = useCognitoForm({
		initialValues,
		fetchCognitoCategory
	});

	const { active, translations } = initialValues;
	const system = initialValues.system || parentSystem || currentSystem;

	const name_translation_eng = translations[0]?.translations.Name || '';

	const initialData = {
		...initialValues,
		name_translation_eng,
		active: !active ? 1 : 0,
		parent_uuid,
		section_id,
		is_empty: t(
			getIsEmptyText({ formValues: initialValues, isInitialData: true })
		),
		system
	};

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		delete values.is_empty; // is_empty is readonly

		const resources = convertFormValues({
			values,
			customProperties,
			initialSectionId
		});

		// We need fresh data to show inherited value 'Adult' from selected section and show link to selected section
		const reloadForm = (itemID) => itemID && fetchCognitoCategory(itemID);

		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'cognito/categories',
			callback: () => reloadForm(itemID)
		});
	};

	return (
		<FormTemplate
			fetchResourceToEdit={fetchCognitoCategory}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={initialData}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting, values, form: { change } }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								isEdit={isEdit}
								customProperties={customProperties}
								customPropertiesValues={customPropertiesValues}
								currentSectionUrl={sectionUrl}
								currentValues={values}
								change={change}
							/>

							<FormButtons
								isButtonDisabled={submitting || error}
								path={redirectPath}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

CognitoForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchCognitoCategory: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ cognito: { form } }) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, { fetchCognitoCategory }),
	withRouter
)(CognitoForm);
