import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input } from 'reactstrap';

const InputBase = ({ input, meta: { touched, error }, ...inputProps }) => (
	<FormGroup className={`${touched && error ? 'has-error' : ''} `}>
		<Input
			invalid={touched && error ? true : false}
			{...input}
			{...inputProps}
		/>
		{touched && error && <span className="input-error">{error}</span>}
	</FormGroup>
);

InputBase.propTypes = {
	input: PropTypes.object.isRequired,
	meta: PropTypes.object
};

export default InputBase;
