import React from 'react';
import { useDispatch } from 'react-redux';

// Import actions
import {
	fetchCognitoLogo,
	fetchCognitoCoverLarge,
	fetchCognitoPoster
} from 'store/actions';

// Import helpers
import { IMAGE_SIZE_INFO } from 'components/helpers/info_helpers/info_texts';

// Import components
import Image from 'components/utilities/images/Image';
import InfoTitle from 'components/utilities/info/InfoTitle';

// Import translation
import { useTranslation } from 'react-i18next';
import { Wraper } from 'components/common/layout';

const CognitoImages = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<Wraper error={false}>
			<InfoTitle title={IMAGE_SIZE_INFO} />

			<Image
				title={t('menu_stb:files.cover_large')}
				fetchResourcesAction={(id) => fetchCognitoCoverLarge(id)(dispatch)}
				collectionType="cover_large"
				mediaType="cognito_catalogue"
				storePathSelector="cognito"
			/>

			<Image
				title={t('menu_stb:files.poster')}
				fetchResourcesAction={(id) => fetchCognitoPoster(id)(dispatch)}
				collectionType="poster"
				mediaType="cognito_catalogue"
				storePathSelector="cognito"
			/>

			<Image
				title={t('common:images.logo')}
				fetchResourcesAction={(id) => fetchCognitoLogo(id)(dispatch)}
				collectionType="logo"
				mediaType="cognito_catalogue"
				storePathSelector="cognito"
			/>
		</Wraper>
	);
};

export default CognitoImages;
