import React from 'react';
import { object, string } from 'prop-types';
import { get } from 'lodash';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { Spin } from 'antd';

function ModalContent({ data, status, error, dataAccessPath }) {
	const { t } = useTranslation();

	switch (status) {
		case 'error':
			const is404Error = error?.response?.status === 404;
			const errorText = is404Error
				? 'ingester:ingester_encoding.not_found'
				: 'common:errors.common_error_message';
			return (
				<div className="d-flex justify-content-center mt-5">
					<p>{t(errorText)}</p>
				</div>
			);

		case 'success':
			const modalData = dataAccessPath ? get(data, dataAccessPath) : data;
			const hasData = !!modalData;

			return hasData ? (
				<pre className="adi-code">{JSON.stringify(modalData, null, 2)}</pre>
			) : (
				<div className="d-flex justify-content-center mt-5">
					<p>{t('common:no_data')}</p>
				</div>
			);

		default:
			return (
				<div className="d-flex justify-content-center mt-5">
					<Spin size="large" />
				</div>
			);
	}
}

ModalContent.propTypes = {
	data: object,
	status: string,
	dataAccessPath: string
};

export default ModalContent;
