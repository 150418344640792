import React from 'react';
import { Button, Col } from 'antd';
import { useTranslation } from 'react-i18next';

// Import context
import { useMassChangesContext } from 'components/utilities/mass_changes/MassChangesProvider';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

const ProductsButtons = () => {
	const { t } = useTranslation();
	const {
		buttonSubmitText,
		handleOnCancel,
		handleOnSubmit,
		submitting,
		selection,
		selectedItem,
		redirectPath
	} = useMassChangesContext();

	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	const isButtonDisabled =
		submitting || !selectedItem || selection?.length === 0;

	return (
		!isReadOnly && (
			<Col
				className="d-flex justify-content-end"
				sm={{ size: '10', offset: 2 }}
			>
				<Button
					className="m-r-md m-b-md"
					onClick={() => handleOnCancel(redirectPath)}
				>
					{t('common:buttons.cancel')}
				</Button>
				<Button
					className="m-b-md"
					data-testid="button"
					disabled={isButtonDisabled}
					type="primary"
					onClick={handleOnSubmit}
				>
					{buttonSubmitText}
				</Button>
			</Col>
		)
	);
};

export default ProductsButtons;
