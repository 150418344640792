import { useQuery } from 'react-query';
import services from 'services/services';

// Import api
import {
	GET_STATISTICS_CATCHUP_API,
	QUERY_STATISTICS_CATCHUP_DATA
} from 'components/helpers/api';

export default function useCatchupStatistics() {
	const { data } = useQuery({
		queryKey: QUERY_STATISTICS_CATCHUP_DATA,
		queryFn: fetchCatchupList
	});

	function fetchCatchupList() {
		return services.get(GET_STATISTICS_CATCHUP_API);
	}

	const catchupStatisticsData = data?.data ?? [];

	return { catchupStatisticsData };
}
