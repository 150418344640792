import React from 'react';
// Import translation
import { useTranslation } from 'react-i18next';

function NotAuthorized() {
	const { t } = useTranslation();
	return (
		<div className="not_auth__container">
			<h1>
				<i className="fa fa-times not_auth__icon"></i>
			</h1>
			<h1 className="not_auth__text">{t('auth:not_authorized')}</h1>
		</div>
	);
}

export default NotAuthorized;
