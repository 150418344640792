import { useState } from 'react';

// Import utilities
import { submitForm } from 'components/utilities/form';

export const useCSVUploads = (updateDeviceSnCSV) => {
	const [submitSucceeded, setSubmitSucceeded] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const uploadCSV = async (file) => {
		setLoading(true);
		const data = new FormData();
		const formData = {
			file: file[0]
		};
		Object.entries(formData).forEach(([key, value]) => data.append(key, value));

		// upload the CSV
		try {
			const response = await submitForm({
				resources: data,
				redirectToEdit: false,
				api: 'device/retail',
				withoutSlug: true,
				formData: true,
				notificationName: 'CSV',
				callback: updateDeviceSnCSV
			});
			response && setSubmitSucceeded(true);
		} catch (error) {
			return error;
		} finally {
			setLoading(false);
			setSubmitSucceeded(false);
		}
	};

	return {
		uploadCSV,
		isLoading,
		submitSucceeded
	};
};
