import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	cover: {
		data: [],
		isLoaded: false,
		error: false
	},
	cover_large: {
		data: [],
		isLoaded: false,
		error: false
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** FETCH SECTION_ITEM COVER FILES ******************
			case types.FETCH_SECTION_ITEM_COVER_FILES_LOADING:
				draft.cover.data = [];
				draft.cover.isLoaded = false;
				draft.cover.error = false;
				break;

			case types.FETCH_SECTION_ITEM_COVER_FILES_SUCCESS:
				draft.cover.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.cover.isLoaded = true;
				break;

			case types.FETCH_SECTION_ITEM_COVER_FILES_ERROR:
				draft.cover.isLoaded = true;
				draft.cover.error = true;
				break;

			// ****************** FETCH SECTION_ITEM COVER_LARGE FILES ******************
			case types.FETCH_SECTION_ITEM_COVER_LARGE_FILES_LOADING:
				draft.cover_large.data = [];
				draft.cover_large.isLoaded = false;
				draft.cover_large.error = false;
				break;

			case types.FETCH_SECTION_ITEM_COVER_LARGE_FILES_SUCCESS:
				draft.cover_large.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.cover_large.isLoaded = true;
				break;

			case types.FETCH_SECTION_ITEM_COVER_LARGE_FILES_ERROR:
				draft.cover_large.isLoaded = true;
				draft.cover_large.error = true;
				break;
			default:
				return state;
		}
	});
