import i18n from 'i18n';

export const BASE_FILEDS = [
	{
		name: 'parentId',
		type: 'select',
		required: true,
		labelColumn: 3,
		inputColumn: 9,
		label: i18n.t('categories:fields.parentId')
	}
];
