import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
	addNewPaymentTemplateTable,
	editPaymentTemplateTable
} from 'store/actions';

// Import components
import { Button, FormGroup, Col } from 'reactstrap';
import Fields from './fields/Fields';

// Import helpers
import validate from './fields/validate';

// Import context
import { PaymentTemplateContext } from '../../PaymentTemplateContext';

const PaymentForm = ({ resources: { assets } }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { assetId, isEdit, updateAsset, clearModal } = useContext(
		PaymentTemplateContext
	);

	const handleOnSubmit = async (values) => {
		if (isEdit) {
			editPaymentTemplateTable(values)(dispatch);
			updateAsset();
		} else {
			addNewPaymentTemplateTable(values)(dispatch);
		}
		clearModal();
	};

	const asset = assets.find(({ payment_method }) => payment_method === assetId);
	const formInitialValues = isEdit && asset ? asset : {};

	return (
		<Form
			onSubmit={handleOnSubmit}
			validate={validate}
			initialValues={formInitialValues}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit}>
					<Fields isEdit={isEdit} submitting={submitting} />
					<FormGroup>
						<Col
							className="d-flex justify-content-end m-t-md"
							sm={{ size: '10', offset: 2 }}
						>
							<Button type="submit" disabled={submitting}>
								{t('common:buttons.submit')}
							</Button>
						</Col>
					</FormGroup>
				</form>
			)}
		</Form>
	);
};

PaymentForm.propTypes = {
	resources: PropTypes.object
};

export default PaymentForm;
