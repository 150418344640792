import React from 'react';
import i18n from 'i18next';

// Import tabs
import MarketingCampaignsForm from 'components/views/marketing_campaigns/marketing_campaigns_form/MarketingCampaignsForm';
import MarketingCampaignsTargets from 'components/views/marketing_campaigns/marketing_campaigns_targets/MarketingCampaignsTargets';

export const marketingCampaignsTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <MarketingCampaignsForm />
	},
	{
		name: i18n.t('marketing_campaigns:tabs.targets'),
		path: 'targets',
		disableOnCreate: true,
		component: <MarketingCampaignsTargets />
	}
];
