import i18n from 'i18n';

// Import variables
import { periods } from './input_fields';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

export const dateFormat = 'YYYY-MM-DD HH:mm:ss';

export const filterResources = (isMain) => {
	if (!isMain)
		return {
			periods: periods.filter(({ value }) => value !== '-1')
		};
	return { periods };
};

export const notifySuccess = () => {
	notificationHandler(
		i18n.t(`messages:notifications.created`),
		`${i18n.t(`messages:notifications.successfuly_created`)} ${i18n.t(
			'names:notification_names.payment_template'
		)} `,
		'success',
		5
	);
};

export const notifyError = () => {
	notificationHandler(
		i18n.t('messages:notifications.error'),
		i18n.t(`payment_templates:errors.created_error`),
		'error',
		5
	);
};
