import i18n from 'i18next';

export const OPERATORS_FILTER_FIELDS = [
	{
		name: 'email',
		label: i18n.t('filters:fields.email'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'firstName',
		label: i18n.t('filters:fields.first_name'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'lastName',
		label: i18n.t('filters:fields.last_name'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'roles',
		label: i18n.t('filters:fields.roles'),
		type: 'multi-select',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	}
];
