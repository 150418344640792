import services from 'services/services';
import * as types from '../types';

// Import variables
import { FETCH_PAYMENT_TEMPLATES_GET } from 'components/helpers/api';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';
import { convertPrice } from 'components/helpers/convert';

export const fetchPaymentTemplateMetadata = (itemId) => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_PAYMENT_TEMPLATE_METADATA_LOADING,
			payload: true
		});

		const url = FETCH_PAYMENT_TEMPLATES_GET.replace(':id', itemId);
		const { data } = await services.get(url);

		const editData = {
			name: data.name,
			id: data.id,
			prices: data.prices,
			// For some reason backend instead of sending empty object, sends empty array
			// (only if there are no external_ids for given template)
			external_ids: Array.isArray(data.external_ids) ? {} : data.external_ids
		};
		dispatch({
			type: types.FETCH_PAYMENT_TEMPLATE_METADATA_SUCCESS,
			payload: editData
		});

		// Only entries with a price are valid
		const tableData = Object.keys(data.prices)
			.filter((item) => data.prices[item])
			.map((item) => ({
				payment_method: item,
				price: convertPrice(data.prices[item]),
				external_id: data.external_ids.hasOwnProperty(item)
					? data.external_ids[item]
					: null
			}));

		dispatch({
			type: types.SET_PAYMENT_TEMPLATE_TABLE_SUCCESS,
			payload: tableData
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_PAYMENT_TEMPLATE_METADATA_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

export const addNewPaymentTemplateTable = ({
	payment_method,
	price,
	external_id
}) => async (dispatch) => {
	dispatch({
		type: types.PAYMENT_TEMPLATE_ADD_PAYMENT_TO_TABLE,
		payload: {
			payment_method,
			price,
			external_id
		}
	});
};

export const deletePaymentTemplateTable = (payment_method) => async (
	dispatch
) => {
	dispatch({
		type: types.PAYMENT_TEMPLATE_DELETE_PAYMENT_FROM_TABLE,
		payload: payment_method
	});
};

export const editPaymentTemplateTable = ({
	payment_method,
	price,
	external_id
}) => async (dispatch) => {
	dispatch({
		type: types.PAYMENT_TEMPLATE_EDIT_PAYMENT_FROM_TABLE,
		payload: {
			payment_method,
			price,
			external_id
		}
	});
};
