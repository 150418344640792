import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

// Import context
import { ProvisioningProductsContext } from './ProvisioningProductsProvider';

// Import components
import Tabs from './tabs/Tabs';
import Select from './select/Select';
import { ModalFormButtons } from 'components/common/buttons';

const ProductsModal = ({ toggle, visible }) => {
	const {
		title,
		handleModalCancel,
		handleModalOk,
		buttonSubmitText
	} = useContext(ProvisioningProductsContext);

	const handleOnCancel = () => {
		toggle();
		handleModalCancel();
	};

	const handleOnSubmit = () => {
		toggle();
		handleModalOk();
	};
	return (
		<Modal
			title={title}
			open={visible}
			onOk={handleOnSubmit}
			onCancel={handleOnCancel}
			footer={false}
			destroyOnClose={true}
			width={700}
		>
			<Tabs />
			<Select />
			<ModalFormButtons
				modalCloseAction={handleOnCancel}
				modalOkAction={handleOnSubmit}
				buttonText={buttonSubmitText}
			/>
		</Modal>
	);
};

ProductsModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired
};

export default ProductsModal;
