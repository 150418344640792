import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

// Import styled components
import { TagWrapper } from './styles';

// Import helpers
import { mapPaymentMethodToTag } from './helpers';

function PaymentTags({ payment_methods }) {
	const methods = payment_methods
		.split(',')
		.map((payment_method) => payment_method.trim());
	const tags = methods
		.map((method) => mapPaymentMethodToTag(method))
		.sort((a, b) => (a.order > b.order ? 1 : -1));
	return (
		<TagWrapper>
			{tags.map(({ name, color, icon }, index) => (
				<Tag
					key={index}
					color={color}
					icon={icon}
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						margin: '0 0.2rem',
						cursor: 'pointer'
					}}
				>
					{name}
				</Tag>
			))}
		</TagWrapper>
	);
}

PaymentTags.propTypes = {
	payment_methods: PropTypes.string.isRequired
};

export default PaymentTags;
