import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import services from 'services/services';
import {
	fetchChannel,
	fetchEpg,
	setEpgQuery,
	updateEpgBlacklist,
	clearPanelState
} from 'store/actions';

// Import api
import {
	GET_CATCHUP_RECORDING_DATA_API,
	CATCHUP_RECORDING_DATA
} from 'components/helpers/api';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

// Import columns
import { createColumns } from './channels_epg_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { Ibox, IboxContentBase, Wraper } from 'components/common/layout';
import { ReactServerSideTableCheckbox } from 'components/utilities/table';
import DatePicker from './DatePicker';
import FilterButton from 'components/common/buttons/FilterButton';

// Import hooks
import useSystem from 'hooks/useSystem';

const Epg = (props) => {
	const {
		error,
		resources,
		fetchChannel,
		fetchEpg,
		setEpgQuery,
		clearPanelState,
		updateEpgBlacklist,
		isLoaded,
		external_uid,
		match: {
			params: { id }
		}
	} = props;

	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();
	const { currentSystem } = useSystem();

	useEffect(() => {
		fetchChannel(id);

		return () => clearPanelState();
		// eslint-disable-next-line
	}, []);

	const fetchCatchupRecordingData = (programUuid) => {
		const url = GET_CATCHUP_RECORDING_DATA_API.replace(
			':programUuid',
			programUuid
		);

		return services.get(url);
	};

	const { columns } = resources;

	return (
		<React.Fragment>
			{isLoaded && (
				<Wraper lg="12" error={error}>
					<Ibox error={false} className="m-b-lg">
						<IboxContentBase>
							<React.Fragment>
								<DatePicker />
								<div className="d-flex justify-content-end">
									<Search
										setQueryValue={setEpgQuery}
										value={resources.options.filters.query}
									/>
									<FilterButton tableColumns={columns} />
								</div>
								<ReactServerSideTableCheckbox
									resources={resources}
									columns={createColumns({
										channelId: id,
										queryKey: CATCHUP_RECORDING_DATA,
										handleFetchData: fetchCatchupRecordingData,
										system: currentSystem
									})}
									itemId={external_uid}
									fetchResourcesAction={fetchEpg}
									updateCheckedItems={updateEpgBlacklist}
									keyField="uuid"
									keyType="blackout"
									notificationUpdateSuccessTxt={i18n.t(
										'epg:notification.update_blacklist_success'
									)}
									notificationUpdateErrorTxt={i18n.t(
										'epg:notification.update_blacklist_error'
									)}
									controlButtonsTitle={i18n.t('epg:control_buttons.title')}
									clearButtonTxt={i18n.t('epg:control_buttons.clear_btn')}
									isReadOnly={isReadOnly}
									tableType="epg"
									defaultSorted={[{ id: 'since', desc: false }]}
								/>
							</React.Fragment>
						</IboxContentBase>
					</Ibox>
				</Wraper>
			)}
		</React.Fragment>
	);
};

Epg.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchChannel: PropTypes.func.isRequired,
	fetchEpg: PropTypes.func.isRequired,
	clearPanelState: PropTypes.func.isRequired,
	setEpgQuery: PropTypes.func.isRequired,
	external_uid: PropTypes.number,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	})
};

const mapStateToProps = ({
	epg: { table },
	channels: {
		form: {
			edit: { external_uid },
			isLoaded
		}
	}
}) => ({
	error: table.error,
	resources: table,
	external_uid,
	isLoaded
});

export default compose(
	connect(mapStateToProps, {
		fetchChannel,
		fetchEpg,
		setEpgQuery,
		updateEpgBlacklist,
		clearPanelState
	}),
	withRouter
)(Epg);
