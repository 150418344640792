import React, { useState, createContext } from 'react';
import { useDispatch } from 'react-redux';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

// Import translation
import { useTranslation } from 'react-i18next';

// Import variables
import { PAYMENT_TEMPLATE_TYPES } from 'components/helpers/variables';

export const PaymentTemplateContext = createContext();

export const PaymentTemplateProvider = ({
	children,
	resources,
	deletePayment
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [isVisible, setIsVisible] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [assetId, setAssetId] = useState(null);
	const [usedPayments, setUsedPayments] = useState([]);

	const toggleFormModal = () => {
		setIsVisible(!isVisible);
	};

	const toggleEdit = () => {
		setIsEdit(!isEdit);
	};

	const updateAsset = () => {
		notificationHandler(
			t('messages:notifications.edited'),
			`${t('messages:notifications.successfuly_edited')} ${t(
				'names:notification_names.payment_template'
			)} `
		);
	};

	const deleteAsset = async (payment_method) => {
		await deletePayment(payment_method)(dispatch);
		notificationHandler(
			t('messages:notifications.deleted'),
			`${t('messages:notifications.successfuly_deleted')} ${t(
				'names:notification_names.payment_template'
			)} `
		);
	};

	const editAsset = (id) => {
		toggleEdit();
		toggleFormModal();
		setAssetId(id);
	};

	const clearModal = () => {
		setAssetId(null);
		setIsEdit(false);
		setIsVisible(false);
	};

	const allUsed = Object.values(PAYMENT_TEMPLATE_TYPES).every((payment) =>
		usedPayments.includes(payment)
	);

	return (
		<PaymentTemplateContext.Provider
			value={{
				isVisible,
				isEdit,
				toggleFormModal,
				toggleEdit,
				setIsEdit,
				resources,
				updateAsset,
				deleteAsset,
				assetId,
				setAssetId,
				editAsset,
				usedPayments,
				setUsedPayments,
				allUsed,
				clearModal
			}}
		>
			{children}
		</PaymentTemplateContext.Provider>
	);
};
