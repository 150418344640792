import { useState, useEffect, useMemo, useImperativeHandle } from 'react';
import { debounce } from 'lodash';

// Import helpers
import {
	TABLE_LENGTH,
	prepareDefaultSortedData,
	createTableOptions
} from 'components/helpers/table';

const useSelectTable = ({
	ref,
	resources,
	defaultSorted = [],
	itemID,
	fetchDataAction,
	resourcesForFetchAction
}) => {
	const [tableRef, setTableRef] = useState(null);
	const [selection, setSelection] = useState([]);
	const [areAllSelected, setAreAllSelected] = useState(false);
	const [pageSize, setPageSize] = useState(TABLE_LENGTH);

	const {
		data: rawData,
		options: { pages, filters, page, total_results: totalResults, sorted },
		loading
	} = resources;

	const data = useMemo(
		() =>
			rawData.map((row, index) => ({
				...row,
				uuid: index
			})),
		[rawData]
	);

	const defaultSortedData = useMemo(
		() => prepareDefaultSortedData(defaultSorted, sorted),
		[defaultSorted, sorted]
	);

	const toggleAll = () => {
		// Uncheck all
		if (areAllSelected) {
			setAreAllSelected(false);
			setSelection([]);
		}
		// Check all
		else {
			const wrappedInstance = tableRef?.getWrappedInstance();
			const currentRecords = wrappedInstance.getResolvedState().sortedData;

			setAreAllSelected(true);
			setSelection(currentRecords.map((item) => item._original));
		}
	};

	const toggleSelection = (key, shift, row) => {
		// Uncheck
		if (isSelected(row?.uuid)) {
			const newlySelected = selection.filter((item) => item.uuid !== row.uuid);
			setAreAllSelected(false);
			setSelection(newlySelected);
		}
		// Check
		else {
			const newlySelected = [...selection, row];
			setAreAllSelected(newlySelected.length === data.length);
			setSelection(newlySelected);
		}
	};

	const isSelected = (uuid) =>
		Boolean(selection.find((item) => item.uuid === uuid));

	const getTrProp = (_, rowInfo) => {
		if (rowInfo?.row._original['mass_changes']) {
			return {
				className: '-mass_changes'
			};
		}

		return {};
	};

	const handleTableSize = (value) => setPageSize(value);

	const fetchData = (state) => {
		// Cancel extra request on pagination
		if (state.page !== 0 && state.page === page) return;

		const options = createTableOptions(state, sorted, 'mass_changes');
		fetchDataAction(options, itemID, resourcesForFetchAction);
		setAreAllSelected(false);
	};

	useEffect(() => {
		if (tableRef) {
			fetchData(tableRef?.wrappedInstance?.state);
		}
		//eslint-disable-next-line
	}, []);

	useImperativeHandle(ref, () => ({
		selected: selection,
		refetch: () => fetchData(tableRef?.wrappedInstance?.state)
	}));

	const checkboxProps = {
		selectAll: areAllSelected,
		isSelected,
		toggleSelection,
		toggleAll,
		keyField: 'uuid',
		keyStyle: 'mass_changes',
		selectType: 'checkbox'
	};

	return {
		tableRef,
		checkboxProps,
		totalResults,
		data,
		page,
		pages,
		isLoading: loading,
		pageSize,
		filters,
		defaultSortedData,
		selection,
		setSelection,
		getTrProp,
		setTableRef,
		handleTableSize,
		fetchData: debounce((state) => fetchData(state), 100)
	};
};

export default useSelectTable;
