import React, { useContext } from 'react';
import { Select as SelectAntd, Spin, Alert } from 'antd';

// Import context
import { ProvisioningProductsContext } from '../ProvisioningProductsProvider';

// Import helpers
import { renderAdditionalLabelData } from '../helpers/index';

const { Option } = SelectAntd;

const Select = () => {
	const {
		select: { value, data, fetching, error },
		modal: { selectedType, tabs },
		fetchSelectAction,
		handleSelectChange,
		idSelector,
		titleSelector,
		labelSelector
	} = useContext(ProvisioningProductsContext);

	const { title } = tabs.find((item) => item.type === selectedType);

	const isLoading = () =>
		fetching ? (
			<div className="text-center">
				<Spin size="small" />
			</div>
		) : null;

	const checkError = () => (
		<Alert
			showIcon
			type="error"
			description="An error has occurred and the data can not be retrieved"
			className="m-b-md animated fadeIn"
		/>
	);

	// renders fetched data as select options in combobox
	// sections must have label displayed before their name
	// vod must have additional data displayed after their title
	const renderSelectData = (item) => {
		switch (selectedType) {
			case 'sections':
				return `(${item[labelSelector]}) ${item[titleSelector]}`;

			case 'vod':
				const nameTags = ['subtype', 'provider', 'payment_models'];
				return `${item[titleSelector]} (${renderAdditionalLabelData(
					nameTags,
					item
				)}) `;

			default:
				return item[titleSelector];
		}
	};

	return (
		<div className="m-t-md">
			{error && checkError()}
			<SelectAntd
				style={{ width: '100%' }}
				mode="multiple"
				labelInValue
				value={value}
				placeholder={title}
				notFoundContent={isLoading()}
				filterOption={false}
				onSearch={fetchSelectAction}
				onChange={handleSelectChange}
			>
				{data.map((item) => (
					<Option key={item[idSelector]} value={item[idSelector]}>
						{renderSelectData(item)}
					</Option>
				))}
			</SelectAntd>
		</div>
	);
};

export default Select;
