import React from 'react';
import { func, bool, array, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

// Import actions
import {
	fetchAffiliatePartnerCodes,
	deleteAffiliatePartnerCode
} from 'store/actions';

// Import component
import { IboxContentBase } from 'components/common/layout';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { messageConfirmDeleteHandler } from 'components/utilities/message';

const AffiliatePartnerCodesList = ({
	codes,
	itemID,
	deleteAffiliatePartnerCode,
	fetchAffiliatePartnerCodes,
	isLoaded
}) => {
	const { t } = useTranslation();

	const handleCodeDelete = (code) => {
		const callback = async (itemID, code) => {
			await deleteAffiliatePartnerCode(itemID, code);
			fetchAffiliatePartnerCodes(itemID);
		};

		messageConfirmDeleteHandler(
			'affiliate_partners_code',
			itemID,
			callback,
			code
		);
	};

	const renderCodes = () =>
		codes.length ? (
			codes.map(({ code }) => (
				<div
					key={code}
					className="d-flex flex-column justify-content-center align-items-center border border-info rounded w-200 m-sm m-r-md m-l-md p-xs"
				>
					<p className="font-weight-bold m-b-sm h4">{code}</p>
					<Button
						className="m-b-md"
						disabled={false}
						onClick={handleCodeDelete.bind(null, code)}
					>
						{t('affiliate_partners:buttons.delete_code')}
					</Button>
				</div>
			))
		) : (
			<div> {t('affiliate_partners:fields_info.code_lack')}</div>
		);

	return (
		<IboxContentBase itemID={itemID}>
			<h4>{t('affiliate_partners:fields.codes_info_title')}</h4>
			{isLoaded && (
				<div className="d-flex flex-wrap m-t-lg">{renderCodes()}</div>
			)}
		</IboxContentBase>
	);
};

AffiliatePartnerCodesList.propTypes = {
	fetchAffiliatePartnerCodes: func.isRequired,
	deleteAffiliatePartnerCode: func.isRequired,
	isLoaded: bool.isRequired,
	codes: array,
	itemId: string
};

const mapStateToProps = ({ affiliate_partners: { form } }) => ({
	isLoaded: form.isLoaded,
	codes: form.codes
});

export default compose(
	connect(mapStateToProps, {
		fetchAffiliatePartnerCodes,
		deleteAffiliatePartnerCode
	})
)(AffiliatePartnerCodesList);
