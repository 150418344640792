import React from 'react';

// Import translation
import i18n from 'i18next';

// Import helpers
import { setFormLink } from 'components/helpers/link_selector';

// Import components
import {
	ProgressBar,
	FetchDataModal,
	RepeatIngesterActionButton
} from 'components/common/elements';
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

export const createColumns = ({
	isReadOnly,
	queryKey,
	handleFetchData,
	refreshAction
}) => [
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.encoder_id'),
		accessor: 'encoder_id',
		sortable: false,
		minWidth: 190,
		Cell: ({ row }) => {
			const { encoder_id } = row._original;
			const isVisible = !!encoder_id;
			return (
				<FetchDataModal
					isVisible={isVisible}
					text={encoder_id}
					id={encoder_id}
					queryKey={queryKey}
					handleFetchData={handleFetchData}
					dataAccessPath={'data.data.fullMediaCoderResponse'}
					showIcon={isVisible}
				/>
			);
		}
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.title'),
		accessor: 'title',
		Cell: ({
			row: {
				_original: { product_uuid, title }
			}
		}) => setFormLink({ uuid: product_uuid, title })
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.encoder'),
		accessor: 'encoder',
		sortable: false,
		width: 110
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.status'),
		accessor: 'status',
		sortable: false,
		width: 90
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.encoder_progress'),
		accessor: 'encoder_progress',
		Cell: ({
			row: {
				_original: { encoder_progress, status }
			}
		}) => <ProgressBar progress={encoder_progress} status={status} />
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.encoder_profile'),
		accessor: 'encoder_profile',
		sortable: false
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.priority'),
		accessor: 'priority',
		width: 80,
		sortable: false
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.scheduled_at'),
		accessor: 'scheduled_at',
		minWidth: 120
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.created_at'),
		accessor: 'created_at',
		minWidth: 120
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.can_be_ingested'),
		accessor: 'can_be_ingested',
		sortable: false,
		minWidth: 70,
		Cell: ({
			row: {
				_original: { can_be_ingested }
			}
		}) => (
			<div className="d-flex justify-content-center">
				<ActiveIcon active={can_be_ingested} />
			</div>
		)
	},
	{
		Header: i18n.t('common:table_columns.action'),
		accessor: 'repeat_encoding',
		className: 'text-center',
		sortable: false,
		width: 70,
		Cell: ({
			row: {
				_original: { uuid }
			}
		}) =>
			!isReadOnly && (
				<RepeatIngesterActionButton
					url={`ingester/encodings/${uuid}/reIngest`}
					refreshAction={refreshAction}
				/>
			)
	}
];
