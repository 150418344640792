export const select_types = [
	{
		name: 'WHITELIST',
		value: 'whitelist'
	},
	{
		name: 'BLACKLIST',
		value: 'blacklist'
	}
];
