import React from 'react';
import { object, func, bool } from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import actions
import { fetchTagItem } from 'store/actions';

// Import utilities
import { submitForm } from 'components/utilities/form';
import validate from './validation';

const TagsForm = ({ error, isLoaded, initialValues, fetchTagItem }) => {
	const { t } = useTranslation();
	const history = useHistory();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) =>
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: 'tags',
			redirectPath: 'tags',
			redirectToPath: true,
			notificationName: 'globaltags'
		});

	return (
		<FormTemplate
			fetchResourceToEdit={fetchTagItem}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={initialValues}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields submitting={submitting} isEdit={isEdit} />

							<FormButtons
								isButtonDisabled={submitting || error}
								path="tags"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

TagsForm.propTypes = {
	error: bool.isRequired,
	isLoaded: bool.isRequired,
	initialValues: object.isRequired,
	fetchTagItem: func.isRequired
};

const mapStateToProps = ({ tags: { form } }) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded
	};
};

export default compose(connect(mapStateToProps, { fetchTagItem }))(TagsForm);
