import i18n from 'i18n';

// Import helpers
import {
	MIMES,
	MARKETING_CAMPAIGNS_STATUS_VALUES
} from 'components/helpers/variables';

const { READY, SENT, DELETED } = MARKETING_CAMPAIGNS_STATUS_VALUES;

// consts
const maxUrlCharacters = 256;
const recommendedRatio = 1.78;
const ratioErrorMargin = 0.05;
const recommendedWidth = 720;
const recommendedHeight = 404;
const allowedMimes = [MIMES.PNG, MIMES.JPEG, MIMES.JPG];

// validators
const validateUrl = ({ url }) => url?.length <= maxUrlCharacters;
const validateMimes = ({ mime }) => allowedMimes.includes(mime);
const validateResolution = ({ width, height }) =>
	width && height && width === recommendedWidth && height === recommendedHeight;
const validateRatio = ({ ratio }) =>
	ratio && Math.abs(ratio - recommendedRatio) < ratioErrorMargin;

export const imageRequirements = [
	{
		label: i18n.t('marketing_campaigns:info.url_length', { maxUrlCharacters }),
		validate: validateUrl,
		info: ({ url }) =>
			i18n.t('marketing_campaigns:info.url_length_tooltip', {
				currentUrlCharacters: url?.length
			}),
		severity: 'error'
	},
	{
		label: i18n.t('marketing_campaigns:info.mime_type'),
		validate: validateMimes,
		severity: 'error',
		info: ({ mime }) =>
			i18n.t('marketing_campaigns:info.mime_type_tooltip', { mime })
	},
	{
		label: i18n.t('marketing_campaigns:info.resolution', {
			recommendedWidth,
			recommendedHeight
		}),
		validate: validateResolution,
		severity: 'warning',
		info: ({ width, height }) =>
			width && height
				? i18n.t('marketing_campaigns:info.resolution_current', {
						width,
						height
				  })
				: i18n.t('marketing_campaigns:info.not_applicable')
	},
	{
		label: i18n.t('marketing_campaigns:info.ratio', { recommendedRatio }),
		validate: validateRatio,
		severity: 'warning',
		info: ({ ratio }) =>
			ratio
				? i18n.t('marketing_campaigns:info.ratio_current', { ratio })
				: i18n.t('marketing_campaigns:info.not_applicable')
	}
];

export const mapStatusToStyles = (status) => {
	switch (status) {
		case READY:
			return { color: 'blue', textWeight: 'bold' };
		case SENT:
			return { color: 'green', textWeight: 'light' };
		case DELETED:
			return { color: 'red', textWeight: 'bold' };
		default:
			return {};
	}
};
