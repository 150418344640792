import { terytCodes } from './terytCodes';
import services from 'services/services';
import { handleErrors } from 'components/utilities/form/submitForm';

// Import utilities
import { createFormNotification } from 'components/utilities/notifications/index';

// Import helpers
import { capitalizeText } from 'components/helpers/convert';

export const getParsedTags = (tagIds, position) => {
	return tagIds.map((tag) => ({
		id: tag.find(({ name }) => name === 'terc')?.id,
		additional_data: { position }
	}));
};

export const parseLocalChannelData = (channel) => {
	const voivodeship = terytCodes.find(
		({ value }) => value === channel.value.slice(0, 2)
	);
	const county = voivodeship.counties.find(
		({ value }) => value === channel.value.slice(2)
	);
	return {
		...channel,
		location: `${voivodeship.name.toLowerCase()}, ${county.name} (${
			channel.value
		})`
	};
};

export const getCountiesForSelect = (selectedVoivodeship) => {
	return terytCodes
		.reduce(
			(acc, { value, counties }) =>
				value === selectedVoivodeship ? [...acc, ...counties] : acc,
			[]
		)
		.sort((a, b) => a.name.localeCompare(b.name));
};

export const getVoivodeshipsForSelect = () =>
	terytCodes.map(({ name, value }) => ({ name, value }));

export const fetchTagIds = async (counties, voivodeship) => {
	let tagWithoutTerc = null;

	const tagIdRequests = counties.map(async (county) => {
		const {
			data: { data }
		} = await services.get(`/tags/list?search[value]=${voivodeship}${county}`);

		const hasTerc = data?.some(({ name }) => name === 'terc');

		if (!hasTerc) {
			tagWithoutTerc = `${voivodeship}${county}`;
		}

		return data;
	});

	try {
		return {
			tagIds: await Promise.all(tagIdRequests),
			tagWithoutTerc
		};
	} catch (error) {
		handleErrors(error);
	}
};

export const submitCreateLocalChannelForm = async ({
	api,
	apiSlug,
	channel_uuid,
	resources
}) => {
	try {
		await services.post(`/${api}/${channel_uuid}/${apiSlug}`, resources);
		createFormNotification(true, capitalizeText(api));
	} catch (error) {
		return handleErrors(error, api);
	}
};

export const getFilterSelectValues = (filterFormValues) => {
	const voivodeshipCodes = terytCodes.map((voivodeship) => voivodeship.value);
	const selectedVoivodeshipCode = filterFormValues.voivodeship;

	const selectedVoivodeshipData = terytCodes.find(
		(voivodeship) => voivodeship.value === selectedVoivodeshipCode
	);

	const countyCodes = selectedVoivodeshipData
		? selectedVoivodeshipData.counties.map((county) => county.value)
		: [];

	return {
		voivodeshipCodes,
		countyCodes
	};
};

export const getFilterQueryValues = (values) => {
	const { voivodeship, county, additional_data, channel_title } = values;

	// If only voivodeship was selected add SQL LIKE operator: (_)
	// Prevents returning county with the code corresponding to the voivodeship
	const countyQuery = county || '__';
	const value = voivodeship ? voivodeship + countyQuery : null;

	const updatedValues = {
		channel_title,
		additional_data,
		value
	};

	// Return object containing keys with truthy values
	return Object.fromEntries(
		Object.entries(updatedValues).filter(([_, v]) => v != null)
	);
};
