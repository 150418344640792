import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH TRACKINGS - TABLE DATA ********************
export const fetchTrackings = (options, id = null) => async (
	dispatch,
	getState
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_TRACKINGS_LOADING,
			payload: true
		});

		const {
			filtered: { date_since, date_till }
		} = options;

		const {
			trackings: {
				table: {
					options: { startIndex: startTableIndex, page }
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `videosession/list?start=${startIndex}&date_from=${date_since}&date_to=${date_till}&length=30${
			id ? `&selected_subscriber=${id}` : ``
		}`;

		const { data } = await services.get(url);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_TRACKINGS_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_TRACKINGS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET TRACKINGS DATE ********************
export const setTrackingsDate = (date, name) => ({
	type: types.SET_TRACKINGS_DATE,
	payload: { date, name }
});
