import { CHANNELS_FILTER_FIELDS } from './filter_fields/channels_filter_fields';
import { LOCAL_CHANNELS_FILTER_FIELDS } from './filter_fields/local_channels_filter_fields';
import { EPG_FILTER_FIELDS } from './filter_fields/epg_filter_fields';
import { VOD_FILTER_FIELDS } from './filter_fields/vod_filter_fields';
import { PAYMENTS_FILTER_FIELDS } from './filter_fields/payments_filter_fields';
import { SEASONS_FILTER_FIELDS } from './filter_fields/seasons_filter_fields';
import { EPISODES_FILTER_FIELDS } from './filter_fields/episodes_filter_fields';
import { PACKETS_FILTER_FIELDS } from './filter_fields/packets_filter_fields';
import { LIMITS_FILTER_FIELDS } from './filter_fields/limits_filter_fields';
import { SUBSCRIBERS_FILTER_FIELDS } from './filter_fields/subscribers_filter_fields';
import { CATEGORIES_FILTER_FIELDS } from './filter_fields/categories_filter_fields';
import { DOCUMENTS_FILTER_FIELDS } from './filter_fields/documents_filter_fields';
import { AGREEMENTS_FILTER_FIELDS } from './filter_fields/agreements_filter_fields';
import { SECTIONS_FILTER_FIELDS } from './filter_fields/sections_filter_fields';
import { BANNERS_FILTER_FIELDS } from './filter_fields/banners_filter_fields';
import { OPERATORS_FILTER_FIELDS } from './filter_fields/operators_filter_fields';
import { GEOLOCKS_FILTER_FIELDS } from './filter_fields/geolocks_filter_fields';
import { INGESTER_ENCODING_FILTER_FIELDS } from './filter_fields/encoding_filter_fields';
import { INGESTER_ASSETS_FILTER_FIELDS } from './filter_fields/assets_filter_fields';
import { PROVISIONING_FILTER_FIELDS } from './filter_fields/provisioning_filter_fields';
import { PROVISIONING_PRODUCTS_FILTER_FIELDS } from './filter_fields/provisioning_products_filter_fields';
import { MAIN_FILTER_FIELDS } from './filter_fields/main_filter_fields';
import { SOFTWARE_FILTER_FIELDS } from './filter_fields/software_filter_fields';
import { PROMOTIONS_FILTER_FIELDS } from './filter_fields/promotions_filter_fields';
import { VIDEO_ASSETS_PARAMS_FILTER_FIELDS } from './filter_fields/video_assets_params_filter_fields';
import { COGNITO_CATEGORIES_FILTER_FIELDS } from './filter_fields/cognito_categories_filter_fields';
import { ACTIVITY_FILTER_FIELDS } from './filter_fields/activity_filter_fields';
import { ACTIVITY_OPERATOR_FILTER_FIELDS } from './filter_fields/activity_operator_filter_fields';
import { PROVIDERS_FILTER_FIELDS } from './filter_fields/providers_filter_fields';
import { COUPONS_FILTER_FIELDS } from './filter_fields/coupons_filter_fields';
import { EPG_GLOBAL_FILTER_FIELDS } from './filter_fields/epg_global_filter_fields';
import { SYSTEMS_FILTER_FIELDS } from './filter_fields/systems_filter_fields';
import { MARKETING_CAMPAIGNS_FILTER_FIELDS } from './filter_fields/marketing_campaigns_filter_fields';
import { TAGS_FILTER_FIELDS } from './filter_fields/tags_filter_fields';
import { MARKETING_CAMPAIGNS_TARGET_FILTER_FIELDS } from './filter_fields/targets_filter_fields';

export const FILTER_FIELDS = {
	channels: CHANNELS_FILTER_FIELDS,
	localChannels: LOCAL_CHANNELS_FILTER_FIELDS,
	epg: EPG_FILTER_FIELDS,
	vod: VOD_FILTER_FIELDS,
	seasons: SEASONS_FILTER_FIELDS,
	episodes: EPISODES_FILTER_FIELDS,
	payments: PAYMENTS_FILTER_FIELDS,
	packets: PACKETS_FILTER_FIELDS,
	limits: LIMITS_FILTER_FIELDS,
	subscribers: SUBSCRIBERS_FILTER_FIELDS,
	categories: CATEGORIES_FILTER_FIELDS,
	documents: DOCUMENTS_FILTER_FIELDS,
	agreements: AGREEMENTS_FILTER_FIELDS,
	sections: SECTIONS_FILTER_FIELDS,
	banners: BANNERS_FILTER_FIELDS,
	operators: OPERATORS_FILTER_FIELDS,
	geolocks: GEOLOCKS_FILTER_FIELDS,
	encoding: INGESTER_ENCODING_FILTER_FIELDS,
	assets: INGESTER_ASSETS_FILTER_FIELDS,
	provisioning: PROVISIONING_FILTER_FIELDS,
	provisioning_products: PROVISIONING_PRODUCTS_FILTER_FIELDS,
	main: MAIN_FILTER_FIELDS,
	software: SOFTWARE_FILTER_FIELDS,
	promotions: PROMOTIONS_FILTER_FIELDS,
	'video-assets-params': VIDEO_ASSETS_PARAMS_FILTER_FIELDS,
	menu_stb: COGNITO_CATEGORIES_FILTER_FIELDS,
	activity: ACTIVITY_FILTER_FIELDS,
	activity_operator: ACTIVITY_OPERATOR_FILTER_FIELDS,
	providers: PROVIDERS_FILTER_FIELDS,
	coupons: COUPONS_FILTER_FIELDS,
	epg_global: EPG_GLOBAL_FILTER_FIELDS,
	systems: SYSTEMS_FILTER_FIELDS,
	tags: TAGS_FILTER_FIELDS,
	marketing_campaigns: MARKETING_CAMPAIGNS_FILTER_FIELDS,
	targets: MARKETING_CAMPAIGNS_TARGET_FILTER_FIELDS
};
