import i18n from 'i18n';

export const BASE_FIELDS = ({ metaData: { isLoading } }) => {
	return [
		{
			name: 'document',
			type: 'CSV',
			filetype: '.csv',
			isVisible: true,
			submitting: isLoading,
			placeholder: i18n.t('devices_sn:buttons.import_csv')
		}
	];
};
