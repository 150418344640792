import React from 'react';
import i18n from 'i18next';

// Import components
import SectionsForm from '../../sections_form/SectionsForm';
import SectionsProducts from '../../sections_products/SectionsProducts';
import SectionsPreview from '../../sections_preview/SectionsPreview';
import SectionsPages from '../../sections_pages/SectionsPages';
import SectionsImages from '../../sections_file/SectionsImages';

export const basicSectionsTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <SectionsForm />
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <SectionsImages />
	},
	{
		name: i18n.t('sections:tabs.preview'),
		path: 'preview',
		disableOnCreate: true,
		component: <SectionsPreview />
	},
	{
		name: i18n.t('common:tabs.static_elements'),
		path: 'static-elements',
		disableOnCreate: true,
		component: <SectionsProducts />
	},
	{
		name: i18n.t('common:tabs.on_pages'),
		path: 'pages',
		disableOnCreate: true,
		component: <SectionsPages />
	}
];
