import React from 'react';
import i18n from 'i18next';

// Import helpers
import {
	setFormLink,
	MARKETING_CAMPAIGNS_STATUS_VALUES
} from 'components/helpers';
import { mapStatusToStyles } from './helpers';

// Import components
import DeleteButton from 'components/utilities/delete_button/DeleteButton';
import ColumnDate from './components/ColumnDate';

const { DELETED } = MARKETING_CAMPAIGNS_STATUS_VALUES;

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('marketing_campaigns:table_columns.title'),
		accessor: 'notification_title',
		width: 600,
		Cell: ({
			row: {
				_original: { campaign_id, notification_title, status }
			}
		}) =>
			setFormLink({
				uuid: campaign_id,
				type: 'marketing_campaigns',
				title: notification_title,
				status
			})
	},
	{
		Header: i18n.t('marketing_campaigns:table_columns.id'),
		accessor: 'campaign_id',
		width: 80
	},
	{
		Header: i18n.t('marketing_campaigns:table_columns.status'),
		accessor: 'status',
		width: 140,
		Cell: ({
			row: {
				_original: { status }
			}
		}) => (
			<span style={mapStatusToStyles(status)}>
				{i18n.t(`marketing_campaigns:select.status.${status}`)}
			</span>
		)
	},
	{
		Header: i18n.t('marketing_campaigns:table_columns.campaign_start'),
		accessor: 'campaign_start',
		Cell: ({
			row: {
				_original: { campaign_start }
			}
		}) => <ColumnDate date={campaign_start} />
	},
	{
		Header: i18n.t('marketing_campaigns:table_columns.campaign_end'),
		accessor: 'campaign_end',
		Cell: ({
			row: {
				_original: { campaign_end }
			}
		}) => <ColumnDate date={campaign_end} />
	},
	{
		Header: i18n.t('marketing_campaigns:table_columns.notification_end'),
		accessor: 'notification_end',
		Cell: ({
			row: {
				_original: { notification_end }
			}
		}) => <ColumnDate date={notification_end} />
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { campaign_id, system_id, status }
			}
		}) => (
			<DeleteButton
				id={campaign_id}
				productSystem={system_id}
				onSelectItem={handleSelectItem}
				isReadOnly={isReadOnly || status === DELETED}
			/>
		)
	}
];
