import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

const SubtitlesHeader = ({ vodId, videoId, canEditVideo }) => {
	const { t } = useTranslation();

	return (
		<div className="d-flex align-items-center justify-content-between">
			<h5 style={{ fontSize: '14px', margin: 0 }}>
				{t('vod_subtitles:table_title')}
			</h5>
			{canEditVideo && (
				<Link
					to={`/panel/vod/${vodId}/materials/${videoId}/subtitles/create`}
					className={'ant-btn btn__link'}
				>
					<i className={`fa fa-plus`} />
				</Link>
			)}
		</div>
	);
};

SubtitlesHeader.propTypes = {
	vodId: PropTypes.string.isRequired,
	videoId: PropTypes.number.isRequired
};

export default SubtitlesHeader;
