import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import {
	setLocalChannelsQuery,
	fetchLocalChannels,
	deleteLocalChannel,
	createFiltersSuggestions
} from 'store/actions';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './local_channels_columns';

// Import utilities
import { Search } from 'components/utilities/search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

// Import teryt codes
import { terytCodes } from './terytCodes';

const LocalChannels = ({
	error,
	setLocalChannelsQuery,
	fetchLocalChannels,
	deleteLocalChannel,
	resources,
	createFiltersSuggestions
}) => {
	const { t } = useTranslation();
	const { columns, options } = resources;
	const { filterFormValues } = useSelector(({ filters }) => filters);

	useFilterLogic({
		createFiltersSuggestions,
		filters: { terytCodes, filterFormValues }
	});

	return (
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="localChannels"
			addLinkText={t('local_channels:buttons.add_local_channel')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchLocalChannels}
			deleteItemAction={deleteLocalChannel}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setLocalChannelsQuery}
					value={options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

LocalChannels.propTypes = {
	error: PropTypes.bool.isRequired,
	setLocalChannelsQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchLocalChannels: PropTypes.func.isRequired,
	deleteLocalChannel: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired
};

const mapStateToProps = ({ local_channels }) => {
	return {
		error: local_channels.table.error,
		resources: local_channels.table
	};
};

export default connect(mapStateToProps, {
	setLocalChannelsQuery,
	fetchLocalChannels,
	deleteLocalChannel,
	createFiltersSuggestions
})(LocalChannels);
