import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchSectionsGroups } from 'store/actions';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import SectionsGroupsFilters from 'components/views/sections_groups/sections_groups_filters/SectionsGroupsFilters';

// Import columns
import { createColumns } from './sections_groups_columns';

// Import translation
import { useTranslation } from 'react-i18next';

const SectionsGroups = ({ error, resources, fetchSectionsGroups }) => {
	const { t } = useTranslation();

	return (
		<PanelPageTemplate
			title={t('common:list')}
			error={error}
			type="sections_groups"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchSectionsGroups}
		>
			<SectionsGroupsFilters />
		</PanelPageTemplate>
	);
};

const mapStateToProps = ({ sections_groups: { table } }) => {
	return {
		error: table.error,
		resources: table
	};
};

SectionsGroups.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchSectionsGroups: PropTypes.func.isRequired
};

export default connect(
	mapStateToProps,
	{ fetchSectionsGroups }
)(SectionsGroups);
