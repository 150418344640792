import i18n from 'i18n';

export default ({ ranks, parallel_streams_limit, platforms }) => {
	const errors = {};
	const isInvalidRankValue = ranks?.some(isNaN);

	// ------------------ Rank ------------------
	if (!ranks) {
		errors.ranks = i18n.t('common:validation.required');
	}
	if (isInvalidRankValue) {
		errors.ranks = i18n.t('channels:system.validation.ranks');
	}
	// ------------------ PARALLEL STREAMS LIMIT ------------------
	if (!parallel_streams_limit) {
		errors.parallel_streams_limit = i18n.t('common:validation.required');
	}

	// ------------------ PLATFORMS ------------------
	if (!platforms) {
		errors.platforms = i18n.t('common:validation.required');
	}

	return errors;
};
