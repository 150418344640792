import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Alert, Spin } from 'antd';

// Import components
import ShakaPlayer from './shaka/ShakaPlayer';
import useFetchConfig from './useFetchConfig';
import AudioPlayer from './audio_player/AudioPlayer';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { getLocationType } from 'components/helpers/convert';

const MaterialPlayer = ({
	match: {
		params: { id }
	},
	location: { pathname },
	format,
	videoId,
	platform
}) => {
	const { t } = useTranslation();

	// check location type to provide proper type for player request (channel | vod)
	const type = getLocationType(pathname);
	const productType = type === 'channels' ? 'channel' : 'vod';

	const { playlist, isSuccess, isError } = useFetchConfig({
		id,
		productType,
		format,
		videoId,
		platform
	});

	const renderPlayer = () => {
		switch (format) {
			case 'file':
				return <AudioPlayer playlist={playlist} />;

			default:
				return (
					<ShakaPlayer
						playlistUrl={playlist}
						videoFormat={format.toUpperCase()}
					/>
				);
		}
	};

	return (
		<div className="material_player h-300">
			{isError && (
				<Alert
					showIcon
					type="error"
					description={t('common:errors.data_retrieve')}
					className="m-b-md animated fadeIn"
				/>
			)}
			{isSuccess ? (
				renderPlayer()
			) : (
				<div className="text-center">
					<Spin size="large" />
				</div>
			)}
		</div>
	);
};

MaterialPlayer.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	}),
	location: PropTypes.shape({
		hash: PropTypes.string.isRequired
	}),
	format: PropTypes.string.isRequired,
	videoId: PropTypes.number.isRequired
};

export default withRouter(MaterialPlayer);
