import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
	fetchChannelMaterials,
	updateChannelMaterials,
	updateChannelMaterialsError,
	deleteChannelMaterials,
	deleteChannelMaterialsError,
	addVideoToChannel
} from 'store/actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Import helpers
import { CHANNELS_MATERIALS_LIMIT } from 'components/helpers/variables';

// Import columns
import { createColumns } from 'components/utilities/table/materials_table/materials_columns';

// Import components
import MaterialsTable from 'components/utilities/table/materials_table/MaterialsTable';
import {
	Ibox,
	IboxTitle,
	IboxContentBase,
	Wraper
} from 'components/common/layout';
import { Empty } from 'antd';
import ChannelsAddMaterialsButton from 'components/common/buttons/add_materials_buttons/ChannelsAddMaterialsButton';

// Import translation
import { useTranslation } from 'react-i18next';

const ChannelsMaterials = ({
	fetchChannelMaterials,
	updateChannelMaterials,
	updateChannelMaterialsError,
	deleteChannelMaterials,
	deleteChannelMaterialsError,
	addVideoToChannel,
	error,
	isLoaded,
	main,
	preview,
	match
}) => {
	// fetch channel materials on mount
	const uuid = match.params.id;
	React.useEffect(() => {
		// Fetch resources
		fetchChannelMaterials(uuid);
		// eslint-disable-next-line
	}, []);

	const { t } = useTranslation();

	// disable Add Video button if there is already main video created
	const isMainDisabled = main.length !== 0;

	return (
		<Wraper lg="12" error={error}>
			<Ibox>
				<ChannelsAddMaterialsButton
					isLoaded={isLoaded}
					isMainDisabled={isMainDisabled}
					uuid={uuid}
					addVideoToChannel={addVideoToChannel}
				/>
				<IboxTitle title={t('common:materials_table.title')} />
				<IboxContentBase isLoaded={isLoaded}>
					<div className="h-150">
						{isLoaded &&
							(main.length || preview.length ? (
								<React.Fragment>
									{main.map((resource) => (
										<MaterialsTable
											key={resource.id}
											columns={createColumns}
											resources={resource}
											updateMaterials={updateChannelMaterials}
											updateMaterialsError={updateChannelMaterialsError}
											deleteMaterials={deleteChannelMaterials}
											deleteMaterialsError={deleteChannelMaterialsError}
											materialsLimit={CHANNELS_MATERIALS_LIMIT}
										/>
									))}
									{preview.map((resource) => (
										<MaterialsTable
											key={resource.id}
											columns={createColumns}
											resources={resource}
											updateMaterials={updateChannelMaterials}
											updateMaterialsError={updateChannelMaterialsError}
											deleteMaterials={deleteChannelMaterials}
											deleteMaterialsError={deleteChannelMaterialsError}
											materialsLimit={CHANNELS_MATERIALS_LIMIT}
										/>
									))}
								</React.Fragment>
							) : (
								<Empty description={t('common:no_data')} className="m-t-md" />
							))}
					</div>
				</IboxContentBase>
			</Ibox>
		</Wraper>
	);
};

const mapStateToProps = ({ channels: { materials } }) => {
	return {
		error: materials.error,
		isLoaded: materials.isLoaded,
		main: materials.main.data,
		preview: materials.preview.data
	};
};

ChannelsMaterials.propTypes = {
	fetchChannelMaterials: PropTypes.func.isRequired,
	updateChannelMaterials: PropTypes.func.isRequired,
	updateChannelMaterialsError: PropTypes.func.isRequired,
	deleteChannelMaterials: PropTypes.func.isRequired,
	deleteChannelMaterialsError: PropTypes.func.isRequired,
	addVideoToChannel: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	main: PropTypes.array.isRequired,
	preview: PropTypes.array.isRequired,
	match: PropTypes.object.isRequired
};

export default compose(
	connect(mapStateToProps, {
		fetchChannelMaterials,
		updateChannelMaterials,
		updateChannelMaterialsError,
		deleteChannelMaterials,
		deleteChannelMaterialsError,
		addVideoToChannel
	}),
	withRouter
)(ChannelsMaterials);
