// Import translation
import i18n from 'i18next';

// Import variables
import { PROVISIONING_MAX_PERIOD_VALUE } from 'components/helpers/variables';

export default ({ period, packet_uuid }) => {
	const errors = {};

	// ------------------ Period ------------------
	if (!period) {
		errors.period = i18n.t('subscribers:validation.period');
	}
	if (period > PROVISIONING_MAX_PERIOD_VALUE) {
		errors.period = i18n.t('subscribers:validation.period_value');
	}
	// ------------------ Packet uuid ------------------
	if (!packet_uuid) {
		errors.packet_uuid = i18n.t('subscribers:validation.packet_uuid');
	}

	return errors;
};
