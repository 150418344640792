import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
	setFilterUrlQuery,
	setFilterFormValues,
	setVodStatus
} from 'store/actions';

/**
 * Custom hook that sets initial vod table filters based on
 * state that is passed in Link component. Data needs to be filtered
 * when redirecting from statistics dashboard (to show proper data range)
 * and from provider products cards
 * @param {linkState} object
 */
export default function useVodInitialFilters({ linkState }) {
	const dispatch = useDispatch();

	useEffect(() => {
		linkState && setInitialFilters();
		// eslint-disable-next-line
	}, []);

	function setInitialFilters() {
		const filterUrlQuery = linkState?.vodFilters?.filterUrlQuery;
		const filterFormValues = linkState?.vodFilters?.filterFormValues;
		const filterStatus = linkState?.vodFilters?.filterStatus;

		filterUrlQuery && setFilterUrlQuery(filterUrlQuery)(dispatch);
		filterFormValues && setFilterFormValues(filterFormValues)(dispatch);
		filterStatus && setVodStatus(filterStatus)(dispatch);
	}
}
