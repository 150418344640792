import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Import components
import NotAuthorized from 'components/layouts/NotAuthorized';

// Import hooks
import useSystem from 'hooks/useSystem';

const RoleAuthorization = ({
	allowedRoles,
	allowedSystems,
	allowedMaxSystemTreeDepth = 3,
	component: Component
}) => {
	const { currentSystem, systemTreeDepth } = useSystem();

	const { roles } = useSelector(({ auth }) => auth.authenticate);

	const isProperSystem = allowedSystems?.some(
		(system) => system === currentSystem
	);

	// If array of allowedSystems is not passed, any system is allowed,
	// If array has some systems, we have to check if currentSystem is in allowedSystems
	const isAllowedSystem = !allowedSystems.length || isProperSystem;

	// Check if user's role is included in allowedRole array
	const isAllowedRoles = roles.some((role) => allowedRoles.includes(role));

	const isAllowedMaxSystemTreeDepth =
		systemTreeDepth <= allowedMaxSystemTreeDepth;

	return isAllowedRoles && isAllowedSystem && isAllowedMaxSystemTreeDepth ? (
		<Component />
	) : (
		<NotAuthorized />
	);
};

RoleAuthorization.defaultProps = {
	allowedSystems: []
};

RoleAuthorization.propTypes = {
	allowedRoles: PropTypes.array.isRequired,
	allowedMaxSystemTreeDepth: PropTypes.number,
	allowedSystems: PropTypes.array,
	children: PropTypes.oneOfType([
		PropTypes.array.isRequired,
		PropTypes.object.isRequired
	])
};

export default RoleAuthorization;
