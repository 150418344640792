import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup } from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react';

import { init_options } from './helpers';
import { FieldInfo, FieldError, Label } from 'components/common/inputs';
class TinyTextEditor extends Component {
	constructor(props) {
		super(props);

		this.state = { content: this.props.input.value, touched: false };
	}

	componentDidUpdate(prevProps) {
		const { input } = this.props;
		if (prevProps.input.value !== input.value) {
			// Check if prev input value is not equal to current input value
			this.setState({ content: input.value });
		}
	}

	handleEditorChange = (content, editor) => {
		const { input } = this.props;
		// Update state
		this.setState({ content });
		// Update input value
		input.onChange(content);
	};

	render() {
		const {
			id,
			input,
			required,
			label,
			fieldInfo,
			inputColumn,
			labelColumn,
			children,
			meta: { error, submitError }
		} = this.props;

		return (
			<FormGroup
				data-testid={`form-group-${input.name}`}
				row
				className={`${this.state.touched && error && 'has-error'} form-group`}
			>
				{label && (
					<Label
						id={id}
						columnWidth={labelColumn}
						required={required}
						content={label}
					/>
				)}
				<Col sm={label ? inputColumn : 12}>
					<Editor
						apiKey={process.env.REACT_APP_TINY_MCE_KEY}
						value={this.state.content}
						init={init_options}
						onEditorChange={this.handleEditorChange}
						onBlur={() => this.setState({ touched: true })}
					/>
					<FieldError
						error={
							submitError ?? (this.state.touched && (error || submitError))
						}
						inputName={input.name}
						content={error || submitError}
					/>
					<FieldInfo info={fieldInfo} />
				</Col>
				{children}
			</FormGroup>
		);
	}
}

TinyTextEditor.defaultProps = {
	required: false,
	labelColumn: 2,
	inputColumn: 10
};

TinyTextEditor.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string,
	id: PropTypes.string.isRequired,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	children: PropTypes.object,
	meta: PropTypes.object,
	fieldInfo: PropTypes.string,
	required: PropTypes.bool.isRequired
};

export default TinyTextEditor;
