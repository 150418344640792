import i18n from 'i18n';

export const BASE_FIELDS = (isEdit) => [
	{
		name: 'payment_method',
		type: 'select',
		label: i18n.t('payment_templates:fields.payment_method'),
		required: true,
		disabled: isEdit,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'price',
		type: 'number',
		label: i18n.t('payment_templates:fields.price'),
		required: true,
		step: 0.01,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'external_id',
		type: 'text',
		label: i18n.t('payment_templates:fields.external_id'),
		required: false,
		labelColumn: 3,
		inputColumn: 9
	}
];
