import { GENRES_SYSTEM_SETTINGS_FIELDS } from './system_settings_fields/genres_system_settings_fields';
import { PACKET_SYSTEM_SETTINGS_FIELDS } from './system_settings_fields/packet_system_settings_fields';
import { EPG_SYSTEM_SETTINGS_FIELDS } from './system_settings_fields/epg_system_settings_fields';
import { VOD_SYSTEM_SETTINGS_FIELDS } from './system_settings_fields/vod_system_settings_fields';
import { PROMOTIONS_SYSTEM_SETTINGS_FIELDS } from './system_settings_fields/promotions_system_settings_fields';
import { LIMITS_SYSTEM_SETTINGS_FIELDS } from './system_settings_fields/limits_system_settings_fields';
import { AVAILABILITY_SYSTEM_SETTINGS_FIELDS } from './system_settings_fields/availability_system_settings_fields';
import { PRICES_SYSTEM_SETTINGS_FIELDS } from './system_settings_fields/prices_system_settings_fields';

export const SYSTEM_SETTINGS_FIELDS = {
	vod: VOD_SYSTEM_SETTINGS_FIELDS,
	packet: PACKET_SYSTEM_SETTINGS_FIELDS,
	'products/genres': GENRES_SYSTEM_SETTINGS_FIELDS,
	'channel/programs': EPG_SYSTEM_SETTINGS_FIELDS,
	promotions: PROMOTIONS_SYSTEM_SETTINGS_FIELDS,
	'player/limits': LIMITS_SYSTEM_SETTINGS_FIELDS,
	'timetables/availability': AVAILABILITY_SYSTEM_SETTINGS_FIELDS,
	'timetables/prices': PRICES_SYSTEM_SETTINGS_FIELDS
};

export const SYSTEM_SETTINGS_FIELDS_TYPE = {
	VOD: 'vod',
	PACKET: 'packet',
	GENRES: 'products/genres',
	EPG: 'channel/programs',
	PROMOTIONS: 'promotions',
	LIMITS: 'limits',
	AVAILABILITY: 'timetables/availability',
	PRICES: 'prices'
};
