import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('affiliate_partners:table_columns.name'),
		accessor: 'name',
		Cell: ({
			row: {
				_original: { id, name }
			}
		}) => (
			<Link to={`/panel/affiliate_partners/edit/${id}/metadata`}>{name}</Link>
		)
	},
	{
		Header: i18n.t('affiliate_partners:table_columns.status'),
		accessor: 'active',
		Cell: ({
			row: {
				_original: { active }
			}
		}) => (
			<>
				{active
					? i18n.t('affiliate_partners:fields_info.active_status')
					: i18n.t('affiliate_partners:fields_info.inactive_status')}
			</>
		)
	},
	{
		Header: i18n.t('affiliate_partners:table_columns.created_at'),
		accessor: 'created_at'
	},
	{
		Header: i18n.t('affiliate_partners:table_columns.updated_at'),
		accessor: 'updated_at'
	},
	{
		Header: i18n.t('sections:table_columns.edit'),
		width: 80,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) =>
			!isReadOnly && (
				<Link
					to={`/panel/affiliate_partners/edit/${id}/metadata`}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-gear" />
				</Link>
			)
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 80,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(id)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
