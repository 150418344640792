import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH PACKET MATERIALS ********************
export const fetchPacketMaterials = (uuid) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_PACKET_MATERIALS_LOADING,
			payload: true
		});

		const { data } = await services.get(
			`/player/${uuid}/list?with_assets=true`
		);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_PACKET_MATERIALS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_PACKET_MATERIALS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
// ******************** UPDATE / DELETE PACKET MATERIALS ********************
export const updatePacketMaterials = (data, type, productId) => ({
	type: types.UPDATE_PACKET_MATERIALS,
	payload: { data, type, productId }
});

export const updatePacketMaterialsError = (error) => ({
	type: types.UPDATE_PACKET_MATERIALS_ERROR,
	payload: error
});

export const deletePacketMaterials = (id, type) => ({
	type: types.DELETE_PACKET_MATERIALS,
	payload: { id, type }
});

export const deletePacketMaterialsError = (error) => ({
	type: types.DELETE_PACKET_MATERIALS_ERROR,
	payload: error
});
