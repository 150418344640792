// Import translation
import i18n from 'i18next';

const FIELDS_VALUES = {
	email: {
		name: 'email',
		type: 'email',
		placeholder: i18n.t('common:form.email'),
		className: 'form-control',
		autoComplete: 'on',
		required: true,
		test_id: 'login-email-input'
	},
	password: {
		name: 'password',
		type: 'password',
		placeholder: i18n.t('common:form.password'),
		className: 'form-control',
		autoComplete: 'on',
		required: true,
		test_id: 'login-password-input'
	}
};
export default FIELDS_VALUES;
