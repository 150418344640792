import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import helpers
import { renderSystem } from 'components/helpers/system';
import { getUrlCheckIpService } from 'components/helpers/ip_checking_service';

export const createColumns = () => [
	{
		Header: i18n.t('devices:table_columns.subscriber'),
		accessor: 'subscriber_id',
		width: 100,
		Cell: ({
			row: {
				_original: { subscriber_id }
			}
		}) => (
			<Link to={`/panel/subscribers/edit/${subscriber_id}/metadata`}>
				{subscriber_id}
			</Link>
		)
	},
	{
		Header: i18n.t('devices:table_columns.active'),
		accessor: 'active',
		width: 75,
		sortable: true,
		headerClassName: 'text-center',
		className: 'text-center',
		Cell: ({
			row: {
				_original: { active }
			}
		}) => (
			<i
				className="fa fa-check"
				style={{
					fontSize: '16px',
					color: `${active ? '#13ce66' : '#676a6c'}`
				}}
				aria-hidden="true"
			></i>
		)
	},
	{
		Header: i18n.t('devices:table_columns.device_id'),
		accessor: 'device_id',
		className: 'scroll-cell'
	},
	{
		Header: i18n.t('devices:table_columns.device_name'),
		accessor: 'device_name',
		className: 'scroll-cell'
	},
	{
		Header: i18n.t('devices:table_columns.agent'),
		accessor: 'agent',
		className: 'scroll-cell',
		Cell: ({
			row: {
				_original: { agent, agent_version, app_version }
			}
		}) => (
			<span>
				{agent} {agent_version} {app_version}
			</span>
		)
	},
	{
		Header: i18n.t('devices:table_columns.os_system'),
		accessor: 'system',
		className: 'scroll-cell',
		Cell: ({
			row: {
				_original: { system }
			}
		}) => <span>{renderSystem(system)}</span>
	},
	{
		Header: i18n.t('devices:table_columns.producer'),
		accessor: 'producer',
		width: 100
	},
	{
		Header: i18n.t('devices:table_columns.version_name'),
		accessor: 'version_name',
		width: 120,
		className: 'scroll-cell'
	},
	{
		Header: i18n.t('devices:table_columns.created_at'),
		accessor: 'created_at',
		width: 160
	},
	{
		Header: i18n.t('devices:table_columns.platform'),
		accessor: 'platform',
		width: 100
	},
	{
		Header: i18n.t('devices:table_columns.last_login_date'),
		accessor: 'last_login_date',
		width: 160
	},
	{
		Header: i18n.t('devices:table_columns.last_login_ip'),
		accessor: 'last_login_ip',
		width: 130,
		Cell: ({
			row: {
				_original: { last_login_ip }
			}
		}) => (
			<>
				{last_login_ip && (
					<span>
						<a
							href={getUrlCheckIpService(last_login_ip)}
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fa fa-question-circle m-r-sm"></i>
						</a>
						{last_login_ip}
					</span>
				)}
			</>
		)
	}
];
