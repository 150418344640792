import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH CATEGORIES - TABLE DATA ********************
export const fetchAllCategories = (type) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_ALL_CATEGORIES_LOADING,
			payload: true
		});

		const response = await services.get(
			`/products/genres/list?type=${type}&length=-1`
		);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_ALL_CATEGORIES_SUCCESS,
			payload: { ...response.data }
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_ALL_CATEGORIES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** REMOVE CATEGORY ITEM ********************
export const removeCategoryItem = (id) => (dispatch) => {
	dispatch({
		type: types.REMOVE_CATEGORY_ITEM,
		payload: id
	});
};
