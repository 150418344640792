import React from 'react';
import { array, bool, object } from 'prop-types';

import { useDispatch } from 'react-redux';
import { fetchProviderNamesById } from 'store/actions';

// Import select options
import { select_vod_types as type } from './select_type_options';

// Import utilites
import { renderFields } from 'components/utilities/form';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({
	actors,
	writers,
	directors,
	submitting,
	genres,
	providersNames,
	providersId,
	paymentsModelsList,
	isEdit,
	currentValues,
	krritTags
}) => {
	const dispatch = useDispatch();

	// Fetch provider names based on provider id change
	const handleProviderIdChange = (value) =>
		fetchProviderNamesById(value)(dispatch);

	const selectResources = {
		actors,
		writers,
		directors,
		type,
		genres,
		provider: providersNames,
		provider_id: providersId,
		payment_models: paymentsModelsList,
		krrit_tags: krritTags
	};
	return (
		<React.Fragment>
			<div className="hr-line-solid">&nbsp;</div>
			{BASE_FIELDS({
				isEdit,
				handleProviderIdChange,
				currentValues
			}).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	actors: array,
	writers: array,
	directors: array,
	genres: array,
	submitting: bool.isRequired,
	providersNames: array,
	providersId: array,
	paymentsModelsList: array,
	isEdit: bool.isRequired,
	currentValues: object.isRequired,
	krritTags: array
};

export default Fields;
