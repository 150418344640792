import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH PACKETS ASSIGNED - TABLE DATA ********************
export const fetchProvisioningPacketsAssigned = (options, id) => async (
	dispatch,
	getState
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_PROVISIONING_PACKETS_ASSIGNED_LOADING,
			payload: true
		});

		const {
			provisioning: {
				packets_assigned: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `/product/${id}/packets?${filterUrlQuery}${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex
		)}`;

		const { data } = await services.get(url);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_PROVISIONING_PACKETS_ASSIGNED_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		//  this is unique error for just this case
		//  Request is asking for svod payment model (hardcoded for now). Sometimes we need
		//  to ask for data for vod that has another payment model (tvod etc.) API returns error for such request.
		//  However we don't want to show error but empty table instead.
		if (error.response.status === 422) {
			dispatch({
				type: types.FETCH_PROVISIONING_PACKETS_ASSIGNED_PAYMENT_MODEL_TYPE_ERROR
			});
		} else {
			dispatch({
				type: types.FETCH_PROVISIONING_PACKETS_ASSIGNED_ERROR,
				payload: checkFetchErrors(error)
			});
		}
	}
};

// ******************** SET SEARCH QUERY ********************
export const setProvisioningPacketsAssignedQuery = (query) => ({
	type: types.SET_PROVISIONING_PACKETS_ASSIGNED_SEARCH_QUERY,
	payload: query
});
