import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPacketProducts } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import ProductsSelect from 'components/utilities/products_select/ProductsSelect';

// Import products tabs
import {
	vod,
	channel
} from 'components/utilities/products_select/helpers/tabs';

const PacketsProducts = (props) => {
	const { t } = useTranslation();
	const { data, error, isLoaded, fetchPacketProducts } = props;
	const tabs = [vod, channel];

	const createSubmitData = (selectedData) => {
		const selection = { products: [] };

		selection.products = selectedData.map((item, index) => ({
			uuid: item.uuid,
			order: index + 1,
			type: item.type
		}));

		return selection;
	};

	return (
		<ProductsSelect
			api="packet"
			title={t('common:tabs.products')}
			tabs={tabs}
			openModalText={t('common:products.add_products')}
			buttonSubmitText={t('common:buttons.submit')}
			notificationName="products"
			initialData={data}
			error={error}
			isLoaded={isLoaded}
			fetchResourcesAction={fetchPacketProducts}
			createSubmitData={createSubmitData}
		/>
	);
};

PacketsProducts.propTypes = {
	data: PropTypes.array.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	fetchPacketProducts: PropTypes.func.isRequired
};

const mapStateToProps = ({ packets: { products } }) => ({
	data: products.data,
	isLoaded: products.isLoaded,
	error: products.error
});

export default connect(mapStateToProps, { fetchPacketProducts })(
	PacketsProducts
);
