import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	fetchPacketAvailability,
	deletePacketsAvailability
} from 'store/actions';

// Import helpers
import { getLocationType } from 'components/helpers/convert';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './packets_availability_columns';

// Import components
import { TableTemplate } from 'components/common/templates';

const PacketsAvailability = ({
	error,
	fetchPacketAvailability,
	deletePacketsAvailability,
	resources,
	match: {
		params: { id }
	},
	location: { pathname }
}) => {
	const { t } = useTranslation();
	const locationType = getLocationType(pathname);

	return (
		// Dispatch fetchPacketAvailability, deletePacketsAvailability action in packets_availability
		<TableTemplate
			itemId={id}
			error={error}
			type={`packets/${id}/availability`}
			typeText="availability"
			addLinkText={t('availability:buttons.add')}
			resources={resources}
			columns={(handleSelectItem, isReadOnly) =>
				createColumns(handleSelectItem, isReadOnly, locationType)
			}
			fetchResourcesAction={fetchPacketAvailability}
			deleteItemAction={deletePacketsAvailability}
			notificationDeleteSuccessTxt={t(
				'availability:notification.delete_success'
			)}
			notificationDeleteErrorTxt={t('availability:notification.delete_error')}
		></TableTemplate>
	);
};

PacketsAvailability.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchPacketAvailability: PropTypes.func.isRequired,
	deletePacketsAvailability: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	})
};

const mapStateToProps = ({ packets: { availability } }) => ({
	error: availability.table.error,
	resources: availability.table
});

export default compose(
	connect(mapStateToProps, {
		fetchPacketAvailability,
		deletePacketsAvailability
	}),
	withRouter
)(PacketsAvailability);
