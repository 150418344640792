import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import components
import MassChangesTabs from './mass_changes_tabs/MassChangesTabs';

// Import helpers
import { massChangesTabs } from './mass_changes_tabs/helpers';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

const MassChanges = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { state } = useLocation();
	const selection = state?.selection || [];
	const tabs = massChangesTabs.map((tab) => ({ ...tab, state: { selection } }));

	const hasSelections = selection?.length > 0;

	React.useEffect(() => {
		if (!hasSelections) {
			notificationHandler(
				t(`mass_changes:no_data.message`),
				t(`mass_changes:no_data.description`),
				'error',
				6
			);

			history.push('/panel/vod');
		}
		// eslint-disable-next-line
	}, [hasSelections]);

	if (!hasSelections) return null;

	return <MassChangesTabs tabs={tabs} />;
};

export default MassChanges;
