import React from 'react';
import i18n from 'i18next';

// Import components
import MainForm from '../../main_form/MainForm';
import MainImages from '../../main_file/MainImages';
import MainProducts from '../../main_products/MainProducts';
import MainAvailability from 'components/views/main/main_availability/MainAvailability';
import MainPrices from 'components/views/main/main_prices/MainPrices';
import SystemSettingsForm from 'components/utilities/system_settings/SystemSettingsForm';

export const mainTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <MainForm />
	},
	{
		name: i18n.t('common:tabs.system_settings'),
		path: 'system_settings',
		disableOnCreate: true,
		component: <SystemSettingsForm />
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <MainImages />
	},
	{
		name: i18n.t('common:tabs.products'),
		path: 'products',
		disableOnCreate: true,
		component: <MainProducts />
	},
	{
		name: i18n.t('common:tabs.availability'),
		path: 'availability',
		disableOnCreate: true,
		component: <MainAvailability />
	},
	{
		name: i18n.t('common:tabs.prices'),
		path: 'prices',
		disableOnCreate: true,
		component: <MainPrices />
	}
];
