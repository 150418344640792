import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchSystemSettings } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import hooks
import useSystemSettingsForm from './useSystemSettingsForm';

const SystemSettingsForm = ({
	history,
	error,
	isLoaded,
	initialValues,
	fetchSystemSettings,
	prevPath
}) => {
	const { t } = useTranslation();

	// Remove "/panel/" from prevPath;
	const redirectPath = prevPath.slice(7);

	const { type, formInitialValues, onSubmit } = useSystemSettingsForm({
		initialValues,
		history
	});

	return (
		<FormTemplate
			fetchResourceToEdit={fetchSystemSettings}
			error={error}
			isLoaded={isLoaded}
			fetchActionResources={{ type }}
			iboxContentTitle={t('channels:system.config_title')}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={formInitialValues}
					onSubmit={onSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields submitting={submitting} type={type} />

							<FormButtons
								isButtonDisabled={submitting || error}
								buttonText={t('common:buttons.submit')}
								path={redirectPath}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

SystemSettingsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	fetchSystemSettings: PropTypes.func.isRequired,
	prevPath: PropTypes.string.isRequired
};

const mapStateToProps = ({
	system_settings,
	previous_location: { pathname }
}) => {
	return {
		initialValues: system_settings.form.edit,
		error: system_settings.form.error,
		isLoaded: system_settings.form.isLoaded,
		prevPath: pathname
	};
};

export default compose(
	connect(mapStateToProps, { fetchSystemSettings }),
	withRouter
)(SystemSettingsForm);
