import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/common/loaders/Loader';

const IboxContentBase = ({ children, isLoaded = true, className = '' }) => (
	<div className={`ibox-content ${className} ${!isLoaded && 'sk-loading'}`}>
		<Loader isLoaded={isLoaded}>{children}</Loader>
	</div>
);

IboxContentBase.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.array.isRequired,
		PropTypes.object.isRequired
	]),
	className: PropTypes.string,
	isLoaded: PropTypes.bool
};

export default IboxContentBase;
