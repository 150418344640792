import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import { DatePicker as DatePickerAntd } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { setEpgDate } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Define initial state
const initialState = moment();

const DatePicker = ({ setEpgDate }) => {
	const { t } = useTranslation();

	const [date, setDate] = React.useState(initialState);

	// Define date format
	const dateFormat = 'YYYY-MM-DD';

	const onDateChange = (value, date) => {
		// Update state
		setDate(value);
		// Dispatch setEpgDate action in epg
		setEpgDate(date);
	};
	return (
		<FormGroup row>
			<Col sm={12}>
				<Label
					for="select-epg-date"
					className="col-form-label col-form-label-lg m-r-sm"
				>
					{t('epg:table_filters.date_from')}
				</Label>
				<DatePickerAntd
					format={dateFormat}
					id="select-epg-date"
					allowClear={false}
					onChange={onDateChange}
					value={date}
				/>
			</Col>
		</FormGroup>
	);
};

DatePicker.propTypes = {
	setEpgDate: PropTypes.func.isRequired
};

export default connect(
	null,
	{ setEpgDate }
)(DatePicker);
