import React from 'react';
import PropTypes from 'prop-types';

// Import components
import Tabs from 'components/utilities/tabs/Tabs';

const MassChangesTabs = ({ tabs }) => <Tabs tabs={tabs} />;

MassChangesTabs.propTypes = {
	tabs: PropTypes.array.isRequired
};

export default MassChangesTabs;
