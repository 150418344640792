import { message } from 'antd';
// Import translation
import i18n from 'i18next';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

// Import services
import services from 'services/services';

// Import variables
import { SYSTEM_TYPES } from 'components/helpers/variables';

export const addSubscriberPacket = async ({ values, id, toggleFormModal }) => {
	const resources = { ...values, subscriber_id: id };

	try {
		await services.post('timetables/user/packet/free/attach', resources);

		notificationHandler(
			i18n.t('messages:notifications.add'),
			`${i18n.t('messages:notifications.successfuly_add')} ${i18n.t(
				'names:notification_names.provisioning'
			)} `
		);

		toggleFormModal();
	} catch (error) {
		message.error(i18n.t('common:errors.common_error_message'));
	}
};

export const removeSubscriberPacket = async (
	packet_uuid,
	{ subscriber_id, clearPanelState }
) => {
	try {
		await services.post('timetables/user/packet/free/detach', {
			subscriber_id,
			packet_uuid
		});

		clearPanelState();

		notificationHandler(
			i18n.t('messages:notifications.deleted'),
			`${i18n.t('messages:notifications.successfuly_deleted')} ${i18n.t(
				'names:notification_names.provisioning'
			)} `
		);
	} catch (error) {
		message.error(i18n.t('common:errors.common_error_message'));
	}
};

export const getSystemParameter = (system) => {
	const { tvonline, ott } = SYSTEM_TYPES;

	let columnNumber;

	switch (system) {
		case tvonline:
			columnNumber = 1;
			break;

		case ott:
			columnNumber = 2;
			break;

		default:
			columnNumber = 3;
			break;
	}

	return `columns[${columnNumber}][search][value]=1`;
};
