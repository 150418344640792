import React from 'react';
import { string, func, bool } from 'prop-types';
import useRepeatIngesterActionButton from './useRepeatIngesterActionButton';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { Modal } from 'antd';

function RepeatIngesterActionButton(props) {
	const { t } = useTranslation();
	const {
		confirm,
		toggle,
		isOpened,
		isLoading,
		buttonClick
	} = useRepeatIngesterActionButton(props);

	return (
		<>
			<button onClick={buttonClick} className="btn btn-warning btn-lg">
				<i className="fa fa-rotate-left stat-icon" />
			</button>
			<Modal
				open={isOpened}
				onOk={confirm}
				onCancel={toggle}
				width={900}
				okText={t('common:buttons.submit')}
				cancelText={t('common:buttons.cancel')}
				title={t('common:warning')}
				confirmLoading={isLoading}
			>
				<p className="m-lg" style={{ fontSize: '1.8rem' }}>
					{t('messages:modal.repeat_ingester')}
				</p>
			</Modal>
		</>
	);
}

RepeatIngesterActionButton.defaultProps = {
	showConfirmationModal: true,
	refreshAction: () => {}
};

RepeatIngesterActionButton.propTypes = {
	url: string.isRequired,
	refreshAction: func.isRequired,
	showConfirmationModal: bool
};

export default RepeatIngesterActionButton;
