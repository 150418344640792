import React from 'react';
import PropTypes from 'prop-types';

// Import utilities
import { renderFields } from 'components/utilities/form';

// Import fields
import { getFields, selectResources } from './input_fields';

const Fields = ({ submitting, ...rest }) => (
	<React.Fragment>
		{getFields(rest).map((item, index) =>
			renderFields({ item, index, submitting, selectResources })
		)}
	</React.Fragment>
);

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired,
	packetType: PropTypes.string,
	isExternalProvider: PropTypes.bool
};

export default Fields;
