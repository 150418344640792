import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setAgreementsQuery,
	fetchAgreements,
	deleteAgreements,
	dictionaryAgreementsTypes,
	createFiltersSuggestions
} from 'store/actions';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './agreements_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const Agreements = ({
	error,
	setAgreementsQuery,
	fetchAgreements,
	deleteAgreements,
	dictionaryAgreementsTypes,
	createFiltersSuggestions,
	resources,
	filters
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	// Actions to fetch data for filters
	const filterActions = {
		dictionaryAgreementsTypes
	};

	// execute filters logic
	useFilterLogic({
		filterActions,
		createFiltersSuggestions,
		filters
	});

	return (
		// Dispatch fetchAgreements, deleteAgreements action in subscribers_panel
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="agreements"
			addLinkText={t('agreements:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchAgreements}
			deleteItemAction={deleteAgreements}
			notificationDeleteSuccessTxt={t('agreements:notification.delete_success')}
			notificationDeleteErrorTxt={t('agreements:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setAgreementsQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Agreements.propTypes = {
	error: PropTypes.bool.isRequired,
	setAgreementsQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchAgreements: PropTypes.func.isRequired,
	deleteAgreements: PropTypes.func.isRequired,
	dictionaryAgreementsTypes: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired
};

const mapStateToProps = ({ agreements, dictionary }) => {
	return {
		error: agreements.table.error,
		resources: agreements.table,
		filters: { type: dictionary.agreementTypes }
	};
};

export default connect(mapStateToProps, {
	setAgreementsQuery,
	fetchAgreements,
	deleteAgreements,
	dictionaryAgreementsTypes,
	createFiltersSuggestions
})(Agreements);
