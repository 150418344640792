import React from 'react';
import { array } from 'prop-types';

function IngesterModalLinkList({ data }) {
	return (
		<div className="d-flex flex-column m-md" style={{ gap: '2rem' }}>
			{data.map((link) => (
				<a key={link} target="_blank" rel="noopener noreferrer" href={link}>
					{link}
				</a>
			))}
		</div>
	);
}

IngesterModalLinkList.propTypes = {
	data: array
};

export default IngesterModalLinkList;
