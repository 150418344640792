import React from 'react';
import PropTypes from 'prop-types';
import services from 'services/services';

const useFetchConfig = ({ id, productType, format, videoId, platform }) => {
	// component state
	const [playlist, setPlaylist] = React.useState(''); //playlist url to pass to player as setup argument
	const [stripe, setStripe] = React.useState({});
	const [isSuccess, setIsSuccess] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const setStripeOptions = ({ productID, stripeVideoId }) => {
		const url =
			'https://r.dcs.redcdn.pl/file/o2/Vectra/cdn/public/assets/prod/vod/';
		return {
			url: `${url}${productID}/${stripeVideoId}/stripes/stripe_{index}.jpg`,
			interval: 30,
			count: 32,
			width: 160,
			height: 90
		};
	};

	// fetch player configuration
	const fetchConfig = async () => {
		try {
			setIsSuccess(false);

			// fetch videoSessionId needed to generate playlist link
			let url = `/player/${id}/configuration?type=${productType}&videoId=${videoId}`;

			if (platform) url += `&platform=${platform}`;

			const { data } = await services.get(url);

			const {
				videoSession: { videoSessionId },
				videoId: stripeVideoId
			} = data;

			// playlist url with videoSessionId
			const playlistUrl = `${process.env.REACT_APP_API_URL}/player/${id}/playlist?type=${productType}&videoSessionId=${videoSessionId}&selectedFormat=${format}`;

			setPlaylist(playlistUrl);
			setStripe(setStripeOptions({ productID: id, stripeVideoId }));
			setIsSuccess(true);
		} catch (error) {
			setIsSuccess(false);
			setIsError(true);
		}
	};

	React.useEffect(() => {
		fetchConfig();
		// eslint-disable-next-line
	}, []);

	return { playlist, stripe, isSuccess, isError };
};

useFetchConfig.propTypes = {
	id: PropTypes.string.isRequired,
	productType: PropTypes.string.isRequired,
	format: PropTypes.string.isRequired
};

export default useFetchConfig;
