import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { withRouter } from 'react-router-dom';
import { Alert } from 'antd';

// Import utilities
import { useForm } from 'components/utilities/form';

// Import components
import Loader from 'components/common/loaders/Loader';
import { Wraper } from 'components/common/layout';
import SettingsForm from './form/SettingsForm';
import ESalesButton from './ESalesButton';

// Import helpers
import { isOboxSystem } from './form/form_helpers';

const Settings = ({ location, match, fetchSettings, error, isLoaded }) => {
	// Use useForm to fetch resources
	const { itemID } = useForm({
		location,
		match,
		fetchFormResources: [],
		fetchResourceToEdit: fetchSettings
	});

	return (
		<>
			{!isOboxSystem && <ESalesButton />}
			<Wraper lg="12" error={error}>
				<Loader isLoaded={isLoaded}>
					<React.Fragment>
						{error && (
							<Alert
								showIcon
								message="Error"
								type="error"
								description="An error has occurred and the data can not be retrieved"
								className="m-t-sm"
							/>
						)}
						<SettingsForm system={itemID} />
					</React.Fragment>
				</Loader>
			</Wraper>
		</>
	);
};

Settings.propTypes = {
	location: PropTypes.object,
	match: PropTypes.object,
	fetchSettings: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		error: state.settings.form.error,
		isLoaded: state.settings.form.list.isLoaded
	};
};

export default React.memo(
	compose(connect(mapStateToProps, actions), withRouter)(Settings)
);
