import React from 'react';
import i18n from 'i18next';

// Import components
import SystemsForm from '../../systems_form/SystemsForm';
import SystemsProvidersProducts from '../../systems_products/SystemsProvidersProducts';
import Settings from 'components/views/settings/Settings';
import SystemsChannelsProducts from '../../systems_products/SystemsChannelsProducts';

export const systemsTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <SystemsForm />
	},
	{
		name: i18n.t('systems:tabs.channels'),
		path: 'system_channels',
		disableOnCreate: true,
		component: <SystemsChannelsProducts />
	},
	{
		name: i18n.t('systems:tabs.providers'),
		path: 'system_providers',
		disableOnCreate: true,
		component: <SystemsProvidersProducts />
	},
	{
		name: i18n.t('systems:tabs.settings'),
		path: 'system_settings',
		disableOnCreate: true,
		component: <Settings />
	}
];
