import React from 'react';
import PropTypes from 'prop-types';

// Import utilites
import { renderFields } from 'components/utilities/form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import fields
import { FILTER_FIELDS } from 'components/helpers/filters_helpers/filter_fields';

//Import variables
import { TABLE_TYPES } from 'components/helpers/variables';

const { LOCAL_CHANNELS } = TABLE_TYPES;

const Fields = ({ submitting, tableType, filtersSuggestions }) => {
	const { t } = useTranslation();

	const renderFilterFields = () => {
		switch (tableType) {
			case LOCAL_CHANNELS:
				return FILTER_FIELDS[tableType](filtersSuggestions).map((item, index) =>
					renderFields({
						item,
						index,
						submitting,
						selectResources: filtersSuggestions
					})
				);
			default:
				return FILTER_FIELDS[tableType].map((item, index) =>
					renderFields({
						item,
						index,
						submitting,
						selectResources: filtersSuggestions
					})
				);
		}
	};

	return (
		<React.Fragment>
			<h3>{t('filters:drawer_title')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{FILTER_FIELDS[tableType]
				? renderFilterFields()
				: t('filters:filters_unavailable')}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	tableType: PropTypes.string.isRequired
};

export default Fields;
