// Import helpers
import {
	FETCH_AGREEMENT_API,
	FETCH_VOD_API,
	FETCH_CHANNEL_API,
	FETCH_CATEGORY_API,
	FETCH_DOCUMENT_API,
	FETCH_PACKET_API,
	FETCH_PROVISIONING_API,
	FETCH_SECTION_API,
	FETCH_SECTIONS_GROUP_API,
	FETCH_LIMIT_API,
	FETCH_MAIN_API,
	FETCH_PROMOTION_API,
	FETCH_BANNER_API
} from 'components/helpers/api';
import { convertLocationType } from 'components/utilities/tabs/helpers/helpers';

//Import variables
import { CORE_PATHS, PRODUCT_TYPES } from './variables';

const {
	AGREEMENTS,
	VOD: VOD_PATH,
	CHANNELS,
	CATEGORIES,
	DOCUMENTS,
	PACKETS,
	PROVISIONING: PROVISIONING_PATH,
	SECTIONS,
	SECTIONS_GROUPS,
	LIMITS,
	BANNERS,
	MAIN: MAIN_PATH,
	PROMOTIONS
} = CORE_PATHS;

const {
	MAIN,
	PREMIUM,
	CHANNEL,
	VOD,
	SECTION,
	BANNER,
	PROVIDER,
	PROVISIONING,
	PROGRAM,
	PACKET
} = PRODUCT_TYPES;

export const getFetchUrl = ({ locationType, id }) => {
	switch (locationType) {
		case AGREEMENTS:
			return FETCH_AGREEMENT_API.replace(':id', id);

		case VOD_PATH:
			return FETCH_VOD_API.replace(':id', id);

		case CHANNELS:
			return FETCH_CHANNEL_API.replace(':id', id);

		case CATEGORIES:
			return FETCH_CATEGORY_API.replace(':id', id);

		case DOCUMENTS:
			return FETCH_DOCUMENT_API.replace(':id', id);

		case PACKETS:
			return FETCH_PACKET_API.replace(':id', id);

		case PROVISIONING_PATH:
			return FETCH_PROVISIONING_API.replace(':id', id);

		case SECTIONS:
			return FETCH_SECTION_API.replace(':id', id);

		case SECTIONS_GROUPS:
			return FETCH_SECTIONS_GROUP_API.replace(':id', id);

		case LIMITS:
			return FETCH_LIMIT_API.replace(':id', id);

		case BANNERS:
			return FETCH_BANNER_API.replace(':id', id);

		case MAIN_PATH:
			return FETCH_MAIN_API.replace(':id', id);

		case PROMOTIONS:
			return FETCH_PROMOTION_API.replace(':id', id);

		default:
			return `/${convertLocationType(locationType)}/${id}`;
	}
};

export const getRedirectUrl = ({
	id,
	type,
	subtype,
	tab = 'metadata',
	parentId = ''
}) => {
	const urlPrefix = '/panel';
	switch (type) {
		case MAIN:
		case PREMIUM:
			return `${urlPrefix}/main/edit/${id}/${tab}`;
		case CHANNEL:
			return `${urlPrefix}/channels/edit/${id}/${tab}`;
		case VOD:
			return `${urlPrefix}/vod/${subtype}/edit/${id}/${tab}`;
		case PACKET:
			return `${urlPrefix}/packets/edit/${id}/${tab}`;
		case SECTION:
			return `${urlPrefix}/sections/edit/${id}/${tab}`;
		case BANNER:
			return `${urlPrefix}/banners/edit/${id}/${tab}`;
		case PROVIDER:
			return `${urlPrefix}/providers/edit/${id}/${tab}`;
		case PROVISIONING:
			return `${urlPrefix}/provisioning/edit/${id}/${tab}`;
		case PROGRAM:
			return `${urlPrefix}/channels/${parentId}/epg/edit/${id}/metadata`;

		default:
			return '';
	}
};
