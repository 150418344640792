import i18n from 'i18next';

export const LOCAL_CHANNELS_FILTER_FIELDS = ({ county }) => {
	return [
		{
			name: 'channel_title',
			label: i18n.t('filters:fields.channel'),
			type: 'text',
			required: false,
			labelColumn: 3,
			inputColumn: 9
		},
		{
			name: 'additional_data',
			label: i18n.t('filters:fields.local_channel_position'),
			type: 'number',
			required: false,
			labelColumn: 3,
			inputColumn: 9
		},
		{
			name: 'voivodeship',
			label: i18n.t('filters:fields.voivodeship_teryt'),
			type: 'multi-select',
			required: false,
			mode: '',
			labelColumn: 3,
			inputColumn: 9
		},
		{
			name: 'county',
			label: i18n.t('filters:fields.county_teryt'),
			type: 'multi-select',
			required: false,
			mode: '',
			labelColumn: 3,
			inputColumn: 9,
			isVisible: county.length
		}
	];
};
