import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setVodQuery,
	fetchVod,
	deleteVodSeries,
	deleteVodSeason,
	deleteVod,
	setVodSubtype,
	setVodStatus,
	fetchCategories,
	createFiltersSuggestions,
	fetchProviders,
	dictionaryParentalControlRating,
	dictionaryPaymentModels,
	dictionaryVODSubstatus
} from 'store/actions';
import { useLocation } from 'react-router-dom';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';
import useVodInitialFilters from './hooks/useVodInitialFilters';
import useSystem from 'hooks/useSystem';

// Import columns
import { createColumns } from './vod_columns';

// Import utilities
import { Search } from 'components/utilities/search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterSelect from 'components/views/vod/helpers/FilterSelect';
import FilterButton from 'components/common/buttons/FilterButton';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { VOD_SUBTYPES, VOD_STATUS } from 'components/helpers/variables';
import { vodCategoriesOptions } from 'components/helpers/genres_helpers/helpers';

const Vod = ({
	error,
	setVodQuery,
	fetchVod,
	deleteVodSeries,
	deleteVodSeason,
	deleteVod,
	resources,
	setVodSubtype,
	setVodStatus,
	fetchCategories,
	createFiltersSuggestions,
	fetchProviders,
	dictionaryParentalControlRating,
	dictionaryPaymentModels,
	dictionaryVODSubstatus,
	filters
}) => {
	const { t } = useTranslation();
	const { state: linkState } = useLocation();

	const { isCurrentSystemMain } = useSystem();

	useEffect(() => {
		localStorage.removeItem('series');
		localStorage.removeItem('season');
	}, []);

	useVodInitialFilters({ linkState });

	// select proper action to delete vod based on its subtype (vod | series | season | episodes)
	const handleVodDelete = (uuid, { type }) => {
		switch (type) {
			case 'series':
				deleteVodSeries(uuid);
				break;
			case 'season':
				deleteVodSeason(uuid);
				break;

			default:
				deleteVod(uuid);
				break;
		}
	};

	// Actions to fetch data for filters
	const filterActions = {
		fetchCategories,
		fetchProviders,
		dictionaryParentalControlRating,
		dictionaryPaymentModels,
		dictionaryVODSubstatus
	};

	// execute filters logic
	useFilterLogic({
		filterActions,
		categoriesOptions: vodCategoriesOptions,
		createFiltersSuggestions,
		filters
	});

	const {
		options: {
			filters: { subtype, status }
		},
		columns
	} = resources;

	const addLinkText = isCurrentSystemMain && t('vod:buttons.add_series');

	return (
		<PanelPageTemplate
			error={error}
			type="vod/series"
			addLinkText={addLinkText}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchVod}
			resourcesForFetchAction={{ isMainVodTable: true }}
			notificationDeleteSuccessTxt={t('vod:notification.delete_success')}
			notificationDeleteErrorTxt={t('vod:notification.delete_error')}
			deleteItemAction={handleVodDelete}
			defaultSorted={[{ id: 'updated_at', desc: false }]}
			isMassChangeTable={true}
		>
			<div>
				<div className="d-flex align-items-center m-b-sm">
					<FilterSelect
						options={VOD_SUBTYPES}
						label={t('vod:select:label.subtype')}
						action={setVodSubtype}
						value={subtype}
					/>

					<FilterSelect
						options={VOD_STATUS}
						label={t('vod:select:label.status')}
						action={setVodStatus}
						value={status}
					/>
				</div>

				<div className="d-flex justify-content-end">
					<Search
						setQueryValue={setVodQuery}
						value={resources.options.filters.query}
					/>
					<FilterButton tableColumns={columns} />
				</div>
			</div>
		</PanelPageTemplate>
	);
};

Vod.propTypes = {
	error: PropTypes.bool.isRequired,
	setVodQuery: PropTypes.func.isRequired,
	fetchVod: PropTypes.func.isRequired,
	deleteVodSeries: PropTypes.func.isRequired,
	deleteVodSeason: PropTypes.func.isRequired,
	deleteVod: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchCategories: PropTypes.func.isRequired,
	setVodSubtype: PropTypes.func.isRequired,
	setVodStatus: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	fetchProviders: PropTypes.func.isRequired,
	dictionaryParentalControlRating: PropTypes.func.isRequired,
	dictionaryPaymentModels: PropTypes.func.isRequired,
	dictionaryVODSubstatus: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	error: state.vod.table.error,
	resources: state.vod.table,
	filters: {
		genres: state.categories.table.data,
		providers: state.providers.table.data,
		rating: state.dictionary.parentalControlRating,
		payment_models: state.dictionary.paymentModels,
		sub_status: state.dictionary.VODSubStatus
	}
});

export default connect(mapStateToProps, {
	setVodQuery,
	fetchVod,
	deleteVodSeries,
	deleteVodSeason,
	deleteVod,
	setVodSubtype,
	setVodStatus,
	fetchCategories,
	createFiltersSuggestions,
	fetchProviders,
	dictionaryParentalControlRating,
	dictionaryPaymentModels,
	dictionaryVODSubstatus
})(Vod);
