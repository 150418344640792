import React from 'react';
import PropTypes from 'prop-types';

// Import translation
import i18n from 'i18next';

// Import helpers
import { renderTableFields } from './helpers';
/**
 * Functional component that renders field matrix based on
 * data from API and hardcoded rows
 */
function PlatformTable({ tableData, tableRows, submitting }) {
	// create columns based on object keys received in data from API
	let tableColumns = [i18n.t('channels:platforms.options_cell')];
	if (tableData) {
		const columnNames = Object.keys(tableData);
		tableColumns = [...tableColumns, ...columnNames];
	}

	return tableData ? (
		<table className="platforms-table">
			<thead>
				<tr>
					{tableColumns.map((column, index) => (
						<th
							className={
								index === 0
									? 'platforms-table__header-options'
									: 'platforms-table__header-cell'
							}
							key={index}
						>
							{column}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{tableRows.map(({ rowName, rowValue }, index) => (
					<tr
						key={index}
						className={index % 2 ? 'platforms-table__row-dark' : null}
					>
						<td className="platforms-table__row-name">{rowName}</td>
						{Object.values(tableData).map((platformData, index) => (
							<td key={index} className="platforms-table__cell">
								{renderTableFields(platformData, rowValue, index, submitting)}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	) : (
		<h3>{i18n.t('common:no_data')}</h3>
	);
}

PlatformTable.propTypes = {
	tableData: PropTypes.object.isRequired,
	tableRows: PropTypes.array.isRequired,
	submitting: PropTypes.bool.isRequired
};

export default PlatformTable;
