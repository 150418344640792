import React from 'react';
import PropTypes from 'prop-types';
import services from 'services/services';
import { Upload, message } from 'antd';

// Import translation
import i18n from 'i18next';

// Import helpers
import { getBase64 } from 'components/helpers/convert';

// Import components
import UploadButton from './UploadButton';
import UploadModal from './UploadModal';

export class UploadImage extends React.Component {
	state = {
		previewVisible: false,
		previewImage: '',
		fileList: []
	};

	componentDidMount() {
		const { files } = this.props;
		this.setState({ fileList: files });
	}

	handleCloseModal = () => this.setState({ previewVisible: false });

	handleChange = ({ fileList }) => this.setState({ fileList });

	handlePreview = async (file) => {
		// Check the url
		if (!file.url && !file.preview) {
			// Set the preview
			file.preview = await getBase64(file.originFileObj);
		}
		// Update state
		this.setState({
			previewImage: file.url || file.preview,
			previewVisible: true
		});
	};

	uploadConfig = (onProgress) => ({
		onUploadProgress: function(progressEvent) {
			const percentCompleted = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total
			);
			onProgress({ percent: percentCompleted });
		}
	});

	handleImageUpload = async ({ file, onSuccess, onError, onProgress }) => {
		try {
			const { modelId, collection, mediaType } = this.props;
			// Format uploaded file
			const formData = new FormData();
			// Append uploaded file, and options
			formData.append('file', file);
			formData.append('collection', collection);
			formData.append('model_id', modelId);
			formData.append('model_type', mediaType);
			// Upload image
			const { data } = await services.post(
				'/assets/create',
				formData,
				this.uploadConfig(onProgress)
			);
			// Trigger on success function
			onSuccess(data, data);
			message.success(i18n.t('common:upload_pictures.upload_success'));
		} catch (error) {
			// If there is an error trigger error function
			onError();
			// Check if status is equal to 413 than show message that file is too large
			error.response.status === 413 &&
				message.error(i18n.t('common:upload_pictures.upload_error'));
		}
	};

	handleRemove = async ({ id, response }) => {
		// Directly after upload file id is stored in response object
		// and is not available in an id property
		const assetId = id ?? response?.id;

		try {
			const { fileList } = this.state;

			assetId && (await services.delete(`/assets/${assetId}`));

			const updatedFileList = fileList.filter(({ id }) => id !== assetId);

			this.handleChange({ fileList: updatedFileList });
			message.success(i18n.t('common:upload_pictures.remove_success'));
		} catch (error) {
			message.error(i18n.t('common:upload_pictures.remove_error'));
		}
	};

	render() {
		const { previewVisible, previewImage, fileList } = this.state;
		const { disabled } = this.props;

		return (
			<React.Fragment>
				<Upload
					listType="picture-card"
					fileList={fileList}
					showUploadList={{ showDownloadIcon: false }}
					onPreview={this.handlePreview}
					onChange={this.handleChange}
					onRemove={this.handleRemove}
					customRequest={this.handleImageUpload}
					disabled={disabled}
				>
					{this.props.isImagesLimit ? (
						fileList.length < this.props.imagesLimit && <UploadButton />
					) : (
						<UploadButton />
					)}
				</Upload>
				<UploadModal
					closeModal={this.handleCloseModal}
					previewVisible={previewVisible}
					previewImage={previewImage}
					modalBodyStyle={this.props.modalBodyStyle}
				/>
			</React.Fragment>
		);
	}
}

UploadImage.defaultProps = {
	imagesLimit: 1,
	isImagesLimit: true,
	disabled: false
};

UploadImage.propTypes = {
	modelId: PropTypes.string.isRequired,
	collection: PropTypes.string.isRequired,
	mediaType: PropTypes.string.isRequired,
	files: PropTypes.array.isRequired,
	modalBodyStyle: PropTypes.object,
	imagesLimit: PropTypes.number,
	isImagesLimit: PropTypes.bool,
	disabled: PropTypes.bool
};

export default UploadImage;
