import React from 'react';
import i18n from 'i18next';

// Import tabs component
import SubscribersForm from 'components/views/subscribers/subscribers_form/SubscribersForm';
import SubscribersDevices from 'components/views/subscribers/subscriber_devices/SubscribersDevices';
import SubscribersCoupons from 'components/views/subscribers/subscriber_coupons/SubscribersCoupons';
import SubscribersPackets from 'components/views/subscribers/subscribers_packets/SubscribersPackets';
import SubscribersDevicesSn from 'components/views/subscribers/subscribers_devices_sn/SubscribersDevicesSn';
import Payments from 'components/views/payments/Payments';
import Trackings from 'components/views/trackings/Trackings';
import SubscribersVerification from '../../subscribers_verification/SubscribersVerification';
import SubscribersInvoice from '../../subscribers_invoices/SubscribersInvoice';
import SubscribersConsent from '../../subscribers_consents/Consents';
import SubscribersPersonalData from '../../subscribers_personal_data/SubscribersPersonalData';

export const subscribersTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <SubscribersForm />
	},
	{
		name: i18n.t('common:tabs.personalData'),
		path: 'personalData',
		disableOnCreate: true,
		component: <SubscribersPersonalData />
	},
	{
		name: i18n.t('common:tabs.devices'),
		path: 'devices',
		disableOnCreate: true,
		component: <SubscribersDevices />
	},
	{
		name: i18n.t('common:tabs.devices_sn'),
		path: 'devices_sn',
		disableOnCreate: true,
		component: <SubscribersDevicesSn />
	},
	{
		name: i18n.t('subscribers:coupons.tabs.coupons'),
		path: 'coupons',
		disableOnCreate: true,
		component: <SubscribersCoupons />
	},
	{
		name: i18n.t('subscribers:tabs.packets'),
		path: 'packets',
		disableOnCreate: true,
		component: <SubscribersPackets />
	},
	{
		name: i18n.t('common:tabs.payments'),
		path: 'payments',
		disableOnCreate: true,
		component: <Payments />
	},
	{
		name: i18n.t('common:tabs.tracking'),
		path: 'tracking',
		disableOnCreate: true,
		component: <Trackings />
	},
	{
		name: i18n.t('common:tabs.verification'),
		path: 'verification',
		disableOnCreate: true,
		component: <SubscribersVerification />
	},
	{
		name: i18n.t('common:tabs.invoices'),
		path: 'invoices',
		disableOnCreate: false,
		component: <SubscribersInvoice />
	},
	{
		name: i18n.t('common:tabs.consents'),
		path: 'consents',
		disableOnCreate: true,
		component: <SubscribersConsent />
	}
];
