import Cookies from 'js-cookie';
import * as types from '../types';
import services from 'services/services';

// Import helpers
import { COOKIE_USER_TOKEN } from 'components/helpers/variables';
import { getCurrentSystem } from 'components/helpers/system';

// ******************** LOGIN ********************
export const signIn = (values) => async (dispatch) => {
	try {
		// Get login response
		const { data } = await services.post('/auth/login', values);

		Cookies.set(COOKIE_USER_TOKEN, data.access_token, { expires: 365 });

		const currentSystem = getCurrentSystem(data.user.systems);

		// Dispatch auth action
		dispatch({
			type: types.AUTH_USER_SUCCESS,
			payload: { ...data, currentSystem }
		});
	} catch (error) {
		// if there is an err, dispatch error action
		dispatch({
			type: types.AUTH_USER_ERROR,
			payload: true
		});
	}
};

// ******************** SIGNOUT ********************
export const signOut = (isAuthenticate = true) => async (dispatch) => {
	try {
		// Dispatch signOut action
		dispatch({
			type: types.SIGN_OUT_LOADING,
			payload: true
		});

		if (isAuthenticate) {
			await services.post('/auth/logout');
		}

		// Remove cookies
		Object.keys(Cookies.get()).forEach((name) => Cookies.remove(name));

		// Dispatch signOut action
		dispatch({
			type: types.SIGN_OUT_SUCCESS,
			payload: null
		});
	} catch (error) {
		// if there is an err, dispatch error action
		dispatch({
			type: types.SIGN_OUT_ERROR,
			payload: true
		});
	}
};

// ******************** SEND RESET EMAIL ********************
export const sendResetEmail = (email) => async (dispatch) => {
	try {
		dispatch({
			type: types.SEND_RESET_EMAIL_LOADING
		});

		const url = '/auth/reset/password';
		await services.post(url, email);

		dispatch({
			type: types.SEND_RESET_EMAIL_SUCCESS
		});
	} catch (error) {
		dispatch({
			type: types.SEND_RESET_EMAIL_ERROR
		});
	}
};

// ******************** CHECK RESET PASSWORD HASH ********************
export const checkResetPasswordHash = (hash) => async (dispatch) => {
	try {
		dispatch({
			type: types.CHECK_RESET_PASSWORD_HASH_LOADING
		});

		const url = `/auth/reset/password/validateHash/${hash}`;
		await services.post(url);

		dispatch({
			type: types.CHECK_RESET_PASSWORD_HASH_SUCCESS
		});
	} catch (error) {
		dispatch({
			type: types.CHECK_RESET_PASSWORD_HASH_ERROR
		});
	}
};

// ******************** SEND NEW PASSWORDS ********************
export const sendNewPasswords = (passwords, hash) => async (dispatch) => {
	try {
		dispatch({
			type: types.SEND_NEW_PASSWORDS_LOADING
		});

		const url = `/auth/reset/password/${hash}`;
		await services.put(url, passwords);

		dispatch({
			type: types.SEND_NEW_PASSWORDS_SUCCESS
		});
	} catch (error) {
		dispatch({
			type: types.SEND_NEW_PASSWORDS_ERROR
		});
	}
};

// ******************** FETCH USER DETAILS ********************
export const fetchAuthUserDetails = () => async (dispatch) => {
	try {
		dispatch({
			type: types.AUTH_USER_DETAILS_LOADING,
			payload: true
		});

		const { data } = await services.post('/auth/me');

		const currentSystem = getCurrentSystem(data.user.systems);

		// Dispatch auth action
		dispatch({
			type: types.AUTH_USER_DETAILS_SUCCESS,
			payload: { ...data, currentSystem }
		});
	} catch (error) {
		dispatch({
			type: types.AUTH_USER_DETAILS_ERROR,
			payload: true
		});
	}
};
