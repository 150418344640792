import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

// Import helpers
import { applyTableColumns } from 'components/helpers/table';

// Import variables
import {
	BLACKOUT_TYPES,
	BLACKOUT_PLATFORMS
} from 'components/helpers/variables';

// Import components
import { FetchDataModal } from 'components/common/elements';

// render blocked icon
const renderAvailableItem = (available) =>
	available ? (
		<i
			className="fa fa-ban"
			style={{
				fontSize: '16px',
				color: '#ff4d4f'
			}}
			aria-hidden="true"
		></i>
	) : null;

// -------- GENERATE COLUMNS DATA FOR EPG BLACKLISTS - HELPERS --------
// Generate header groups with system and platform name
export const generateBlackoutPlatformsColumns = (system) => {
	return BLACKOUT_PLATFORMS.map(({ name, value }) => ({
		Header: `${system.toUpperCase()} ${name}`,
		columns: generateBlackoutTypes(value, system)
	}));
};

// Generate base columns with headers
const generateBlackoutTypes = (platform, system) => {
	return BLACKOUT_TYPES.map((type) => {
		return {
			Header: i18n.t(`epg:table_columns.blackout_${type}`),
			accessor: `blackouts.${system}.blackout_${type}_${platform}`,
			width: 70,
			sortable: false,
			headerClassName: 'text-center',
			className: 'text-center',
			Cell: ({ row: { _original } }) =>
				renderAvailableItem(
					_original.blackouts?.[system]?.[`blackout_${type}_${platform}`]
				)
		};
	});
};

// BASIC TABLE COLUMNS
export const basicColumns = ({ channelId, queryKey, handleFetchData }) => [
	{
		Header: i18n.t('epg:table_columns.name'),
		accessor: 'title',
		minWidth: 160,
		Cell: ({
			row: {
				_original: { uuid, title }
			}
		}) => (
			<Link
				to={`/panel/channels/${channelId}/epg/edit/${uuid}/metadata`}
				title={title}
			>
				{title}
			</Link>
		)
	},
	{
		Header: i18n.t('epg:table_columns.since'),
		accessor: 'since',
		minWidth: 160
	},
	{
		Header: i18n.t('epg:table_columns.till'),
		accessor: 'till',
		minWidth: 160
	},
	{
		Header: i18n.t('epg:table_columns.category'),
		accessor: 'category',
		sortable: false
	},
	{
		Header: i18n.t('epg:table_columns.star_rating'),
		accessor: 'star_rating',
		width: 70,
		sortable: false
	},
	{
		Header: i18n.t('epg:table_columns.catchup_recording_state'),
		accessor: 'catchup_recording_state',
		width: 120,
		sortable: false
	},
	{
		Header: i18n.t('epg:table_columns.catchup_recording_required'),
		accessor: 'catchup_recording_required',
		width: 130,
		sortable: false,
		Cell: ({ row }) => {
			const { uuid, catchup_recording_required } = row._original;
			const isVisible = !!catchup_recording_required;
			return (
				<div className="d-flex justify-content-center">
					<FetchDataModal
						id={uuid}
						queryKey={queryKey}
						handleFetchData={handleFetchData}
						showIcon={isVisible}
						isVisible={isVisible}
						dataAccessPath={'data.data.media_coder.data.fullMediaCoderResponse'}
					/>
				</div>
			);
		}
	}
];

// merge basic columns and blacklist columns
export const createColumns = ({ system, ...rest }) => {
	return applyTableColumns(
		basicColumns(rest),
		generateBlackoutPlatformsColumns(system),
		5
	);
};
