import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

// Import helpers
import { getLocationType } from 'components/helpers/convert';
import services from 'services/services';
import { getFetchUrl } from 'components/helpers/url';
import { checkProductProperLocation } from 'components/helpers/product_location';

const useProductSystem = () => {
	const { id } = useParams();
	const { pathname } = useLocation();
	const queryClient = useQueryClient();

	const locationType = getLocationType(pathname);

	const queryKey = [`${locationType}_PRODUCT_SYSTEM`, id];

	// Check if the system of the current product already exists in the cache
	const currentQueryData = queryClient.getQueryData(queryKey);
	const currentProductSystem = currentQueryData?.data?.system;

	const detailsSystem = useSelector(
		(state) => state[locationType]?.form?.edit?.system
	);

	const { isProperLocation, isMetaDataLocation } = checkProductProperLocation(
		pathname
	);

	const isFetchingEnabled = !currentProductSystem && isProperLocation;

	const handleFetchData = () => {
		const url = getFetchUrl({ locationType, id });

		return services.get(url);
	};

	useEffect(() => {
		// If we are in the metadata we have already downloaded the system via redux and we don't need to download it again in the other product tabs so we add it to the queryClient memory
		if (isMetaDataLocation && detailsSystem) {
			queryClient.setQueryData(queryKey, { data: { system: detailsSystem } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMetaDataLocation, detailsSystem]);

	const { data } = useQuery({
		queryKey,
		queryFn: handleFetchData,
		enabled: isFetchingEnabled
	});

	const queryData = data?.data?.system;

	return {
		productSystem: queryData
	};
};

export default useProductSystem;
