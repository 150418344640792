import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	VOD: [],
	DEFAULT: [],
	isLoaded: true,
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.FETCH_VOD_TAGS_LOADING:
				draft.isLoaded = false;
				return;

			case types.FETCH_VOD_TAGS_SUCCESS:
				draft[action.payload.category] = action.payload.values;
				draft.isLoaded = true;
				return;

			case types.FETCH_VOD_TAGS_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
