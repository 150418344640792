import services from 'services/services';
import { generatePath } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import helpers
import { convertErrors, translateError } from 'components/helpers/error';
import { capitalizeText } from 'components/helpers/convert';

// Import utilities
import {
	createFormNotification,
	notificationHandler
} from 'components/utilities/notifications/index';

export const submitForm = async ({
	isEdit = true,
	itemID,
	resources,
	formData,
	history,
	api,
	notificationName = null,
	scrollTop = true,
	redirect = true, // sets request url
	redirectToPath = false, // redirect to specified path
	redirectToEdit = true, // redirect to edit form after creating
	redirectPath = '',
	apiSlug = '',
	callback = null, //callback function e.g. redux action
	withoutSlug = false,
	withMetadata = true,
	createSlug = true,
	createUrl = null,
	editUrl = null
}) => {
	try {
		// Some endpoints to creating don't have 'create' in url
		const additionalSlug = createSlug ? 'create' : '';
		// Check if it is edit, return additional slug or id to update
		const path = !isEdit ? itemID : additionalSlug;
		// Check method - create or update. Updating software via software form update requires using post method
		const setMethod = () => {
			let method = !isEdit ? services.put : services.post;
			// some edit / update requests require post method instead of put
			if (api === 'device/software') method = services.post;
			if (api === 'promotions' && apiSlug === 'users') method = services.post;
			if (api === 'promotions' && apiSlug === 'products/out/algorithm/generate')
				method = services.post;
			if (api === 'promotions' && apiSlug === 'products/in/algorithm/generate')
				method = services.post;
			if (api === 'channel/systemFields') method = services.post;
			if (api === 'vod/systemFields') method = services.post;
			if (api === 'packet/systemFields') method = services.post;
			if (api === 'timetables/availability/systemFields')
				method = services.post;
			if (api === 'timetables/prices/systemFields') method = services.post;
			if (api === 'player/limits/systemFields') method = services.post;
			if (api === 'promotions/systemFields') method = services.post;
			if (api === 'products/genres/systemFields') method = services.post;
			if (api === 'channel/platformFields') method = services.post;
			if (api === 'channel/programs/systemFields') method = services.post;
			if (api === 'subscriber/notifications') method = services.post;
			if (api === 'vod/massDelete') method = services.delete;
			if (api === 'timetables/availability' && apiSlug === 'update')
				method = services.post;
			if (api === 'cognito/topology/upload') method = services.post;
			if (api === 'cognito/ports/upload') method = services.post;
			if (api === 'cognito/categories/assets/metadata') method = services.post;
			if (api === 'affiliate' && apiSlug === 'codes') method = services.post;

			//Upload documents
			if (api === 'assets') method = services.post;
			//Upload CSV in devices SN
			if (api === 'device/retail') method = services.post;
			return method;
		};

		// Sometimes request url has slug
		const checkSlug = !isEdit && !withoutSlug ? `${apiSlug}` : '';

		const urlPath = path ? `/${path}/${checkSlug}` : `/${checkSlug}`;

		let url = `/${api}${redirect ? urlPath : ''}`;
		if (editUrl && createUrl) {
			url =
				!isEdit && itemID ? generatePath(editUrl, { id: itemID }) : createUrl;
		}

		// Sent request with resources
		const response = await setMethod()(url, resources, {}, formData);

		// if it was create, redirect to the edit
		if (redirectToEdit && isEdit && !redirectToPath) {
			const currentUrl = history.location.pathname;
			const partOfUrl = currentUrl.split('/create')[0];
			const elemId =
				response.data.id ||
				response.data.uuid ||
				response.data.name ||
				response.data.data.id ||
				response.data?.data?.campaign_id;
			const newUrl = `${partOfUrl}/edit/${elemId}${
				withMetadata ? '/metadata' : ''
			}`;
			history.push(newUrl);
		}

		// call callback function
		if (callback) {
			callback();
		}

		// Go back to the main panel
		// creating and editing does not redirect
		/* if you whant have redirection create an if statement
		   and change redirectToPath value to true */
		redirectToPath && history.push(`/panel/${redirectPath}`);
		// Create notification
		createFormNotification(isEdit, capitalizeText(notificationName || api));
		// Scroll to top
		scrollTop &&
			window.scroll({
				top: 0,
				behavior: 'smooth'
			});

		return response;
	} catch (error) {
		return handleErrors(error, api);
	}
};

export const handleErrors = (error, api) => {
	const errorCode = error?.response?.data?.errorCode;
	const message = error?.response?.data?.message;
	const status = error?.response?.status;
	const details = error?.response?.data?.details;

	if (errorCode) {
		const ids = Array.isArray(details) ? details?.join(', ') : undefined;
		notificationHandler(
			i18n.t('common:error'),
			i18n.t(`errorCodes:${errorCode}`, { ids }),
			'error',
			8
		);
		return null;
	}
	if (error?.response?.data?.errorCode === null) {
		notificationHandler(
			i18n.t('common:error'),
			translateError('An unexpected error has occurred'),
			'error',
			8
		);
	}
	if (status === 409 && api === 'device/retail') {
		notificationHandler(
			i18n.t('common:error'),
			translateError('resource_exist'),
			'error',
			8
		);
	}
	if ((status === 406 || status === 409) && api !== 'device/retail') {
		notificationHandler(
			i18n.t('common:error'),
			translateError(message),
			'error',
			8
		);
		// Throw new errors
		return null;
	}
	if (status !== 401 && error.response.data.validator) {
		// Convert Errors
		const errors = convertErrors(error.response.data.validator.errors);
		// CreateNotification
		Object.entries(errors).forEach(([key, value]) => {
			notificationHandler(capitalizeText(key), value, 'error', 8);
		});
		// Throw new errors
		return { ...errors };
	}
};
