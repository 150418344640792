// ******************** AFFILIATE_PARTNERS DATA TABLE ********************
// -------------------- FETCH AFFILIATE_PARTNERS --------------------
export const FETCH_AFFILIATE_PARTNERS_LOADING =
	'FETCH_AFFILIATE_PARTNERS_LOADING';
export const FETCH_AFFILIATE_PARTNERS_SUCCESS =
	'FETCH_AFFILIATE_PARTNERS_SUCCESS';
export const FETCH_AFFILIATE_PARTNERS_ERROR = 'FETCH_AFFILIATE_PARTNERS_ERROR';

// -------------------- DELETE AFFILIATE PARTNER --------------------
export const DELETE_AFFILIATE_PARTNER_LOADING =
	'DELETE_AFFILIATE_PARTNER_LOADING';
export const DELETE_AFFILIATE_PARTNER_SUCCESS =
	'DELETE_AFFILIATE_PARTNER_SUCCESS';
export const DELETE_AFFILIATE_PARTNER_ERROR = 'DELETE_AFFILIATE_PARTNER_ERROR';

// ******************** AFFILIATE PARTNER ********************************
// -------------------- FETCH AFFILIATE_PARTNER --------------------
export const FETCH_AFFILIATE_PARTNER_LOADING =
	'FETCH_AFFILIATE_PARTNER_LOADING';
export const FETCH_AFFILIATE_PARTNER_SUCCESS =
	'FETCH_AFFILIATE_PARTNER_SUCCESS';
export const FETCH_AFFILIATE_PARTNER_ERROR = 'FETCH_AFFILIATE_PARTNER_ERROR';

// -------------------- FETCH AFFILIATE PARTNER CODES --------------------
export const FETCH_AFFILIATE_PARTNER_CODES_LOADING =
	'FETCH_AFFILIATE_PARTNER_CODES_LOADING';
export const FETCH_AFFILIATE_PARTNER_CODES_SUCCESS =
	'FETCH_AFFILIATE_PARTNER_CODES_SUCCESS';
export const FETCH_AFFILIATE_PARTNER_CODES_ERROR =
	'FETCH_AFFILIATE_PARTNER_CODES_ERROR';

// -------------------- DELETE AFFILIATE PARTNER --------------------
export const DELETE_AFFILIATE_PARTNER_CODES_LOADING =
	'DELETE_AFFILIATE_PARTNER_CODES_LOADING';
export const DELETE_AFFILIATE_PARTNER_CODES_SUCCESS =
	'DELETE_AFFILIATE_PARTNER_CODES_SUCCESS';
export const DELETE_AFFILIATE_PARTNER_CODES_ERROR =
	'DELETE_AFFILIATE_PARTNER_CODES_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_AFFILIATE_PARTNERS_SEARCH_QUERY =
	'SET_AFFILIATE_PARTNERS_SEARCH_QUERY';
