import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button, FormGroup } from 'reactstrap';

const InputSearch = ({
	filters,
	field,
	placeholder,
	handleOnChangeFilter,
	handleClearFilter
}) => (
	<FormGroup className="m-r-sm">
		<Input
			placeholder={placeholder}
			value={filters[field] || ''}
			name={field}
			className="search__input"
			onChange={handleOnChangeFilter}
		/>
		{filters[field] && (
			<div className="search__clear">
				<Button onClick={handleClearFilter(field)} className="btn__clear">
					<i className="fa fa-times" />
				</Button>
			</div>
		)}
	</FormGroup>
);

InputSearch.propTypes = {
	filters: PropTypes.object.isRequired,
	field: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	handleOnChangeFilter: PropTypes.func.isRequired,
	handleClearFilter: PropTypes.func.isRequired
};

export default InputSearch;
