// Import translation
import i18n from 'i18next';

export default ({ name }) => {
	const errors = {};

	// ------------------ Name ------------------
	if (!name) {
		errors.name = i18n.t('payment_templates:errors.name');
	}
	return errors;
};
