import React from 'react';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import utilities
import { renderFields } from 'components/utilities/form/renderer/renderFields';

const Fields = ({ submitting }) => {
	return (
		<>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}
		</>
	);
};

export default Fields;
