import React from 'react';

// Import translation
import { useTranslation } from 'react-i18next';

//Import components
import { Toggle } from 'components/utilities';
import { Button } from 'reactstrap';

const EpisodesModalWrapper = ({ children }) => {
	const { t } = useTranslation();

	return (
		<Toggle>
			{({ on, toggle }) => (
				<React.Fragment>
					<div className="row">
						<div className="d-flex justify-content-end align-items-center col-sm-12 m-b-md">
							<Button
								className="m-l-sm btn btn-secondary"
								color="primary"
								onClick={toggle}
							>
								{t('vod:buttons.add_episodes')}
							</Button>
						</div>
					</div>
					{children({ on, toggle })}
				</React.Fragment>
			)}
		</Toggle>
	);
};

export default EpisodesModalWrapper;
