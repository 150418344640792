// ******************** FETCH TAGS DATA TABLE ********************
export const FETCH_TAGS_LOADING = 'FETCH_TAGS_LOADING';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_ERROR = 'FETCH_TAGS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_TAGS_SEARCH_QUERY = 'SET_TAGS_SEARCH_QUERY';

// ******************** FETCH TAG DETAILS ********************
export const FETCH_TAG_DETAILS_LOADING = 'FETCH_TAG_DETAILS_LOADING';
export const FETCH_TAG_DETAILS_SUCCESS = 'FETCH_TAG_DETAILS_SUCCESS';
export const FETCH_TAG_DETAILS_ERROR = 'FETCH_TAG_DETAILS_ERROR';

// ******************** DELETE TAG ITEM ********************
export const DELETE_TAG_ITEM_LOADING = 'DELETE_TAG_ITEM_LOADING';
export const DELETE_TAG_ITEM_SUCCESS = 'DELETE_TAG_ITEM_SUCCESS';
export const DELETE_TAG_ITEM_ERROR = 'DELETE_TAG_ITEM_ERROR';

// ******************** FETCH VOD TAGS ********************
export const FETCH_VOD_TAGS_LOADING = 'FETCH_VOD_TAGS_LOADING';
export const FETCH_VOD_TAGS_SUCCESS = 'FETCH_VOD_TAGS_SUCCESS';
export const FETCH_VOD_TAGS_ERROR = 'FETCH_VOD_TAGS_ERROR';
