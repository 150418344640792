import { useEffect } from 'react';

const useForm = ({
	title = '',
	location,
	match,
	fetchResourceToEdit,
	fetchFormResources,
	fetchActionResources
}) => {
	// Get pathname
	const isEdit = location.pathname.split('/').includes('create');

	// Check the params id
	const itemID = match.params.id || null;

	// Handle fetch form resources
	const handleFetchResources = async () => {
		// Fetch fag category
		// Dispatch an action
		if (fetchFormResources.length > 0) {
			// eslint-disable-next-line
			for (const fetchResource of fetchFormResources) {
				await fetchResource();
			}
		}
		// If there is resource edit request
		// Dispatch an action
		!isEdit && fetchResourceToEdit(itemID, fetchActionResources);
	};

	useEffect(() => {
		// Fetch resources
		handleFetchResources();
		// eslint-disable-next-line
	}, []);

	// Define breadcrumbs
	const checkBreadcrumbs = isEdit ? 'Create' : 'Edit';
	const breadcrumbs = [`${title} List`, `${checkBreadcrumbs} ${title}`];

	return {
		breadcrumbs,
		isEdit,
		itemID
	};
};

export default useForm;
