import UAParser from 'ua-parser-js';

/**
 * Function for determinig if form is in create or isEdit mode,
 * based on component location
 * @param {string} pathname
 */
export const isCreatePath = (pathname) => {
	return pathname.split('/').includes('create');
};

// Get user agent
export const getUserAgent = () => {
	const uastring = navigator.userAgent;
	const parser = new UAParser();
	const { browser, os } = parser.setUA(uastring).getResult();
	const isSafari = browser.name.toLowerCase() === 'safari';
	return { browser, os, isSafari };
};
