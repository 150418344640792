import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	edit: {
		free_vod: false,
		external_ids: [],
		url: '',
		active: false,
		slug: null,
		visible_primary: true,
		visible_secondary: true
	},
	isLoaded: true,
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR PACKET STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// ****************** FETCH PACKET ******************
			case types.FETCH_PACKET_LOADING:
				draft.isLoaded = false;
				return;

			case types.FETCH_PACKET_SUCCESS:
				draft.edit = action.payload;
				draft.isLoaded = true;
				return;

			case types.FETCH_PACKET_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
