import React from 'react';
import { Label as ReactStrapLabel } from 'reactstrap';
import PropTypes from 'prop-types';
import Asterisk from './Asterisk';

function Label({ required, content, id, columnWidth }) {
	return (
		<ReactStrapLabel
			for={id}
			sm={columnWidth}
			className="col-form-label col-form-label-lg"
		>
			{content} {required && <Asterisk />}
		</ReactStrapLabel>
	);
}

Label.defaultProps = {
	required: false,
	content: ''
};

Label.propTypes = {
	required: PropTypes.bool.isRequired,
	content: PropTypes.string.isRequired
};

export default Label;
