import React from 'react';

import { mapPaymentMethodToTag, mapPaymentMethodToIcon } from '../../helpers';

const SelectPaymentMethod = ({ payment_method }) => {
	const { name } = mapPaymentMethodToTag(payment_method);
	return (
		<div className="d-flex justify-content-start align-items-center">
			<span className="d-flex justify-content-start align-items-center m-r-sm">
				{mapPaymentMethodToIcon(payment_method)}
			</span>
			<span className="text-muted">{name}</span>
		</div>
	);
};

export default SelectPaymentMethod;
