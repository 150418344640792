// fetch playlist data
export const fetchPlaylistData = async ({ playlist, isProtected = true }) => {
	const playlistData = await fetch(playlist).then((res) => res.json());
	let certificate;

	if (isProtected) {
		certificate = await fetch(playlistData.drm.FAIRPLAY.cert).then((res) =>
			res.arrayBuffer()
		);
	}

	return { playlistData, certificate };
};

// player configuration
export const getPlayerConfiguration = (drm, certificate) => ({
	preferredAudioLanguage: 'pl',
	preferredTextLanguage: 'pl',
	streaming: {
		alwaysStreamText: true,
		bufferBehind: 10,
		bufferingGoal: 10,
		rebufferingGoal: 5
	},
	drm: {
		servers: {
			'com.widevine.alpha': drm.WIDEVINE,
			'com.microsoft.playready': drm.PLAYREADY,
			'com.apple.fps.1_0': drm.FAIRPLAY?.src
		},
		advanced: {
			'com.apple.fps.1_0': {
				serverCertificate: new Uint8Array(certificate)
			},
			'com.widevine.alpha': {
				videoRobustness: 'SW_SECURE_CRYPTO',
				audioRobustness: 'SW_SECURE_CRYPTO'
			}
		}
	},
	abr: {
		enabled: true
	}
});

export const UI_CONFIG = {
	addBigPlayButton: true,
	seekBarColors: {
		base: '#666',
		played: '#21619A',
		buffered: '#666'
	}
};
