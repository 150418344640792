// ******************** GEOLOCKS DATA TABLE ********************
export const FETCH_GEOLOCKS_LOADING = 'FETCH_GEOLOCKS_LOADING';
export const FETCH_GEOLOCKS_SUCCESS = 'FETCH_GEOLOCKS_SUCCESS';
export const FETCH_GEOLOCKS_ERROR = 'FETCH_GEOLOCKS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_GEOLOCKS_SEARCH_QUERY = 'SET_GEOLOCKS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_GEOLOCKS_TABLE_SORTING = 'SAVE_GEOLOCKS_TABLE_SORTING';

// ******************** DELETE GEOLOCK ********************
export const DELETE_GEOLOCK_LOADING = 'DELETE_GEOLOCK_LOADING';
export const DELETE_GEOLOCK_SUCCESS = 'DELETE_GEOLOCK_SUCCESS';
export const DELETE_GEOLOCK_ERROR = 'DELETE_GEOLOCK_ERROR';
