// Import translation
import i18n from 'i18next';

// Import helpers
import { checkDecimalPlaces } from '../helpers';
import { SUBSCRIPTION_PAYMENT_TYPES } from 'components/helpers/variables';

export default ({ range, price, period, paymentMethod }) => {
	const errors = {};
	const isSubscriptionPayment = SUBSCRIPTION_PAYMENT_TYPES.includes(
		paymentMethod
	);

	// ------------------ Range ------------------
	if (!range || !range.length) {
		errors.range = i18n.t('prices:validation.range_required');
	}

	// ------------------ Price ------------------
	if (!price) {
		errors.price = i18n.t('prices:validation.price_required');
	} else if (price <= 0) {
		errors.price = i18n.t('prices:validation.price_length');
	} else if (checkDecimalPlaces(price) >= 3) {
		errors.price = i18n.t('prices:validation.price_step');
	}

	// ------------------ Period ------------------
	if (period == null) {
		errors.period = i18n.t('prices:validation.period_required');
	} else if (period < 0 && !isSubscriptionPayment) {
		errors.period = i18n.t('common:form.validation.negative_value');
	}

	// ------------------ PaymentMethod ------------------
	if (!paymentMethod) {
		errors.paymentMethod = i18n.t('prices:validation.payment_method_required');
	}

	return errors;
};
