import { useEffect, useState } from 'react';
import services from 'services/services';

// Import helpers
import { checkFetchErrors } from 'components/helpers/error';
import { SECTION_PREVIEW_LIMIT } from 'components/helpers/variables';

const useSectionsPreview = (itemId) => {
	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState([]); // list of products to display
	const [isEnd, setIsEnd] = useState(false); // bool that checks if there is more data to display
	const [fetchError, setFetchError] = useState(false);

	const fetchSectionsPreview = async () => {
		try {
			// set loading only when there is no data. In other case spinner is handled by InfiniteScroll
			if (products.length === 0) setLoading(true);

			const { data } = await services.get(
				`/section/${itemId}/content?offset=${products.length}&limit=${SECTION_PREVIEW_LIMIT}`
			);

			setProducts([...products, ...data.data]);
			setLoading(false);
			if (data.data.length === 0 || data.data.length < SECTION_PREVIEW_LIMIT) {
				setIsEnd(true);
			}
		} catch (error) {
			setFetchError(checkFetchErrors(error));
		}
	};

	useEffect(() => {
		fetchSectionsPreview();
		// eslint-disable-next-line
	}, []);

	return { products, isEnd, loading, fetchError, fetchSectionsPreview };
};

export default useSectionsPreview;
