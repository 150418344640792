// Import translation
import i18n from 'i18next';

export default ({
	start_date,
	version_name,
	version_code,
	sn_to,
	sn_from,
	package_id,
	file,
	platform
}) => {
	const errors = {};

	// ------------------ start_date ------------------
	if (!start_date) {
		errors.start_date = i18n.t('software:form:validation.start_date');
	}
	// ------------------ version_name ------------------
	if (!version_name) {
		errors.version_name = i18n.t('software:form:validation.version_name');
	}
	// ------------------ version_code ------------------
	if (!version_code) {
		errors.version_code = i18n.t('software:form:validation.version_code');
	}
	// // ------------------ sn_to ------------------
	// if (!sn_to) {
	// 	errors.sn_to = i18n.t('software:form:validation.sn_to');
	// }
	// // ------------------ sn_from ------------------
	// if (!sn_from) {
	// 	errors.sn_from = i18n.t('software:form:validation.sn_from');
	// }
	// ------------------ package_id ------------------
	if (!package_id) {
		errors.package_id = i18n.t('software:form:validation.package_id');
	}
	// ------------------ file ------------------
	if (!file) {
		errors.file = i18n.t('software:form:validation.file');
	}
	// ------------------ platform ------------------
	if (!platform) {
		errors.platform = i18n.t('software:form:validation.platform');
	}

	return errors;
};
