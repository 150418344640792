import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	fetchPaymentTemplatesDropdown,
	fetchPaymentTemplatesDetails
} from 'store/actions';
import { useMutation } from 'react-query';

// Import helpers
import { periods } from '../fields/input_fields';
import { dateFormat, notifyError, notifySuccess } from '../fields/helpers';
import moment from 'moment';

const useValues = ({ prevPath, useTemplate, id, templateDetails }) => {
	const history = useHistory();
	const isMain = prevPath.split('/').includes('main');
	const dispatch = useDispatch();
	const [initialDates, setInitialDates] = useState(null);

	const { mutate } = useMutation({
		mutationFn: useTemplate,
		onSuccess: notifySuccess,
		onError: notifyError,
		onSettled: () => history.push(prevPath)
	});

	useEffect(() => {
		fetchPaymentTemplatesDropdown()(dispatch);
		fetchPaymentTemplatesDetails(id)(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initialValues = {
		period: periods[0].value,
		range: initialDates
	};

	const convertRawDates = (data) => {
		const { since, till } = data.reduce(
			(obj, { since, till }) => {
				return {
					since: [...obj.since, moment(since)],
					till: [...obj.till, moment(till)]
				};
			},
			{ since: [], till: [] }
		);
		const minDate = moment.min(since);
		const maxDate = moment.max(till);
		return [minDate, maxDate];
	};

	const startConversion = useCallback(() => {
		const result = convertRawDates(templateDetails);
		setInitialDates(result);
	}, [templateDetails]);

	useEffect(() => {
		startConversion();
	}, [startConversion]);

	const redirectPath = prevPath.slice(7);

	const handleOnSubmit = () => async ({ template, range, period }) => {
		const formData = new FormData();
		formData.append('since', range[0].format(dateFormat));
		formData.append('till', range[1].format(dateFormat));
		formData.append('period', period?.value ?? period);

		mutate({ templateId: template, productId: id, data: formData });
	};

	return {
		handleOnSubmit,
		redirectPath,
		isMain,
		initialValues
	};
};

export default useValues;
