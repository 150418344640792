import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	fetchIngesterEncodingVodDetails,
	refreshIngesterEncoding
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

// Import columns
import { createColumns } from './ingester_encoding_vod_details_columns';

// Import components
import ReactTable from 'react-table';
import { Empty } from 'antd';

// Import api
import { ENCODER_ID_DATA } from 'components/helpers/api';

// Import helpers
import { fetchEncoderIdData } from 'components/helpers/encoding_helpers/encoding_helpers';

function IngesterEncodingVodDetails({
	vodId,
	resources,
	fetchIngesterEncodingVodDetails,
	refreshIngesterEncoding
}) {
	const { t } = useTranslation();

	useEffect(() => {
		fetchIngesterEncodingVodDetails(vodId);
		// eslint-disable-next-line
	}, [JSON.stringify(resources)]);

	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	return resources.length > 0 ? (
		<ReactTable
			data={resources}
			columns={createColumns({
				isReadOnly,
				queryKey: ENCODER_ID_DATA,
				handleFetchData: fetchEncoderIdData,
				refreshAction: refreshIngesterEncoding
			})}
			pageSize={resources.length}
			showPagination={false}
		/>
	) : (
		<Empty description={t('common:no_data')} />
	);
}

IngesterEncodingVodDetails.propTypes = {
	vodId: PropTypes.string.isRequired,
	resources: PropTypes.array.isRequired,
	fetchIngesterEncodingVodDetails: PropTypes.func.isRequired,
	refreshIngesterEncoding: PropTypes.func
};

const mapStateToProps = ({
	ingester: {
		encoding_table: { data }
	}
}) => ({
	resources: data
});

export default connect(mapStateToProps, {
	fetchIngesterEncodingVodDetails,
	refreshIngesterEncoding
})(IngesterEncodingVodDetails);
