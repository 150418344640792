import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { RangePickerLabel } from 'components/common/inputs';

const Fields = ({ submitting }) => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<Field
				disabled={submitting}
				name="range"
				type="text"
				placeholder={[
					t('availability:fields.since'),
					t('availability:fields.till')
				]}
				label={t('availability:fields.range')}
				id="range"
				required={true}
				showTime={true}
				rangeFormat="YYYY-MM-DD HH:mm:ss"
				component={RangePickerLabel}
			/>
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
