// ******************** SECTIONS DATA TABLE ********************
export const FETCH_SECTIONS_LOADING = 'FETCH_SECTIONS_LOADING';
export const FETCH_SECTIONS_SUCCESS = 'FETCH_SECTIONS_SUCCESS';
export const FETCH_SECTIONS_ERROR = 'FETCH_SECTIONS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_SECTIONS_SEARCH_QUERY = 'SET_SECTIONS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_SECTIONS_TABLE_SORTING = 'SAVE_SECTIONS_TABLE_SORTING';

// ******************** DELETE SECTION ********************
export const DELETE_SECTION_LOADING = 'DELETE_SECTION_LOADING';
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS';
export const DELETE_SECTION_ERROR = 'DELETE_SECTION_ERROR';

// ******************** SECTIONS PRODUCTS ********************
export const FETCH_SECTION_PRODUCTS_LOADING = 'FETCH_SECTION_PRODUCTS_LOADING';
export const FETCH_SECTION_PRODUCTS_SUCCESS = 'FETCH_SECTION_PRODUCTS_SUCCESS';
export const FETCH_SECTION_PRODUCTS_ERROR = 'FETCH_SECTION_PRODUCTS_ERROR';

// ******************** SECTION ALGORITHMS ********************
export const FETCH_SECTION_ALGORITHMS_LOADING =
	'FETCH_SECTION_ALGORITHMS_LOADING';
export const FETCH_SECTION_ALGORITHMS_SUCCESS =
	'FETCH_SECTION_ALGORITHMS_SUCCESS';
export const FETCH_SECTION_ALGORITHMS_ERROR = 'FETCH_SECTION_ALGORITHMS_ERROR';

// ******************** SECTION DATA FORM ********************
export const FETCH_SECTION_LOADING = 'FETCH_SECTION_LOADING';
export const FETCH_SECTION_SUCCESS = 'FETCH_SECTION_SUCCESS';
export const FETCH_SECTION_ERROR = 'FETCH_SECTION_ERROR';

// ******************** SECTIONS PAGES ********************
export const FETCH_SECTIONS_PAGES_LOADING = 'FETCH_SECTIONS_PAGES_LOADING';
export const FETCH_SECTIONS_PAGES_SUCCESS = 'FETCH_SECTIONS_PAGES_SUCCESS';
export const FETCH_SECTIONS_PAGES_ERROR = 'FETCH_SECTIONS_PAGES_ERROR';

// ******************** SAVE SORTING OPTIONS (SECTIONS PAGES)  ********************
export const SAVE_SECTIONS_PAGES_TABLE_SORTING =
	'SAVE_SECTIONS_PAGES_TABLE_SORTING';

// ******************** FILES ********************
// -------------------- COVER --------------------
export const FETCH_SECTION_ITEM_COVER_FILES_LOADING =
	'FETCH_SECTION_ITEM_COVER_FILES_LOADING';
export const FETCH_SECTION_ITEM_COVER_FILES_SUCCESS =
	'FETCH_SECTION_ITEM_COVER_FILES_SUCCESS';
export const FETCH_SECTION_ITEM_COVER_FILES_ERROR =
	'FETCH_SECTION_ITEM_COVER_FILES_ERROR';
// -------------------- COVER_LARGE --------------------
export const FETCH_SECTION_ITEM_COVER_LARGE_FILES_LOADING =
	'FETCH_SECTION_ITEM_COVER_LARGE_FILES_LOADING';
export const FETCH_SECTION_ITEM_COVER_LARGE_FILES_SUCCESS =
	'FETCH_SECTION_ITEM_COVER_LARGE_FILES_SUCCESS';
export const FETCH_SECTION_ITEM_COVER_LARGE_FILES_ERROR =
	'FETCH_SECTION_ITEM_COVER_LARGE_FILES_ERROR';
