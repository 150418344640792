import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setMarketingCampaignQuery,
	fetchMarketingCampaigns,
	deleteMarketingCampaign,
	setMarketingCampaignStatus,
	createFiltersSuggestions
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './marketing_campaigns_columns';

// Import utilities
import { Search } from 'components/utilities/search';
import useFilterLogic from 'hooks/useFilterLogic';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterSelect from 'components/views/vod/helpers/FilterSelect';
import FilterButton from 'components/common/buttons/FilterButton';

// Import variables
import { MARKETING_CAMPAIGNS_STATUS } from 'components/helpers/variables';

const MarketingCampaigns = ({
	error,
	setMarketingCampaignQuery,
	fetchMarketingCampaigns,
	deleteMarketingCampaign,
	setMarketingCampaignStatus,
	createFiltersSuggestions,
	resources
}) => {
	const { t } = useTranslation();
	const {
		columns,
		options: {
			filters: { status }
		}
	} = resources;

	useFilterLogic({
		createFiltersSuggestions,
		filters: { campaign_status: MARKETING_CAMPAIGNS_STATUS }
	});

	return (
		<PanelPageTemplate
			error={error}
			type="marketing_campaigns"
			addLinkText={t('marketing_campaigns:buttons.add_new')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchMarketingCampaigns}
			deleteItemAction={deleteMarketingCampaign}
			defaultSorted={[{ id: 'campaign_id', desc: true }]}
		>
			<div>
				<div className="d-flex align-items-center m-b-sm">
					<FilterSelect
						options={MARKETING_CAMPAIGNS_STATUS}
						label={t('vod:select:label.status')}
						action={setMarketingCampaignStatus}
						value={status}
					/>
				</div>

				<div className="d-flex justify-content-end">
					<Search
						setQueryValue={setMarketingCampaignQuery}
						value={resources.options?.filters?.query}
					/>
					<FilterButton tableColumns={columns} />
				</div>
			</div>
		</PanelPageTemplate>
	);
};

MarketingCampaigns.propTypes = {
	error: PropTypes.bool.isRequired,
	setMarketingCampaignQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchMarketingCampaigns: PropTypes.func.isRequired,
	deleteMarketingCampaign: PropTypes.func.isRequired,
	setMarketingCampaignStatus: PropTypes.func,
	createFiltersSuggestions: PropTypes.func
};

const mapStateToProps = ({ marketing_campaigns }) => ({
	error: marketing_campaigns.table.error,
	resources: marketing_campaigns.table
});

export default connect(mapStateToProps, {
	setMarketingCampaignQuery,
	fetchMarketingCampaigns,
	deleteMarketingCampaign,
	setMarketingCampaignStatus,
	createFiltersSuggestions
})(MarketingCampaigns);
