import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	edit: {
		active: false,
		system: ''
	},
	isLoaded: true,
	error: false,

	// ******** TO GET THE PACKETS OF THE DEVICE SN **********
	columns: [],
	data: [],
	deleteItem: { success: false, error: false },
	loading: true,
	options: {
		filters: { query: '' },
		page: 0,
		pages: 0,
		sorted: { columnIndex: 0, type: 'asc' },
		startIndex: 0,
		total_results: 0
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };
			// ****************** FETCH DEVICE_SN ******************
			case types.FETCH_DEVICE_SN_LOADING:
				draft.isLoaded = false;
				draft.error = false;
				draft.edit = {};
				return;

			case types.FETCH_DEVICE_SN_SUCCESS:
				draft.edit = action.payload;
				draft.isLoaded = true;
				return;

			case types.FETCH_DEVICE_SN_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			// ****************** FETCH DEVICES SN PACKETS ******************
			case types.FETCH_DEVICE_SN_PACKETS_LOADING:
				draft.loading = true;
				draft.error = false;
				return;

			case types.FETCH_DEVICE_SN_PACKETS_SUCCESS:
				draft.loading = false;
				draft.error = false;
				draft.data = action.payload.data;
				return;

			case types.FETCH_DEVICE_SN_PACKETS_ERROR:
				draft.error = action.payload;
				draft.loading = false;
				draft.data = [];
				return;

			default:
				return state;
		}
	});
