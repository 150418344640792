import React from 'react';
import { useDispatch } from 'react-redux';

// Import actions
import { fetchChannelLogo, fetchChannelLogoInverted } from 'store/actions';

// Import components
import Image from 'components/utilities/images/Image';
import InfoTitle from 'components/utilities/info/InfoTitle';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { IMAGE_SIZE_INFO } from 'components/helpers/info_helpers/info_texts';

const ChannelsImages = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	return (
		<>
			<InfoTitle title={IMAGE_SIZE_INFO} />
			<div className="row">
				<div className="col-lg-6">
					<Image
						title={t('common:images.logo')}
						fetchResourcesAction={(id) => fetchChannelLogo(id)(dispatch)}
						collectionType="logo"
						mediaType="channel"
						storePathSelector="channels"
						isDarkBackground={true}
					/>
				</div>
				<div className="col-lg-6">
					<Image
						title={t('common:images.logo_inverted')}
						fetchResourcesAction={(id) =>
							fetchChannelLogoInverted(id)(dispatch)
						}
						collectionType="logo_inverted"
						mediaType="channel"
						storePathSelector="channels"
					/>
				</div>
			</div>
		</>
	);
};

export default ChannelsImages;
