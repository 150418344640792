// Import translation
import i18n from 'i18next';

export default ({ title, description, short_description, order }) => {
	const errors = {};

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('packets:validation.title');
	} else if (title.length < 2 || title.length > 200) {
		errors.title = i18n.t('packets:validation.title_length');
	}
	// ------------------ Description ------------------
	if (!description) {
		errors.description = i18n.t('packets:validation.description');
	}
	// ------------------ Description short ------------------
	if (!short_description) {
		errors.short_description = i18n.t('packets:validation.short_description');
	}
	// ------------------ Order ------------------
	if (!order) {
		errors.order = i18n.t('packets:validation.order');
	}
	return errors;
};
