import React from 'react';
import useTabsLogic from './useTabsLogic';
import { useLocation } from 'react-router-dom';

// Import components
import Links from './links/Links';
import Routes from './routes/Routes';
import TabsBreadcrumbs from './TabsBreadcrumbs';
import { Skeleton } from 'antd';
import CogntitoBreadcrumbs from 'components/utilities/tabs/cognito_breadcrumbs/CognitoBreadcrumbs';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

const Tabs = ({ tabs }) => {
	const {
		url,
		path,
		loading,
		child,
		parent,
		baseParent,
		properSubtype,
		properType,
		isMenuSTB
	} = useTabsLogic();

	// check if user have rights to create and edit videos
	const {
		isMaterialTabVisible,
		isEncodingTabVisible,
		isPaymentsTabVisible,
		isTrackingTabVisible
	} = useAuthCheck();

	const renderTabsBreadcrumbs = () => (
		<>
			{!loading ? (
				<TabsBreadcrumbs
					child={child}
					parent={parent}
					baseParent={baseParent}
					properSubtype={properSubtype}
				/>
			) : (
				<div style={{ width: '400px' }}>
					<Skeleton
						active
						title
						paragraph={false}
						className="tabs-breadcrumbs__skeleton"
					/>
				</div>
			)}
		</>
	);
	const { pathname } = useLocation();
	const isCreate = pathname.includes('/create');

	const renderBreadCrumbs = () => {
		if (properType && !isCreate) {
			return (
				<div className="d-flex align-items-center tabs-breadcrumbs__container">
					{isMenuSTB ? <CogntitoBreadcrumbs /> : renderTabsBreadcrumbs()}
				</div>
			);
		}
	};

	return (
		<>
			{renderBreadCrumbs()}
			<Links
				tabs={tabs}
				url={url}
				isMaterialTabVisible={isMaterialTabVisible}
				isEncodingTabVisible={isEncodingTabVisible}
				isPaymentsTabVisible={isPaymentsTabVisible}
				isTrackingTabVisible={isTrackingTabVisible}
			/>
			<Routes
				tabs={tabs}
				path={path}
				url={url}
				isMaterialTabVisible={isMaterialTabVisible}
				isEncodingTabVisible={isEncodingTabVisible}
				isPaymentsTabVisible={isPaymentsTabVisible}
				isTrackingTabVisible={isTrackingTabVisible}
			/>
		</>
	);
};

export default Tabs;
