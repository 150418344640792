// history types
const EMERGENCY = 'emergency';
const ALERT = 'alert';
const CRITICAL = 'critical';
const ERROR = 'error';
const WARNING = 'warning';
const NOTICE = 'notice';
const INFO = 'info';
const DEBUG = 'debug';

export const setColorByType = (type) => {
	switch (type) {
		case EMERGENCY:
		case ALERT:
		case CRITICAL:
		case ERROR:
			return 'red';

		case NOTICE:
		case INFO:
			return 'green';

		case WARNING:
		case DEBUG:
			return 'orange';

		default:
			// empty string sets color tag to grey
			return '';
	}
};
