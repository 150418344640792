import services from 'services/services';
import * as types from 'store/actions/types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from 'store/actions/helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH VIDEO ASSETS PARAMS - TABLE DATA ********************
export const fetchVideoAssetsParams = (options) => async (
	dispatch,
	getState
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_VIDEO_ASSETS_PARAMS_LOADING,
			payload: true
		});

		const {
			ingester: {
				video_assets_params: {
					table: {
						columns,
						options: {
							filters: { filterUrlQuery },
							startIndex: startTableIndex,
							page
						}
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `/player/product/video/asset/params/list?${filterUrlQuery}${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex,
			true
		)}`;

		const { data } = await services.get(url);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_VIDEO_ASSETS_PARAMS_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_VIDEO_ASSETS_PARAMS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE VIDEO ASSETS PARAMS ********************
export const deleteVideoAssetsParams = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DELETE_VIDEO_ASSETS_PARAMS_LOADING,
			payload: true
		});

		await services.delete(`/player/product/video/asset/params/${id}`);
		// Dispatch an action with data
		dispatch({
			type: types.DELETE_VIDEO_ASSETS_PARAMS_SUCCESS,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DELETE_VIDEO_ASSETS_PARAMS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET SEARCH QUERY ********************
export const setVideoAssetsParamsQuery = (query) => ({
	type: types.SET_VIDEO_ASSETS_PARAMS_SEARCH_QUERY,
	payload: query
});
