import React from 'react';

// Import translation
import i18n from 'i18next';

// Import components
import { Modal, Tag } from 'antd';
import message from 'antd/es/message';
import { mapPaymentMethodToTag } from '../../helpers';

export const createColumns = ({ deleteAsset, editAsset, isEdit }) => [
	{
		title: i18n.t('payment_templates:table_columns.payment_method'),
		dataIndex: 'payment_method',
		key: 'payment_method',
		width: 300,
		render: (text, record, index) => {
			const { color, name, icon } = mapPaymentMethodToTag(
				record.payment_method
			);
			return (
				<Tag
					key={index}
					color={color}
					icon={icon}
					style={{
						display: 'inline-flex',
						justifyContent: 'start',
						alignItems: 'center',
						cursor: 'pointer'
					}}
					onClick={() => editAsset(record.payment_method)}
					disabled={isEdit}
				>
					{name}
				</Tag>
			);
		}
	},
	{
		title: i18n.t('payment_templates:table_columns.price'),
		dataIndex: 'price',
		key: 'price'
	},
	{
		title: i18n.t('payment_templates:table_columns.external_id'),
		dataIndex: 'external_id',
		key: 'external_id'
	},
	{
		title: i18n.t('common:materials_table.table_columns.edit'),
		dataIndex: 'edit',
		key: 'edit',
		align: 'center',
		width: 80,
		render: (text, record, index) => (
			<React.Fragment>
				<button
					onClick={() => editAsset(record.payment_method)}
					className="btn btn-xs btn-primary"
					disabled={isEdit}
					type="button"
				>
					<i className="fa fa-gear" />
				</button>
			</React.Fragment>
		)
	},
	{
		title: i18n.t('common:materials_table.table_columns.delete'),
		dataIndex: 'delete',
		key: 'delete',
		align: 'center',
		width: 80,
		render: (text, record, index) => {
			const confirmModal = (event) => {
				event.preventDefault();
				event.stopPropagation();
				Modal.confirm({
					title: i18n.t(`common:delete`),
					content: `${i18n.t(`messages:modal.delete_confirm_message`)}`,
					okText: i18n.t('common:buttons.ok'),
					cancelText: i18n.t('common:buttons.cancel'),
					onOk: () => {
						event.preventDefault();
						event.stopPropagation();
						deleteAsset(record.payment_method);
					},
					onCancel() {
						message.info(i18n.t('messages:messages.asset_delete_canceled'));
					}
				});
			};
			return (
				<React.Fragment>
					<button
						onClick={confirmModal}
						className="btn btn-xs btn-primary"
						disabled={isEdit}
						type="button"
					>
						<i className="fa fa-trash" />
					</button>
				</React.Fragment>
			);
		}
	}
];
