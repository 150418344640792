import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import useSubscribersCouponsForm from './useSubscribersCouponsForm';

// Import components
import Fields from './fields/Fields';
import { ModalFormButtons } from 'components/common/buttons';

// Validate
import validate from './validation';

const SubscribersCouponsForm = ({ userId, toggle, subscriberCouponChange }) => {
	const {
		submit,
		formInitialValues,
		couponPromotionsList
	} = useSubscribersCouponsForm({ userId, subscriberCouponChange });

	return (
		<Form
			onSubmit={submit}
			initialValues={formInitialValues}
			validate={validate}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit}>
					<Fields couponPromotionsList={couponPromotionsList} />
					<ModalFormButtons
						isSubmitting={submitting}
						modalCloseAction={toggle}
					/>
				</form>
			)}
		</Form>
	);
};

SubscribersCouponsForm.propTypes = {
	userId: PropTypes.string.isRequired,
	toggle: PropTypes.func.isRequired,
	subscriberCouponChange: PropTypes.func.isRequired
};

export default SubscribersCouponsForm;
