import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

const useDeeplinkManual = ({ input, close }) => {
	const { t } = useTranslation();

	const handleUpdateValue = async (values) => {
		input.onChange(values.deeplink);
		close();
		notification.success({
			message: t('product_select:notifications.deeplink_created'),
			placement: 'bottom'
		});
	};

	const initialValues = {
		deeplink: input?.value ?? ''
	};

	const validate = ({ deeplink }) => {
		let errors = {};
		if (!deeplink) {
			errors.deeplink = t('marketing_campaigns:form:errors.message_deeplink');
		}
		return errors;
	};

	return {
		handleUpdateValue,
		validate,
		initialValues
	};
};

export default useDeeplinkManual;
