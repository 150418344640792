// ******************** EPG GLOBAL DATA TABLE ********************
export const FETCH_EPG_GLOBAL_LOADING = 'FETCH_EPG_GLOBAL_LOADING';
export const FETCH_EPG_GLOBAL_SUCCESS = 'FETCH_EPG_GLOBAL_SUCCESS';
export const FETCH_EPG_GLOBAL_ERROR = 'FETCH_EPG_GLOBAL_ERROR';

export const SEND_EPG_GLOBAL_TO_BLACKLIST_LOADING =
	'SEND_EPG_GLOBAL_TO_BLACKLIST_LOADING';
export const SEND_EPG_GLOBAL_TO_BLACKLIST_SUCCESS =
	'SEND_EPG_GLOBAL_TO_BLACKLIST_SUCCESS';
export const SEND_EPG_GLOBAL_TO_BLACKLIST_ERROR =
	'SEND_EPG_GLOBAL_TO_BLACKLIST_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_EPG_GLOBAL_SEARCH_QUERY = 'SET_EPG_GLOBAL_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_EPG_GLOBAL_TABLE_SORTING = 'SAVE_EPG_GLOBAL_TABLE_SORTING';

// ******************** EPG GLOBAL DATA FORM ********************
export const FETCH_EPG_GLOBAL_ITEM_LOADING = 'FETCH_EPG_GLOBAL_ITEM_LOADING';
export const FETCH_EPG_GLOBAL_ITEM_SUCCESS = 'FETCH_EPG_GLOBAL_ITEM_SUCCESS';
export const FETCH_EPG_GLOBAL_ITEM_ERROR = 'FETCH_EPG_GLOBAL_ITEM_ERROR';
