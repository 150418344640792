import * as types from 'store/actions/types';
import produce from 'immer';

// Import variables
import { PACKETS_CONFIGURATOR } from 'components/helpers/variables';

export const INITIAL_STATE = {
	edit: {
		external_ids: [],
		url: '',
		free_vod: false,
		active: false,
		slug: null,
		visible_primary: true,
		visible_secondary: true,
		type: PACKETS_CONFIGURATOR.MAIN,
		is_recommended: false,
		is_bundle: false,
		external_provider: null
	},
	isLoaded: true,
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR MAIN_ITEM STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// ****************** FETCH MAIN_ITEM ******************
			case types.FETCH_MAIN_ITEM_LOADING:
				draft.isLoaded = false;
				return;

			case types.FETCH_MAIN_ITEM_SUCCESS:
				draft.edit = action.payload;
				draft.isLoaded = true;
				return;

			case types.FETCH_MAIN_ITEM_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
