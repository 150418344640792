// ************************ PAYEMNT TEMPLATE FETCH ************************
export const FETCH_PAYMENT_TEMPLATES_LOADING =
	'FETCH_PAYMENT_TEMPLATES_LOADING';
export const FETCH_PAYMENT_TEMPLATES_SUCCESS =
	'FETCH_PAYMENT_TEMPLATES_SUCCESS';
export const FETCH_PAYMENT_TEMPLATES_ERROR = 'FETCH_PAYMENT_TEMPLATES_ERROR';

// ************************ PAYEMNT TEMPLATE DELETE ************************
export const DELETE_PAYMENT_TEMPLATES_LOADING =
	'DELETE_PAYMENT_TEMPLATES_LOADING';
export const DELETE_PAYMENT_TEMPLATES_SUCCESS =
	'DELETE_PAYMENT_TEMPLATES_SUCCESS';
export const DELETE_PAYMENT_TEMPLATES_ERROR = 'DELETE_PAYMENT_TEMPLATES_ERROR';

// ************************ PAYEMNT TEMPLATE QUERY ************************
export const SET_PAYMENT_TEMPLATES_SEARCH_QUERY =
	'SET_PAYMENT_TEMPLATES_SEARCH_QUERY';

// ************************ FETCH SPECIFIC PAYMENT TEMPLATE ************************
export const FETCH_PAYMENT_TEMPLATE_METADATA_LOADING =
	'FETCH_PAYMENT_TEMPLATE_METADATA_LOADING';
export const FETCH_PAYMENT_TEMPLATE_METADATA_SUCCESS =
	'FETCH_PAYMENT_TEMPLATE_METADATA_SUCCESS';
export const FETCH_PAYMENT_TEMPLATE_METADATA_ERROR =
	'FETCH_PAYMENT_TEMPLATE_METADATA_ERROR';

// ************************ TRANSFORM DATA TO BE DISPLAYED IN A TABLE ************************
export const SET_PAYMENT_TEMPLATE_TABLE_SUCCESS =
	'SET_PAYMENT_TEMPLATE_TABLE_SUCCESS';

// ************************ ADD PAYMENT TO TABLE ************************
export const PAYMENT_TEMPLATE_ADD_PAYMENT_TO_TABLE =
	'PAYMENT_TEMPLATE_ADD_PAYMENT_TO_TABLE';

// ************************ DELETE PAYMENT FROM TABLE ************************
export const PAYMENT_TEMPLATE_DELETE_PAYMENT_FROM_TABLE =
	'PAYMENT_TEMPLATE_DELETE_PAYMENT_FROM_TABLE';

// ************************ EDIT PAYMENT FROM TABLE ************************
export const PAYMENT_TEMPLATE_EDIT_PAYMENT_FROM_TABLE =
	'PAYMENT_TEMPLATE_EDIT_PAYMENT_FROM_TABLE';

// ************************ FETCH PAYMENT TEMPLATES DROPDOWN ************************
export const FETCH_PAYMENT_TEMPLATES_DROPDOWN_LOADING =
	'FETCH_PAYMENT_TEMPLATES_DROPDOWN_LOADING';
export const FETCH_PAYMENT_TEMPLATES_DROPDOWN_SUCCESS =
	'FETCH_PAYMENT_TEMPLATES_DROPDOWN_SUCCESS';
export const FETCH_PAYMENT_TEMPLATES_DROPDOWN_ERROR =
	'FETCH_PAYMENT_TEMPLATES_DROPDOWN_ERROR';

// ************************ FETCH PAYMENT TEMPLATES DETAILS ************************
export const FETCH_PAYMENT_TEMPLATES_DETAILS_LOADING =
	'FETCH_PAYMENT_TEMPLATES_DETAILS_LOADING';
export const FETCH_PAYMENT_TEMPLATES_DETAILS_SUCCESS =
	'FETCH_PAYMENT_TEMPLATES_DETAILS_SUCCESS';
export const FETCH_PAYMENT_TEMPLATES_DETAILS_ERROR =
	'FETCH_PAYMENT_TEMPLATES_DETAILS_ERROR';
