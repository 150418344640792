import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';
import { convertMinutesToTime } from 'components/helpers';

// Import variables
import * as api from 'components/helpers/api';

// ******************** FETCH SECTION ALGORITHMS ********************
export const fetchSectionAlgorithms = () => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_SECTION_ALGORITHMS_LOADING,
			payload: true
		});

		const response = await services.get('/section/algorithms');

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SECTION_ALGORITHMS_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SECTION_ALGORITHMS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** FETCH SECTION TO EDIT ********************
export const fetchSection = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_SECTION_LOADING,
			payload: true
		});

		const url = api.FETCH_SECTION_API.replace(':id', id);

		const { data } = await services.get(url);
		const { active_from_minute, active_to_minute } = data;

		const returnData = {
			...data,
			active_from_minute: convertMinutesToTime(active_from_minute),
			active_to_minute: convertMinutesToTime(active_to_minute)
		};

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SECTION_SUCCESS,
			payload: returnData
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SECTION_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
