import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	loading: false,
	error: false,
	data: []
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// ****************** GET CSV DATA ******************
			case types.GET_CSV_DATA_LOADING:
				draft.loading = true;
				draft.error = false;
				break;

			case types.GET_CSV_DATA_SUCCESS:
				draft.data = action.payload;
				draft.loading = false;
				draft.error = false;
				break;

			case types.GET_CSV_DATA_ERROR:
				draft.error = action.payload;
				break;

			// ****************** CLEAR CSV DATA ******************
			case types.CLEAR_CSV_DATA:
				draft.data = [];
				break;

			default:
				return state;
		}
	});
