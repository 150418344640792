import i18n from 'i18next';

export const BASE_FIELDS = [
	{
		name: 'title',
		label: i18n.t('notifications:form:fields.title'),
		type: 'text',
		required: true
	},
	{
		name: 'message',
		label: i18n.t('notifications:form:fields.message'),
		type: 'textarea',
		required: true
	},
	{
		name: 'platforms',
		label: i18n.t('notifications:form:fields.platforms'),
		type: 'multi-select',
		required: true,
		mode: 'multiple',
		showSearch: true
	},
	{
		name: 'file',
		label: i18n.t('notifications:form:fields.file'),
		type: 'file',
		required: false
	}
];

export const SENDING_METHODS_FIELDS = [
	{
		name: 'sn_from',
		label: i18n.t('notifications:form:fields.sn_from'),
		type: 'text',
		required: false
	},
	{
		name: 'sn_to',
		label: i18n.t('notifications:form:fields.sn_to'),
		type: 'text',
		required: false
	},
	{
		name: 'sn_list',
		label: i18n.t('notifications:form:fields.sn_list'),
		type: 'textarea',
		required: false
	},
	{
		name: 'logins',
		label: i18n.t('notifications:form:fields.logins'),
		type: 'textarea',
		required: false
	},
	{
		name: 'send_to_all',
		label: i18n.t('notifications:form:fields.send_to_all'),
		type: 'checkbox',
		required: false
	}
];
