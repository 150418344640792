// Import translation
import i18n from 'i18next';

export default ({ topology_file }) => {
	const errors = {};

	if (!topology_file) {
		errors.topology_file = i18n.t('vod_subtitles:validation.file_required');
	}

	return errors;
};
