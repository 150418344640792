import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import components
import DeleteButton from 'components/utilities/delete_button/DeleteButton';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('sections:table_columns.name'),
		accessor: 'name',
		Cell: ({
			row: {
				_original: { id, name }
			}
		}) => <Link to={`/panel/sections/edit/${id}/metadata`}>{name}</Link>
	},
	{
		Header: i18n.t('sections:table_columns.label'),
		accessor: 'label'
	},
	{
		Header: i18n.t('sections:table_columns.type'),
		accessor: 'type'
	},
	{
		Header: i18n.t('common:table_columns.system'),
		accessor: 'system'
	},
	{
		Header: i18n.t('sections:table_columns.edit'),
		width: 80,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) =>
			!isReadOnly && (
				<Link
					to={`/panel/sections/edit/${id}/metadata`}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-gear" />
				</Link>
			)
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id, system_id }
			}
		}) => (
			<DeleteButton
				id={id}
				productSystem={system_id}
				onSelectItem={handleSelectItem}
				isReadOnly={isReadOnly}
			/>
		)
	}
];

export const selectOptionColumns = [
	{
		header: i18n.t('common:search_box.all_columns'),
		name: ''
	},
	{
		header: i18n.t('sections:table_columns.name'),
		name: 'name'
	},
	{
		header: i18n.t('sections:table_columns.label'),
		name: 'label'
	},
	{
		header: i18n.t('sections:table_columns.type'),
		name: 'type'
	}
];
