import React from 'react';
import i18n from 'i18next';
import { Link } from 'react-router-dom';

// FIELDS NAMES
export const NAME = 'Name';
export const NAME_TRANSLATION_ENG = 'name_translation_eng';
export const SHORT_SYNOPSIS = 'ShortSynopsis';
export const ORDINAL = 'Ordinal';
export const SECTION_ID = 'section_id';
export const IS_EMPTY = 'is_empty';
export const IS_ADULT = 'IsAdult';
export const ACTIVE = 'active';
export const SHOW_EMPTY = 'show_empty';
export const SYSTEM = 'system';

export const COGNITO_FIELDS = ({
	currentValues,
	isEdit,
	currentSectionUrl,
	sectionsUrl,
	handleShowEmptyChange
}) => [
	{
		name: NAME,
		label: i18n.t('menu_stb:fields.name'),
		type: 'text',
		required: true
	},
	{
		name: NAME_TRANSLATION_ENG,
		label: i18n.t('menu_stb:fields.name_translation_eng'),
		type: 'text'
	},
	{
		name: SHORT_SYNOPSIS,
		label: i18n.t('menu_stb:fields.short_synopsis'),
		type: 'textarea'
	},
	{
		name: ORDINAL,
		label: i18n.t('menu_stb:fields.ordinal'),
		type: 'number',
		required: true
	},
	{
		name: SECTION_ID,
		label: i18n.t('menu_stb:fields.section'),
		type: 'select-on-search',
		url: sectionsUrl,
		fieldInfo: currentSectionUrl && (
			<Link to={currentSectionUrl}>{i18n.t('menu_stb:links.section')}</Link>
		)
	},
	{
		name: IS_EMPTY,
		label: i18n.t('menu_stb:fields.is_empty'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: IS_ADULT,
		label: i18n.t('menu_stb:fields.adult'),
		type: 'checkbox',
		disabled: currentValues.section_id,
		fieldInfo: i18n.t('menu_stb:fields_info.adult')
	},
	{
		name: ACTIVE,
		label: i18n.t('menu_stb:fields.active'),
		type: 'checkbox'
	},
	{
		name: SHOW_EMPTY,
		label: i18n.t('menu_stb:fields.show_empty'),
		type: 'checkbox',
		onChangeAction: handleShowEmptyChange
	},
	{
		name: SYSTEM,
		label: i18n.t('menu_stb:fields.system'),
		type: 'text',
		disabled: true
	}
];
