import { combineReducers } from 'redux';
// Import reducers
import table from './promotions_panel_reducer';
import form from './promotions_form_reducer';
import users_list from './promotions_users_list_reducer';
import packets_list from './promotions_packets_list_reducer';
import promotions_products from './products/promotions_products_reducer';

export default combineReducers({
	table,
	form,
	users_list,
	promotions_products,
	packets_list
});
