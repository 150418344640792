// Import translation
import i18n from 'i18next';

// Import helpers
import { isUrlValid } from 'components/helpers/validation_helpers';

export default ({ alias, title, content, redirection_url, is_redirection }) => {
	const errors = {};

	// ------------------ Alias ------------------
	if (!alias) {
		errors.alias = i18n.t('documents:validation.alias_required');
	}
	// ------------------ Tytuł ------------------
	if (!title) {
		errors.title = i18n.t('documents:validation.title_required');
	}
	// ------------------ Redirect url ------------------
	if (is_redirection) {
		if (!redirection_url) {
			errors.redirection_url = i18n.t(
				'documents:validation.redirect_url_required'
			);
		} else if (!isUrlValid(redirection_url)) {
			errors.redirection_url = i18n.t(
				'documents:validation.redirect_url_invalid'
			);
		}
	}

	// ------------------ Content ------------------
	if (!content && !is_redirection) {
		errors.content = i18n.t('documents:validation.content_required');
	}

	return errors;
};
