export const convertSystemProvidersProducts = (data) =>
	data.map((item) => ({
		title: item.provider_name,
		type: 'provider',
		uuid: item.id
	}));

export const convertSystemChannelsProducts = (data) =>
	data.map((item) => ({
		images: item.images,
		title: item.title,
		type: 'channel',
		uuid: item.uuid
	}));
