import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setSubscribersQuery,
	fetchSubscribers,
	createFiltersSuggestions,
	dictionarySubscriberSource
} from 'store/actions';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './subscriber_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const Subscribers = ({
	error,
	setSubscribersQuery,
	fetchSubscribers,
	resources,
	filters,
	createFiltersSuggestions,
	dictionarySubscriberSource
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	// Actions to fetch data for filters
	const filterActions = {
		dictionarySubscriberSource
	};

	// execute filters logic
	useFilterLogic({
		filterActions,
		createFiltersSuggestions,
		filters
	});

	return (
		// Dispatch fetchSubscribers, deleteSubscriber action in subscribers_panel
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="subscribers"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchSubscribers}
			notificationDeleteSuccessTxt={t(
				'subscribers:notification.delete_success'
			)}
			notificationDeleteErrorTxt={t('subscribers:notification.delete_success')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setSubscribersQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Subscribers.propTypes = {
	error: PropTypes.bool.isRequired,
	setSubscribersQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchSubscribers: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	dictionarySubscriberSource: PropTypes.func.isRequired
};

const mapStateToProps = ({ subscribers, dictionary }) => {
	return {
		error: subscribers.table.error,
		resources: subscribers.table,
		filters: {
			system: dictionary.subscriberSystem,
			source: dictionary.subscriberSource
		}
	};
};

export default connect(mapStateToProps, {
	setSubscribersQuery,
	fetchSubscribers,
	createFiltersSuggestions,
	dictionarySubscriberSource
})(Subscribers);
