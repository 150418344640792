// Import translation
import i18n from 'i18next';

export const defaultFieldValues = {
	id: null,
	operator_email: '',
	operator_id: '',
	operator_ip: '',
	product_uuid: '',
	product_type: '',
	permission: '',
	content: '',
	status_code: '',
	status_message: ''
};

export const BASE_FIELDS = () => [
	{
		name: 'id',
		label: i18n.t('activity:form:fields.id'),
		type: 'number',
		required: false,
		disabled: true
	},
	{
		name: 'operator_email',
		label: i18n.t('activity:form:fields.operator_email'),
		type: 'text',
		required: false,
		disabled: true
	},
	{
		name: 'operator_id',
		label: i18n.t('activity:form:fields.operator_id'),
		type: 'text',
		required: false,
		disabled: true
	},
	{
		name: 'operator_ip',
		label: i18n.t('activity:form:fields.operator_ip'),
		type: 'text',
		required: false,
		disabled: true
	},
	{
		name: 'model_type',
		label: i18n.t('activity:form:fields.model_type'),
		type: 'text',
		required: false,
		disabled: true
	},
	{
		name: 'model_id',
		label: i18n.t('activity:form:fields.model_id'),
		type: 'text',
		required: false,
		disabled: true
	},
	{
		name: 'permission',
		label: i18n.t('activity:form:fields.permission'),
		type: 'text',
		required: false,
		disabled: true
	},
	{
		name: 'content',
		label: i18n.t('activity:form:fields.content'),
		type: 'textarea',
		required: false,
		disabled: true
	},
	{
		name: 'status_code',
		label: i18n.t('activity:form:fields.status_code'),
		type: 'text',
		required: false,
		disabled: true
	},
	{
		name: 'status_message',
		label: i18n.t('activity:form:fields.status_message'),
		type: 'textarea',
		required: false,
		disabled: true
	}
];
