import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	fetchChannel,
	fetchCategories,
	dictionarytVoivodeshipCodes
} from 'store/actions';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import components
import { FormTemplate } from 'components/common/templates';
import MetadataForm from '../channels_common/metadata_form/MetadataForm';
import InfoTitle from 'components/utilities/info/InfoTitle';

// Import helpers
import {
	liveCategoriesOptions,
	convertGenresToNames,
	convertToIds
} from 'components/helpers/genres_helpers/helpers';
import { convertValueToArray } from './helpers';

const ChannelsForm = ({
	history,
	fetchChannel,
	fetchCategories,
	dictionarytVoivodeshipCodes,
	error,
	isLoaded,
	initialValues,
	genresList,
	voivodeshipCodes
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		// Fetch categories with default params to feed genres combobox
		fetchCategories(liveCategoriesOptions, null, null);

		dictionarytVoivodeshipCodes();
		// eslint-disable-next-line
	}, []);

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { genres, cities, subdivisions, alternate_names } = values;

		const convertedCities = convertValueToArray(cities);
		const convertedAlternateNames = convertValueToArray(alternate_names);

		const subdivisionsValue = subdivisions?.length ? subdivisions : null;

		// selected genres mapped to array of ids
		const genresId = convertToIds(genresList, genres);

		const regionLimitation = {
			cities: convertedCities,
			subdivisions: subdivisionsValue
		};

		const resources = {
			...values,
			genres: genresId,
			region_limitation: regionLimitation,
			alternate_names: convertedAlternateNames
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'channel',
			redirectPath: 'channels'
		});
	};

	const { genres, region_limitation, alternate_names } = initialValues;

	// convert genres objects of currently edited channel to array of genres names
	const currentChannelGenres = convertGenresToNames(genres);

	const regionLimitationCities = region_limitation?.cities;

	const cities = regionLimitationCities?.length
		? regionLimitationCities.join(',')
		: null;
	const subdivisions = region_limitation?.subdivisions;
	const alternateNames = alternate_names?.length
		? alternate_names.join(',')
		: null;

	// form initial values
	const formInitialValues = {
		...initialValues,
		genres: currentChannelGenres,
		cities,
		subdivisions,
		alternate_names: alternateNames
	};

	return (
		// Dispatch fetchChannelCategories, fetchChannel actions in in channels_form
		<FormTemplate
			fetchResourceToEdit={fetchChannel}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<>
					<InfoTitle title={t('channels:info_title')} className="mb-4" />
					<MetadataForm
						genres={genresList}
						isEdit={isEdit}
						itemID={itemID}
						initialValues={formInitialValues}
						handleOnSubmit={handleOnSubmit}
						path="channels"
						voivodeshipCodes={voivodeshipCodes}
					/>
				</>
			)}
		</FormTemplate>
	);
};

ChannelsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchCategories: PropTypes.func.isRequired,
	fetchChannel: PropTypes.func.isRequired,
	dictionarytVoivodeshipCodes: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	genresList: PropTypes.array.isRequired,
	voivodeshipCodes: PropTypes.array.isRequired
};

const mapStateToProps = ({
	channels: { form },
	categories: {
		table: { data }
	},
	dictionary: { voivodeshipCodes }
}) => ({
	initialValues: form.edit,
	error: form.error,
	isLoaded: form.isLoaded,
	genresList: data,
	voivodeshipCodes
});

export default compose(
	connect(mapStateToProps, {
		fetchCategories,
		fetchChannel,
		dictionarytVoivodeshipCodes
	}),
	withRouter
)(ChannelsForm);
