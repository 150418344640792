import React from 'react';
import { Trans } from 'react-i18next';

const Footer = () => (
	<footer className="footer d-flex align-items-center justify-content-center">
		<p className="m-0">
			<Trans
				i18nKey="footer:copyright"
				values={{ version: process.env.REACT_APP_VERSION }}
			/>
		</p>
	</footer>
);

export default Footer;
