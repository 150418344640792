import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Validate
import validate from './validation';

// Import components
import Fields from './fields/Fields';
import { FormButtons } from 'components/common/buttons';
import { notificationHandler } from 'components/utilities/notifications';
import { IboxContentTitle } from 'components/common/layout';

// Import sevices
import services from 'services/services';

// Import variables
import { TOPOLOGY_FILES } from 'components/helpers/variables';

const PortsUpload = ({ onSuccessUpload }) => {
	const { t } = useTranslation();

	const handleOnSubmit = () => async (values) => {
		// Submit the form with field values
		let formData = new FormData();
		formData.append('ports', values.ports_file[0]);
		formData.append('mappings', values.mappings_name);

		try {
			await services.post('cognito/ports/upload', formData);
			notificationHandler(
				t('messages:notifications.edited'),
				`${t('messages:notifications.successfuly_edited')} ${t(
					'names:notification_names.ports'
				)}`
			);

			window.scrollTo(0, 0);
			onSuccessUpload(TOPOLOGY_FILES.PORTS);
		} catch (error) {
			const {
				response: {
					data: { message }
				}
			} = error;

			notificationHandler(t('common:error'), message, 'error', '20000');
		}
	};

	return (
		<IboxContentTitle title={t('topology:tabs.upload_ports')}>
			<Form
				onSubmit={handleOnSubmit()}
				validate={validate}
				render={({ handleSubmit, submitting }) => (
					<form onSubmit={handleSubmit}>
						<Fields submitting={submitting} />

						<FormButtons
							isButtonDisabled={submitting}
							buttonText={t('common:buttons.submit')}
						/>
					</form>
				)}
			/>
		</IboxContentTitle>
	);
};

PortsUpload.propTypes = {
	onSuccessUpload: PropTypes.func.isRequired
};

export default PortsUpload;
