import React from 'react';
import { Field } from 'react-final-form';

// Import components
import { InputLabel, CheckboxLabel } from 'components/common/inputs';

// Import variables
import { PLATFORM_NAMES } from 'components/helpers/variables';

const { STB } = PLATFORM_NAMES;

/**
 * Function that renders proper fields in platforms table
 * @param {object} platformData - all data about specific platform
 * @param {string} rowValue - name/value of row. For proper data access
 * @param {number} index - helper index to create proper field keys
 * @param {bool} submitting - react final form submitting value
 */
export const renderTableFields = (
	platformData,
	rowValue,
	index,
	submitting
) => {
	const { platform: platformName } = platformData;
	const fieldName = `${platformName}.${rowValue}`;

	switch (rowValue) {
		case 'catch_up_time':
			return (
				<Field
					key={`${platformName}-${index}`}
					disabled={submitting}
					name={fieldName}
					type="number"
					id={fieldName}
					component={InputLabel}
					labelColumn={0}
					inputColumn={12}
					className="platforms-table__input"
				/>
			);
		case 'catch_up_pause':
		case 'catch_up_seek_forward':
		case 'catch_up_seek_backward':
		case 'catch_up_play':
		case 'catch_up_skip':
		case 'restart_pause':
		case 'restart_seek_forward':
		case 'restart_seek_backward':
		case 'restart_play':
		case 'restart_skip':
			return (
				<>
					{platformName === STB && (
						<Field
							key={`${platformName}-${index}`}
							disabled={submitting}
							name={fieldName}
							type="checkbox"
							id={fieldName}
							component={CheckboxLabel}
							labelColumn={0}
							inputColumn={12}
							className="platforms-table__input"
						/>
					)}
				</>
			);

		default:
			return (
				<Field
					key={`${platformName}-${index}`}
					disabled={submitting}
					name={fieldName}
					type="checkbox"
					id={fieldName}
					component={CheckboxLabel}
					labelColumn={0}
					inputColumn={12}
					className="platforms-table__input"
				/>
			);
	}
};

// /**
//  * Function that removes PLATFORM_ appendix from column name and replaces
//  * underscores with space
//  * @param {string} columnName - column name from api
//  */
// export const removePlatformFromName = (columnNames) => {
// 	return columnNames.map((colName) =>
// 		colName.replace('PLATFORM_', '').replace('_', ' ')
// 	);
// };
