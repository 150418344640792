// Import translation
import i18n from 'i18next';

export default ({ ip_from, ip_to }) => {
	const errors = {};

	// ------------------ Ip From ------------------
	if (!ip_from) {
		errors.ip_from = i18n.t('geolocks:validation.ip_required');
	} else if (
		!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
			ip_from
		)
	) {
		errors.ip_from = i18n.t('geolocks:validation.ip_format_error');
	}
	// ------------------ Ip To ------------------
	if (!ip_to) {
		errors.ip_to = i18n.t('geolocks:validation.ip_required');
	} else if (
		!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
			ip_to
		)
	) {
		errors.ip_to = i18n.t('geolocks:validation.ip_format_error');
	}

	return errors;
};
