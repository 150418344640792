import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	fetchDevices,
	setDevicesQuery,
	setDevicesPlatform
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './devices_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import helpers
import { DEVICES_PLATFORM } from 'components/helpers/variables';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterSelect from '../vod/helpers/FilterSelect';

const Devices = ({
	error,
	fetchDevices,
	resources,
	setDevicesQuery,
	setDevicesPlatform
}) => {
	const { t } = useTranslation();
	const breadcrumbs = [t('common:breadcrumbs.devices')];

	const {
		options: {
			filters: { platform }
		}
	} = resources;

	return (
		<PanelPageTemplate
			title={t('common:list')}
			error={error}
			breadcrumbs={breadcrumbs}
			type="devices"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchDevices}
		>
			<div className="d-flex justify-content-between align-items-start">
				<FilterSelect
					options={DEVICES_PLATFORM()}
					label={t('devices:select:label.platform')}
					action={setDevicesPlatform}
					value={platform}
				/>
				<Search
					setQueryValue={setDevicesQuery}
					value={resources.options.filters.query}
				/>
			</div>
		</PanelPageTemplate>
	);
};

Devices.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchDevices: PropTypes.func.isRequired,
	setDevicesQuery: PropTypes.func.isRequired,
	setDevicesPlatform: PropTypes.func.isRequired
};

const mapStateToProps = ({ devices }) => ({
	error: devices.table.error,
	resources: devices.table
});

export default connect(mapStateToProps, {
	fetchDevices,
	setDevicesQuery,
	setDevicesPlatform
})(Devices);
