import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import redux actions
import {
	setCognitoProvidersQuery,
	fetchCognitoProviders,
	deleteCognitoProvider
} from 'store/actions';

// Import columns
import { createColumns } from './providers_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const Providers = ({
	error,
	setCognitoProvidersQuery,
	fetchCognitoProviders,
	deleteCognitoProvider,
	resources
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	return (
		<PanelPageTemplate
			error={error}
			type="providers"
			addLinkText={t('providers:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchCognitoProviders}
			deleteItemAction={deleteCognitoProvider}
			notificationDeleteSuccessTxt={t('providers:notification.delete_success')}
			notificationDeleteErrorTxt={t('providers:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setCognitoProvidersQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Providers.propTypes = {
	error: PropTypes.bool.isRequired,
	setCognitoProvidersQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchCognitoProviders: PropTypes.func.isRequired,
	deleteCognitoProvider: PropTypes.func.isRequired
};

const mapStateToProps = ({ cognito_providers }) => {
	return {
		error: cognito_providers.table.error,
		resources: cognito_providers.table
	};
};

export default connect(mapStateToProps, {
	setCognitoProvidersQuery,
	fetchCognitoProviders,
	deleteCognitoProvider
})(Providers);
