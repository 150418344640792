import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Helper function that return Link with proper path to form that matches vod type
 * for example 'vod' type will link to vodForm.js while 'series' type will link to seriesForm.js
 * @param {string} uuid - Id of item
 * @param {string} type - type of item (channel | vod | series | season | episode)
 * @param {object} image - image associated with item
 * @param {string} title - title of item
 * @param {string} status - status of item (used to hide link for deleted vod)
 * @returns {*}
 */
export const setFormLink = ({
	uuid,
	type = 'vod',
	image,
	title,
	status = null
}) => {
	const className =
		type === 'channel' ? 'table-img-preview__logo' : 'table-img-preview';

	// checks pathname for link based on product type
	// channel  ||  vod, series, season, episode
	let pathname;
	switch (type) {
		case 'channel':
			pathname = `/panel/channels/edit/${uuid}/metadata`;
			break;
		case 'packet':
			pathname = `/panel/provisioning/edit/${uuid}/metadata`;
			break;
		case 'marketing_campaigns':
			pathname = `/panel/marketing_campaigns/edit/${uuid}/metadata`;
			break;

		default:
			pathname = `/panel/vod/${type}/edit/${uuid}/metadata`;
			break;
	}

	if (image) {
		return (
			<Link to={{ pathname }}>
				<img src={image} alt="Logo" className={className} />
			</Link>
		);
	} else if (status && status === 'deleted') {
		return <span title={title}>{title || uuid}</span>;
	} else {
		return (
			<Link to={{ pathname }} title={title}>
				{title || uuid}
			</Link>
		);
	}
};
