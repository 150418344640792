import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DiffFilled } from '@ant-design/icons';

function UseTemplateButton({ productId }) {
	const { t } = useTranslation();

	return (
		<div className="d-flex justify-content-end m-b-md">
			<Link
				data-testid={`create-payment-template-link`}
				className="btn__link bg-success"
				to={`/panel/use_payment_template/${productId}`}
			>
				<div className="d-flex align-items-center">
					<DiffFilled
						style={{
							color: 'white',
							marginRight: '0.5rem',
							fontSize: '1.75rem'
						}}
					/>
					{t('payment_templates:buttons.use_payment_template')}
				</div>
			</Link>
		</div>
	);
}

UseTemplateButton.propTypes = {
	productId: PropTypes.string.isRequired
};

export default UseTemplateButton;
