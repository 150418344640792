import services from 'services/services';
import { useDispatch } from 'react-redux';
import { fetchSubscriberDevicesSn } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

// Import components
import { message } from 'antd';

const useSubscribersDevicesSnForm = ({ subscriberId, setModalVisibility }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleSubmit = async (values) => {
		const { device_sn_id } = values;
		const url = `/subscriber/serialnumbers/${device_sn_id}/attach`;

		try {
			await services.patch(url, { subscriber_id: subscriberId });
			setModalVisibility();

			notificationHandler(
				t('messages:notifications.created'),
				`${t('messages:notifications.successfuly_created')} ${t(
					'names:notification_names.device'
				)} `
			);

			dispatch(fetchSubscriberDevicesSn(null, subscriberId));
		} catch (error) {
			message.error(error.message);
		}
	};

	return { handleSubmit };
};

export default useSubscribersDevicesSnForm;
