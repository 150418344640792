import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setProvisioningQuery,
	fetchProvisioning,
	deleteProvisioning
} from 'store/actions';
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './provisioning_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const Provisioning = ({
	error,
	setProvisioningQuery,
	fetchProvisioning,
	deleteProvisioning,
	resources
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	return (
		// Dispatch fetchProvisioning, deleteProvisioning action in provisioning_panel
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="provisioning"
			addLinkText={t('provisioning:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchProvisioning}
			deleteItemAction={deleteProvisioning}
			notificationDeleteSuccessTxt={t(
				'provisioning:notification.delete_success'
			)}
			notificationDeleteErrorTxt={t('provisioning:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setProvisioningQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Provisioning.propTypes = {
	error: PropTypes.bool.isRequired,
	setProvisioningQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchProvisioning: PropTypes.func.isRequired,
	deleteProvisioning: PropTypes.func.isRequired
};

const mapStateToProps = ({ provisioning: { table } }) => {
	return {
		error: table.error,
		resources: table
	};
};

export default connect(mapStateToProps, {
	setProvisioningQuery,
	fetchProvisioning,
	deleteProvisioning
})(Provisioning);
