import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchMarketingCampaign } from 'store/actions';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const MarketingCampaignsForm = ({
	history,
	fetchMarketingCampaign,
	error,
	isLoaded,
	initialValues
}) => {
	const { t } = useTranslation();

	const convertDate = (date) => moment(date).format('YYYY-MM-DD HH:mm:ss');

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const {
			campaign_range,
			notification_end,
			message_text,
			notification_autoclose,
			message_scale
		} = values;

		const resources = {
			...initialValues,
			...values,
			notification_body: message_text,
			notification_end: convertDate(notification_end),
			campaign_start: convertDate(campaign_range?.[0]),
			campaign_end: convertDate(campaign_range?.[1]),
			notification_autoclose: notification_autoclose
				? Number(notification_autoclose)
				: 0,
			message_scale: message_scale ? Number(message_scale) : 0
		};

		delete resources.campaign_range;
		delete resources.genres;

		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'subscribers/campaigns',
			redirectPath: 'marketing_campaigns',
			createUrl: '/subscribers/campaigns',
			editUrl: '/subscribers/campaigns/:id'
		});
	};

	const campaign_range =
		initialValues?.campaign_start && initialValues?.campaign_end
			? [
					moment(initialValues.campaign_start),
					moment(initialValues.campaign_end)
			  ]
			: null;

	const formInitialValues = {
		...defaultFieldValues,
		...initialValues,
		campaign_range
	};

	return (
		<FormTemplate
			fetchResourceToEdit={fetchMarketingCampaign}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={formInitialValues}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting, values }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								isEdit={isEdit}
								submitting={submitting}
								messageType={values.message_type}
							/>
							<FormButtons
								isButtonDisabled={submitting || error}
								path="vod"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

MarketingCampaignsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	fetchMarketingCampaign: PropTypes.func
};

const mapStateToProps = ({ marketing_campaigns: { form } }) => ({
	initialValues: form.edit,
	error: form.error,
	isLoaded: form.isLoaded
});

export default compose(
	connect(mapStateToProps, {
		fetchMarketingCampaign
	}),
	withRouter
)(MarketingCampaignsForm);
