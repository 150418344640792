import React from 'react';
import PropTypes from 'prop-types';

// Import components
import StatIcon from './StatIcon';

/**
 * Function component that renders info about queued and in progress materials
 * in ingester. It shows checkmark if there are no materials being progressed.
 * It shows dedicated spinner in other case.
 * @param {number} number
 * @param {string} color
 * @param {string} label
 * @param {string} statType
 * @returns {*}
 */
const StatNumber = ({ number, color, label, statType }) => {
	return (
		<div>
			<StatIcon color={color} number={number} statType={statType} />
			<span className="stat-label m-l-sm">{`${label}:`}</span>
			<span className="stat-label stat-label__number m-l-sm">{number}</span>
		</div>
	);
};

StatNumber.propTypes = {
	number: PropTypes.number.isRequired,
	color: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	statType: PropTypes.string
};

export default StatNumber;
