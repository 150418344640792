import i18n from 'i18next';

export const DOCUMENTS_FILTER_FIELDS = [
	{
		name: 'alias',
		label: i18n.t('filters:fields.alias'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'title',
		label: i18n.t('filters:fields.title'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'created_at',
		label: i18n.t('filters:fields.created_at'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	},
	{
		name: 'updated_at',
		label: i18n.t('filters:fields.updated_at'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	}
];
