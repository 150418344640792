// ******************** TRACKINGS DATA TABLE ********************
export const FETCH_TRACKINGS_LOADING = 'FETCH_TRACKINGS_LOADING';
export const FETCH_TRACKINGS_SUCCESS = 'FETCH_TRACKINGS_SUCCESS';
export const FETCH_TRACKINGS_ERROR = 'FETCH_TRACKINGS_ERROR';

// ******************** SET TRACKINGS DATE ********************
export const SET_TRACKINGS_DATE = 'SET_TRACKINGS_DATE';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_TRACKINGS_TABLE_SORTING = 'SAVE_TRACKINGS_TABLE_SORTING';
