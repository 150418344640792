import * as types from '../types';

// ***************** CLEAR  *************************

export const clearState = () => {
	return {
		type: types.CLEAR_STATE,
		payload: null
	};
};

export const clearPanelState = () => {
	return {
		type: types.CLEAR_PANEL_STATE,
		payload: null
	};
};
