import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import i18n from 'i18next';

// Import components
import { InputBase } from 'components/common/inputs';

const Fields = ({ submitting }) => (
	<Field
		component={InputBase}
		name="email"
		type="email"
		placeholder={i18n.t('login:forgot_password:fields.email_placeholder')}
		className="form-control form-reset__input"
		required={true}
		disabled={submitting}
	/>
);

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
