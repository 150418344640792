import { combineReducers } from 'redux';
// Import reducers
import table from './limits_panel_reducer';
import form from './limits_form_reducer';
import products from './limits_products_reducer';

export default combineReducers({
	table,
	form,
	products
});
