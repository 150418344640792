import React, { useState, useEffect } from 'react';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { CUSTOM_PROPERTIES_FIELDS, renderCustomPropertyRow } from './helpers';

const CustomPropertiesFields = ({ properties, propertiesValues }) => {
	const { t } = useTranslation();
	const { submitting, getState, change } = useForm();
	const { values } = getState();
	const [selectedProperties, setSelectedProperties] = useState({});

	const fields = new Array(properties.length).fill(CUSTOM_PROPERTIES_FIELDS);

	const handleSelect = (selected, index) => {
		setSelectedProperties((prev) => ({ ...prev, [index]: selected }));
	};

	const handleDelete = (selectedIndex) => {
		const selectedPropertiesArray = Object.entries(selectedProperties);

		for (let i = selectedIndex; i < selectedPropertiesArray.length; i++) {
			change(`href_${i}`, values[`href_${i + 1}`]);
			change(`value_${i}`, values[`value_${i + 1}`]);
		}

		// remove deleted properties from state and reset keys greater than or equal to selectedIndex
		const updatedSelectedProperties = selectedPropertiesArray.reduce(
			(acc, item, index) =>
				+item[0] !== selectedIndex
					? { ...acc, [index - (index >= selectedIndex)]: item[1] }
					: acc,
			{}
		);
		setSelectedProperties(updatedSelectedProperties);
	};

	const handleRenderRow = (item, index) => {
		return renderCustomPropertyRow({
			array: item,
			index,
			submitting,
			values,
			properties,
			propertiesValues,
			selectedProperties,
			handleSelect,
			handleDelete
		});
	};

	useEffect(() => {
		const { selectedProperties } = values;
		// convert array to object with indices as keys
		const convertedProperties = selectedProperties.reduce(
			(acc, val, index) => ({ ...acc, [index]: val }),
			{}
		);
		setSelectedProperties(convertedProperties);
		// eslint-disable-next-line
	}, [values.selectedProperties]);

	return (
		<>
			<h3>{t('menu_stb:fields.custom_properties')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			<Row>{fields.map(handleRenderRow)}</Row>
			<div className="hr-line-solid">&nbsp;</div>
		</>
	);
};

CustomPropertiesFields.propTypes = {
	properties: PropTypes.array.isRequired
};

export default CustomPropertiesFields;
