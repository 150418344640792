import { combineReducers } from 'redux';
// Import reducers
import table from './banners_panel_reducer';
import form from './banners_form_reducer';
import files from './banners_files_reducer';

export default combineReducers({
	table,
	form,
	files
});
