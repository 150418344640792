import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchProvisioningItem } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import { defaultValues } from './fields/input_fields';

// Import actions
import { dictionaryPacketPermissions } from 'store/actions';

const ProvisioningForm = ({
	history,
	fetchProvisioningItem,
	error,
	isLoaded,
	initialValues
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dictionaryPacketPermissions()(dispatch);

		//eslint-disable-next-line
	}, []);

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		// external_id is not required
		delete values.external_id;

		const { permission, external_ids } = values;

		const resources = {
			...values,
			permission: permission || null,
			is_recommended: false,
			external_ids: external_ids || []
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'packet',
			redirectPath: 'provisioning'
		});
	};

	return (
		// Dispatch fetchProvisioningItem actions in in provisioning_form
		<FormTemplate
			fetchResourceToEdit={fetchProvisioningItem}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={{ ...defaultValues, ...initialValues }}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields submitting={submitting} isEdit={isEdit} />

							<FormButtons
								isButtonDisabled={submitting || error}
								path="provisioning"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

ProvisioningForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchProvisioningItem: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ provisioning: { form } }) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, { fetchProvisioningItem }),
	withRouter
)(ProvisioningForm);
