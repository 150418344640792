import React from 'react';
import { Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import { sendResetEmail } from 'store/actions';

// Import translation
import i18n from 'i18next';

// Import validation
import validate from './helpers/validate';

// Import components
import { Button, Spinner } from 'reactstrap';
import Fields from './Fields';

const ForgotPasswordForm = () => {
	const dispatch = useDispatch();
	const { loading } = useSelector(
		({ auth }) => auth.resetPassword.sendResetEmail
	);

	// Submit data to receive the link to reset the password
	// Change the isSubmited state to show confirmation notification
	const handleOnSubmit = async (values) =>
		await sendResetEmail(values)(dispatch);

	return (
		<Form
			initialValues={{ email: '' }}
			onSubmit={handleOnSubmit}
			validate={validate}
			render={({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} className="form-reset m-t">
					<Fields submitting={submitting} />
					<Button
						type="submit"
						className="btn__signin m-t m-b"
						disabled={submitting}
						color="primary"
						size="lg"
						block
					>
						{submitting || loading ? (
							<Spinner
								as="span"
								animation="border"
								size="md"
								role="status"
								aria-hidden="true"
							/>
						) : (
							i18n.t('login:forgot_password:buttons.reset')
						)}
					</Button>
				</form>
			)}
		/>
	);
};

export default ForgotPasswordForm;
