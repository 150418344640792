import i18n from 'i18next';

export default ({ local_channel, voivodeship, counties, position }) => {
	const errors = {};

	if (!local_channel) {
		errors.local_channel = i18n.t('local_channels:form.validation.required');
	}

	if (!voivodeship) {
		errors.voivodeship = i18n.t('local_channels:form.validation.required');
	}

	if (!counties || counties.length === 0) {
		errors.counties = i18n.t('local_channels:form.validation.required');
	}

	if (!position) {
		errors.position = i18n.t('local_channels:form.validation.required');
	}

	return errors;
};
