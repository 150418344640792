import React from 'react';
import {
	CreditCardFilled,
	GoogleOutlined,
	AppleFilled,
	BankFilled,
	HistoryOutlined,
	FileTextOutlined,
	PaperClipOutlined
} from '@ant-design/icons';

import { PAYMENT_TEMPLATE_TYPES } from 'components/helpers/variables';

export const mapPaymentMethodToTag = (payment_method) => {
	switch (payment_method) {
		case PAYMENT_TEMPLATE_TYPES.CARD:
			return {
				name: 'Card',
				icon: mapPaymentMethodToIcon(payment_method),
				color: 'green',
				order: 1
			};
		case PAYMENT_TEMPLATE_TYPES.GOOGLEPLAY:
			return {
				name: 'Googleplay',
				icon: mapPaymentMethodToIcon(payment_method),
				color: 'green',
				order: 2
			};
		case PAYMENT_TEMPLATE_TYPES.APPSTORE:
			return {
				name: 'Appstore',
				icon: mapPaymentMethodToIcon(payment_method),
				color: 'green',
				order: 3
			};
		case PAYMENT_TEMPLATE_TYPES.OFFER:
			return {
				name: 'Offer',
				icon: mapPaymentMethodToIcon(payment_method),
				color: 'blue',
				order: 5
			};
		case PAYMENT_TEMPLATE_TYPES.INVOICE:
			return {
				name: 'Invoice',
				icon: mapPaymentMethodToIcon(payment_method),
				color: 'blue',
				order: 4
			};
		case PAYMENT_TEMPLATE_TYPES.BLUEMEDIA:
			return {
				name: 'Bluemedia',
				icon: mapPaymentMethodToIcon(payment_method),
				color: 'blue',
				order: 6
			};
		case PAYMENT_TEMPLATE_TYPES.SUBSCRIPTION_APPSTORE:
			return {
				name: 'Subscription Appstore',
				icon: mapPaymentMethodToIcon(payment_method),
				color: 'gold',
				order: 8
			};
		case PAYMENT_TEMPLATE_TYPES.SUBSCRIPTION_BLUEMEDIA:
			return {
				name: 'Subscription Bluemedia',
				icon: mapPaymentMethodToIcon(payment_method),
				color: 'gold',
				order: 9
			};
		case PAYMENT_TEMPLATE_TYPES.SUBSCRIPTION_GOOGLEPLAY:
			return {
				name: 'Subscription Googleplay',
				icon: mapPaymentMethodToIcon(payment_method),
				color: 'gold',
				order: 7
			};
		default:
			return {
				name: '',
				icon: mapPaymentMethodToIcon(payment_method),
				color: 'default',
				order: 10
			};
	}
};

export const mapPaymentMethodToIcon = (payment_method) => {
	switch (payment_method) {
		case PAYMENT_TEMPLATE_TYPES.CARD:
			return <CreditCardFilled />;
		case PAYMENT_TEMPLATE_TYPES.GOOGLEPLAY:
			return <GoogleOutlined />;
		case PAYMENT_TEMPLATE_TYPES.APPSTORE:
			return <AppleFilled />;
		case PAYMENT_TEMPLATE_TYPES.OFFER:
			return <FileTextOutlined />;
		case PAYMENT_TEMPLATE_TYPES.INVOICE:
			return <BankFilled />;
		case PAYMENT_TEMPLATE_TYPES.BLUEMEDIA:
			return <PaperClipOutlined />;
		case PAYMENT_TEMPLATE_TYPES.SUBSCRIPTION_APPSTORE:
			return <HistoryOutlined />;
		case PAYMENT_TEMPLATE_TYPES.SUBSCRIPTION_BLUEMEDIA:
			return <HistoryOutlined />;
		case PAYMENT_TEMPLATE_TYPES.SUBSCRIPTION_GOOGLEPLAY:
			return <HistoryOutlined />;
		default:
			return null;
	}
};

export const addObjectEntriesToFormData = (formData, name, obj) => {
	Object.entries(obj).forEach(([key, value]) =>
		formData.append(`${name}[${key}]`, value)
	);
};
