import services from 'services/services';
import * as types from '../../types';

// Import helpers
import { checkFetchErrors } from 'store/actions/helpers_actions/convert_helpers';

// ******************** FETCH INGESTER ENCODING_STATS - TABLE DATA ********************
export const fetchIngesterEncodingStats = () => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_INGESTER_ENCODING_STATS_LOADING,
			payload: true
		});

		const { data } = await services.get(`/ingester/encodings/stats`);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_INGESTER_ENCODING_STATS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_INGESTER_ENCODING_STATS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** FETCH INGESTER ASSETS_STATS - TABLE DATA ********************
export const fetchIngesterAssetsStats = () => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_INGESTER_ASSETS_STATS_LOADING,
			payload: true
		});

		const { data } = await services.get(`/ingester/assets/stats`);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_INGESTER_ASSETS_STATS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_INGESTER_ASSETS_STATS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
