import React from 'react';
import PropTypes from 'prop-types';
// import { fetchPromotionProductsList } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import ProductsSelect from 'components/utilities/products_select/ProductsSelect';

const PromotionsProductsList = ({
	productListStore: { data, error, isLoaded },
	fetchListAction,
	productType,
	tabs
}) => {
	const { t } = useTranslation();

	const createSubmitData = (selectedData) => {
		const selection = { products: [] };

		selection.products = selectedData.map((item) => ({
			uuid: item.uuid
		}));

		return selection;
	};
	return (
		<ProductsSelect
			api="promotions"
			apiSlug={`products/${productType}/sync`}
			title={t('promotions:product_list.title')}
			tabs={tabs}
			openModalText={t('promotions:product_list.add_packets')}
			buttonSubmitText={t('common:buttons.assign')}
			notificationName="products"
			initialData={data}
			error={error}
			isLoaded={isLoaded}
			fetchResourcesAction={fetchListAction}
			createSubmitData={createSubmitData}
		/>
	);
};

PromotionsProductsList.propTypes = {
	productListStore: PropTypes.shape({
		data: PropTypes.array.isRequired,
		isLoaded: PropTypes.bool.isRequired,
		error: PropTypes.bool.isRequired
	}),
	fetchListAction: PropTypes.func.isRequired,
	productType: PropTypes.string.isRequired
};

export default PromotionsProductsList;
