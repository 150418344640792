import React from 'react';
import useCatchupStatistics from './useCatchupStatistics';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { Link } from 'react-router-dom';

function CatchupStatistics() {
	const { t } = useTranslation();
	const { catchupStatisticsData } = useCatchupStatistics();

	return (
		<div className="statistics__wrapper">
			<h2>{t('statistics:titles.catchup')}</h2>
			<table className="statistics__table">
				<tbody>
					{catchupStatisticsData.map(
						({ count, catchup_recording_state }, index) => {
							return (
								<tr key={`${catchup_recording_state}-${index}`}>
									<td>
										<Link
											to={{
												pathname: '/panel/epg_global',
												state: {
													epgGlobalFilters: {
														filterUrlQuery: `columns[12][search][value]=${catchup_recording_state}&`,
														filterFormValues: {
															catchup_recording_state
														}
													}
												}
											}}
										>
											{catchup_recording_state ?? t('common:none')}
										</Link>
									</td>
									<td>{count}</td>
								</tr>
							);
						}
					)}
				</tbody>
			</table>
		</div>
	);
}

export default CatchupStatistics;
