import FileDownload from 'js-file-download';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';
import { notificationHandler } from 'components/utilities/notifications';

// Import services
import services from 'services/services';

export const useCognitoMetadata = () => {
	const { t } = useTranslation();
	const { id } = useParams();
	const history = useHistory();

	const handleSubmit = async (values) => {
		// Submit the form with field values
		let formData = new FormData();
		formData.append('file', values.metadata_file[0]);
		formData.append('collection', 'metadata');
		formData.append('model_id', id);
		formData.append('model_type', 'cognito_catalogue');

		return await submitForm({
			history,
			redirect: false,
			resources: formData,
			api: `cognito/categories/assets/metadata`,
			createSlug: false
		});
	};

	const handleButtonDownloadClick = async () => {
		try {
			const url = `/assets/${id}/list?model_type=cognito_catalogue&collection=metadata`;
			const { data } = await services.get(url);
			const dataUrl = data[0]?.url;

			// Data to download is fetched from Google Service
			// hence valid data url is needed and raw axios is used
			if (dataUrl) {
				const { data } = await axios.get(dataUrl);
				const file = JSON.stringify(data);

				return FileDownload(file, 'metadata.json');
			} else {
				return notificationHandler(
					t('messages:notifications.error'),
					`${t('messages:notifications.file_not_found')}`,
					'error'
				);
			}
		} catch (error) {
			return notificationHandler(
				t('messages:notifications.error'),
				`${t('common:errors.data_retrieve')}`,
				'error'
			);
		}
	};

	return {
		onSubmit: handleSubmit,
		onButtonDownloadClick: handleButtonDownloadClick
	};
};
