// Import helpers
import { convertPrice, convertPriceToCents } from 'components/helpers/convert';

// PROMOTION TYPES
export const PERCENTAGE = 'percentage';
export const DISCOUNT = 'discount';
export const STATIC_PRICE = 'static_price';

// ENTRY CONDITIONS
export const INHERITED = 'inherited';

// PRODUCT SPECIFICATION
export const ALL_PRODUCTS = 'all_products';
export const PRODUCTS_LIST = 'products_list';

// USER SPECIFICATION
export const ALL_USERS = 'all_users';
export const PACKETS_LIST = 'packets_list';
export const USERS_LIST = 'users_list';
export const NEW_USERS = 'new_users';

// OTHER
export const AUTO = 'auto';
export const CODE = 'code';

// convert promotion value to proper unit (percentage | zł) in promotion table
export const convertPromotionValue = (promotionType, value) => {
	switch (promotionType) {
		case PERCENTAGE:
			return value + ' %';

		case DISCOUNT:
			return convertPrice(value) + ' zł';

		case STATIC_PRICE:
			return convertPrice(value) + ' zł';

		default:
			return value;
	}
};

// convert promotion form values to int (percent) and to grosz (discount | static_price)
// before sending request
export const convertFormSubmitValues = (promotionType, value) => {
	switch (promotionType) {
		case PERCENTAGE:
			return parseInt(value, 10);

		case DISCOUNT:
			return convertPriceToCents(value);

		case STATIC_PRICE:
			return convertPriceToCents(value);

		default:
			return value;
	}
};

// convert promotion values in form initial values
// if somehow value is equal to 0 return null
export const convertFormInitialValues = (promotionType, value) => {
	if (promotionType !== PERCENTAGE) {
		const convertedPrice = convertPrice(value);
		return convertedPrice ? convertedPrice : 0;
	}
	return value || 0;
};

export const convertNumberValue = (value) => {
	return value ? parseInt(value, 10) : null;
};

export const getPromotionType = (promotion_type) => {
	switch (promotion_type) {
		case PERCENTAGE:
			return 'promotions:form:select_values:promotion_type.percentage';
		case DISCOUNT:
			return 'promotions:form:select_values:promotion_type.discount';
		case STATIC_PRICE:
			return 'promotions:form:select_values:promotion_type.static_price';

		default:
			return promotion_type;
	}
};

// switch translations for info under form fields. Info can change
// dynamically based on user input
export const switchInfo = (value, formSubSection) => {
	// Subsection of form - entry condition
	// Created becasue of repeaded form names and values
	if (formSubSection === 'entry_condition') {
		switch (value) {
			case INHERITED:
				return 'promotions:form:fields_info.entry_condition_inherited';
			case ALL_PRODUCTS:
				return 'promotions:form:fields_info.entry_condition_all_products';
			case PRODUCTS_LIST:
				return 'promotions:form:fields_info.entry_condition_products_list';

			default:
				return '';
		}
	}
	switch (value) {
		// Users specification
		case ALL_USERS:
			return 'promotions:form:fields_info.users_specification_all';
		case PACKETS_LIST:
			return 'promotions:form:fields_info.users_specification_packet';
		case USERS_LIST:
			return 'promotions:form:fields_info.users_specification_user';
		case ALL_PRODUCTS:
			return 'promotions:form:fields_info.product_specification_all';
		case PRODUCTS_LIST:
			return 'promotions:form:fields_info.product_specification_list';
		case AUTO:
			return 'promotions:form:fields_info.auto';
		case CODE:
			return 'promotions:form:fields_info.code';
		case PERCENTAGE:
			return 'promotions:form:fields_info.percentage';
		case DISCOUNT:
			return 'promotions:form:fields_info.discount';
		case STATIC_PRICE:
			return 'promotions:form:fields_info.static_price';

		default:
			return '';
	}
};
