import React from 'react';
import { Alert } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import logic
import useShakaPlayer from './useShakaPlayer';

const ShakaPlayer = (props) => {
	const { t } = useTranslation();

	const { videoRef, containerRef, error, data, isSuccess } = useShakaPlayer(
		props
	);

	return (
		<>
			{error && (
				<Alert
					type="error"
					description={JSON.stringify(error)}
					message={t('common:error')}
					className="m-b-md animated fadeIn"
				/>
			)}

			<div className="video-container" ref={containerRef}>
				<video className="shaka-video" id="shaka-video" ref={videoRef} autoPlay>
					{isSuccess &&
						data.playlist.subtitles?.map((item) => (
							<track
								key={item.id}
								label="Subtitles"
								kind="subtitles"
								srcLang={item.language}
								src={item.url}
								default=""
							/>
						))}
				</video>
			</div>
		</>
	);
};

ShakaPlayer.propTypes = {
	playlistUrl: PropTypes.string.isRequired,
	videoFormat: PropTypes.string.isRequired
};

export default ShakaPlayer;
