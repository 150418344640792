import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

// Import components
import ProductSelect from 'components/common/product_select/ProductSelect';
import Label from 'components/common/inputs/Label';

// Import hooks
import useDeeplink from './context/useDeeplink';

// Import helpers
import { PRODUCT_TYPES } from 'components/helpers';

const { CHANNEL, CATCHUP } = PRODUCT_TYPES;

const SearchForProducts = () => {
	const { t } = useTranslation();
	const { productType, productSelectRef, setSelectedProduct } = useDeeplink();

	const selectedProductType = productType === CATCHUP ? CHANNEL : productType;

	return (
		<Row sm={12}>
			<Label
				columnWidth={4}
				content={t(`product_select:inputs.media_type_${productType}`)}
			/>
			<Col sm={8}>
				<ProductSelect
					ref={productSelectRef}
					productType={selectedProductType}
					disabled={!selectedProductType}
					onSelect={setSelectedProduct}
					placeholder={t(`product_select:inputs.media_type_${productType}`)}
				/>
			</Col>
		</Row>
	);
};

export default SearchForProducts;
