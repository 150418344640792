import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Import utilities
import { renderFields } from 'components/utilities/form';

// Import select options
import { select_packet_types as type } from 'components/helpers/variables';

import { BASE_INPUTS } from './input_fields';

const Fields = ({ submitting, isEdit }) => {
	const packetPermissions = useSelector(
		({ dictionary }) => dictionary.packetPermissions
	);

	const selectResources = {
		type,
		permission: packetPermissions || []
	};

	return (
		<React.Fragment>
			{BASE_INPUTS({ isEdit }).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired
};

export default Fields;
