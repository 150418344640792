import React from 'react';

const LoginTop = () => (
	<div className="login__top m-b-md p-h-md">
		<div className="logo">
			<h1 data-testid="login-heading" className="heading heading__login m-t-md">
				<img src="/images/logo.png" alt="Logo" style={{ height: 30 }} />
				<span data-testid="login-content-mgmt" className="heading d-block">
					{process.env.REACT_APP_PROJECT_NAME} {process.env.REACT_APP_ENV}
				</span>
			</h1>
		</div>
	</div>
);

export default LoginTop;
