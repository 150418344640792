import React from 'react';
import PaymentTemplateTable from './table/PaymentTemplateTable';

import { Ibox, IboxContentBase, Wraper } from 'components/common/layout';

import { createColumns } from './table/createColumns';

function PaymentTemplateModal({ isLoaded, resource, error }) {
	return (
		<Wraper error={error}>
			<Ibox>
				{isLoaded && (
					<IboxContentBase isLoaded={isLoaded}>
						<div className="h-150 m-t-lg">
							<PaymentTemplateTable
								columns={createColumns}
								resources={resource}
							/>
						</div>
					</IboxContentBase>
				)}
			</Ibox>
		</Wraper>
	);
}

export default PaymentTemplateModal;
