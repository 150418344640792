import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	tableColumns: [],
	filterFormValues: {},
	filtersSuggestions: {},
	isFiltersDisable: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************** RESET FILTER ********************
			case types.FILTER_RESET:
				draft.filterFormValues = {};
				break;

			// ****************** SET TABLE COLUMNS ******************
			case types.SET_TABLE_COLUMNS:
				draft.tableColumns = action.payload;
				break;

			// ******************** SET FILTER FORM VALUES ********************
			case types.SET_FILTER_FORM_VALUES:
				draft.filterFormValues = action.payload;
				break;

			// *************** CLEAR FILTERS ***************
			case types.CLEAR_FILTERS:
				draft.filterFormValues = {};
				break;

			// *************** CREATE_FILTERS_SUGGESTIONS ***************
			case types.CREATE_FILTERS_SUGGESTIONS:
				draft.filtersSuggestions = action.payload;
				break;

			// ******************** SET FILTERS DISABLE ********************
			case types.SET_FILTERS_DISABLE:
				draft.isFiltersDisable = action.payload;
				break;

			default:
				return state;
		}
	});
