import React from 'react';
import PropTypes from 'prop-types';
import { Select, Spin } from 'antd';
import moment from 'moment';

// Import hooks
import useProductSelect from './useProductSelect';

const { Option } = Select;

const ProductSelect = React.forwardRef(
	(
		{
			productType,
			disabled,
			onSelect = () => {},
			placeholder,
			catchupDate,
			channelExternalId
		},
		ref
	) => {
		const {
			fetchProductList,
			productData,
			handleSelect,
			selectedProduct,
			isLoading
		} = useProductSelect({
			productType,
			onSelect,
			ref,
			catchupDate,
			channelExternalId
		});

		const loadingIndicator = () =>
			isLoading ? (
				<div className="text-center">
					<Spin size="middle" />
				</div>
			) : null;

		const getOptionLabel = ({ title, since, till }) => {
			if (productType === 'catchup') {
				return `${title} 🕗 (${moment(since).format('hh:mm A')} ~ ${moment(
					till
				).format('hh:mm A')})`;
			}
			return title;
		};

		return (
			<Select
				showSearch
				allowClear
				size="large"
				placeholder={placeholder}
				style={{ width: '100%' }}
				value={selectedProduct}
				notFoundContent={loadingIndicator()}
				loading={isLoading}
				filterOption={false}
				onSearch={fetchProductList}
				onChange={handleSelect}
				disabled={disabled}
			>
				{!isLoading &&
					productData?.map(({ uuid, title, since, till }) => (
						<Option key={uuid} value={uuid} label={title}>
							{getOptionLabel({ title, since, till })}
						</Option>
					))}
			</Select>
		);
	}
);

ProductSelect.propTypes = {
	productType: PropTypes.string,
	disabled: PropTypes.bool,
	onSelect: PropTypes.func,
	placeholder: PropTypes.string,
	catchupDate: PropTypes.string,
	channelExternalId: PropTypes.string
};

export default ProductSelect;
