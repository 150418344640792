import React from 'react';

// Import translation
import i18n from 'i18next';

// Import helpers
import { actionNames } from './helpers';

const iconStyle = {
	display: 'inline-block',
	width: '18px',
	color: '#ffa500',
	fontSize: '12px'
};

export const createColumns = [
	{
		title: i18n.t('trackings:info_columns.action'),
		dataIndex: 'action',
		key: 'action',
		render: (colSpan) => (
			<>
				<span className="icon" style={iconStyle}>
					<i
						className={`fa ${actionNames[colSpan].icon}`}
						aria-hidden="true"
					></i>
				</span>{' '}
				{actionNames[colSpan].name}
			</>
		)
	},
	{
		title: i18n.t('trackings:info_columns.action_time'),
		dataIndex: 'action_time',
		key: 'action_time'
	},

	{
		title: i18n.t('trackings:info_columns.material_time'),
		dataIndex: 'material_time',
		key: 'material_time'
	},
	{
		title: i18n.t('trackings:info_columns.bitrate'),
		dataIndex: 'bitrate',
		key: 'bitrate'
	}
];
