import { combineReducers } from 'redux';
// Impor reducers
import table from './categories_panel_reducer';
import form from './categories_form_reducer';
import merge from './categories_merge_reducer';
import files from './categories_files_reducer';

export default combineReducers({
	table,
	form,
	files,
	merge
});
