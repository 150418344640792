import React from 'react';
import { any, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { Modal, Button } from 'antd';
import { IngesterModalLinkList } from 'components/views/vod/vod_encoding/components';

// Import utilities
import { useToggle } from 'hooks';

function IngesterDataModal({ data, type }) {
	const { t } = useTranslation();
	const [isOpened, toggle] = useToggle();

	const addLinks = type === 'stripes';

	if (!data || data?.length === 0) {
		return (
			<div className="d-flex justify-content-center">{t('common:no_data')}</div>
		);
	}

	return (
		<div className="d-flex justify-content-center">
			<Button onClick={toggle}>
				<i className="fa fa-info" />
			</Button>
			<Modal open={isOpened} footer={null} onCancel={toggle} width={900}>
				{addLinks ? (
					<IngesterModalLinkList data={data} />
				) : (
					<pre className="adi-code">{JSON.stringify(data, null, 2)}</pre>
				)}
			</Modal>
		</div>
	);
}

IngesterDataModal.propTypes = {
	data: any,
	type: string
};

IngesterDataModal.defaultProps = {
	showRepeat: false
};

export default IngesterDataModal;
