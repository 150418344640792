import services from 'services/services';

// Import utilities
import { messageConfirmHandler } from 'components/utilities/message';

// Import translation
import i18n from 'i18next';

// Import api
import { GET_ENCODER_ID_DATA_API } from 'components/helpers/api';

// Repeat or cancel encoding confirmation modal and dispatch repeat / cancel encoding action
export const handleActionEncoding = (type, id, action) => {
	const title =
		type === 'repeat'
			? i18n.t('ingester:ingester_encoding:repeat_encoding_modal.title')
			: i18n.t('ingester:ingester_encoding:cancel_encoding_modal.title');
	const description =
		type === 'repeat'
			? i18n.t('messages:modal.repeat_encoding_confirm_message')
			: i18n.t('messages:modal.cancel_encoding_confirm_message');

	messageConfirmHandler(title, description, id, action, 'confirm');
};

export const fetchEncoderIdData = (encoderId) => {
	const url = GET_ENCODER_ID_DATA_API.replace(':encoder_id', encoderId);

	return services.get(url);
};
