import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setBannersQuery, fetchBanners, deleteBanner } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './banners_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const Banners = ({
	error,
	setBannersQuery,
	fetchBanners,
	deleteBanner,
	resources
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	return (
		// Dispatch fetchBanners, deleteBanner action in banners_panel
		<PanelPageTemplate
			title={t('banners:title')}
			error={error}
			type="banners"
			addLinkText={t('banners:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchBanners}
			deleteItemAction={deleteBanner}
			notificationDeleteSuccessTxt={t('banners:notification.delete_success')}
			notificationDeleteErrorTxt={t('banners:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setBannersQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Banners.propTypes = {
	error: PropTypes.bool.isRequired,
	setBannersQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchBanners: PropTypes.func.isRequired,
	deleteBanner: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		error: state.banners.table.error,
		resources: state.banners.table
	};
};

export default connect(mapStateToProps, {
	setBannersQuery,
	fetchBanners,
	deleteBanner
})(Banners);
