import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	loading: true,
	error: false,
	data: [],
	columns: [],
	deleteItem: {
		success: false,
		error: false
	},
	options: {
		pages: 0,
		total_results: 0,
		sorted: undefined,
		filters: {
			query: '',
			filterUrlQuery: ''
		}
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR DATA ******************
			case types.CLEAR_PANEL_STATE:
				return { ...INITIAL_STATE };

			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// ******************** FETCH INGESTER_ASSETS_ITEMS - VOD DETAILS DATA ********************
			case types.FETCH_INGESTER_ASSETS_ITEMS_VOD_DETAILS_LOADING:
				draft.loading = true;
				draft.error = false;
				return;

			case types.FETCH_INGESTER_ASSETS_ITEMS_VOD_DETAILS_SUCCESS:
				draft.loading = false;
				draft.data = action.payload.data;
				return;

			case types.FETCH_INGESTER_ASSETS_ITEMS_VOD_DETAILS_ERROR:
				draft.error = action.payload;
				return;

			// ******************** REFRESH INGESTER HISTORY ********************
			case types.REFRESH_INGESTER_ASSETS_ITEMS:
				draft.data = [];
				return;

			default:
				return state;
		}
	});
