import React from 'react';
import { Link } from 'react-router-dom';

// Import helpers
import {
	convertPromotionValue,
	getPromotionType
} from 'components/helpers/promotion_helpers/helpers';

// Import translation
import i18n from 'i18next';

// Import utilities
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('promotions:table_columns.active'),
		accessor: 'active',
		width: 75,
		headerClassName: 'text-center',
		className: 'text-center',
		Cell: ({
			row: {
				_original: { active }
			}
		}) => <ActiveIcon active={active} />
	},
	{
		Header: i18n.t('promotions:table_columns.name'),
		accessor: 'name',
		className: 'scroll-cell',
		Cell: ({
			row: {
				_original: { id, name }
			}
		}) => <Link to={`/panel/coupons/edit/${id}/metadata`}>{name}</Link>
	},
	{
		Header: i18n.t('promotions:table_columns.description'),
		accessor: 'description'
	},
	{
		Header: i18n.t('promotions:table_columns.promotion_type'),
		accessor: 'promotion_type',
		width: 140,
		Cell: ({
			row: {
				_original: { promotion_type }
			}
		}) => i18n.t(getPromotionType(promotion_type))
	},
	{
		Header: i18n.t('promotions:table_columns.promotion_value'),
		accessor: 'promotion_value',
		width: 130,
		Cell: ({
			row: {
				_original: { promotion_type, promotion_value }
			}
		}) => convertPromotionValue(promotion_type, promotion_value)
	},
	{
		Header: i18n.t('promotions:table_columns.since'),
		accessor: 'since'
	},
	{
		Header: i18n.t('promotions:table_columns.till'),
		accessor: 'till'
	},
	{
		Header: i18n.t('common:table_columns.system'),
		accessor: 'system'
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(id)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
