import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// Import variables
import * as api from 'components/helpers/api';

// ******************** FETCH BANNER TO EDIT ********************
export const fetchBanner = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_BANNER_LOADING,
			payload: true
		});

		const url = api.FETCH_BANNER_API.replace(':id', id);

		const response = await services.get(url);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_BANNER_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_BANNER_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
