import i18n from 'i18next';

export const defaultFieldValues = {};

export const BASE_FIELDS = [
	{
		name: 'active',
		label: i18n.t('channels:system:fields.active'),
		type: 'checkbox'
	},
	{
		name: 'casts',
		label: i18n.t('channels:system:fields.casts'),
		type: 'multi-select',
		required: false
	},
	{
		name: 'platforms',
		label: i18n.t('channels:system:fields.platforms'),
		type: 'multi-select',
		required: true
	},
	{
		name: 'ranks',
		label: i18n.t('channels:system:fields.ranks'),
		type: 'multi-select',
		mode: 'tags',
		fieldInfo: i18n.t('channels:system:fields_info.ranks'),
		required: true
	},
	{
		name: 'subscriber_local_limitation',
		label: i18n.t('channels:system:fields.subscriber_local_limitation'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'parallel_streams_limit',
		label: i18n.t('channels:system:fields.parallel_streams_limit'),
		type: 'number',
		required: true
	},
	{
		name: 'npvr_max_depth',
		label: i18n.t('channels:system:fields.npvr_max_depth'),
		type: 'number',
		required: false
	}
];

export const VISIBLE_FIELDS = [
	{
		name: 'visible_at_home',
		label: i18n.t('channels:system:fields.visible_at_home'),
		type: 'checkbox'
	},
	{
		name: 'visible_secondary',
		label: i18n.t('channels:system:fields.visible_secondary'),
		type: 'checkbox'
	}
];
