import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterUrlQuery, setFilterFormValues } from 'store/actions';

const useEpgGlobalInitialFilters = ({ linkState }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		linkState && setInitialFilters();
		// eslint-disable-next-line
	}, []);

	const setInitialFilters = () => {
		const filterUrlQuery = linkState?.epgGlobalFilters?.filterUrlQuery;
		const filterFormValues = linkState?.epgGlobalFilters?.filterFormValues;

		filterUrlQuery && setFilterUrlQuery(filterUrlQuery)(dispatch);
		filterFormValues && setFilterFormValues(filterFormValues)(dispatch);
	};
};

export default useEpgGlobalInitialFilters;
