import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setMainQuery, fetchMain, deleteMain } from 'store/actions';
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './main_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const Main = ({ error, setMainQuery, fetchMain, deleteMain, resources }) => {
	const { t } = useTranslation();

	const { columns } = resources;

	return (
		// Dispatch fetchMain, deleteMain action in main_panel
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="main"
			addLinkText={t('main:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchMain}
			deleteItemAction={deleteMain}
			notificationDeleteSuccessTxt={t('main:notification.delete_success')}
			notificationDeleteErrorTxt={t('main:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setMainQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Main.propTypes = {
	error: PropTypes.bool.isRequired,
	setMainQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchMain: PropTypes.func.isRequired,
	deleteMain: PropTypes.func.isRequired
};

const mapStateToProps = ({ main: { table } }) => {
	return {
		error: table.error,
		resources: table
	};
};

export default connect(mapStateToProps, {
	setMainQuery,
	fetchMain,
	deleteMain
})(Main);
