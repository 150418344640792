import React from 'react';
import PropTypes from 'prop-types';

// Import context
import { MassChangesProvider } from './MassChangesProvider';

// Import components
import {
	Wraper,
	Ibox,
	IboxTitle,
	IboxContentBase
} from 'components/common/layout';
import SelectProductType from './select_product_type/SelectProductType';
import ProductsButtons from './products_buttons/ProductsButtons';
import MassChangesElementsList from './elements_list';

// Import products types
import { productsTypes } from './helpers/products_types';

// Import variables
import { PROMOTION_PRODUCTS_OUT } from 'components/helpers/variables';

const MassChangesContextWrapper = ({
	title,
	productsTitle,
	typeSelectLabel,
	productSelectLabel,
	labelSelector = 'label',
	actionType,
	notificationTxt = '',
	buttonSubmitText,
	redirectPath,
	notificationTitle = ''
}) => {
	const createSubmitData = ({ selection, actionType, id, api }) => {
		const products = selection.map(({ uuid, type }) => ({
			uuid,
			type,
			order: 0
		}));

		const submitData = {};

		switch (actionType) {
			case 'add':
				submitData.add = products;
				submitData.del = [];
				break;

			default:
				submitData.add = [];
				submitData.del = products;
				break;
		}

		switch (api) {
			case 'promotions':
				submitData.promotion_id = id;
				submitData.type = PROMOTION_PRODUCTS_OUT;
				break;

			case 'section':
				submitData.section_id = id;
				break;

			default:
				break;
		}

		return submitData;
	};

	return (
		<MassChangesProvider
			productsTypes={productsTypes}
			productsTitle={productsTitle}
			typeSelectLabel={typeSelectLabel}
			productSelectLabel={productSelectLabel}
			labelSelector={labelSelector}
			buttonSubmitText={buttonSubmitText}
			createSubmitData={createSubmitData}
			actionType={actionType}
			notificationTxt={notificationTxt}
			redirectPath={redirectPath}
			notificationTitle={notificationTitle}
		>
			{({ error, selection, handleRemoveItem, productsTitle }) => {
				return (
					<Wraper error={error}>
						<Ibox error={false}>
							<IboxTitle title={title} />
							<IboxContentBase isLoaded={true}>
								<SelectProductType />
								<MassChangesElementsList
									selection={selection}
									handleRemoveItem={handleRemoveItem}
									productsTitle={productsTitle}
								/>
								<ProductsButtons />
							</IboxContentBase>
						</Ibox>
					</Wraper>
				);
			}}
		</MassChangesProvider>
	);
};

MassChangesContextWrapper.propTypes = {
	typeSelectLabel: PropTypes.string.isRequired,
	productSelectLabel: PropTypes.string.isRequired,
	productsTitle: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	labelSelector: PropTypes.string,
	actionType: PropTypes.string.isRequired,
	notificationTxt: PropTypes.string.isRequired,
	buttonSubmitText: PropTypes.string.isRequired,
	redirectPath: PropTypes.string.isRequired,
	notificationTitle: PropTypes.string.isRequired
};

export default MassChangesContextWrapper;
