import i18n from 'i18next';

// Import helpers
import { isIPValid } from 'components/helpers/validation_helpers';

export const validate = ({ ip_address }) => {
	const errors = {};

	if (!ip_address) {
		errors.ip_address = i18n.t('ip_verification:form.validation.ip_required');
	} else if (!isIPValid(ip_address)) {
		errors.ip_address = i18n.t(
			'ip_verification:form.validation.ip_format_error'
		);
	}

	return errors;
};
