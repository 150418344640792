import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { InputFileLabel, InputLabel } from 'components/common/inputs';

const Fields = ({ submitting }) => {
	const { t } = useTranslation();

	return (
		<>
			<Field
				disabled={submitting}
				name="mappings_name"
				type="text"
				placeholder={t('topology:fields.mappings_name')}
				label={t('topology:fields.mappings_name')}
				id="mappings_name"
				required={true}
				component={InputLabel}
			/>
			<Field
				disabled={submitting}
				name="ports_file"
				type="file"
				placeholder={t('topology:fields.ports_file')}
				label={t('topology:fields.ports_file')}
				id="ports_file"
				required={true}
				component={InputFileLabel}
			/>
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
