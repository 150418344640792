import i18n from 'i18next';

export const TAGS_FILTER_FIELDS = [
	{
		name: 'name',
		label: i18n.t('filters:fields.name'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'value',
		label: i18n.t('filters:fields.value'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'type',
		label: i18n.t('filters:fields.type'),
		type: 'custom-names',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'category',
		label: i18n.t('filters:fields.category'),
		type: 'custom-names',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	}
];
