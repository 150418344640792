import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import variables
import { INCREMENT, DECREMENT } from './helpers';

export const createDeviceColumns = ({
	userId,
	subscriberCouponChange,
	isReadOnly
}) => {
	return [
		{
			Header: i18n.t('subscribers:coupons.table_columns.promotion_id'),
			accessor: 'promotion_id',
			sortable: false,
			Cell: ({
				row: {
					_original: { promotion_id, promotion_name }
				}
			}) => (
				<Link to={`/panel/promotions/edit/${promotion_id}/metadata`}>
					{promotion_name ?? promotion_id}
				</Link>
			)
		},
		{
			Header: i18n.t('subscribers:coupons.table_columns.used'),
			accessor: 'used',
			sortable: false
		},
		{
			Header: i18n.t('subscribers:coupons.table_columns.remaining'),
			sortable: false,
			Cell: ({
				row: {
					_original: { used, limit }
				}
			}) => limit - used
		},
		{
			Header: i18n.t('subscribers:coupons.table_columns.increment'),
			accessor: 'increment',
			width: 90,
			className: 'text-center',
			sortable: false,
			Cell: ({
				row: {
					_original: { promotion_id }
				}
			}) =>
				!isReadOnly && (
					<button
						onClick={() =>
							subscriberCouponChange({
								userId,
								promotionId: promotion_id,
								modificationType: INCREMENT
							})
						}
						className="btn btn-xs btn-primary"
					>
						<i className="fa fa-plus" />
					</button>
				)
		},
		{
			Header: i18n.t('subscribers:coupons.table_columns.decrement'),
			accessor: 'decrement',
			width: 90,
			className: 'text-center',
			sortable: false,
			Cell: ({
				row: {
					_original: { promotion_id, used, limit }
				}
			}) => {
				const isDisabled = used >= limit;

				return (
					!isReadOnly && (
						<button
							onClick={() =>
								subscriberCouponChange({
									userId,
									promotionId: promotion_id,
									modificationType: DECREMENT
								})
							}
							className="btn btn-xs btn-primary"
							disabled={isDisabled}
						>
							<i className="fa fa-minus" />
						</button>
					)
				);
			}
		}
	];
};
