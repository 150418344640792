import React from 'react';
import PropTypes from 'prop-types';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import helpers
import { prepareSectionsList } from './helpers';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({ submitting, sections }) => {
	const sectionsList = prepareSectionsList(sections);

	const selectResources = {
		section_id: sectionsList
	};

	return (
		<React.Fragment>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	sections: PropTypes.array.isRequired
};

export default Fields;
