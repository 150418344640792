import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	dictionaryCustomProperties,
	dictionarySections,
	dictionaryCustomPropertiesValues
} from 'store/actions';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

const useCognitoForm = ({
	initialValues: {
		parent_uuid: initialParentUuid,
		section_id: initialSectionId
	},
	fetchCognitoCategory
}) => {
	const { state } = useLocation();
	const { id } = useParams();
	const dispatch = useDispatch();
	const { customProperties, sections, customPropertiesValues } = useSelector(
		({ dictionary }) => dictionary
	);
	const previousPath = useSelector(
		({ previous_location }) => previous_location.pathname
	);
	// state
	const [parentUuid, setParentUuid] = useState(null);
	const [sectionId, setSectionId] = useState(null);
	const [sectionUrl, setSectionUrl] = useState(null);
	const [redirectPath, setRedirectPath] = useState(null);
	const [parentSystem, setParentSystem] = useState('');

	const handleFetchFormResources = () => {
		dictionaryCustomProperties()(dispatch);
		dictionarySections()(dispatch);
		dictionaryCustomPropertiesValues()(dispatch);
	};

	const handleFetchResourceToEdit = () => fetchCognitoCategory(id);

	useEffect(() => {
		handleFetchFormResources();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const parent = state?.parentId;
		const parentSystem = state?.system;

		if (parent) {
			setParentUuid(parent);
		} else {
			setParentUuid(initialParentUuid);
		}

		setParentSystem(parentSystem);

		// eslint-disable-next-line
	}, [JSON.stringify(state), initialParentUuid]);

	useEffect(() => {
		if (sections.length) {
			const searchedSection = sections?.find(
				({ id }) => id === initialSectionId
			);

			if (searchedSection) {
				const { id, name, label } = searchedSection;

				const sectionId = `${name} | ${label}`;
				const sectionUrl = `/panel/sections/edit/${id}/metadata`;

				setSectionId(sectionId);
				setSectionUrl(sectionUrl);
			}
		}
		// eslint-disable-next-line
	}, [JSON.stringify(state), initialSectionId, JSON.stringify(sections)]);

	useEffect(() => {
		if (previousPath) {
			const [, path] = previousPath.split('panel/');
			setRedirectPath(path);
		} else {
			setRedirectPath('menu_stb');
		}
		// eslint-disable-next-line
	}, [previousPath]);

	useEffect(() => {
		// We need fresh form data, when user switch catalog to avoid empty fields
		id && handleFetchResourceToEdit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return {
		customProperties,
		customPropertiesValues,
		parent_uuid: parentUuid,
		section_id: sectionId,
		initialSectionId,
		redirectPath,
		sectionUrl,
		parentSystem
	};
};

useCognitoForm.propTypes = {
	initialParentUuid: PropTypes.string
};

export default useCognitoForm;
