import React from 'react';
import PropTypes from 'prop-types';
import { renderFields } from './renderer/renderFields';

const FieldsWithLabel = ({ label, fields, submitting }) => (
	<>
		<h3>{label}</h3>
		<div className="hr-line-solid">&nbsp;</div>
		{fields.map((item, index) => renderFields({ item, index, submitting }))}
	</>
);

FieldsWithLabel.propTypes = {
	label: PropTypes.string.isRequired,
	fields: PropTypes.array.isRequired,
	submitting: PropTypes.bool.isRequired
};

export default FieldsWithLabel;
