import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	icon: {
		data: [],
		isLoaded: false,
		error: false
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// ****************** FETCH CATEGORIES ICON FILES ******************
			case types.FETCH_CATEGORIES_ICON_FILES_LOADING:
				draft.icon.isLoaded = false;
				draft.icon.error = false;
				return;

			case types.FETCH_CATEGORIES_ICON_FILES_SUCCESS:
				draft.icon.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.icon.isLoaded = true;
				return;

			case types.FETCH_CATEGORIES_ICON_FILES_ERROR:
				draft.icon.isLoaded = true;
				draft.icon.error = true;
				return;

			default:
				return state;
		}
	});
