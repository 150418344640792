import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Import utilites
import { renderFields } from 'components/utilities/form';

// Import variables
import { PAYMENT_TEMPLATE_TYPES } from 'components/helpers/variables';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import helpers
import { PaymentTemplateContext } from '../../../PaymentTemplateContext';
import SelectPaymentMethod from '../SelectPaymentMethod';

const Fields = ({ submitting, isEdit }) => {
	const { usedPayments } = useContext(PaymentTemplateContext);

	const selectResources = {
		payment_method: Object.values(PAYMENT_TEMPLATE_TYPES)
			.filter((payment) => !usedPayments.includes(payment))
			.map((value) => ({
				name: <SelectPaymentMethod payment_method={value} />,
				value
			}))
	};

	return (
		<React.Fragment>
			{BASE_FIELDS(isEdit).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired
};

export default Fields;
