import { combineReducers } from 'redux';
// Import reducers
import table from './epg_panel_reducer';
import form from './epg_form_reducer';
import system from './program_systems_reducer';

export default combineReducers({
	table,
	form,
	system
});
