// Import translation
import i18n from 'i18next';

export default ({ description, action, applicable_from }) => {
	const errors = {};

	// ------------------ Description ------------------
	if (!description) {
		errors.description = i18n.t(
			'agreements_versions:validation.description_required'
		);
	}

	// ------------------ Action ------------------
	if (!action) {
		errors.action = i18n.t('agreements_versions:validation.action_required');
	} else if (action.length < 2 || action.length > 200) {
		errors.action = i18n.t('agreements_versions:validation.action_length');
	}

	// ------------------ Applicable_from ------------------
	if (!applicable_from) {
		errors.applicable_from = i18n.t(
			'agreements_versions:validation.applicable_from_required'
		);
	}

	return errors;
};
