import services from 'services/services';
import * as types from '../types';

// Import variables
import * as api from 'components/helpers/api';
import { TABLE_LENGTH } from 'components/helpers/table';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// ******************** FETCH SUBSCRIBER CONSENTS ********************
export const fetchSubscriberConsents = (options, id) => async (
	dispatch,
	getState
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_SUBSCRIBER_CONSENTS_LOADING,
			payload: true
		});
		const {
			subscribers: {
				consents: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = api.FETCH_SUBSCRIBER_CONSENTS_API.replace(':id', id);

		const { data } = await services.get(
			`${url}&${filterUrlQuery}${queryParams(
				options,
				TABLE_LENGTH,
				columns,
				startIndex
			)}`
		);
		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SUBSCRIBER_CONSENTS_SUCCESS,
			payload: { ...data }
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SUBSCRIBER_CONSENTS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
