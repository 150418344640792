import services from 'services/services';
import * as types from 'store/actions/types';

// Import helpers
import { checkFetchErrors } from 'store/actions/helpers_actions/convert_helpers';

// ******************** FETCH INGESTER ASSETS_ITEMS - VOD DETAILS DATA ********************
export const fetchIngesterAssetsItemsVodDetails = (vodId) => async (
	dispatch
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_INGESTER_ASSETS_ITEMS_VOD_DETAILS_LOADING
		});

		const url = `ingester/assets/items?order[0][column]=1&order[0][dir]=asc&start=0&product_uuid=${vodId}`;

		const { data } = await services.get(url);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_INGESTER_ASSETS_ITEMS_VOD_DETAILS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_INGESTER_ASSETS_ITEMS_VOD_DETAILS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** REFRESH INGESTER HISTORY ********************
export const refreshIngesterAssetsItems = () => (dispatch) =>
	dispatch({
		type: types.REFRESH_INGESTER_ASSETS_ITEMS
	});
