import i18n from 'i18next';

export const AGREEMENTS_FILTER_FIELDS = [
	{
		name: 'name',
		label: i18n.t('filters:fields.agreements_name'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'obligatory',
		label: i18n.t('filters:fields.obligatory'),
		type: 'checkbox',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'type',
		label: i18n.t('filters:fields.type'),
		type: 'multi-select',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'rank',
		label: i18n.t('filters:fields.rank'),
		type: 'number',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	}
];
