import React from 'react';
import PropTypes from 'prop-types';

// Import variables
import { SYSTEM_TYPES } from 'components/helpers/variables';

import { SystemWrapper, InfoWrapper } from './wrappers';

const allSystems = Object.values(SYSTEM_TYPES);

function FieldWrapper({
	visible,
	systems,
	info,
	content,
	modalWidth,
	children
}) {
	if (!visible) return null;
	return (
		<SystemWrapper systems={systems}>
			<InfoWrapper info={info} content={content} modalWidth={modalWidth}>
				{children}
			</InfoWrapper>
		</SystemWrapper>
	);
}

FieldWrapper.defaultProps = {
	visible: true,
	systems: null,
	info: false,
	content: <div></div>,
	modalWidth: 520
};

FieldWrapper.propTypes = {
	visible: PropTypes.bool.isRequired,
	systems: PropTypes.arrayOf(PropTypes.oneOf(allSystems)),
	info: PropTypes.bool.isRequired,
	content: PropTypes.node,
	modalWidth: PropTypes.number
};

export default FieldWrapper;
