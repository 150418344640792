import { useQuery } from 'react-query';
import services from 'services/services';

// Import api
import {
	GET_STATISTICS_PROVIDERS_API,
	QUERY_STATISTICS_PROVIDERS_DATA
} from 'components/helpers/api';

export default function useProviderStatistics() {
	const { data } = useQuery({
		queryKey: QUERY_STATISTICS_PROVIDERS_DATA,
		queryFn: fetchProvidersList
	});

	function fetchProvidersList() {
		return services.get(GET_STATISTICS_PROVIDERS_API);
	}

	const providerStatisticsData = data?.data || [];

	return { providerStatisticsData };
}
