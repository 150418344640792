// Import translation
import i18n from 'i18next';

export default ({ asset_url, drm_key }) => {
	const errors = {};

	// ------------------ Asset URL ------------------
	if (!asset_url) {
		errors.asset_url = i18n.t('common:materials_table.validation.url_required');
	}

	return errors;
};
