import React from 'react';

// Import components
import { Link } from 'react-router-dom';
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

// Import helpers
import { mapAvailabilityProductTypeName } from 'components/helpers/name_mapping';

// Import translation
import i18n from 'i18next';

// Import utilities
import ScheduleActivity from 'components/utilities/schedule_activity/ScheduleActivity';
import DeleteButton from 'components/utilities/delete_button/DeleteButton';

export const createColumns = (handleSelectItem, isReadOnly, locationType) => [
	{
		Header: i18n.t('availability:table_columns.active'),
		accessor: 'active',
		width: 75,
		className: 'text-center ',
		Cell: ({
			row: {
				_original: { id, active }
			}
		}) => <ScheduleActivity id={id} isActive={active} />
	},
	{
		Header: i18n.t('availability:table_columns.type'),
		accessor: 'type',
		width: 75,
		className: 'text-center',
		Cell: ({
			row: {
				_original: { type }
			}
		}) => <ActiveIcon active={type} />
	},
	{
		Header: i18n.t('availability:table_columns.since'),
		accessor: 'since'
	},
	{
		Header: i18n.t('availability:table_columns.till'),
		accessor: 'till'
	},
	{
		Header: i18n.t('availability:table_columns.created_at'),
		accessor: 'created_at'
	},
	{
		Header: i18n.t('common:table_columns.system'),
		accessor: 'system'
	},
	{
		Header: i18n.t('sections:table_columns.edit'),
		width: 80,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { product_uuid, id }
			}
		}) => {
			const mappedProductType = mapAvailabilityProductTypeName(locationType);
			return (
				!isReadOnly && (
					<Link
						to={`/panel/${mappedProductType}/${product_uuid}/availability/edit/${id}`}
						className="btn btn-xs btn-primary"
					>
						<i className="fa fa-gear" />
					</Link>
				)
			);
		}
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id, system_id, source_system_id }
			}
		}) => (
			<DeleteButton
				id={id}
				productSystem={system_id}
				sourceSystem={source_system_id}
				onSelectItem={handleSelectItem}
				isReadOnly={isReadOnly}
			/>
		)
	}
];
