import i18n from 'i18n';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

export const avaliableExtensions = ['jpeg', 'png'];

export const notifyVerify = (success) => {
	if (success) {
		notificationHandler(
			i18n.t('verification:notification.verify.title'),
			i18n.t('verification:notification.verify.subtitle')
		);
	} else {
		notificationHandler(
			i18n.t('verification:notification.verify.title'),
			i18n.t('verification:notification.verify.subtitle_error'),
			'error'
		);
	}
};

export const notifyUnverify = (success) => {
	if (success) {
		notificationHandler(
			i18n.t('verification:notification.unverify.title'),
			i18n.t('verification:notification.unverify.subtitle')
		);
	} else {
		notificationHandler(
			i18n.t('verification:notification.unverify.title'),
			i18n.t('verification:notification.unverify.subtitle_error'),
			'error'
		);
	}
};
