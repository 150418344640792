import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

// Import components
import DeleteButton from 'components/utilities/delete_button/DeleteButton';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('tags:table_columns.name'),
		accessor: 'name'
	},
	{
		Header: i18n.t('tags:table_columns.value'),
		accessor: 'value'
	},
	{
		Header: i18n.t('tags:table_columns.category'),
		accessor: 'category',
		width: 120
	},
	{
		Header: i18n.t('tags:table_columns.type'),
		accessor: 'type',
		width: 120
	},
	{
		Header: i18n.t('tags:table_columns.order'),
		accessor: 'order',
		width: 120
	},
	{
		Header: i18n.t('common:table_columns.edit'),
		accessor: 'edit',
		width: 70,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) => (
			<Link
				to={`/panel/tags/edit/${id}/metadata`}
				className="btn btn-xs btn-primary"
				disabled={isReadOnly}
			>
				<i className="fa fa-gear" />
			</Link>
		)
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 70,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) => (
			<DeleteButton
				id={id}
				onSelectItem={handleSelectItem}
				isReadOnly={isReadOnly}
			/>
		)
	}
];
