import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';

// Import helpers
import { handleDeleteEntry } from './helpers';

const DeleteTarget = ({ target_type, external_id, campaign_id }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const handleOnClick = async () => {
		setIsLoading(true);
		try {
			await handleDeleteEntry({ target_type, external_id, campaign_id });
			notification.success({
				message: t(
					'marketing_campaigns:targets.notifications.success.delete_title'
				),
				description: t(
					'marketing_campaigns:targets.notifications.success.delete_description'
				)
			});
		} catch {
			notification.error({
				message: t(
					'marketing_campaigns:targets.notifications.error.delete_title'
				),
				description: t(
					'marketing_campaigns:targets.notifications.error.delete_description'
				)
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Button
			size="small"
			loading={isLoading}
			icon={<DeleteOutlined />}
			onClick={handleOnClick}
		/>
	);
};

DeleteTarget.propTypes = {
	target_type: PropTypes.string,
	external_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	campaignId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default DeleteTarget;
