import i18n from 'i18n';

export const defaultValues = {
	alias: '',
	name: '',
	is_redirection: false,
	redirection_url: '',
	content: i18n.t('documents:default_values.content')
};

export const BASE_FIELDS = ({ values, metaData: { isEditPage } }) => {
	const isRedirection = values.is_redirection ?? false;

	return [
		{
			name: 'alias',
			type: 'text',
			label: i18n.t('documents:fields.alias_placeholder'),
			required: true,
			fieldInfo: ''
		},
		{
			name: 'title',
			type: 'text',
			placeholder: i18n.t('documents:fields.title_placeholder'),
			label: i18n.t('documents:fields.title_label'),
			required: true,
			fieldInfo: ''
		},
		{
			name: 'is_redirection',
			type: 'checkbox',
			label: i18n.t('documents:fields.is_redirection'),
			required: false
		},
		{
			name: 'redirection_url',
			type: 'text',
			placeholder: i18n.t('documents:fields.redirection_url'),
			label: i18n.t('documents:fields.redirection_url'),
			required: isRedirection
		},
		{
			name: 'content',
			type: 'tiny-text-editor',
			editorValues: { content: values.content },
			required: !isRedirection,
			fieldInfo: !isEditPage ? i18n.t('documents:fields.field_info') : ''
		}
	];
};
