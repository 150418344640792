import React from 'react';
import PropTypes from 'prop-types';

// Import utilities
import { renderFields } from 'components/utilities/form';

// Import helpers
import { BASE_FIELDS } from './input_fields';
import { filterResources } from './helpers';

const Fields = ({ submitting, templates, isMain }) => {
	const { periods } = filterResources(isMain);

	const selectResources = {
		template: templates,
		period: periods
	};
	return (
		<React.Fragment>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	templates: PropTypes.array,
	isMain: PropTypes.bool
};

Fields.defaultProps = {
	isMain: false,
	templates: []
};

export default Fields;
