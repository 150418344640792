import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	data: [],
	total: 0,
	isLoaded: false,
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR SECTION STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// ****************** FETCH PROMOTION_OUT PRODUCTS LIST ******************
			case types.FETCH_PROMOTION_OUT_PRODUCTS_LIST_LOADING:
				draft.isLoaded = false;
				draft.error = false;
				return;

			case types.FETCH_PROMOTION_OUT_PRODUCTS_LIST_SUCCESS:
				draft.data = action.payload.data;
				draft.total = action.payload.total;
				draft.isLoaded = true;
				return;

			case types.FETCH_PROMOTION_OUT_PRODUCTS_LIST_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
