// ******************** PAYMENTS DATA TABLE ********************
export const FETCH_PAYMENTS_LOADING = 'FETCH_PAYMENTS_LOADING';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_ERROR = 'FETCH_PAYMENTS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_PAYMENTS_SEARCH_QUERY = 'SET_PAYMENTS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_PAYMENTS_TABLE_SORTING = 'SAVE_PAYMENTS_TABLE_SORTING';

// ******************** SET PAYMENT ID TYPE ********************
export const SET_PAYMENTS_ID_TYPE = 'SET_PAYMENTS_ID_TYPE';

// ******************** SET PAYMENT ID SUBTYPE ********************
export const SET_PAYMENTS_ID_SUBTYPE = 'SET_PAYMENTS_ID_SUBTYPE';
