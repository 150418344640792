import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	setBarkerChannelProductsQuery,
	fetchBarkerChannelProducts,
	deleteBarkerChannelProduct,
	repeatBarkerChannelProductIngestion
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './barker_channel_products_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const BarkerChannelProducts = ({
	error,
	setBarkerChannelProductsQuery,
	fetchBarkerChannelProducts,
	deleteBarkerChannelProduct,
	repeatBarkerChannelProductIngestion,
	resources,
	match: {
		params: { id }
	}
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	return (
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="barker_channels_products"
			resources={resources}
			columns={createColumns({
				refreshAction: repeatBarkerChannelProductIngestion
			})}
			itemId={id}
			fetchResourcesAction={fetchBarkerChannelProducts}
			deleteItemAction={deleteBarkerChannelProduct}
			notificationDeleteSuccessTxt={t(
				'barker_channels:products.notification.delete_success'
			)}
			notificationDeleteErrorTxt={t(
				'barker_channels:products.notification.delete_error'
			)}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setBarkerChannelProductsQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

BarkerChannelProducts.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	setBarkerChannelProductsQuery: PropTypes.func.isRequired,
	fetchBarkerChannelProducts: PropTypes.func.isRequired,
	deleteBarkerChannelProduct: PropTypes.func.isRequired,
	repeatBarkerChannelProductIngestion: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	})
};

const mapStateToProps = ({ barker_channels: { products } }) => {
	return {
		error: products.error,
		resources: products
	};
};

export default compose(
	connect(mapStateToProps, {
		setBarkerChannelProductsQuery,
		fetchBarkerChannelProducts,
		deleteBarkerChannelProduct,
		repeatBarkerChannelProductIngestion
	}),
	withRouter
)(BarkerChannelProducts);
