// Get data from selected and convert them to selectedData format
export const getNewSelectedData = (
	tabs,
	selected,
	selectedData,
	idSelector
) => {
	const newSelected = tabs.reduce((acc, tabItem) => {
		if (selected[tabItem.type]) {
			acc = [...acc, ...selected[tabItem.type]];
		}
		return acc;
	}, []);

	// checking if each item from select already exist in selectedData
	const newSelectedData = newSelected.filter(
		(newItem) =>
			selectedData.findIndex(
				(item) => item[idSelector] === newItem[idSelector]
			) === -1
	);

	return newSelectedData;
};

// Convert data from selected to select format
export const dataToSelect = (selected, type, idSelector, titleSelector) => {
	let selectedTabData = [];
	if (selected[type]) {
		selectedTabData = selected[type].map((item) => ({
			key: item[idSelector],
			label: item[titleSelector]
		}));
	}

	return selectedTabData;
};

// Convert data from select format to selected
export const dataToSelected = (value, data, idSelector) => {
	const newSelected = value.reduce((acc, { key }) => {
		const item = data.find((item) =>
			idSelector === 'id'
				? item[idSelector] === +key
				: typeof item[idSelector] === 'string' // we need this becasue uuid can be either a string or a number
				? item[idSelector] === key
				: item[idSelector] === +key
		);

		return [...acc, item];
	}, []);

	return newSelected;
};

// chekc and get src
export const getImgUrl = (img) => {
	if (img) {
		if (img.poster && img.poster.length) {
			return img.poster[0].url;
		} else if (img.logo && img.logo.length) {
			return img.logo[0].url;
		} else if (img.cover && img.cover.length) {
			return img.cover[0].url;
		} else if (img.length) {
			return img[0].url;
		}
	}

	return null;
};

// compare if arrays are the same
export const compareArrays = (array, updatedArray) =>
	array.length === updatedArray.length &&
	array.every((value, index) => value === updatedArray[index]);

// generate link to proper product in section preview grid
export const generateProductLink = (item) => {
	const { type, subtype, uuid, parent_uuid } = item;
	switch (type) {
		case 'channel':
			return `/panel/channels/edit/${uuid}/metadata`;

		case 'vod':
			return `/panel/vod/${subtype}/edit/${uuid}/metadata`;

		case 'banner':
			return `/panel/banners/edit/${uuid}/metadata`;

		// added with only collection in mind (if more packet subtypes will be needed (main | provisioning) expand switch)
		case 'packet':
			return `/panel/packets/edit/${uuid}/metadata`;

		case 'program':
			return `/panel/channels/${parent_uuid}/epg/edit/${uuid}/metadata`;

		default:
			return '';
	}
};

// render additional data next to vod title based on provided array
export const renderAdditionalLabelData = (array, item) =>
	array
		.map((tag) => {
			if (!!item[tag]) {
				return tag === 'payment_models' && item[tag].length === 0 //payment_models is an array and we don't want to return empty array
					? null
					: item[tag];
			} else return null;
		})
		.filter((item) => item !== null)
		.join(' | ');

export const getApiUrlForSystemProducts = ({ selectedType, system }) => {
	switch (selectedType) {
		case 'providerSystem':
			return `/products/providers/sync?system=${system}`;

		default:
			return `/channel/system/updateAvailability?system=${system}`;
	}
};

export const PRODUCT_TYPES = {
	PROVIDER_SYSTEM: 'providerSystem'
};
