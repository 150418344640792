import React from 'react';
import i18n from 'i18next';

// Import components
import TagsForm from 'components/views/tags/tags_form/TagsForm.js';

export const tagsTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <TagsForm />
	}
];
