import React from 'react';
import PropTypes from 'prop-types';

// Import select options
import { select_packet_types as type } from 'components/helpers/variables';

// Import utilities
import { renderFields } from 'components/utilities/form';

import { BASE_FIELDS } from './input_fields';

const Fields = ({ submitting, isEdit }) => {
	const DEFAULT_PACKET_TYPE_SELECT = 'collection';

	const defaultType = type.find(
		(option) => option.value === DEFAULT_PACKET_TYPE_SELECT
	).value;

	const selectResources = {
		type
	};

	return (
		<React.Fragment>
			{BASE_FIELDS(isEdit, defaultType).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired
};

export default Fields;
