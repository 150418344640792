import i18n from 'i18next';

export const LIMITS_FILTER_FIELDS = [
	{
		name: 'group_name',
		label: i18n.t('filters:fields.group_name'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'limit',
		label: i18n.t('filters:fields.limit'),
		type: 'number',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	}
];
