// ******************** DEVICES DATA TABLE ********************
export const FETCH_DEVICES_SN_LOADING = 'FETCH_DEVICES_SN_LOADING';
export const FETCH_DEVICES_SN_SUCCESS = 'FETCH_DEVICES_SN_SUCCESS';
export const FETCH_DEVICES_SN_ERROR = 'FETCH_DEVICES_SN_ERROR';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_DEVICES_SN_TABLE_SORTING = 'SAVE_DEVICES_SN_TABLE_SORTING';

// ******************** SET SEARCH QUERY ********************
export const SET_DEVICES_SN_SEARCH_QUERY = 'SET_DEVICES_SN_SEARCH_QUERY';

// ******************** DEVICES SN FORM ********************
export const FETCH_DEVICE_SN_LOADING = 'FETCH_DEVICE_SN_LOADING';
export const FETCH_DEVICE_SN_SUCCESS = 'FETCH_DEVICE_SN_SUCCESS';
export const FETCH_DEVICE_SN_ERROR = 'FETCH_DEVICE_SN_ERROR';

// ******************** DEVICES SN SERIAL PACKETS ********************
export const FETCH_DEVICE_SN_PACKETS_LOADING =
	'FETCH_DEVICE_SN_PACKETS_LOADING';
export const FETCH_DEVICE_SN_PACKETS_SUCCESS =
	'FETCH_DEVICE_SN_PACKETS_SUCCESS';
export const FETCH_DEVICE_SN_PACKETS_ERROR = 'FETCH_DEVICE_SN_PACKETS_ERROR';

// ******************** UPDATE DEVICES SN VIA CSV ********************
export const UPDATE_DEVICE_SN_LIST_CSV_SUCCESS =
	'UPDATE_DEVICE_SN_LIST_CSV_SUCCESS';
