import { combineReducers } from 'redux';
// Import reducers
import table from './agreements_panel_reducer';
import form from './agreements_form_reducer';
import versions_table from './agreements_versions_panel_reducer';
import versions_form from './agreements_versions_form_reducer';

const versions = combineReducers({
	table: versions_table,
	form: versions_form
});

export default combineReducers({
	table,
	form,
	versions
});
