// Import helpers
import {
	PACKETS_LIST,
	USERS_LIST,
	PRODUCTS_LIST
} from 'components/helpers/promotion_helpers/helpers';

export const PROMOTIONS_EDIT_TABS_PATHS = {
	PROMOTIONS_USERS: 'promotions_users',
	PROMOTIONS_PRODUCTS_OUT: 'promotions_products_out',
	PROMOTIONS_PRODUCTS_OUT_PREVIEW: 'promotions_products_out_preview',
	PROMOTIONS_PRODUCTS_IN: 'promotions_products_in',
	PROMOTIONS_PRODUCTS_IN_PREVIEW: 'promotions_products_in_preview',
	PROMOTIONS_USERS_WITH_PACKETS: 'promotions_users_with_packets'
};

const {
	PROMOTIONS_USERS,
	PROMOTIONS_PRODUCTS_OUT,
	PROMOTIONS_PRODUCTS_OUT_PREVIEW,
	PROMOTIONS_USERS_WITH_PACKETS,
	PROMOTIONS_PRODUCTS_IN,
	PROMOTIONS_PRODUCTS_IN_PREVIEW
} = PROMOTIONS_EDIT_TABS_PATHS;

export const shouldTabBeHidden = (
	{ productOutSpecification, productInSpecification, usersSpecification },
	tabLabel
) => {
	switch (tabLabel) {
		// Should hide user selection tab
		case PROMOTIONS_USERS:
			return usersSpecification !== USERS_LIST;

		// Should hide user selection tab based on their packets
		case PROMOTIONS_USERS_WITH_PACKETS:
			return usersSpecification !== PACKETS_LIST;

		// Should hide product selection tab
		case PROMOTIONS_PRODUCTS_OUT:
			return productOutSpecification !== PRODUCTS_LIST;

		// Should hide promotion items preview
		case PROMOTIONS_PRODUCTS_OUT_PREVIEW:
			return (
				productOutSpecification !== PRODUCTS_LIST &&
				productOutSpecification !== PACKETS_LIST
			);

		// Should hide product selection (n from m)
		case PROMOTIONS_PRODUCTS_IN:
			return productInSpecification !== PRODUCTS_LIST;

		// Should hide promotion items preview (n from m)
		case PROMOTIONS_PRODUCTS_IN_PREVIEW:
			return (
				productInSpecification !== PRODUCTS_LIST &&
				productInSpecification !== PACKETS_LIST
			);

		// Hide tabs by default
		default:
			return true;
	}
};
