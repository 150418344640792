// ******************** SYSTEMS DATA TABLE ********************
export const FETCH_SYSTEMS_LOADING = 'FETCH_SYSTEMS_LOADING';
export const FETCH_SYSTEMS_SUCCESS = 'FETCH_SYSTEMS_SUCCESS';
export const FETCH_SYSTEMS_ERROR = 'FETCH_SYSTEMS_ERROR';

// ******************** SYSTEM DATA FORM ********************
export const FETCH_SYSTEM_LOADING = 'FETCH_SYSTEM_LOADING';
export const FETCH_SYSTEM_SUCCESS = 'FETCH_SYSTEM_SUCCESS';
export const FETCH_SYSTEM_ERROR = 'FETCH_SYSTEM_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_SYSTEMS_SEARCH_QUERY = 'SET_SYSTEMS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_SYSTEMS_TABLE_SORTING = 'SAVE_SYSTEMS_TABLE_SORTING';

// ******************** SYSTEM PROVIDERS PRODUCTS ********************
export const FETCH_SYSTEM_PROVIDERS_PRODUCTS_LOADING =
	'FETCH_SYSTEM_PROVIDERS_PRODUCTS_LOADING';
export const FETCH_SYSTEM_PROVIDERS_PRODUCTS_SUCCESS =
	'FETCH_SYSTEM_PROVIDERS_PRODUCTS_SUCCESS';
export const FETCH_SYSTEM_PROVIDERS_PRODUCTS_ERROR =
	'FETCH_SYSTEM_PROVIDERS_PRODUCTS_ERROR';

// ******************** SYSTEM CHANNELS PRODUCTS ********************
export const FETCH_SYSTEM_CHANNELS_PRODUCTS_LOADING =
	'FETCH_SYSTEM_CHANNELS_PRODUCTS_LOADING';
export const FETCH_SYSTEM_CHANNELS_PRODUCTS_SUCCESS =
	'FETCH_SYSTEM_CHANNELS_PRODUCTS_SUCCESS';
export const FETCH_SYSTEM_CHANNELS_PRODUCTS_ERROR =
	'FETCH_SYSTEM_CHANNELS_PRODUCTS_ERROR';
