// ******************** INGESTER ASSETS ********************
// -------------------- INGESTER ASSETS DATA TABLE --------------------
export const FETCH_INGESTER_ASSETS_LOADING = 'FETCH_INGESTER_ASSETS_LOADING';
export const FETCH_INGESTER_ASSETS_SUCCESS = 'FETCH_INGESTER_ASSETS_SUCCESS';
export const FETCH_INGESTER_ASSETS_ERROR = 'FETCH_INGESTER_ASSETS_ERROR';

// -------------------- INGESTER ASSETS VOD DETAILS DATA TABLE --------------------
export const FETCH_INGESTER_ASSETS_VOD_DETAILS_LOADING =
	'FETCH_INGESTER_ASSETS_VOD_DETAILS_LOADING';
export const FETCH_INGESTER_ASSETS_VOD_DETAILS_SUCCESS =
	'FETCH_INGESTER_ASSETS_VOD_DETAILS_SUCCESS';
export const FETCH_INGESTER_ASSETS_VOD_DETAILS_ERROR =
	'FETCH_INGESTER_ASSETS_VOD_DETAILS_ERROR';

// -------------------- REFRESH INGESTER ASSETS --------------------
export const REFRESH_INGESTER_ASSETS = 'REFRESH_INGESTER_ASSETS';

// -------------------- SET INGESTER ASSETS SEARCH QUERY --------------------
export const SET_INGESTER_ASSETS_SEARCH_QUERY =
	'SET_INGESTER_ASSETS_SEARCH_QUERY';

// ******************** SAVE INGESTER ASSETS SORTING OPTIONS  ********************
export const SAVE_INGESTER_ASSETS_TABLE_SORTING =
	'SAVE_INGESTER_ASSETS_TABLE_SORTING';

// ******************** INGESTER ENCODING ********************
// -------------------- INGESTER ENCODING DATA TABLE --------------------
export const FETCH_INGESTER_ENCODING_LOADING =
	'FETCH_INGESTER_ENCODING_LOADING';
export const FETCH_INGESTER_ENCODING_SUCCESS =
	'FETCH_INGESTER_ENCODING_SUCCESS';
export const FETCH_INGESTER_ENCODING_ERROR = 'FETCH_INGESTER_ENCODING_ERROR';

// -------------------- INGESTER ENCODING VOD DETAILS DATA TABLE --------------------
export const FETCH_INGESTER_ENCODING_VOD_DETAILS_LOADING =
	'FETCH_INGESTER_ENCODING_VOD_DETAILS_LOADING';
export const FETCH_INGESTER_ENCODING_VOD_DETAILS_SUCCESS =
	'FETCH_INGESTER_ENCODING_VOD_DETAILS_SUCCESS';
export const FETCH_INGESTER_ENCODING_VOD_DETAILS_ERROR =
	'FETCH_INGESTER_ENCODING_VOD_DETAILS_ERROR';

// -------------------- REFRESH INGESTER ENCODING --------------------
export const REFRESH_INGESTER_ENCODING = 'REFRESH_INGESTER_ENCODING';

// -------------------- SET INGESTER ENCODING SEARCH QUERY --------------------
export const SET_INGESTER_ENCODING_SEARCH_QUERY =
	'SET_INGESTER_ENCODING_SEARCH_QUERY';

// ******************** SAVE INGESTER ASSETS SORTING OPTIONS  ********************
export const SAVE_INGESTER_ENCODING_TABLE_SORTING =
	'SAVE_INGESTER_ENCODING_TABLE_SORTING';

// ******************** INGESTER STATS ********************
// -------------------- INGESTER ENCODING STATS DATA --------------------
export const FETCH_INGESTER_ENCODING_STATS_LOADING =
	'FETCH_INGESTER_ENCODING_STATS_LOADING';
export const FETCH_INGESTER_ENCODING_STATS_SUCCESS =
	'FETCH_INGESTER_ENCODING_STATS_SUCCESS';
export const FETCH_INGESTER_ENCODING_STATS_ERROR =
	'FETCH_INGESTER_ENCODING_STATS_ERROR';

// -------------------- INGESTER ASSETS STATS DATA --------------------
export const FETCH_INGESTER_ASSETS_STATS_LOADING =
	'FETCH_INGESTER_ASSETS_STATS_LOADING';
export const FETCH_INGESTER_ASSETS_STATS_SUCCESS =
	'FETCH_INGESTER_ASSETS_STATS_SUCCESS';
export const FETCH_INGESTER_ASSETS_STATS_ERROR =
	'FETCH_INGESTER_ASSETS_STATS_ERROR';

// ******************** VIDEO ASSETS PARAMS ********************
// -------------------- VIDEO ASSETS PARAMS DATA TABLE --------------------
export const FETCH_VIDEO_ASSETS_PARAMS_LOADING =
	'FETCH_VIDEO_ASSETS_PARAMS_LOADING';
export const FETCH_VIDEO_ASSETS_PARAMS_SUCCESS =
	'FETCH_VIDEO_ASSETS_PARAMS_SUCCESS';
export const FETCH_VIDEO_ASSETS_PARAMS_ERROR =
	'FETCH_VIDEO_ASSETS_PARAMS_ERROR';
// -------------------- DELETE VIDEO ASSETS PARAMS --------------------
export const DELETE_VIDEO_ASSETS_PARAMS_LOADING =
	'DELETE_VIDEO_ASSETS_PARAMS_LOADING';
export const DELETE_VIDEO_ASSETS_PARAMS_SUCCESS =
	'DELETE_VIDEO_ASSETS_PARAMS_SUCCESS';
export const DELETE_VIDEO_ASSETS_PARAMS_ERROR =
	'DELETE_VIDEO_ASSETS_PARAMS_ERROR';
// -------------------- SET SEARCH QUERY --------------------
export const SET_VIDEO_ASSETS_PARAMS_SEARCH_QUERY =
	'SET_VIDEO_ASSETS_PARAMS_SEARCH_QUERY';

// -------------------- VIDEO ASSETS PARAMS FORM --------------------
export const FETCH_VIDEO_ASSETS_PARAM_LOADING =
	'FETCH_VIDEO_ASSETS_PARAM_LOADING';
export const FETCH_VIDEO_ASSETS_PARAM_SUCCESS =
	'FETCH_VIDEO_ASSETS_PARAM_SUCCESS';
export const FETCH_VIDEO_ASSETS_PARAM_ERROR = 'FETCH_VIDEO_ASSETS_PARAM_ERROR';

// ******************** INGESTER HISTORY ********************
// -------------------- INGESTER HISTORY VOD DETAILS DATA TABLE --------------------
export const FETCH_INGESTER_HISTORY_VOD_DETAILS_LOADING =
	'FETCH_INGESTER_HISTORY_VOD_DETAILS_LOADING';
export const FETCH_INGESTER_HISTORY_VOD_DETAILS_SUCCESS =
	'FETCH_INGESTER_HISTORY_VOD_DETAILS_SUCCESS';
export const FETCH_INGESTER_HISTORY_VOD_DETAILS_ERROR =
	'FETCH_INGESTER_HISTORY_VOD_DETAILS_ERROR';

// -------------------- REFRESH INGESTER HISTORY --------------------
export const REFRESH_INGESTER_HISTORY = 'REFRESH_INGESTER_HISTORY';

// ******************** INGESTER ASSETS ITEMS ********************
// -------------------- INGESTER ASSETS ITEMS VOD DETAILS DATA TABLE --------------------
export const FETCH_INGESTER_ASSETS_ITEMS_VOD_DETAILS_LOADING =
	'FETCH_INGESTER_ASSETS_ITEMS_VOD_DETAILS_LOADING';
export const FETCH_INGESTER_ASSETS_ITEMS_VOD_DETAILS_SUCCESS =
	'FETCH_INGESTER_ASSETS_ITEMS_VOD_DETAILS_SUCCESS';
export const FETCH_INGESTER_ASSETS_ITEMS_VOD_DETAILS_ERROR =
	'FETCH_INGESTER_ASSETS_ITEMS_VOD_DETAILS_ERROR';

// -------------------- REFRESH INGESTER ASSETS_ITEMS --------------------
export const REFRESH_INGESTER_ASSETS_ITEMS = 'REFRESH_INGESTER_ASSETS_ITEMS';
