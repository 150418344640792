// Import translation
import i18n from 'i18next';

export const checkDecimalPlaces = (value) => {
	const decimals = value.toString().split('.')[1];
	if (value && Math.floor(value) !== value)
		return decimals ? decimals.length || 0 : 0;
	return 0;
};

export default ({ payment_method, price }) => {
	const errors = {};

	// ------------------ Price ------------------
	if (!price) {
		errors.price = i18n.t('payment_templates:validation.price_required');
	} else if (price <= 0) {
		errors.price = i18n.t('payment_templates:validation.price_length');
	} else if (checkDecimalPlaces(price) >= 3) {
		errors.price = i18n.t('payment_templates:validation.price_step');
	} else if (price >= 1000) {
		errors.price = i18n.t('payment_templates:validation.price_too_long');
	}

	// ------------------ Period ------------------
	if (!payment_method) {
		errors.payment_method = i18n.t(
			'payment_templates:validation.payment_method_required'
		);
	}
	return errors;
};
