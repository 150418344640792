// Import translation
import i18n from 'i18next';

export default ({ Name, Ordinal, href_0 }) => {
	const errors = {};

	if (!Name) {
		errors.Name = i18n.t('menu_stb:validation.name');
	}

	if (!Ordinal && Ordinal !== 0) {
		errors.Ordinal = i18n.t('menu_stb:validation.ordinal');
	}

	if (!href_0) {
		errors.href_0 = i18n.t('menu_stb:validation.custom_properties');
	}

	return errors;
};
