import { useMutation } from 'react-query';
import services from 'services/services';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';
import useSystem from 'hooks/useSystem';

// Import helpers
import { checkIsProperVectraSystem } from 'components/helpers/system_permissions';

// Import api url
import { SYNCHRONIZE_OFFERS_API } from 'components/helpers/api';

const useESalesButton = () => {
	const { isReadOnly } = useAuthCheck();
	const { currentSystem, isCurrentSystemMain } = useSystem();

	const synchronizeOffers = (payload) =>
		services.post(SYNCHRONIZE_OFFERS_API, payload);

	const { isLoading, isError, isSuccess, mutate } = useMutation(
		synchronizeOffers
	);

	const isVisible = checkIsProperVectraSystem({
		currentSystem,
		isCurrentSystemMain,
		isReadOnly
	});

	const payload = {
		channels: ['OTT', 'TVO']
	};

	const handleSynchronizeOffers = () => mutate(payload);

	return {
		isVisible,
		synchronizeOffers: handleSynchronizeOffers,
		isLoading,
		isError,
		isSuccess
	};
};

export default useESalesButton;
