import React from 'react';
import { Link } from 'react-router-dom';
// Import translation
import i18n from 'i18next';

// Import components
import DeleteButton from 'components/utilities/delete_button/DeleteButton';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('packets:table_columns.title'),
		accessor: 'title',
		Cell: ({
			row: {
				_original: { uuid, title }
			}
		}) => (
			<Link to={`/panel/packets/edit/${uuid}/metadata`} title={title}>
				{title}
			</Link>
		)
	},
	{
		Header: i18n.t('packets:table_columns.order'),
		accessor: 'order',
		width: 100
	},
	{
		Header: i18n.t('common:table_columns.system'),
		accessor: 'system'
	},
	{
		Header: i18n.t('packets:table_columns.created_at'),
		accessor: 'created_at'
	},
	{
		Header: i18n.t('packets:table_columns.updated_at'),
		accessor: 'updated_at'
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { uuid, system_id, source_system_id }
			}
		}) => (
			<DeleteButton
				id={uuid}
				productSystem={system_id}
				sourceSystem={source_system_id}
				onSelectItem={handleSelectItem}
				isReadOnly={isReadOnly}
			/>
		)
	}
];
