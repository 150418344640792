// Import translation
import i18n from 'i18next';

export const defaultFieldValues = {
	active_obox: false,
	active_ott: false,
	active_tvonline: false,
	visible_primary: false,
	visible_secondary: false
};

export const BASE_FIELDS = [
	{
		name: 'name',
		label: i18n.t('categories:fields.name'),
		type: 'text',
		required: true
	},
	{
		name: 'slug',
		label: i18n.t('categories:fields.slug'),
		type: 'text',
		required: true
	},
	{
		name: 'order',
		label: i18n.t('categories:fields.order'),
		type: 'number',
		required: false,
		step: 1
	}
];

export const VISIBLE_FIELDS = [
	{
		name: 'visible_primary',
		label: i18n.t('categories:fields.visible_primary'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'visible_secondary',
		label: i18n.t('categories:fields.visible_secondary'),
		type: 'checkbox',
		required: false
	}
];
