// Import translation
import i18n from 'i18next';

// Import variables
import { SYSTEM_TYPES } from 'components/helpers/variables';

export const BASE_FIELDS = (currentSystem) => [
	{
		name: 'login',
		disabled: true,
		type: 'text',
		placeholder: i18n.t('subscribers:fields.login'),
		label: i18n.t('subscribers:fields.login'),
		required: true
	},
	{
		name: 'tags',
		disabled: true,
		type: 'multi-select',
		required: false,
		label: i18n.t('subscribers:fields.tags')
	},
	{
		name: 'active',
		type: 'checkbox',
		disabled: true,
		placeholder: i18n.t('subscribers:fields.active'),
		label: i18n.t('subscribers:fields.active')
	},
	{
		name: 'cdr_key',
		disabled: true,
		type: 'text',
		placeholder: i18n.t('subscribers:fields.cdr_key'),
		label: i18n.t('subscribers:fields.cdr_key')
	},
	{
		name: 'business_id',
		type: 'text',
		disabled: true,
		required: false,
		isVisible: currentSystem !== SYSTEM_TYPES.tvonline,
		placeholder: i18n.t('subscribers:placeholders.business_id'),
		label: i18n.t('subscribers:fields.business_id'),
		fieldInfo: i18n.t('subscribers:fields_info.business_id')
	},
	{
		name: 'account_pin',
		type: 'text',
		disabled: false,
		required: true,
		placeholder: i18n.t('subscribers:fields.account_pin'),
		label: i18n.t('subscribers:fields.account_pin')
	},
	{
		name: 'box_account_pin',
		type: 'text',
		disabled: false,
		required: true,
		placeholder: i18n.t('subscribers:fields.box_account_pin'),
		label: i18n.t('subscribers:fields.box_account_pin')
	},
	{
		name: 'adult_access_pin',
		type: 'text',
		disabled: false,
		required: true,
		placeholder: i18n.t('subscribers:fields.adult_access_pin'),
		label: i18n.t('subscribers:fields.adult_access_pin'),
		fieldInfo: i18n.t('subscribers:fields_info.adult_access_pin')
	},
	{
		name: 'credits_limit',
		type: 'number',
		disabled: currentSystem === SYSTEM_TYPES.pureott,
		required: true,
		placeholder: i18n.t('subscribers:fields.credits_limit'),
		label: i18n.t('subscribers:fields.credits_limit'),
		fieldInfo:
			currentSystem === SYSTEM_TYPES.pureott
				? i18n.t('subscribers:fields_info.credits_limit')
				: ''
	},
	{
		name: 'npvr_storage_limit',
		type: 'number',
		disabled: false,
		required: true,
		placeholder: i18n.t('subscribers:fields.npvr_storage_limit'),
		label: i18n.t('subscribers:fields.npvr_storage_limit')
	},
	{
		name: 'full_pvr_parallel_limit',
		type: 'number',
		disabled: false,
		placeholder: i18n.t('subscribers:fields.full_pvr_parallel_limit'),
		label: i18n.t('subscribers:fields.full_pvr_parallel_limit')
	},
	{
		name: 'usb_pvr_parallel_limit',
		type: 'number',
		disabled: false,
		placeholder: i18n.t('subscribers:fields.usb_pvr_parallel_limit'),
		label: i18n.t('subscribers:fields.usb_pvr_parallel_limit')
	},
	{
		name: 'hide_unavailable_products',
		type: 'checkbox',
		disabled: false,
		placeholder: i18n.t('subscribers:fields.hide_unavailable_products'),
		label: i18n.t('subscribers:fields.hide_unavailable_products')
	}
];
