import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
	setMarketingCampaignTargetsQuery,
	createFiltersSuggestions,
	fetchMarketingCampaignTargets
} from 'store/actions';
import { Result } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// Import components
import {
	Ibox,
	IboxContentBase,
	IboxTitle,
	IboxTools,
	Wraper
} from 'components/common/layout';
import FilterButton from 'components/common/buttons/FilterButton';
import Search from 'components/utilities/search/Search';
import ImportTargets from './ImportTargets/ImportTargets';
import SelectTable from 'components/utilities/table/selectTable/SelectTable';
import MassDelete from './MassDelete';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';
import useSelectSystem from 'components/layouts/top_header/SelectSystem/useSelectSystem';

// Import helpers
import {
	TARGET_STATUS,
	TARGET_TYPES_VALUES,
	TARGET_PLATFORMS
} from 'components/helpers/variables';
import { columns } from './helpers';

const MarketingCampaignsTargets = ({
	setMarketingCampaignTargetsQuery,
	fetchMarketingCampaignTargets,
	createFiltersSuggestions,
	error,
	resources,
	isLoading
}) => {
	const { id } = useParams();
	const { t } = useTranslation();
	const ref = useRef();
	const { subSystems } = useSelectSystem();

	const { columns: filterColumns } = resources;

	const shouldUploadTestTargets = !resources?.data?.length || isLoading;

	const target_system = subSystems.map(({ name }) => ({ name, value: name }));

	const status = Object.values(TARGET_STATUS).map((value) => ({
		name: t(`marketing_campaigns:select:target_status.${value}`),
		value
	}));

	const filters = {
		target_system,
		target_platforms: TARGET_PLATFORMS,
		target_type: TARGET_TYPES_VALUES,
		status
	};

	useFilterLogic({
		createFiltersSuggestions,
		filters
	});

	const renderInfoBox = () => {
		if (isLoading) {
			return (
				<div style={{ minHeight: '30rem' }}>
					<Result icon={<LoadingOutlined spin />} />
				</div>
			);
		}
		if (!resources?.data?.length || isLoading) {
			return (
				<Result
					status="warning"
					title={t('marketing_campaigns:targets.info.testing_title')}
					subTitle={t('marketing_campaigns:targets.info.testing_description')}
					extra={<p>{t('marketing_campaigns:targets.info.testing_extra')}</p>}
				/>
			);
		} else {
			return (
				<Result
					title={t('marketing_campaigns:targets.info.prod_title')}
					subTitle={t('marketing_campaigns:targets.info.prod_description')}
				/>
			);
		}
	};

	return (
		<Wraper lg="12" error={error} isLoading={isLoading}>
			{renderInfoBox()}
			<Ibox>
				<IboxTitle
					title={t(
						shouldUploadTestTargets
							? t('marketing_campaigns:targets.info.testing_users')
							: t('marketing_campaigns:targets.info.prod_users')
					)}
				>
					<IboxTools>
						<ImportTargets refetchData={ref?.current?.refetch} id={id} />
					</IboxTools>
				</IboxTitle>
				<IboxContentBase>
					<div className="d-flex justify-content-end">
						<Search
							setQueryValue={setMarketingCampaignTargetsQuery}
							value={resources.options.filters.query}
						/>
						<FilterButton tableColumns={filterColumns} />
					</div>
					<SelectTable
						resources={resources}
						columns={columns}
						itemID={Number(id)}
						fetchDataAction={fetchMarketingCampaignTargets}
						ref={ref}
						massDeleteComponent={({
							selection,
							totalResults,
							setSelection
						}) => (
							<MassDelete
								selection={selection}
								totalResults={totalResults}
								campaignId={id}
								refetch={ref?.current?.refetch}
								setSelection={setSelection}
							/>
						)}
					/>
				</IboxContentBase>
			</Ibox>
		</Wraper>
	);
};

const mapStateToProps = ({ marketing_campaigns }) => ({
	error: marketing_campaigns.targets.error,
	isLoading: marketing_campaigns.targets.loading,
	resources: marketing_campaigns.targets
});

MarketingCampaignsTargets.propTypes = {
	setMarketingCampaignTargetsQuery: PropTypes.func,
	fetchMarketingCampaignTargets: PropTypes.func,
	createFiltersSuggestions: PropTypes.func,
	error: PropTypes.bool,
	resources: PropTypes.object,
	isLoading: PropTypes.bool
};

export default connect(mapStateToProps, {
	setMarketingCampaignTargetsQuery,
	createFiltersSuggestions,
	fetchMarketingCampaignTargets
})(MarketingCampaignsTargets);
