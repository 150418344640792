// Array of all columns which value needs to be formatted to proper date
// Basically all dates in filters
export const columnsWithDateValues = [
	'created_at',
	'updated_at',
	'since',
	'till',
	'last_login',
	'campaign_start',
	'campaign_end',
	'notification_end'
];
