// ******************** SECTION GROUPS DATA TABLE ********************
export const FETCH_SECTIONS_GROUPS_LOADING = 'FETCH_SECTIONS_GROUPS_LOADING';
export const FETCH_SECTIONS_GROUPS_SUCCESS = 'FETCH_SECTIONS_GROUPS_SUCCESS';
export const FETCH_SECTIONS_GROUPS_ERROR = 'FETCH_SECTIONS_GROUPS_ERROR';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_SECTIONS_GROUPS_TABLE_SORTING =
	'SAVE_SECTIONS_GROUPS_TABLE_SORTING';

// ******************** DELETE SECTION ********************
export const DELETE_SECTIONS_GROUP_LOADING = 'DELETE_SECTIONS_GROUP_LOADING';
export const DELETE_SECTIONS_GROUP_SUCCESS = 'DELETE_SECTIONS_GROUP_SUCCESS';
export const DELETE_SECTIONS_GROUP_ERROR = 'DELETE_SECTIONS_GROUP_ERROR';

// ******************** ASSIGNED SECTIONS DATA TABLE ********************
export const FETCH_SECTIONS_ASSIGNED_LOADING =
	'FETCH_SECTIONS_ASSIGNED_LOADING';
export const FETCH_SECTIONS_ASSIGNED_SUCCESS =
	'FETCH_SECTIONS_ASSIGNED_SUCCESS';
export const FETCH_SECTIONS_ASSIGNED_ERROR = 'FETCH_SECTIONS_ASSIGNED_ERROR';

// ******************** SECTIONS GROUP DATA FORM ********************
export const FETCH_SECTIONS_GROUP_LOADING = 'FETCH_SECTIONS_GROUP_LOADING';
export const FETCH_SECTIONS_GROUP_SUCCESS = 'FETCH_SECTIONS_GROUP_SUCCESS';
export const FETCH_SECTIONS_GROUP_ERROR = 'FETCH_SECTIONS_GROUP_ERROR';

// ******************** SET SECTIONS GROUPS TYPE ********************
export const SET_SECTIONS_GROUPS_TYPE = 'SET_SECTIONS_GROUPS_TYPE';
