import i18n from 'i18next';

export const PROMOTIONS_FILTER_FIELDS = [
	{
		name: 'active',
		label: i18n.t('filters:fields.active'),
		type: 'checkbox',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'name',
		label: i18n.t('filters:fields.name'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'description',
		label: i18n.t('filters:fields.description'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'promotion_type',
		label: i18n.t('filters:fields.promotion_type'),
		type: 'custom-names',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'promotion_value',
		label: i18n.t('filters:fields.promotion_value'),
		type: 'number',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'since',
		label: i18n.t('filters:fields.since'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	},
	{
		name: 'till',
		label: i18n.t('filters:fields.till'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	},
	{
		name: 'users_specification',
		label: i18n.t('filters:fields.users_specification'),
		type: 'custom-names',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'products_specification',
		label: i18n.t('filters:fields.products_specification'),
		type: 'custom-names',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'trigger',
		label: i18n.t('filters:fields.trigger'),
		type: 'custom-names',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'code',
		label: i18n.t('filters:fields.code'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	}
];
