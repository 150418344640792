import { useEffect } from 'react';

/**
 * Custom hook to fetch combobox data for filters and
 * convert and prepare data for filters
 * @param {object} filterActions - object with all required fetch suggestions actions
 * @param {object} categoriesOptions - object with options necessary to fetch categories (vod | live)
 * @param {func} createFiltersSuggestions - action that converts and prepares data for filters
 * @param {object} filters - fetched raw data for filters
 *
 */
const useFilterLogic = ({
	filterActions = null,
	categoriesOptions = null,
	createFiltersSuggestions,
	filters
}) => {
	// fetch combobox data for filters
	useEffect(() => {
		filterActions &&
			Object.keys(filterActions).forEach((action) => {
				if (action === 'fetchCategories') {
					filterActions[action](categoriesOptions, null, null);
				} else {
					filterActions[action]();
				}
			});
		// eslint-disable-next-line
	}, []);

	// convert and prepare data for filters
	useEffect(() => {
		createFiltersSuggestions(filters);
		// eslint-disable-next-line
	}, [JSON.stringify(filters)]);
};

export default useFilterLogic;
