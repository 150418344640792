import i18n from 'i18next';

export const PAYMENTS_FILTER_FIELDS = [
	{
		name: 'product_title',
		label: i18n.t('filters:fields.product_title'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'price',
		label: i18n.t('filters:fields.price'),
		type: 'number',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'payment_method',
		label: i18n.t('filters:fields.payment_method'),
		type: 'multi-select',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'period',
		label: i18n.t('filters:fields.period'),
		type: 'number',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'user_login',
		label: i18n.t('filters:fields.user_login'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'provider_id',
		label: i18n.t('filters:fields.provider'),
		type: 'custom-names',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'range',
		label: i18n.t('filters:fields.range'),
		type: 'range',
		placeholder: [
			i18n.t('filters:fields.since'),
			i18n.t('filters:fields.till')
		],
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		showTime: true,
		rangeFormat: 'YYYY-MM-DD HH:mm:ss',
		style: { width: '100%' }
	}
];
