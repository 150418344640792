// Import variables
import { TAGS_TYPES, TAGS_CATEGORIES } from 'components/helpers/variables';

const SELECT_TYPES = Object.values(TAGS_TYPES).map((value) => ({
	name: value,
	value
}));

const SELECT_CATEGORIES = Object.values(TAGS_CATEGORIES).map((value) => ({
	name: value,
	value
}));

export const SELECT_RESOURCES = {
	type: SELECT_TYPES,
	category: SELECT_CATEGORIES
};
