import i18n from 'i18n';
import React from 'react';
import {
	HourglassOutlined,
	SendOutlined,
	CloseOutlined,
	DeleteOutlined,
	StopOutlined
} from '@ant-design/icons';
import { Link, generatePath } from 'react-router-dom';
import services from 'services/services';

// Import components
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';
import DeleteTarget from '../DeleteTarget';

// Import variables
import { TARGET_STATUSES } from './variables';

const { READY, SENT, QUEUED, FAILED, DELETED, SKIPPED } = TARGET_STATUSES;

export const getUrl = (target_type) => {
	switch (target_type) {
		case 'serial_number':
			return '/subscribers/campaigns/targets/campaign/:campaign_id/bySerialNumber/:external_id';
		case 'id':
			return '/subscribers/campaigns/targets/campaign/:campaign_id/target/:external_id';
		case 'login':
			return '/services/subscribers/campaigns/targets/campaign/:campaign_id/byLogin/:external_id';
		default:
			return null;
	}
};

export const handleDeleteEntry = async ({
	target_type,
	external_id,
	campaign_id
}) => {
	const path = getUrl(target_type);

	if (!path) throw new Error();

	return services.delete(generatePath(path, { campaign_id, external_id }));
};

const getStatusStyles = (status) => {
	switch (status) {
		case READY:
			return { color: 'inherit' };
		case SENT:
			return { color: 'rgb(19, 206, 102)', fontWeight: 'bold' };
		case QUEUED:
			return { color: 'orange' };
		case FAILED:
			return { color: 'red' };
		case DELETED:
			return { color: 'crimson' };
		case SKIPPED:
			return { color: 'red' };
		default:
			return {};
	}
};

const getStatusIcon = (status) => {
	switch (status) {
		case READY:
		case QUEUED:
			return <HourglassOutlined className="m-r-sm" />;
		case SENT:
			return <SendOutlined className="m-r-sm" />;
		case FAILED:
			return <CloseOutlined className="m-r-sm" />;
		case DELETED:
			return <DeleteOutlined className="m-r-sm" />;
		case SKIPPED:
			return <StopOutlined className="m-r-sm" />;
		default:
			return null;
	}
};

export const columns = [
	{
		Header: i18n.t('marketing_campaigns:targets:columns.subscriber'),
		accessor: 'subscriber_id',
		sortable: false,
		Cell: ({
			row: {
				_original: { subscriber_id }
			}
		}) => (
			<Link to={`/panel/subscribers/edit/${subscriber_id}/metadata`}>
				{subscriber_id}
			</Link>
		)
	},
	{
		Header: i18n.t('marketing_campaigns:targets:columns.external_id'),
		accessor: 'external_id',
		sortable: false
	},
	{
		Header: i18n.t('marketing_campaigns:targets:columns.target_type'),
		accessor: 'target_type',
		Cell: ({
			row: {
				_original: { target_type }
			}
		}) => i18n.t(`marketing_campaigns:select:target_type.${target_type}`)
	},
	{
		Header: i18n.t('marketing_campaigns:targets:columns.target_platforms'),
		accessor: 'target_platforms'
	},
	{
		Header: i18n.t('marketing_campaigns:targets:columns.target_system'),
		accessor: 'target_system',
		width: 90
	},
	{
		Header: i18n.t('marketing_campaigns:targets.columns.status'),
		accessor: 'status',
		Cell: ({
			row: {
				_original: { status, mailbox_visibility }
			}
		}) => {
			const statusIndicator = !mailbox_visibility ? DELETED : status;

			return (
				<span style={getStatusStyles(statusIndicator)}>
					{getStatusIcon(statusIndicator)}
					{i18n.t(
						`marketing_campaigns:select:target_status.${statusIndicator}`
					)}
				</span>
			);
		}
	},
	{
		Header: i18n.t(
			'marketing_campaigns:targets:columns.notification_read_status'
		),
		accessor: 'notification_read_status',
		sortable: false,
		Cell: ({
			row: {
				_original: { notification_read_status }
			}
		}) => <ActiveIcon active={notification_read_status} />
	},
	{
		Header: i18n.t('marketing_campaigns:targets:columns.message_read_status'),
		accessor: 'message_read_status',
		sortable: false,
		Cell: ({
			row: {
				_original: { message_read_status }
			}
		}) => <ActiveIcon active={message_read_status} />
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { external_id, target_type, campaign_id }
			}
		}) => (
			<DeleteTarget
				external_id={external_id}
				target_type={target_type}
				campaign_id={campaign_id}
			/>
		)
	}
];
