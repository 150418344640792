// Import translation
import i18n from 'i18next';

export default ({ promotionId }) => {
	const errors = {};

	// ------------------ Device ID ------------------
	if (!promotionId) {
		errors.promotionId = i18n.t(
			'subscribers:coupons.form.validation.promotion_id_required'
		);
	}

	return errors;
};
