import React from 'react';
import PropTypes from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { Button } from 'reactstrap';

/**
 * Functional component that returns button able to add new video to channel (main)
 * @param {string} uuid - id of channel
 * @param {bool} isMainDisabled - this prop can disable add main video button
 * @param {func} addVideoToChannel - dispatches action creator (POST add video to product)
 * @param {bool} isLoaded - disables button if table content is not yet loaded
 */
const ChannelsAddMaterialsButtons = ({
	uuid,
	isMainDisabled = false,
	addVideoToChannel,
	isLoaded
}) => {
	const { t } = useTranslation();

	// dispatch action
	const handleAddVideo = () => {
		addVideoToChannel(uuid);
	};

	return (
		<>
			<Button
				onClick={handleAddVideo}
				disabled={isMainDisabled || !isLoaded}
				className="m-b-sm m-r-sm"
			>
				{t('channels:buttons.add_video')}
			</Button>
		</>
	);
};
ChannelsAddMaterialsButtons.propTypes = {
	uuid: PropTypes.string.isRequired,
	isMainDisabled: PropTypes.bool.isRequired,
	addVideoToChannel: PropTypes.func.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

export default ChannelsAddMaterialsButtons;
