import React from 'react';
import {
	string,
	number,
	func,
	oneOfType,
	node,
	arrayOf,
	shape,
	object,
	bool,
	array
} from 'prop-types';
import { Col, FormGroup } from 'reactstrap';

// Import components
import { FieldInfo, FieldError, Label } from 'components/common/inputs';
import InputWithModal from './InputWithModal';
import DeeplinkTabs from './DeeplinkTabs';

const Deeplink = ({
	input,
	id,
	label,
	labelColumn,
	inputColumn,
	children,
	fieldInfo,
	required,
	meta: { touched, error, submitError },
	requirements,
	...inputProps
}) => {
	const hasError = error || submitError;

	return (
		<FormGroup row>
			<Label columnWidth={labelColumn} required={required} content={label} />
			<Col sm={inputColumn}>
				<InputWithModal
					id={id}
					hasErrors={touched && error}
					{...input}
					{...inputProps}
				>
					<DeeplinkTabs input={input} />
				</InputWithModal>
				<FieldError
					error={touched && hasError}
					inputName={input.name}
					content={hasError}
				/>
				<FieldInfo info={fieldInfo} />
			</Col>
			{children}
		</FormGroup>
	);
};

Deeplink.propTypes = {
	input: shape({
		onChange: func.isRequired
	}),
	id: string.isRequired,
	label: string,
	labelColumn: number,
	inputColumn: number,
	required: bool,
	fieldInfo: string,
	children: oneOfType([node, arrayOf(node)]),
	meta: object,
	requirements: array
};

export default Deeplink;
