import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setPromotionsQuery,
	fetchPromotions,
	deletePromotion,
	createFiltersSuggestions
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import columns
import { createColumns } from './promotions_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import helpers
import {
	select_promotion_types,
	select_user_specification,
	select_product_specification,
	select_promotion_triggers
} from 'components/views/promotions/promotions_form/fields/select_type_options';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const Promotions = ({
	error,
	setPromotionsQuery,
	fetchPromotions,
	deletePromotion,
	resources,
	createFiltersSuggestions
}) => {
	const { t } = useTranslation();

	const filters = {
		promotion_type: select_promotion_types,
		users_specification: select_user_specification,
		products_specification: select_product_specification,
		trigger: select_promotion_triggers
	};
	// execute filters logic
	useFilterLogic({
		createFiltersSuggestions,
		filters
	});

	const { columns } = resources;
	return (
		// Dispatch fetchPromotions, action in subscribers_panel
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="promotions"
			addLinkText={t('promotions:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchPromotions}
			deleteItemAction={deletePromotion}
			notificationDeleteSuccessTxt={t('promotions:notification.delete_success')}
			notificationDeleteErrorTxt={t('promotions:notification.delete_error')}
			defaultSorted={[
				{ id: 'active', desc: true },
				{ id: 'since', desc: true }
			]}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setPromotionsQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Promotions.propTypes = {
	error: PropTypes.bool.isRequired,
	setPromotionsQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchPromotions: PropTypes.func.isRequired,
	deletePromotion: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired
};

const mapStateToProps = ({ promotions }) => {
	return {
		error: promotions.table.error,
		resources: promotions.table
	};
};

export default connect(mapStateToProps, {
	setPromotionsQuery,
	fetchPromotions,
	deletePromotion,
	createFiltersSuggestions
})(Promotions);
