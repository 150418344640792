import { useEffect, useState } from 'react';
import services from 'services/services';

// Import helpers
import { checkFetchErrors } from 'components/helpers/error';

const useModalInfo = (visible, videoSessionId) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [fetchError, setFetchError] = useState(false);

	const fetchTracking = async () => {
		try {
			const url = `/videosession/${videoSessionId}/tracking`;
			const { data } = await services.get(url);
			setData(data);
			setLoading(false);
		} catch (error) {
			setFetchError(checkFetchErrors(error));
		}
	};

	useEffect(() => {
		if (visible) {
			fetchTracking();
		}

		// eslint-disable-next-line
	}, [visible]);

	return { loading, data, fetchError };
};

export default useModalInfo;
