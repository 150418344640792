import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const GeolocksForm = ({ history }) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) =>
		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: 'player/ipAddress',
			redirectPath: 'geolocks',
			redirectToEdit: false
		});

	// initialValues are hardcoded since there is no edit
	const initialValues = { type: 'whitelist' };

	return (
		<FormTemplate>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={initialValues}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields submitting={submitting} />

							<FormButtons
								isButtonDisabled={submitting}
								path="geolocks"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

GeolocksForm.propTypes = {
	history: PropTypes.object.isRequired
};

export default withRouter(GeolocksForm);
