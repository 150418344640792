import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Col, FormGroup } from 'reactstrap';

// Import translation
import { useTranslation } from 'react-i18next';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

const FormButtons = ({
	buttonText,
	isButtonDisabled = false,
	cancelButton = true,
	path = '/panel/',
	linkClass = '',
	className = '',
	hash = '',
	cancelButtonDisabled = true
}) => {
	const { t } = useTranslation();

	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	return (
		!isReadOnly && (
			<FormGroup row className={className}>
				<Col
					className="d-flex justify-content-end m-t-md"
					sm={{ size: '10', offset: 2 }}
				>
					{cancelButton && (
						<Link
							className={`btn__link btn__link--outline ${linkClass} ${isButtonDisabled &&
								cancelButtonDisabled &&
								'btn__link--disabled'} m-r-md m-b-md`}
							to={{ pathname: `/panel/${path}`, hash }}
						>
							{t('common:buttons.cancel')}
						</Link>
					)}
					<Button
						className="m-b-md"
						data-testid="submit-button"
						disabled={isButtonDisabled}
						type="submit"
					>
						{buttonText}
					</Button>
				</Col>
			</FormGroup>
		)
	);
};

FormButtons.propTypes = {
	path: PropTypes.string,
	isButtonDisabled: PropTypes.bool,
	cancelButton: PropTypes.bool,
	buttonText: PropTypes.string,
	className: PropTypes.string,
	linkClass: PropTypes.string,
	hash: PropTypes.string,
	cancelButtonDisabled: PropTypes.bool
};

export default FormButtons;
