import * as types from '../types';

// Import helpers
import { mapTableTypesNames } from 'components/helpers/table';

// ******************** TABLE SORTING ********************
export const saveTableSorting = (sortData, tableType) => (dispatch) => {
	const formattedTableType = tableType.length
		? `${mapTableTypesNames(tableType).toUpperCase()}_`
		: tableType;
	// check if proper type is available before dispatching action
	if (types[`SAVE_${formattedTableType}TABLE_SORTING`]) {
		dispatch({
			type: types[`SAVE_${formattedTableType}TABLE_SORTING`],
			payload: sortData
		});
	}
};
