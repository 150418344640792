import i18n from 'i18next';

export const PROVISIONING_PRODUCTS_FILTER_FIELDS = [
	{
		name: 'title',
		label: i18n.t('filters:fields.title'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'subtype',
		label: i18n.t('filters:fields.subtype'),
		type: 'multi-select',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'provider_id',
		label: i18n.t('filters:fields.provider'),
		type: 'custom-names',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'rating',
		label: i18n.t('filters:fields.rating'),
		type: 'multi-select',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'status',
		label: i18n.t('filters:fields.status'),
		type: 'custom-names',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'genres',
		label: i18n.t('filters:fields.genres'),
		type: 'multi-select',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	}
];
