import React from 'react';
import useSubscriberCoupons from './useSubscriberCoupons';

// Import columns
import { createDeviceColumns } from './subscriber_coupons_columns';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import {
	Ibox,
	IboxTitle,
	IboxContentBase,
	Wraper
} from 'components/common/layout';
import ReactTable from 'react-table';
import { Button } from 'antd';
import SubscribersCouponModal from './subscribers_coupons_form/SubscribersCouponsModal';

const SubscribersCoupons = () => {
	const { t } = useTranslation();

	const {
		isReadOnly,
		isOpened,
		toggle,
		subscriberCouponChange,
		userId,
		resources,
		error
	} = useSubscriberCoupons();

	return (
		<Wraper lg="12" error={error}>
			<Ibox error={false} className="m-b-lg">
				<IboxTitle title={t('subscribers:coupons.title')} isLoaded={false}>
					{!isReadOnly && (
						<div className="d-flex justify-content-end">
							<Button
								type="primary"
								htmlType="button"
								onClick={toggle}
								className="ml-3"
							>
								{t('subscribers:coupons.buttons.add_coupon')}
							</Button>
							<SubscribersCouponModal
								isOpened={isOpened}
								onCancel={toggle}
								userId={userId}
								subscriberCouponChange={subscriberCouponChange}
								toggle={toggle}
							/>
						</div>
					)}
				</IboxTitle>

				<IboxContentBase>
					<ReactTable
						data={resources}
						columns={createDeviceColumns({
							userId,
							subscriberCouponChange,
							isReadOnly
						})}
						pageSize={resources.length}
						showPagination={false}
						noDataText={t('common:no_data')}
					/>
				</IboxContentBase>
			</Ibox>
		</Wraper>
	);
};

export default SubscribersCoupons;
