import React from 'react';

// Import tabs
import Tabs from 'components/utilities/tabs/Tabs';

// Import helpers
import { providersTabs } from './helpers';

const ProvidersTabs = () => <Tabs tabs={providersTabs} />;

export default ProvidersTabs;
