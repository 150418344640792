import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { Tooltip } from 'antd';
import Button from 'antd/es/button/button';

// Import hooks
import useMenuItemButtons from './useMenuItemButtons';

const MenuItemButtons = ({
	id,
	deleteCognitoCategory,
	resources,
	sectionId,
	ordinal,
	setPreviousScrollPosition,
	fetchCognitoTree,
	lastChildCategory,
	system
}) => {
	const { t } = useTranslation();

	const {
		onChangeOrdinalUp,
		onChangeOrdinalDown,
		onDeleteMenuItemClick,
		navigateToAdd,
		navigateToEdit,
		navigateToSection
	} = useMenuItemButtons({
		id,
		deleteCognitoCategory,
		resources,
		sectionId,
		ordinal,
		setPreviousScrollPosition,
		fetchCognitoTree,
		system
	});

	return (
		<div className="m-l-xl mr-4 menuItemButtons">
			{sectionId && (
				<Tooltip title={t('menu_stb:tooltips.section_link')}>
					<button
						className="btn btn-xs btn-primary mr-2"
						onClick={navigateToSection}
					>
						<i className="fa fa-arrow-right" />
					</button>
				</Tooltip>
			)}
			<Tooltip title={t('menu_stb:tooltips.add')}>
				<button className="btn btn-xs btn-primary mr-2" onClick={navigateToAdd}>
					<i className="fa fa-plus" />
				</button>
			</Tooltip>
			<Tooltip title={t('menu_stb:tooltips.edit')}>
				<button
					className="btn btn-xs btn-primary mr-2"
					onClick={navigateToEdit}
				>
					<i className="fa fa-file"></i>
				</button>
			</Tooltip>
			<Tooltip title={t('menu_stb:tooltips.delete')}>
				<button
					className="btn btn-xs btn-primary"
					onClick={onDeleteMenuItemClick}
				>
					<i className="fa fa-trash" />
				</button>
			</Tooltip>
			<Tooltip title={t('menu_stb:tooltips.ordinal_up')}>
				<Button
					className="m-l-lg"
					type="primary"
					size="small"
					shape="circle"
					disabled={ordinal === 0}
					onClick={onChangeOrdinalUp}
				>
					<i className="fa fa-arrow-up" />
				</Button>
			</Tooltip>
			<Tooltip title={t('menu_stb:tooltips.ordinal_down')}>
				<Button
					className="ml-2"
					type="primary"
					size="small"
					shape="circle"
					onClick={onChangeOrdinalDown}
					disabled={ordinal === lastChildCategory}
				>
					<i className="fa fa-arrow-down" />
				</Button>
			</Tooltip>
		</div>
	);
};

MenuItemButtons.propTypes = {
	id: PropTypes.string.isRequired,
	deleteCognitoCategory: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	sectionId: PropTypes.number,
	ordinal: PropTypes.number.isRequired,
	setPreviousScrollPosition: PropTypes.func.isRequired,
	fetchCognitoTree: PropTypes.func.isRequired,
	lastChildCategory: PropTypes.number.isRequired
};

export default MenuItemButtons;
