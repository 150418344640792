import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup } from 'reactstrap';
import { Select } from 'antd';

import { FieldInfo, FieldError, Label } from 'components/common/inputs';

const SelectLabel = ({
	input,
	id,
	label,
	labelColumn,
	inputColumn,
	children,
	meta: { touched, error, submitError },
	fieldInfo,
	onChangeCallback,
	required,
	...inputProps
}) => {
	const handleOnChange = (event) => {
		input.onChange(event);
		onChangeCallback(event);
	};

	return (
		<FormGroup
			row
			className={`${touched && error ? 'has-error' : ''} form-group`}
		>
			<Label
				id={id}
				columnWidth={labelColumn}
				required={required}
				content={label}
			/>
			<Col sm={inputColumn}>
				<Select
					invalid={touched && (error || submitError) ? 'true' : 'false'}
					type="select"
					style={{ width: '100%' }}
					className="m-b-sm"
					optionFilterProp="children"
					{...input}
					{...inputProps}
					onChange={handleOnChange}
					value={input.value || null} // set fallback to null for placeholder to work
				>
					{children}
				</Select>
				<FieldError
					error={touched && (error || submitError)}
					inputName={input.name}
					content={error || submitError}
				/>
				<FieldInfo info={fieldInfo} />
			</Col>
		</FormGroup>
	);
};

SelectLabel.defaultProps = {
	labelColumn: 2,
	inputColumn: 10,
	onChangeCallback: () => {}
};

SelectLabel.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	inputColumn: PropTypes.number,
	labelColumn: PropTypes.number,
	meta: PropTypes.object,
	required: PropTypes.bool,
	onChangeCallback: PropTypes.func,
	fieldInfo: PropTypes.string
};

export default SelectLabel;
