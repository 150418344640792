// Import translation
import i18n from 'i18next';

export const defaultFieldValues = {
	type: 'LOGIN',
	obligatory: false
};

export const BASE_FIELDS = [
	{
		name: 'name',
		type: 'text',
		label: i18n.t('agreements:fields.name_label'),
		required: true
	},
	{
		name: 'rank',
		type: 'number',
		label: i18n.t('agreements:fields.rank_label'),
		required: true
	},
	{
		name: 'type',
		type: 'select',
		label: i18n.t('agreements:fields.type_label'),
		required: true
	},
	{
		name: 'obligatory',
		type: 'checkbox',
		label: i18n.t('agreements:fields.required_label')
	}
];
