export const DICTIONARY_VIDEO_FORMATS = 'DICTIONARY_VIDEO_FORMATS';
export const DICTIONARY_PRODUCT_TYPES = 'DICTIONARY_PRODUCT_TYPES';
export const DICTIONARY_VIDEO_TYPES = 'DICTIONARY_VIDEO_TYPES';
export const DICTIONARY_ASSETS_COLLECTION_TYPES =
	'DICTIONARY_ASSETS_COLLECTION_TYPES';
export const DICTIONARY_COUNTER_TYPES = 'DICTIONARY_COUNTER_TYPES';
export const DICTIONARY_IP_RANGE_TYPES = 'DICTIONARY_IP_RANGE_TYPES';
export const DICTIONARY_PACKETS_TYPES = 'DICTIONARY_PACKETS_TYPES';
export const DICTIONARY_PACKET_PERMISSIONS = 'DICTIONARY_PACKET_PERMISSIONS';
export const DICTIONARY_PARTENTAL_CONTROL_RATING =
	'DICTIONARY_PARTENTAL_CONTROL_RATING';
export const DICTIONARY_PAYMENT_METHODS = 'DICTIONARY_PAYMENT_METHODS';
export const DICTIONARY_PLAY_MODES = 'DICTIONARY_PLAY_MODES';
export const DICTIONARY_REMINDER_TYPES = 'DICTIONARY_REMINDER_TYPES';
export const DICTIONARY_SECTION_TYPES = 'DICTIONARY_SECTION_TYPES';
export const DICTIONARY_AGREEMENTS_TYPES = 'DICTIONARY_AGREEMENTS_TYPES';
export const DICTIONARY_PLATFORMS_TYPES = 'DICTIONARY_PLATFORMS_TYPES';
export const DICTIONARY_PAYMENT_MODELS = 'DICTIONARY_PAYMENT_MODELS';
export const DICTIONARY_CAST_TYPES = 'DICTIONARY_CAST_TYPES';
export const DICTIONARY_VOD_PROVIDERS = 'DICTIONARY_VOD_PROVIDERS';
export const DICTIONARY_VOD_SUBSTATUS = 'DICTIONARY_VOD_SUBSTATUS';
export const DICTIONARY_CUSTOM_PROPERTIES = 'DICTIONARY_CUSTOM_PROPERTIES';
export const DICTIONARY_CUSTOM_PROPERTIES_VALUES =
	'DICTIONARY_CUSTOM_PROPERTIES_VALUES';
export const DICTIONARY_SECTIONS = 'DICTIONARY_SECTIONS';
export const DICTIONARY_MODEL_TYPES = 'DICTIONARY_MODEL_TYPES';
export const DICTIONARY_SUBSCRIBER_SOURCE = 'DICTIONARY_SUBSCRIBER_SOURCE';
export const DICTIONARY_VOIVODESHIP_CODES = 'DICTIONARY_VOIVODESHIP_CODES';
export const DICTIONARY_SYSTEMS = 'DICTIONARY_SYSTEMS';
export const DICTIONARY_SUBSYSTEMS = 'DICTIONARY_SUBSYSTEMS';
export const DICTIONARY_CHANNELS = 'DICTIONARY_CHANNELS';
export const DICTIONARY_PROVIDERS = 'DICTIONARY_PROVIDERS';
export const DICTIONARY_CURRENT_SYSTEM_WITH_CHILDREN =
	'DICTIONARY_CURRENT_SYSTEM_WITH_CHILDREN';
export const DICTIONARY_PROVISIONING = 'DICTIONARY_PROVISIONING';
export const DICTIONARY_CATCHUP_RECORDING_STATES =
	'DICTIONARY_CATCHUP_RECORDING_STATES';
export const DICTIONARY_DRM_TEMPLATES = 'DICTIONARY_DRM_TEMPLATES';
