import services from 'services/services';
import { useQuery } from 'react-query';

// Import helpers
import { convertArrayToValuePairsByKeyNames } from 'components/helpers/convert';

// Import variables
import { INCREMENT } from '../helpers';

// Import api
import {
	GET_PROMOTIONS_COUPONS_API,
	QUERY_PROMOTIONS_COUPONS_DATA
} from 'components/helpers/api';

const useSubscribersCouponsForm = ({ userId, subscriberCouponChange }) => {
	const fetchCouponPromotions = () => {
		return services.get(GET_PROMOTIONS_COUPONS_API);
	};
	const { data } = useQuery({
		queryKey: QUERY_PROMOTIONS_COUPONS_DATA,
		queryFn: fetchCouponPromotions
	});

	const couponPromotionsData = data?.data?.data;

	const couponPromotionsList = couponPromotionsData
		? convertArrayToValuePairsByKeyNames(couponPromotionsData, 'name', 'id')
		: [];

	const handleOnSubmit = ({ promotionId }) => {
		const resources = {
			userId,
			promotionId,
			modificationType: INCREMENT,
			toggleModal: true
		};
		subscriberCouponChange(resources);
	};

	const formInitialValues = {
		promotionId: couponPromotionsList[0]?.value ?? null
	};

	return {
		submit: handleOnSubmit,
		formInitialValues,
		couponPromotionsList
	};
};

export default useSubscribersCouponsForm;
