import React from 'react';
import i18n from 'i18next';

// Import components
import CategoriesForm from '../../categories_form/CategoriesForm';
import CategoriesImages from 'components/views/categories/categories_file/CategoriesImages';
import CategoriesChildrenTable from 'components/views/categories/categories_children_table/CategoriesChildrenTable';
import SystemSettingsForm from 'components/utilities/system_settings/SystemSettingsForm';

export const categoriesTabs = (isFormDataAvailable) => [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: (
			<>
				<CategoriesForm />
				{isFormDataAvailable && <CategoriesChildrenTable />}
			</>
		)
	},
	{
		name: i18n.t('common:tabs.system_settings'),
		path: 'system_settings',
		disableOnCreate: false,
		component: <SystemSettingsForm />
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <CategoriesImages />
	}
];
