import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setSectionsGroupsType } from 'store/actions';
import { Radio } from 'antd';

//  Import helpers
import { PLAYER_TYPES } from 'components/helpers/section_groups_helpers/helpers';

// Import translation
import { useTranslation } from 'react-i18next';

const SectionsGroupsFilters = ({ setSectionsGroupsType }) => {
	const { t } = useTranslation();

	const [playerType, setPlayerType] = useState(PLAYER_TYPES.all);

	const handlePlayerChange = ({ target: { value } }) => {
		// set value in state for Player Radio Group
		setPlayerType(value);
		// dispatch filter types action
		setSectionsGroupsType({ playerType: value });
	};

	return (
		<>
			<div className="m-r-md">
				<p>{t('sections_groups:radio_buttons.select_type')}</p>
				<Radio.Group
					onChange={handlePlayerChange}
					value={playerType}
					className={'m-b-md'}
				>
					<Radio value={PLAYER_TYPES.all}>
						{t('sections_groups:radio_buttons.all')}
					</Radio>
					<Radio value={PLAYER_TYPES.atv}>
						{t('sections_groups:radio_buttons.atv')}
					</Radio>
					<Radio value={PLAYER_TYPES.box}>
						{t('sections_groups:radio_buttons.box')}
					</Radio>
					<Radio value={PLAYER_TYPES.mobile}>
						{t('sections_groups:radio_buttons.mobile')}
					</Radio>
					<Radio value={PLAYER_TYPES.web}>
						{t('sections_groups:radio_buttons.web')}
					</Radio>
				</Radio.Group>
			</div>
		</>
	);
};

export default connect(null, { setSectionsGroupsType })(SectionsGroupsFilters);
