import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//helpers
import { useDocumentUploads } from './helpers/useDocumentUploads';

// Import components
import DocumentUploadsTable from './documents_upload_table/DocumentsUploadsTable';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const DocumentUploads = ({ isEditPage, values }) => {
	const { t } = useTranslation();
	const {
		file,
		isLoading,
		uploadDocument,
		onSortEnd,
		updateTableOrder,
		deleteAssetAndRefetch,
		dataSource,
		uploadProps
	} = useDocumentUploads({
		isEditPage
	});

	if (!isEditPage) return null;
	return (
		<>
			<Upload {...uploadProps}>
				<Button disabled={isLoading} type="button" icon={<UploadOutlined />}>
					{t('common:form.file')}
				</Button>
			</Upload>
			<Button
				className="m-t-sm"
				disabled={!file.length}
				type="button"
				loading={isLoading}
				onClick={uploadDocument}
			>
				{t('common:form.submit_file')}
			</Button>
			<DocumentUploadsTable
				content={values?.content}
				dataSource={dataSource}
				onSortEnd={onSortEnd}
				updateTableOrder={updateTableOrder}
				deleteAsset={deleteAssetAndRefetch}
				isLoading={isLoading}
				disabled={!dataSource.length}
			/>
		</>
	);
};

DocumentUploads.propTypes = {
	isEditPage: PropTypes.bool.isRequired,
	values: PropTypes.object.isRequired
};

export default DocumentUploads;
