// Import translation
import i18n from 'i18next';

export const BASE_FIELDS = [
	{
		name: 'name',
		label: i18n.t('payment_templates:fields.name'),
		type: 'text',
		required: true,
		fieldInfo: i18n.t('payment_templates:info.unique_name')
	}
];
