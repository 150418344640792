import i18n from 'i18next';

export const defaultFieldValues = {};

export const BASE_FIELDS = (isEdit) => [
	{
		name: 'force',
		label: i18n.t('software:form:fields.force'),
		type: 'checkbox'
	},
	{
		name: 'start_date',
		label: i18n.t('software:form:fields.start_date'),
		type: 'date',
		showTime: true,
		required: true
	},
	{
		name: 'version_name',
		label: i18n.t('software:form:fields.version_name'),
		type: 'text',
		required: true
	},
	{
		name: 'version_code',
		label: i18n.t('software:form:fields.version_code'),
		type: 'number',
		required: true
	},
	{
		name: 'sn_from',
		label: i18n.t('software:form:fields.sn_from'),
		type: 'number',
		required: false
	},
	{
		name: 'sn_to',
		label: i18n.t('software:form:fields.sn_to'),
		type: 'number',
		required: false
	},
	{
		name: 'sn_list',
		label: i18n.t('software:form:fields.sn_list'),
		type: 'textarea',
		required: false
	},
	{
		name: 'package_id',
		label: i18n.t('software:form:fields.package_id'),
		type: 'text',
		required: true
	},
	{
		name: 'file',
		label: i18n.t('software:form:fields.file'),
		type: 'file',
		required: isEdit
	},
	{
		name: 'platform',
		label: i18n.t('software:form:fields.platform'),
		type: 'multi-select',
		required: true,
		mode: '',
		showSearch: true
	}
];
