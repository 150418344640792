import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	list: {
		data: {},
		isLoaded: false
	},
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** FETCH SETTINGS ******************
			case types.FETCH_SETTINGS_LOADING:
				return { ...INITIAL_STATE };

			case types.FETCH_SETTINGS_SUCCESS:
				// Convert data array to object
				draft.list.data = action.payload.reduce((prev, next) => {
					// Replace dots to |
					prev[`${next.key.replace(/\./g, '|')}`] = next.value;
					return prev;
				}, {});
				draft.list.isLoaded = true;
				return;

			case types.FETCH_SETTINGS_ERROR:
				draft.list.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
