import React from 'react';
import {
	string,
	number,
	func,
	oneOfType,
	node,
	arrayOf,
	shape,
	object,
	bool,
	array
} from 'prop-types';
import { Input } from 'antd';
import { Col, FormGroup } from 'reactstrap';

// Import components
import { FieldInfo, FieldError, Label } from 'components/common/inputs';
import ImagePreview from './ImagePreview';
import ImageMetadata from './ImageMetadata';

// Import hooks
import useImageUrl from './useImageUrl';

const ImageUrl = ({
	input,
	id,
	label,
	labelColumn,
	inputColumn,
	children,
	fieldInfo,
	required,
	meta,
	requirements,
	...inputProps
}) => {
	const hasError = meta.error || meta.submitError;

	const {
		url,
		isLoading,
		onChange,
		isValid,
		metadata,
		rules,
		error
	} = useImageUrl({
		value: input.value,
		input,
		requirements
	});

	return (
		<FormGroup row>
			<Label
				id={id}
				columnWidth={labelColumn}
				required={required}
				content={label}
			/>
			<Col sm={inputColumn}>
				<Input
					status={hasError && 'error'}
					id={id}
					{...input}
					{...inputProps}
					onChange={onChange}
					addonBefore={
						<ImagePreview
							isValid={isValid}
							error={error}
							url={url}
							isLoading={isLoading}
						/>
					}
				/>
				<FieldError
					error={meta.touched && hasError}
					inputName={input.name}
					content={hasError}
				/>
				<FieldInfo info={fieldInfo} />
				<ImageMetadata metadata={metadata} requirements={rules} />
			</Col>
			{children}
		</FormGroup>
	);
};

ImageUrl.propTypes = {
	input: shape({
		onChange: func.isRequired
	}),
	id: string.isRequired,
	label: string,
	labelColumn: number,
	inputColumn: number,
	required: bool,
	fieldInfo: string,
	children: oneOfType([node, arrayOf(node)]),
	meta: object,
	requirements: array
};

export default ImageUrl;
