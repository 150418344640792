import React from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Spinner = () => (
	<Spin
		style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		}}
		indicator={
			<SyncOutlined
				style={{
					fontSize: '1.5rem',
					color: 'white',
					marginRight: '1rem'
				}}
				spin
			/>
		}
	/>
);
export default Spinner;
