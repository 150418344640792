import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Import components
import { Pagination, Select } from 'antd';

// Import utitities
import CSVExport from 'components/utilities/csv/CSVExport';

// Import helpers
import { isCSVExportVisible } from './helpers/helpers';
import { getSubLocationType } from 'components/helpers/convert';

// Import variables
import { TABLE_LENGTH, maxPerPageOptions } from 'components/helpers/table';

// Import translation
import { useTranslation } from 'react-i18next';

/**
 * Functional component that returns custom pagination showing total results counter
 * @param {object} tableProps - props from React Table. Required for proper communication between pagination and React Table
 * @param {number} totalResults - total results number from api
 * @returns {*}
 */

const { Option } = Select;

const CustomPagination = ({
	tableProps,
	totalResults,
	onChangeTableSize,
	children
}) => {
	const { page, onPageChange, pageSize } = tableProps;

	const { t } = useTranslation();

	const { pathname } = useLocation();

	const [current, setCurrent] = useState(page + 1);

	// creating table type to deterimine if table is valid for showing csv export
	const tableType = getSubLocationType(pathname);

	const handlePageChange = (page) => {
		setCurrent(page);
		onPageChange(page - 1);
	};

	return (
		<div className="pagination-container m-b-sm">
			<div className="d-flex">
				<Pagination
					current={current}
					total={totalResults}
					onChange={handlePageChange}
					pageSize={pageSize}
					showSizeChanger={false}
				/>
				<Select
					defaultValue={TABLE_LENGTH}
					value={pageSize}
					onChange={onChangeTableSize}
					style={{ width: 80 }}
					className="ml-4 mr-3"
				>
					{maxPerPageOptions.map((item) => (
						<Option value={item} key={item}>
							{item}
						</Option>
					))}
				</Select>
				{children}
			</div>
			<div className="pagination-utils-container">
				{isCSVExportVisible(tableType) && <CSVExport />}
				<p className="m-l-md">{`${t(
					'common:pagination.total'
				)} ${totalResults}`}</p>
			</div>
		</div>
	);
};

CustomPagination.propTypes = {
	tableProps: PropTypes.object.isRequired,
	totalResults: PropTypes.number.isRequired,
	onChangeTableSize: PropTypes.func.isRequired,
	children: PropTypes.node
};

export default CustomPagination;
