import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchAgreement, dictionaryAgreementsTypes } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import default values
import { defaultFieldValues } from './fields/input_fields';

const AgreementsForm = ({
	history,
	fetchAgreement,
	error,
	isLoaded,
	initialValues,
	agreementTypes
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dictionaryAgreementsTypes()(dispatch);
	}, [dispatch]);

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) =>
		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: 'agreements',
			redirectPath: 'agreements'
		});

	const formInitialValues = {
		...defaultFieldValues,
		...initialValues
	};

	return (
		// Dispatch fetchOperatorRoles, fetchOperator actions in in agreements_form
		<FormTemplate
			fetchFormResources={[]}
			fetchResourceToEdit={fetchAgreement}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={formInitialValues}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields submitting={submitting} agreementTypes={agreementTypes} />

							<FormButtons
								isButtonDisabled={submitting || error}
								path="agreements"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

AgreementsForm.propTypes = {
	history: PropTypes.object.isRequired,
	fetchAgreement: PropTypes.func.isRequired,
	initialValues: PropTypes.object.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	agreementTypes: PropTypes.array
};

const mapStateToProps = ({
	agreements: { form },
	dictionary: { agreementTypes }
}) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded,
		agreementTypes
	};
};

export default compose(
	connect(mapStateToProps, { fetchAgreement, dictionaryAgreementsTypes }),
	withRouter
)(AgreementsForm);
