import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH COGNITO TREE ********************
export const fetchCognitoTree = () => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_COGNITO_TREE_LOADING,
			payload: true
		});

		const { data } = await services.get('/cognito/categories/treelist');

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_COGNITO_TREE_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_COGNITO_TREE_SUCCESS,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE COGNITO CATEGORY ********************
export const deleteCognitoCategory = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DELETE_COGNITO_CATEGORY_LOADING,
			payload: true
		});

		await services.delete(`/cognito/categories/${id}`);
		// Dispatch an action with data
		dispatch({
			type: types.DELETE_COGNITO_CATEGORY_SUCCESS,
			payload: true
		});

		// Fetching fresh tree data after daleted
		fetchCognitoTree()(dispatch);
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DELETE_COGNITO_CATEGORY_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
