// ************* FILTERS *************
// hardcoded data for filters suggestions
// ------------ INGESTER ------------
export const encodingStatus = [
	'STATUS_WAITING',
	'STATUS_READY',
	'STATUS_QUEUED',
	'STATUS_PROCESSING',
	'STATUS_SUCCESS',
	'STATUS_FINISHED',
	'STATUS_FAILED'
];

export const encryptionType = ['PLAYREADY', 'FAIRPLAY'];

export const ingestionAssetStatus = [
	'STATUS_NEW',
	'STATUS_READY',
	'STATUS_QUEUED',
	'STATUS_PROCESSING',
	'STATUS_DOWNLOADING',
	'STATUS_UPLOADING',
	'STATUS_TRANSFER_FINISHED',
	'STATUS_SUCCESS',
	'STATUS_REPLACED',
	'STATUS_FAILED',
	'STATUS_FAILED_FINAL'
];

export const ingestionTaskStatus = [
	'STATUS_NEW',
	'STATUS_READY_UPDATE',
	'STATUS_PROCESSING',
	'STATUS_SUCCESS',
	'STATUS_OMITTED',
	'STATUS_QUEUED',
	'STATUS_FAILED',
	'STATUS_FAILED_FINAL'
];

export const MCStatus = [
	'waiting',
	'waiting_for_reingestion',
	'waiting_for_deletion',
	'queued',
	'omitted',
	'queued_for_deletion',
	'scheduled',
	'ingesting',
	'ingested',
	'error',
	'error_final',
	'not_found',
	'deleted',
	'licence_ended',
	'replaced'
];

export const assetsType = ['Movie', 'Poster', 'Preview'];
