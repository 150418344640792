import React from 'react';

// Import translation
import i18n from 'i18next';

// Import helpers
import { getValidStr } from 'components/views/subscribers/subscribers_personal_data/helpers';

export const createPersonalDataColumns = (hasPeselNumber) => [
	{
		Header: i18n.t('subscribers:personalData.firstname'),
		accessor: 'firstname'
	},
	{
		Header: i18n.t('subscribers:personalData.lastname'),
		accessor: 'lastname'
	},
	{
		Header: i18n.t('subscribers:personalData.id_document_type'),
		accessor: 'id_document_type',

		Cell: ({
			row: {
				_original: { id_document_type }
			}
		}) => {
			const str = 'subscribers:personalData.id_document_type_';
			return (
				<>
					{i18n.t(
						!hasPeselNumber
							? getValidStr(str, id_document_type)
							: getValidStr(str, 'pesel')
					)}
				</>
			);
		}
	},
	{
		Header: i18n.t('subscribers:personalData.id_document'),
		accessor: 'pesel_number',
		show: hasPeselNumber
	},
	{
		Header: i18n.t('subscribers:personalData.id_document'),
		accessor: 'id_document',
		show: !hasPeselNumber
	}
];

export const addressColumns = [
	{
		Header: i18n.t('subscribers:address.city'),
		accessor: 'city'
	},
	{
		Header: i18n.t('subscribers:address.street'),
		accessor: 'street'
	},
	{
		Header: i18n.t('subscribers:address.building_no'),
		accessor: 'building_no'
	},
	{
		Header: i18n.t('subscribers:address.apartment_no'),
		accessor: 'apartment_no'
	},
	{
		Header: i18n.t('subscribers:address.postal_code'),
		accessor: 'postal_code'
	}
];
