import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	cover: {
		data: [],
		isLoaded: false,
		error: false
	},
	cover_large: {
		data: [],
		isLoaded: false,
		error: false
	},
	cover_small: {
		data: [],
		isLoaded: false,
		error: false
	},
	cover_detail: {
		data: [],
		isLoaded: false,
		error: false
	},
	cover_detail_large: {
		data: [],
		isLoaded: false,
		error: false
	},
	poster: {
		data: [],
		isLoaded: false,
		error: false
	},
	title: {
		data: [],
		isLoaded: false,
		error: false
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** FETCH PACKET COVER FILES ******************
			case types.FETCH_PACKET_COVER_FILES_LOADING:
				draft.cover.data = [];
				draft.cover.isLoaded = false;
				draft.cover.error = false;
				return;

			case types.FETCH_PACKET_COVER_FILES_SUCCESS:
				draft.cover.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.cover.isLoaded = true;
				return;

			case types.FETCH_PACKET_COVER_FILES_ERROR:
				draft.cover.isLoaded = true;
				draft.cover.error = true;
				return;

			// ****************** FETCH PACKET COVER_LARGE FILES ******************
			case types.FETCH_PACKET_COVER_LARGE_FILES_LOADING:
				draft.cover_large.data = [];
				draft.cover_large.isLoaded = false;
				draft.cover_large.error = false;
				return;

			case types.FETCH_PACKET_COVER_LARGE_FILES_SUCCESS:
				draft.cover_large.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.cover_large.isLoaded = true;
				return;

			case types.FETCH_PACKET_COVER_LARGE_FILES_ERROR:
				draft.cover_large.isLoaded = true;
				draft.cover_large.error = true;
				return;

			// ****************** FETCH PACKET COVER_SMALL FILES ******************
			case types.FETCH_PACKET_COVER_SMALL_FILES_LOADING:
				draft.cover_small.data = [];
				draft.cover_small.isLoaded = false;
				draft.cover_small.error = false;
				return;

			case types.FETCH_PACKET_COVER_SMALL_FILES_SUCCESS:
				draft.cover_small.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.cover_small.isLoaded = true;
				return;

			case types.FETCH_PACKET_COVER_SMALL_FILES_ERROR:
				draft.cover_small.isLoaded = true;
				draft.cover_small.error = true;
				return;

			// ****************** FETCH PACKET COVER_DETAIL FILES ******************
			case types.FETCH_PACKET_COVER_DETAIL_FILES_LOADING:
				draft.cover_detail.data = [];
				draft.cover_detail.isLoaded = false;
				draft.cover_detail.error = false;
				return;

			case types.FETCH_PACKET_COVER_DETAIL_FILES_SUCCESS:
				draft.cover_detail.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.cover_detail.isLoaded = true;
				return;

			case types.FETCH_PACKET_COVER_DETAIL_FILES_ERROR:
				draft.cover_detail.isLoaded = true;
				draft.cover_detail.error = true;
				return;

			// ****************** FETCH PACKET COVER_DETAIL_LARGE FILES ******************
			case types.FETCH_PACKET_COVER_DETAIL_LARGE_FILES_LOADING:
				draft.cover_detail_large.data = [];
				draft.cover_detail_large.isLoaded = false;
				draft.cover_detail_large.error = false;
				return;

			case types.FETCH_PACKET_COVER_DETAIL_LARGE_FILES_SUCCESS:
				draft.cover_detail_large.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.cover_detail_large.isLoaded = true;
				return;

			case types.FETCH_PACKET_COVER_DETAIL_LARGE_FILES_ERROR:
				draft.cover_detail_large.isLoaded = true;
				draft.cover_detail_large.error = true;
				return;

			// ****************** FETCH PACKET POSTER FILES ******************
			case types.FETCH_PACKET_POSTER_FILES_LOADING:
				draft.poster.data = [];
				draft.poster.isLoaded = false;
				draft.poster.error = false;
				return;

			case types.FETCH_PACKET_POSTER_FILES_SUCCESS:
				draft.poster.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.poster.isLoaded = true;
				return;

			case types.FETCH_PACKET_POSTER_FILES_ERROR:
				draft.poster.isLoaded = true;
				draft.poster.error = true;
				return;

			// ****************** FETCH PACKET TITLE FILES ******************
			case types.FETCH_PACKET_TITLE_FILES_LOADING:
				draft.title.data = [];
				draft.title.isLoaded = false;
				draft.title.error = false;
				return;

			case types.FETCH_PACKET_TITLE_FILES_SUCCESS:
				draft.title.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.title.isLoaded = true;
				return;

			case types.FETCH_PACKET_TITLE_FILES_ERROR:
				draft.title.isLoaded = true;
				draft.title.error = true;
				return;

			default:
				return state;
		}
	});
