import React from 'react';
import PropTypes from 'prop-types';

//Import components
import { Toggle } from 'components/utilities';
import MergeForm from '../categories_merge/MergeForm';

// Import hooks
import useSystem from 'hooks/useSystem';

const CategoriesMergeCell = ({ id, name, isReadOnly }) => {
	const { isCurrentSystemMain } = useSystem();

	return (
		<Toggle>
			{({ on, toggle }) => (
				<>
					{!isReadOnly && (
						<button
							onClick={toggle}
							className="btn btn-xs btn-primary"
							disabled={!isCurrentSystemMain}
						>
							<i className="fa fa-object-ungroup" />
						</button>
					)}

					<MergeForm
						childId={id}
						childName={name}
						visible={on}
						toggle={toggle}
					/>
				</>
			)}
		</Toggle>
	);
};

CategoriesMergeCell.propTypes = {
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	isReadOnly: PropTypes.bool.isRequired
};

export default CategoriesMergeCell;
