import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { fetchVodSectionsAssigned } from 'store/actions';

// Import columns
import { createColumns } from './vod_sections_assigned_columns';

// Import components
import { PanelPageTemplate } from 'components/common/templates';

const VodSectionsAssigned = ({
	error,
	fetchVodSectionsAssigned,
	resources,
	fetchUrlParams = '?',
	match: {
		params: { id }
	}
}) => {
	return (
		<PanelPageTemplate
			itemId={id}
			title="Lista"
			error={error}
			type="sections_assigned"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchVodSectionsAssigned}
			resourcesForFetchAction={fetchUrlParams}
		/>
	);
};

VodSectionsAssigned.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchVodSectionsAssigned: PropTypes.func.isRequired,
	fetchUrlParams: PropTypes.string
};

const mapStateToProps = ({ vod }) => {
	return {
		error: vod.sections_assigned.error,
		resources: vod.sections_assigned
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchVodSectionsAssigned
	}),
	withRouter
)(VodSectionsAssigned);
