import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Button } from 'reactstrap';

// Import components
import MediaTypeSelect from './MediaTypeSelect';
import CatchupForm from './CatchupForm';
import SearchForProducts from './SearchForProducts';

// Import hooks
import useDeeplink from './context/useDeeplink';

const DeeplinkWizzard = () => {
	const { t } = useTranslation();
	const {
		productSearch,
		shouldAllowToGenerate,
		handleUpdateValue,
		isCatchup
	} = useDeeplink();

	return (
		<FormGroup className="deeplink_form">
			<MediaTypeSelect />
			<div className="hr-line-dashed my-4">&nbsp;</div>
			{productSearch && (
				<>
					<SearchForProducts />
					<div className="hr-line-dashed my-4">&nbsp;</div>
				</>
			)}
			{isCatchup && (
				<>
					<CatchupForm />
					<div className="hr-line-dashed my-4">&nbsp;</div>
				</>
			)}
			<Button
				className="m-b-md"
				disabled={!shouldAllowToGenerate}
				onClick={handleUpdateValue}
			>
				{t('product_select:buttons.generate')}
			</Button>
		</FormGroup>
	);
};

export default DeeplinkWizzard;
