import i18n from 'i18next';

export const BASE_FIELDS = ({ isEdit }) => [
	{
		name: 'active',
		label: i18n.t('devices_sn:form.fields.active'),
		type: 'checkbox',
		fieldInfo: ''
	},
	{
		name: 'login',
		label: i18n.t('devices_sn:form.fields.login'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit,
		fieldInfo: ''
	},
	{
		name: 'serial_number',
		label: i18n.t('devices_sn:form.fields.serial_number'),
		type: 'text',
		fieldInfo: ''
	},
	{
		name: 'system',
		label: i18n.t('devices_sn:form.fields.system'),
		type: 'select',
		fieldInfo: ''
	},
	{
		name: 'packet_uuid',
		label: i18n.t('devices_sn:form.fields.packet_uuid'),
		type: 'text',
		fieldInfo: '',
		isVisible: !isEdit,
		disabled: true,
		placeholder: i18n.t('devices_sn:form.fields.packet_uuid')
	},
	{
		name: 'pvr_enabled',
		label: i18n.t('devices_sn:form.fields.full_pvr'),
		type: 'checkbox',
		disabled: true
	},
	{
		name: 'usb_pvr_enabled',
		label: i18n.t('devices_sn:form.fields.usb_pvr'),
		type: 'checkbox',
		disabled: true
	}
];
