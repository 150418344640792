import React from 'react';

// Import components
import SystemSettingsForm from 'components/utilities/system_settings/SystemSettingsForm';

const PriceSettings = () => {
	return (
		<>
			<SystemSettingsForm />
		</>
	);
};

export default PriceSettings;
