import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { fetchActivity } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const ActivityForm = ({
	history,
	fetchActivity,
	error,
	isLoaded,
	initialValues
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) =>
		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: 'activity',
			redirectPath: 'activity'
		});

	const formInitialValues = {
		...defaultFieldValues,
		...initialValues
	};

	return (
		<FormTemplate
			fetchResourceToEdit={fetchActivity}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={formInitialValues}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting, values }) => (
						<form onSubmit={handleSubmit}>
							<Fields values={values} submitting={submitting} />

							<FormButtons
								isButtonDisabled={true}
								path="activity"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

ActivityForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchActivity: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ activity: { form } }) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded
	};
};

export default connect(mapStateToProps, { fetchActivity })(ActivityForm);
