import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setSectionsQuery,
	fetchSections,
	deleteSection,
	dictionarySectionTypes,
	createFiltersSuggestions
} from 'store/actions';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

// Import columns
import { createColumns } from './sections_columns';

// Import utilities
import { Search } from 'components/utilities/search';

// Import translation
import { useTranslation } from 'react-i18next';

const Sections = ({
	error,
	resources,
	setSectionsQuery,
	fetchSections,
	deleteSection,
	dictionarySectionTypes,
	createFiltersSuggestions,
	filters
}) => {
	const { t } = useTranslation();

	// Actions to fetch data for filters
	const filterActions = {
		dictionarySectionTypes
	};

	// execute filters logic
	useFilterLogic({
		filterActions,
		createFiltersSuggestions,
		filters
	});

	const { columns } = resources;

	return (
		<PanelPageTemplate
			title={t('common:list')}
			error={error}
			type="sections"
			addLinkText={t('sections:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchSections}
			deleteItemAction={deleteSection}
			notificationDeleteSuccessTxt={t('sections:notification.delete_success')}
			notificationDeleteErrorTxt={t('sections:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setSectionsQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Sections.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	setSectionsQuery: PropTypes.func.isRequired,
	fetchSections: PropTypes.func.isRequired,
	deleteSection: PropTypes.func.isRequired,
	dictionarySectionTypes: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	filters: PropTypes.object
};

const mapStateToProps = ({ sections, dictionary: { sectionTypes } }) => ({
	error: sections.table.error,
	resources: sections.table,
	filters: {
		type: sectionTypes
	}
});

export default connect(mapStateToProps, {
	setSectionsQuery,
	fetchSections,
	deleteSection,
	dictionarySectionTypes,
	createFiltersSuggestions
})(Sections);
