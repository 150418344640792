// Import translation
import i18n from 'i18next';

// Import variables
import { PAYMENT_TYPES } from 'components/helpers/variables';

export const select_payment = Object.values(PAYMENT_TYPES);

export const select_period = [
	{
		name: 'Na zawsze',
		value: '0'
	},
	{
		name: '24h',
		value: '24'
	},
	{
		name: '48h',
		value: '48'
	},
	{
		name: '72h',
		value: '72'
	},
	{
		name: i18n.t('prices:recurring_payment'),
		value: '-1'
	}
];
