import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH CATEGORIES - TABLE DATA ********************
/* eslint-disable */
export const fetchCategories = (
	options,
	id = null, //sometimes id is needed as second param in PanelPageTemplate
	tableLenght = TABLE_LENGTH
) => async (dispatch, getState) => {
	/* eslint-enable */
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_CATEGORIES_LOADING,
			payload: true
		});

		const {
			categories: {
				table: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const {
			filtered: { type }
		} = options;

		const url = `/products/genres/list?type=${type}&${filterUrlQuery}${queryParams(
			options,
			tableLenght,
			columns,
			startIndex,
			true
		)}`;

		const response = await services.get(url);

		// Calculate pages
		const pages = Math.ceil(response.data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_CATEGORIES_SUCCESS,
			payload: { ...response.data, pages, page: options.page, startIndex }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_CATEGORIES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE CATEGORY ********************
export const deleteCategory = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DELETE_CATEGORY_LOADING,
			payload: true
		});

		await services.delete(`/products/genres/${id}`);
		// Dispatch an action with data
		dispatch({
			type: types.DELETE_CATEGORY_SUCCESS,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DELETE_CATEGORY_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET SEARCH QUERY ********************
export const setCategoriesQuery = (query) => ({
	type: types.SET_CATEGORIES_SEARCH_QUERY,
	payload: query
});

// ******************** SET CATEGORIES TYPE ********************
export const setCategoriesType = (type) => ({
	type: types.SET_CATEGORIES_TYPE,
	payload: type
});
