import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchCoupon } from 'store/actions';
import moment from 'moment';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import {
	convertFormSubmitValues,
	convertFormInitialValues
} from 'components/helpers/promotion_helpers/helpers';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

const CouponsForm = ({
	history,
	fetchCoupon,
	error,
	isLoaded,
	initialValues
}) => {
	const { t } = useTranslation();

	//  -------------- INITIAL VALUES ---------------
	// evaluate initial values
	let promotionPeriodInitialValues = defaultFieldValues.promotion_period;

	if (initialValues.since && initialValues.till) {
		promotionPeriodInitialValues = [
			moment(initialValues.since),
			moment(initialValues.till)
		];
	}

	const formInitialValues = {
		...defaultFieldValues,
		...initialValues,
		promotion_value: convertFormInitialValues(
			initialValues.promotion_type,
			initialValues.promotion_value
		),
		promotion_period: promotionPeriodInitialValues
	};

	//  -------------- SUBMIT --------------
	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const {
			promotion_type,
			promotion_value,
			promotion_period,
			description
		} = values;

		// Promotion period evaluation
		const sinceDateEvaluation = moment(promotion_period[0]).format(
			'YYYY-MM-DD HH:mm:ss'
		);
		const tillDateEvaluation = moment(promotion_period[1]).format(
			'YYYY-MM-DD HH:mm:ss'
		);

		delete values.promotion_period;

		// apply proper date format to since and till, convert promotion value to proper unit
		// apply converted data
		const resources = {
			...values,
			since: sinceDateEvaluation,
			till: tillDateEvaluation,
			promotion_value: convertFormSubmitValues(promotion_type, promotion_value),
			description: description || null
		};

		// reload form after editing promotion (check if item id is accesible, before reloading)
		// do not reload when creating promotion
		const reloadForm = () => {
			if (itemID) fetchCoupon(itemID);
			else return null;
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'promotions/coupons',
			redirectPath: 'coupons',
			callback: reloadForm
		});
	};

	return (
		<FormTemplate
			fetchResourceToEdit={fetchCoupon}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={formInitialValues}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					validate={validate}
					render={({ handleSubmit, submitting, values }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								isEdit={isEdit}
								currentValues={values}
							/>
							<FormButtons
								isButtonDisabled={submitting || error}
								path="coupons"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

CouponsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchCoupon: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ coupons: { form } }) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, { fetchCoupon }),
	withRouter
)(CouponsForm);
