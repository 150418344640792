import React from 'react';
import { object, bool, string, func, array } from 'prop-types';
import { Form } from 'react-final-form';
// Import translation
import { useTranslation } from 'react-i18next';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

// Validate
import validate from './validation';

// Import components
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const MetadataForm = ({
	initialValues,
	isEdit,
	itemID,
	handleOnSubmit,
	path,
	genres,
	voivodeshipCodes
}) => {
	const { t } = useTranslation();
	return (
		<Form
			initialValues={{ ...defaultFieldValues, ...initialValues }}
			onSubmit={handleOnSubmit({ isEdit, itemID })}
			validate={validate}
			render={({ handleSubmit, submitting, values }) => (
				<form onSubmit={handleSubmit}>
					<Fields
						genres={genres}
						submitting={submitting}
						subdivisions={voivodeshipCodes}
						isNpvrActive={values?.npvr_active}
					/>

					<FormButtons
						isButtonDisabled={submitting}
						path={path}
						buttonText={t('common:buttons.submit')}
					/>
				</form>
			)}
		/>
	);
};

MetadataForm.propTypes = {
	initialValues: object.isRequired,
	isEdit: bool.isRequired,
	itemID: string,
	handleOnSubmit: func.isRequired,
	path: string.isRequired,
	genres: array.isRequired,
	voivodeshipCodes: array.isRequired
};

export default MetadataForm;
