import i18n from 'i18n';
import moment from 'moment';

// Import helpers
import { MESSAGE_TYPES } from 'components/helpers';
import { checkIsInt } from 'components/helpers/convert';

const { INTERNAL, EXTERNAL, WEBVIEW } = MESSAGE_TYPES;

export default ({
	campaign_range,
	notification_title,
	message_text,
	message_type,
	notification_end,
	notification_autoclose,
	message_deeplink,
	message_scale
}) => {
	const errors = {};

	const isInternal = message_type === INTERNAL;
	const isExternal = message_type === EXTERNAL;
	const isWebview = message_type === WEBVIEW;

	// Campaign range
	if (!campaign_range) {
		errors.campaign_range = i18n.t(
			'marketing_campaigns:form:errors.campaign_range'
		);
	} else {
		const start = moment(campaign_range[0]);
		const end = moment(campaign_range[1]);
		if (!start.isBefore(end)) {
			errors.campaign_range = i18n.t(
				'marketing_campaigns:form:errors.campaign_range_before'
			);
		}
	}

	// Campaign title
	if (!notification_title) {
		errors.notification_title = i18n.t(
			'marketing_campaigns:form:errors.notification_title'
		);
	} else if (notification_title.length > 256) {
		errors.notification_title = i18n.t(
			'marketing_campaigns:form:errors.notification_title_length'
		);
	}

	// Campaign text
	if (!message_text) {
		errors.message_text = i18n.t(
			'marketing_campaigns:form:errors.notification_text'
		);
	}

	// Notifications
	if (!notification_end) {
		errors.notification_end = i18n.t(
			'marketing_campaigns:form:errors.notification_till'
		);
	}

	// Notification autoclose
	if (notification_autoclose) {
		if (!checkIsInt(notification_autoclose)) {
			errors.notification_till = i18n.t(
				'marketing_campaigns:form:errors.notification_autoclose_int'
			);
		} else if (notification_autoclose < 0) {
			errors.notification_till = i18n.t(
				'marketing_campaigns:form:errors.notification_autoclose_positive'
			);
		}
	}

	// Webview fields validation
	if (isWebview) {
		if (!message_scale) {
			errors.message_scale = i18n.t(
				'marketing_campaigns:form:errors.message_scale'
			);
		} else if (message_scale < 0 || message_scale > 100) {
			errors.message_scale = i18n.t(
				'marketing_campaigns:form:errors.message_scale_range'
			);
		}
	}

	// Internal fields validation`
	if (isInternal || isExternal || isWebview) {
		if (!message_deeplink) {
			errors.message_deeplink = i18n.t(
				'marketing_campaigns:form:errors.message_deeplink'
			);
		}
	}

	return errors;
};
