import i18n from 'i18n';

export const BASE_FIELDS = [
	{
		name: 'deeplink',
		label: i18n.t('marketing_campaigns:form:fields.message_deeplink'),
		type: 'text',
		required: true
	}
];
