import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH DEVICES - TABLE DATA ********************
export const fetchDevices = (options) => async (dispatch, getState) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_DEVICES_LOADING,
			payload: true
		});

		const {
			devices: {
				table: {
					columns,
					options: {
						filters: { platform },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const platformQuery = platform !== 'all' && `&platform=${platform}`;

		let url = `/device/list?${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex,
			true
		)}`;

		if (platformQuery) {
			url += platformQuery;
		}

		const { data } = await services.get(url);
		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_DEVICES_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_DEVICES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET SEARCH QUERY ********************
export const setDevicesQuery = (query) => ({
	type: types.SET_DEVICES_SEARCH_QUERY,
	payload: query
});

// ******************** SET DEVICES PLATFORM ********************
export const setDevicesPlatform = (platform) => ({
	type: types.SET_DEVICES_PLATFORM,
	payload: platform
});
