// check if the pin.length === 4 and contains only numbers
export const checkPinFormat = (pin) => {
	const isValid = pin.toString().length === 4 && pin.match(/^\d+$/);
	return isValid;
};

export const isUrlValid = (url) => {
	try {
		return Boolean(new URL(url));
	} catch (e) {
		return false;
	}
};

export const isIPValid = (ip) => {
	const regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
	return regex.test(ip);
};

// Regex validation
export const REGEX_VALIDATION_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
