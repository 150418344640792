import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import services from 'services/services';
import { generatePath } from 'react-router-dom';

// Import helpers
import { MARKETING_CAMPAIGN_TARGETS_MASS_DELETE } from 'components/helpers';

const MassDelete = ({
	selection,
	totalResults,
	refetch,
	campaignId,
	setSelection
}) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const deleteAllSelected = async () => {
		setIsLoading(true);
		const targetsList = selection.map(({ id }) => id);

		try {
			await services.delete(
				generatePath(MARKETING_CAMPAIGN_TARGETS_MASS_DELETE, {
					id: campaignId
				}),
				{ data: { target_ids: targetsList } }
			);

			refetch();
			setSelection([]);

			notification.success({
				message: t(
					'marketing_campaigns:targets.notifications.success.delete_title',
					{ targets: targetsList.length }
				),
				description: t(
					'marketing_campaigns:targets.notifications.success.delete_description'
				)
			});
		} catch {
			notification.error({
				message: t(
					'marketing_campaigns:targets.notifications.error.delete_title'
				),
				description: t(
					'marketing_campaigns:targets.notifications.error.delete_description'
				)
			});
		} finally {
			setIsLoading(false);
		}
	};

	if (!selection.length) return null;

	return (
		<Button
			loading={isLoading}
			onClick={deleteAllSelected}
			type="text"
			danger
			icon={<DeleteOutlined style={{ fontSize: '1.25em' }} />}
			style={{ display: 'flex', alignItems: 'center' }}
		>
			{t('marketing_campaigns:targets.buttons.mass_delete', {
				all: totalResults,
				selected: selection?.length
			})}
		</Button>
	);
};

MassDelete.propTypes = {
	selection: PropTypes.array,
	totalResults: PropTypes.number,
	refetch: PropTypes.func,
	campaignId: PropTypes.number,
	setSelection: PropTypes.func
};

export default MassDelete;
