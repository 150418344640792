import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	loading: true,
	error: false,
	deleteItem: {
		success: false,
		error: false
	},
	data: []
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR COGNITO TREE STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// *************** FETCH COGNITO TREE ***************
			case types.FETCH_COGNITO_TREE_LOADING:
				draft.loading = true;
				draft.error = false;
				return;

			case types.FETCH_COGNITO_TREE_SUCCESS:
				draft.loading = false;
				draft.data = action.payload;
				return;

			case types.FETCH_COGNITO_TREE_ERROR:
				draft.error = action.payload;
				return;

			// *************** DELETE COGNITO CATEGORY ***************
			case types.DELETE_COGNITO_CATEGORY_LOADING:
				draft.deleteItem.success = false;
				draft.deleteItem.error = false;
				return;

			case types.DELETE_COGNITO_CATEGORY_SUCCESS:
				draft.deleteItem.success = action.payload;
				draft.deleteItem.error = false;
				return;

			case types.DELETE_COGNITO_CATEGORY_ERROR:
				draft.deleteItem.error = action.payload;
				return;

			default:
				return state;
		}
	});
