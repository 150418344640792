import { combineReducers } from 'redux';
// Import reducers
import table from './main_panel_reducer';
import form from './main_form_reducer';
import files from './main_files_reducer';
import products from './main_products_reducer';
import availability from './main_availability/main_availability_reducer';
import prices from './main_prices_reducer';

export default combineReducers({
	table,
	form,
	files,
	products,
	availability,
	prices
});
