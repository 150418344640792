import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setChannelsQuery, fetchChannels, deleteChannel } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './channels_columns';

// Import utilities
import { Search } from 'components/utilities/search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

// Import hooks
import useSystem from 'hooks/useSystem';

const Channels = ({
	error,
	setChannelsQuery,
	fetchChannels,
	deleteChannel,
	resources
}) => {
	const { t } = useTranslation();

	const { isCurrentSystemMain } = useSystem();

	const { columns } = resources;

	return (
		// Dispatch fetchChannels, deleteChannel action in subscribers_panel
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="channels"
			addLinkText={isCurrentSystemMain && t('channels:buttons.add_channel')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchChannels}
			deleteItemAction={deleteChannel}
			notificationDeleteSuccessTxt={t('channels:notification.delete_success')}
			notificationDeleteErrorTxt={t('channels:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setChannelsQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Channels.propTypes = {
	error: PropTypes.bool.isRequired,
	setChannelsQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchChannels: PropTypes.func.isRequired,
	deleteChannel: PropTypes.func.isRequired
};

const mapStateToProps = ({ channels }) => {
	return {
		error: channels.table.error,
		resources: channels.table
	};
};

export default connect(mapStateToProps, {
	setChannelsQuery,
	fetchChannels,
	deleteChannel
})(Channels);
