import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { fetchDocument } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import UploadedFilesView from '../documents_uploads/DocumentUploads';
import Fields from './fields/Fields';

// Import variables
import { defaultValues } from './fields/input_fields';

const DocumentsForm = ({ fetchDocument, error, isLoaded, initialValues }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { pathname } = useLocation();
	//check if its edit or create page
	const isEditPage = pathname.includes('/edit');

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const resources = {
			...values,
			redirection_url: values.redirection_url ?? ''
		};

		//Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'documents',
			withMetadata: false,
			scrollTop: false
		});
	};

	return (
		<>
			{/* Dispatch fetchDocument actions in in documents_form */}
			<FormTemplate
				fetchResourceToEdit={fetchDocument}
				error={error}
				isLoaded={isLoaded}
			>
				{({ isEdit, itemID }) => (
					<React.Fragment>
						<Form
							initialValues={{ ...defaultValues, ...initialValues }}
							onSubmit={handleOnSubmit({ isEdit, itemID })}
							validate={validate}
							render={({ handleSubmit, submitting, values }) => (
								<form onSubmit={handleSubmit}>
									<Fields
										values={values}
										submitting={submitting}
										metaData={{ isEditPage }}
									/>
									<UploadedFilesView isEditPage={isEditPage} values={values} />
									<FormButtons
										isButtonDisabled={submitting || error}
										path="documents"
										buttonText={t('common:buttons.submit')}
									/>
								</form>
							)}
						/>
					</React.Fragment>
				)}
			</FormTemplate>
		</>
	);
};

DocumentsForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	fetchDocument: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ documents: { form } }) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded
	};
};

export default connect(mapStateToProps, { fetchDocument })(DocumentsForm);
