import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { RobotOutlined, EditOutlined } from '@ant-design/icons';

// Import components
import DeeplinkManual from './DeeplinkManual/DeeplinkManual';
import DeeplinkWizzard from './DeeplinkWizzard/DeeplinkWizzard';
import DeeplinkContextProvider from './DeeplinkWizzard/context/DeeplinkContext';

const DeeplinkTabs = (props) => {
	const { t } = useTranslation();

	const items = [
		{
			label: (
				<label className="deeplink_tab_label">
					<RobotOutlined />
					<span>{t('product_select:tabs.wizzard')}</span>
				</label>
			),
			key: 1,
			children: (
				<DeeplinkContextProvider {...props}>
					<DeeplinkWizzard />
				</DeeplinkContextProvider>
			)
		},
		{
			label: (
				<label className="deeplink_tab_label">
					<EditOutlined />
					<span>{t('product_select:tabs.manual')}</span>
				</label>
			),
			key: 2,
			children: <DeeplinkManual {...props} />
		}
	];

	return (
		<Tabs
			defaultActiveKey="1"
			type="card"
			size="large"
			tabBarGutter={10}
			items={items}
		/>
	);
};

DeeplinkTabs.propTypes = {
	children: PropTypes.node,
	input: PropTypes.object,
	close: PropTypes.func
};

export default DeeplinkTabs;
