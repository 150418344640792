import React, { useEffect } from 'react';
import { string, array } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscriberDevicesLimits } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';
// Import components
import { Spin } from 'antd';
/**
 * Functional component that returns info about user devices limits
 * @param {string} subscriberId - id of subscriber
 * @param {array} tableResources - data of subscriber devices table. If it changes we download info about limits from API.
 */
function SubscribersDevicesInfo({ subscriberId, tableResources }) {
	const dispatch = useDispatch();
	useEffect(() => {
		fetchSubscriberDevicesLimits(subscriberId)(dispatch);
		// eslint-disable-next-line
	}, [JSON.stringify(tableResources)]);

	const limitInfo = useSelector((state) => state.subscribers.devices.limitInfo);
	const { t } = useTranslation();

	const {
		data: {
			changes_current: changesThisMonth,
			changes_limit: changesLimit,
			active_current: currentActiveDevices,
			active_limit: activeDevicesLimit
		},
		loading,
		error
	} = limitInfo;

	// calculate remaining changes this month and remaining available slots for devices
	// client request this data to be displayed
	const remainingChanges = changesLimit - changesThisMonth;
	const remainingDevices = activeDevicesLimit - currentActiveDevices;

	const renderInfo = () => {
		if (loading) {
			return (
				<>
					<Spin size="small" />
					<span className="m-l-sm">
						{t('subscribers:devices:limits_info.loading')}
					</span>
				</>
			);
		} else if (error) {
			return (
				<p className="csv__error">
					{t('subscribers:devices:limits_info.error')}
				</p>
			);
		} else {
			return (
				<>
					{/* changes this month */}
					<span className="m-r-md">{`${t(
						'subscribers:devices:limits_info.changes_current'
					)} ${changesThisMonth} ${t(
						'subscribers:devices:limits_info.from'
					)} ${changesLimit}`}</span>
					<span className="m-r-md">{`${t(
						'subscribers:devices:limits_info.changes_remaining'
					)} ${remainingChanges < 0 ? 0 : remainingChanges}`}</span>
					{/* active devices */}
					<span className="m-r-md">{`${t(
						'subscribers:devices:limits_info.active_current'
					)} ${currentActiveDevices} ${t(
						'subscribers:devices:limits_info.from'
					)} ${activeDevicesLimit}`}</span>
					<span className="m-r-md">{`${t(
						'subscribers:devices:limits_info.devices_remaining'
					)} ${remainingDevices < 0 ? 0 : remainingDevices}`}</span>
				</>
			);
		}
	};

	return <div className="m-b-md">{renderInfo()}</div>;
}

SubscribersDevicesInfo.propTypes = {
	subscriberId: string.isRequired,
	tableResources: array
};

export default SubscribersDevicesInfo;
