import React from 'react';
import i18n from 'i18next';
import { Link } from 'react-router-dom';

export const createColumns = [
	{
		title: i18n.t('verification:form.fileName'),
		dataIndex: 'fileName',
		key: 'fileName'
	},
	{
		title: i18n.t('verification:form.extension'),
		dataIndex: 'extension',
		key: 'extension'
	},
	{
		title: i18n.t('verification:form.status'),
		dataIndex: 'status',
		key: 'status',
		render: (status) => (
			<span className={`${status}--text-color`}>{status}</span>
		)
	},
	{
		title: i18n.t('verification:form.verifiedAt'),
		dataIndex: 'verifiedAt',
		key: 'verifiedAt'
	},
	{
		title: i18n.t('verification:form.verifiedBy'),
		dataIndex: 'verifiedBy',
		key: 'verifiedBy',
		render: (id) => (
			<Link to={`/panel/operators/edit/${id}/metadata`}>{id}</Link>
		)
	}
];
