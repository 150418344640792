import React from 'react';
import i18n from 'i18next';

// Import helpers
import { setFormLink } from 'components/helpers/link_selector';

// Import components
import DeleteButton from 'components/utilities/delete_button/DeleteButton';

export const createColumns = (handleSelectItem, isReadOnly, productSystem) => [
	{
		Header: i18n.t('provisioning:products:table_columns.image'),
		accessor: 'images',
		sortable: false,
		width: 100,
		Cell: ({
			row: {
				_original: { uuid, image, subtype }
			}
		}) => (
			<React.Fragment>
				{image ? setFormLink({ uuid, type: subtype, image }) : <span>---</span>}
			</React.Fragment>
		)
	},
	{
		Header: i18n.t('provisioning:products:table_columns.title'),
		accessor: 'title',
		Cell: ({
			row: {
				_original: { uuid, subtype, title }
			}
		}) => setFormLink({ uuid, type: subtype, title })
	},
	{
		Header: i18n.t('provisioning:products:table_columns.type'),
		accessor: 'type',
		sortable: false
	},
	{
		Header: i18n.t('provisioning:products:table_columns.subtype'),
		accessor: 'subtype'
	},
	{
		Header: i18n.t('provisioning:products:table_columns.provider'),
		accessor: 'provider'
	},
	{
		Header: i18n.t('provisioning:products:table_columns.rating'),
		accessor: 'rating'
	},
	{
		Header: i18n.t('provisioning:products:table_columns.status'),
		accessor: 'status',
		sortable: true,
		width: 150,
		Cell: ({
			row: {
				_original: { status }
			}
		}) => status && i18n.t([`vod:select:status.${status}`, status])
	},
	{
		Header: i18n.t('provisioning:products:table_columns.genres'),
		accessor: 'genres',
		sortable: false,
		width: 160,
		Cell: ({
			row: {
				_original: { genres }
			}
		}) =>
			genres ? (
				<span>{genres.map((genre) => genre.name).join(', ')}</span>
			) : (
				<span>---</span>
			)
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { uuid }
			}
		}) => (
			<DeleteButton
				id={uuid}
				onSelectItem={handleSelectItem}
				productSystem={productSystem}
				isReadOnly={isReadOnly}
			/>
		)
	}
];
