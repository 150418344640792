import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

export const fetchVodProductive = (itemId) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_VOD_PRODUCTIVE_LOADING,
			payload: true
		});

		const { data } = await services.get(
			`/stats/cognito/product/${itemId}/status`
		);

		// // Dispatch an action with data
		dispatch({
			type: types.FETCH_VOD_PRODUCTIVE_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_VOD_PRODUCTIVE_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
