import React from 'react';
import i18n from 'i18next';

// Import components
import BarkerChannelsForm from 'components/views/barker_channels/barker_channels_form/BarkerChannelsForm';
import BarkerChannelProducts from 'components/views/barker_channels/barker_channel_products/BarkerChannelProducts';

export const barkerChannelsTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <BarkerChannelsForm />
	},
	{
		name: i18n.t('common:tabs.products'),
		path: 'products',
		disableOnCreate: true,
		component: <BarkerChannelProducts />
	}
];
