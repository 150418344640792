export const validateRequirements = (requirements = [], data) =>
	requirements.map(({ label, validate, severity, info }) => {
		if (!data) return { label, isValid: false, info: null };
		const isValid = validate ? validate(data) : true;
		const infoData = info ? info(data) : null;
		if (isValid)
			return {
				label,
				severity: 'valid',
				isValid,
				info: infoData
			};
		return {
			label,
			severity,
			isValid: severity !== 'error',
			info: infoData
		};
	});
