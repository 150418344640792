// Import translation
import i18n from 'i18next';

export default ({
	content_id,
	title,
	broadcast_date,
	description_short,
	country,
	category
}) => {
	const errors = {};

	// ------------------ Content_id ------------------
	if (!content_id) {
		errors.content_id = i18n.t('epg:validation.content_id_required');
	}

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('epg:validation.title_required');
	} else if (title.length < 2 || title.length > 200) {
		errors.title = i18n.t('epg:validation.title_length');
	}

	// ------------------ Broadcast_date ------------------
	if (!broadcast_date) {
		errors.broadcast_date = i18n.t('epg:validation.broadcast_date_required');
	}

	// ------------------ Description_short ------------------
	if (!description_short) {
		errors.description_short = i18n.t(
			'epg:validation.description_short_required'
		);
	}

	return errors;
};
