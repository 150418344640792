import React from 'react';
import { Form } from 'react-final-form';

// Import components
import Fields from './fields/Fields';

//helpers
import { useCSVUploads } from '../helpers/useCSVUploads';

const DevicesSnUploadCSV = ({ updateDeviceSnCSV }) => {
	const { submitSucceeded, uploadCSV, isLoading } = useCSVUploads(
		updateDeviceSnCSV
	);

	const getSelectedFile = (selectedFile) => {
		if (selectedFile[0]?.status === 'removed') return;
		uploadCSV(selectedFile);
	};

	return (
		<>
			<Form
				onSubmit={() => {}}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Fields
							metaData={{
								callBackFn: getSelectedFile,
								submitSucceeded,
								isLoading
							}}
						/>
					</form>
				)}
			/>
		</>
	);
};

export default DevicesSnUploadCSV;
