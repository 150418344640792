import React from 'react';
import PropTypes from 'prop-types';
import ModalImage from 'react-modal-image';

function ImagePreview({ url, alt }) {
	return (
		<ModalImage
			small={url}
			large={url}
			alt={alt}
			className="document_image_preview document_border"
		/>
	);
}

ImagePreview.propTypes = {
	url: PropTypes.string,
	alt: PropTypes.string
};

export default ImagePreview;
