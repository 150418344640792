import React from 'react';
import { Field } from 'react-final-form';
import { Select } from 'antd';

// Import components
import {
	InputLabel,
	InputLabelButtonAsync,
	DatePickerLabel,
	TextareaLabel,
	MultiSelectLabel,
	SelectLabel,
	CheckboxLabel,
	InputFileLabel,
	CSVUploader,
	RangePickerLabel,
	MultiselectLabelCustomChange,
	ProviderFieldMultiSelectLabel,
	TinyTextEditor,
	FetchSelectLabel,
	SelectOnSearchLabel,
	ImageUrl,
	Deeplink
} from 'components/common/inputs';

import FieldWrapper from './FieldWrapper';

const generateRandomStringId = () => {
	return Math.random()
		.toString(36)
		.replace(/[^a-z]+/g, '');
};

export const renderFields = ({
	item,
	index,
	submitting,
	metaData,
	selectResources,
	onSearch
}) => {
	// some fields have isVisible flag. If it's undefined, create and set flag to true
	if (!item.hasOwnProperty('isVisible')) {
		item.isVisible = true;
	}

	const disabled = submitting || item.disabled;
	const placeholder = item.placeholder || item.label;
	const key = `${item.name}-${index ?? generateRandomStringId()}`;

	switch (item.type) {
		case 'textarea':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="textarea"
						component={TextareaLabel}
						id={item.name}
						disabled={disabled}
						name={item.name}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						fieldInfo={item.fieldInfo}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						parse={(value) => value}
						maxLength={item.maxLength}
						style={item.style}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		case 'imageUrl':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="text"
						component={ImageUrl}
						id={item.name}
						disabled={disabled}
						name={item.name}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						fieldInfo={item.fieldInfo}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						requirements={item.requirements}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		case 'deeplink':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="text"
						component={Deeplink}
						id={item.name}
						disabled={disabled}
						name={item.name}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						fieldInfo={item.fieldInfo}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						requirements={item.requirements}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		case 'number':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="number"
						component={InputLabel}
						id={item.name}
						disabled={disabled}
						name={item.name}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						step={item.step}
						min={item.min ?? 0}
						fieldInfo={item.fieldInfo}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		case 'time':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="time"
						component={InputLabel}
						id={item.name}
						disabled={disabled}
						name={item.name}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						fieldInfo={item.fieldInfo}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		case 'date':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="number"
						component={DatePickerLabel}
						id={item.name}
						disabled={disabled}
						name={item.name}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						showTime={item.showTime}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						fieldInfo={item.fieldInfo}
						style={item.style}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		case 'range':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="number"
						component={RangePickerLabel}
						id={item.name}
						disabled={disabled}
						name={item.name}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						showTime={item.showTime}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						fieldInfo={item.fieldInfo}
						style={item.style}
						rangeFormat={item.rangeFormat}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		// rember to provide array attached to the same key as item.name
		// for example item.name = title, then selectResources = {title: []}
		case 'multi-select':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="multi-select"
						component={MultiSelectLabel}
						id={item.name}
						disabled={disabled}
						name={item.name}
						mode={item.mode}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						onSearch={onSearch}
						showSearch={item.showSearch}
						fieldInfo={item.fieldInfo}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
					>
						{selectResources[item.name].map((item, index) => (
							<Select.Option key={index} value={item}>
								{item}
							</Select.Option>
						))}
					</Field>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		// rember to provide array attached to the same key as item.name
		// for example item.name = title, then selectResources = {title: []}
		case 'select':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="select"
						component={SelectLabel}
						id={item.name}
						disabled={disabled}
						name={item.name}
						mode={item.mode}
						placeholder={placeholder}
						defaultValue={item.defaultValue}
						label={item.label}
						filterOption={item.filterOption}
						required={item.required}
						fieldInfo={item.fieldInfo}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						showSearch={item.showSearch}
					>
						{selectResources[item.name].map((item, index) => (
							<Select.Option
								key={index}
								value={item.value}
								disabled={item.disabled}
							>
								{item.name}
							</Select.Option>
						))}
					</Field>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		case 'checkbox':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="checkbox"
						component={CheckboxLabel}
						id={item.name}
						disabled={disabled}
						name={item.name}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						fieldInfo={item.fieldInfo}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		case 'file':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="file"
						component={InputFileLabel}
						id={item.name}
						disabled={disabled}
						name={item.name}
						fileType={item.filetype || ''}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						fieldInfo={item.fieldInfo}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						meta={metaData ?? {}}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		case 'CSV':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="CSV"
						component={CSVUploader}
						id={item.name}
						disabled={metaData?.isLoading}
						name={item.name}
						fileType={item.filetype || ''}
						placeholder={placeholder}
						inputColumn={item.inputColumn}
						meta={metaData ?? {}}
					/>
				</FieldWrapper>
			);

		case 'text-w-button-async':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="text-w-button-async"
						component={InputLabelButtonAsync}
						id={item.name}
						disabled={disabled}
						name={item.name}
						placeholder={placeholder}
						label={item.label}
						fieldInfo={item.fieldInfo}
						required={item.required}
						buttonText={item.buttonText}
						url={item.url}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		// multi-select to which you can provide select resources as array of objects with name and value keys {name: foo, value: bar}
		case 'custom-names':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="multi-select"
						component={MultiSelectLabel}
						id={item.name}
						disabled={disabled}
						name={item.name}
						mode={item.mode}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						onSearch={onSearch}
						showSearch={item.showSearch}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						filterOption={item.filterOption}
					>
						{selectResources[item.name].map((item, index) => (
							<Select.Option key={index} value={item.value}>
								{item.name}
							</Select.Option>
						))}
					</Field>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		// this option is barely used (provider_id field in series and seasons form)
		// however it is quite generic and can be used elsewhere
		case 'multi-select-on-change':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="multi-select"
						component={MultiselectLabelCustomChange}
						id={item.name}
						disabled={disabled}
						name={item.name}
						mode={item.mode}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						onSearch={onSearch}
						onChangeAction={item.onChangeAction}
						showSearch={item.showSearch}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						currentValues={item.currentValues}
						clearFieldName={item.clearFieldName}
					>
						{selectResources[item.name].map((item, index) => (
							<Select.Option key={index} value={item}>
								{item}
							</Select.Option>
						))}
					</Field>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		// --------------------- CUSTOM FIELDS -----------------------
		// USED WITH SPECIFIC FORMS IN MIND

		// custom providers field for season and series forms
		case 'provider-field-multi-select':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="multi-select"
						component={ProviderFieldMultiSelectLabel}
						id={item.name}
						disabled={disabled}
						name={item.name}
						mode={item.mode}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						onSearch={onSearch}
						showSearch={item.showSearch}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		case 'multi-select-no-resources':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type="multi-select"
						component={MultiSelectLabel}
						id={item.name}
						disabled={disabled}
						name={item.name}
						mode={item.mode}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						onSearch={onSearch}
						showSearch={item.showSearch}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						dropdownStyle={{ display: 'none' }}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		case 'tiny-text-editor':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type={item.type}
						component={TinyTextEditor}
						id={item.name}
						disabled={disabled}
						name={item.name}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						fieldInfo={item.fieldInfo}
						values={item.editorValues}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		case 'fetch-select':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						disabled={disabled}
						name={item.name}
						type="select"
						url={item.url}
						placeholder={item.label}
						label={item.label}
						id={item.name}
						required={item.required}
						fieldInfo={item.fieldInfo}
						component={FetchSelectLabel}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						selectValue={item.selectValue}
						selectName={item.selectName}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		case 'select-on-search':
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						disabled={disabled}
						name={item.name}
						placeholder={item.label}
						label={item.label}
						id={item.name}
						required={item.required}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						currentValues={item.currentValues}
						component={SelectOnSearchLabel}
						url={item.url}
						fieldInfo={item.fieldInfo}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);

		default:
			return (
				<FieldWrapper
					visible={item.isVisible}
					systems={item.systems}
					info={item.info}
					content={item.infoContent}
					modalWidth={item.infoModalWidth}
					key={key}
				>
					<Field
						type={item.type}
						component={InputLabel}
						id={item.name}
						disabled={disabled}
						name={item.name}
						placeholder={placeholder}
						label={item.label}
						required={item.required}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						fieldInfo={item.fieldInfo}
						parse={(value) => value}
						maxLength={item.maxLength}
					/>
					<div className="hr-line-dashed">&nbsp;</div>
				</FieldWrapper>
			);
	}
};
