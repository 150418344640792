import React from 'react';
import { Button, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

const PermanentDeleteButtons = ({ isButtonDisabled, onDelete, onCancel }) => {
	const { t } = useTranslation();
	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	return (
		!isReadOnly && (
			<Col
				className="d-flex justify-content-end"
				sm={{ size: '10', offset: 2 }}
			>
				<Button className="m-r-md m-b-md" onClick={onCancel}>
					{t('common:buttons.cancel')}
				</Button>
				<Button
					className="m-b-md"
					data-testid="button"
					disabled={isButtonDisabled}
					type="primary"
					onClick={onDelete}
				>
					{t('common:delete')}
				</Button>
			</Col>
		)
	);
};

PermanentDeleteButtons.propTypes = {
	isButtonDisabled: PropTypes.bool,
	onDelete: PropTypes.func,
	onCancel: PropTypes.func
};

export default PermanentDeleteButtons;
