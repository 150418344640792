import React from 'react';
import i18n from 'i18next';

// Import components
import ProvidersForm from 'components/views/providers/providers_form/ProvidersForm';

export const providersTabs = [
	{
		name: i18n.t('providers:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <ProvidersForm />
	}
];
