import React from 'react';
import PropTypes from 'prop-types';

// Import components
import ListItem from './ListItem';

const MassChangesElementsList = ({
	selection,
	handleRemoveItem,
	productsTitle,
	showSeparator = true
}) => {
	if (selection.length === 0) return null;

	return (
		<>
			{showSeparator && <hr className="mt-5 mb-5" />}
			{productsTitle && <h5>{productsTitle}</h5>}
			<ul className="cards-list">
				{selection.map(({ uuid, title, type, images }) => {
					return (
						type !== 'provider' && (
							<ListItem
								key={uuid}
								value={title}
								type={type}
								img={images}
								handleRemoveItem={handleRemoveItem}
								id={uuid}
							/>
						)
					);
				})}
			</ul>
		</>
	);
};

MassChangesElementsList.propTypes = {
	selection: PropTypes.array.isRequired,
	handleRemoveItem: PropTypes.func.isRequired,
	productsTitle: PropTypes.string.isRequired,
	showSeparator: PropTypes.bool
};

export default MassChangesElementsList;
