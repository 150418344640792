// Import translation
import i18n from 'i18next';

// Import roles
import {
	cms,
	crm,
	data,
	portal,
	ingestion,
	admin,
	support,
	main,
	system_operator
} from 'components/helpers/roles_variables';
// Define roles
// const Admin = ['admin', 'super_admin'];

const NAV_LINKS = [
	{
		title: i18n.t('nav:title.products'),
		path: '/panel/channels',
		icon: 'fa fa-tv',
		name: i18n.t('nav:name.channels'),
		roles: [...cms, ...support],
		rolesGroupName: 'cms'
	},
	{
		title: '',
		path: '/panel/epg_global',
		icon: 'fa fa-video-camera',
		name: i18n.t('nav:name.epg_global'),
		roles: [...cms, ...support],
		rolesGroupName: 'cms'
	},
	{
		title: '',
		path: '/panel/localChannels',
		icon: 'fa fa-tv',
		name: i18n.t('nav:name.localChannels'),
		roles: [...cms, ...support],
		rolesGroupName: 'cms'
	},
	{
		title: '',
		path: '/panel/vod',
		icon: 'fa fa-film',
		name: i18n.t('nav:name.vod'),
		roles: [...cms, ...support],
		rolesGroupName: 'cms'
	},
	{
		title: '',
		path: '/panel/packets',
		icon: 'fa fa-ticket',
		name: i18n.t('nav:name.packets'),
		roles: [...cms, ...support],
		rolesGroupName: 'cms'
	},
	{
		title: '',
		path: '/panel/promotions',
		icon: 'fa fa-dollar',
		name: i18n.t('nav:name.promotions'),
		roles: [...cms, ...support],
		rolesGroupName: 'cms'
	},
	{
		title: '',
		path: '/panel/coupons',
		icon: 'fa fa-dollar',
		name: i18n.t('nav:name.coupons'),
		roles: [...cms, ...support],
		rolesGroupName: 'cms'
	},
	{
		title: '',
		path: '/panel/limits',
		icon: 'fa fa-check-square-o',
		name: i18n.t('nav:name.limits'),
		roles: [...cms, ...support],
		rolesGroupName: 'cms'
	},
	{
		title: '',
		path: '/panel/payment_templates',
		icon: 'fa fa-money',
		name: i18n.t('nav:name.payment_templates'),
		roles: [...cms, ...support],
		rolesGroupName: 'cms'
	},
	{
		title: i18n.t('nav:title.users'),
		path: '/panel/subscribers',
		icon: 'fa fa-user',
		name: i18n.t('nav:name.subscribers'),
		roles: [...crm, ...support],
		rolesGroupName: 'crm'
	},
	{
		title: '',
		path: '/panel/devices',
		icon: 'fa fa-tablet',
		name: i18n.t('nav:name.devices'),
		roles: [...crm, ...support],
		rolesGroupName: 'crm'
	},
	{
		title: '',
		path: '/panel/devices_sn',
		icon: 'fa fa-tablet',
		name: i18n.t('nav:name.devices_sn'),
		roles: [...crm, ...support],
		rolesGroupName: 'crm'
	},
	{
		title: '',
		path: '/panel/provisioning',
		icon: 'fa fa-ticket',
		name: i18n.t('nav:name.provisioning'),
		roles: [...crm, ...support],
		rolesGroupName: 'crm'
	},
	{
		title: i18n.t('nav:title.analitics'),
		path: '/panel/payments',
		icon: 'fa fa-money',
		name: i18n.t('nav:name.payments'),
		roles: data,
		rolesGroupName: 'data'
	},
	{
		title: '',
		path: '/panel/trackings',
		icon: 'fa fa-clock-o',
		name: i18n.t('nav:name.trackings'),
		roles: data,
		rolesGroupName: 'data'
	},
	{
		title: i18n.t('nav:title.content'),
		path: '/panel/categories',
		icon: 'fa fa-check-square-o',
		name: i18n.t('nav:name.categories'),
		roles: portal,
		rolesGroupName: 'portal'
	},
	{
		title: '',
		path: '/panel/documents',
		icon: 'fa fa-file-text-o',
		name: i18n.t('nav:name.documents'),
		roles: portal,
		rolesGroupName: 'portal'
	},
	{
		title: '',
		path: '/panel/agreements',
		icon: 'fa fa-check-square-o',
		name: i18n.t('nav:name.agreements'),
		roles: portal,
		rolesGroupName: 'portal'
	},
	{
		title: '',
		path: '/panel/sections_groups',
		icon: 'fa fa-window-maximize',
		name: i18n.t('nav:name.sections_groups'),
		roles: portal,
		rolesGroupName: 'portal'
	},
	{
		title: '',
		path: '/panel/sections',
		icon: 'fa fa-list-alt',
		name: i18n.t('nav:name.sections'),
		roles: portal,
		rolesGroupName: 'portal'
	},
	{
		title: '',
		path: '/panel/menu_stb',
		icon: 'fa fa-check-square-o',
		name: i18n.t('nav:name.cognito'),
		roles: portal,
		rolesGroupName: 'portal'
	},
	{
		title: '',
		path: '/panel/barker_channels',
		icon: 'fa fa-bullhorn',
		name: i18n.t('nav:name.barker_channels'),
		roles: portal,
		rolesGroupName: 'portal'
	},
	{
		title: '',
		path: '/panel/banners',
		icon: 'fa fa-picture-o',
		name: i18n.t('nav:name.banners'),
		roles: portal,
		rolesGroupName: 'portal'
	},
	{
		title: '',
		path: '/panel/main',
		icon: 'fa fa-ticket',
		name: i18n.t('nav:name.main'),
		roles: portal,
		rolesGroupName: 'portal'
	},
	{
		title: '',
		path: '/panel/tags',
		icon: 'fa fa-tags',
		name: i18n.t('nav:name.tags'),
		roles: portal,
		rolesGroupName: 'portal'
	},
	{
		title: i18n.t('nav:title.ingester'),
		path: '/panel/ingester/assets',
		icon: 'fa fa-exchange',
		name: i18n.t('nav:name.assets'),
		roles: ingestion,
		rolesGroupName: 'ingestion',
		allowedSystems: main
	},
	{
		title: '',
		path: '/panel/ingester/encoding',
		icon: 'fa fa-code',
		name: i18n.t('nav:name.encoding'),
		roles: ingestion,
		rolesGroupName: 'ingestion',
		allowedSystems: main
	},
	{
		title: '',
		path: '/panel/ingester/video-assets-params',
		icon: 'fa fa-gear',
		name: i18n.t('nav:name.video_assets_params'),
		roles: ingestion,
		rolesGroupName: 'ingestion',
		allowedSystems: main
	},
	{
		title: i18n.t('nav:title.administration'),
		path: '/panel/operators',
		icon: 'fa fa-user-plus',
		name: i18n.t('nav:name.operators'),
		roles: admin,
		rolesGroupName: 'admin'
	},
	{
		path: '/panel/providers',
		icon: 'fa fa-truck',
		name: i18n.t('nav:name.providers'),
		roles: admin,
		rolesGroupName: 'admin'
	},
	{
		path: '/panel/affiliate_partners',
		icon: 'fa fa-handshake-o',
		name: i18n.t('nav:name.affiliate_partners'),
		roles: admin,
		rolesGroupName: 'admin'
	},
	{
		path: '/panel/statistics',
		icon: 'fa fa-area-chart',
		name: i18n.t('nav:name.statistics'),
		roles: admin,
		rolesGroupName: 'admin'
	},
	{
		title: '',
		path: '/panel/activity',
		icon: 'fa fa-list',
		name: i18n.t('nav:name.activity'),
		roles: admin,
		rolesGroupName: 'admin'
	},
	{
		title: '',
		path: '/panel/geolocks',
		icon: 'fa fa-globe',
		name: i18n.t('nav:name.geolock'),
		roles: admin,
		rolesGroupName: 'admin'
	},
	{
		title: '',
		path: '/panel/topology',
		icon: 'fa fa-exchange',
		name: i18n.t('nav:name.topology'),
		roles: admin,
		rolesGroupName: 'admin'
	},
	{
		title: '',
		path: '/panel/notifications/create',
		icon: 'fa fa-bell',
		name: i18n.t('nav:name.notifications'),
		roles: admin,
		rolesGroupName: 'admin'
	},
	{
		title: '',
		path: '/panel/marketing_campaigns',
		icon: 'fa fa-bullhorn',
		name: i18n.t('nav:name.marketing_campaigns'),
		roles: admin,
		rolesGroupName: 'admin'
	},
	{
		title: '',
		path: '/panel/software',
		icon: 'fa fa-file-code-o',
		name: i18n.t('nav:name.software'),
		roles: admin,
		rolesGroupName: 'admin'
	},
	{
		title: '',
		path: '/panel/ip_verification',
		icon: 'fa fa-check-circle-o',
		name: i18n.t('nav:name.ip_verification'),
		roles: [...admin, ...crm, ...support],
		rolesGroupName: 'admin'
	},
	{
		title: '',
		path: '/panel/systems',
		icon: 'fa fa-briefcase',
		name: i18n.t('nav:name.systems'),
		roles: [...admin, ...system_operator],
		rolesGroupName: 'admin',
		maxSystemTreeDepth: 1
	},
	{
		title: '',
		path: '/panel/settings/metadata',
		icon: 'fa fa-cogs',
		name: i18n.t('nav:name.settings'),
		roles: admin,
		rolesGroupName: 'admin'
	}
];

export default NAV_LINKS;
