import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchEpgItem } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const EpgForm = ({
	history,
	fetchEpgItem,
	error,
	isLoaded,
	initialValues,
	match: {
		params: { channelId }
	}
}) => {
	const { t } = useTranslation();

	const redirectPath = `channels/edit/${channelId}/epg`;

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) =>
		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: 'channel/programs',
			redirectPath
		});

	const { photos } = initialValues;

	return (
		// Dispatch fetchEpgItem actions in in epg_form
		<FormTemplate
			fetchResourceToEdit={fetchEpgItem}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={{ ...initialValues }}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields photos={photos} submitting={submitting} />

							<FormButtons
								isButtonDisabled={submitting || error}
								path={redirectPath}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

EpgForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchEpgItem: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			channelId: PropTypes.string.isRequired
		})
	})
};

const mapStateToProps = ({ epg: { form } }) => ({
	initialValues: form.edit,
	error: form.error,
	isLoaded: form.isLoaded
});

export default compose(
	connect(
		mapStateToProps,
		{ fetchEpgItem }
	),
	withRouter
)(EpgForm);
