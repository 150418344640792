import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Import services
import services from 'services/services';

// Validate
import validate from './fields/validation';

// Import components
import Fields from './fields/Fields';
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';

// Import helpers
import { FETCH_PAYMENT_TEMPLATES_USE } from 'components/helpers/api';
import useValues from './helpers/useValues';

const useTemplate = ({ templateId, productId, data }) => {
	const url = FETCH_PAYMENT_TEMPLATES_USE.replace(
		':templateId',
		templateId
	).replace(':productId', productId);

	return services.post(url, data, {
		headers: { 'Content-Type': 'multipart/form-data' }
	});
};

const UsePaymentTemplate = ({
	match: {
		params: { id }
	},
	prevPath,
	loading,
	error,
	templates,
	templateDetails
}) => {
	const { t } = useTranslation();
	const { handleOnSubmit, redirectPath, isMain, initialValues } = useValues({
		prevPath,
		useTemplate,
		id,
		templateDetails
	});

	return (
		<FormTemplate isLoaded={!loading} error={error}>
			{({ isEdit, itemID }) => (
				<Form
					validate={validate}
					initialValues={initialValues}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								templates={templates}
								isMain={isMain}
								details={templateDetails}
							/>
							<FormButtons
								isButtonDisabled={submitting}
								path={redirectPath}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

UsePaymentTemplate.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string })
	}),
	prevPath: PropTypes.string.isRequired,
	loading: PropTypes.bool,
	error: PropTypes.bool,
	templates: PropTypes.array,
	templateDetails: PropTypes.array
};

const mapStateToProps = ({
	previous_location: { pathname },
	payment_templates: {
		dropdown: { details, data, loading, error }
	}
}) => {
	return {
		prevPath: pathname,
		loading,
		error,
		templates: data,
		templateDetails: details
	};
};

export default compose(
	connect(mapStateToProps),
	withRouter
)(UsePaymentTemplate);
