import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';
import { getPropertiesData } from './helpers';

// ******************** FETCH COGNITO CATEGORY TO EDIT ********************
export const fetchCognitoCategory = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_COGNITO_CATEGORY_LOADING,
			payload: true
		});

		const { data } = await services.get(`/cognito/categories/${id}`);

		const { customProperties, selectedProperties } = getPropertiesData({
			data
		});

		const payload = {
			...data,
			...customProperties,
			selectedProperties
		};

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_COGNITO_CATEGORY_SUCCESS,
			payload
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_COGNITO_CATEGORY_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** CLEAR COGNITO CATEGORY DATA ********************
export const clearCognitoCategoryData = () => async (dispatch) => {
	dispatch({
		type: types.CLEAR_STATE,
		payload: true
	});
};
