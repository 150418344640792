import services from 'services/services';
import * as types from '../types';

// Import variables
import { UPDATE_DEVICE_SN_LIST_CSV_API } from 'components/helpers/api';
import { TABLE_LENGTH } from 'components/helpers/table';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// ******************** UPDATE DEVICE_SN TO EDIT ********************
export const updateDeviceSnCSV = (_) => async (dispatch, getState) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_DEVICE_SN_LOADING
		});

		const {
			devices_sn: {
				table: { columns, options: stateOptions }
			}
		} = getState();

		const startIndex = stateOptions.page;
		/*=============================================
	dummy options were used here to simulate the options
parameters passed into the fetchDevicesSn function by the
PanelPageTemplate table on mount. This to keep table state
in-sync with was was previously showing before uploading CSV
    =============================================*/
		const options = {
			accessor: 'active',
			filtered: { query: '' },
			pageSize: 30,
			page: 0,
			currentlySorted: [],
			defaultSorted: [],
			sorted: {
				columnIndex: 0,
				type: 'asc'
			},
			startIndex: 0
		};

		const { data } = await services.get(
			`${UPDATE_DEVICE_SN_LIST_CSV_API}${queryParams(
				options,
				TABLE_LENGTH,
				columns,
				startIndex
			)}`
		);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.UPDATE_DEVICE_SN_LIST_CSV_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex: 0 }
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_DEVICE_SN_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
