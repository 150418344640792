import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { sortableElement } from 'react-sortable-hoc';

const SortableItem = sortableElement(
	({ value: { name, label }, handleRemoveItem, id }) => {
		const handleRemove = () => handleRemoveItem(id);

		return (
			<li className="sortable-sections__li">
				<span className="item">
					<span className="item__move">
						<i className="fa fa-bars" aria-hidden="true"></i>
					</span>
					<span className="item__title">
						<Link
							to={`/panel/sections/edit/${id}/metadata`}
						>{`( ${label} ) ${name}`}</Link>
					</span>
					<button className="item__delete" onClick={handleRemove} type="button">
						<i className="fa fa-trash-o" aria-hidden="true"></i>
					</button>
				</span>
			</li>
		);
	}
);

SortableItem.propTypes = {
	value: PropTypes.shape({
		name: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired
	}),
	handleRemoveItem: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired
};

export default SortableItem;
