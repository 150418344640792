import React from 'react';

// Import components
import Tabs from 'components/utilities/tabs/Tabs';

// Import helpers
import { tagsTabs } from './helpers/index';

const TagsTabs = () => <Tabs tabs={tagsTabs} />;

export default TagsTabs;
