import React from 'react';
import PropTypes from 'prop-types';
import Spin from 'antd/es/spin';

const Loader = ({ children, isLoaded, size }) => (
	<div className="loading">
		<Spin size={size} className="loading__circle" spinning={!isLoaded}>
			{children}
		</Spin>
	</div>
);

Loader.defaultProps = {
	size: 'large'
};

Loader.propTypes = {
	isLoaded: PropTypes.bool.isRequired,
	size: PropTypes.string
};

export default Loader;
