import React from 'react';

// Import translation
import i18n from 'i18next';

// Import components
import { Modal } from 'antd';
import message from 'antd/es/message';

// renders cell with delete action, contains modal to confirm delete
const RenderCell = (handleSelectItem, canEditVideo) => (
	text,
	record,
	index
) => {
	// shows modal to confirm delete. On OK it calls function to delete that is in Context
	const confirmModal = () =>
		Modal.confirm({
			title: i18n.t(`common:delete`),
			content: `${i18n.t(`messages:modal.delete_confirm_message`)} ${i18n.t(
				`names:notification_names.subtitle`
			)}`,
			okText: i18n.t('common:buttons.ok'),
			cancelText: i18n.t('common:buttons.cancel'),
			onOk: () => handleSelectItem(record.uuid),
			onCancel() {
				message.info(i18n.t('messages:messages.subtitle_delete_canceled'));
			}
		});

	return (
		<button onClick={confirmModal} className="btn btn-xs btn-primary">
			<i className="fa fa-trash" />
		</button>
	);
};

export const createColumns = (handleSelectItem, canEditVideo) => [
	{
		title: i18n.t('vod_subtitles:table_columns.language'),
		dataIndex: 'language',
		key: 'language'
	},
	{
		title: i18n.t('vod_subtitles:table_columns.filename'),
		dataIndex: 'filename',
		key: 'filename',
		render: (text, record, index) => (
			<a target="_blank" rel="noopener noreferrer" href={record.url}>
				{record.filename}
			</a>
		)
	},

	{
		title: i18n.t('common:table_columns.delete'),
		dataIndex: 'delete',
		key: 'delete',
		width: 70,
		align: 'center',
		render: canEditVideo && RenderCell(handleSelectItem, canEditVideo)
	}
];
