import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchPromotionPacketsList } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import ProductsSelect from 'components/utilities/products_select/ProductsSelect';

// Import products tabs
import { provisioning } from 'components/utilities/products_select/helpers/tabs';

const PromotionsPacketsList = ({
	data,
	error,
	isLoaded,
	fetchPromotionPacketsList
}) => {
	const { t } = useTranslation();
	const tabs = [provisioning];

	const createSubmitData = (selectedData) => {
		const selection = { packets_uuids: [] };

		selection.packets_uuids = selectedData.map((item) => item.uuid);

		return selection;
	};

	return (
		<ProductsSelect
			api="promotions"
			apiSlug={`packets`}
			title={t('promotions:packets_list.title')}
			tabs={tabs}
			openModalText={t('promotions:packets_list.add_packets')}
			buttonSubmitText={t('common:buttons.submit')}
			notificationName="products"
			initialData={data}
			error={error}
			isLoaded={isLoaded}
			fetchResourcesAction={fetchPromotionPacketsList}
			createSubmitData={createSubmitData}
		/>
	);
};

PromotionsPacketsList.propTypes = {
	data: PropTypes.array.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	fetchPromotionPacketsList: PropTypes.func.isRequired
};

const mapStateToProps = ({ promotions: { packets_list } }) => {
	return {
		data: packets_list.data,
		isLoaded: packets_list.isLoaded,
		error: packets_list.error
	};
};

export default connect(mapStateToProps, { fetchPromotionPacketsList })(
	PromotionsPacketsList
);
