import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { connect } from 'react-redux';

import { PaymentTemplateContext } from '../../PaymentTemplateContext';
import PaymentTemplateTableHeader from './PaymentTemplateTableHeader';

// Import helpers
import { locale } from 'components/helpers/table';

function PaymentTemplateTable({ columns, resources, usedPayments }) {
	const { t } = useTranslation();
	const { editAsset, isEdit, setUsedPayments, deleteAsset } = useContext(
		PaymentTemplateContext
	);

	setUsedPayments(usedPayments);
	const TitleHeader = () => (
		<PaymentTemplateTableHeader resources={resources} />
	);

	return (
		<>
			<span className="input-info m-b-sm">
				<i className="fa fa-info-circle m-r-sm"></i>
				{t('payment_templates:info.unique_payment_methods')}
			</span>
			<Table
				rowKey={(record) => record.payment_method}
				dataSource={resources.assets}
				columns={columns({ editAsset, isEdit, deleteAsset })}
				title={TitleHeader}
				bordered
				pagination={false}
				size="small"
				className="material-table"
				locale={locale}
			/>
		</>
	);
}

PaymentTemplateTable.propTypes = {
	usedPayments: PropTypes.arrayOf(PropTypes.string),
	columns: PropTypes.func,
	resources: PropTypes.object
};

const mapStateToProps = ({ payment_templates: { edit } }) => ({
	usedPayments: edit.table.map((row) => row.payment_method)
});

export default connect(mapStateToProps)(PaymentTemplateTable);
