import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	promotionsQueryParams
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH PROMOTIONS - TABLE DATA ********************
export const fetchPromotions = (options) => async (dispatch, getState) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_PROMOTIONS_LOADING,
			payload: true
		});

		const {
			promotions: {
				table: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		// Do not show coupons => get promotions with is_coupon: false flag
		// Coupons have their own table, they are just using the same endpoint
		const couponsQuery = 'columns[12][search][value]=0&';

		const url = `/promotions/list?${couponsQuery}${filterUrlQuery}${promotionsQueryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex
		)}`;

		const { data } = await services.get(url);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_PROMOTIONS_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_PROMOTIONS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE PROMOTION ********************
export const deletePromotion = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DELETE_PROMOTION_LOADING,
			payload: true
		});

		await services.delete(`/promotions/${id}`);
		// Dispatch an action with data
		dispatch({
			type: types.DELETE_PROMOTION_SUCCESS,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DELETE_PROMOTION_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET SEARCH QUERY ********************
export const setPromotionsQuery = (query) => ({
	type: types.SET_PROMOTIONS_SEARCH_QUERY,
	payload: query
});
