import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setTableColumns } from 'store/actions';

// Import utilities
import Toggle from 'components/utilities/toggle/Toggle';

// Import components
import { Button } from 'reactstrap';
import FiltersDrawer from 'components/utilities/filters/filters_drawer/FiltersDrawer';

// Import translation
import { useTranslation } from 'react-i18next';

/**
 * Functional component rendering filter button next to search in dashboard tables.
 * It contains filters drawer with filter form
 * @param {array} tableColumns - array of current table columns available from api
 * @returns {*}
 */
const FilterButton = ({ tableColumns }) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const { filterFormValues } = useSelector((state) => state.filters);

	// if there are no values in filterFormValues or all values are null
	// second condition is added because antd DatePicker on clearing returns null value and React Final Form can't clear that value
	const isFiltered = !(
		(Object.entries(filterFormValues).length === 0 &&
			filterFormValues.constructor === Object) ||
		Object.values(filterFormValues).filter((value) => value !== null).length ===
			0
	);

	useEffect(() => {
		setTableColumns(tableColumns)(dispatch);
		// eslint-disable-next-line
	}, [tableColumns]);
	return (
		<Toggle>
			{({ on, toggle }) => {
				return (
					<>
						<Button
							className={`btn m-l-sm ${
								isFiltered ? 'filters__button__active' : ''
							}`}
							onClick={toggle}
						>
							{isFiltered
								? t('filters:buttons.filters_active')
								: t('filters:buttons.filters')}
						</Button>
						<FiltersDrawer on={on} toggle={toggle} />
					</>
				);
			}}
		</Toggle>
	);
};

FilterButton.propTypes = {
	tableColumns: PropTypes.array.isRequired
};

export default FilterButton;
