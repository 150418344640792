import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { Link } from 'react-router-dom';
import TabsWebLink from './TabsWebLink';

function TabsBreadcrumbs({ child, parent, baseParent, properSubtype }) {
	const { childName, childId, childSubtype, childActive } = child;
	const { parentName, parentId, parentSubtype } = parent;
	const { baseParentName, baseParentId, baseParentSubtype } = baseParent;
	return (
		<div className="d-flex">
			{/* render base parent link */}
			{baseParentName && parentName && childName && (
				<Link
					to={`/panel/vod/${baseParentSubtype}/edit/${baseParentId}/metadata`}
				>
					<span className="tabs-breadcrumbs__link">{baseParentName} /</span>
				</Link>
			)}
			{/* render parent link */}
			{parentName && childName && (
				<Link to={`/panel/vod/${parentSubtype}/edit/${parentId}/metadata`}>
					<span className="tabs-breadcrumbs__link">{parentName} /</span>
				</Link>
			)}
			{/* render child name */}
			{childName && <span className="tabs-breadcrumbs__name">{childName}</span>}
			{/* render web link for product details */}
			{properSubtype && childActive && (
				<TabsWebLink id={childId} title={childName} subtype={childSubtype} />
			)}
		</div>
	);
}

TabsBreadcrumbs.propTypes = {
	child: PropTypes.object.isRequired,
	parent: PropTypes.object.isRequired,
	baseParent: PropTypes.object.isRequired,
	properSubtype: PropTypes.bool.isRequired
};

export default TabsBreadcrumbs;
