import React from 'react';
import PropTypes from 'prop-types';

// Import utilities
import { renderFields } from 'components/utilities/form';

import { BASE_FIELDS } from './input_fields';

const Fields = ({ metaData }) => {
	return (
		<React.Fragment>
			{BASE_FIELDS({ metaData }).map((item, index) =>
				renderFields({ item, index, metaData })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	metaData: PropTypes.object.isRequired
};

export default Fields;
