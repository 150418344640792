// Import translation
import i18n from 'i18next';

export default ({ metadata_file }) => {
	const errors = {};

	if (!metadata_file) {
		errors.metadata_file = i18n.t('vod_subtitles:validation.file_required');
	}

	return errors;
};
