import React from 'react';
import i18n from 'i18next';

// Import components
import PaymentTemplateForm from '../../payment_template_form/PaymentTemplateForm';

export const panelTemplatesTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <PaymentTemplateForm />
	}
];
