import services from 'services/services';
import * as types from '../types';
import { generatePath } from 'react-router-dom';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// Import variables
import { MARKETING_CAMPAIGN } from 'components/helpers';

// ******************** FETCH MARKETING CAMPAIGN TO EDIT ********************
export const fetchMarketingCampaign = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_MARKETING_CAMPAIGN_LOADING,
			payload: true
		});

		const url = generatePath(MARKETING_CAMPAIGN, { id });

		const { data } = await services.get(url);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_MARKETING_CAMPAIGN_SUCCESS,
			payload: data.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_MARKETING_CAMPAIGN_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
