import React from 'react';

// Import translation
import i18n from 'i18next';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('geolocks:table_columns.id'),
		accessor: 'id',
		width: 60
	},
	{
		Header: i18n.t('geolocks:table_columns.type'),
		accessor: 'type'
	},
	{
		Header: i18n.t('geolocks:table_columns.ip_from'),
		accessor: 'ip_from'
	},
	{
		Header: i18n.t('geolocks:table_columns.ip_to'),
		accessor: 'ip_to'
	},
	{
		Header: i18n.t('geolocks:table_columns.description'),
		accessor: 'description'
	},
	{
		Header: i18n.t('geolocks:table_columns.created_at'),
		accessor: 'created_at'
	},
	{
		Header: i18n.t('geolocks:table_columns.updated_at'),
		accessor: 'updated_at'
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(id)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
