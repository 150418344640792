import React from 'react';
import PropTypes from 'prop-types';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import helpers
import { renderFields } from 'components/utilities/form';

const Fields = ({ submitting, currentSystemWithChildren, systems, isEdit }) => {
	const selectResources = {
		// At create stage we can display only current system with its children, while at edit stage we need to have list of all systems, because parent system can be of any level e.g. main.
		parent_system: !isEdit ? systems : currentSystemWithChildren
	};

	return (
		<>
			{BASE_FIELDS({ isEdit }).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	currentSystemWithChildren: PropTypes.array.isRequired,
	systems: PropTypes.array.isRequired,
	isEdit: PropTypes.bool.isRequired
};

export default Fields;
