import React from 'react';
import PropTypes from 'prop-types';

// Import helpers
import { avaliableExtensions } from '../helpers';

// Import components
import NoPreview from './previews/NoPreview';
import ImagePreview from './previews/ImagePreview';
import PdfPreview from './previews/PdfPreview';

function DocumentPreview({ type, url, fileName }) {
	if (type === 'pdf') return <PdfPreview />;
	if (!avaliableExtensions.includes(type)) return <NoPreview />;
	return (
		<div className="document_preview">
			<div className="document_preview_center">
				<ImagePreview url={url} alt={fileName} />
			</div>
		</div>
	);
}

DocumentPreview.propTypes = {
	type: PropTypes.string,
	url: PropTypes.string,
	fileName: PropTypes.string
};

export default DocumentPreview;
