import React from 'react';
import i18n from 'i18next';

// Import components
import AgreementsForm from '../../agreements_form/AgreementsForm';
import AgreementsVersions from '../../agreements_versions/AgreementsVersions';

export const agreementsTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <AgreementsForm />
	},
	{
		name: i18n.t('common:tabs.versions'),
		path: 'versions',
		disableOnCreate: true,
		component: <AgreementsVersions />
	}
];
