import React from 'react';
import i18n from 'i18next';

// Import components
import PromotionsForm from '../../promotions_form/PromotionsForm';
import PromotionsUsersList from '../../promotions_users_list/PromotionsUsersList';
import PromotionsPacketsList from '../../promotions_packets_list/PromotionsPacketsList';
import PromotionsProductsList from '../../promotions_products/promotion_products_list/PromotionsProductsList';
import PromotionsProductsForm from '../../promotions_products/promotions_products_form/PromotionsProductsForm';
import PromotionProductsWrapper from '../../promotions_products/PromotionProductsWrapper';
import PromotionsProductsPreview from '../../promotions_products/promotions_products_preview/PromotionsProductsPreview';
import SystemSettingsForm from 'components/utilities/system_settings/SystemSettingsForm';

// Import helpers
import { PROMOTIONS_EDIT_TABS_PATHS } from './helpers';
import {
	PROMOTION_PRODUCTS_IN,
	PROMOTION_PRODUCTS_OUT
} from 'components/helpers/variables';

// Import products tabs
import {
	vod,
	collections,
	allPackets
} from 'components/utilities/products_select/helpers/tabs';

const {
	PROMOTIONS_USERS,
	PROMOTIONS_PRODUCTS_OUT,
	PROMOTIONS_PRODUCTS_OUT_PREVIEW,
	PROMOTIONS_PRODUCTS_IN,
	PROMOTIONS_PRODUCTS_IN_PREVIEW,
	PROMOTIONS_USERS_WITH_PACKETS
} = PROMOTIONS_EDIT_TABS_PATHS;

const renderPromotionProducts = (productTypeVariable, tabs) => (
	<PromotionProductsWrapper productType={productTypeVariable}>
		{(
			{
				childActions: { fetchListAction, fetchFormAction },
				childStore: { productListStore, productFormStore }
			},
			productType
		) => (
			<>
				<PromotionsProductsForm
					fetchFormAction={fetchFormAction}
					productFormStore={productFormStore}
					productType={productType}
				/>
				<PromotionsProductsList
					fetchListAction={fetchListAction}
					productListStore={productListStore}
					productType={productType}
					tabs={tabs}
				/>
			</>
		)}
	</PromotionProductsWrapper>
);

export const promotionsTabs = ({ isCreateTab, checkIsTabDisabled }) => {
	const promotionsTabsList = [
		{
			name: i18n.t('common:tabs.metadata'),
			path: 'metadata',
			disableOnCreate: false,
			component: <PromotionsForm />
		},
		{
			name: i18n.t('common:tabs.system_settings'),
			path: 'system_settings',
			disableOnCreate: true,
			component: <SystemSettingsForm />
		}
	];
	const promotionsEditTabs = [
		{
			name: i18n.t('promotions:tabs.promotions_users'),
			path: PROMOTIONS_USERS,
			disableOnCreate: true,
			isDisabled: checkIsTabDisabled(PROMOTIONS_USERS),
			component: <PromotionsUsersList />
		},
		{
			name: i18n.t('promotions:tabs.promotions_packets'),
			path: PROMOTIONS_USERS_WITH_PACKETS,
			disableOnCreate: true,
			isDisabled: checkIsTabDisabled(PROMOTIONS_USERS_WITH_PACKETS),
			component: <PromotionsPacketsList />
		},
		{
			name: i18n.t('promotions:tabs.promotions_products_out'),
			path: PROMOTIONS_PRODUCTS_OUT,
			disableOnCreate: true,
			isDisabled: checkIsTabDisabled(PROMOTIONS_PRODUCTS_OUT),
			component: renderPromotionProducts(PROMOTION_PRODUCTS_OUT, [
				vod,
				collections,
				allPackets
			])
		},
		{
			name: i18n.t('promotions:tabs.promotions_products_out_preview'),
			path: PROMOTIONS_PRODUCTS_OUT_PREVIEW,
			disableOnCreate: true,
			isDisabled: checkIsTabDisabled(PROMOTIONS_PRODUCTS_OUT_PREVIEW),
			component: (
				<PromotionsProductsPreview productType={PROMOTION_PRODUCTS_OUT} />
			)
		},
		{
			name: i18n.t('promotions:tabs.promotions_products_in'),
			path: PROMOTIONS_PRODUCTS_IN,
			disableOnCreate: true,
			isDisabled: checkIsTabDisabled(PROMOTIONS_PRODUCTS_IN),
			component: renderPromotionProducts(PROMOTION_PRODUCTS_IN, [
				vod,
				collections,
				allPackets
			])
		},
		{
			name: i18n.t('promotions:tabs.promotions_products_in_preview'),
			path: PROMOTIONS_PRODUCTS_IN_PREVIEW,
			disableOnCreate: true,
			isDisabled: checkIsTabDisabled(PROMOTIONS_PRODUCTS_IN_PREVIEW),
			component: (
				<PromotionsProductsPreview productType={PROMOTION_PRODUCTS_IN} />
			)
		}
	];

	if (!isCreateTab) {
		return [...promotionsTabsList, ...promotionsEditTabs];
	}

	return promotionsTabsList;
};
