// genres types for vod and live
export const LIVE_GENRES_TYPE = 1;
export const VOD_GENRES_TYPE = 2;

// defualt parameters to fetch categories for live
export const liveCategoriesOptions = {
	pageSize: null,
	page: 0,
	startIndex: 0,
	sorted: { columnIndex: 0, type: 'asc' },
	filtered: { query: '', type: LIVE_GENRES_TYPE },
	accessor: 'name'
};

// defualt parameters to fetch categories for vod
export const vodCategoriesOptions = {
	pageSize: null,
	page: 0,
	startIndex: 0,
	sorted: { columnIndex: 0, type: 'asc' },
	filtered: { query: '', type: VOD_GENRES_TYPE },
	accessor: 'name'
};

// creates array of string names from array of objects
export const convertGenresToNames = (genres) => genres.map(({ name }) => name);

// creates array of ids from two genres arrays
export const convertToIds = (genresList, genresSelect) =>
	genresList
		.filter(({ name }) => genresSelect.includes(name))
		.map(({ id }) => id);

// convert number of category type to coresponding string
export const convertTypeToString = (type) => {
	if (type === LIVE_GENRES_TYPE) return 'live';
	if (type === VOD_GENRES_TYPE) return 'vod';
	else return type;
};

// convert string of category type to coresponding number
export const convertTypeToNumber = (type) => {
	if (type === 'live') return LIVE_GENRES_TYPE;
	if (type === 'vod') return VOD_GENRES_TYPE;
	else return type;
};
