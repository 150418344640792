import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// Import variables
import { TAGS_LIST_API } from 'components/helpers/api';
import { TAGS_CATEGORIES } from 'components/helpers/variables';

const { VOD } = TAGS_CATEGORIES;

// ******************** FETCH CATEGORY RELATED TAGS ********************
export const fetchTagsByCategory = (category = VOD) => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_VOD_TAGS_LOADING,
			payload: true
		});

		const { data } = await services.get(TAGS_LIST_API, {
			params: { 'columns[4][search][value]': category }
		});

		const availableValues = data?.data?.map(({ name }) => name);

		dispatch({
			type: types.FETCH_VOD_TAGS_SUCCESS,
			payload: { category, values: availableValues }
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_VOD_TAGS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
