import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	fetchVodAdi,
	refreshIngesterAssets,
	refreshIngesterEncoding,
	refreshIngesterHistory,
	refreshIngesterAssetsItems
} from 'store/actions';

const useVodEncoding = () => {
	const { id } = useParams();

	const dispatch = useDispatch();

	const { loading: adiLoading, error: adiError } = useSelector(
		(state) => state.vod.encoding
	);
	const {
		assets_table: { loading: assetsLoading, error: assetsError },
		encoding_table: { loading: encodingLoading, error: encodingError },
		history_table: { loading: historyLoading, error: historyError },
		assets_items_table: { loading: assetsItemsLoading, error: assetsItemsError }
	} = useSelector(({ ingester }) => ingester);

	useEffect(() => {
		fetchVodAdi(id)(dispatch);
		// eslint-disable-next-line
	}, []);

	const handleRefresh = () => {
		refreshIngesterAssets()(dispatch);
		refreshIngesterEncoding()(dispatch);
		refreshIngesterHistory()(dispatch);
		refreshIngesterAssetsItems()(dispatch);
	};

	return {
		assetsLoading,
		assetsError,
		adiLoading,
		adiError,
		encodingLoading,
		encodingError,
		historyLoading,
		historyError,
		assetsItemsLoading,
		assetsItemsError,
		refresh: handleRefresh,
		vodId: id
	};
};

export default useVodEncoding;
