// Import translation
import i18n from 'i18next';

export default ({ code }) => {
	const errors = {};

	const isProperCodeValue = /^[a-z0-9_]*$/i.test(code);
	const isProperCodeLength = !(code?.length > 40);

	// ------------------ code ------------------
	if (!code) {
		errors.code = i18n.t('affiliate_partners:validation.code');
	}

	if (!isProperCodeValue) {
		errors.code = i18n.t('affiliate_partners:validation.code_wrong');
	}

	if (!isProperCodeLength) {
		errors.code = i18n.t('affiliate_partners:validation.code_length');
	}

	return errors;
};
