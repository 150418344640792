import fileDownload from 'js-file-download';

// Import services
import services from 'services/services';

export const handleDownloadButtonClick = ({ id, filename }) => {
	const fetchFile = async () => {
		const url = `/cognito/topology/file/${id}/download`;

		const { data } = await services.get(url);

		fileDownload(data, filename);
	};

	fetchFile();
};
