// Import translation
import i18n from 'i18next';

// Validate the form
const validate = (values) => {
	const errors = {};
	// Check if email is valid
	if (!values.email) {
		errors.email = i18n.t('login:validation.email_required');
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = i18n.t('login:validation.email_invalid');
	}
	return errors;
};

export default validate;
