import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { DatePicker as DatePickerAntd } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { setTrackingsDate } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Define initial state
const initialStateSince = moment().subtract(7, 'days');
const initialStateTill = moment();

const DatePickers = ({ setTrackingsDate }) => {
	const { t } = useTranslation();

	const [dateSince, setDateSince] = React.useState(initialStateSince);
	const [dateTill, setDateTill] = React.useState(initialStateTill);

	// Define date format
	const dateFormat = 'YYYY-MM-DD';

	const dateNames = [
		{
			name: 'date_since'
		},
		{
			name: 'date_till'
		}
	];

	const onDateChange = (value, date, name) => {
		// Update state
		name === 'date_since' ? setDateSince(value) : setDateTill(value);
		// Dispatch setTrackingsDate action in trackings_table
		setTrackingsDate(date, name);
	};

	return (
		<Row>
			{dateNames.map(({ name }, index) => (
				<Col sm={12} md={6} lg={4} xl={3} key={index}>
					<FormGroup>
						<Label
							for="select-epg-date"
							className="col-form-label col-form-label-lg m-r-sm"
						>
							{t(`trackings:table_filters.${name}`)}
						</Label>
						<DatePickerAntd
							format={dateFormat}
							id={`select-tracking-${name}`}
							allowClear={false}
							onChange={(value, date) => onDateChange(value, date, name)}
							value={name === 'date_since' ? dateSince : dateTill}
						/>
					</FormGroup>
				</Col>
			))}
		</Row>
	);
};

DatePickers.propTypes = {
	setTrackingsDate: PropTypes.func.isRequired
};

export default connect(
	null,
	{ setTrackingsDate }
)(DatePickers);
