import React from 'react';
import PropTypes from 'prop-types';

// Import select options
import {
	select_promotion_triggers,
	select_promotion_types,
	select_product_specification,
	select_user_specification,
	select_entry_condition_products_specification
} from './select_type_options';

// Import utilites
import { renderFields } from 'components/utilities/form';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({ submitting, currentValues, isEdit }) => {
	// apply select values to proper select fields and theirs comboboxes
	const selectResources = {
		users_specification: select_user_specification,
		products_specification: select_product_specification,
		promotion_type: select_promotion_types,
		trigger: select_promotion_triggers,
		entry_condition_products_specification: select_entry_condition_products_specification
	};

	return (
		<React.Fragment>
			{BASE_FIELDS({ isEdit, currentValues }).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	currentValues: PropTypes.object.isRequired,
	isEdit: PropTypes.bool.isRequired
};

export default Fields;
