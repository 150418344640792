// TYPES
export const UPDATE_CHILD_DATA = 'UPDATE_CHILD_DATA';
export const UPDATE_PARENT_DATA = 'UPDATE_PARENT_DATA';
export const UPDATE_BASE_PARENT_DATA = 'UPDATE_BASE_PARENT_DATA';
export const UPDATE_LOADING = 'UPDATE_LOADING';

// INIT STATE
export const initialState = {
	loading: true,
	child: {
		childName: '',
		childId: null,
		childSubtype: null,
		childActive: false
	},
	parent: {
		parentName: '',
		parentId: null,
		parentSubtype: null
	},
	baseParent: {
		baseParentName: '',
		baseParentId: null,
		baseParentSubtype: null
	}
};

// ACTIONS
export const updateChildData = (childData) => (dispatch) => {
	dispatch({ type: UPDATE_CHILD_DATA, payload: childData });
};
export const updateParentData = (parentData) => (dispatch) => {
	dispatch({ type: UPDATE_PARENT_DATA, payload: parentData });
};
export const updateBaseParentData = (baseParentData) => (dispatch) => {
	dispatch({ type: UPDATE_BASE_PARENT_DATA, payload: baseParentData });
};

export const updateLoading = () => (dispatch) => {
	dispatch({ type: UPDATE_LOADING });
};

// REDUCER
export function tabReducer(state, action) {
	switch (action.type) {
		case UPDATE_CHILD_DATA:
			return { ...state, child: { ...action.payload } };

		case UPDATE_PARENT_DATA:
			return {
				...state,
				parent: {
					...action.payload
				}
			};
		case UPDATE_BASE_PARENT_DATA:
			return {
				...state,
				baseParent: {
					...action.payload
				}
			};
		case UPDATE_LOADING:
			return {
				...state,
				loading: false
			};

		default:
			return { ...state };
	}
}
