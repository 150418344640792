import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	loading: true,
	error: false,
	data: [],
	columns: [],
	deleteItem: {
		success: false,
		error: false
	},
	options: {
		pages: 0,
		page: 0,
		total_results: 0,
		startIndex: 0,
		sorted: undefined,
		filters: {
			query: '',
			platform: 'all'
		}
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR DATA ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// *************** FETCH DEVICES ***************
			case types.FETCH_DEVICES_LOADING:
				draft.loading = true;
				draft.error = false;
				return;

			case types.FETCH_DEVICES_SUCCESS:
				draft.loading = false;
				draft.data = action.payload.data;
				draft.columns = action.payload.input.columns;
				draft.options.pages = action.payload.pages;
				draft.options.total_results = action.payload.recordsFiltered;
				draft.options.startIndex = action.payload.startIndex;
				draft.options.page = action.payload.page;
				return;

			case types.FETCH_DEVICES_ERROR:
				draft.error = action.payload;
				return;
			// *************** SAVE SORTING OPTIONS ***************
			case types.SAVE_DEVICES_TABLE_SORTING:
				draft.options.sorted = action.payload;
				break;

			// *************** SET SEARCH QUERY ***************
			case types.SET_DEVICES_SEARCH_QUERY:
				draft.options.filters.query = action.payload;
				return;

			// *************** SET DEVICES PLATFORM***************
			case types.SET_DEVICES_PLATFORM:
				draft.options.filters.platform = action.payload;
				break;

			default:
				return state;
		}
	});
