import { combineReducers } from 'redux';

// Import reducers
import table from './systems_panel_reducer';
import form from './systems_form_reducer';
import providers from './systems_providers_products_reducer';
import channels from './systems_channels_products_reducer';

export default combineReducers({
	table,
	form,
	providers,
	channels
});
