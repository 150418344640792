import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import components
import DeleteButton from 'components/utilities/delete_button/DeleteButton';
import ScheduleActivity from 'components/utilities/schedule_activity/ScheduleActivity';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('agreements:table_columns.name'),
		accessor: 'name',
		Cell: ({
			row: {
				_original: { id, name }
			}
		}) => <Link to={`/panel/agreements/edit/${id}/metadata`}>{name}</Link>
	},
	{
		Header: i18n.t('agreements:table_columns.obligatory'),
		accessor: 'obligatory',
		Cell: ({
			row: {
				_original: { obligatory }
			}
		}) => (
			<span>
				{obligatory === 1
					? i18n.t('agreements:info.yes')
					: i18n.t('agreements:info.no')}
			</span>
		)
	},
	{
		Header: i18n.t('agreements:table_columns.type'),
		accessor: 'type'
	},
	{
		Header: i18n.t('agreements:table_columns.rank'),
		accessor: 'rank'
	},
	{
		Header: i18n.t('common:table_columns.system'),
		accessor: 'system'
	},
	{
		Header: i18n.t('agreements:table_columns.active'),
		accessor: 'active',
		width: 75,
		className: 'text-center ',
		Cell: ({
			row: {
				_original: { id, active }
			}
		}) => <ScheduleActivity id={id} isActive={active} />
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id, system_id }
			}
		}) => (
			<DeleteButton
				id={id}
				productSystem={system_id}
				onSelectItem={handleSelectItem}
				isReadOnly={isReadOnly}
			/>
		)
	}
];
