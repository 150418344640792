import React, { useState } from 'react';

// Import translation
import i18n from 'i18next';

// Import components
import message from 'antd/es/message';
import { Toggle } from 'components/utilities';
import { Modal } from 'antd';
import { Tooltip, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { SortableHandle } from 'react-sortable-hoc';

// renders cell with delete action, contains modal to confirm delete
const RenderCell = ({ content, data, deleteAsset }) => {
	const deleteUpload = () => {
		if (content.includes(data.url)) {
			// shows modal to prevent delete.
			const errorModal = () =>
				Modal.error({
					title: i18n.t(`common:delete`),
					content: `${i18n.t(
						`messages:modal.prevent_delete_of_upload_links`
					)} `,
					okText: i18n.t('common:buttons.ok')
				});
			errorModal();
		} else {
			Modal.confirm({
				title: i18n.t(`common:delete`),
				content: `${i18n.t(`messages:modal.delete_confirm_message`)}`,
				okText: i18n.t('common:buttons.ok'),
				cancelText: i18n.t('common:buttons.cancel'),
				onOk: () => deleteAsset(data?.id),
				onCancel() {
					message.info(i18n.t('messages:modal.delete_cancel_message'));
				}
			});
		}
	};
	return (
		<Toggle>
			{() => (
				<button
					type="button"
					className="btn btn-xs btn-primary"
					onClick={deleteUpload}
				>
					<i className="fa fa-trash" />
				</button>
			)}
		</Toggle>
	);
};

const ClickToCopy = ({ data }) => {
	const [copied, setCopied] = useState(null);
	const trigger = async (value) => {
		try {
			await navigator.clipboard.writeText(data);
			//delay the tooltip
			if (value === false) {
				setTimeout(() => {
					setCopied(value);
				}, 200);
			} else {
				setCopied(value);
			}
		} catch (err) {
			return err;
		}
	};

	return (
		<Tooltip
			title={i18n.t(`common:materials_table.copied_to_clipboard`)}
			trigger="click"
			onClick={() => trigger(true)}
			onMouseLeave={() => trigger(false)}
			open={copied}
		>
			<Button>
				<i className="fa fa-solid fa-copy" />
			</Button>
		</Tooltip>
	);
};

const DragHandle = SortableHandle(() => (
	<MenuOutlined
		style={{
			cursor: 'grab',
			color: '#999'
		}}
	/>
));

export const createColumns = ({ deleteAsset, metadata }) => {
	return [
		{
			title: '',
			dataIndex: 'sort',
			key: 'sort',
			width: 30,
			className: 'drag-visible',
			render: () => <DragHandle />
		},
		{
			title: i18n.t('common:materials_table.table_columns.file_name'),
			align: 'left',
			key: 'file_name',
			dataIndex: 'file_name',
			className: 'drag-visible'
		},
		{
			title: i18n.t('common:materials_table.table_columns.url'),
			dataIndex: 'url',
			key: 'url',
			render: (...args) => {
				const { 1: record } = args;
				return (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between'
						}}
					>
						<a href={record.url} target="_blank" rel="noopener noreferrer">
							{record.url}
						</a>
					</div>
				);
			}
		},
		{
			title: i18n.t('common:materials_table.table_columns.copy'),
			align: 'center',
			dataIndex: 'copy',
			key: 'copy',
			render: (...args) => {
				const { 1: record } = args;
				return <ClickToCopy data={record.url} />;
			}
		},
		{
			title: i18n.t('common:materials_table.table_columns.delete'),
			dataIndex: 'edit',
			key: 'edit',
			width: 100,
			align: 'center',
			render: (...args) => {
				const { 1: record } = args;

				return (
					<RenderCell
						content={metadata?.content}
						data={record}
						deleteAsset={deleteAsset}
					/>
				);
			}
		}
	];
};
