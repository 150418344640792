import i18n from 'i18next';

export const GEOLOCKS_FILTER_FIELDS = [
	{
		name: 'id',
		label: i18n.t('filters:fields.id'),
		type: 'number',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'type',
		label: i18n.t('filters:fields.type'),
		type: 'multi-select',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'ip_from',
		label: i18n.t('filters:fields.ip_from'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'ip_to',
		label: i18n.t('filters:fields.ip_to'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'description',
		label: i18n.t('filters:fields.description'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'created_at',
		label: i18n.t('filters:fields.created_at'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	},
	{
		name: 'updated_at',
		label: i18n.t('filters:fields.updated_at'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	}
];
