import React from 'react';
import { Form } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import validate
import validate from './validation';

// Import components
import Fields from './fields/Fields';
import { FormButtons } from 'components/common/buttons';
import { IboxContentTitle, Wraper } from 'components/common/layout';
import { Button } from 'reactstrap';

// Import hooks
import { useCognitoMetadata } from './useCognitoMetadata';

const CognitoMetadataUpload = () => {
	const { t } = useTranslation();

	const { onButtonDownloadClick, onSubmit } = useCognitoMetadata();

	return (
		<Wraper error={false}>
			<IboxContentTitle title={t('menu_stb:metadata')} className="d-block">
				<Button onClick={onButtonDownloadClick} className="mb-4">
					{t('common:download_file')}
				</Button>
				<div className="hr-line-solid">&nbsp;</div>
				<Form
					onSubmit={onSubmit}
					validate={validate}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields submitting={submitting} />

							<FormButtons
								isButtonDisabled={submitting}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			</IboxContentTitle>
		</Wraper>
	);
};

export default CognitoMetadataUpload;
