import i18n from 'i18next';

// Import helpers text
import { label_info, type_info, algorithm_info } from './helpers';

export const BASE_FIELDS = [
	{
		name: 'name',
		label: i18n.t('sections:fields.name'),
		type: 'text'
	},
	{
		name: 'label',
		label: i18n.t('sections:fields.label'),
		type: 'text',
		required: true,
		info: true,
		infoContent: label_info
	},
	{
		name: 'active',
		label: i18n.t('sections:fields.active'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'algorithm_id',
		label: i18n.t('sections:fields.algorithm_id.title'),
		type: 'select',
		required: true
	},
	{
		name: 'main_limit',
		label: i18n.t('sections:fields.main_limit'),
		type: 'number',
		required: true
	},
	{
		name: 'type',
		label: i18n.t('sections:fields.type'),
		type: 'select',
		required: true,
		info: true,
		infoContent: type_info
	},
	{
		name: 'is_adult',
		label: i18n.t('sections:fields.is_adult'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'algorithm_config',
		label: i18n.t('sections:fields.algorithm_config'),
		type: 'textarea',
		required: false,
		info: true,
		infoContent: algorithm_info,
		infoModalWidth: 700
	},
	{
		name: 'live_epg',
		label: i18n.t('sections:fields.live_epg'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'active_from_minute',
		label: i18n.t('sections:fields.active_from_minute'),
		type: 'time'
	},
	{
		name: 'active_to_minute',
		label: i18n.t('sections:fields.active_to_minute'),
		type: 'time'
	}
];
