import React from 'react';
import i18n from 'i18next';

// Import components
import DevicesSnForm from 'components/views/devices_sn/devices_sn_form/DevicesSnForm';

export const devicesSnTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <DevicesSnForm />
	}
];
