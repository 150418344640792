import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/common/loaders/Loader';

const IboxContentTitle = ({
	children,
	title = '',
	isLoaded = true,
	classNameTitle = '',
	classNameContent = ''
}) => (
	<div className="ibox">
		<div data-testid="ibox-title" className={`ibox-title ${classNameTitle}`}>
			<h5>{title}</h5>
		</div>
		<div
			data-testid="ibox-content"
			className={`ibox-content ${classNameContent} ${!isLoaded &&
				'sk-loading'}`}
		>
			<Loader data-testid="loader" isLoaded={isLoaded}>
				{children}
			</Loader>
		</div>
	</div>
);

IboxContentTitle.propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	title: PropTypes.string,
	classNameTitle: PropTypes.string,
	classNameContent: PropTypes.string,
	isLoaded: PropTypes.bool
};

export default IboxContentTitle;
