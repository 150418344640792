// Import translation
import i18n from 'i18next';

export default ({ group_name, limit }) => {
	const errors = {};

	// ------------------ Group_name ------------------
	if (!group_name) {
		errors.group_name = i18n.t('limits:validation.group_name_required');
	} else if (group_name.length < 2 || group_name.length > 200) {
		errors.group_name = i18n.t('limits:validation.group_name_length');
	}

	// ------------------ Limit ------------------
	if (!limit) {
		errors.limit = i18n.t('limits:validation.limit_required');
	}

	return errors;
};
