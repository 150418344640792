import * as types from '../types';

export const setRequestUrl = (url) => (dispatch) => {
	const converted_request_url = url
		.replace(/&length=[0-9]+/, '')
		.replace(/&start=[0-9]+/, '&start=0');

	dispatch({
		type: types.SET_REQUEST_URL,
		payload: { request_url: url, converted_request_url }
	});
};
