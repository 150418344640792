import { useTranslation } from 'react-i18next';

const useImagePreview = ({ url, isValid, error }) => {
	const { t } = useTranslation();

	const isCors = error === 'cors';
	const defaultConfig = { color: 'gray', text: null, link: null };

	const getConfig = () => {
		if (!url) return defaultConfig;
		if (isValid)
			return {
				color: 'green',
				text: t('common:inputs.imageUrl.open_in_new_window'),
				link: url
			};
		switch (error) {
			case 'cors':
				return {
					color: 'orange',
					text: t('common:inputs.imageUrl.cors_error'),
					link: url
				};
			case 'invalid':
				return {
					color: 'red',
					text: t('common:inputs.imageUrl.invalid_url'),
					link: null
				};
			case 'not_found':
				return {
					color: 'red',
					text: t('common:inputs.imageUrl.not_found'),
					link: null
				};
			default:
				return defaultConfig;
		}
	};

	return {
		isCors,
		config: getConfig()
	};
};

export default useImagePreview;
