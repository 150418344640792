import React from 'react';

// Import translation
import i18n from 'i18next';

// import components
import { IngesterDataModal } from 'components/views/vod/vod_encoding/components';
import { RepeatIngesterActionButton } from 'components/common/elements';

export const createColumns = ({ isReadOnly, refreshTableAction }) => [
	{
		Header: i18n.t('ingester:ingester_assets_items:table_columns.asset_uuid'),
		accessor: 'asset_uuid',
		sortable: false
	},
	{
		Header: i18n.t('ingester:ingester_assets_items:table_columns.type'),
		accessor: 'type',
		sortable: false,
		width: 120
	},
	{
		Header: i18n.t('ingester:ingester_assets_items:table_columns.status'),
		accessor: 'status',
		width: 120,
		sortable: false
	},
	{
		Header: i18n.t('ingester:ingester_assets_items:table_columns.updated_at'),
		accessor: 'updated_at',
		sortable: false
	},
	{
		sortable: false,
		width: 120,
		Cell: ({
			row: {
				_original: { data, type }
			}
		}) => <IngesterDataModal data={data} type={type} />
	},
	{
		Header: i18n.t('common:table_columns.action'),
		className: 'text-center',
		sortable: false,
		width: 120,
		Cell: ({
			row: {
				_original: { uuid }
			}
		}) =>
			!isReadOnly && (
				<RepeatIngesterActionButton
					url={`ingester/assets/items/${uuid}/reIngest`}
					refreshAction={refreshTableAction}
				/>
			)
	}
];
