import React from 'react';
import PropTypes from 'prop-types';

// Import helpers
import { renderSystemVisibilityFields } from 'components/utilities/form/form_render_system_fields/form_render_fields';

// Import utilites
import { renderFields } from 'components/utilities/form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import fields
import { BASE_FIELDS, VISIBLE_FIELDS } from './input_fields';

const Fields = ({ submitting }) => {
	const { t } = useTranslation();

	const systemVisibilityFields = renderSystemVisibilityFields();

	return (
		<>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}
			<h3>{t('common:form:field_groups.visible_in_platforms')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{VISIBLE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}
			{systemVisibilityFields && (
				<>
					<h3>{t('common:form:field_groups.visible_in')}</h3>
					<div className="hr-line-solid">&nbsp;</div>
					{systemVisibilityFields.map((item, index) =>
						renderFields({ item, index, submitting })
					)}
				</>
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
