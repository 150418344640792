import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

const renderForceIcons = (force) =>
	force ? <i className="fa fa-check" /> : <i className="fa fa-times" />;

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('software:table_columns.force'),
		accessor: 'force',
		width: 180,
		Cell: ({
			row: {
				_original: { force }
			}
		}) => renderForceIcons(force)
	},
	{
		Header: i18n.t('software:table_columns.start_date'),
		accessor: 'start_date',
		width: 180
	},
	{
		Header: i18n.t('software:table_columns.version_name'),
		accessor: 'version_name',
		Cell: ({
			row: {
				_original: { id, version_name }
			}
		}) => <Link to={`/panel/software/edit/${id}`}>{version_name}</Link>
	},
	{
		Header: i18n.t('software:table_columns.version_code'),
		accessor: 'version_code',
		width: 90
	},
	{
		Header: i18n.t('software:table_columns.sn_from'),
		accessor: 'sn_from',
		width: 90
	},
	{
		Header: i18n.t('software:table_columns.sn_to'),
		accessor: 'sn_to',
		width: 90
	},
	{
		Header: i18n.t('software:table_columns.package_id'),
		accessor: 'package_id'
	},
	{
		Header: i18n.t('software:table_columns.file'),
		accessor: 'file',
		width: 90,
		Cell: ({
			row: {
				_original: { file }
			}
		}) => (
			<a target="_blank" rel="noopener noreferrer" href={file}>
				{i18n.t('software:file_link')}
			</a>
		)
	},
	{
		Header: i18n.t('software:table_columns.platform'),
		accessor: 'platform',
		width: 200
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(id)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
