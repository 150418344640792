// Import helpers
import { convertErrors } from 'components/helpers/error';
import { capitalizeText } from 'components/helpers/convert';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications';

// Import translation
import i18n from 'i18next';

// Convert data from selected to select format
export const dataToSelect = (selected, type, idSelector, titleSelector) => {
	let selectedTabData = [];
	if (selected[type]) {
		selectedTabData = selected[type].map((item) => ({
			key: item[idSelector],
			label: item[titleSelector]
		}));
	}

	return selectedTabData;
};

// Convert data from select format to selected
export const dataToSelected = (value, data, idSelector) => {
	const newSelected = value.reduce((acc, { key }) => {
		const item = data.find((item) => item[idSelector] === key);

		return [...acc, item];
	}, []);

	return newSelected;
};

// render additional data next to vod title based on provided array
export const renderAdditionalLabelData = (array, item) =>
	array
		.map((tag) => {
			if (!!item[tag]) {
				return tag === 'payment_models' && item[tag].length === 0 //payment_models is an array and we don't want to return empty array
					? null
					: item[tag];
			} else return null;
		})
		.filter((item) => item !== null)
		.join(' | ');

// handle errors when submitting products in provisioning products table
export const handleErrors = (error) => {
	if (error.response?.data?.validator?.errors) {
		// Convert Errors
		const errors = convertErrors(error.response.data.validator.errors);
		// CreateNotification
		Object.entries(errors).forEach(([key, value]) => {
			notificationHandler(capitalizeText(key), value, 'error', 8);
		});
		// Throw new errors
		return { ...errors };
	} else {
		notificationHandler(
			i18n.t('common:error'),
			i18n.t('common:errors.common_error_message'),
			'error',
			8
		);
	}
};
