import Modal from 'antd/es/modal';
import message from 'antd/es/message';

// Import translation
import i18n from 'i18next';

export const messageConfirmDeleteHandler = (text, id, callback, resources) =>
	Modal['confirm']({
		title: i18n.t('common:delete'),
		content: `${i18n.t('messages:modal.delete_confirm_message')} ${i18n.t(
			`names:notification_names.${text}`
		)}?`,
		onOk: () => callback(id, resources),
		onCancel() {
			message.info(
				`${i18n.t('messages:modal.delete_cancel_message')} ${i18n.t(
					`names:notification_names.${text}`
				)}`
			);
		},
		cancelText: i18n.t('common:buttons.cancel')
	});

export const messageConfirmHandler = (
	title,
	text,
	id,
	callback,
	type = 'warning',
	callbackResources
) =>
	Modal[type]({
		title,
		content: text,
		onOk: () => callback(id, callbackResources),
		onCancel() {
			message.info(i18n.t('messages:messages.operation_canceled'));
		},
		cancelText: i18n.t('common:buttons.cancel')
	});

export const messageConfirmDeactivateHandler = (userId, deviceId, callback) =>
	Modal['confirm']({
		title: i18n.t('common:deactivate'),
		content: deviceId
			? `${i18n.t('messages:modal.deactivate_confirm_message')}`
			: `${i18n.t('messages:modal.deactivate_all_confirm_message')}`,
		onOk: () => callback(userId, deviceId),
		onCancel() {
			message.info(`${i18n.t('messages:modal.deactivate_cancel_message')}`);
		},
		cancelText: i18n.t('common:buttons.cancel')
	});

export const messageConfirmMultipleIdHandler = ({
	callback,
	title,
	content,
	type = 'confirm',
	ids
}) =>
	Modal[type]({
		title,
		content,
		onOk: () => callback(...ids)
	});

export const messageConfirmClearHandler = (userId, callback) =>
	Modal['confirm']({
		title: i18n.t('common:refresh'),
		content: `${i18n.t(
			'messages:modal.refresh_subscriber_permissions_message'
		)}`,
		onOk: () => callback(userId),
		cancelText: i18n.t('common:buttons.cancel')
	});
