import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	fetchIngesterAssetsItemsVodDetails,
	refreshIngesterAssetsItems
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './ingester_assets_items_vod_details_columns';

// Import components
import ReactTable from 'react-table';
import { Empty } from 'antd';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';
function IngesterAssetsItemsVodDetails({
	vodId,
	resources,
	fetchIngesterAssetsItemsVodDetails,
	refreshIngesterAssetsItems
}) {
	const { t } = useTranslation();

	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	useEffect(() => {
		fetchIngesterAssetsItemsVodDetails(vodId);
		// eslint-disable-next-line
	}, [JSON.stringify(resources)]);

	if (!resources.length) {
		return <Empty description={t('common:no_data')} />;
	}

	return (
		<ReactTable
			data={resources}
			columns={createColumns({
				isReadOnly,
				refreshTableAction: refreshIngesterAssetsItems
			})}
			pageSize={resources.length}
			showPagination={false}
		/>
	);
}

IngesterAssetsItemsVodDetails.propTypes = {
	vodId: PropTypes.string.isRequired,
	resources: PropTypes.array.isRequired,
	fetchIngesterAssetsItemsVodDetails: PropTypes.func.isRequired,
	refreshIngesterAssetsItems: PropTypes.func
};

const mapStateToProps = ({
	ingester: {
		assets_items_table: { data }
	}
}) => ({
	resources: data
});

export default connect(mapStateToProps, {
	fetchIngesterAssetsItemsVodDetails,
	refreshIngesterAssetsItems
})(IngesterAssetsItemsVodDetails);
