import React from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'antd';
import { Col, Row } from 'reactstrap';

// Import hooks
import useDeeplink from './context/useDeeplink';

// Import components
import ProductSelect from 'components/common/product_select/ProductSelect';
import Label from 'components/common/inputs/Label';

const CatchupForm = () => {
	const { t } = useTranslation();
	const {
		catchupDate,
		setCatchupDate,
		selectedProduct,
		setSelectedProduct,
		productSelectRef,
		setCatchupProgram
	} = useDeeplink();

	return (
		<>
			<Row sm={12}>
				<Label
					columnWidth={4}
					content={t(`product_select:inputs.media_type_channel`)}
				/>
				<Col sm={8}>
					<ProductSelect
						ref={productSelectRef}
						productType="channel"
						disabled={false}
						onSelect={setSelectedProduct}
						placeholder={t(`product_select:inputs.media_type_channel`)}
					/>
				</Col>
			</Row>
			<div className="hr-line-dashed my-4">&nbsp;</div>
			<Row sm={12}>
				<Label
					columnWidth={4}
					content={t(`product_select:inputs.media_type_catchup_date`)}
				/>
				<Col sm={8}>
					<DatePicker
						disabled={!selectedProduct}
						value={catchupDate}
						onChange={setCatchupDate}
						style={{ width: '100%' }}
						placeholder={t(`product_select:inputs.media_type_catchup_date`)}
					/>
				</Col>
			</Row>
			<div className="hr-line-dashed my-4">&nbsp;</div>
			<Row sm={12}>
				<Label
					columnWidth={4}
					content={t(`product_select:inputs.media_type_catchup_title`)}
				/>
				<Col sm={8}>
					<ProductSelect
						productType="catchup"
						disabled={!selectedProduct || !catchupDate}
						onSelect={setCatchupProgram}
						placeholder={t(`product_select:inputs.media_type_catchup_title`)}
						catchupDate={catchupDate}
						channelExternalId={selectedProduct?.external_uid}
					/>
				</Col>
			</Row>
		</>
	);
};

export default CatchupForm;
