import React from 'react';
import { Field } from 'react-final-form';
import { Select } from 'antd';
import { Col } from 'reactstrap';
import i18n from 'i18next';

// Import components
import { InputLabel, MultiSelectLabel } from 'components/common/inputs';
import Button from 'reactstrap/lib/Button';

export const getUrl = ({ url, column }) => {
	return `${url}/list?order[0][column]=${column}&order[0][dir]=asc&start=0&length=30`;
};

export const CUSTOM_PROPERTIES_FIELDS = [
	{
		name: 'href',
		type: 'custom-names',
		required: true
	},
	{
		name: 'value',
		type: 'text',
		required: false
	}
];

export const renderCustomPropertyFields = ({
	item: { name, type, required },
	index,
	submitting,
	selectedProperties,
	handleSelect,
	properties,
	propertiesValues
}) => {
	const fieldName = `${name}_${index}`;

	switch (type) {
		case 'custom-names':
			return (
				<Field
					key={`${fieldName}-${type}`}
					disabled={submitting}
					name={fieldName}
					type="multi-select"
					mode=""
					placeholder={i18n.t('menu_stb:fields.href')}
					label={i18n.t('menu_stb:fields.href')}
					id={fieldName}
					required={required}
					component={MultiSelectLabel}
					showSearch={true}
					onSelect={(item) => handleSelect(item, index)}
				>
					{properties.map(({ value, name }, index) => (
						<Select.Option
							key={index}
							value={value}
							disabled={Object.values(selectedProperties).includes(value)}
						>
							{name}
						</Select.Option>
					))}
				</Field>
			);

		case 'text':
			return (
				<Field
					key={`${fieldName}-${type}`}
					disabled={submitting}
					name={fieldName}
					type="multi-select"
					mode="tags"
					maxTagCount={1}
					placeholder={i18n.t('menu_stb:fields.value')}
					label={i18n.t('menu_stb:fields.value')}
					id={fieldName}
					showSearch={true}
					required={required}
					component={MultiSelectLabel}
				>
					{propertiesValues.map(({ value, name }, index) => (
						<Select.Option key={index} value={value}>
							{name}
						</Select.Option>
					))}
				</Field>
			);

		default:
			return (
				<Field
					key={`${fieldName}-${type}`}
					disabled={submitting}
					name={fieldName}
					type="text"
					placeholder={i18n.t('menu_stb:fields.value')}
					label={i18n.t('menu_stb:fields.value')}
					id={fieldName}
					required={false}
					component={InputLabel}
					style={{ height: '32px' }}
				/>
			);
	}
};

const shouldRowRender = ({ values, index }) => values[`href_${index - 1}`];

export const renderCustomPropertyRow = ({
	array,
	index,
	values,
	handleDelete,
	...rest
}) => {
	switch (index) {
		case 0:
			return array.map((item) => (
				<Col xs={6} key={`${item.name}-${index}`} className="d-flex">
					<div className="w-100">
						{renderCustomPropertyFields({
							...rest,
							item,
							required: true,
							index
						})}
					</div>
					<div className="ml-4">
						{item.type === 'text' && (
							<Button onClick={() => handleDelete(index)}>
								<i className="fa fa-trash" aria-hidden="true" />
							</Button>
						)}
					</div>
				</Col>
			));

		default:
			return (
				shouldRowRender({ values, index }) &&
				array.map((item) => (
					<Col xs={6} key={`${item.name}-${index}`} className="d-flex">
						<div className="w-100">
							{renderCustomPropertyFields({ ...rest, item, index })}
						</div>
						<div className="ml-4">
							{item.type === 'text' && (
								<Button onClick={() => handleDelete(index)}>
									<i className="fa fa-trash" aria-hidden="true" />
								</Button>
							)}
						</div>
					</Col>
				))
			);
	}
};
