import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Select } from 'antd';

// Import components
import Label from 'components/common/inputs/Label';

// Import hooks
import useDeeplink from './context/useDeeplink';

// Import helpers
import { MEDIA_TYPES_VALUES } from 'components/helpers';

const { Option } = Select;

const MediaTypeSelect = () => {
	const { t } = useTranslation();
	const { productSelectRef, productType, setProductType } = useDeeplink();

	return (
		<Row sm={12}>
			<Label
				columnWidth={4}
				content={t('product_select:inputs.media_type_select')}
			/>
			<Col sm={8}>
				<Select
					onChange={(value) => {
						if (productType !== value && productSelectRef.current) {
							productSelectRef.current.clear();
						}
						setProductType(value);
					}}
					allowClear
					onClear={() => {
						if (productSelectRef.current) {
							productSelectRef.current.clear();
						}
					}}
					style={{ width: '100%' }}
					value={productType}
				>
					{MEDIA_TYPES_VALUES.map(({ name, value }) => (
						<Option key={value} value={value}>
							{name}
						</Option>
					))}
				</Select>
			</Col>
		</Row>
	);
};

export default MediaTypeSelect;
