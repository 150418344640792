import i18n from 'i18n';

// Import select options
import {
	select_packet_types,
	PACKETS_CONFIGURATOR,
	PACKETS_PROVIDERS
} from 'components/helpers/variables';

export const selectResources = {
	type: select_packet_types.filter(({ name }) => PACKETS_CONFIGURATOR[name]),
	external_provider: PACKETS_PROVIDERS
};

export const getFields = ({ isEdit, packetType, isExternalProvider }) => [
	{
		name: 'type',
		type: 'select',
		label: i18n.t('main:fields.type'),
		required: true
	},
	{
		name: 'is_bundle',
		type: 'checkbox',
		label: i18n.t('main:fields.is_bundle'),
		isVisible: packetType === PACKETS_CONFIGURATOR.MAIN
	},
	{
		name: 'external_ids',
		type: 'text',
		label: i18n.t('main:fields.external_ids'),
		required: true
	},
	{
		name: 'external_provider',
		type: 'select',
		label: i18n.t('main:fields.external_provider'),
		disabled: !isEdit
	},
	{
		name: 'external_id',
		type: 'text',
		label: i18n.t('main:fields.external_id'),
		disabled: !isEdit,
		required: isExternalProvider,
		isVisible: isExternalProvider
	},
	{
		name: 'title',
		type: 'text',
		label: i18n.t('main:fields.title'),
		required: true
	},
	{
		name: 'subtitle',
		type: 'text',
		label: i18n.t('main:fields.subtitle'),
		required: false
	},
	{
		name: 'description',
		type: 'textarea',
		label: i18n.t('main:fields.description'),
		required: true
	},
	{
		name: 'short_description',
		type: 'tiny-text-editor',
		label: i18n.t('main:fields.short_description'),
		required: true
	},
	{
		name: 'order',
		type: 'number',
		label: i18n.t('main:fields.order'),
		required: true
	},
	{
		name: 'url',
		type: 'text',
		label: i18n.t('main:fields.url'),
		required: false
	},
	{
		name: 'active',
		type: 'checkbox',
		label: i18n.t('main:fields.active'),
		required: false
	},
	{
		name: 'is_recommended',
		type: 'checkbox',
		label: i18n.t('main:fields.is_recommended'),
		required: false
	},
	{
		name: 'created_at',
		type: 'text',
		label: i18n.t('main:fields.created_at'),
		required: false,
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'updated_at',
		type: 'text',
		label: i18n.t('main:fields.updated_at'),
		required: false,
		disabled: true,
		isVisible: !isEdit
	}
];
