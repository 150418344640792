import { useState } from 'react';
import services from 'services/services';
import { useForm } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

const useInputLabelButtonAsyncLogic = (url, fieldName) => {
	const form = useForm();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const { t } = useTranslation();

	const handleClick = async () => {
		try {
			setLoading(true);

			const { data } = await services.get(url);

			setLoading(false);

			// change value of code field and set generated code data
			form.change(fieldName, data.code);
		} catch (error) {
			setError(t('common:errors.data_retrieve'));
			setLoading(false);
		}
	};

	return {
		loading,
		buttonError: error,
		handleClick
	};
};

export default useInputLabelButtonAsyncLogic;
