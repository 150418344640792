import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';
import { TABLE_LENGTH } from 'components/helpers/table';

// Import variables
import { MARKETING_CAMPAIGN_TARGETS } from 'components/helpers';

// ******************** FETCH MARKETING CAMPAIGN TO EDIT ********************
export const fetchMarketingCampaignTargets = (options, itemId) => async (
	dispatch,
	getState
) => {
	try {
		dispatch({
			type: types.FETCH_MARKETING_CAMPAIGN_TARGETS_LOADING,
			payload: true
		});

		const {
			marketing_campaigns: {
				targets: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `${MARKETING_CAMPAIGN_TARGETS}?${filterUrlQuery}${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex,
			true
		)}&columns[0][search][value]=${itemId}`;

		const { data } = await services.get(url);
		const pages = Math.ceil(data.data.recordsFiltered / TABLE_LENGTH);
		setRequestUrl(url)(dispatch);

		dispatch({
			type: types.FETCH_MARKETING_CAMPAIGN_TARGETS_SUCCESS,
			payload: { ...data.data, pages, page: options.page, startIndex }
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_MARKETING_CAMPAIGN_TARGETS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET SEARCH QUERY ********************
export const setMarketingCampaignTargetsQuery = (filter_params) => ({
	type: types.SET_MARKETING_CAMPAIGN_TARGETS_QUERY,
	payload: filter_params
});
