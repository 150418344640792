import React from 'react';
import i18n from 'i18next';
import { Link } from 'react-router-dom';

// Import components
import DeleteButton from 'components/utilities/delete_button/DeleteButton';
import PaymentTags from './PaymentTags';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('payment_templates:table_columns.name'),
		accessor: 'name',
		width: 350,
		Cell: ({
			row: {
				_original: { id, name }
			}
		}) => (
			<Link to={`/panel/payment_templates/edit/${id}/metadata`}>{name}</Link>
		)
	},
	{
		Header: i18n.t('payment_templates:table_columns.payment_methods'),
		accessor: 'payment_methods',
		sortable: false,
		Cell: ({
			row: {
				_original: { payment_methods }
			}
		}) => <PaymentTags payment_methods={payment_methods} />
	},
	{
		Header: i18n.t('payment_templates:table_columns.edit'),
		accessor: 'edit',
		width: 70,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) => (
			<Link
				to={`/panel/payment_templates/edit/${id}/metadata`}
				className="btn btn-xs btn-primary"
				disabled={isReadOnly}
			>
				<i className="fa fa-gear" />
			</Link>
		)
	},
	{
		Header: i18n.t('payment_templates:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id, system }
			}
		}) => (
			<DeleteButton
				id={id}
				productSystem={system}
				onSelectItem={handleSelectItem}
				isReadOnly={isReadOnly}
			/>
		)
	}
];
