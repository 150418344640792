import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';
import { generatePath } from 'react-router-dom';

// Import helpers
import {
	checkFetchErrors,
	queryParams,
	getColumnId,
	getColumnParamName
} from '../helpers_actions/convert_helpers';
import {
	MARKETING_CAMPAIGNS_TABLE,
	MARKETING_CAMPAIGN_DELETE,
	MARKETING_CAMPAIGNS_STATUS_VALUES
} from 'components/helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

const { ALL } = MARKETING_CAMPAIGNS_STATUS_VALUES;

// ******************** FETCH MARKETING_CAMPAIGNS - TABLE DATA ********************
export const fetchMarketingCampaigns = (options) => async (
	dispatch,
	getState
) => {
	try {
		dispatch({
			type: types.FETCH_MARKETING_CAMPAIGNS_LOADING,
			payload: true
		});
		const {
			marketing_campaigns: {
				table: {
					columns,
					options: {
						filters: { filterUrlQuery, status },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const params = {};

		if (status !== ALL) {
			const index = getColumnId(columns)('status');
			const paramName = getColumnParamName(index);
			params[paramName] = status;
		}

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `${MARKETING_CAMPAIGNS_TABLE}?${filterUrlQuery}${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex,
			true
		)}`;

		const { data } = await services.get(url, {
			params
		});

		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		dispatch({
			type: types.FETCH_MARKETING_CAMPAIGNS_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_MARKETING_CAMPAIGNS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE MARKETING_CAMPAIGN ********************
export const deleteMarketingCampaign = (id) => async (dispatch) => {
	try {
		dispatch({
			type: types.DELETE_MARKETING_CAMPAIGN_LOADING,
			payload: true
		});
		await services.delete(generatePath(MARKETING_CAMPAIGN_DELETE, { id }));

		dispatch({
			type: types.DELETE_MARKETING_CAMPAIGN_SUCCESS,
			payload: true
		});
	} catch (error) {
		dispatch({
			type: types.DELETE_MARKETING_CAMPAIGN_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET SEARCH QUERY ********************
export const setMarketingCampaignQuery = (filter_params) => ({
	type: types.SET_MARKETING_CAMPAIGNS_SEARCH_QUERY,
	payload: filter_params
});

// ******************** SET FILTERS ********************
export const setMarketingCampaignStatus = (status) => ({
	type: types.SET_MARKETING_CAMPAIGN_STATUS,
	payload: status
});
