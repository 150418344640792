import i18n from 'i18next';

export const EPG_GLOBAL_FILTER_FIELDS = [
	{
		name: 'title',
		label: i18n.t('filters:fields.title'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'since',
		label: i18n.t('filters:fields.since_field'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	},
	{
		name: 'till',
		label: i18n.t('filters:fields.till_field'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	},
	{
		name: 'catchup_recording_state',
		label: i18n.t('filters:fields.catchup_recording_state'),
		type: 'multi-select',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'catchup_recording_required',
		label: i18n.t('filters:fields.catchup_recording_required'),
		type: 'custom-names',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'concurrent_catchup_asset_id',
		label: i18n.t('filters:fields.concurrent_catchup_asset_id'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	}
];
