import i18n from 'i18next';

export const BANNERS_FILTER_FIELDS = [
	{
		name: 'title',
		label: i18n.t('filters:fields.title'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'group',
		label: i18n.t('filters:fields.group'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'url_pc',
		label: i18n.t('filters:fields.url_pc'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'url_mobile',
		label: i18n.t('filters:fields.url_mobile'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	}
];
