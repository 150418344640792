import React from 'react';
import { number, string } from 'prop-types';
import getProgressProperties from './helpers';

// Import components
import { Progress } from 'reactstrap';

function ProgressBar({ progress, status }) {
	const { color, progressValue, label } = getProgressProperties({
		progress,
		status
	});

	return (
		<Progress
			color={color}
			value={progressValue}
			className="table-progress-bar"
		>
			{label}
		</Progress>
	);
}

ProgressBar.defaultProps = {
	status: ''
};

ProgressBar.propTypes = {
	progress: number,
	status: string
};

export default ProgressBar;
