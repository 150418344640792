import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { Form } from 'react-final-form';
import { Trans } from 'react-i18next';
import { Spin } from 'antd';

// Import components
import { IboxContentTitle, Wraper } from 'components/common/layout';

// Import utilities
import { renderFields } from 'components/utilities/form';
import { notificationHandler } from 'components/utilities/notifications';

// Import helpers
import { FIELDS } from './variables';
import { validate } from './validate';

// Import query
import ip from 'store/query/ip';

const IpVerification = () => {
	const { t } = useTranslation();
	const [ipValidate, result] = ip.useLazyVerificationQuery();

	const handleFormSubmit = (values) => {
		ipValidate(values)
			.unwrap()
			.catch(() => {
				notificationHandler(
					t('common:error'),
					t('common:errors.data_retrieve'),
					'error'
				);
			});
	};

	return (
		<Wraper lg="12">
			<IboxContentTitle
				title={t('ip_verification:title')}
				classNameTitle="align-items-center"
			>
				<Form
					onSubmit={handleFormSubmit}
					className="align-items-end"
					validate={validate}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit} className="d-flex flex-column">
							{FIELDS.map((item, index) =>
								renderFields({ item, index, submitting })
							)}
							<Button
								className="m-b-md align-self-end"
								disabled={submitting}
								type="submit"
							>
								{t('common:buttons.submit')}
							</Button>
						</form>
					)}
				/>

				{result.isFetching && (
					<div className="text-center">
						<Spin size="large" />
					</div>
				)}

				<div className="d-flex flex-column justify-content-between mt-5">
					{result.currentData && (
						<>
							<p className="h4 pb-4">
								<Trans
									i18nKey="ip_verification:data_title"
									values={result.originalArgs}
								/>
							</p>
							<pre>{JSON.stringify(result.currentData, null, 4)}</pre>
						</>
					)}
				</div>
			</IboxContentTitle>
		</Wraper>
	);
};

export default IpVerification;
