import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import useSubscribersDevicesSnForm from './useSubscribersDevicesSnForm';

// Import components
import Fields from './fields/Fields';
import { ModalFormButtons } from 'components/common/buttons';

// Validate
import validate from './validation';

const SubscribersDevicesSnForm = ({ subscriberId, setModalVisibility }) => {
	const { handleSubmit } = useSubscribersDevicesSnForm({
		subscriberId,
		setModalVisibility
	});

	return (
		<Form onSubmit={handleSubmit} validate={validate}>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit}>
					<Fields />
					<ModalFormButtons
						isButtonDisabled={submitting}
						modalCloseAction={setModalVisibility}
					/>
				</form>
			)}
		</Form>
	);
};

SubscribersDevicesSnForm.propTypes = {
	subscriberId: PropTypes.string.isRequired,
	setModalVisibility: PropTypes.func.isRequired
};

export default SubscribersDevicesSnForm;
