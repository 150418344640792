import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	setChannelsPacketsAssignedQuery,
	fetchChannelsPacketsAssigned
} from 'store/actions';

// Import columns
import { createColumns } from './channels_packets_assigned_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';

const ChannelsPacketsAssigned = ({
	error,
	setChannelsPacketsAssignedQuery,
	fetchChannelsPacketsAssigned,
	resources,
	match: {
		params: { id }
	}
}) => {
	return (
		<PanelPageTemplate
			itemId={id}
			title="Lista"
			error={error}
			type="packets_assigned"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchChannelsPacketsAssigned}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setChannelsPacketsAssignedQuery}
					value={resources.options.filters.query}
				/>
			</div>
		</PanelPageTemplate>
	);
};

ChannelsPacketsAssigned.propTypes = {
	error: PropTypes.bool.isRequired,
	setChannelsPacketsAssignedQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchChannelsPacketsAssigned: PropTypes.func.isRequired
};

const mapStateToProps = ({ channels }) => {
	return {
		error: channels.packets_assigned.error,
		resources: channels.packets_assigned
	};
};

export default compose(
	connect(mapStateToProps, {
		setChannelsPacketsAssignedQuery,
		fetchChannelsPacketsAssigned
	}),
	withRouter
)(ChannelsPacketsAssigned);
