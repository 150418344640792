import { useMemo, useState, useImperativeHandle } from 'react';
import { generatePath } from 'react-router-dom';
import services from 'services/services';
import debounce from 'lodash.debounce';
import moment from 'moment';

// Import helpers
import {
	PRODUCT_TYPES,
	URL_PARAMS,
	VOD_URL_PARAMS,
	CATCHUP_PARAMS
} from './helpers';
import { MEDIA_TYPES } from 'components/helpers';

const { VOD, SEASON, SERIES, EPISODE, CATCHUP } = MEDIA_TYPES;

const useProductSelect = ({
	productType,
	onSelect,
	ref,
	catchupDate,
	channelExternalId
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [productData, setProductData] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState(null);

	const apiUrl = useMemo(() => {
		const api = PRODUCT_TYPES.find((item) => item.type === productType)?.apiUrl;
		switch (productType) {
			case VOD:
			case SERIES:
			case EPISODE:
			case SEASON:
				return `${api}${VOD_URL_PARAMS}`;
			case CATCHUP:
				return `${api}${CATCHUP_PARAMS}`;
			default:
				return `${api}${URL_PARAMS}`;
		}
	}, [productType]);

	useImperativeHandle(ref, () => ({
		clear: () => {
			setSelectedProduct(null);
			setProductData([]);
		}
	}));

	const fetchProductList = async (value) => {
		if (value.length < 2) {
			setIsLoading(false);
			setProductData([]);
			return;
		}
		try {
			setIsLoading(true);
			const url = generatePath(apiUrl, {
				value,
				productType,
				date: moment(catchupDate).format('YYYY-MM-DD'),
				external_id: channelExternalId
			});

			const {
				data: { data }
			} = await services.get(url);

			setProductData(data);
		} catch (error) {
			setProductData([]);
		} finally {
			setIsLoading(false);
		}
	};

	const clearSelectState = () => setSelectedProduct(null);

	const handleSelect = (value) => {
		setSelectedProduct(value);
		onSelect(productData.find(({ uuid }) => uuid === value));
	};

	return {
		productData,
		isLoading,
		handleSelect,
		clearSelectState,
		selectedProduct,
		fetchProductList: debounce(fetchProductList, 800)
	};
};

export default useProductSelect;
