import { useLocation } from 'react-router';

// Import hooks
import useSystem from './useSystem';
import useProductSystem from './useProductSystem';

// Import helpers
import {
	READ_ONLY_LOCATIONS_FOR_SUBSYSTEMS,
	checkUserCanEditProduct
} from 'components/helpers/system_permissions';
import {
	getLocationType,
	getSubLocationType
} from 'components/helpers/convert';
import { CORE_PATHS } from 'components/helpers/variables';

const { DOCUMENTS, AGREEMENTS, METADATA } = CORE_PATHS;

const useAuthSystemCheck = () => {
	const { pathname } = useLocation();
	const { currentSystem, isCurrentSystemMain } = useSystem();

	const { productSystem } = useProductSystem();

	const locationType = getLocationType(pathname);

	// Form of documents and agreements version doesn't have sublocation
	const isDocumentsFormLocation =
		locationType === DOCUMENTS && pathname.includes('documents/edit');
	const isAgreementsVersionFormLocation =
		locationType === AGREEMENTS && pathname.includes('versions/edit');

	// Sublocation depends on selected tab of selected view
	// for example: metadata, images, availability etc.
	const subLocationType =
		isDocumentsFormLocation || isAgreementsVersionFormLocation
			? METADATA
			: getSubLocationType(pathname);

	const readOnlyLocations = READ_ONLY_LOCATIONS_FOR_SUBSYSTEMS[locationType];

	// We can always create new products except channels and vods
	const isCreatePath = pathname.includes('create');
	// We can edit products of our system
	const isProductSystemHasCurrentSystem = productSystem === currentSystem;
	// Check if current sublocation is read only, when we have active other systems except main
	const isSubLocationReadOnly = readOnlyLocations?.some(
		(location) => location === subLocationType
	);

	const isReadOnly =
		isCurrentSystemMain || isCreatePath || isProductSystemHasCurrentSystem
			? false
			: isSubLocationReadOnly;

	return {
		isReadOnlyForCurrentSystem: isReadOnly,
		checkUserCanEditProduct,
		locationType,
		subLocationType,
		currentSystem,
		isCurrentSystemMain
	};
};

export default useAuthSystemCheck;
