import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import services from 'services/services';
import { useLocation } from 'react-router-dom';
import {
	fetchEpgGlobal,
	setEpgGlobalQuery,
	updateEpgGlobalBlacklist,
	dictionaryCatchupRecordingStates,
	createFiltersSuggestions
} from 'store/actions';

// Import api
import {
	GET_CATCHUP_RECORDING_DATA_API,
	CATCHUP_RECORDING_DATA
} from 'components/helpers/api';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';
import useFilterLogic from 'hooks/useFilterLogic';

// Import columns
import { createColumns } from './epg_global_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { Ibox, IboxContentBase, Wraper } from 'components/common/layout';
import { ReactServerSideTableCheckbox } from 'components/utilities/table';
import FilterButton from 'components/common/buttons/FilterButton';
import useEpgGlobalInitialFilters from './hooks/useEpgGlobalInitialFilters';

// Import variables
import { CATCHUP_RECORDING_REQUIRED_OPTIONS } from './variables';

const EpgGlobal = ({
	error,
	resources,
	fetchEpgGlobal,
	setEpgGlobalQuery,
	updateEpgGlobalBlacklist,
	dictionaryCatchupRecordingStates,
	createFiltersSuggestions,
	filters
}) => {
	const { state: linkState } = useLocation();

	useEpgGlobalInitialFilters({ linkState });

	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	const fetchCatchupRecordingData = (programUuid) => {
		const url = GET_CATCHUP_RECORDING_DATA_API.replace(
			':programUuid',
			programUuid
		);

		return services.get(url);
	};

	const filterActions = {
		dictionaryCatchupRecordingStates
	};

	useFilterLogic({
		filterActions,
		createFiltersSuggestions,
		filters: {
			...filters,
			catchup_recording_required: CATCHUP_RECORDING_REQUIRED_OPTIONS
		}
	});

	const { columns } = resources;

	return (
		<React.Fragment>
			<Wraper lg="12" error={error}>
				<Ibox error={false} className="m-b-lg">
					<IboxContentBase>
						<React.Fragment>
							<div className="d-flex justify-content-end">
								<Search
									setQueryValue={setEpgGlobalQuery}
									value={resources.options.filters.query}
								/>
								<FilterButton tableColumns={columns} />
							</div>
							<ReactServerSideTableCheckbox
								resources={resources}
								columns={createColumns({
									queryKey: CATCHUP_RECORDING_DATA,
									handleFetchData: fetchCatchupRecordingData
								})}
								fetchResourcesAction={fetchEpgGlobal}
								updateCheckedItems={updateEpgGlobalBlacklist}
								keyField="uuid"
								keyType="blackout"
								notificationUpdateSuccessTxt={i18n.t(
									'epg:notification.update_blacklist_success'
								)}
								notificationUpdateErrorTxt={i18n.t(
									'epg:notification.update_blacklist_error'
								)}
								controlButtonsTitle={i18n.t('epg:control_buttons.title')}
								clearButtonTxt={i18n.t('epg:control_buttons.clear_btn')}
								isReadOnly={isReadOnly}
								tableType="epg_global"
							/>
						</React.Fragment>
					</IboxContentBase>
				</Ibox>
			</Wraper>
		</React.Fragment>
	);
};

EpgGlobal.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchEpgGlobal: PropTypes.func.isRequired,
	setEpgGlobalQuery: PropTypes.func.isRequired,
	external_uid: PropTypes.number,
	dictionaryCatchupRecordingStates: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired
};

const mapStateToProps = ({ epg_global, dictionary }) => ({
	error: epg_global.error,
	resources: epg_global,
	filters: {
		catchup_recording_state: dictionary.catchup_recording_state
	}
});

export default compose(
	connect(mapStateToProps, {
		fetchEpgGlobal,
		setEpgGlobalQuery,
		updateEpgGlobalBlacklist,
		dictionaryCatchupRecordingStates,
		createFiltersSuggestions
	}),
	withRouter
)(EpgGlobal);
