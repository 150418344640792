import React from 'react';
import PropTypes from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { getImgUrl } from 'components/utilities/products_select/helpers';

const ListItem = ({ value, type, img, handleRemoveItem, id }) => {
	const { t } = useTranslation();
	const handleRemove = () => handleRemoveItem(id);

	const imgUrl = getImgUrl(img);

	return (
		<li className="cards-list__li">
			<span className="item">
				{imgUrl && (
					<span className="item__img">
						<img className="img-fluid" src={imgUrl} alt={value} />
					</span>
				)}
				<button className="item__delete" onClick={handleRemove} type="button">
					<span className="btn-txt">
						{t('common:delete')}{' '}
						<i className="fa fa-trash-o" aria-hidden="true"></i>
					</span>
					<span className="btn-icon">
						<i className="fa fa-times" aria-hidden="true"></i>
					</span>
				</button>
				<span className="item__title">{value}</span>
				<span className="item__category">{type}</span>
			</span>
		</li>
	);
};

ListItem.propTypes = {
	value: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	handleRemoveItem: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
	img: PropTypes.object
};

export default ListItem;
