// ******************** DEVICES DATA TABLE ********************
export const FETCH_DEVICES_LOADING = 'FETCH_DEVICES_LOADING';
export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';
export const FETCH_DEVICES_ERROR = 'FETCH_DEVICES_ERROR';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_DEVICES_TABLE_SORTING = 'SAVE_DEVICES_TABLE_SORTING';

// ******************** SET SEARCH QUERY ********************
export const SET_DEVICES_SEARCH_QUERY = 'SET_DEVICES_SEARCH_QUERY';

// ******************** SET DEVICES PLATFORM ********************
export const SET_DEVICES_PLATFORM = 'SET_DEVICES_PLATFORM';
