import { useHistory } from 'react-router-dom';

// Import components
import { messageConfirmDeleteHandler } from 'components/utilities/message';

// Import helpers
import { SET_ORDINAL_DOWN, SET_ORDINAL_UP, setOrdinal } from './helpers';

const useMenuItemButtons = ({
	id,
	deleteCognitoCategory,
	resources,
	ordinal,
	setPreviousScrollPosition,
	fetchCognitoTree,
	sectionId,
	system
}) => {
	const history = useHistory();
	const handleChangeOrdinalItem = ({ type }) => {
		let changedOrdinal;

		switch (type) {
			case SET_ORDINAL_DOWN:
				// This is not logical, but the backend requires this notation
				changedOrdinal = ordinal + 2;
				break;

			case SET_ORDINAL_UP:
				changedOrdinal = ordinal - 1;
				break;

			default:
				changedOrdinal = ordinal;
				break;
		}

		setOrdinal({
			uuid: id,
			ordinal: changedOrdinal,
			fetchCognitoTree
		});

		setPreviousScrollPosition(window.pageYOffset);
	};

	const onChangeOrdinalUp = () =>
		handleChangeOrdinalItem({
			type: SET_ORDINAL_UP
		});

	const onChangeOrdinalDown = () =>
		handleChangeOrdinalItem({
			type: SET_ORDINAL_DOWN
		});

	const handleDeleteMenuItemClick = () =>
		messageConfirmDeleteHandler(
			'menu_stb',
			id,
			deleteCognitoCategory,
			resources
		);

	const navigateToAdd = () =>
		history.push({
			pathname: '/panel/menu_stb/create/metadata',
			state: { parentId: id, system }
		});
	const navigateToEdit = () =>
		history.push(`/panel/menu_stb/edit/${id}/metadata`);
	const navigateToSection = () =>
		history.push(`/panel/sections/edit/${sectionId}/preview`);

	return {
		onChangeOrdinalUp,
		onChangeOrdinalDown,
		onDeleteMenuItemClick: handleDeleteMenuItemClick,
		navigateToAdd,
		navigateToEdit,
		navigateToSection
	};
};

export default useMenuItemButtons;
