// Import variables
import { FETCH_SYSTEMS_API } from 'components/helpers/api';
import { LAST_SYSTEM_DEPTH } from 'components/helpers/variables';

// Import services
import services from 'services/services';

export const getSystemsNames = (systems) => systems.map(({ name }) => name);

export const fetchAllSystems = () => services.get(FETCH_SYSTEMS_API);

export const checkIsLastSystemDepth = ({ currentSystem, systems }) => {
	if (systems?.length) {
		const { system_tree_depth } = systems.find(
			({ name }) => name === currentSystem
		);

		// Systems have only three levels (0, 1, 2)
		// Last system depth is 2
		const isLastSystemDepth = system_tree_depth === LAST_SYSTEM_DEPTH;

		return isLastSystemDepth;
	}

	return false;
};
