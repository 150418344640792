import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import routes
import ROUTES_PATHS from './helpers/route_paths';

// Import helpers
import { generateRedirectPath } from './helpers/helpers';

const Routes = () => {
	const { roles: userRoles } = useSelector((state) => state.auth.authenticate);
	return (
		<Switch>
			<Redirect exact from="/panel" to={generateRedirectPath(userRoles)} />
			{ROUTES_PATHS.map((route, index) => (
				<Route key={index} path={route.path} render={() => route.component} />
			))}
			<Route render={() => <Redirect to={generateRedirectPath(userRoles)} />} />
		</Switch>
	);
};

export default Routes;
