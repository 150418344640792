import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const PageHeading = ({
	colHeading = 'col-sm-12',
	colError = 'col-sm-12',
	errors = false,
	breadcrumbs,
	title,
	data_testid = 'panel-heading'
}) => {
	// Check if there is an fetch error
	const checkFetchErrors = (errors) => {
		return (
			errors && (
				<Alert
					showIcon
					message="Error"
					type="error"
					description="An error has occurred and the data can not be retrieved"
					className="m-t-sm"
				/>
			)
		);
	};

	const renderBreadcrumbs = () =>
		breadcrumbs.map((breadcrumb, index) => {
			if (index !== breadcrumbs.length - 1) {
				return <BreadcrumbItem key={index}>{breadcrumb}</BreadcrumbItem>;
			} else {
				return (
					<BreadcrumbItem active key={index}>
						{breadcrumb}
					</BreadcrumbItem>
				);
			}
		});

	return (
		<React.Fragment>
			<div className="row wrapper border-bottom white-bg page-heading">
				<div className={colHeading}>
					<h2 data-testid={data_testid} className="m-t-sm">
						{title}
					</h2>
					<Breadcrumb>{renderBreadcrumbs()}</Breadcrumb>
				</div>
			</div>
			{/* Check fetch errors */}
			<div className={colError}>{checkFetchErrors(errors)}</div>
		</React.Fragment>
	);
};

PageHeading.propTypes = {
	errors: PropTypes.bool,
	breadcrumbs: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired,
	colHeading: PropTypes.string,
	colError: PropTypes.string,
	data_testid: PropTypes.string
};

export default PageHeading;
