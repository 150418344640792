import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// ******************** FETCH EPG GLOBAL - TABLE DATA ********************
export const fetchEpgGlobal = (options) => async (dispatch, getState) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_EPG_GLOBAL_LOADING,
			payload: true
		});

		const {
			epg_global: {
				columns,
				options: {
					filters: { filterUrlQuery },
					startIndex: startTableIndex,
					page
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		// Change in normal queries order requires remove & at the end of filter url
		const trimmedFilterUrlQuery = filterUrlQuery.slice(0, -1);

		const url = `/channel/programs/list?${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex,
			true
		)}&${trimmedFilterUrlQuery}`;

		const { data } = await services.get(url);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_EPG_GLOBAL_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_EPG_GLOBAL_ERROR,
			payload: true
		});
	}
};

// ******************** SEND EPG GLOBAL TO BLACKLIST ********************
export const updateEpgGlobalBlacklist = (data, apiSlug) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.SEND_EPG_GLOBAL_TO_BLACKLIST_LOADING,
			payload: true
		});

		await services.post(`/channel/programs/blackout/${apiSlug}`, data);
		// Dispatch an action with data
		dispatch({
			type: types.SEND_EPG_GLOBAL_TO_BLACKLIST_SUCCESS,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.SEND_EPG_GLOBAL_TO_BLACKLIST_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET SEARCH QUERY ********************
export const setEpgGlobalQuery = (query) => ({
	type: types.SET_EPG_GLOBAL_SEARCH_QUERY,
	payload: query
});
