import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import components
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('operators:table_columns.email'),
		accessor: 'email',
		Cell: ({
			row: {
				_original: { id, email }
			}
		}) => <Link to={`/panel/operators/edit/${id}/metadata`}>{email}</Link>
	},
	{
		Header: i18n.t('operators:table_columns.last_login_at'),
		accessor: 'last_login_at',
		width: 200
	},
	{
		Header: i18n.t('operators:table_columns.active'),
		accessor: 'active',
		width: 75,
		className: 'text-center',
		Cell: ({
			row: {
				_original: { active }
			}
		}) => <ActiveIcon active={active} />
	},
	{
		Header: i18n.t('operators:table_columns.name'),
		accessor: 'firstName'
	},
	{
		Header: i18n.t('operators:table_columns.last_name'),
		accessor: 'lastName'
	},
	{
		Header: i18n.t('operators:table_columns.role'),
		accessor: 'roles',
		sortable: false
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id, products }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(id)}
					className="btn btn-xs btn-primary"
					disabled={products === 0 ? true : false}
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
