import moment from 'moment';

// convert Date to  yyyy-mm-dd format
export const convertDateFormat = (date) =>
	`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

// Convert names to ids
export const convertNameToIDs = (resources, values) =>
	values.map((item) => resources.find((resource) => resource.name === item).id);

// Capitalize letter
export const capitalizeText = (text) =>
	text.charAt(0).toUpperCase() + text.slice(1).replace('_', ' ');

// Convert files
export const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

// Convert from grosz to zloty
export const convertPrice = (value) => {
	return value ? (parseFloat(value) * 0.01).toFixed(2) : 0;
};

// Convert from zloty to grosz
export const convertPriceToCents = (value) => {
	// parseInt is used because we need to send a number to API not a string
	return value ? parseInt((parseFloat(value) * 100).toFixed(0), 10) : 0;
};

// Sublocation depends on selected tab of selected view or selected view
// for example: metadata, images, availability etc. or vod, channels etc.
export const getSubLocationType = (pathname) => {
	const pathnameArray = pathname.split('/');
	const subLocationType = pathnameArray[pathnameArray.length - 1];
	return subLocationType;
};

// get location type based on url. Returns string with type
export const getLocationType = (pathname) => {
	const pathnameArray = pathname.split('/');
	const locationType = pathnameArray[2];
	return locationType;
};

// get location subtype based on url. Returns string with type
export const getLocationSubtype = (pathname) => {
	const pathnameArray = pathname.split('/');
	const locationType = pathnameArray[3];
	return locationType;
};

// check if value is an int
export const checkIsInt = (value) => Number.isInteger(Number(value));

// convert external_ids to table
export const convertPacketExternalIds = (value) => [`${value}`];

/**
 * Convert array of data to array of objects with name and value keys
 * @param {array} array - array of data to convert
 * @param {string} nameKey - name of name key (value for name)
 * @param {string} valueKey - name of value key (value for value)
 * @returns {array} - [{name: 'item[nameKey]', value: item[valueKey]}, ...]
 */
export const convertArrayToValuePairsByKeyNames = (
	array,
	nameKey,
	valueKey,
	labelKey
) => {
	return array?.map((item) => {
		const name = item[nameKey] || item[0];
		const value = item[valueKey] || item[1];
		const label = item[labelKey] || '';

		const nameValue = label ? `${name} (${label})` : name;

		return { name: nameValue, value };
	});
};

export const convertObjectToValuePairs = (data) =>
	Object.values(data).map((value) => ({ value, name: value }));

export const convertBytesToHumanReadable = (bytes, decimalPoints = 1) => {
	if (Math.abs(bytes) < 1000) {
		return bytes + ' B';
	}

	const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	let u = -1;
	const r = 10 ** decimalPoints;

	do {
		bytes /= 1000;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= 1000 && u < units.length - 1);

	return bytes.toFixed(decimalPoints) + ' ' + units[u];
};

export const convertTimeToMinutes = (time) => {
	const date = moment(time, 'HH:mm');
	return date.hours() * 60 + date.minutes();
};

export const convertMinutesToTime = (minutes) => {
	if (!minutes) return null;

	const time = moment()
		.startOf('day')
		.add(minutes, 'minutes');

	return time.format('HH:mm');
};
