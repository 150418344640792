import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

export const createColumns = () => [
	{
		Header: i18n.t('menu_stb:table_columns.name'),
		accessor: 'Name',
		Cell: ({
			row: {
				_original: { id, Name }
			}
		}) => (
			<Link
				to={{
					pathname: `/panel/menu_stb/edit/${id}/metadata`,
					state: { parent: { name: Name, id } }
				}}
			>
				{Name}
			</Link>
		)
	},

	{
		Header: i18n.t('menu_stb:table_columns.ordinal'),
		accessor: 'Ordinal'
	},
	{
		Header: i18n.t('menu_stb:table_columns.adult'),
		accessor: 'IsAdult',
		Cell: ({
			row: {
				_original: { IsAdult }
			}
		}) => <span>{IsAdult ? i18n.t('common:yes') : i18n.t('common:no')}</span>
	},

	{
		Header: i18n.t('menu_stb:table_columns.created_at'),
		accessor: 'created_at'
	},
	{
		Header: i18n.t('menu_stb:table_columns.updated_at'),
		accessor: 'updated_at'
	}
];
