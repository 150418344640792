import React from 'react';
import PropTypes from 'prop-types';

const IboxTitle = ({ children, title = '', className = '' }) => (
    <div className={`ibox-title ${className}`}>
        <h5>{title}</h5>
        {children}
    </div>
);

IboxTitle.propTypes = {
    children: PropTypes.object,
    className: PropTypes.string,
    title: PropTypes.string
};

export default IboxTitle;
