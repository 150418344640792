const convertSectionId = (currentValue, initialValue) => {
	if (!currentValue) return null;

	const isSectionIdNotNumber = isNaN(currentValue);
	const section_id = isSectionIdNotNumber ? initialValue : currentValue;

	return section_id;
};

export const convertFormValues = ({
	values,
	customProperties,
	initialSectionId
}) => {
	const array = new Array(customProperties.length - 1).fill('');

	const {
		section_id,
		active,
		parent_uuid,
		IsAdult,
		name_translation_eng,
		ShortSynopsis
	} = values;
	const sectionId = convertSectionId(section_id, initialSectionId);

	const customProperty = array
		.map((item, i) => {
			const href = values[`href_${i}`];
			const value = values[`value_${i}`] && values[`value_${i}`][0];

			const data = { href };

			if (value) {
				data.Value = value;
			}

			return data;
		})
		.filter(({ href }) => href);

	const isAdult = section_id ? null : IsAdult;
	const translations = name_translation_eng?.length
		? [
				{
					language: 'en',
					translations: {
						Name: name_translation_eng
					}
				}
		  ]
		: [];

	return {
		...values,
		CustomProperties: {
			CustomProperty: customProperty
		},
		active: !active,
		parent_uuid: parent_uuid || null,
		section_id: sectionId || null,
		ShortSynopsis: ShortSynopsis || null,
		IsAdult: isAdult,
		translations
	};
};

export const getIsEmptyText = ({ formValues, isInitialData = false }) => {
	const { show_empty, is_empty } = formValues;

	// show_empty value will not have time to change as getIsEmptyText function
	// is called before form state update. We need to asses opposite value in this case
	const showEmptyValue = isInitialData ? show_empty : !show_empty;

	if (showEmptyValue) {
		return 'menu_stb:fields.values.not_applicable';
	}
	return is_empty ? 'common:yes' : 'common:no';
};
