import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import MassChangesContextWrapper from 'components/utilities/mass_changes/MassChangesContextWrapper';

const MassChangesAddTo = () => {
	const { t } = useTranslation();

	return (
		<MassChangesContextWrapper
			title={t('mass_changes:action_add_to.title')}
			productsTitle={t('mass_changes:action_add_to.productsTitle')}
			typeSelectLabel={t('mass_changes:typeSelectLabel')}
			productSelectLabel={t('mass_changes:productSelectLabel')}
			actionType="add"
			notificationTxt={t('mass_changes:action_add_to.success')}
			buttonSubmitText={t('common:buttons.assign')}
			redirectPath="/panel/vod"
			notificationTitle="successfuly_add"
		/>
	);
};

export default MassChangesAddTo;
