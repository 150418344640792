import { Spin, Alert } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAuthUserDetails } from 'store/actions';

export const MainTemplate = ({ children }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { isDetailsError, isDetailsLoading, signOut } = useSelector(
		({ auth }) => auth
	);

	useEffect(() => {
		fetchAuthUserDetails()(dispatch);
		// eslint-disable-next-line
	}, []);

	const isLoaded = !isDetailsLoading && !signOut.loading;

	if (isDetailsError) {
		return (
			<Alert
				showIcon
				type="error"
				description={t('auth:details_error')}
				className="animated fadeIn"
			/>
		);
	}

	return (
		<div id="wrapper">
			{isLoaded ? children : <Spin size="large" className="main__loading" />}
		</div>
	);
};
