import { combineReducers } from 'redux';
// Import reducers
import table from './barker_channels_panel_reducer';
import form from './barker_channels_form_reducer';
import products from './barker_channel_products_reducer';

export default combineReducers({
	table,
	form,
	products
});
