import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	cover: {
		data: [],
		isLoaded: false,
		error: false
	},
	cover_small: {
		data: [],
		isLoaded: false,
		error: false
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** FETCH BANNER'S COVER FILES ******************
			case types.FETCH_BANNER_COVER_FILES_LOADING:
				draft.cover.data = [];
				draft.cover.isLoaded = false;
				draft.cover.error = false;
				return;

			case types.FETCH_BANNER_COVER_FILES_SUCCESS:
				draft.cover.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.cover.isLoaded = true;
				return;

			case types.FETCH_BANNER_COVER_FILES_ERROR:
				draft.cover.isLoaded = true;
				draft.cover.error = true;
				return;

			// ****************** FETCH BANNER'S SMALL COVER FILES ******************
			case types.FETCH_BANNER_COVER_SMALL_FILES_LOADING:
				draft.cover_small.data = [];
				draft.cover_small.isLoaded = false;
				draft.cover_small.error = false;
				return;

			case types.FETCH_BANNER_COVER_SMALL_FILES_SUCCESS:
				draft.cover_small.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.cover_small.isLoaded = true;
				return;

			case types.FETCH_BANNER_COVER_SMALL_FILES_ERROR:
				draft.cover_small.isLoaded = true;
				draft.cover_small.error = true;
				return;

			default:
				return state;
		}
	});
