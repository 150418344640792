// Import translation
import i18n from 'i18next';

export default ({ name, rank, type }) => {
	const errors = {};

	// ------------------ Name ------------------
	if (!name) {
		errors.name = i18n.t('agreements:validation.name_required');
	} else if (name.length < 2 || name.length > 200) {
		errors.name = i18n.t('agreements:validation.name_length');
	}
	// ------------------ Rank ------------------
	if (!rank) {
		errors.rank = i18n.t('agreements:validation.rank_required');
	} else if (rank < 1) {
		errors.rank = i18n.t('agreements:validation.rank_length');
	}
	// ------------------ Type ------------------
	if (!type) {
		errors.type = i18n.t('agreements:validation.type_required');
	}
	return errors;
};
