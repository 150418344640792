import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';
import { generatePath } from 'react-router-dom';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';
import { TAGS_LIST_API, TAG_DETAIL_API } from 'components/helpers/api';

// ******************** FETCH MAIN - TABLE DATA ********************
export const fetchTags = (options) => async (dispatch, getState) => {
	try {
		dispatch({
			type: types.FETCH_TAGS_LOADING,
			payload: true
		});

		const {
			main: {
				table: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `${TAGS_LIST_API}?${filterUrlQuery}${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex
		)}`;

		const { data } = await services.get(url);

		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		dispatch({
			type: types.FETCH_TAGS_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_TAGS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE TAG ITEM ********************
export const deleteTag = (id) => async (dispatch) => {
	try {
		dispatch({
			type: types.DELETE_TAG_ITEM_LOADING,
			payload: true
		});

		const url = generatePath(TAG_DETAIL_API, { id });

		await services.delete(url);

		dispatch({
			type: types.DELETE_TAG_ITEM_SUCCESS,
			payload: true
		});
	} catch (error) {
		dispatch({
			type: types.DELETE_TAG_ITEM_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET SEARCH QUERY ********************
export const setTagsQuery = (query) => ({
	type: types.SET_TAGS_SEARCH_QUERY,
	payload: query
});
