import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

// Import context
import {
	MaterialsProvider,
	MaterialsContext
} from 'components/utilities/table/materials_table/materials_context/MaterialsContext';

// import components
import MaterialsHeader from './MaterialsHeader';

// Import helpers
import { locale } from 'components/helpers/table';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

const MaterialsTable = ({
	columns,
	resources,
	updateMaterials,
	updateMaterialsError,
	deleteMaterials,
	deleteMaterialsError,
	showHeader,
	metaData
}) => {
	// check if user have rights to create and edit forms
	const { canEditVideo } = useAuthCheck();
	const materialsHeader = () => (
		<MaterialsHeader resources={resources} canEditVideo={canEditVideo} />
	);

	return (
		<MaterialsProvider
			resources={resources}
			metaData={metaData}
			updateMaterials={updateMaterials}
			updateMaterialsError={updateMaterialsError}
			deleteMaterials={deleteMaterials}
			deleteMaterialsError={deleteMaterialsError}
		>
			<MaterialsContext.Consumer>
				{({ deleteAsset, editAsset, metaData }) => (
					<React.Fragment>
						{resources.length !== 0 && (
							<Table
								rowKey={(record) => record.id}
								dataSource={resources.assets}
								columns={columns(
									deleteAsset,
									resources.type,
									editAsset,
									canEditVideo,
									metaData
								)}
								title={showHeader && materialsHeader}
								bordered
								pagination={false}
								size="small"
								className="m-b-lg material-table"
								locale={locale}
							/>
						)}
					</React.Fragment>
				)}
			</MaterialsContext.Consumer>
		</MaterialsProvider>
	);
};

MaterialsTable.propTypes = {
	columns: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	updateMaterials: PropTypes.func,
	updateMaterialsError: PropTypes.func,
	deleteMaterials: PropTypes.func,
	deleteMaterialsError: PropTypes.func,
	showHeader: PropTypes.bool,
	metaData: PropTypes.object
};

MaterialsTable.defaultProps = {
	showHeader: true
};

export default MaterialsTable;
