import services from 'services/services';
import * as types from '../types';

// Import actions
import { setRequestUrl } from 'store/actions';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ************** FETCH AGREEMENTS VERSIONS - TABLE DATA ***************
export const fetchAgreementsVersions = (options, itemId) => async (
	dispatch
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_AGREEMENTS_VERSIONS_LOADING,
			payload: true
		});

		const url = `/agreements/${itemId}/versions`;

		const { data } = await services.get(url);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// // Dispatch an action with data
		dispatch({
			type: types.FETCH_AGREEMENTS_VERSIONS_SUCCESS,
			payload: { ...data, pages }
		});

		// Save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_AGREEMENTS_VERSIONS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE AGREEMENTS VERSION ********************
export const deleteAgreementsVersion = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DELETE_AGREEMENTS_VERSION_LOADING,
			payload: true
		});

		await services.delete(`/agreements/versions/${id}`);
		// Dispatch an action with data
		dispatch({
			type: types.DELETE_AGREEMENTS_VERSION_SUCCESS,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DELETE_AGREEMENTS_VERSION_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** ACTIVATE AGREEMENTS VERSION ********************
export const activateAgreementsVersion = (id, resources) => async (
	dispatch
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.ACTIVATE_AGREEMENTS_VERSION_LOADING,
			payload: true
		});

		await services.post(`/agreements/versions/${id}/activate`, resources);
		// Dispatch an action with data
		dispatch({
			type: types.ACTIVATE_AGREEMENTS_VERSION_SUCCESS,
			payload: { success: true, id }
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.ACTIVATE_AGREEMENTS_VERSION_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
