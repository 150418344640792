import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import {
	LinkOutlined,
	LoadingOutlined,
	ExclamationCircleOutlined
} from '@ant-design/icons';

// Import hooks
import useImagePreview from './useImagePreview';

const ImagePreview = ({ url, isValid, isLoading, error }) => {
	const { isCors, config } = useImagePreview({ url, isValid, error });
	if (isLoading) return <LoadingOutlined spin />;

	return (
		<Tooltip placement="bottom" title={config.text}>
			<a
				href={config.link}
				disabled={!config.link}
				target="_blank"
				rel="noopener noreferrer"
			>
				{isCors ? (
					<ExclamationCircleOutlined style={{ color: 'orange' }} />
				) : (
					<LinkOutlined style={{ color: config.color }} />
				)}
			</a>
		</Tooltip>
	);
};

ImagePreview.propTypes = {
	url: PropTypes.string,
	isValid: PropTypes.bool,
	error: PropTypes.string,
	isLoading: PropTypes.bool
};

export default ImagePreview;
