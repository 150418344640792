import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

//Import components
import DeleteButton from 'components/utilities/delete_button/DeleteButton';
import CategoriesMergeCell from './categories_merge_cell/CategoriesMergeCell';

export const createColumns = ({ handleSelectItem, type, isReadOnly }) => {
	const columns = [
		{
			Header: i18n.t('categories:table_columns.order'),
			accessor: 'order',
			width: 100
		},
		{
			Header: i18n.t('categories:table_columns.category'),
			accessor: 'name',
			Cell: ({
				row: {
					_original: { id, name }
				}
			}) => <Link to={`/panel/categories/edit/${id}/metadata`}>{name}</Link>
		},
		{
			Header: i18n.t('categories:table_columns.slug'),
			accessor: 'slug'
		},
		{
			Header: i18n.t('categories:table_columns.visible'),
			accessor: 'public',
			sortable: false,
			Cell: ({
				row: {
					_original: { public: visible }
				}
			}) => <span>{visible ? i18n.t('common:yes') : i18n.t('common:no')}</span>
		},
		{
			Header: i18n.t('common:table_columns.system'),
			minWidth: 150,
			accessor: 'system'
		},
		{
			Header: i18n.t('categories:table_columns.children'),
			accessor: 'children',
			sortable: false,
			Cell: ({
				row: {
					_original: { children }
				}
			}) =>
				children ? (
					<span>{children.map(({ name }) => name).join(', ')}</span>
				) : (
					<span>---</span>
				)
		},
		{
			Header: i18n.t('common:table_columns.merge'),
			accessor: 'merge',
			width: 60,
			className: 'text-center',
			sortable: false,
			Cell: ({
				row: {
					_original: { id, name }
				}
			}) => <CategoriesMergeCell id={id} name={name} isReadOnly={isReadOnly} />
		}
	];

	const deleteColumn = {
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id, system_id }
			}
		}) => (
			<DeleteButton
				id={id}
				productSystem={system_id}
				onSelectItem={handleSelectItem}
				isReadOnly={isReadOnly}
			/>
		)
	};

	if (type === 1) {
		columns.push(deleteColumn);
	}

	return columns;
};
