import React from 'react';
import useVodEncoding from './useVodEncoding';

// Import components
import { IboxContentTitle, Wraper } from 'components/common/layout';
import { Button } from 'reactstrap';

import {
	IngesterAssetsVodDetails,
	IngesterEncodingVodDetails,
	IngesterHistoryVodDetails,
	IngesterAssetsItemsVodDetails,
	AdiCode
} from './components';

// Import translation
import { useTranslation } from 'react-i18next';

/**
 * Functional component that returns formatted XML ADI code of encoded VOD
 * and details about ingester assets and encoding of specific VOD
 */
const VodEncoding = () => {
	const { t } = useTranslation();

	const {
		adiLoading,
		adiError,
		assetsLoading,
		assetsError,
		encodingLoading,
		encodingError,
		historyLoading,
		historyError,
		assetsItemsLoading,
		assetsItemsError,
		refresh,
		vodId
	} = useVodEncoding();

	return (
		<>
			<div className="d-flex justify-content-end">
				<Button onClick={refresh}>
					{<i className="fa fa-refresh m-r-sm" />}
					{t('common:buttons.refresh')}
				</Button>
			</div>
			<Wraper lg="12" error={assetsError}>
				<IboxContentTitle
					title={t('vod:encoding.ingester_assets')}
					isLoaded={!assetsLoading}
				>
					<IngesterAssetsVodDetails vodId={vodId} />
				</IboxContentTitle>
			</Wraper>
			<Wraper lg="12" error={assetsItemsError}>
				<IboxContentTitle
					title={t('vod:encoding.ingester_assets_items')}
					isLoaded={!assetsItemsLoading}
				>
					<IngesterAssetsItemsVodDetails vodId={vodId} />
				</IboxContentTitle>
			</Wraper>
			<Wraper lg="12" error={encodingError}>
				<IboxContentTitle
					title={t('vod:encoding.ingester_encoding')}
					isLoaded={!encodingLoading}
				>
					<IngesterEncodingVodDetails vodId={vodId} />
				</IboxContentTitle>
			</Wraper>
			<Wraper lg="12" error={historyError}>
				<IboxContentTitle
					title={t('vod:encoding.ingester_history')}
					isLoaded={!historyLoading}
				>
					<IngesterHistoryVodDetails vodId={vodId} />
				</IboxContentTitle>
			</Wraper>
			<Wraper lg="12" error={adiError}>
				<IboxContentTitle title={t('vod:encoding.adi')} isLoaded={!adiLoading}>
					<AdiCode />
				</IboxContentTitle>
			</Wraper>
		</>
	);
};

export default VodEncoding;
