import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	fetchPaymentTemplates,
	deletePaymentTemplate,
	setPaymentTemplateQuery
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './payment_templates_columns';

// Import utilities
import { Search } from 'components/utilities/search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';

const PaymentTemplates = ({
	error,
	fetchPaymentTemplates,
	deletePaymentTemplate,
	setPaymentTemplateQuery,
	resources
}) => {
	const { t } = useTranslation();

	return (
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="payment_templates"
			addLinkText={t('payment_templates:buttons.add_template')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchPaymentTemplates}
			deleteItemAction={deletePaymentTemplate}
			notificationDeleteSuccessTxt={t('payment_templates:info.delete_success')}
			notificationDeleteErrorTxt={t('payment_templates:info.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setPaymentTemplateQuery}
					value={resources.options.filters.query}
				/>
			</div>
		</PanelPageTemplate>
	);
};

PaymentTemplates.propTypes = {
	error: PropTypes.bool.isRequired,
	setPaymentTemplateQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchPaymentTemplates: PropTypes.func.isRequired,
	deletePaymentTemplate: PropTypes.func.isRequired
};

const mapStateToProps = ({ payment_templates }) => {
	return {
		error: payment_templates.table.error,
		resources: payment_templates.table
	};
};

export default connect(mapStateToProps, {
	fetchPaymentTemplates,
	deletePaymentTemplate,
	setPaymentTemplateQuery
})(PaymentTemplates);
