// ******************** ACTIVITYS DATA TABLE ********************
export const FETCH_ACTIVITIES_LOADING = 'FETCH_ACTIVITIES_LOADING';
export const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
export const FETCH_ACTIVITIES_ERROR = 'FETCH_ACTIVITIES_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_ACTIVITY_SEARCH_QUERY = 'SET_ACTIVITY_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_ACTIVITY_TABLE_SORTING = 'SAVE_ACTIVITY_TABLE_SORTING';

// ******************** ACTIVITY ********************
export const FETCH_ACTIVITY_LOADING = 'FETCH_ACTIVITY_LOADING';
export const FETCH_ACTIVITY_SUCCESS = 'FETCH_ACTIVITY_SUCCESS';
export const FETCH_ACTIVITY_ERROR = 'FETCH_ACTIVITY_ERROR';
