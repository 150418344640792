import React from 'react';
import useProviderStatistics from './useProviderStatistics';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { Link } from 'react-router-dom';

// Import variables
import { VOD_STATUS_VALUES } from 'components/helpers/variables';

const { ALL } = VOD_STATUS_VALUES;

const ProviderStatistics = () => {
	const { t } = useTranslation();
	const { providerStatisticsData } = useProviderStatistics();

	return (
		<div className="statistics__wrapper">
			<h2>{t('statistics:titles.providers')}</h2>
			<table className="statistics__table">
				<tbody>
					{providerStatisticsData.map(
						({ count, provider, provider_ids }, index) => {
							return (
								<tr key={`${provider}-${index}`}>
									<td>
										<Link
											to={{
												pathname: '/panel/vod',
												state: {
													vodFilters: {
														filterUrlQuery: `columns[19][search][value]=${provider_ids}&`,
														filterFormValues: {
															provider_id: provider_ids
														},
														filterStatus: ALL
													}
												}
											}}
										>
											{provider ?? t('common:none')}
										</Link>
									</td>
									<td>{count}</td>
								</tr>
							);
						}
					)}
				</tbody>
			</table>
		</div>
	);
};

export default ProviderStatistics;

// return (
// 	<div
// 		key={`${provider}-${index}`}
// 		className={`statistics__providers-grid`}
// 	>
// 		<Link
// 			to={{
// 				pathname: '/panel/vod',
// 				state: {
// 					providerStatistics: {
// 						providerIds: provider_ids
// 					}
// 				}
// 			}}
// 		>
// 			{provider}
// 		</Link>
// 		{count}
// 	</div>
// );
