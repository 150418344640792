// Import translation
import i18n from 'i18next';
import moment from 'moment';

// Import helpers
import {
	switchInfo,
	PERCENTAGE,
	INHERITED,
	ALL_USERS,
	ALL_PRODUCTS,
	AUTO,
	CODE,
	NEW_USERS
} from 'components/helpers/promotion_helpers/helpers';

export const defaultFieldValues = {
	description: null,
	active: false,
	promotions_period: null,
	promotion_type: PERCENTAGE,
	promotion_value: 0,
	since: null,
	till: null,
	users_specification: ALL_USERS,
	products_specification: ALL_PRODUCTS,
	trigger: AUTO,
	code: '',
	limit: null,
	is_coupon: false,
	entry_condition: false,
	entry_condition_products_specification: INHERITED,
	active_obox: false,
	active_ott: false,
	active_tvonline: false,
	grace_days_period: null,
	promo_days_period: null,
	is_packet: false
};

export const BASE_FIELDS = ({ isEdit, currentValues }) => {
	const hasEntryCondition = !!currentValues.entry_condition;
	const isForNewUsers = currentValues.users_specification === NEW_USERS;
	const hasCode = currentValues.trigger === CODE;
	const hasPromotionalPeriod = !!currentValues.is_packet;

	return [
		{
			name: 'name',
			label: i18n.t('promotions:form:fields.name'),
			type: 'text',
			required: true
		},
		{
			name: 'description',
			label: i18n.t('promotions:form:fields.description'),
			type: 'textarea',
			required: false
		},
		{
			name: 'active',
			label: i18n.t('promotions:form:fields.active'),
			type: 'checkbox',
			required: false
		},
		{
			// Promotion period is field that handles since and till dates in single date range picker
			name: 'promotion_period',
			label: i18n.t('promotions:form:fields.promotion_period'),
			placeholder: [
				i18n.t('promotions:form:placeholder.promotion_period_since'),
				i18n.t('promotions:form:placeholder.promotion_period_till')
			],
			type: 'range',
			required: true,
			showTime: {
				defaultValue: [
					moment('00:00:00', 'HH:mm:ss'),
					moment('23:59:59', 'HH:mm:ss')
				]
			},
			rangeFormat: 'YYYY-MM-DD HH:mm:ss'
		},
		{
			name: 'promotion_type',
			label: i18n.t('promotions:form:fields.promotion_type'),
			type: 'select',
			required: false,
			fieldInfo: i18n.t(`${switchInfo(currentValues['promotion_type'])}`)
		},
		{
			name: 'promotion_value',
			label: i18n.t('promotions:form:fields.promotion_value'),
			type: 'number',
			required: false,
			step: '.01'
		},
		{
			name: 'users_specification',
			label: i18n.t('promotions:form:fields.users_specification'),
			type: 'select',
			required: false,
			fieldInfo: i18n.t(`${switchInfo(currentValues['users_specification'])}`)
		},
		{
			name: 'grace_days_period',
			label: i18n.t('promotions:form:fields.grace_days'),
			type: 'number',
			fieldInfo: i18n.t(`promotions:form:fields_info.grace_period`),
			required: isForNewUsers,
			isVisible: isForNewUsers
		},
		{
			name: 'products_specification',
			label: i18n.t('promotions:form:fields.products_specification'),
			type: 'select',
			required: false,
			fieldInfo: i18n.t(
				`${switchInfo(currentValues['products_specification'])}`
			)
		},
		{
			name: 'trigger',
			label: i18n.t('promotions:form:fields.trigger'),
			type: 'select',
			required: false,
			fieldInfo: i18n.t(`${switchInfo(currentValues['trigger'])}`)
		},
		{
			name: 'code',
			label: i18n.t('promotions:form:fields.code'),
			type: 'text-w-button-async',
			required: false,
			isVisible: hasCode,
			url: '/promotions/code/generate',
			buttonText: i18n.t('promotions:buttons.generate_code')
		},
		{
			name: 'limit',
			label: i18n.t('promotions:form:fields.limit'),
			type: 'number',
			required: false,
			fieldInfo: i18n.t('promotions:form:fields_info.limit')
		},
		{
			name: 'created_at',
			label: i18n.t('promotions:form:fields.created_at'),
			type: 'text',
			required: false,
			disabled: true,
			isVisible: !isEdit
		},
		{
			name: 'updated_at',
			label: i18n.t('promotions:form:fields.updated_at'),
			type: 'text',
			required: false,
			disabled: true,
			isVisible: !isEdit
		},
		{
			name: 'entry_condition',
			label: i18n.t('promotions:form:fields.entry_condition'),
			type: 'checkbox',
			required: false
		},
		{
			name: 'entry_condition_purchases_threshold',
			label: i18n.t(
				'promotions:form:fields.entry_condition_purchases_threshold'
			),
			type: 'number',
			required: hasEntryCondition,
			isVisible: hasEntryCondition,
			fieldInfo: i18n.t(
				'promotions:form:fields_info.entry_condition_purchases_threshold'
			)
		},
		{
			name: 'entry_condition_products_specification',
			label: i18n.t(
				'promotions:form:fields.entry_condition_products_specification'
			),
			type: 'select',
			required: false,
			isVisible: hasEntryCondition,
			fieldInfo: i18n.t(
				`${switchInfo(
					currentValues['entry_condition_products_specification'],
					'entry_condition'
				)}`
			)
		},
		{
			name: 'entry_condition_in_ttl',
			label: i18n.t('promotions:form:fields.entry_condition_in_ttl'),
			type: 'number',
			required: false,
			isVisible: hasEntryCondition,
			fieldInfo: i18n.t('promotions:form:fields_info.entry_condition_in_ttl')
		},
		{
			name: 'entry_condition_limit',
			label: i18n.t('promotions:form:fields.entry_condition_limit'),
			type: 'number',
			required: false,
			isVisible: hasEntryCondition,
			fieldInfo: i18n.t('promotions:form:fields_info.entry_condition_limit')
		},
		{
			name: 'entry_condition_min_sum_price',
			label: i18n.t('promotions:form:fields.entry_condition_min_sum_price'),
			type: 'number',
			required: false,
			isVisible: hasEntryCondition
		},
		{
			name: 'is_packet',
			label: i18n.t('promotions:form:fields.is_packet'),
			type: 'checkbox',
			required: false
		},
		{
			name: 'promo_days_period',
			label: i18n.t('promotions:form:fields.promo_days_period'),
			type: 'number',
			fieldInfo: i18n.t(`promotions:form:fields_info.promo_days_period`),
			required: hasPromotionalPeriod,
			isVisible: hasPromotionalPeriod
		}
	];
};
