// Import translation
import i18n from 'i18next';

export default ({ title, title_org, summary_short }) => {
	const errors = {};

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('vod:form:validation.title');
	}

	// ------------------ Title original ------------------
	if (!title_org) {
		errors.title_org = i18n.t('vod:form:validation.title_org');
	}

	// ------------------ Summary short ------------------
	if (!summary_short) {
		errors.summary_short = i18n.t('vod:form:validation.summary_short');
	}

	return errors;
};
