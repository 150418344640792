import React from 'react';
import { useTranslation } from 'react-i18next';

// Import logic
import useSubscribersButtons from './useSubscribersButtons';

// Import components
import { Button } from 'reactstrap';

const SubscribersButtons = ({ subscriberData }) => {
	const { t } = useTranslation();

	const {
		suspendSubscriber,
		unsuspendSubscriber,
		resendResetPassword,
		resendApprovalEmail,
		isSubscriberSuspended
	} = useSubscribersButtons(subscriberData);

	return (
		<div className="d-flex justify-content-end">
			{!isSubscriberSuspended && (
				<Button className="m-l-sm" onClick={suspendSubscriber}>
					{t('subscribers:buttons.suspend_subscriber')}
				</Button>
			)}
			{isSubscriberSuspended && (
				<Button className="m-l-sm" onClick={unsuspendSubscriber}>
					{t('subscribers:buttons.unsuspend_subscriber')}
				</Button>
			)}
			<Button className="m-l-sm" onClick={resendResetPassword}>
				{t('subscribers:buttons.resend_reset_password')}
			</Button>
			<Button className="m-l-sm" onClick={resendApprovalEmail}>
				{t('subscribers:buttons.resend_approval_email')}
			</Button>
		</div>
	);
};

export default SubscribersButtons;
