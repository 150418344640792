import React from 'react';
import PropTypes from 'prop-types';

// Import hooks
import useDeleteButton from './useDeleteButton';

const DeleteButton = ({
	id,
	additionalResources,
	products,
	productSystem,
	sourceSystem,
	onSelectItem,
	isReadOnly
}) => {
	const { canUserDeleteProduct } = useDeleteButton({
		products,
		productSystem,
		sourceSystem
	});

	if (isReadOnly) return null;

	const handleClick = () => onSelectItem(id, additionalResources);

	return (
		<button
			onClick={handleClick}
			className="btn btn-xs btn-primary"
			disabled={!canUserDeleteProduct}
		>
			<i className="fa fa-trash" />
		</button>
	);
};

DeleteButton.defaultProps = {
	additionalResources: null,
	sourceSystem: null
};

DeleteButton.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	products: PropTypes.number,
	productSystem: PropTypes.string,
	sourceSystem: PropTypes.string,
	onSelectItem: PropTypes.func.isRequired,
	isReadOnly: PropTypes.bool,
	additionalResources: PropTypes.any
};

export default DeleteButton;
