import React from 'react';
import i18n from 'i18next';

// Import helpers
import { setFormLink } from 'components/helpers/link_selector';

// Import components
import DeleteButton from 'components/utilities/delete_button/DeleteButton';

export const createColumns = (handleSelectItem, isReadOnly) => {
	return [
		{
			Header: i18n.t('local_channels:table_columns.location'),
			accessor: 'location'
		},
		{
			Header: i18n.t('local_channels:table_columns.name'),
			accessor: 'title',
			Cell: ({
				row: {
					_original: { channel_uuid, channel_title }
				}
			}) =>
				setFormLink({
					uuid: channel_uuid,
					type: 'channel',
					title: channel_title
				})
		},
		{
			Header: i18n.t('local_channels:table_columns.channel_position'),
			accessor: 'additional_data.position'
		},
		{
			Header: i18n.t('common:table_columns.delete'),
			accessor: 'delete',
			width: 60,
			className: 'text-center',
			sortable: false,
			Cell: ({
				row: {
					_original: { uuid, system_id, tag_id, channel_uuid }
				}
			}) => (
				<DeleteButton
					id={uuid}
					productSystem={system_id}
					onSelectItem={() => handleSelectItem(channel_uuid, tag_id)}
					isReadOnly={isReadOnly}
				/>
			)
		}
	];
};
