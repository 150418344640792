import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH COUPONS ********************
export const fetchSubscriberCoupons = (userId) => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_SUBSCRIBER_COUPONS_LOADING
		});

		const url = `/promotions/user/${userId}/coupons`;

		const { data } = await services.get(url);

		dispatch({
			type: types.FETCH_SUBSCRIBER_COUPONS_SUCCESS,
			payload: data
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_SUBSCRIBER_COUPONS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
