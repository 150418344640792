export const actionNames = [
	{
		name: 'PLAYING',
		icon: 'fa-play'
	},
	{
		name: 'BUFFERING',
		icon: 'fa-spinner'
	},
	{
		name: 'PAUSED',
		icon: 'fa-pause'
	},
	{
		name: 'SEEKING',
		icon: 'fa-search'
	},
	{
		name: 'TRACK_CHANGED',
		icon: 'fa-step-forward'
	},
	{
		name: 'COMPLETE',
		icon: 'fa-flag-checkered'
	},
	{
		name: 'STOPPED',
		icon: 'fa-stop'
	},
	{
		name: 'CLOSED',
		icon: 'fa-times-circle'
	},
	{
		name: 'ERROR',
		icon: 'fa-exclamation-triangle'
	}
];
