import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchSection, fetchSectionAlgorithms } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { types, addTranslation } from './fields/helpers';
import { convertTimeToMinutes } from 'components/helpers';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const SectionsForm = ({
	history,
	fetchSection,
	fetchSectionAlgorithms,
	error,
	isLoaded,
	initialValues,
	algorithms
}) => {
	const { t } = useTranslation();

	const algorithmsTranslated = addTranslation(algorithms);

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { algorithm_config, active_from_minute, active_to_minute } = values;

		const resources = {
			...values,
			algorithm_config: algorithm_config || null,
			active_from_minute: convertTimeToMinutes(active_from_minute),
			active_to_minute: convertTimeToMinutes(active_to_minute)
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'section',
			redirectPath: 'sections'
		});
	};

	return (
		<FormTemplate
			fetchFormResources={[fetchSectionAlgorithms]}
			fetchResourceToEdit={fetchSection}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={{ active: false, live_epg: false, ...initialValues }}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								algorithm_id={algorithmsTranslated}
								type={types}
								isEdit={isEdit}
								submitting={submitting}
							/>

							<FormButtons
								isButtonDisabled={submitting || error}
								path="sections"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

SectionsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchSection: PropTypes.func.isRequired,
	fetchSectionAlgorithms: PropTypes.func.isRequired,
	algorithms: PropTypes.array.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ sections: { form } }) => {
	return {
		initialValues: form.edit,
		algorithms: form.algorithms,
		error: form.error,
		isLoaded: form.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, { fetchSection, fetchSectionAlgorithms }),
	withRouter
)(SectionsForm);
