import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchLimitProducts } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import ProductsSelect from 'components/utilities/products_select/ProductsSelect';

// Import products tabs
import {
	providers,
	channel
} from 'components/utilities/products_select/helpers/tabs';

const LimitsProducts = ({ data, error, isLoaded, fetchLimitProducts }) => {
	const { t } = useTranslation();
	const tabs = [providers, channel];

	const createSubmitData = (selectedData) => {
		const selection = { products: [] };

		selection.products = selectedData.map(({ uuid, type, title }) => ({
			product_id: uuid,
			type: type,
			name: title
		}));

		return selection;
	};

	return (
		<ProductsSelect
			api="player/limits"
			title={t('common:products.title')}
			tabs={tabs}
			openModalText={t('common:products.add_products')}
			buttonSubmitText={t('common:buttons.submit')}
			notificationName="products"
			productsTitle={t('limits:products_title')}
			initialData={data}
			error={error}
			isLoaded={isLoaded}
			fetchResourcesAction={fetchLimitProducts}
			createSubmitData={createSubmitData}
		/>
	);
};

LimitsProducts.propTypes = {
	data: PropTypes.array.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	fetchLimitProducts: PropTypes.func.isRequired
};

const mapStateToProps = ({ limits: { products } }) => ({
	data: products.data,
	isLoaded: products.isLoaded,
	error: products.error
});

export default connect(mapStateToProps, { fetchLimitProducts })(LimitsProducts);
