export const FETCH_PAYMENT_TEMPLATES_GET_ALL =
	'/timetables/priceTemplates/list';

export const FETCH_PAYMENT_TEMPLATES_GET = '/timetables/priceTemplates/:id';

export const FETCH_PAYMENT_TEMPLATES_CREATE =
	'/timetables/priceTemplates/create';

export const FETCH_PAYMENT_TEMPLATES_UPDATE = '/timetables/priceTemplates/:id';

export const FETCH_PAYMENT_TEMPLATES_DELETE = '/timetables/priceTemplates/:id';

export const FETCH_PAYMENT_TEMPLATES_USE =
	'/timetables/priceTemplates/:templateId/products/:productId/apply';

export const FETCH_PAYMENT_TEMPLATES_DETAILS =
	'/timetables/availability/listByProduct/vod/:productId';
