import React from 'react';
import PropTypes from 'prop-types';
import { Input, Modal } from 'antd';
import { RobotOutlined } from '@ant-design/icons';

// Import hooks
import { useToggle } from 'hooks';

// Import styles
import './styles.scss';

const InputWithModal = ({ id, children, hasErrors, ...auxiliaryProps }) => {
	const [opened, toggle] = useToggle();
	const dummyAction = (e) => {
		e.preventDefault();
	};
	return (
		<>
			<button onClick={toggle} type="button" className="button-reset">
				<Input
					{...auxiliaryProps}
					status={hasErrors && 'error'}
					id={id}
					onChange={dummyAction}
					onClick={dummyAction}
					onBlur={dummyAction}
					readOnly
					className="readonly-input"
					addonBefore={<RobotOutlined />}
				/>
			</button>
			{opened && (
				<Modal
					open={opened}
					footer={null}
					onCancel={toggle}
					width={900}
					destroyOnClose
					bodyStyle={{ minHeight: '25vh' }}
				>
					{React.cloneElement(children, { close: toggle })}
				</Modal>
			)}
		</>
	);
};

InputWithModal.propTypes = {
	id: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	hasErrors: PropTypes.bool
};

export default InputWithModal;
