import React from 'react';
import { useParams } from 'react-router-dom';

// Import components
import Loader from 'components/common/loaders/Loader';
import { Ibox, IboxContentBase, Wraper } from 'components/common/layout';
import {
	VerificationTitle,
	VerificationButtons,
	VerificationDocumentPreview
} from './components';

// Import helpers
import {
	VerificationContext,
	VerificationContextProvider
} from './VerificationContext';

function SubscribersVerification() {
	const { id } = useParams();
	return (
		<VerificationContextProvider documentId={id}>
			<VerificationContext.Consumer>
				{({ error, isLoaded }) => (
					<Wraper lg="12" error={error}>
						<Ibox error={false} className="m-b-md">
							<Loader isLoaded={isLoaded}>
								<VerificationTitle />
								<IboxContentBase>
									<VerificationButtons />
									<VerificationDocumentPreview />
								</IboxContentBase>
							</Loader>
						</Ibox>
					</Wraper>
				)}
			</VerificationContext.Consumer>
		</VerificationContextProvider>
	);
}

export default SubscribersVerification;
