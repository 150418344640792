export const terytCodes = [
	{
		name: 'DOLNOŚLĄSKIE',
		value: '02',
		counties: [
			{
				name: 'bolesławiecki',
				value: '01'
			},
			{
				name: 'dzierżoniowski',
				value: '02'
			},
			{
				name: 'głogowski',
				value: '03'
			},
			{
				name: 'górowski',
				value: '04'
			},
			{
				name: 'jaworski',
				value: '05'
			},
			{
				name: 'jeleniogórski',
				value: '06'
			},
			{
				name: 'kamiennogórski',
				value: '07'
			},
			{
				name: 'kłodzki',
				value: '08'
			},
			{
				name: 'legnicki',
				value: '09'
			},
			{
				name: 'lubański',
				value: '10'
			},
			{
				name: 'lubiński',
				value: '11'
			},
			{
				name: 'lwówecki',
				value: '12'
			},
			{
				name: 'milicki',
				value: '13'
			},
			{
				name: 'oleśnicki',
				value: '14'
			},
			{
				name: 'oławski',
				value: '15'
			},
			{
				name: 'polkowicki',
				value: '16'
			},
			{
				name: 'strzeliński',
				value: '17'
			},
			{
				name: 'średzki',
				value: '18'
			},
			{
				name: 'świdnicki',
				value: '19'
			},
			{
				name: 'trzebnicki',
				value: '20'
			},
			{
				name: 'wałbrzyski',
				value: '21'
			},
			{
				name: 'wołowski',
				value: '22'
			},
			{
				name: 'wrocławski',
				value: '23'
			},
			{
				name: 'ząbkowicki',
				value: '24'
			},
			{
				name: 'zgorzelecki',
				value: '25'
			},
			{
				name: 'złotoryjski',
				value: '26'
			},
			{
				name: 'Jelenia Góra',
				value: '61'
			},
			{
				name: 'Legnica',
				value: '62'
			},
			{
				name: 'Wrocław',
				value: '64'
			},
			{
				name: 'Wałbrzych',
				value: '65'
			}
		]
	},
	{
		name: 'KUJAWSKO POMORSKIE',
		value: '04',
		counties: [
			{
				name: 'aleksandrowski',
				value: '01'
			},
			{
				name: 'brodnicki',
				value: '02'
			},
			{
				name: 'bydgoski',
				value: '03'
			},
			{
				name: 'chełmiński',
				value: '04'
			},
			{
				name: 'golubsko-dobrzyński',
				value: '05'
			},
			{
				name: 'grudziądzki',
				value: '06'
			},
			{
				name: 'inowrocławski',
				value: '07'
			},
			{
				name: 'lipnowski',
				value: '08'
			},
			{
				name: 'mogileński',
				value: '09'
			},
			{
				name: 'nakielski',
				value: '10'
			},
			{
				name: 'radziejowski',
				value: '11'
			},
			{
				name: 'rypiński',
				value: '12'
			},
			{
				name: 'sępoleński',
				value: '13'
			},
			{
				name: 'świecki',
				value: '14'
			},
			{
				name: 'toruński',
				value: '15'
			},
			{
				name: 'tucholski',
				value: '16'
			},
			{
				name: 'wąbrzeski',
				value: '17'
			},
			{
				name: 'włocławski',
				value: '18'
			},
			{
				name: 'żniński',
				value: '19'
			},
			{
				name: 'Bydgoszcz',
				value: '61'
			},
			{
				name: 'Grudziądz',
				value: '62'
			},
			{
				name: 'Toruń',
				value: '63'
			},
			{
				name: 'Włocławek',
				value: '64'
			}
		]
	},
	{
		name: 'LUBELSKIE',
		value: '06',
		counties: [
			{
				name: 'bialski',
				value: '01'
			},
			{
				name: 'biłgorajski',
				value: '02'
			},
			{
				name: 'chełmski',
				value: '03'
			},
			{
				name: 'hrubieszowski',
				value: '04'
			},
			{
				name: 'janowski',
				value: '05'
			},
			{
				name: 'krasnostawski',
				value: '06'
			},
			{
				name: 'kraśnicki',
				value: '07'
			},
			{
				name: 'lubartowski',
				value: '08'
			},
			{
				name: 'lubelski',
				value: '09'
			},
			{
				name: 'łęczyński',
				value: '10'
			},
			{
				name: 'łukowski',
				value: '11'
			},
			{
				name: 'opolski',
				value: '12'
			},
			{
				name: 'parczewski',
				value: '13'
			},
			{
				name: 'puławski',
				value: '14'
			},
			{
				name: 'radzyński',
				value: '15'
			},
			{
				name: 'rycki',
				value: '16'
			},
			{
				name: 'świdnicki',
				value: '17'
			},
			{
				name: 'tomaszowski',
				value: '18'
			},
			{
				name: 'włodawski',
				value: '19'
			},
			{
				name: 'zamojski',
				value: '20'
			},
			{
				name: 'Biała Podlaska',
				value: '61'
			},
			{
				name: 'Chełm',
				value: '62'
			},
			{
				name: 'Lublin',
				value: '63'
			},
			{
				name: 'Zamość',
				value: '64'
			}
		]
	},
	{
		name: 'LUBUSKIE',
		value: '08',
		counties: [
			{
				name: 'gorzowski',
				value: '01'
			},
			{
				name: 'krośnieński',
				value: '02'
			},
			{
				name: 'międzyrzecki',
				value: '03'
			},
			{
				name: 'nowosolski',
				value: '04'
			},
			{
				name: 'słubicki',
				value: '05'
			},
			{
				name: 'strzelecko-drezdenecki',
				value: '06'
			},
			{
				name: 'sulęciński',
				value: '07'
			},
			{
				name: 'świebodziński',
				value: '08'
			},
			{
				name: 'zielonogórski',
				value: '09'
			},
			{
				name: 'żagański',
				value: '10'
			},
			{
				name: 'żarski',
				value: '11'
			},
			{
				name: 'wschowski',
				value: '12'
			},
			{
				name: 'Gorzów Wielkopolski',
				value: '61'
			},
			{
				name: 'Zielona Góra',
				value: '62'
			}
		]
	},
	{
		name: 'ŁÓDZKIE',
		value: '10',
		counties: [
			{
				name: 'bełchatowski',
				value: '01'
			},
			{
				name: 'kutnowski',
				value: '02'
			},
			{
				name: 'łaski',
				value: '03'
			},
			{
				name: 'łęczycki',
				value: '04'
			},
			{
				name: 'łowicki',
				value: '05'
			},
			{
				name: 'łódzki wschodni',
				value: '06'
			},
			{
				name: 'opoczyński',
				value: '07'
			},
			{
				name: 'pabianicki',
				value: '08'
			},
			{
				name: 'pajęczański',
				value: '09'
			},
			{
				name: 'piotrkowski',
				value: '10'
			},
			{
				name: 'poddębicki',
				value: '11'
			},
			{
				name: 'radomszczański',
				value: '12'
			},
			{
				name: 'rawski',
				value: '13'
			},
			{
				name: 'sieradzki',
				value: '14'
			},
			{
				name: 'skierniewicki',
				value: '15'
			},
			{
				name: 'tomaszowski',
				value: '16'
			},
			{
				name: 'wieluński',
				value: '17'
			},
			{
				name: 'wieruszowski',
				value: '18'
			},
			{
				name: 'zduńskowolski',
				value: '19'
			},
			{
				name: 'zgierski',
				value: '20'
			},
			{
				name: 'brzeziński',
				value: '21'
			},
			{
				name: 'Łódź',
				value: '61'
			},
			{
				name: 'Piotrków Trybunalski',
				value: '62'
			},
			{
				name: 'Skierniewice',
				value: '63'
			}
		]
	},
	{
		name: 'MAŁOPOLSKIE',
		value: '12',
		counties: [
			{
				name: 'bocheński',
				value: '01'
			},
			{
				name: 'brzeski',
				value: '02'
			},
			{
				name: 'chrzanowski',
				value: '03'
			},
			{
				name: 'dąbrowski',
				value: '04'
			},
			{
				name: 'gorlicki',
				value: '05'
			},
			{
				name: 'krakowski',
				value: '06'
			},
			{
				name: 'limanowski',
				value: '07'
			},
			{
				name: 'miechowski',
				value: '08'
			},
			{
				name: 'myślenicki',
				value: '09'
			},
			{
				name: 'nowosądecki',
				value: '10'
			},
			{
				name: 'nowotarski',
				value: '11'
			},
			{
				name: 'olkuski',
				value: '12'
			},
			{
				name: 'oświęcimski',
				value: '13'
			},
			{
				name: 'proszowicki',
				value: '14'
			},
			{
				name: 'suski',
				value: '15'
			},
			{
				name: 'tarnowski',
				value: '16'
			},
			{
				name: 'tatrzański',
				value: '17'
			},
			{
				name: 'wadowicki',
				value: '18'
			},
			{
				name: 'wielicki',
				value: '19'
			},
			{
				name: 'Kraków',
				value: '61'
			},
			{
				name: 'Tarnów',
				value: '62'
			},
			{
				name: 'Nowy Sącz',
				value: '63'
			}
		]
	},
	{
		name: 'MAZOWIECKIE',
		value: '14',
		counties: [
			{
				name: 'białobrzeski',
				value: '01'
			},
			{
				name: 'ciechanowski',
				value: '02'
			},
			{
				name: 'garwoliński',
				value: '03'
			},
			{
				name: 'gostyniński',
				value: '04'
			},
			{
				name: 'grodziski',
				value: '05'
			},
			{
				name: 'grójecki',
				value: '06'
			},
			{
				name: 'kozienicki',
				value: '07'
			},
			{
				name: 'legionowski',
				value: '08'
			},
			{
				name: 'lipski',
				value: '09'
			},
			{
				name: 'łosicki',
				value: '10'
			},
			{
				name: 'makowski',
				value: '11'
			},
			{
				name: 'miński',
				value: '12'
			},
			{
				name: 'mławski',
				value: '13'
			},
			{
				name: 'nowodworski',
				value: '14'
			},
			{
				name: 'ostrołęcki',
				value: '15'
			},
			{
				name: 'ostrowski',
				value: '16'
			},
			{
				name: 'otwocki',
				value: '17'
			},
			{
				name: 'piaseczyński',
				value: '18'
			},
			{
				name: 'płocki',
				value: '19'
			},
			{
				name: 'płoński',
				value: '20'
			},
			{
				name: 'pruszkowski',
				value: '21'
			},
			{
				name: 'przasnyski',
				value: '22'
			},
			{
				name: 'przysuski',
				value: '23'
			},
			{
				name: 'pułtuski',
				value: '24'
			},
			{
				name: 'radomski',
				value: '25'
			},
			{
				name: 'siedlecki',
				value: '26'
			},
			{
				name: 'sierpecki',
				value: '27'
			},
			{
				name: 'sochaczewski',
				value: '28'
			},
			{
				name: 'sokołowski',
				value: '29'
			},
			{
				name: 'szydłowiecki',
				value: '30'
			},
			{
				name: 'warszawski zachodni',
				value: '32'
			},
			{
				name: 'węgrowski',
				value: '33'
			},
			{
				name: 'wołomiński',
				value: '34'
			},
			{
				name: 'wyszkowski',
				value: '35'
			},
			{
				name: 'zwoleński',
				value: '36'
			},
			{
				name: 'żuromiński',
				value: '37'
			},
			{
				name: 'żyrardowski',
				value: '38'
			},
			{
				name: 'Ostrołęka',
				value: '61'
			},
			{
				name: 'Płock',
				value: '62'
			},
			{
				name: 'Radom',
				value: '63'
			},
			{
				name: 'Siedlce',
				value: '64'
			},
			{
				name: 'Warszawa',
				value: '65'
			}
		]
	},
	{
		name: 'OPOLSKIE',
		value: '16',
		counties: [
			{
				name: 'brzeski',
				value: '01'
			},
			{
				name: 'głubczycki',
				value: '02'
			},
			{
				name: 'kędzierzyńsko-kozielski',
				value: '03'
			},
			{
				name: 'kluczborski',
				value: '04'
			},
			{
				name: 'krapkowicki',
				value: '05'
			},
			{
				name: 'namysłowski',
				value: '06'
			},
			{
				name: 'nyski',
				value: '07'
			},
			{
				name: 'oleski',
				value: '08'
			},
			{
				name: 'opolski',
				value: '09'
			},
			{
				name: 'prudnicki',
				value: '10'
			},
			{
				name: 'strzelecki',
				value: '11'
			},
			{
				name: 'Opole',
				value: '61'
			}
		]
	},
	{
		name: 'PODKARPACKIE',
		value: '18',
		counties: [
			{
				name: 'bieszczadzki',
				value: '01'
			},
			{
				name: 'brzozowski',
				value: '02'
			},
			{
				name: 'dębicki',
				value: '03'
			},
			{
				name: 'jarosławski',
				value: '04'
			},
			{
				name: 'jasielski',
				value: '05'
			},
			{
				name: 'kolbuszowski',
				value: '06'
			},
			{
				name: 'krośnieński',
				value: '07'
			},
			{
				name: 'leżajski',
				value: '08'
			},
			{
				name: 'lubaczowski',
				value: '09'
			},
			{
				name: 'łańcucki',
				value: '10'
			},
			{
				name: 'mielecki',
				value: '11'
			},
			{
				name: 'niżański',
				value: '12'
			},
			{
				name: 'przemyski',
				value: '13'
			},
			{
				name: 'przeworski',
				value: '14'
			},
			{
				name: 'ropczycko-sędziszowski',
				value: '15'
			},
			{
				name: 'rzeszowski',
				value: '16'
			},
			{
				name: 'sanocki',
				value: '17'
			},
			{
				name: 'stalowowolski',
				value: '18'
			},
			{
				name: 'strzyżowski',
				value: '19'
			},
			{
				name: 'tarnobrzeski',
				value: '20'
			},
			{
				name: 'leski',
				value: '21'
			},
			{
				name: 'Krosno',
				value: '61'
			},
			{
				name: 'Przemyśl',
				value: '62'
			},
			{
				name: 'Rzeszów',
				value: '63'
			},
			{
				name: 'Tarnobrzeg',
				value: '64'
			}
		]
	},
	{
		name: 'PODLASKIE',
		value: '20',
		counties: [
			{
				name: 'augustowski',
				value: '01'
			},
			{
				name: 'białostocki',
				value: '02'
			},
			{
				name: 'bielski',
				value: '03'
			},
			{
				name: 'grajewski',
				value: '04'
			},
			{
				name: 'hajnowski',
				value: '05'
			},
			{
				name: 'kolneński',
				value: '06'
			},
			{
				name: 'łomżyński',
				value: '07'
			},
			{
				name: 'moniecki',
				value: '08'
			},
			{
				name: 'sejneński',
				value: '09'
			},
			{
				name: 'siemiatycki',
				value: '10'
			},
			{
				name: 'sokólski',
				value: '11'
			},
			{
				name: 'suwalski',
				value: '12'
			},
			{
				name: 'wysokomazowiecki',
				value: '13'
			},
			{
				name: 'zambrowski',
				value: '14'
			},
			{
				name: 'Białystok',
				value: '61'
			},
			{
				name: 'Łomża',
				value: '62'
			},
			{
				name: 'Suwałki',
				value: '63'
			}
		]
	},
	{
		name: 'POMORSKIE',
		value: '22',
		counties: [
			{
				name: 'bytowski',
				value: '01'
			},
			{
				name: 'chojnicki',
				value: '02'
			},
			{
				name: 'człuchowski',
				value: '03'
			},
			{
				name: 'gdański',
				value: '04'
			},
			{
				name: 'kartuski',
				value: '05'
			},
			{
				name: 'kościerski',
				value: '06'
			},
			{
				name: 'kwidzyński',
				value: '07'
			},
			{
				name: 'lęborski',
				value: '08'
			},
			{
				name: 'malborski',
				value: '09'
			},
			{
				name: 'nowodworski',
				value: '10'
			},
			{
				name: 'pucki',
				value: '11'
			},
			{
				name: 'słupski',
				value: '12'
			},
			{
				name: 'starogardzki',
				value: '13'
			},
			{
				name: 'tczewski',
				value: '14'
			},
			{
				name: 'wejherowski',
				value: '15'
			},
			{
				name: 'sztumski',
				value: '16'
			},
			{
				name: 'Gdańsk',
				value: '61'
			},
			{
				name: 'Gdynia',
				value: '62'
			},
			{
				name: 'Słupsk',
				value: '63'
			},
			{
				name: 'Sopot',
				value: '64'
			}
		]
	},
	{
		name: 'ŚLĄSKIE',
		value: '24',
		counties: [
			{
				name: 'będziński',
				value: '01'
			},
			{
				name: 'bielski',
				value: '02'
			},
			{
				name: 'cieszyński',
				value: '03'
			},
			{
				name: 'częstochowski',
				value: '04'
			},
			{
				name: 'gliwicki',
				value: '05'
			},
			{
				name: 'kłobucki',
				value: '06'
			},
			{
				name: 'lubliniecki',
				value: '07'
			},
			{
				name: 'mikołowski',
				value: '08'
			},
			{
				name: 'myszkowski',
				value: '09'
			},
			{
				name: 'pszczyński',
				value: '10'
			},
			{
				name: 'raciborski',
				value: '11'
			},
			{
				name: 'rybnicki',
				value: '12'
			},
			{
				name: 'tarnogórski',
				value: '13'
			},
			{
				name: 'bieruńsko-lędziński',
				value: '14'
			},
			{
				name: 'wodzisławski',
				value: '15'
			},
			{
				name: 'zawierciański',
				value: '16'
			},
			{
				name: 'żywiecki',
				value: '17'
			},
			{
				name: 'Bielsko-Biała',
				value: '61'
			},
			{
				name: 'Bytom',
				value: '62'
			},
			{
				name: 'Chorzów',
				value: '63'
			},
			{
				name: 'Częstochowa',
				value: '64'
			},
			{
				name: 'Dąbrowa Górnicza',
				value: '65'
			},
			{
				name: 'Gliwice',
				value: '66'
			},
			{
				name: 'Jastrzębie-Zdrój',
				value: '67'
			},
			{
				name: 'Jaworzno',
				value: '68'
			},
			{
				name: 'Katowice',
				value: '69'
			},
			{
				name: 'Mysłowice',
				value: '70'
			},
			{
				name: 'Piekary Śląskie',
				value: '71'
			},
			{
				name: 'Ruda Śląska',
				value: '72'
			},
			{
				name: 'Rybnik',
				value: '73'
			},
			{
				name: 'Siemianowice Śląskie',
				value: '74'
			},
			{
				name: 'Sosnowiec',
				value: '75'
			},
			{
				name: 'Świętochłowice',
				value: '76'
			},
			{
				name: 'Tychy ',
				value: '77'
			},
			{
				name: 'Zabrze',
				value: '78'
			},
			{
				name: 'Żory',
				value: '79'
			}
		]
	},
	{
		name: 'ŚWIĘTOKRZYSKIE',
		value: '26',
		counties: [
			{
				name: 'buski',
				value: '01'
			},
			{
				name: 'jędrzejowski',
				value: '02'
			},
			{
				name: 'kazimierski',
				value: '03'
			},
			{
				name: 'kielecki',
				value: '04'
			},
			{
				name: 'konecki',
				value: '05'
			},
			{
				name: 'opatowski',
				value: '06'
			},
			{
				name: 'ostrowiecki',
				value: '07'
			},
			{
				name: 'pińczowski',
				value: '08'
			},
			{
				name: 'sandomierski',
				value: '09'
			},
			{
				name: 'skarżyski',
				value: '10'
			},
			{
				name: 'starachowicki',
				value: '11'
			},
			{
				name: 'staszowski',
				value: '12'
			},
			{
				name: 'włoszczowski',
				value: '13'
			},
			{
				name: 'Kielce',
				value: '61'
			}
		]
	},
	{
		name: 'WARMIŃSKO MAZURSKIE',
		value: '28',
		counties: [
			{
				name: 'bartoszycki',
				value: '01'
			},
			{
				name: 'braniewski',
				value: '02'
			},
			{
				name: 'działdowski',
				value: '03'
			},
			{
				name: 'elbląski',
				value: '04'
			},
			{
				name: 'ełcki',
				value: '05'
			},
			{
				name: 'giżycki',
				value: '06'
			},
			{
				name: 'iławski',
				value: '07'
			},
			{
				name: 'kętrzyński',
				value: '08'
			},
			{
				name: 'lidzbarski',
				value: '09'
			},
			{
				name: 'mrągowski',
				value: '10'
			},
			{
				name: 'nidzicki',
				value: '11'
			},
			{
				name: 'nowomiejski',
				value: '12'
			},
			{
				name: 'olecki',
				value: '13'
			},
			{
				name: 'olsztyński',
				value: '14'
			},
			{
				name: 'ostródzki',
				value: '15'
			},
			{
				name: 'piski',
				value: '16'
			},
			{
				name: 'szczycieński',
				value: '17'
			},
			{
				name: 'gołdapski',
				value: '18'
			},
			{
				name: 'węgorzewski',
				value: '19'
			},
			{
				name: 'Elbląg',
				value: '61'
			},
			{
				name: 'Olsztyn',
				value: '62'
			}
		]
	},
	{
		name: 'WIELKOPOLSKIE',
		value: '30',
		counties: [
			{
				name: 'chodzieski',
				value: '01'
			},
			{
				name: 'czarnkowsko-trzcianecki',
				value: '02'
			},
			{
				name: 'gnieźnieński',
				value: '03'
			},
			{
				name: 'gostyński',
				value: '04'
			},
			{
				name: 'grodziski',
				value: '05'
			},
			{
				name: 'jarociński',
				value: '06'
			},
			{
				name: 'kaliski',
				value: '07'
			},
			{
				name: 'kępiński',
				value: '08'
			},
			{
				name: 'kolski',
				value: '09'
			},
			{
				name: 'koniński',
				value: '10'
			},
			{
				name: 'kościański',
				value: '11'
			},
			{
				name: 'krotoszyński',
				value: '12'
			},
			{
				name: 'leszczyński',
				value: '13'
			},
			{
				name: 'międzychodzki',
				value: '14'
			},
			{
				name: 'nowotomyski',
				value: '15'
			},
			{
				name: 'obornicki',
				value: '16'
			},
			{
				name: 'ostrowski',
				value: '17'
			},
			{
				name: 'ostrzeszowski',
				value: '18'
			},
			{
				name: 'pilski',
				value: '19'
			},
			{
				name: 'pleszewski',
				value: '20'
			},
			{
				name: 'poznański',
				value: '21'
			},
			{
				name: 'rawicki',
				value: '22'
			},
			{
				name: 'słupecki',
				value: '23'
			},
			{
				name: 'szamotulski',
				value: '24'
			},
			{
				name: 'średzki',
				value: '25'
			},
			{
				name: 'śremski',
				value: '26'
			},
			{
				name: 'turecki',
				value: '27'
			},
			{
				name: 'wągrowiecki',
				value: '28'
			},
			{
				name: 'wolsztyński',
				value: '29'
			},
			{
				name: 'wrzesiński',
				value: '30'
			},
			{
				name: 'złotowski',
				value: '31'
			},
			{
				name: 'Kalisz',
				value: '61'
			},
			{
				name: 'Konin',
				value: '62'
			},
			{
				name: 'Leszno',
				value: '63'
			},
			{
				name: 'Poznań',
				value: '64'
			}
		]
	},
	{
		name: 'ZACHODNIO POMORSKIE',
		value: '32',
		counties: [
			{
				name: 'białogardzki',
				value: '01'
			},
			{
				name: 'choszczeński',
				value: '02'
			},
			{
				name: 'drawski',
				value: '03'
			},
			{
				name: 'goleniowski',
				value: '04'
			},
			{
				name: 'gryficki',
				value: '05'
			},
			{
				name: 'gryfiński',
				value: '06'
			},
			{
				name: 'kamieński',
				value: '07'
			},
			{
				name: 'kołobrzeski',
				value: '08'
			},
			{
				name: 'koszaliński',
				value: '09'
			},
			{
				name: 'myśliborski',
				value: '10'
			},
			{
				name: 'policki',
				value: '11'
			},
			{
				name: 'pyrzycki',
				value: '12'
			},
			{
				name: 'sławieński',
				value: '13'
			},
			{
				name: 'stargardzki',
				value: '14'
			},
			{
				name: 'szczecinecki',
				value: '15'
			},
			{
				name: 'świdwiński',
				value: '16'
			},
			{
				name: 'wałecki',
				value: '17'
			},
			{
				name: 'łobeski',
				value: '18'
			},
			{
				name: 'Koszalin',
				value: '61'
			},
			{
				name: 'Szczecin',
				value: '62'
			},
			{
				name: 'Świnoujście',
				value: '63'
			}
		]
	}
];
