// Import translation
import i18n from 'i18next';

export default ({ name, parent_system }) => {
	const errors = {};

	// ------------------ Name ------------------
	if (!name) {
		errors.name = i18n.t('systems:form.validation.name_required');
	}

	// ------------------ PARENT SYSTEM ID ------------------
	if (!parent_system) {
		errors.parent_system = i18n.t(
			'systems:form.validation.parent_system_required'
		);
	}

	return errors;
};
