import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import utilities
import FieldsWithLabel from 'components/utilities/form/FieldsWithLabel';

// Import fields
import {
	SYSTEM_SETTINGS_FIELDS,
	SYSTEM_SETTINGS_FIELDS_TYPE
} from 'components/helpers/system_settings_helpers/system_settings_fields';

const { VOD, PACKET, GENRES, EPG } = SYSTEM_SETTINGS_FIELDS_TYPE;

const AdditionalFields = ({ type, submitting }) => {
	const { t } = useTranslation();

	switch (type) {
		case VOD:
		case PACKET:
		case GENRES:
			return (
				<FieldsWithLabel
					label={t('common:form:field_groups.visible_in_platforms')}
					fields={SYSTEM_SETTINGS_FIELDS[type].visibleFields}
					submitting={submitting}
				/>
			);
		case EPG:
			return (
				<>
					<FieldsWithLabel
						label={t('epg:system:field_groups.primary_fields')}
						fields={SYSTEM_SETTINGS_FIELDS[type].primaryFields}
						submitting={submitting}
					/>
					<FieldsWithLabel
						label={t('epg:system:field_groups.secondary_fields')}
						fields={SYSTEM_SETTINGS_FIELDS[type].secondaryFields}
						submitting={submitting}
					/>
				</>
			);

		default:
			return null;
	}
};

AdditionalFields.propTypes = {
	type: PropTypes.string.isRequired,
	submitting: PropTypes.bool.isRequired
};

export default AdditionalFields;
