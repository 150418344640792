import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { SyncOutlined } from '@ant-design/icons';
import { Button as AntdButton } from 'antd';
import { IboxTitle } from 'components/common/layout';
import { VerificationContext } from '../VerificationContext';

function VerificationTitle() {
	const {
		isLoaded,
		status = 'unverified',
		refetchResources,
		isDisabledSpinner
	} = useContext(VerificationContext);
	const { t } = useTranslation();
	return (
		<IboxTitle isLoaded={isLoaded}>
			<span
				className={`font-weight-bolder h1 m-r-sm m-b-sm m-t-sm status_underline ${status}`}
			>
				{t(`verification:state.${status}`)}
			</span>
			<AntdButton
				disabled={isDisabledSpinner}
				onClick={refetchResources}
				icon={<SyncOutlined spin={!isLoaded} />}
				className="m-r-sm m-l-md m-b-sm m-t-sm"
			/>
		</IboxTitle>
	);
}

export default VerificationTitle;
