import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	request_url: '',
	converted_request_url: ''
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// ****************** SET REQUEST URL ******************
			case types.SET_REQUEST_URL:
				draft.request_url = action.payload.request_url;
				draft.converted_request_url = action.payload.converted_request_url;
				break;

			default:
				return state;
		}
	});
