import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH VOD ADI ********************
export const fetchVodAdi = (vodId) => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_VOD_ADI_LOADING
		});

		const { data } = await services.get(`ingester/product/${vodId}/adi`);

		dispatch({
			type: types.FETCH_VOD_ADI_SUCCESS,
			payload: data?.data?.xml ?? ''
		});
	} catch (error) {
		error.response.status = 404
			? dispatch({
					type: types.FETCH_VOD_ADI_404_ERROR
			  })
			: dispatch({
					type: types.FETCH_VOD_ADI_ERROR,
					payload: checkFetchErrors(error)
			  });
	}
};
