import i18n from 'i18n';

export const BASE_FIELDS = [
	{
		name: 'type',
		type: 'select',
		label: i18n.t('geolocks:fields.type'),
		required: true
	},
	{
		name: 'description',
		type: 'textarea',
		label: i18n.t('geolocks:fields.description'),
		required: false
	},
	{
		name: 'ip_from',
		type: 'text',
		label: i18n.t('geolocks:fields.ip_from'),
		required: true
	},
	{
		name: 'ip_to',
		type: 'text',
		label: i18n.t('geolocks:fields.ip_to'),
		required: true
	}
];
