import * as types from 'store/actions/types';
import services from 'services/services';
import { checkFetchErrors } from 'store/actions/helpers_actions/convert_helpers';
import { FETCH_SUBSCRIBER_ADDRESS } from 'components/helpers/api/subscribers_address';

export const fetchSubscriberAddress = (id) => async (dispatch) => {
	// Dispatch a loading action
	dispatch({
		type: types.FETCH_SUBSCRIBER_ADDRESS_LOADING
	});

	try {
		const { data } = await services.get(
			FETCH_SUBSCRIBER_ADDRESS.replace(':id', id)
		);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SUBSCRIBER_ADDRESS_SUCCESS,
			payload: data
		});
	} catch (error) {
		if (error.response && error.response.status === 404) {
			// 404 means there is no address added by user
			dispatch({
				type: types.FETCH_SUBSCRIBER_ADDRESS_SUCCESS,
				payload: {}
			});
		} else {
			dispatch({
				type: types.FETCH_SUBSCRIBER_ADDRESS_ERROR,
				payload: checkFetchErrors(error)
			});
		}
	}
};
