import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import MassChangesContextWrapper from 'components/utilities/mass_changes/MassChangesContextWrapper';

const MassChangesDeleteFrom = () => {
	const { t } = useTranslation();

	return (
		<MassChangesContextWrapper
			title={t('mass_changes:action_delete_from.title')}
			productsTitle={t('mass_changes:action_delete_from.productsTitle')}
			typeSelectLabel={t('mass_changes:typeSelectLabel')}
			productSelectLabel={t('mass_changes:productSelectLabel')}
			actionType="deleted"
			notificationTxt={t('mass_changes:action_delete_from.success')}
			buttonSubmitText={t('common:delete')}
			redirectPath="/panel/vod"
			notificationTitle="successfuly_deleted"
		/>
	);
};

export default MassChangesDeleteFrom;
