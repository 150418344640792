import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH PROVISIONING ITEM PRODUCTS - TABLE DATA ********************
export const fetchProvisioningItemProducts = (options, id) => async (
	dispatch,
	getState
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_PROVISIONING_ITEM_PRODUCTS_LOADING,
			payload: true
		});

		const {
			provisioning: {
				products: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `/packet/${id}/products?${filterUrlQuery}${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex,
			true
		)}`;

		const { data } = await services.get(url);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		dispatch({
			type: types.FETCH_PROVISIONING_ITEM_PRODUCTS_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex, packetId: id }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_PROVISIONING_ITEM_PRODUCTS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE PROVISIONING ITEM PRODUCT ********************
export const deleteProvisioningItemProduct = (id) => async (
	dispatch,
	getState
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DELETE_PROVISIONING_ITEM_PRODUCTS_LOADING,
			payload: true
		});

		// get packet id (parent id)
		const {
			provisioning: {
				products: { packetId }
			}
		} = getState();

		// product data and data for request properly formatted
		const productData = { uuid: id, order: 0 };
		const requestData = { add: [], del: [productData] };

		await services.post(`packet/${packetId}/addDelProducts`, requestData);

		// Dispatch an action with data
		dispatch({
			type: types.DELETE_PROVISIONING_ITEM_PRODUCTS_SUCCESS,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DELETE_PROVISIONING_ITEM_PRODUCTS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** RESET PROVISIONING ITEM PRODUCT TABLE ********************
// force table to fetch data by adding dummy data object before clearing panel state
// DO NOT REUSE as it's quick workaround for infinite loader when
// adding new data to empty table (table does not trigger fetch because data does not change
// it's empty before clearing state)
export const forceFetchProvisioningProductsTable = () => (dispatch) =>
	dispatch({ type: types.FORCE_FETCH_PROVISIONING_ITEM_PRODUCTS_TABLE });

// ******************** SET SEARCH QUERY ********************
export const setProvisioningItemProductsQuery = (query) => ({
	type: types.SET_PROVISIONING_ITEM_PRODUCTS_QUERY,
	payload: query
});
