import React from 'react';

// Import translation
import i18n from 'i18next';

export const algorithm_info = (
	<>
		<div>{i18n.t('sections:algorithm_info.title')}</div>
		<ul>
			<li>
				<strong>types</strong> - {i18n.t('sections:algorithm_info.type')}
			</li>
			<li>
				<strong>days</strong> - {i18n.t('sections:algorithm_info.days')}
			</li>
			<li>
				<strong>actor</strong> - {i18n.t('sections:algorithm_info.actor')}
			</li>
			<li>
				<strong>director</strong> - {i18n.t('sections:algorithm_info.director')}
			</li>
			<li>
				<strong>category_slugs</strong> -{' '}
				{i18n.t('sections:algorithm_info.category_slugs')}
			</li>
			<li>
				<strong>packet_uuids</strong> -{' '}
				{i18n.t('sections:algorithm_info.packet_uuid')}
			</li>
			<li>
				<strong>limit</strong> - {i18n.t('sections:algorithm_info.limit')}
			</li>
			<li>
				<strong>providers</strong> -{' '}
				{i18n.t('sections:algorithm_info.providers')}
			</li>
			<li>
				<strong>countries</strong> -{' '}
				{i18n.t('sections:algorithm_info.countries')}
			</li>
			<li>
				<strong>billing_ids</strong> -{' '}
				{i18n.t('sections:algorithm_info.billing_ids')}
			</li>
			<li>
				<strong>channels</strong> - {i18n.t('sections:algorithm_info.channels')}
			</li>
			<li>
				<strong>studios</strong> - {i18n.t('sections:algorithm_info.studios')}
			</li>
		</ul>
		<div style={{ marginBottom: '1rem' }}>
			{i18n.t('sections:algorithm_info.example')}
			<br />
			<em>&#123;</em>
			<br />
			<em> "types":["vod","series"],</em>
			<br />
			<em> "days":12,</em>
			<br />
			<em> "actor": "Malkovich",</em>
			<br />
			<em> "director": "Peter J",</em>
			<br />
			<em> "category_slugs": ["dokument", "historyczny"],</em>
			<br />
			<em>
				"packet_uuids": ["2892f8f5-2bc3-4102-b3a4-3b65e5edcb1b",
				"a668e813-25b0-4f83-88f9-a41abb31c93f"],
			</em>
			<br />
			<em> "limit":100,</em>
			<br />
			<em> "providers": ["HBO", "Discovery"],</em>
			<br />
			<em> "countries": ["Polska", "Niemcy"],</em>
			<br />
			<em> "billing_ids": ["HBO01", "HIS01"],</em>
			<br />
			<em> "channels": ["HBO OD", "Monolith"],</em>
			<br />
			<em> "studios": ["Kew Media", "Blue Ant International Limited"]</em>
			<br />
			<em>&#125;</em>
		</div>
		<div>{i18n.t('sections:algorithm_info.warning')}</div>
		<div>{i18n.t('sections:algorithm_info.empty_algorithm')}</div>
		<div>{i18n.t('sections:algorithm_info.empty_braces')}</div>
	</>
);
