import React from 'react';
import i18n from 'i18next';

// Import tabs component
import Settings from '../../Settings';
import HBOProviderImages from '../../settings_file/HBOProviderImages';

export const settingsTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <Settings />
	},
	{
		name: i18n.t('settings:tabs.hbo_logo'),
		path: 'hbo_logo',
		disableOnCreate: false,
		component: <HBOProviderImages />
	}
];
