import i18n from 'i18n';

export const BASE_FIELDS = (isEdit, defaultType) => [
	{
		name: 'type',
		type: 'select',
		label: i18n.t('packets:fields.type'),
		defaultValue: defaultType,
		required: true,
		disabled: true
	},
	{
		name: 'external_ids',
		type: 'text',
		label: i18n.t('packets:fields.external_ids'),
		required: true
	},
	{
		name: 'title',
		type: 'text',
		label: i18n.t('packets:fields.title'),
		required: true
	},
	{
		name: 'description',
		type: 'textarea',
		label: i18n.t('packets:fields.description'),
		required: true
	},
	{
		name: 'short_description',
		type: 'textarea',
		label: i18n.t('packets:fields.short_description'),
		required: true
	},
	{
		name: 'order',
		type: 'number',
		label: i18n.t('packets:fields.order'),
		required: true
	},
	{
		name: 'active',
		type: 'checkbox',
		label: i18n.t('packets:fields.active'),
		required: false
	},
	{
		name: 'created_at',
		type: 'text',
		label: i18n.t('packets:fields.created_at'),
		required: false,
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'updated_at',
		type: 'text',
		label: i18n.t('packets:fields.updated_at'),
		required: false,
		disabled: true,
		isVisible: !isEdit
	}
];
