import { combineReducers } from 'redux';
// Import reducers
import table from './sections_panel_reducer';
import form from './sections_form_reducer';
import products from './sections_products_reducer';
import pages from './sections_pages_reducer';
import files from './sections_files_reducer';

export default combineReducers({
	table,
	form,
	files,
	products,
	pages
});
