import { useSelector } from 'react-redux';

// Import variables
import { BLUEONLINE_MAIN_SYSTEM } from 'components/helpers/variables';

const useSystem = () => {
	const { currentSystem, user } = useSelector(({ auth }) => auth.authenticate);

	const isCurrentSystemMain = currentSystem === BLUEONLINE_MAIN_SYSTEM;

	return {
		currentSystem,
		mainSystem: user.system,
		isCurrentSystemMain,
		systemTreeDepth: user.system_tree_depth
	};
};

export default useSystem;
