import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import components
import {
	Ibox,
	IboxTitle,
	IboxContentBase,
	Wraper
} from 'components/common/layout';
import { Table } from 'antd';

// Import actions
import { fetchVodProductive } from 'store/actions';

// Import helpers
import { columns } from './helpers/vod_productive_stb_columns';

// Import variables
import { CHILDREN_KEY_SUBSTITUTE } from 'components/helpers/variables';

const VodProductiveSTB = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { id } = useParams();

	const { data, loading, error } = useSelector(({ vod }) => vod.productive_stb);

	const isLoaded = !loading;

	// Rename children key to assigned_children (children key has a functionality in antd Table)
	if (data.hasOwnProperty('children')) {
		data[CHILDREN_KEY_SUBSTITUTE] = data.children;
		delete data.children;
	}

	useEffect(() => {
		// Dispatch fetchVodProductive actions in vod_productive_stb
		fetchVodProductive(id)(dispatch);
		// eslint-disable-next-line
	}, []);

	return (
		<Wraper lg="12">
			<Ibox error={false} className="m-b-lg">
				<IboxTitle title={t('common:tabs.productive_stb')} />
				<IboxContentBase isLoaded={isLoaded} />
				{isLoaded && !error && (
					<Table
						dataSource={[data]}
						columns={columns}
						pagination={false}
						scroll={{ x: 1300 }}
						expandIconAsCell={false}
						expandIconColumnIndex={-1}
					/>
				)}
			</Ibox>
		</Wraper>
	);
};

export default VodProductiveSTB;
