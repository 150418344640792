import React from 'react';

// Import helpers
import { renderFields } from 'components/utilities/form/renderFields';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({ couponPromotionsList }) => {
	const selectResources = {
		promotionId: couponPromotionsList
	};

	return (
		<>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, selectResources })
			)}
		</>
	);
};
export default Fields;
