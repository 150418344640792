// ******************** PROVISIONING DATA TABLE ********************
export const FETCH_PROVISIONING_LOADING = 'FETCH_PROVISIONING_LOADING';
export const FETCH_PROVISIONING_SUCCESS = 'FETCH_PROVISIONING_SUCCESS';
export const FETCH_PROVISIONING_ERROR = 'FETCH_PROVISIONING_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_PROVISIONING_SEARCH_QUERY = 'SET_PROVISIONING_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_PROVISIONING_TABLE_SORTING =
	'SAVE_PROVISIONING_TABLE_SORTING';

// ******************** DELETE PROVISIONING_ITEM ********************
export const DELETE_PROVISIONING_ITEM_LOADING =
	'DELETE_PROVISIONING_ITEM_LOADING';
export const DELETE_PROVISIONING_ITEM_SUCCESS =
	'DELETE_PROVISIONING_ITEM_SUCCESS';
export const DELETE_PROVISIONING_ITEM_ERROR = 'DELETE_PROVISIONING_ITEM_ERROR';

// ******************** PROVISIONING_ITEM ********************
export const FETCH_PROVISIONING_ITEM_LOADING =
	'FETCH_PROVISIONING_ITEM_LOADING';
export const FETCH_PROVISIONING_ITEM_SUCCESS =
	'FETCH_PROVISIONING_ITEM_SUCCESS';
export const FETCH_PROVISIONING_ITEM_ERROR = 'FETCH_PROVISIONING_ITEM_ERROR';

// ******************** RESET PROVISIONING ITEM PRODUCT TABLE ********************
export const FORCE_FETCH_PROVISIONING_ITEM_PRODUCTS_TABLE =
	'FORCE_FETCH_PROVISIONING_ITEM_PRODUCTS_TABLE';

// ******************** FILES ********************
// -------------------- COVER --------------------
export const FETCH_PROVISIONING_ITEM_COVER_FILES_LOADING =
	'FETCH_PROVISIONING_ITEM_COVER_FILES_LOADING';
export const FETCH_PROVISIONING_ITEM_COVER_FILES_SUCCESS =
	'FETCH_PROVISIONING_ITEM_COVER_FILES_SUCCESS';
export const FETCH_PROVISIONING_ITEM_COVER_FILES_ERROR =
	'FETCH_PROVISIONING_ITEM_COVER_FILES_ERROR';
// -------------------- COVER_LARGE --------------------
export const FETCH_PROVISIONING_ITEM_COVER_LARGE_FILES_LOADING =
	'FETCH_PROVISIONING_ITEM_COVER_LARGE_FILES_LOADING';
export const FETCH_PROVISIONING_ITEM_COVER_LARGE_FILES_SUCCESS =
	'FETCH_PROVISIONING_ITEM_COVER_LARGE_FILES_SUCCESS';
export const FETCH_PROVISIONING_ITEM_COVER_LARGE_FILES_ERROR =
	'FETCH_PROVISIONING_ITEM_COVER_LARGE_FILES_ERROR';
// -------------------- COVER_SMALL --------------------
export const FETCH_PROVISIONING_ITEM_COVER_SMALL_FILES_LOADING =
	'FETCH_PROVISIONING_ITEM_COVER_SMALL_FILES_LOADING';
export const FETCH_PROVISIONING_ITEM_COVER_SMALL_FILES_SUCCESS =
	'FETCH_PROVISIONING_ITEM_COVER_SMALL_FILES_SUCCESS';
export const FETCH_PROVISIONING_ITEM_COVER_SMALL_FILES_ERROR =
	'FETCH_PROVISIONING_ITEM_COVER_SMALL_FILES_ERROR';
// -------------------- COVER_DETAIL --------------------
export const FETCH_PROVISIONING_ITEM_COVER_DETAIL_FILES_LOADING =
	'FETCH_PROVISIONING_ITEM_COVER_DETAIL_FILES_LOADING';
export const FETCH_PROVISIONING_ITEM_COVER_DETAIL_FILES_SUCCESS =
	'FETCH_PROVISIONING_ITEM_COVER_DETAIL_FILES_SUCCESS';
export const FETCH_PROVISIONING_ITEM_COVER_DETAIL_FILES_ERROR =
	'FETCH_PROVISIONING_ITEM_COVER_DETAIL_FILES_ERROR';
// -------------------- COVER_DETAIL_LARGE --------------------
export const FETCH_PROVISIONING_ITEM_COVER_DETAIL_LARGE_FILES_LOADING =
	'FETCH_PROVISIONING_ITEM_COVER_DETAIL_LARGE_FILES_LOADING';
export const FETCH_PROVISIONING_ITEM_COVER_DETAIL_LARGE_FILES_SUCCESS =
	'FETCH_PROVISIONING_ITEM_COVER_DETAIL_LARGE_FILES_SUCCESS';
export const FETCH_PROVISIONING_ITEM_COVER_DETAIL_LARGE_FILES_ERROR =
	'FETCH_PROVISIONING_ITEM_COVER_DETAIL_LARGE_FILES_ERROR';

// ******************** PRODUCTS ********************
// -------------------- FETCH --------------------
export const FETCH_PROVISIONING_ITEM_PRODUCTS_LOADING =
	'FETCH_PROVISIONING_ITEM_PRODUCTS_LOADING';
export const FETCH_PROVISIONING_ITEM_PRODUCTS_SUCCESS =
	'FETCH_PROVISIONING_ITEM_PRODUCTS_SUCCESS';
export const FETCH_PROVISIONING_ITEM_PRODUCTS_ERROR =
	'FETCH_PROVISIONING_ITEM_PRODUCTS_ERROR';
// -------------------- DELETE --------------------
export const DELETE_PROVISIONING_ITEM_PRODUCTS_LOADING =
	'DELETE_PROVISIONING_ITEM_PRODUCTS_LOADING';
export const DELETE_PROVISIONING_ITEM_PRODUCTS_SUCCESS =
	'DELETE_PROVISIONING_ITEM_PRODUCTS_SUCCESS';
export const DELETE_PROVISIONING_ITEM_PRODUCTS_ERROR =
	'DELETE_PROVISIONING_ITEM_PRODUCTS_ERROR';
// -------------------- SET SEARCH QUERY --------------------
export const SET_PROVISIONING_ITEM_PRODUCTS_QUERY =
	'SET_PROVISIONING_ITEM_PRODUCTS_QUERY';
// -------------------- SAVE SORTING OPTIONS  --------------------
export const SAVE_PROVISIONING_ITEM_PRODUCTS_TABLE_SORTING =
	'SAVE_PROVISIONING_TABLE_SORTING';

// ******************** MATERIALS ********************
// -------------------- FETCH --------------------
export const FETCH_PROVISIONING_ITEM_MATERIALS_LOADING =
	'FETCH_PROVISIONING_ITEM_MATERIALS_LOADING';
export const FETCH_PROVISIONING_ITEM_MATERIALS_SUCCESS =
	'FETCH_PROVISIONING_ITEM_MATERIALS_SUCCESS';
export const FETCH_PROVISIONING_ITEM_MATERIALS_ERROR =
	'FETCH_PROVISIONING_ITEM_MATERIALS_ERROR';
// -------------------- UPDATE --------------------
export const UPDATE_PROVISIONING_ITEM_MATERIALS =
	'UPDATE_PROVISIONING_ITEM_MATERIALS';
export const UPDATE_PROVISIONING_ITEM_MATERIALS_ERROR =
	'UPDATE_PROVISIONING_ITEM_MATERIALS_ERROR';
// -------------------- UPDATE --------------------
export const DELETE_PROVISIONING_ITEM_MATERIALS =
	'DELETE_PROVISIONING_ITEM_MATERIALS';
export const DELETE_PROVISIONING_ITEM_MATERIALS_ERROR =
	'DELETE_PROVISIONING_ITEM_MATERIALS_ERROR';

// ******************** PACKETS ASSIGNED ********************
// -------------------- FETCH PACKETS ASSIGNED - TABLE DATA --------------------
export const FETCH_PROVISIONING_PACKETS_ASSIGNED_LOADING =
	'FETCH_PROVISIONING_PACKETS_ASSIGNED_LOADING';
export const FETCH_PROVISIONING_PACKETS_ASSIGNED_SUCCESS =
	'FETCH_PROVISIONING_PACKETS_ASSIGNED_SUCCESS';
export const FETCH_PROVISIONING_PACKETS_ASSIGNED_ERROR =
	'FETCH_PROVISIONING_PACKETS_ASSIGNED_ERROR';
export const FETCH_PROVISIONING_PACKETS_ASSIGNED_PAYMENT_MODEL_TYPE_ERROR =
	'FETCH_PROVISIONING_PACKETS_ASSIGNED_PAYMENT_MODEL_TYPE_ERROR';

// -------------------- SET SEARCH QUERY --------------------
export const SET_PROVISIONING_PACKETS_ASSIGNED_SEARCH_QUERY =
	'SET_PROVISIONING_PACKETS_ASSIGNED_SEARCH_QUERY';
