// ******************** COUPONS DATA TABLE ********************
export const FETCH_COUPONS_LOADING = 'FETCH_COUPONS_LOADING';
export const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS';
export const FETCH_COUPONS_ERROR = 'FETCH_COUPONS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_COUPONS_SEARCH_QUERY = 'SET_COUPONS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_COUPONS_TABLE_SORTING = 'SAVE_COUPONS_TABLE_SORTING';

// ******************** DELETE COUPON ********************
export const DELETE_COUPON_LOADING = 'DELETE_COUPON_LOADING';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_ERROR = 'DELETE_COUPON_ERROR';

// ******************** FETCH COUPON ********************
export const FETCH_COUPON_LOADING = 'FETCH_COUPON_LOADING';
export const FETCH_COUPON_SUCCESS = 'FETCH_COUPON_SUCCESS';
export const FETCH_COUPON_ERROR = 'FETCH_COUPON_ERROR';
