import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchSubscriberInvoice } from 'store/actions';

// Import components
import { FormTemplate } from 'components/common/templates';
import Fields from './fields/Fields';

const SubscribersInvoice = ({
	fetchSubscriberInvoice,
	error,
	isLoaded,
	initialValues
}) => {
	return (
		<>
			<FormTemplate
				fetchResourceToEdit={fetchSubscriberInvoice}
				error={error}
				isLoaded={isLoaded}
			>
				{({ isEdit }) => (
					<Form
						initialValues={initialValues[0]}
						onSubmit={() => {}}
						render={({ handleSubmit, submitting }) => (
							<form onSubmit={handleSubmit}>
								<Fields
									isEdit={isEdit}
									submitting={submitting}
									invoiceType={initialValues?.type}
								/>
							</form>
						)}
					/>
				)}
			</FormTemplate>
		</>
	);
};

SubscribersInvoice.propTypes = {
	initialValues: PropTypes.array.isRequired,
	fetchSubscriberInvoice: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ subscribers: { invoice } }) => ({
	initialValues: [invoice.edit],
	error: invoice.error,
	isLoaded: invoice.isLoaded
});

export default compose(
	connect(mapStateToProps, { fetchSubscriberInvoice }),
	withRouter
)(SubscribersInvoice);
