import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import actions
import { fetchSettingsLogo, dictionaryVODProviders } from 'store/actions';

// Import components
import Image from 'components/utilities/images/Image';
import InfoTitle from 'components/utilities/info/InfoTitle';
import { Spin } from 'antd';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import {
	IMAGE_SIZE_INFO,
	HBO_LOGO_INFO
} from 'components/helpers/info_helpers/info_texts';

const HBOProviderImages = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	useEffect(() => {
		// Dispatch  action in channels_files
		dictionaryVODProviders()(dispatch);
		// eslint-disable-next-line
	}, []);
	const { VODProviders } = useSelector((state) => state.dictionary);

	// hbo media type and id
	const HBOMediaType = Object.keys(VODProviders)[0];
	const HBOCustomId = Object.values(VODProviders)[0];

	return (
		<>
			<InfoTitle title={`${IMAGE_SIZE_INFO} ${HBO_LOGO_INFO}`} />
			{Object.entries(VODProviders).length ? (
				<Image
					title={t('common:images.logo')}
					fetchResourcesAction={(id) =>
						fetchSettingsLogo(id, HBOMediaType)(dispatch)
					}
					collectionType="logo"
					mediaType={HBOMediaType}
					storePathSelector="settings"
					customId={HBOCustomId}
					isDarkBackground={true}
				/>
			) : (
				<div className="d-flex justify-content-center">
					<Spin size="large" />
				</div>
			)}
		</>
	);
};

export default HBOProviderImages;
