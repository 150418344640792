import React from 'react';
import { useDispatch } from 'react-redux';

// Import actions
import {
	fetchMainItemCover,
	fetchMainItemCoverLarge,
	fetchMainItemCoverSmall,
	fetchMainItemCoverDetail,
	fetchMainItemCoverDetailLarge
} from 'store/actions';

// Import components
import Image from 'components/utilities/images/Image';
import InfoTitleCollapse from 'components/utilities/info/InfoTitleCollapse';

// Import helpers
import {
	PACKETS_IMAGE_SIZE_INFO,
	MAIN_IMAGES_REQUIREMENTS
} from 'components/helpers/info_helpers/info_texts';

// Import translation
import { useTranslation } from 'react-i18next';

const MainImages = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	return (
		<>
			<InfoTitleCollapse
				title={PACKETS_IMAGE_SIZE_INFO}
				requirements={MAIN_IMAGES_REQUIREMENTS}
			/>
			<Image
				title={t('common:images.cover')}
				fetchResourcesAction={(id) => fetchMainItemCover(id)(dispatch)}
				collectionType="cover"
				mediaType="packet"
				storePathSelector="main"
			/>
			<Image
				title={t('common:images.cover_large')}
				fetchResourcesAction={(id) => fetchMainItemCoverLarge(id)(dispatch)}
				collectionType="cover_large"
				mediaType="packet"
				storePathSelector="main"
			/>
			<Image
				title={t('common:images.cover_small')}
				fetchResourcesAction={(id) => fetchMainItemCoverSmall(id)(dispatch)}
				collectionType="cover_small"
				mediaType="packet"
				storePathSelector="main"
			/>
			<Image
				title={t('common:images.cover_detail')}
				fetchResourcesAction={(id) => fetchMainItemCoverDetail(id)(dispatch)}
				collectionType="cover_detail"
				mediaType="packet"
				storePathSelector="main"
			/>
			<Image
				title={t('common:images.cover_detail_large')}
				fetchResourcesAction={(id) =>
					fetchMainItemCoverDetailLarge(id)(dispatch)
				}
				collectionType="cover_detail_large"
				mediaType="packet"
				storePathSelector="main"
			/>
		</>
	);
};

export default MainImages;
