import services from 'services/services';
import * as types from '../types';
import { generatePath } from 'react-router-dom';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// Import variables
import { TAG_DETAIL_API } from 'components/helpers/api';

// ******************** FETCH TAG DETAILS ********************
export const fetchTagItem = (id) => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_TAG_DETAILS_LOADING,
			payload: true
		});

		const url = generatePath(TAG_DETAIL_API, { id });

		const response = await services.get(url);

		dispatch({
			type: types.FETCH_TAG_DETAILS_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_TAG_DETAILS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
