import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { sendNewPasswords } from 'store/actions';
import { useDispatch } from 'react-redux';

// Import validation
import validate from './helpers/validate';

// Import components
import Fields from './Fields';
import { Button } from 'reactstrap';

// Import translation
import i18n from 'i18next';

const ResetPasswordForm = ({ hash }) => {
	const dispatch = useDispatch();
	const handleOnSubmit = async (values) => {
		await sendNewPasswords(values, hash)(dispatch);
	};

	return (
		<Form
			initialValues={{ password: '', password_confirmation: '' }}
			onSubmit={handleOnSubmit}
			validate={validate}
			render={({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} className="m-t">
					<Fields submitting={submitting} />
					<Button
						type="submit"
						className="btn__signin m-t"
						disabled={submitting}
						color="primary"
						size="lg"
						block
					>
						{i18n.t('common:buttons.submit')}
					</Button>
				</form>
			)}
		/>
	);
};
ResetPasswordForm.propTypes = {
	hash: PropTypes.string.isRequired
};

export default ResetPasswordForm;
