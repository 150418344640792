import { combineReducers } from 'redux';
// Import reducers
import table from './vod_panel_reducer';
import form from './vod_form_reducer';
import files from './vod_files_reducer';
import materials from './vod_materials_reducer';
import series_form from './vod_series_form_reducer';
import season_table from './vod_seasons_table_reducer';
import season_form from './vod_seasons_form_reducer';
import availability from './vod_availabilty/vod_availability_reducer';
import episodes_table from './vod_episodes_table_reducer';
import episode_form from './vod_episodes_form_reducer';
import prices from './vod_prices_reducer';
import encoding from './vod_encoding_reducer';
import packets_assigned from './vod_packets_assigned_reducer';
import productive_stb from './vod_productive_stb_reducer';
import sections_assigned from './vod_sections_assigned_reducer';
import catalogs_assigned from './vod_catalogs_assigned_reducer';

export default combineReducers({
	table,
	form,
	files,
	materials,
	series_form,
	season_table,
	season_form,
	availability,
	episodes_table,
	episode_form,
	prices,
	encoding,
	packets_assigned,
	productive_stb,
	sections_assigned,
	catalogs_assigned
});
