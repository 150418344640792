import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import utilites
import FetchMultiselectLabel from 'components/common/inputs/input-label/multiselect/FetchMultiselectLabel';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { EPISODES_MODAL_SEARCH_LENGTH } from 'components/helpers/variables';

const Fields = ({ submitting }) => {
	const { t } = useTranslation();

	const url = `/vod/newList?order[0][column]=4&order[0][dir]=asc&start=0&length=${EPISODES_MODAL_SEARCH_LENGTH}&subtype=vod`;

	return (
		<Field
			key="episodes-1"
			disabled={submitting}
			name="episodes"
			type="select"
			mode="multiple"
			placeholder="label"
			label="label"
			id="episodes"
			required={false}
		>
			{({ input: { name, value, onChange } }) => (
				<FetchMultiselectLabel
					name={name}
					value={value}
					onChange={onChange}
					placeholder={t('vod:episodes:form.select_episodes')}
					url={url}
				/>
			)}
		</Field>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
