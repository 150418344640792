import i18n from 'i18next';

import { select_operator } from './select_type_options';

export const defaultFieldValues = {
	provider: null,
	os: null,
	os_version: null,
	operator: select_operator[0].value,
	max_bitrate: null,
	min_bitrate: null
};

export const BASE_FIELDS = [
	{
		name: 'provider_id',
		label: i18n.t('ingester:video_assets_params.form.fields.provider_id'),
		type: 'custom-names',
		required: true,
		mode: '',
		showSearch: true
	},
	{
		name: 'os',
		label: i18n.t('ingester:video_assets_params.form.fields.os'),
		type: 'text',
		required: true
	},
	{
		name: 'operator',
		label: i18n.t('ingester:video_assets_params.form.fields.operator'),
		type: 'select',
		required: true,
		fieldInfo: i18n.t('ingester:video_assets_params.form.fields_info.operator')
	},
	{
		name: 'os_version',
		label: i18n.t('ingester:video_assets_params.form.fields.os_version'),
		type: 'text',
		required: true
	},
	{
		name: 'max_bitrate',
		label: i18n.t('ingester:video_assets_params.form.fields.max_bitrate'),
		type: 'number',
		required: false,
		step: 1
	},
	{
		name: 'min_bitrate',
		label: i18n.t('ingester:video_assets_params.form.fields.min_bitrate'),
		type: 'number',
		required: false,
		step: 1
	}
];
