import i18n from 'i18next';

export const VIDEO_ASSETS_PARAMS_FILTER_FIELDS = [
	{
		name: 'provider_id',
		label: i18n.t('filters:fields.provider'),
		type: 'custom-names',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'os',
		label: i18n.t('filters:fields.os'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'os_version',
		label: i18n.t('filters:fields.os_version'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'operator',
		label: i18n.t('filters:fields.operator'),
		type: 'select',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'max_bitrate',
		label: i18n.t('filters:fields.max_bitrate'),
		type: 'number',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'min_bitrate',
		label: i18n.t('filters:fields.min_bitrate'),
		type: 'number',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	}
];
