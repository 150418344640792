import React, { useContext, useEffect } from 'react';
import { Form } from 'react-final-form';
import services from 'services/services';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	dictionaryPlatformsTypes,
	dictionaryDRMTemplates
} from 'store/actions';

// Import helpers
import { MATERIALS_TYPES } from 'components/helpers/variables';
import { convertObjectToValuePairs } from 'components/helpers/convert';
import {
	createMaterialOptions,
	createInitialValues,
	convertAssets,
	MATERIAL_PLATFORM_TYPES
} from './helpers/index';

// Import components
import { Button, FormGroup, Col } from 'reactstrap';
import Fields from './fields/Fields';

// Import utilities
import { createFormNotification } from 'components/utilities/notifications/index';

// Validate
import validate from './validation';

// Import context
import { MaterialsContext } from './materials_context/MaterialsContext';

// Import translation
import { useTranslation } from 'react-i18next';

const MaterialsForm = ({
	resources: { id, type, assets },
	dictionaryPlatformsTypes,
	dictionaryDRMTemplates,
	platformList,
	drmTemplatesList
}) => {
	const {
		toggleFormModal,
		updateData,
		isEdit,
		toggleEdit,
		assetId,
		setAssetId
	} = useContext(MaterialsContext);

	const { t } = useTranslation();

	useEffect(() => {
		dictionaryPlatformsTypes();
		dictionaryDRMTemplates();
		// eslint-disable-next-line
	}, []);

	// Convert platform types to proper list and add default value (null)
	const convertedPlatformList = platformList.map((platform) => ({
		name: platform,
		value: platform
	}));
	const platformTypes = [
		{
			name: MATERIAL_PLATFORM_TYPES.default,
			value: MATERIAL_PLATFORM_TYPES.default
		},
		...convertedPlatformList
	];

	const { assetData } = convertAssets({ assets, assetId });

	// Submit function that sends different requests to different urls depending on isEdit flag
	const handleOnSubmit = async (values) => {
		try {
			const { drm_params_template_key, ...optionsValues } = values;

			const options = createMaterialOptions(optionsValues, assetData);

			const url = `/player/video/${
				isEdit ? `asset/${assetId}/update` : `${id}/asset/create`
			}`;

			const drmTemplateUrl = `/player/video/asset/${assetId}/drmParamsTemplateKey`;

			const drmTemplateKey =
				drm_params_template_key === 'none' ? null : drm_params_template_key;

			// sending request with form data
			const { data } = await services.post(url, options);

			if (isEdit) {
				await services.post(drmTemplateUrl, {
					drm_params_template_key: drmTemplateKey
				});

				data.drm_params_template_key = drmTemplateKey;
			}

			// updating data in Redux, so new data is immidately displayed in table without sending additional requests
			updateData(data, type, id);

			// close modal
			toggleFormModal();
			// show success notification
			createFormNotification(!isEdit, 'asset');

			// if we are editing, set isEditFlag to off and reset id of edited asset in contex state
			if (isEdit) {
				toggleEdit();
				setAssetId(null);
			}
		} catch (error) {
			// display error notification to client
			createFormNotification(
				!isEdit,
				'asset',
				'error',
				'materials_form_error_message',
				10
			);
		}
	};

	const initialValues = createInitialValues(isEdit, assetData);

	// drmKey passed to fields to be displayed as placeholder
	const isDrmKey = isEdit ? assetData.drm_key : null;
	const selectResources = {
		format: convertObjectToValuePairs(MATERIALS_TYPES),
		platform: platformTypes,
		drm_params_template_key: drmTemplatesList
	};

	return (
		<Form
			onSubmit={handleOnSubmit}
			validate={validate}
			initialValues={initialValues}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit}>
					<Fields drmKey={isDrmKey} selectResources={selectResources} />
					<FormGroup>
						<Col
							className="d-flex justify-content-end m-t-md"
							sm={{ size: '10', offset: 2 }}
						>
							<Button type="submit" disabled={submitting}>
								{t('common:buttons.submit')}
							</Button>
						</Col>
					</FormGroup>
				</form>
			)}
		</Form>
	);
};

const mapStateToProps = ({ dictionary: { platformTypes, drmTemplates } }) => {
	return {
		platformList: platformTypes,
		drmTemplatesList: drmTemplates
	};
};

MaterialsForm.propTypes = {
	id: PropTypes.number,
	type: PropTypes.string,
	assets: PropTypes.array,
	dictionaryPlatformsTypes: PropTypes.func,
	dictionaryDRMTemplates: PropTypes.func,
	platformList: PropTypes.array,
	drmTemplatesList: PropTypes.array
};

export default connect(mapStateToProps, {
	dictionaryPlatformsTypes,
	dictionaryDRMTemplates
})(MaterialsForm);
