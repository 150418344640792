import { useState, useCallback } from 'react';

/**
 * Small utility hook to toggle UI elements
 * @param {bool} defaultValue - set default visibility = ON / OFF
 * @returns {array} state - state property, toggle - toggle ON / OFF function
 */
const useToggle = (initialState = false) => {
	const [state, setState] = useState(initialState);
	const toggle = useCallback(() => setState((state) => !state), []);

	return [state, toggle];
};

export default useToggle;
