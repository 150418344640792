import { useEffect, useState } from 'react';
import services from 'services/services';
import moment from 'moment';

/**
 * Custom hook that prepares initial dates for price form based
 * on product availability since and till values
 */
const usePriceDefaultValues = ({ productType, itemID }) => {
	const [initialDates, setInitialDates] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	// Function that fetches and converts data to
	const prepareInitialDates = async () => {
		try {
			setIsLoading(true);
			// fetch data
			const {
				data: { data }
			} = await services.get(
				`/timetables/availability/listByProduct/${productType}/${itemID}`
			);

			setIsLoading(false);
			// if there is data convert it and save in state
			if (data.length) {
				const convertedData = convertRawDates(data);
				const preparedDates = findDates(convertedData);
				setInitialDates(preparedDates);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	// convert data about availabilty to object with two values with arrays
	// of dates (moments) assigned
	const convertRawDates = (data) => {
		const convertedData = data.reduce(
			(obj, { since, till }) => {
				return {
					since: [...obj.since, moment(since)],
					till: [...obj.till, moment(till)]
				};
			},
			{ since: [], till: [] }
		);
		return convertedData;
	};

	// function that returns properly selected dates.
	// Start date is the earliest from availability dates or is current date if
	// earliest available is in the past. End date is the furthest in the future.
	const findDates = (convertedDates) => {
		const { since, till } = convertedDates;
		let startDate = undefined;
		let endDate = undefined;

		const currentDate = moment().startOf('day'); //set current date and time to 00:00
		const minDate = moment.min(since);
		const maxDate = moment.max(till);

		// check if availability dates aren't in the past
		if (maxDate.isBefore(currentDate)) return null;

		// if min date is before current date set current date as start date
		startDate = minDate.isBefore(currentDate) ? currentDate : minDate;
		endDate = maxDate;
		return [startDate, endDate];
	};

	useEffect(() => {
		prepareInitialDates();
		// eslint-disable-next-line
	}, []);

	return { initialDates, isLoading };
};

export default usePriceDefaultValues;
