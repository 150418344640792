import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactTable from 'react-table';

// Import utils
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import useSelectTable from './useSelectTable';

// Import components
import CustomPagination from 'components/utilities/table/pagination/CustomPagination';

const CheckboxTable = checkboxHOC(ReactTable);

const SelectTable = React.forwardRef(
	(
		{
			resources,
			columns,
			itemID,
			fetchDataAction,
			defaultSorted,
			resourcesForFetchAction,
			massDeleteComponent = () => null
		},
		ref
	) => {
		const { t } = useTranslation();
		const {
			checkboxProps,
			totalResults,
			data,
			page,
			pages,
			isLoading,
			pageSize,
			filters,
			defaultSortedData,
			fetchData,
			selection,
			setSelection,
			getTrProp,
			handleTableSize,
			setTableRef
		} = useSelectTable({
			ref,
			resources,
			fetchDataAction,
			itemID,
			defaultSorted,
			resourcesForFetchAction
		});

		return (
			<CheckboxTable
				PaginationComponent={(props) => (
					<CustomPagination
						tableProps={props}
						totalResults={totalResults}
						onChangeTableSize={handleTableSize}
					>
						{massDeleteComponent({
							selection,
							ref,
							totalResults,
							setSelection
						})}
					</CustomPagination>
				)}
				ref={setTableRef}
				manual
				data={data}
				columns={columns}
				pages={pages}
				page={page}
				filtered={filters}
				showPageSizeOptions={false}
				pageSize={pageSize}
				loading={isLoading}
				defaultSorted={defaultSortedData}
				noDataText={t('common:no_data')}
				previousText={t('common:table_buttons.prev')}
				nextText={t('common:table_buttons.next')}
				className="-striped -select"
				onFetchData={fetchData}
				{...checkboxProps}
				getTrProps={getTrProp}
				showPagination={true}
				showPaginationTop={true}
				minRows={5}
			/>
		);
	}
);

SelectTable.propTypes = {
	resources: PropTypes.object,
	columns: PropTypes.array,
	itemID: PropTypes.string,
	fetchDataAction: PropTypes.func,
	defaultSorted: PropTypes.object,
	resourcesForFetchAction: PropTypes.func,
	massDeleteComponent: PropTypes.func
};

export default React.memo(SelectTable);
