import React from 'react';

// Import components
import {
	ReactServerSideTable,
	ReactMassChangesTable
} from 'components/utilities/table';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

const PanelPageTable = ({ isMassChangeTable, ...restProps }) => {
	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	const renderTable = () =>
		isMassChangeTable ? (
			<ReactMassChangesTable
				{...restProps}
				isReadOnly={isReadOnly}
				keyField="uuid"
				keyType="mass_changes"
			/>
		) : (
			<ReactServerSideTable {...restProps} />
		);

	return renderTable();
};

export default PanelPageTable;
