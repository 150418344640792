import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup } from 'reactstrap';
import { DatePicker } from 'antd';
import moment from 'moment';
import { FieldInfo, FieldError, Label } from 'components/common/inputs';

const { RangePicker } = DatePicker;

const RangePickerLabel = ({
	input,
	id,
	label,
	placeholder,
	labelColumn,
	inputColumn,
	showTime,
	format,
	rangeFormat,
	meta: { touched, error, submitError },
	required,
	fieldInfo,
	...inputProps
}) => {
	return (
		<FormGroup row>
			<Label
				id={id}
				columnWidth={labelColumn}
				required={required}
				content={label}
			/>
			<Col sm={inputColumn}>
				<RangePicker
					{...input}
					{...inputProps}
					separator="~"
					value={input.value || null}
					id={id}
					ranges={{
						Today: [moment().startOf('day'), moment().endOf('day')],
						'This Month': [moment().startOf('month'), moment().endOf('month')],
						Month: [
							moment().startOf('day'),
							moment()
								.add(1, 'month')
								.endOf('day')
						],
						'Two Months': [
							moment().startOf('day'),
							moment()
								.add(2, 'month')
								.endOf('day')
						],
						Quarter: [
							moment().startOf('day'),
							moment()
								.add(1, 'quarter')
								.endOf('day')
						]
					}}
					placeholder={placeholder}
					showTime={showTime}
					format={rangeFormat || format}
					status={touched && error && 'error'}
				/>
				<FieldError
					error={touched && (error || submitError)}
					inputName={input.name}
					content={error || submitError}
				/>
				<FieldInfo info={fieldInfo} />
			</Col>
		</FormGroup>
	);
};

RangePickerLabel.defaultProps = {
	required: false,
	labelColumn: 2,
	inputColumn: 10,
	showTime: false,
	format: 'YYYY-MM-DD'
};

RangePickerLabel.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	placeholder: PropTypes.array.isRequired,
	inputColumn: PropTypes.number,
	labelColumn: PropTypes.number,
	showTime: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	format: PropTypes.string,
	rangeFormat: PropTypes.string,
	meta: PropTypes.object,
	required: PropTypes.bool,
	fieldInfo: PropTypes.string
};

export default RangePickerLabel;
