import React from 'react';
import PropTypes from 'prop-types';

// Import utlis
import { renderFields } from 'components/utilities/form';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({ submitting, type, algorithm_id }) => {
	const selectResources = {
		type,
		algorithm_id
	};

	return (
		<React.Fragment>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired,
	algorithm_id: PropTypes.array.isRequired
};

export default Fields;
