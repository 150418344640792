import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { sortableElement } from 'react-sortable-hoc';
import { Card } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

// Import helpers
import { getImgUrl } from '../helpers/index';
import { getRedirectUrl } from 'components/helpers/url';

// Import placeholder
import placeholder from 'images/no_image_placeholder.svg';

// Import styles
import { StyledCard, GridItemCover } from './styles';

const { Meta } = Card;

const SortableItem = sortableElement(
	({ value, type, subtype, img, handleRemoveItem, id, parentId = '' }) => {
		const itemType = type !== subtype && subtype ? `${type}(${subtype})` : type;

		const handleRemove = () => handleRemoveItem(id);
		const pathname = getRedirectUrl({ type, id, subtype, parentId });

		const handleEdit = useCallback(
			(e) => {
				e.preventDefault();
				if (pathname) {
					window.open(pathname, '_blank');
				}
			},
			[pathname]
		);
		const imgUrl = getImgUrl(img);

		return (
			<li className="sortable-list__li">
				<StyledCard
					hoverable
					size="small"
					actions={[
						<EditOutlined key="edit" onClick={handleEdit} />,
						<DeleteOutlined key="delete" onClick={handleRemove} />
					]}
					cover={
						<GridItemCover
							src={imgUrl ?? placeholder}
							alt={value}
							loading="lazy"
						/>
					}
				>
					<Meta
						title={value ? value : '-----'}
						description={
							<div className="d-flex align-items-center justify-content-between">
								<span>{itemType ?? '---'}</span>
							</div>
						}
					/>
				</StyledCard>
			</li>
		);
	}
);

SortableItem.propTypes = {
	value: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	handleRemoveItem: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
	img: PropTypes.object,
	subtype: PropTypes.string
};

export default SortableItem;
