import React from 'react';
import PropTypes from 'prop-types';

// Import utilities
import { renderFields } from 'components/utilities/form';

import { BASE_FIELDS } from './input_fields';

const Fields = ({ submitting }) => {
	return (
		<React.Fragment>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
