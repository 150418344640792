// ******************** AGREEMENTS DATA TABLE ********************
export const FETCH_AGREEMENTS_LOADING = 'FETCH_AGREEMENTS_LOADING';
export const FETCH_AGREEMENTS_SUCCESS = 'FETCH_AGREEMENTS_SUCCESS';
export const FETCH_AGREEMENTS_ERROR = 'FETCH_AGREEMENTS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_AGREEMENTS_SEARCH_QUERY = 'SET_AGREEMENTS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_AGREEMENTS_TABLE_SORTING = 'SAVE_AGREEMENTS_TABLE_SORTING';

// ******************** DELETE AGREEMENT ********************
export const DELETE_AGREEMENT_LOADING = 'DELETE_AGREEMENT_LOADING';
export const DELETE_AGREEMENT_SUCCESS = 'DELETE_AGREEMENT_SUCCESS';
export const DELETE_AGREEMENT_ERROR = 'DELETE_AGREEMENT_ERROR';

// ******************** AGREEMENT DATA FORM ********************
export const FETCH_AGREEMENT_LOADING = 'FETCH_AGREEMENT_LOADING';
export const FETCH_AGREEMENT_SUCCESS = 'FETCH_AGREEMENT_SUCCESS';
export const FETCH_AGREEMENT_ERROR = 'FETCH_AGREEMENT_ERROR';
