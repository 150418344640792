import { useEffect, useState } from 'react';
import services from 'services/services';

// Import helpers
import { checkFetchErrors } from 'components/helpers/error';
import { PROMOTIONS_PRODUCTS_PREVIEW_LIMIT } from 'components/helpers/variables';

const usePromotionsProductsPreview = (promotionId, productType) => {
	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState([]); // list of products to display
	const [isEnd, setIsEnd] = useState(false); // bool that checks if there is more data to display
	const [fetchError, setFetchError] = useState(false);

	const fetchPromotionsProductsPreview = async () => {
		try {
			// set loading only when there is no data. In other case spinner is handled by InfiniteScroll
			if (products.length === 0) setLoading(true);

			const url = `/promotions/${promotionId}/products/${productType}?offset=${products.length}&limit=${PROMOTIONS_PRODUCTS_PREVIEW_LIMIT}`;

			const { data } = await services.get(url);

			setProducts([...products, ...data.data]);
			setLoading(false);
			if (
				data.data.length === 0 ||
				data.data.length < PROMOTIONS_PRODUCTS_PREVIEW_LIMIT
			) {
				setIsEnd(true);
			}
		} catch (error) {
			setFetchError(checkFetchErrors(error));
		}
	};

	useEffect(() => {
		fetchPromotionsProductsPreview();
		// eslint-disable-next-line
	}, []);

	return {
		products,
		isEnd,
		loading,
		fetchError,
		fetchPromotionsProductsPreview
	};
};

export default usePromotionsProductsPreview;
