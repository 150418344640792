import styled from 'styled-components';

export const Error = styled.div`
	display: flex;
	align-items: center;
	font-size: 2rem;
	span {
		font-size: 3.5rem;
		font-weight: bold;
	}
	p {
		margin: auto 1.5rem;
		color: #1890ff;
	}
`;
