import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setVideoAssetsParamsQuery,
	fetchVideoAssetsParams,
	deleteVideoAssetsParams,
	fetchProviders,
	createFiltersSuggestions
} from 'store/actions';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './video_assets_params_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import select values
import { select_operator } from './video_assets_params_form/fields/select_type_options';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const VideoAssetsParams = ({
	error,
	setVideoAssetsParamsQuery,
	fetchVideoAssetsParams,
	deleteVideoAssetsParams,
	fetchProviders,
	resources,
	filters,
	createFiltersSuggestions
}) => {
	const { t } = useTranslation();
	// Actions to fetch data for filters
	const filterActions = {
		fetchProviders
	};

	// add select operators based on what is added in form
	const modifiedFilters = { ...filters, operator: select_operator };

	// execute filters logic
	useFilterLogic({
		filterActions,
		createFiltersSuggestions,
		filters: modifiedFilters
	});

	const { columns } = resources;
	return (
		// Dispatch fetchVideoAssetsParams, deleteVideoAssetsParams action in subscribers_panel
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="ingester/video-assets-params"
			typeText="video-assets-params"
			addLinkText={t('ingester:video_assets_params.buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchVideoAssetsParams}
			deleteItemAction={deleteVideoAssetsParams}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setVideoAssetsParamsQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

VideoAssetsParams.propTypes = {
	error: PropTypes.bool.isRequired,
	setVideoAssetsParamsQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchVideoAssetsParams: PropTypes.func.isRequired,
	deleteVideoAssetsParams: PropTypes.func.isRequired,
	fetchProviders: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired
};

const mapStateToProps = ({ ingester: { video_assets_params }, providers }) => {
	return {
		error: video_assets_params.table.error,
		resources: video_assets_params.table,
		filters: {
			providers: providers.table.data
		}
	};
};

export default connect(mapStateToProps, {
	setVideoAssetsParamsQuery,
	fetchVideoAssetsParams,
	deleteVideoAssetsParams,
	fetchProviders,
	createFiltersSuggestions
})(VideoAssetsParams);
