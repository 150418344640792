import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

import { parseLocalChannelData } from 'components/views/local_channels/helpers';

// ******************** FETCH CHANNELS - TABLE DATA ********************
export const fetchLocalChannels = (options) => async (dispatch, getState) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_LOCAL_CHANNELS_LOADING,
			payload: true
		});
		const {
			channels: {
				table: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `/channel/localChannelList?${filterUrlQuery}${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex
		)}`;

		const { data } = await services.get(url);

		const parsedData = { ...data, data: data.data.map(parseLocalChannelData) };

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_LOCAL_CHANNELS_SUCCESS,
			payload: { ...parsedData, pages, page: options.page, startIndex }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_LOCAL_CHANNELS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE CHANNEL ********************
export const deleteLocalChannel = (channel_uuid, tag_id) => async (
	dispatch
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DELETE_LOCAL_CHANNEL_LOADING,
			payload: true
		});

		await services.post(`/tags/${channel_uuid}/removeResourceTag`, {
			resource_type: 'channel',
			tag_id
		});
		// Dispatch an action with data
		dispatch({
			type: types.DELETE_LOCAL_CHANNEL_SUCCESS,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DELETE_LOCAL_CHANNEL_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET SEARCH QUERY ********************
export const setLocalChannelsQuery = (filter_params) => ({
	type: types.SET_LOCAL_CHANNELS_SEARCH_QUERY,
	payload: filter_params
});
