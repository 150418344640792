import React from 'react';
import PropTypes from 'prop-types';

function SignInAlert({ title, message }) {
	return (
		<div className="sign_in_alert">
			<div className="d-flex sign_in_alert__container">
				<i className="fa fa-times sign_in_alert__icon" />
				<div>
					<h4 className="sign_in_alert__title">{title}</h4>
					<p className="sign_in_alert__message">{message}</p>
				</div>
			</div>
		</div>
	);
}

SignInAlert.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string
};

export default SignInAlert;
