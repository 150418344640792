import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

// Import helpers
import { initialValues, validate, BASE_FIELDS } from './helpers';
import {
	TARGET_TYPES_VALUES,
	TARGET_PLATFORMS
} from 'components/helpers/variables';
import services from 'services/services';

// Import utilities
import { renderFields } from 'components/utilities/form';
import useSelectSystem from 'components/layouts/top_header/SelectSystem/useSelectSystem';

const ImportTargetsForm = ({ toggle, refetchData }) => {
	const { t } = useTranslation();
	const { id } = useParams();
	const { currentSystem, subSystems } = useSelectSystem();

	const handleOnSubmit = async (values) => {
		const formData = new FormData();
		formData.append('campaign_id', values.campaign_id);
		formData.append('target_type', values.target_type);
		formData.append('target_platforms[0]', values.target_platforms);
		formData.append('target_system', values.target_system);
		formData.append('targets', values.targets[0]);

		try {
			await services.post('/subscribers/campaigns/targets', formData, {
				headers: { 'Content-Type': 'multipart/form-data' }
			});
			notification.success({
				message: t(
					'marketing_campaigns:targets.notifications.success.csv_title'
				),
				description: t(
					'marketing_campaigns:targets.notifications.success.csv_description'
				)
			});
			refetchData();
		} catch (error) {
			notification.error({
				message: t('marketing_campaigns:targets.notifications.error.csv_title'),
				description: t(
					'marketing_campaigns:targets.notifications.error.csv_description'
				)
			});
		} finally {
			toggle();
		}
	};

	const target_system = subSystems.map(({ name }) => ({ name, value: name }));

	const selectResources = {
		target_system,
		target_platforms: TARGET_PLATFORMS,
		target_type: TARGET_TYPES_VALUES
	};

	return (
		<Form
			validate={validate}
			initialValues={{
				...initialValues,
				campaign_id: id,
				target_system: currentSystem
			}}
			onSubmit={handleOnSubmit}
			render={({ handleSubmit, submitting, values }) => (
				<form onSubmit={handleSubmit}>
					<fieldset disabled={submitting}>
						{BASE_FIELDS.map((item, index) =>
							renderFields({ item, index, submitting, selectResources })
						)}
					</fieldset>
					<Button className="m-b-md" type="submit" disabled={!values.targets}>
						{t('common:buttons.submit')}
					</Button>
				</form>
			)}
		/>
	);
};

ImportTargetsForm.propTypes = {
	refetchData: PropTypes.func,
	toggle: PropTypes.func
};

export default ImportTargetsForm;
