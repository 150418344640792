// ******************** BANNERS DATA TABLE ********************
// -------------------- FETCH BANNER TABLE DATA --------------------
export const FETCH_BANNERS_LOADING = 'FETCH_BANNERS_LOADING';
export const FETCH_BANNERS_SUCCESS = 'FETCH_BANNERS_SUCCESS';
export const FETCH_BANNERS_ERROR = 'FETCH_BANNERS_ERROR';
// -------------------- DELETE BANNER --------------------
export const DELETE_BANNER_LOADING = 'DELETE_BANNER_LOADING';
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS';
export const DELETE_BANNER_ERROR = 'DELETE_BANNER_ERROR';

// ******************** FORM ********************
// -------------------- FETCH BANNER --------------------
export const FETCH_BANNER_LOADING = 'FETCH_BANNER_LOADING';
export const FETCH_BANNER_SUCCESS = 'FETCH_BANNER_SUCCESS';
export const FETCH_BANNER_ERROR = 'FETCH_BANNER_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_BANNERS_SEARCH_QUERY = 'SET_BANNERS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_BANNERS_TABLE_SORTING = 'SAVE_BANNERS_TABLE_SORTING';

// ******************** FILES ********************
// -------------------- COVER --------------------
export const FETCH_BANNER_COVER_FILES_LOADING =
	'FETCH_BANNER_COVER_FILES_LOADING';
export const FETCH_BANNER_COVER_FILES_SUCCESS =
	'FETCH_BANNER_COVER_FILES_SUCCESS';
export const FETCH_BANNER_COVER_FILES_ERROR = 'FETCH_BANNER_COVER_FILES_ERROR';
// -------------------- COVER SMALL --------------------
export const FETCH_BANNER_COVER_SMALL_FILES_LOADING =
	'FETCH_BANNER_COVER_SMALL_FILES_LOADING';
export const FETCH_BANNER_COVER_SMALL_FILES_SUCCESS =
	'FETCH_BANNER_COVER_SMALL_FILES_SUCCESS';
export const FETCH_BANNER_COVER_SMALL_FILES_ERROR =
	'FETCH_BANNER_COVER_SMALL_FILES_ERROR';
