// Import translation
import i18n from 'i18next';

export default ({ serial_number }) => {
	const errors = {};

	if (!serial_number) {
		errors.serial_number = i18n.t(
			'devices_sn:form.validation.serial_number_required'
		);
	}

	return errors;
};
