import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	fetchDevicesSn,
	setDevicesSnQuery,
	updateDeviceSnCSV
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './devices_sn_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import DevicesSnUploadCSV from './devices_sn_import_csv/devices_sn_upload_button';

const DevicesSn = ({
	error,
	fetchDevicesSn,
	resources,
	setDevicesSnQuery,
	updateDeviceSnCSV
}) => {
	const { t } = useTranslation();

	return (
		<PanelPageTemplate
			title={t('common:list')}
			error={error}
			type="devices_sn"
			addLinkText={t('devices_sn:buttons.add')}
			additionalChildren={
				<DevicesSnUploadCSV updateDeviceSnCSV={updateDeviceSnCSV} />
			}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchDevicesSn}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setDevicesSnQuery}
					value={resources.options.filters.query}
				/>
			</div>
		</PanelPageTemplate>
	);
};

DevicesSn.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchDevicesSn: PropTypes.func.isRequired,
	setDevicesSnQuery: PropTypes.func.isRequired,
	updateDeviceSnCSV: PropTypes.func.isRequired
};

const mapStateToProps = ({ devices_sn }) => ({
	error: devices_sn.table.error,
	resources: devices_sn.table
});

export default connect(mapStateToProps, {
	fetchDevicesSn,
	updateDeviceSnCSV,
	setDevicesSnQuery
})(DevicesSn);
