import React from 'react';
import {
	string,
	number,
	func,
	oneOfType,
	node,
	arrayOf,
	shape,
	bool
} from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

// Import hooks
import useInputFileLabel from './useInputFileLabel';

import { Upload, Button } from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import { Col, FormGroup } from 'reactstrap';
import { FieldInfo, FieldError, Label } from 'components/common/inputs';

const InputFileLabel = ({
	children,
	input: { value, onChange: setFileState, ...input },
	id,
	label,
	labelColumn,
	inputColumn,
	meta: { touched, error, submitError, submitSucceeded, callBackFn },
	required,
	fieldInfo,
	...inputProps
}) => {
	const { t } = useTranslation();
	const { uploadProps } = useInputFileLabel({
		setFileState,
		submitSucceeded,
		callBackFn
	});
	return (
		<FormGroup
			data-testid={`form-group-${input.name}`}
			row
			className={`${touched && error && 'has-error'} field-group`}
		>
			<Label
				id={id}
				columnWidth={labelColumn}
				required={required}
				content={label}
			/>

			<Col sm={inputColumn}>
				<Upload {...uploadProps} {...inputProps} accept={inputProps?.fileType}>
					<Button icon={<UploadOutlined />}>{t('common:form.file')}</Button>
				</Upload>
				<FieldError
					error={touched && (error || submitError)}
					inputName={input.name}
					content={error || submitError}
				/>
				<FieldInfo info={fieldInfo} />
			</Col>
			{children}
		</FormGroup>
	);
};

InputFileLabel.defaultProps = {
	required: false,
	labelColumn: 2,
	inputColumn: 10
};

InputFileLabel.propTypes = {
	input: shape({
		onChange: func.isRequired
	}),
	label: string,
	id: string.isRequired,
	labelColumn: number,
	inputColumn: number,
	children: oneOfType([node, arrayOf(node)]),
	meta: shape({
		touched: bool,
		error: string,
		submitError: bool
	}),
	fieldInfo: string,
	required: bool
};

export default InputFileLabel;
