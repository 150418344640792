import React from 'react';
import PropTypes from 'prop-types';

// Import hooks
import useSystem from 'hooks/useSystem';

// Import variables
import { SYSTEM_TYPES } from 'components/helpers/variables';
const allSystems = Object.values(SYSTEM_TYPES);

function SystemWrapper({ systems, children }) {
	const { currentSystem } = useSystem();
	if (!systems || systems.includes(currentSystem)) return <>{children}</>;
	return null;
}

SystemWrapper.defaultProps = {
	systems: null
};

SystemWrapper.propTypes = {
	systems: PropTypes.arrayOf(PropTypes.oneOf(allSystems))
};

export default SystemWrapper;
