import React from 'react';

// Import translation
import i18n from 'i18next';

// Import helpers
import { setFormLink } from 'components/helpers/link_selector';

// Import components
import {
	RepeatIngesterActionButton,
	ProgressBar
} from 'components/common/elements';

export const createColumns = ({ isReadOnly, refreshAction }) => [
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.title'),
		accessor: 'title',
		Cell: ({
			row: {
				_original: { product_uuid, title }
			}
		}) => setFormLink({ uuid: product_uuid, title })
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.source_file_name'),
		accessor: 'source_file_name',
		sortable: false
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.status'),
		accessor: 'status',
		sortable: false,
		width: 200
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.priority'),
		accessor: 'priority',
		sortable: false,
		width: 80
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.progress'),
		accessor: 'progress',
		sortable: false,
		Cell: ({
			row: {
				_original: { progress, status }
			}
		}) => <ProgressBar progress={progress} status={status} />
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.type'),
		accessor: 'type',
		sortable: false
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.version'),
		accessor: 'version',
		sortable: false,
		width: 100
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.updated_at'),
		accessor: 'updated_at',
		sortable: true
	},
	{
		Header: i18n.t('common:table_columns.action'),
		accessor: 'repeat_encoding',
		className: 'text-center',
		sortable: false,
		width: 120,
		Cell: ({
			row: {
				_original: { uuid }
			}
		}) =>
			!isReadOnly && (
				<RepeatIngesterActionButton
					url={`ingester/assets/${uuid}/reIngest`}
					refreshAction={refreshAction}
				/>
			)
	}
];
