import React from 'react';
import PropTypes from 'prop-types';

// Product statuses
const PRE_DELETE = 'pre_delete';

function DeleteProductButton({
	productId,
	productStatus,
	handleSelectItem,
	additionalResources,
	isReadOnly
}) {
	const isWaitingForDeletion = productStatus === PRE_DELETE;
	const hideDeleteButton = isReadOnly || isWaitingForDeletion;
	if (hideDeleteButton) return null;

	const handleClick = () => handleSelectItem(productId, additionalResources);

	return (
		<button onClick={handleClick} className="btn btn-lg btn-primary">
			<i className="fa fa-trash" style={{ fontSize: '2rem' }} />
		</button>
	);
}

DeleteProductButton.defaultProps = {
	additionalResources: null
};

DeleteProductButton.propTypes = {
	productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
	productStatus: PropTypes.string,
	handleSelectItem: PropTypes.func.isRequired,
	isReadOnly: PropTypes.bool.isRequired,
	additionalResources: PropTypes.any
};

export default DeleteProductButton;
