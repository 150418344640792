import React from 'react';
import PropTypes from 'prop-types';

// Import utilities
import { renderFields } from 'components/utilities/form';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({ submitting, agreementTypes }) => {
	const selectResources = {
		type: Object.values(agreementTypes).map((type) => ({
			name: type,
			value: type
		}))
	};

	return (
		<>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	agreementTypes: PropTypes.object
};

export default Fields;
