import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Import redux actions
import { fetchPromotion } from 'store/actions';

// Import helpers
import { shouldTabBeHidden } from './helpers/helpers';

const usePromotionsTabs = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const { pathname } = useLocation();

	const isCreateTab = pathname.split('/').includes('create');
	const isMetadata = pathname.split('/').includes('metadata');

	const { tabs, edit } = useSelector(({ promotions }) => promotions.form);
	const shouldRefetchPromotion = !isMetadata && !isCreateTab && isEmpty(edit);

	useEffect(() => {
		if (shouldRefetchPromotion) {
			fetchPromotion(id)(dispatch);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldRefetchPromotion]);

	const checkIsTabDisabled = (tab) => shouldTabBeHidden(tabs, tab);

	return {
		isCreateTab,
		checkIsTabDisabled
	};
};

export default usePromotionsTabs;
