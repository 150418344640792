import React from 'react';

// Import translation
import i18n from 'i18next';

// Import convert
import { convertPrice } from 'components/helpers/convert';

// Import components
import DeleteButton from 'components/utilities/delete_button/DeleteButton';
import EditButton from 'components/utilities/edit_button/EditButton';
import ScheduleActivity from 'components/utilities/schedule_activity/ScheduleActivity';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('prices:table_columns.active'),
		accessor: 'active',
		width: 75,
		className: 'text-center ',
		Cell: ({
			row: {
				_original: { id, active }
			}
		}) => <ScheduleActivity id={id} isActive={active} />
	},
	{
		Header: i18n.t('prices:table_columns.since'),
		accessor: 'since',
		width: 150
	},
	{
		Header: i18n.t('prices:table_columns.till'),
		accessor: 'till',
		width: 150
	},
	{
		Header: i18n.t('prices:table_columns.price'),
		accessor: 'price',
		Cell: ({
			row: {
				_original: { price }
			}
		}) => <span>{convertPrice(price)}</span>
	},
	{
		Header: i18n.t('prices:table_columns.period'),
		accessor: 'period',
		minWidth: 150,
		Cell: ({
			row: {
				_original: { period }
			}
		}) => (
			<span>
				{parseInt(period) === -1 ? i18n.t('prices:recurring_payment') : period}
			</span>
		)
	},
	{
		Header: i18n.t('prices:table_columns.payment_method'),
		accessor: 'payment_method',
		minWidth: 200
	},
	{
		Header: i18n.t('prices:table_columns.created_at'),
		accessor: 'created_at',
		width: 150
	},
	{
		Header: i18n.t('common:table_columns.system'),
		accessor: 'system',
		minWidth: 150
	},
	{
		Header: i18n.t('common:table_columns.edit'),
		width: 80,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { product_uuid, id, system_id }
			}
		}) => (
			<EditButton
				productType="main"
				productUuid={product_uuid}
				id={id}
				productSystem={system_id}
				isReadOnly={isReadOnly}
			/>
		)
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id, system_id, source_system_id }
			}
		}) => (
			<DeleteButton
				id={id}
				productSystem={system_id}
				sourceSystem={source_system_id}
				onSelectItem={handleSelectItem}
				isReadOnly={isReadOnly}
			/>
		)
	}
];
