import i18n from 'i18n';

// Import helpers
import { filterOptions } from './helpers';

export const defaultValues = (isPacketMain) => {
	const { paymentOptions, periodOptions } = filterOptions({ isPacketMain });
	return {
		period: periodOptions[0]?.value || '',
		paymentMethod: paymentOptions[0]?.value || ''
	};
};

export const BASE_FIELDS = [
	{
		name: 'range',
		type: 'range',
		placeholder: [
			i18n.t('availability:fields.since'),
			i18n.t('availability:fields.till')
		],
		label: i18n.t('availability:fields.range'),
		required: true,
		showTime: true,
		rangeFormat: 'YYYY-MM-DD HH:mm:ss'
	},
	{
		name: 'price',
		type: 'number',
		label: i18n.t('prices:fields.price'),
		required: true,
		step: 0.01
	},
	{
		name: 'external_id',
		type: 'text',
		label: i18n.t('prices:fields.external_id'),
		required: false
	},
	{
		name: 'period',
		type: 'select',
		label: i18n.t('prices:fields.period'),
		required: true
	},
	{
		name: 'paymentMethod',
		type: 'select',
		label: i18n.t('prices:fields.payment_method'),
		required: true
	}
];
