import React from 'react';
import i18n from 'i18next';

// Import components
import SectionsGroupsForm from '../../section_groups_form/SectionsGroupsForm';
import SectionsAssigned from '../../sections_assigned/SectionsAssigned';

export const sectionsGroupsTabs = [
	{
		name: i18n.t('common:tabs.sections_assigned'),
		path: 'sections-assigned',
		disableOnCreate: true,
		component: <SectionsAssigned />
	},
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <SectionsGroupsForm />
	}
];
