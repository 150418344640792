import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import helpers
import { convertFormData } from './form_helpers';

// Import components
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const SettingsForm = ({ system }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { data, isLoaded } = useSelector(({ settings }) => settings.form.list);

	const handleOnSubmit = async (values) => {
		const api = system ? `settings?system=${system}` : 'settings';
		const resources = convertFormData(values);

		// Submit the form with field values
		return await submitForm({
			history,
			itemID: '',
			isEdit: true,
			resources,
			api,
			redirect: false,
			redirectToEdit: false,
			notificationName: 'settings'
		});
	};

	return (
		<Form
			initialValues={data}
			onSubmit={handleOnSubmit}
			render={({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit}>
					<Fields submitting={submitting} list={data} isLoaded={isLoaded} />

					{isLoaded && (
						<FormButtons
							cancelButton={false}
							isButtonDisabled={submitting}
							path="settings"
							buttonText={t('common:buttons.submit')}
						/>
					)}
				</form>
			)}
		/>
	);
};

SettingsForm.propTypes = {
	system: PropTypes.string
};

export default SettingsForm;
