import i18n from 'i18n';

export const BASE_FIELDS = [
	{
		name: 'file',
		type: 'file',
		required: true,
		label: i18n.t('vod_subtitles:fields.file')
	},
	{
		name: 'language',
		type: 'text',
		required: true,
		label: i18n.t('vod_subtitles:fields.language')
	}
];
