import React from 'react';
import { Spin } from 'antd';
import { string } from 'prop-types';

// Import logic
import useAudioPlayer from './useAudioPlayer';

const AudioPlayer = ({ playlist }) => {
	const { isLoading, audioSource } = useAudioPlayer({ playlist });

	return (
		<div className="h-300 d-flex justify-content-center align-items-center">
			{isLoading && (
				<div className="text-center">
					<Spin size="large" />
				</div>
			)}

			{!isLoading && audioSource && (
				<audio controls autoPlay>
					<source src={audioSource} type="audio/mpeg" />
				</audio>
			)}
		</div>
	);
};

AudioPlayer.propTypes = {
	playlist: string.isRequired
};

export default AudioPlayer;
