import React, { useEffect } from 'react';
import { object, bool, func, array, shape, string } from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Import actions
import {
	fetchSeries,
	fetchCategories,
	fetchProviders,
	fetchTagsByCategory
} from 'store/actions';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import ProductizationNote from 'components/utilities/info/ProductizationNote';

// Import helpers
import {
	vodCategoriesOptions,
	convertGenresToNames,
	convertToIds
} from 'components/helpers/genres_helpers/helpers';
import { convertProvidersToId } from 'components/views/vod/helpers/helpers';

const SeriesForm = ({
	history,
	fetchSeries,
	fetchCategories,
	fetchProviders,
	fetchTagsByCategory,
	error,
	isLoaded,
	initialValues,
	genresList,
	providersList,
	paymentsModelsList,
	match: {
		params: { id }
	},
	categoriesTags
}) => {
	useEffect(() => {
		if (id) localStorage.setItem('series', id);
		// Fetch categories with default params to feed genres combobox
		fetchCategories(vodCategoriesOptions, null, null);
		// eslint-disable-next-line
	}, []);

	const { t } = useTranslation();

	const redirectPath = 'vod';

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { type, genres, active, provider, provider_id, ...metadata } = values;

		// selected genres mapped to array of ids
		const genresId = convertToIds(genresList, genres);

		// resources to submit
		const resources = {
			...initialValues,
			metadata,
			type,
			genres: genresId,
			active,
			provider,
			provider_id
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'vod/series',
			apiSlug: 'update',
			redirectPath
		});
	};

	const {
		metadata,
		type,
		genres,
		active,
		status,
		subtype,
		provider,
		provider_id,
		payment_models
	} = initialValues;
	const { actors, writers, directors } = metadata;

	// array of genres names that will be passed to multi-select
	// both initial values and data for combobox
	const genresNames = convertGenresToNames(genres);
	const genresListNames = convertGenresToNames(genresList);

	// arrays of provider id's passed to suggestion combobox for form fields
	const providersId = convertProvidersToId(providersList);

	// form initial values
	const formInitialValues = {
		...defaultFieldValues,
		...metadata,
		type,
		genres: genresNames,
		active,
		provider,
		provider_id,
		payment_models
	};

	return (
		// Dispatch fetchSeries actions in series_form
		<FormTemplate
			fetchResourceToEdit={fetchSeries}
			fetchFormResources={[fetchTagsByCategory, fetchProviders]}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<>
					<ProductizationNote />
					<Form
						initialValues={formInitialValues}
						validate={validate}
						onSubmit={handleOnSubmit({ isEdit, itemID })}
						render={({ handleSubmit, submitting, values }) => (
							<form onSubmit={handleSubmit}>
								<Fields
									isEdit={isEdit}
									submitting={submitting}
									actors={actors}
									writers={writers}
									directors={directors}
									type={type}
									genres={genresListNames}
									status={status}
									subtype={subtype}
									providersId={providersId}
									krritTags={categoriesTags.VOD}
									paymentsModelsList={paymentsModelsList}
									currentValues={values}
								/>
								<FormButtons
									isButtonDisabled={submitting || error}
									path={redirectPath}
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						)}
					/>
				</>
			)}
		</FormTemplate>
	);
};

SeriesForm.propTypes = {
	history: object.isRequired,
	initialValues: object.isRequired,
	fetchSeries: func.isRequired,
	fetchCategories: func.isRequired,
	fetchProviders: func.isRequired,
	fetchTagsByCategory: func.isRequired,
	error: bool.isRequired,
	isLoaded: bool.isRequired,
	genresList: array.isRequired,
	providersList: array.isRequired,
	paymentsModelsList: array.isRequired,
	match: shape({
		params: shape({ id: string })
	}),
	categoriesTags: object.isRequired
};

const mapStateToProps = ({
	vod: { series_form },
	categories: {
		table: { data }
	},
	providers,
	dictionary,
	tags: { categoriesTags }
}) => ({
	initialValues: series_form.edit,
	error: series_form.error,
	isLoaded: series_form.isLoaded,
	genresList: data,
	providersList: providers.table.data,
	paymentsModelsList: dictionary.paymentModels,
	categoriesTags
});

export default compose(
	connect(mapStateToProps, {
		fetchSeries,
		fetchCategories,
		fetchProviders,
		fetchTagsByCategory
	}),
	withRouter
)(SeriesForm);
