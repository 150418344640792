import React from 'react';
import i18n from 'i18next';

// Import components
import EpgForm from '../../epg_form/EpgForm';

import SystemSettingsForm from 'components/utilities/system_settings/SystemSettingsForm';

export const basicEpgTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <EpgForm />
	},
	{
		name: i18n.t('common:tabs.system_settings'),
		path: 'system_settings',
		disableOnCreate: false,
		component: <SystemSettingsForm />
	}
];
