// Import translation
import i18n from 'i18next';

// Import helpers
import { checkPinFormat } from 'components/helpers/validation_helpers';

export default ({
	account_pin,
	box_account_pin,
	adult_access_pin,
	credits_limit,
	npvr_storage_limit,
	full_pvr_parallel_limit,
	usb_pvr_parallel_limit
}) => {
	const errors = {};

	const isAccountPinValid = account_pin && checkPinFormat(account_pin);
	const isBoxAccountPinValid =
		box_account_pin && checkPinFormat(box_account_pin);
	const isAdultAccessPinValid =
		adult_access_pin && checkPinFormat(adult_access_pin);

	// ------------------ Account pin ------------------
	if (!account_pin) {
		errors.account_pin = i18n.t('subscribers:validation.account_pin_required');
	} else if (!isAccountPinValid) {
		errors.account_pin = i18n.t('common:validation.pin_format');
	}

	// ------------------ BOX account pin ------------------
	if (!box_account_pin) {
		errors.box_account_pin = i18n.t(
			'subscribers:validation.box_account_pin_required'
		);
	} else if (!isBoxAccountPinValid) {
		errors.box_account_pin = i18n.t('common:validation.pin_format');
	}

	// ------------------ Adult access pin ------------------
	if (!adult_access_pin) {
		errors.adult_access_pin = i18n.t(
			'subscribers:validation.adult_access_pin_required'
		);
	} else if (!isAdultAccessPinValid) {
		errors.adult_access_pin = i18n.t('common:validation.pin_format');
	}

	// ------------------ Credits_limit ------------------
	if (credits_limit == null) {
		errors.credits_limit = i18n.t(
			'subscribers:validation.credits_limit_required'
		);
	} else if (credits_limit < 0) {
		errors.credits_limit = i18n.t('subscribers:validation.negative_value');
	}

	// ------------------ Npvr_storage_limit ------------------
	if (npvr_storage_limit == null) {
		errors.npvr_storage_limit = i18n.t(
			'subscribers:validation.npvr_storage_limit_required'
		);
	} else if (npvr_storage_limit < 0) {
		errors.npvr_storage_limit = i18n.t('subscribers:validation.negative_value');
	}

	// ------------------ Full PVR recordings limit ------------------
	if (full_pvr_parallel_limit < 0) {
		errors.full_pvr_parallel_limit = i18n.t(
			'subscribers:validation.negative_value'
		);
	}

	// ------------------ USB PVR recordings limit ------------------
	if (usb_pvr_parallel_limit < 0) {
		errors.usb_pvr_parallel_limit = i18n.t(
			'subscribers:validation.negative_value'
		);
	}

	return errors;
};
