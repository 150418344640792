import React from 'react';
import PropTypes from 'prop-types';

// Import comoponents
import { Button } from 'antd';

// Import variables
import { LOCAL_STORAGE_LANGUAGE } from 'components/helpers/variables';

// Import translation
import { useTranslation } from 'react-i18next';

/**
 * Functional component responsible for rendering change language button in SignIn screen and main app.
 * Also handles language change (polish | english)
 */
function ChangeLanguage({ isMain = true }) {
	const { i18n, t } = useTranslation();

	// variables holding button text translations
	const polishTranslation = isMain
		? t('common:buttons.polish')
		: t('common:buttons.pl');
	const englishTranslation = isMain
		? t('common:buttons.english')
		: t('common:buttons.eng');

	// get language from local storage
	let userLang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

	// set default language when localstorage is empty based on which language is selected in i18n
	if (!userLang) {
		userLang = i18n.language;
	}

	const buttonLanguage =
		userLang === 'pl' ? englishTranslation : polishTranslation;

	// logic behind changing language
	const handleLanguageChange = () => {
		let changedLanguage = '';

		// select opposite language and prepare new data
		userLang === 'pl' ? (changedLanguage = 'eng') : (changedLanguage = 'pl');

		// set new language value in local storage
		localStorage.setItem(LOCAL_STORAGE_LANGUAGE, changedLanguage);

		// change language in i18n
		i18n.changeLanguage(changedLanguage);

		// reload window to see translation results
		window.location.reload();
	};

	return (
		<>
			{isMain ? (
				<Button onClick={handleLanguageChange}>
					<i className="fa fa-language m-r-sm" /> {buttonLanguage}
				</Button>
			) : (
				<button
					onClick={handleLanguageChange}
					className="btn__language_sign_in"
				>
					{buttonLanguage}
				</button>
			)}
		</>
	);
}

ChangeLanguage.propTypes = {
	isMain: PropTypes.bool
};

export default ChangeLanguage;
