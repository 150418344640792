// ******************** CATEGORIES DATA TABLE ********************
export const FETCH_CATEGORIES_LOADING = 'FETCH_CATEGORIES_LOADING';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_CATEGORIES_SEARCH_QUERY = 'SET_CATEGORIES_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_CATEGORIES_TABLE_SORTING = 'SAVE_CATEGORIES_TABLE_SORTING';

// ******************** SET CATEGORY TYPE ********************
export const SET_CATEGORIES_TYPE = 'SET_CATEGORIES_TYPE';

// ******************** DELETE CATEGORY ********************
export const DELETE_CATEGORY_LOADING = 'DELETE_CATEGORY_LOADING';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';

// ******************** CATEGORIES ALL DATA ********************
export const FETCH_ALL_CATEGORIES_LOADING = 'FETCH_ALL_CATEGORIES_LOADING';
export const FETCH_ALL_CATEGORIES_SUCCESS = 'FETCH_ALL_CATEGORIES_SUCCESS';
export const FETCH_ALL_CATEGORIES_ERROR = 'FETCH_ALL_CATEGORIES_ERROR';

export const REMOVE_CATEGORY_ITEM = 'REMOVE_CATEGORY_ITEM';

// ******************** SECTION DATA FORM ********************
export const FETCH_CATEGORY_LOADING = 'FETCH_CATEGORY_LOADING';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_ERROR = 'FETCH_CATEGORY_ERROR';

// ******************** FILES ********************
// -------------------- ICON --------------------
export const FETCH_CATEGORIES_ICON_FILES_LOADING =
	'FETCH_CATEGORIES_ICON_FILES_LOADING';
export const FETCH_CATEGORIES_ICON_FILES_SUCCESS =
	'FETCH_CATEGORIES_ICON_FILES_SUCCESS';
export const FETCH_CATEGORIES_ICON_FILES_ERROR =
	'FETCH_CATEGORIES_ICON_FILES_ERROR';
