// Import translation
import i18n from 'i18next';

// Import helpers
import { checkIsInt } from 'components/helpers/convert';

export default ({ name, slug, order }) => {
	const errors = {};

	// ------------------ Name ------------------
	if (!name) {
		errors.name = i18n.t('categories:validation.name_required');
	} else if (name.length < 2 || name.length > 200) {
		errors.name = i18n.t('categories:validation.name_length');
	}

	// ------------------ Slug ------------------
	if (!slug) {
		errors.slug = i18n.t('categories:validation.slug_required');
	} else if (slug.length < 2 || slug.length > 60) {
		errors.slug = i18n.t('categories:validation.slug_length');
	}

	// ------------------ Order ------------------
	if (order && order < 0) {
		errors.order = i18n.t('categories:validation.negative_value');
	} else if (!checkIsInt(order) && order) {
		errors.order = i18n.t('categories:validation.fraction_invalid');
	}

	return errors;
};
