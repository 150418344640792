import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import CardsList from '../cards_list/CardsList';

// Import translation
import { useTranslation } from 'react-i18next';

// Import context
import { ProductsContext } from '../ProductsProvider';

// Import variables
import { PRODUCT_TYPES } from '../helpers';

const { PROVIDER_SYSTEM } = PRODUCT_TYPES;

const ProvidersList = ({ type }) => {
	const { t } = useTranslation();
	const { selectedData, handleRemoveItem } = useContext(ProductsContext);

	const providers = selectedData.filter(({ type }) => type === 'provider');

	const titleText =
		type === PROVIDER_SYSTEM
			? t('systems:providers_products_title')
			: t('limits:provider');

	return (
		providers.length > 0 && (
			<>
				<h5>{titleText}</h5>
				<CardsList
					data={providers}
					isDelete={true}
					deleteFunction={handleRemoveItem}
				/>
			</>
		)
	);
};

ProvidersList.propTypes = {
	type: PropTypes.string
};

export default ProvidersList;
