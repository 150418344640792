import React from 'react';

// Import translation
import i18n from 'i18next';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('categories:children_table:table_columns.name'),
		accessor: 'name'
	},
	{
		Header: i18n.t('categories:children_table:table_columns.slug'),
		accessor: 'slug'
	},
	{
		Header: i18n.t('categories:children_table:table_columns.reverse_merge'),
		accessor: 'reverse_merge',
		width: 100,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(id)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-exchange" />
				</button>
			)
	}
];
