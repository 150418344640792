// ******************** TABLE PANEL ********************
// -------------------- MARKETING_CAMPAIGNS DATA TABLE --------------------
export const FETCH_MARKETING_CAMPAIGNS_LOADING =
	'FETCH_MARKETING_CAMPAIGNS_LOADING';
export const FETCH_MARKETING_CAMPAIGNS_SUCCESS =
	'FETCH_MARKETING_CAMPAIGNS_SUCCESS';
export const FETCH_MARKETING_CAMPAIGNS_ERROR =
	'FETCH_MARKETING_CAMPAIGNS_ERROR';

// -------------------- SET SEARCH QUERY --------------------
export const SET_MARKETING_CAMPAIGNS_SEARCH_QUERY =
	'SET_MARKETING_CAMPAIGNS_SEARCH_QUERY';

// -------------------- SAVE SORTING OPTIONS  --------------------
export const SAVE_MARKETING_CAMPAIGNS_TABLE_SORTING =
	'SAVE_MARKETING_CAMPAIGNS_TABLE_SORTING';

// -------------------- DELETE MARKETING_CAMPAIGN --------------------
export const DELETE_MARKETING_CAMPAIGN_LOADING =
	'DELETE_MARKETING_CAMPAIGN_LOADING';
export const DELETE_MARKETING_CAMPAIGN_SUCCESS =
	'DELETE_MARKETING_CAMPAIGN_SUCCESS';
export const DELETE_MARKETING_CAMPAIGN_ERROR =
	'DELETE_MARKETING_CAMPAIGN_ERROR';

// --------------------  MARKETING_CAMPAIGN --------------------
export const FETCH_MARKETING_CAMPAIGN_LOADING =
	'FETCH_MARKETING_CAMPAIGN_LOADING';
export const FETCH_MARKETING_CAMPAIGN_SUCCESS =
	'FETCH_MARKETING_CAMPAIGN_SUCCESS';
export const FETCH_MARKETING_CAMPAIGN_ERROR = 'FETCH_MARKETING_CAMPAIGN_ERROR';

// --------------------  STATUS --------------------
export const SET_MARKETING_CAMPAIGN_STATUS = 'SET_MARKETING_CAMPAIGN_STATUS';

export const FETCH_MARKETING_CAMPAIGN_TARGETS_LOADING =
	'FETCH_MARKETING_CAMPAIGN_TARGETS_LOADING';
export const FETCH_MARKETING_CAMPAIGN_TARGETS_SUCCESS =
	'FETCH_MARKETING_CAMPAIGN_TARGETS_SUCCESS';
export const FETCH_MARKETING_CAMPAIGN_TARGETS_ERROR =
	'FETCH_MARKETING_CAMPAIGN_TARGETS_ERROR';

export const SET_MARKETING_CAMPAIGN_TARGETS_QUERY =
	'SET_MARKETING_CAMPAIGN_TARGETS_QUERY';

export const DELETE_MARKETING_CAMPAIGN_TARGETS_ERROR =
	'DELETE_MARKETING_CAMPAIGN_TARGETS_ERROR';
export const DELETE_MARKETING_CAMPAIGN_TARGETS_LOADING =
	'DELETE_MARKETING_CAMPAIGN_TARGETS_LOADING';
export const DELETE_MARKETING_CAMPAIGN_TARGETS_SUCCESS =
	'DELETE_MARKETING_CAMPAIGN_TARGETS_SUCCESS';
