import React from 'react';
import { string, bool, func } from 'prop-types';
import useFetchDataModal from './useFetchDataModal';

// Import components
import { Modal } from 'antd';
import ModalContent from './ModalContent';

function FetchDataModal({
	text,
	id,
	queryKey,
	handleFetchData,
	dataAccessPath,
	showIcon,
	isVisible
}) {
	const {
		openModal,
		cancel,
		isOpened,
		data,
		status,
		error
	} = useFetchDataModal({
		queryKey,
		handleFetchData,
		id
	});

	if (!isVisible) {
		return null;
	}

	return (
		<>
			<span className="fetch_data_modal__text" onClick={openModal}>
				{text}
				{showIcon && <i className="fa fa-info-circle ml-2" />}
			</span>
			<Modal
				open={isOpened}
				footer={null}
				onCancel={cancel}
				width={900}
				destroyOnClose
			>
				<ModalContent
					data={data}
					status={status}
					dataAccessPath={dataAccessPath}
					error={error}
				/>
			</Modal>
		</>
	);
}

FetchDataModal.defaultProps = {
	showIcon: false
};

FetchDataModal.propTypes = {
	isVisible: bool.isRequired,
	text: string,
	id: string,
	queryKey: string,
	handleFetchData: func,
	dataAccessPath: string,
	showIcon: bool
};

export default FetchDataModal;
