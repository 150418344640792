export const init_options = {
	language: 'pl',
	language_url: '/tinymce/langs/pl.js',
	height: 500,
	plugins: [
		'print importcss', // File
		'paste', // Edit
		'image link media table charmap emoticons hr anchor insertdatetime', // Insert
		'visualblocks visualchars preview fullscreen', // View
		'wordcount code', // Tools
		'advlist lists imagetools textpattern noneditable help quickbars',
		'searchreplace autolink directionality'
	],
	menubar: 'file edit insert view format table tools',
	toolbar: `undo redo | formatselect | bold italic |
        alignleft aligncenter alignright alignjustify |
        bullist numlist outdent indent | image link |
        code fullscreen`,
	branding: false,
	// below changes to remove auto p tag in source code
	mode: 'textareas',
	force_br_newlines: false,
	force_p_newlines: false,
	forced_root_block: '',
	entity_encoding: 'raw' // Removing HTML entities from content
};
