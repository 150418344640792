import notification from 'antd/es/notification';

// Import translation
import i18n from 'i18next';

export const notificationHandler = (
	title,
	message,
	type = 'success',
	duration = 2
) => {
	notification[type]({
		message: title,
		description: message,
		duration
	});
};

export const createFormNotification = (
	pathLocation,
	item,
	type = 'success',
	customErrorMessage = 'form_create_error_message',
	duration = 8
) => {
	// Create notification
	const title = pathLocation
		? i18n.t('messages:notifications.created')
		: i18n.t('messages:notifications.edited');
	// Create success message
	const successMessage = pathLocation
		? `${i18n.t('messages:notifications.successfuly_created')} ${i18n.t(
				`names:notification_names.${item.toLowerCase()}`
		  )} `
		: `${i18n.t('messages:notifications.successfuly_edited')} ${i18n.t(
				`names:notification_names.${item.toLowerCase()}`
		  )}`;

	// Create error message
	const errorMessage = i18n.t(`messages:notifications.${customErrorMessage}`);
	// Check the type
	let message = type === 'success' ? successMessage : errorMessage;
	// Show notification
	notification[type]({
		message: title,
		description: message,
		duration
	});
};
