import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

// Import services
import services from 'services/services';

const useCognitoBreadcrumbs = () => {
	const { id } = useParams();

	const [catalogs, setCatalogs] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchCognitoCatalogs = async (id) => {
		const { data } = await services.get(`/cognito/categories/${id}`);

		const parentUuid = data?.parent_uuid;
		const name = data?.Name;

		const catalog = { name, uuid: id };

		setCatalogs((prevCatalogs) => [catalog, ...prevCatalogs]);

		if (parentUuid) {
			return fetchCognitoCatalogs(parentUuid);
		}

		setLoading(false);
	};

	useEffect(() => {
		// To clean when user switch catalog
		setCatalogs([]);
		setLoading(true);

		fetchCognitoCatalogs(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return { catalogs, loading };
};

export default useCognitoBreadcrumbs;
