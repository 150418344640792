import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

import {
	FETCH_PAYMENT_TEMPLATES_GET_ALL,
	FETCH_PAYMENT_TEMPLATES_DETAILS
} from 'components/helpers/api';

// ******************** FETCH PAYMENT SCHEDULE - TABLE DATA ********************
export const fetchPaymentTemplatesDropdown = () => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_PAYMENT_TEMPLATES_DROPDOWN_LOADING,
			payload: true
		});

		const { data } = await services.get(FETCH_PAYMENT_TEMPLATES_GET_ALL);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_PAYMENT_TEMPLATES_DROPDOWN_SUCCESS,
			payload: data.data.map(({ name, id }) => ({
				name,
				value: id
			}))
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_PAYMENT_TEMPLATES_DROPDOWN_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** FETCH PAYMENT DETAILS ********************
export const fetchPaymentTemplatesDetails = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_PAYMENT_TEMPLATES_DETAILS_LOADING,
			payload: true
		});

		const url = FETCH_PAYMENT_TEMPLATES_DETAILS.replace(':productId', id);

		const { data } = await services.get(url);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_PAYMENT_TEMPLATES_DETAILS_SUCCESS,
			payload: data?.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_PAYMENT_TEMPLATES_DETAILS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
