import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setPacketsQuery, fetchPackets, deletePacket } from 'store/actions';
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './packets_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const Packets = ({
	error,
	setPacketsQuery,
	fetchPackets,
	deletePacket,
	resources
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	return (
		// Dispatch fetchPackets, deletePacket action in packets_panel
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="packets"
			addLinkText={t('packets:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchPackets}
			deleteItemAction={deletePacket}
			notificationDeleteSuccessTxt={t('packets:notification.delete_success')}
			notificationDeleteErrorTxt={t('packets:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setPacketsQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Packets.propTypes = {
	error: PropTypes.bool.isRequired,
	setPacketsQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchPackets: PropTypes.func.isRequired,
	deletePacket: PropTypes.func.isRequired
};

const mapStateToProps = ({ packets }) => {
	return {
		error: packets.table.error,
		resources: packets.table
	};
};

export default connect(mapStateToProps, {
	setPacketsQuery,
	fetchPackets,
	deletePacket
})(Packets);
