import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { notifyVerify, notifyUnverify } from './helpers';

// Import store
import {
	fetchSubscriberDocument,
	verifySubscriber,
	unverifySubscriber
} from 'store/actions';

// Import helpers
import { VERIFICATION } from 'components/helpers/variables';

function useSubscriberVerification({ id }) {
	const dispatch = useDispatch();
	const {
		isLoaded,
		error,
		data: { document, fileName, extension, status, verifiedAt, verifiedBy },
		verify,
		unverify
	} = useSelector(({ subscribers }) => subscribers.verification);

	const isDisabled = !document || unverify.loading || verify.loading;
	const isAcceptDisabled = isDisabled && status !== VERIFICATION.UNVERIFIED;
	const isRejectDisabled = isDisabled && status !== VERIFICATION.VERIFIED;
	const isDisabledSpinner = unverify.loading || verify.loading;

	useEffect(() => {
		fetchSubscriberDocument(id)(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onVerify = async () => {
		await verifySubscriber(id)(dispatch);
		notifyVerify(!verify.error);
		!verify.error && (await fetchSubscriberDocument(id)(dispatch));
	};

	const onUnverify = async () => {
		await unverifySubscriber(id)(dispatch);
		notifyUnverify(!unverify.error);
		!verify.error && (await fetchSubscriberDocument(id)(dispatch));
	};

	const refetchResources = async (e) => {
		e.preventDefault();
		await fetchSubscriberDocument(id)(dispatch);
	};

	return {
		isDisabled,
		isAcceptDisabled,
		isRejectDisabled,
		isDisabledSpinner,
		isLoaded,
		error,
		onVerify,
		onUnverify,
		refetchResources,
		fileName,
		extension,
		status,
		unverify,
		verify,
		document,
		verifiedAt,
		verifiedBy
	};
}

export default useSubscriberVerification;
