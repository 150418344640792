import React from 'react';
import i18n from 'i18n';

export const infoContent = (
	<div>
		<h4>{i18n.t('payment_templates:info.all_used.title')}</h4>
		<p>{i18n.t('payment_templates:info.all_used.subtitle')}</p>
		<p className="text-primary">
			{i18n.t('payment_templates:info.all_used.bottom')}
		</p>
	</div>
);
