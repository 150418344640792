// THIS FUNCTION MIGHT BE NEEDED IN FUTURE - CHECK WITH PM THE DATA TYPE OF LOGINS
// convert text input of users id to array of int
// export const convertUsersToIntArray = (array) =>
// 	array
// 		.split(',') //ids should be separated by comma
// 		.map((id) => parseInt(id.replace(/\s+/g, ''), 10)) //replace whitespace characters and convert them to int
// 		.filter(Boolean); //filter NaN values

// convert string text input of users to array of strings
export const convertUsersToArray = (users) => {
	// if data is an array -> return array (happens when data fetched from API is not edited)
	if (Array.isArray(users)) return users;
	// else convert string data to array
	else if (users) {
		return (
			users
				.split(',')
				// remove unnecessary new line characters
				.map((user) =>
					user.includes('\n') ? user.replace(/\r?\n|\r/g, '') : user
				)
				// remove white space (if users are separated by comma with whitespace [, ])
				.map((user) => (user[0] === ' ' ? user.substring(1) : user))
		);
	}
	// for empty string return empty array
	else return [];
};
