import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ClockCircleOutlined } from '@ant-design/icons';

const ColumnDate = ({ date }) => {
	const isInFuture = useMemo(() => moment(date).isAfter(moment()), [date]);
	const styles = {
		fontWeight: isInFuture ? '600' : 'normal',
		columnGap: '0.5em',
		display: 'flex'
	};

	return (
		<span style={styles} title={date}>
			{moment(date).format('YYYY-MM-DD')}
			{isInFuture && <ClockCircleOutlined style={{ color: 'green' }} />}
		</span>
	);
};

ColumnDate.propTypes = {
	date: PropTypes.string.isRequired
};

export default ColumnDate;
