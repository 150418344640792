import React from 'react';
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';
import i18n from 'i18next';
import moment from 'moment';

// Import variables
import { CHILDREN_KEY_SUBSTITUTE } from 'components/helpers/variables';

const convertDate = (value) => moment(value).format('DD/MM/YYYY');

export const columns = [
	{
		title: i18n.t('vod:productive_stb:columns.status'),
		dataIndex: 'status',
		key: 'status',
		align: 'center',
		width: 120
	},
	{
		title: i18n.t('vod:productive_stb:columns.active'),
		dataIndex: 'active',
		key: 'active',
		align: 'center',
		width: 70,
		render: (value) => ActiveIcon({ active: Number(value) })
	},
	{
		title: i18n.t('vod:productive_stb:columns.product'),
		dataIndex: 'product',
		key: 'product',
		align: 'center',
		width: 70
	},
	{
		title: i18n.t('vod:productive_stb:columns.assets'),
		dataIndex: 'assets',
		key: 'assets',
		align: 'center',
		width: 70
	},
	{
		title: i18n.t('vod:productive_stb:columns.timetables'),
		dataIndex: 'timetables',
		key: 'timetables',
		align: 'center',
		width: 100
	},
	{
		title: i18n.t('vod:productive_stb:columns.prices'),
		dataIndex: 'prices',
		key: 'prices',
		align: 'center',
		width: 100
	},
	{
		title: i18n.t('vod:productive_stb:columns.ingestion'),
		dataIndex: 'ingestion',
		key: 'ingestion',
		align: 'center',
		width: 70
	},
	{
		title: i18n.t('vod:productive_stb:columns.since'),
		dataIndex: 'since',
		key: 'since',
		align: 'center',
		width: 100,
		render: (value) => <span>{convertDate(value)}</span>
	},
	{
		title: i18n.t('vod:productive_stb:columns.till'),
		dataIndex: 'till',
		key: 'till',
		align: 'center',
		width: 100,
		render: (value) => <span>{convertDate(value)}</span>
	},
	{
		title: i18n.t('vod:productive_stb:columns.children'),
		dataIndex: CHILDREN_KEY_SUBSTITUTE,
		key: CHILDREN_KEY_SUBSTITUTE,
		align: 'center',
		width: 100
	},
	{
		title: i18n.t('vod:productive_stb:columns.packets'),
		dataIndex: 'packets',
		key: 'packets',
		align: 'center',
		width: 100
	}
];
