import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import debounce from 'lodash.debounce';
import services from 'services/services';
import { Select, Spin } from 'antd';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

// Import translation
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const SelectOnSearchLabel = ({
	input,
	labelColumn = 2,
	inputColumn = 10,
	meta: { touched, error, submitError },
	fieldInfo = null,
	url,
	id,
	label,
	...rest
}) => {
	const { t } = useTranslation();

	// component state
	const [dataList, setDataList] = useState([]);
	const [isFetching, setIsFetching] = useState(false);
	const [isError, setIsError] = useState(false);

	// variable checks if values from proper requests are shown
	// important when there's slower internet connection
	let lastFetchId = 0;

	// fetching data to populate select combobox
	const fetchData = debounce(async (value) => {
		try {
			// function works after two letters are passed
			if (value.length >= 2) {
				lastFetchId += 1;
				const fetchId = lastFetchId;

				setDataList([]);
				setIsFetching(true);

				// Get data from server and add them to select list
				const {
					data: { data }
				} = await services.get(`${url}&search[value]=${value}`);

				setDataList(data);
				setIsFetching(false);

				if (fetchId !== lastFetchId) return;
			} else {
				setDataList([]);
				setIsFetching(false);
			}
		} catch (error) {
			setDataList([]);
			setIsFetching(false);
			setIsError(true);
		}
	}, 800);

	const showSpinner = () =>
		isFetching ? (
			<div className="text-center">
				<Spin size="small" />
			</div>
		) : null;

	useEffect(() => {
		if (isError) {
			notificationHandler(t('common:errors.data_retrieve'), '', 'error', 8);
		}
		// eslint-disable-next-line
	}, [isError]);

	return (
		<FormGroup row className={`${touched && error ? 'has-error' : ''} `}>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label}
			</Label>
			<Col sm={inputColumn}>
				<Select
					{...input}
					{...rest}
					showSearch
					notFoundContent={showSpinner()}
					filterOption={false}
					onSearch={fetchData}
					style={{ width: '100%' }}
					allowClear
				>
					{dataList.map(({ id, label, name }) => (
						<Option key={id} id={id} value={id}>
							{name} | {label}
						</Option>
					))}
				</Select>
				{touched && (error || submitError) && (
					<span className="input-error">{error || submitError}</span>
				)}
				{fieldInfo && (
					<span className="input-info">
						<i className="fa fa-info-circle m-r-sm"></i>
						{fieldInfo}
					</span>
				)}
			</Col>
		</FormGroup>
	);
};

SelectOnSearchLabel.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	inputColumn: PropTypes.number,
	labelColumn: PropTypes.number,
	meta: PropTypes.object,
	url: PropTypes.string.isRequired
};

export default SelectOnSearchLabel;
