import React, { useState, createContext } from 'react';
import services from 'services/services';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

// Import translation
import { useTranslation } from 'react-i18next';

export const MaterialsContext = createContext();

export const MaterialsProvider = ({
	children,
	resources,
	metaData,
	updateMaterials,
	updateMaterialsError,
	deleteMaterials,
	deleteMaterialsError
}) => {
	const { t } = useTranslation();

	// context state to determine if modal should be visible or not
	const [isVisible, setIsVisible] = useState(false);
	// flag to determine if we are showing edit form or create form
	const [isEdit, setIsEdit] = useState(false);
	// asset id that is currently being edited
	const [assetId, setAssetId] = useState(null);

	// show and hide form modal
	const toggleFormModal = () => setIsVisible(!isVisible);

	// sets flag for if we are editing or creating asset
	const toggleEdit = () => {
		setIsEdit(!isEdit);
	};

	// update local data in redux without making server request
	const updateData = (data, type, productId) => {
		updateMaterials(data, type, productId);
	};

	// deletes asset from product. Sends request and starts an action. Shows notification.
	const deleteAsset = async (id, type, endpoint, callBack) => {
		const url = endpoint || `/player/video/asset/${id}/delete`;
		if (id && type) {
			try {
				await services.delete(url);
				deleteMaterials(id, type);
				notificationHandler(
					t('messages:notifications.deleted'),
					`${t('messages:notifications.successfuly_deleted')} ${t(
						'names:notification_names.asset'
					)} `
				);
			} catch (error) {
				deleteMaterialsError(error);
			}
		} else {
			await services.delete(url);
			notificationHandler(
				t('messages:notifications.deleted'),
				`${t('messages:notifications.successfuly_deleted')}`
			);

			//trigger a callback function after sucsess
			if (callBack) {
				callBack();
			}
		}
	};

	// opens edit modal, retrieves asset id an calls function to change edit flag to on
	const editAsset = (id) => {
		toggleEdit();
		toggleFormModal();
		setAssetId(id);
	};

	return (
		<MaterialsContext.Provider
			value={{
				isVisible,
				isEdit,
				toggleFormModal,
				toggleEdit,
				resources,
				metaData,
				updateData,
				deleteAsset,
				assetId,
				setAssetId,
				editAsset,
				updateMaterialsError
			}}
		>
			{children}
		</MaterialsContext.Provider>
	);
};
