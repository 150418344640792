import React from 'react';

// Import components
import { Wraper } from 'components/common/layout';
import {
	ProviderStatistics,
	ProductisationStatistics,
	CatchupStatistics
} from './components';

function StatisticsDashboard() {
	return (
		<Wraper lg="12" error={false}>
			<div className="statistics__main-grid">
				<ProviderStatistics />
				<ProductisationStatistics />
				<CatchupStatistics />
			</div>
		</Wraper>
	);
}

export default StatisticsDashboard;
