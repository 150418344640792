import React from 'react';
import i18n from 'i18next';

// Import components
import ProvisioningForm from '../../provisioning_form/ProvisioningForm';
// import ProvisioningImages from '../../provisioning_file/ProvisioningImages';
import ProvisioningProductsTable from '../../provisioning_products/ProvisioningProductsTable';
import SystemSettingsForm from 'components/utilities/system_settings/SystemSettingsForm';
import ProvisioningPacketsAssigned from 'components/views/provisioning/provisioning_packets_assigned/ProvisioningPacketsAssigned';

export const provisioningTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <ProvisioningForm />
	},
	{
		name: i18n.t('common:tabs.system_settings'),
		path: 'system_settings',
		disableOnCreate: true,
		component: <SystemSettingsForm />
	},
	// {
	// 	name: i18n.t('common:tabs.pictures'),
	// 	path: 'pictures',
	// 	disableOnCreate: true,
	// 	component: <ProvisioningImages />
	// },
	{
		name: i18n.t('common:tabs.products'),
		path: 'provisioning_products',
		disableOnCreate: true,
		component: <ProvisioningProductsTable />
	},
	{
		name: i18n.t('common:tabs.packets_assigned'),
		path: 'packets_assigned',
		disableOnCreate: true,
		component: <ProvisioningPacketsAssigned />
	}
];
