import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import {
	fetchPaymentTemplateMetadata,
	deletePaymentTemplateTable,
	editPaymentTemplateTable
} from 'store/actions';

// Import services
import services from 'services/services';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

// Import translation
import { useTranslation } from 'react-i18next';

// Import validation
import validate from './validate';

// Import helpers
import {
	FETCH_PAYMENT_TEMPLATES_UPDATE,
	FETCH_PAYMENT_TEMPLATES_CREATE
} from 'components/helpers/api';
import { notifySuccess, notifyError } from './helpers';
import { addObjectEntriesToFormData } from '../helpers';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';

import Fields from './fields/Fields';
import PaymentTemplateModal from '../payment_template_modal/PaymentTemplateModal';
import { PaymentTemplateProvider } from '../PaymentTemplateContext';

const PaymentTemplateForm = ({
	fetchPaymentTemplateMetadata,
	error,
	loading,
	resource,
	requestBody,
	initialValues,
	match: {
		params: { id }
	}
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { pathname } = useLocation();
	const isEdit = pathname.includes('/edit');

	const mode = isEdit ? 'edited' : 'created';

	const handleOnSubmit = async ({ name }) => {
		// There must be an entry in the table to submit
		if (Object.keys(requestBody.prices).length <= 0) {
			notificationHandler(
				t('messages:notifications.error'),
				t(`payment_templates:errors.table_empty`),
				'error',
				5
			);
			return;
		}

		const url = isEdit
			? FETCH_PAYMENT_TEMPLATES_UPDATE.replace(':id', id)
			: FETCH_PAYMENT_TEMPLATES_CREATE;

		// Create formdata
		const formData = new FormData();
		formData.append('name', name);
		addObjectEntriesToFormData(formData, 'prices', requestBody.prices);
		addObjectEntriesToFormData(
			formData,
			'external_ids',
			requestBody.external_ids
		);
		// Editing template on backend requires invoking POST method with additional attr _method that
		// allows to distinguish create from edit. For some reason API doesn't allow PUT method with a body data.
		if (isEdit) formData.append('_method', 'PUT');

		try {
			const res = await services.post(url, formData, {
				headers: { 'Content-Type': 'multipart/form-data' }
			});
			if (res.data?.success === false) {
				notifyError(mode);
			} else {
				notifySuccess(mode);
				history.push('/panel/payment_templates');
			}
		} catch (error) {
			if (error.response.status === 422) {
				if (error.response.data.validator?.errors?.name) {
					notificationHandler(
						t('messages:notifications.error'),
						t(`payment_templates:errors.name_unique`),
						'error',
						5
					);
				} else notifyError(mode);
			} else notifyError(mode);
		}
	};

	return (
		<PaymentTemplateProvider
			resources={resource}
			updatePayment={editPaymentTemplateTable}
			deletePayment={deletePaymentTemplateTable}
		>
			<FormTemplate
				fetchResourceToEdit={fetchPaymentTemplateMetadata}
				error={error}
				isLoaded={!loading}
			>
				{() => (
					<Form
						initialValues={initialValues}
						validate={validate}
						onSubmit={handleOnSubmit}
						render={({ handleSubmit, submitting }) => (
							<form onSubmit={handleSubmit}>
								<Fields submitting={submitting} isEdit={isEdit} />
								<PaymentTemplateModal
									id={id}
									resource={resource}
									isLoaded={!loading}
									error={error}
								/>
								<FormButtons
									isButtonDisabled={submitting}
									path="payment_templates"
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						)}
					/>
				)}
			</FormTemplate>
		</PaymentTemplateProvider>
	);
};

PaymentTemplateForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchPaymentTemplateMetadata: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string })
	})
};

const mapStateToProps = ({ payment_templates: { edit } }) => {
	return {
		initialValues: { name: edit.edit.name },
		resource: {
			assets: edit.table
		},
		requestBody: edit.edit,
		error: edit.error,
		loading: edit.loading
	};
};

export default compose(
	connect(mapStateToProps, { fetchPaymentTemplateMetadata }),
	withRouter
)(PaymentTemplateForm);
