import { BLUEONLINE_MAIN_SYSTEM } from './variables';

// list of all user roles
export const allRoles = {
	CMS_WRITE: 'CMS_WRITE',
	CMS_READ: 'CMS_READ',
	CRM_WRITE: 'CRM_WRITE',
	CRM_READ: 'CRM_READ',
	DATA_WRITE: 'DATA_WRITE',
	DATA_READ: 'DATA_READ',
	PORTAL_WRITE: 'PORTAL_WRITE',
	PORTAL_READ: 'PORTAL_READ',
	INGESTION_WRITE: 'INGESTION_WRITE',
	INGESTION_READ: 'INGESTION_READ',
	ADMIN_WRITE: 'ADMIN_WRITE',
	ADMIN_READ: 'ADMIN_READ',
	VIDEO_WRITE: 'VIDEO_WRITE',
	VIDEO_READ: 'VIDEO_READ',
	SUPPORT_WRITE: 'SUPPORT_WRITE',
	SYSTEM_OPERATOR: 'SYSTEM_OPERATOR'
};

const superadmin = ['superadmin', 'super_admin'];

// specific roles
export const cms = ['CMS_WRITE', 'CMS_READ', ...superadmin];
export const crm = ['CRM_WRITE', 'CRM_READ', ...superadmin];
export const data = ['DATA_WRITE', 'DATA_READ', ...superadmin];
export const portal = ['PORTAL_WRITE', 'PORTAL_READ', ...superadmin];
export const ingestion = ['INGESTION_WRITE', 'INGESTION_READ', ...superadmin];
export const admin = ['ADMIN_WRITE', 'ADMIN_READ', ...superadmin];
export const video = ['VIDEO_WRITE', 'VIDEO_READ', ...superadmin];
export const support = ['SUPPORT_WRITE', ...superadmin];
export const system_operator = ['SYSTEM_OPERATOR', ...superadmin];

// specific systems
export const main = [BLUEONLINE_MAIN_SYSTEM];

// all roles grouped in single object
export const allRolesGrouped = {
	cms,
	crm,
	data,
	portal,
	ingestion,
	admin,
	video,
	support
};

// Mock roles for auth
export const mockRoles = [
	'CMS_WRITE',
	'CMS_READ',
	'CRM_WRITE',
	'CRM_READ',
	'DATA_WRITE',
	'DATA_READ',
	'PORTAL_WRITE',
	'PORTAL_READ',
	'INGESTION_WRITE',
	'INGESTION_READ',
	'ADMIN_WRITE',
	'ADMIN_READ',
	'VIDEO_WRITE',
	'VIDEO_READ',
	'SUPPORT_WRITE'
];
