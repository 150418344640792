import { combineReducers } from 'redux';
// Import reducers
import table from './provisioning_panel_reducer';
import form from './provisioning_form_reducer';
import files from './provisioning_files_reducer';
import products from './provisioning_products_reducer';
import packets_assigned from './provisioning_packets_assigned_reducer';

export default combineReducers({
	table,
	form,
	files,
	products,
	packets_assigned
});
