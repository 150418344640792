import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchSubscriberDevicesSn, detachDeviceSn } from 'store/actions';

// Import columns
import { createDeviceColumns } from './subscriber_devices_sn_columns';

// Import translation
import { useTranslation } from 'react-i18next';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

// Import utilities
import { messageConfirmMultipleIdHandler } from 'components/utilities/message';

// Import components
import {
	Ibox,
	IboxTitle,
	IboxContentBase,
	Wraper
} from 'components/common/layout';
import { ReactServerSideTable } from 'components/utilities/table';
import { Button } from 'antd';
import SubscribersDevicesSnModal from './subscribers_devices_sn_form/SubscribersDevicesSnModal';

const SubscribersDevicesSn = ({
	error,
	resources,
	fetchSubscriberDevicesSn,
	detachDeviceSn,
	match: {
		params: { id: userId }
	}
}) => {
	const { t } = useTranslation();
	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleDeviceDetach = (deviceId) => {
		messageConfirmMultipleIdHandler({
			callback: detachDeviceSn,
			title: t('subscribers:devices_sn.detach_confirmation.title'),
			content: t('subscribers:devices_sn.detach_confirmation.content'),
			ids: [userId, deviceId]
		});
	};

	const handleSetModalVisibility = () => {
		setIsModalVisible((prevState) => !prevState);
	};

	return (
		<Wraper lg="12" error={error}>
			<Ibox error={false} className="m-b-lg">
				<IboxTitle title="Urządzenia SN" isLoaded={false}>
					{!isReadOnly && (
						<div className="d-flex justify-content-end">
							<Button
								type="primary"
								htmlType="button"
								onClick={handleSetModalVisibility}
								className="ml-3"
							>
								{t('subscribers:buttons.add_device')}
							</Button>
							<SubscribersDevicesSnModal
								isVisible={isModalVisible}
								onCancel={handleSetModalVisibility}
								subscriberId={userId}
								setModalVisibility={handleSetModalVisibility}
							/>
						</div>
					)}
				</IboxTitle>

				<IboxContentBase>
					<>
						<ReactServerSideTable
							resources={resources}
							columns={createDeviceColumns(handleDeviceDetach, isReadOnly)}
							itemId={userId}
							fetchResourcesAction={fetchSubscriberDevicesSn}
							tableType="subscriber_devices_sn"
						/>
					</>
				</IboxContentBase>
			</Ibox>
		</Wraper>
	);
};

SubscribersDevicesSn.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchSubscriberDevicesSn: PropTypes.func.isRequired,
	detachDeviceSn: PropTypes.func.isRequired,
	match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) })
};

const mapStateToProps = ({ subscribers: { devicesSn } }) => ({
	error: devicesSn.error,
	resources: devicesSn
});

export default compose(
	connect(mapStateToProps, {
		fetchSubscriberDevicesSn,
		detachDeviceSn
	}),
	withRouter
)(SubscribersDevicesSn);
