import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	logo: {
		data: [],
		isLoaded: false,
		error: false
	},
	poster: {
		data: [],
		isLoaded: false,
		error: false
	},
	cover_large: {
		data: [],
		isLoaded: false,
		error: false
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** FETCH LOGO FILE ******************
			case types.FETCH_COGNITO_LOGO_FILE_LOADING:
				draft.logo.data = [];
				draft.logo.isLoaded = false;
				draft.logo.error = false;
				return;

			case types.FETCH_COGNITO_LOGO_FILE_SUCCESS:
				draft.logo.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.logo.isLoaded = true;
				return;

			case types.FETCH_COGNITO_LOGO_FILE_ERROR:
				draft.logo.isLoaded = true;
				draft.logo.error = true;
				return;

			// ****************** FETCH COVER LARGE FILE ******************
			case types.FETCH_COGNITO_COVER_LARGE_FILE_LOADING:
				draft.cover_large.data = [];
				draft.cover_large.isLoaded = false;
				draft.cover_large.error = false;
				return;

			case types.FETCH_COGNITO_COVER_LARGE_FILE_SUCCESS:
				draft.cover_large.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.cover_large.isLoaded = true;
				return;

			case types.FETCH_COGNITO_COVER_LARGE_FILE_ERROR:
				draft.cover_large.isLoaded = true;
				draft.cover_large.error = true;
				return;

			// ****************** FETCH POSTER FILE ******************
			case types.FETCH_COGNITO_POSTER_FILE_LOADING:
				draft.poster.data = [];
				draft.poster.isLoaded = false;
				draft.poster.error = false;
				return;

			case types.FETCH_COGNITO_POSTER_FILE_SUCCESS:
				draft.poster.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.poster.isLoaded = true;
				return;

			case types.FETCH_COGNITO_POSTER_FILE_ERROR:
				draft.poster.isLoaded = true;
				draft.poster.error = true;
				return;

			default:
				return state;
		}
	});
