import services from 'services/services';

// Import helpers
import { convertErrors } from 'components/helpers/error';
import { capitalizeText } from 'components/helpers/convert';

// Import utilities
import {
	createFormNotification,
	notificationHandler
} from 'components/utilities/notifications/index';

export const submitModalForm = async ({
	isEdit = true,
	itemID,
	resources,
	api,
	redirect = true,
	apiSlug = '',
	notification,
	callbackAction = () => {}
}) => {
	try {
		// Check if it is edit, return create or id to update
		const path = !isEdit ? itemID : 'create';
		// Check method - create or update
		const method = !isEdit ? services.put : services.post;
		// Sent request with resources
		await method(`/${api}${redirect ? `/${path}/${apiSlug}` : ''}`, resources);

		// If there is no notification function in props, then use createFormNotification
		!notification
			? createFormNotification(isEdit, capitalizeText(api))
			: notification();

		callbackAction();
	} catch (error) {
		return handleErrors(error);
	}
};

const handleErrors = (error) => {
	// Convert Errors
	const errors = convertErrors(error.response.data.validator.errors);
	// CreateNotification
	Object.entries(errors).forEach(([key, value]) => {
		notificationHandler(capitalizeText(key), value, 'error', 8);
	});
	// Throw new errors
	return { ...errors };
};
