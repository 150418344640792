import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { VerificationContext } from '../VerificationContext';

// Import components
import { Button } from 'reactstrap';
import Spinner from './Spinner';
import { Ibox } from 'components/common/layout';

function VerificationButtons() {
	const { t } = useTranslation();
	const {
		isDisabled,
		isAcceptDisabled,
		isRejectDisabled,
		document,
		fileName,
		onVerify,
		onUnverify,
		verify,
		unverify
	} = useContext(VerificationContext);
	return (
		<Ibox className="d-flex justify-content-between m-b-lg">
			<div className="d-flex">
				<div>
					<Button
						href={document}
						disabled={isDisabled}
						download={fileName}
						className="m-r-sm btn__link btn__link--outline bg-primary text-white"
					>
						{t('verification:buttons.download')}
					</Button>
				</div>
				<div>
					<Button
						href={document}
						disabled={isDisabled}
						target="_blank"
						rel="noopener noreferrer"
						className="btn__link btn__link--outline bg-primary text-white"
					>
						{t('verification:buttons.open')}
					</Button>
				</div>
			</div>
			<div className="d-flex">
				<Button
					className="bg-primary m-r-sm"
					disabled={isAcceptDisabled}
					onClick={onVerify}
				>
					<div className="d-flex">
						{verify.loading && <Spinner />}
						{t('verification:buttons.activate')}
					</div>
				</Button>
				<Button
					className="bg-primary"
					disabled={isRejectDisabled}
					onClick={onUnverify}
				>
					<div className="d-flex">
						{unverify.loading && <Spinner />}
						{t('verification:buttons.deactivate')}
					</div>
				</Button>
			</div>
		</Ibox>
	);
}

export default VerificationButtons;
