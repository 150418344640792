// Import translation
import i18n from 'i18next';

export default ({ type, category, name, value }) => {
	const errors = {};

	if (!type) {
		errors.type = i18n.t('common:validation.required');
	}

	if (!category) {
		errors.category = i18n.t('common:validation.required');
	}

	if (!name) {
		errors.name = i18n.t('common:validation.required');
	} else if (name.length > 255) {
		errors.name = i18n.t('tags:validation.name_length');
	}

	if (!value) {
		errors.value = i18n.t('common:validation.required');
	} else if (value.length < 2 || value.length > 512) {
		errors.value = i18n.t('tags:validation.value_length');
	}

	return errors;
};
