import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { shape, string } from 'prop-types';
import { withRouter } from 'react-router-dom';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import {
	Wraper,
	Ibox,
	IboxTitle,
	IboxContentBase
} from 'components/common/layout';
import CardsList from 'components/utilities/products_select/cards_list/CardsList';
import Spin from 'antd/es/spin';

// Import custom hook
import useSectionsPreview from './useSectionsPreview';

const SectionsPreview = ({
	match: {
		params: { id: itemId }
	}
}) => {
	const { t } = useTranslation();

	const {
		products,
		isEnd,
		loading,
		fetchError,
		fetchSectionsPreview
	} = useSectionsPreview(itemId);

	const infiniteScrollLoader = !loading ? (
		<div className="d-flex justify-content-center" key="loader">
			<Spin size="large" />
		</div>
	) : null;

	return (
		<>
			<Wraper error={fetchError}>
				<Ibox>
					<IboxTitle title={t('preview:title')} />
					<IboxContentBase isLoaded={!loading}>
						<InfiniteScroll
							pageStart={0}
							loadMore={fetchSectionsPreview}
							hasMore={!isEnd}
							loader={infiniteScrollLoader}
							initialLoad={false}
						>
							<CardsList data={products} />
						</InfiniteScroll>
					</IboxContentBase>
				</Ibox>
			</Wraper>
		</>
	);
};

SectionsPreview.propTypes = {
	match: shape({
		params: shape({ id: string.isRequired })
	})
};

export default withRouter(SectionsPreview);
