import React from 'react';
import i18n from 'i18next';

// Import components
import CognitoForm from '../../cognito_form/CognitoForm';
import CognitoImages from '../../cognito_files/CognitoImages';
import CognitoMetadataUpload from '../../cognito_files/CognitoMetadata';

export const cognitoTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <CognitoForm />
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <CognitoImages />
	},
	{
		name: i18n.t('common:tabs.metadata_file'),
		path: 'metadata_file',
		disableOnCreate: true,
		component: <CognitoMetadataUpload />
	}
];
