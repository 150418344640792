import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

// Import redux actions
import {
	fetchSystems,
	setSystemsQuery,
	dictionarySystems,
	createFiltersSuggestions
} from 'store/actions';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import columns
import { createColumns } from './systems_columns';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';
import useSystem from 'hooks/useSystem';

// Import helpers
import {
	checkIsLastSystemDepth,
	fetchAllSystems,
	getSystemsNames
} from './helpers';
import { QUERY_SYSTEMS_DATA } from 'components/helpers/api';

const Systems = ({
	fetchSystems,
	setSystemsQuery,
	resources,
	error,
	dictionarySystems,
	createFiltersSuggestions,
	filters
}) => {
	const { t } = useTranslation();
	const { currentSystem, mainSystem } = useSystem();

	// We need this query because we need all systems to check system depth, and the systems in the list change during we filter them
	const { data } = useQuery({
		queryKey: QUERY_SYSTEMS_DATA,
		queryFn: fetchAllSystems
	});

	const isLastSystemDepth = checkIsLastSystemDepth({
		currentSystem,
		systems: data?.data?.data
	});

	const systemsFilters = {
		...filters,
		parent_system: getSystemsNames(filters.parent_system)
	};

	const filterActions = { dictionarySystems };

	useFilterLogic({
		filterActions,
		createFiltersSuggestions,
		filters: systemsFilters
	});

	const { columns } = resources;

	const addLinkText = !isLastSystemDepth && t('systems:buttons.add');

	return (
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="systems"
			typeText="system"
			addLinkText={addLinkText}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchSystems}
			resourcesForFetchAction={{ parent_system: mainSystem }}
			notificationDeleteSuccessTxt={t('systems:notification.delete_success')}
			notificationDeleteErrorTxt={t('systems:notification.delete_success')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setSystemsQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Systems.propTypes = {
	fetchSystems: PropTypes.func.isRequired,
	setSystemsQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	error: PropTypes.bool.isRequired,
	dictionarySystems: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired
};

const mapStateToProps = ({ systems, dictionary }) => {
	return {
		error: systems.table.error,
		resources: systems.table,
		filters: {
			parent_system: dictionary.systems
		}
	};
};

export default connect(mapStateToProps, {
	fetchSystems,
	setSystemsQuery,
	dictionarySystems,
	createFiltersSuggestions
})(Systems);
