import React from 'react';
import i18n from 'i18next';

// Import components
import SeasonsForm from '../../seasons_form/SeasonForm';
import Episodes from '../../../vod_episodes/episodes_table/Episodes';
import VodPrices from 'components/views/vod/vod_prices/VodPrices';
import VodAvailability from 'components/views/vod/vod_availability/VodAvailability';
import Payments from 'components/views/payments/Payments';
import SeasonsImages from 'components/views/vod/vod_file/SeasonsImages';
import VodPacketsAssigned from 'components/views/vod/vod_packets_assigned/VodPacketsAssigned';
import VodProductiveSTB from 'components/views/vod/vod_productive_stb/VodProductiveSTB';
import VodSectionsAssigned from '../../../vod_sections_assigned/VodSectionsAssigned';
import VodCatalogsAssigned from '../../../vod_catalogs_assigned/VodCatalogsAssigned';
import SystemSettingsForm from 'components/utilities/system_settings/SystemSettingsForm';

export const seasonsTabs = [
	{
		name: i18n.t(`common:tabs.metadata`),
		path: 'metadata',
		disableOnCreate: false,
		component: <SeasonsForm />
	},
	{
		name: i18n.t('common:tabs.system_settings'),
		path: 'system_settings',
		disableOnCreate: false,
		component: <SystemSettingsForm />
	},
	{
		name: i18n.t(`common:tabs.pictures`),
		path: 'pictures',
		disableOnCreate: true,
		component: <SeasonsImages />
	},
	{
		name: i18n.t(`common:tabs.availability`),
		path: 'availability',
		disableOnCreate: true,
		component: <VodAvailability />
	},
	{
		name: i18n.t(`common:tabs.prices`),
		path: 'prices',
		disableOnCreate: true,
		component: <VodPrices />
	},
	{
		name: i18n.t(`common:tabs.episodes`),
		path: 'episodes',
		disableOnCreate: true,
		component: <Episodes />
	},
	{
		name: i18n.t(`common:tabs.payments`),
		path: 'payments',
		disableOnCreate: true,
		component: <Payments />
	},
	{
		name: i18n.t('common:tabs.packets_assigned'),
		path: 'packets_assigned',
		disableOnCreate: true,
		component: <VodPacketsAssigned />
	},
	{
		name: i18n.t('common:tabs.product_sections_assigned_statically'),
		path: 'sections_assigned',
		disableOnCreate: true,
		component: <VodSectionsAssigned fetchUrlParams="/static?" />
	},
	{
		name: i18n.t('common:tabs.product_sections_assigned_stb'),
		path: 'sections_assigned_stb',
		disableOnCreate: true,
		component: <VodSectionsAssigned fetchUrlParams="?platform=STB&" />
	},
	{
		name: i18n.t('common:tabs.product_catalogs_assigned'),
		path: 'catalogs_assigned',
		disableOnCreate: true,
		component: <VodCatalogsAssigned />
	},
	{
		name: i18n.t('common:tabs.productive_stb'),
		path: 'productization_stb',
		disableOnCreate: true,
		component: <VodProductiveSTB />
	}
];
