import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchIngesterHistoryVodDetails } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { columns } from './ingester_history_vod_details_columns';

// Import components
import ReactTable from 'react-table';
import { Empty } from 'antd';
function IngesterHistoryVodDetails({
	vodId,
	resources,
	fetchIngesterHistoryVodDetails
}) {
	const { t } = useTranslation();

	useEffect(() => {
		fetchIngesterHistoryVodDetails(vodId);
		// eslint-disable-next-line
	}, [JSON.stringify(resources)]);

	if (!resources.length) {
		return <Empty description={t('common:no_data')} />;
	}

	return (
		<ReactTable
			data={resources}
			columns={columns}
			pageSize={resources.length}
			showPagination={false}
		/>
	);
}

IngesterHistoryVodDetails.propTypes = {
	vodId: PropTypes.string.isRequired,
	resources: PropTypes.array.isRequired,
	fetchIngesterHistoryVodDetails: PropTypes.func.isRequired
};

const mapStateToProps = ({
	ingester: {
		history_table: { data }
	}
}) => ({
	resources: data
});

export default connect(mapStateToProps, {
	fetchIngesterHistoryVodDetails
})(IngesterHistoryVodDetails);
