import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { Form as FinalForm } from 'react-final-form';
import { Button, Form } from 'reactstrap';

// Import translation
import i18n from 'i18next';

// Import validation
import validate from './helpers/validate';

// Import components
import Fields from './Fields';

class SignIn extends React.Component {
	static propTypes = {
		signIn: PropTypes.func.isRequired
	};

	// Fetch signIn action to log in the user
	// Dispatch signIn action from login
	onSubmit = (values) => this.props.signIn(values);

	render() {
		return (
			<FinalForm
				initialValues={{ email: '', password: '' }}
				onSubmit={this.onSubmit}
				validate={validate}
				render={({ handleSubmit, submitting }) => (
					<Form
						data-testid="login-form"
						onSubmit={handleSubmit}
						className="login__form m-t"
					>
						<Fields />

						<div className="login__link_box">
							<Link
								data-testid="login-link"
								className="login__link"
								to="/login/forgot_password"
							>
								<small>{i18n.t('login:sign_up.forget_password')}</small>
							</Link>
						</div>
						<Button
							data-testid="login-submit-button"
							type="submit"
							className="btn__signin m-t-xs"
							disabled={submitting}
							color="primary"
							size="lg"
							block
						>
							{i18n.t('login:sign_up.sign_in')}
						</Button>
					</Form>
				)}
			/>
		);
	}
}

export default connect(
	null,
	actions
)(SignIn);
