// ******************** BARKER_CHANNELS DATA TABLE ********************
export const FETCH_BARKER_CHANNELS_LOADING = 'FETCH_BARKER_CHANNELS_LOADING';
export const FETCH_BARKER_CHANNELS_SUCCESS = 'FETCH_BARKER_CHANNELS_SUCCESS';
export const FETCH_BARKER_CHANNELS_ERROR = 'FETCH_BARKER_CHANNELS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_BARKER_CHANNELS_SEARCH_QUERY =
	'SET_BARKER_CHANNELS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_BARKER_CHANNELS_TABLE_SORTING =
	'SAVE_BARKER_CHANNELS_TABLE_SORTING';

// ******************** DELETE BARKER CHANNEL ********************
export const DELETE_BARKER_CHANNEL_LOADING = 'DELETE_BARKER_CHANNEL_LOADING';
export const DELETE_BARKER_CHANNEL_SUCCESS = 'DELETE_BARKER_CHANNEL_SUCCESS';
export const DELETE_BARKER_CHANNEL_ERROR = 'DELETE_BARKER_CHANNEL_ERROR';

// ******************** BARKER_CHANNEL ********************
export const FETCH_BARKER_CHANNEL_LOADING = 'FETCH_BARKER_CHANNEL_LOADING';
export const FETCH_BARKER_CHANNEL_SUCCESS = 'FETCH_BARKER_CHANNEL_SUCCESS';
export const FETCH_BARKER_CHANNEL_ERROR = 'FETCH_BARKER_CHANNEL_ERROR';

// ******************** BARKER_CHANNEL PRODUCTS ********************
// ---------------- FETCH PRODUCTS ----------------
export const FETCH_BARKER_CHANNEL_PRODUCTS_LOADING =
	'FETCH_BARKER_CHANNEL_PRODUCTS_LOADING';
export const FETCH_BARKER_CHANNEL_PRODUCTS_SUCCESS =
	'FETCH_BARKER_CHANNEL_PRODUCTS_SUCCESS';
export const FETCH_BARKER_CHANNEL_PRODUCTS_ERROR =
	'FETCH_BARKER_CHANNEL_PRODUCTS_ERROR';

// ---------------- SET PRODUCTS SEARCH QUERY ----------------
export const SET_BARKER_CHANNEL_PRODUCTS_SEARCH_QUERY =
	'SET_BARKER_CHANNEL_PRODUCTS_SEARCH_QUERY';

// ---------------- DELETE PRODUCT ----------------
export const DELETE_BARKER_CHANNEL_PRODUCT_LOADING =
	'DELETE_BARKER_CHANNEL_PRODUCT_LOADING';
export const DELETE_BARKER_CHANNEL_PRODUCT_SUCCESS =
	'DELETE_BARKER_CHANNEL_PRODUCT_SUCCESS';
export const DELETE_BARKER_CHANNEL_PRODUCT_ERROR =
	'DELETE_BARKER_CHANNEL_PRODUCT_ERROR';

// ---------------- REFRESH_BARKER_CHANNEL_PRODUCTS ----------------
export const REFRESH_BARKER_CHANNEL_PRODUCTS =
	'REFRESH_BARKER_CHANNEL_PRODUCTS';

// ---------------- SAVE SORTING OPTIONS  ----------------
export const SAVE_BARKER_CHANNEL_PRODUCTS_TABLE_SORTING =
	'SAVE_BARKER_CHANNEL_PRODUCTS_TABLE_SORTING';
