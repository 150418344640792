import { combineReducers } from 'redux';

// Import reducers
import table from './marketing_campaigns_table_reducer';
import form from './marketing_campaigns_form_reducer';
import targets from './marketing_campaign_targets_reducer';

export default combineReducers({
	table,
	form,
	targets
});
