import { omit } from 'lodash';

// Import translation
import i18n from 'i18next';

// Import variables
import { CHANNEL, VOD } from 'components/helpers/variables';

export const checkFetchErrors = (error) => {
	if (error.response) {
		return error.response.status === 401 ? false : true;
	}
};

// Capitalize first letter
export const capitalizeText = (text) =>
	text.charAt(0).toUpperCase() + text.slice(1).replace('_', ' ');

// Create query parameters
export const queryParams = (
	options,
	tableLength,
	columns,
	startIndex,
	withLimitZero,
	isReport
) => {
	const {
		sorted: { type },
		filtered: { query, search_column },
		accessor,
		pageSize
	} = options;

	const length = pageSize ? pageSize : tableLength;

	// Check if we want length query in url
	const lengthParameter = length ? `length=${length}` : '';

	// Find column's index in columns array
	const index = columns.findIndex((column) => column.name === accessor);
	// Check if column index exists
	const columnIndex = index > 0 ? index : 0;

	// Find column's index in columns array for search in column
	const index_search_column = columns.findIndex(
		(column) => column.name === search_column
	);

	const searchBy = search_column
		? `columns[${index_search_column}][search][value]=`
		: 'search[value]=';

	// first request for columns is faster thanks to limit=0 option
	const limit = withLimitZero && columnIndex === 0 ? '&limit=0' : '';

	// IsReport set to true will increase scope of data retrieved from backend
	const report = isReport ? `&is_report=true` : '';

	const urlSortSection = `order[0][column]=${columnIndex}&order[0][dir]=${type}&`;
	const urlVariablesSection = `start=${startIndex}&${lengthParameter}&${searchBy}${query}${limit}${report}`;

	return `${urlSortSection}${urlVariablesSection}`;
};

// Create query parameters for promotions
// Promotions need sorting by two columns (but function can accept more)
export const promotionsQueryParams = (
	options,
	tableLength,
	columns,
	startIndex
) => {
	const {
		sorted: { type },
		filtered: { query, search_column },
		accessor,
		defaultSorted,
		currentlySorted
	} = options;

	// Check if we want length query in url
	const length = tableLength ? `&length=${tableLength}` : '';

	// Find column's index in columns array
	const index = columns.findIndex((column) => column.name === accessor);
	// Check if column index exists
	const columnIndex = index > 0 ? index : 0;

	// Find column's index in columns array for search in column
	const index_search_column = columns.findIndex(
		(column) => column.name === search_column
	);

	const searchBy = search_column
		? `columns[${index_search_column}][search][value]=`
		: 'search[value]=';

	// ======== COLUMN SORT QUERY ========
	// has user sorted table differently
	const isDefaultSort =
		JSON.stringify(defaultSorted) === JSON.stringify(currentlySorted);

	// helper function that searches for column index.
	// returns zero if column not present
	const searchColumnIndex = (defaultColumnName) => {
		const index = columns.findIndex(
			(column) => column.name === defaultColumnName
		);
		return index === -1 ? 0 : index;
	};

	// query for default sort
	const columnsDefaultOrderQuery = defaultSorted.reduce(
		(query, sortingColumn, index) => {
			return (
				query +
				`order[${index}][column]=${searchColumnIndex(
					sortingColumn.id
				)}&order[${index}][dir]=${sortingColumn.desc ? 'desc' : 'asc'}&`
			);
		},
		''
	);

	// query for user sort
	const columnsUserOrderQuery = `order[0][column]=${columnIndex}&order[0][dir]=${type}&`;

	// final query based on user action
	// if currently sorted column is different from default sort then change url query
	const columnsOrderQuery = isDefaultSort
		? columnsDefaultOrderQuery
		: columnsUserOrderQuery;
	// ======== COLUMN SORT QUERY ========

	return `${columnsOrderQuery}start=${startIndex}${length}&${searchBy}${query}`;
};

// Get column id by its name
export const getColumnId = (columns) => (columnName) => {
	return columns.findIndex((column) => column.name === columnName);
};

export const getColumnParamName = (index) => `columns[${index}][search][value]`;

// Get providers name from object list
export const convertProvidersToValuePairs = (providers) =>
	providers.map(({ provider, provider_id }) => ({
		name: provider,
		value: provider_id
	}));

// Convert model payments suggestions to key - value pairs and add none value suggestion
export const convertPaymentModelsSuggestions = (paymentModels) => {
	const pairedValues = paymentModels.map((paymentModel) => ({
		name: paymentModel,
		value: paymentModel
	}));

	return [
		...pairedValues,
		{ name: i18n.t('filters:suggestions.none'), value: 'none' }
	];
};

// escape "quotes" characters for csv export
// when there are "quotes" csv export treated it as new column. Applying ""double quoutes"" solves the problem
export const escapeQuotes = (data) => {
	return data.map((item) => {
		//checks if item is an Object
		if (item === Object(item)) {
			Object.keys(item).forEach((key) => {
				if (key && typeof item[key] === 'string') {
					item[key] = item[key].replace(/"/g, `""`);
				}
			});
			return item;
		} else return item;
	});
};

// Convert Genres CSV data
const convertGenresData = (genres) => genres?.map(({ name }) => name) ?? [];

// Convert VOD CSV data
const convertChannelData = (item) => {
	const isChannel = item.type === CHANNEL;

	const genres = convertGenresData(item.genres);

	if (isChannel) {
		let platformFields = {};
		item.platform_fields.forEach(({ platform, ...rest }) => {
			for (const key in rest) {
				platformFields[`${platform}_${key}`] = rest[key];
			}
		});

		const materials = item.materials.reduce((acc, { src }) => {
			return src ? `${acc}${src} \n` : acc;
		}, '');

		const region_limitation = item.region_limitation?.cities ?? [];

		const systemFields = item.system_fields ?? {};

		const product = omit(item, ['platform_fields', 'system_fields', 'images']);

		return {
			...product,
			...systemFields,
			...platformFields,
			genres,
			materials,
			region_limitation
		};
	}

	const product = omit(item, ['images']);

	return { ...product, genres };
};

// Convert CSV data
export const convertCSVData = (data) =>
	data.map((item) => {
		switch (item.type) {
			case VOD:
			case CHANNEL:
				return convertChannelData(item);

			default:
				return item;
		}
	});

export const convertStatusToValuePairs = (statusList) => {
	const pairedValues = statusList.map((status) => ({
		name: i18n.t(`vod:select:status.${status}`),
		value: status
	}));

	return pairedValues;
};

export const convertEntriesToValuePairs = (resources) => {
	return resources.map((item) => ({
		name: item[0],
		value: item[1]
	}));
};

export const convertCatchupStatesToValuePairs = (catchupRecordingStates) =>
	catchupRecordingStates
		.filter(({ catchup_recording_state }) => !!catchup_recording_state)
		.map(({ catchup_recording_state }) => catchup_recording_state);

/**
 * Convert array of data to array of objects with name and value keys
 * @param {array} array - array of data to convert
 * @param {string} nameKey - name of name key (value for name)
 * @param {string} valueKey - name of value key (value for value)
 * @returns {array} - [{name: 'item[nameKey]', value: item[valueKey]}, ...]
 */
export const convertArrayToValuePairsByKeyNames = (
	array,
	nameKey,
	valueKey,
	labelKey
) => {
	return array?.map((item) => {
		const name = item[nameKey] || item[0];
		const value = item[valueKey] || item[1];
		const label = item[labelKey] || '';

		const nameValue = label ? `${name} (${label})` : name;

		return { name: nameValue, value };
	});
};

export const convertDevicesSnData = (deviceSnData, tagsData) => {
	tagsData.forEach(({ name, value }) => {
		deviceSnData[name] = +value;
	});

	return deviceSnData;
};
