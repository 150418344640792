import i18n from 'i18next';

export const defaultFieldValues = {
	is_visible_at_login_screen: false
};

export const BASE_FIELDS = ({ isEdit }) => [
	{
		name: 'name',
		label: i18n.t('systems:form.fields.name'),
		type: 'text',
		disabled: !isEdit,
		required: true
	},
	{
		name: 'parent_system',
		label: i18n.t('systems:form.fields.parent_system'),
		type: 'select',
		disabled: !isEdit,
		required: true
	},
	{
		name: 'display_name',
		label: i18n.t('systems:form.fields.display_name'),
		type: 'text'
	},
	{
		name: 'is_visible_at_login_screen',
		label: i18n.t('systems:form.fields.is_visible_at_login_screen'),
		type: 'checkbox'
	},
	{
		name: 'login_screen_position',
		label: i18n.t('systems:form.fields.login_screen_position'),
		type: 'number'
	}
];
