import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	providersNamesById: []
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR DATA ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			case types.CLEAR_PROVIDERS_NAMES_BY_ID:
				draft.providersNamesById = [];
				break;

			// *************** FETCH PROVIDERS NAMES BASED ON PROVIDER ID ***************
			case types.FETCH_PROVIDERS_NAMES_BY_ID:
				draft.providersNamesById = action.payload.data;
				break;

			default:
				return state;
		}
	});
