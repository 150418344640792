import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

// Import components
import SubscribersDevicesSnForm from './SubscribersDevicesSnForm';

const SubscribersDevicesSnModal = ({
	isVisible,
	onCancel,
	subscriberId,
	setModalVisibility
}) => {
	const { t } = useTranslation();
	return (
		<Modal
			title={t('subscribers:devices_sn.modal_title')}
			open={isVisible}
			footer={null}
			onCancel={onCancel}
			destroyOnClose={true}
			width={700}
		>
			<SubscribersDevicesSnForm
				subscriberId={subscriberId}
				setModalVisibility={setModalVisibility}
			/>
		</Modal>
	);
};

SubscribersDevicesSnModal.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	subscriberId: PropTypes.string.isRequired,
	setModalVisibility: PropTypes.func.isRequired
};

export default SubscribersDevicesSnModal;
