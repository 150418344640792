import { useEffect, useState } from 'react';
import services from 'services/services';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscriber, clearPanelState } from 'store/actions';
import { message } from 'antd';

// Import utilities
import {
	messageConfirmClearHandler,
	messageConfirmDeleteHandler
} from 'components/utilities/message/confirm_message';
import { notificationHandler } from 'components/utilities/notifications';

// Import hooks
import useSystem from 'hooks/useSystem';
import useAuthCheck from 'hooks/useAuthCheck';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { SUBSCRIBER_SOURCE_TYPES } from 'components/helpers/variables';
import { checkIsProperVectraSystem } from 'components/helpers/system_permissions';
import { removeSubscriberPacket } from './helpers';

const useSubscribersPackets = ({ id }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [formModalVisibility, setFormModalVisibility] = useState(false);

	const { isReadOnly } = useAuthCheck();
	const { currentSystem, isCurrentSystemMain } = useSystem();

	useEffect(() => {
		fetchSubscriber(id)(dispatch);
		// eslint-disable-next-line
	}, []);

	const subscriberData = useSelector(
		({ subscribers }) => subscribers.form.edit
	);

	// conditions are written this way to always hide button and table until data is fetched
	let showClearPermissionsButton = false;
	let showSubscriptionsTable = false;
	if (subscriberData?.source) {
		if (subscriberData?.source === SUBSCRIBER_SOURCE_TYPES.PURE_OTT) {
			showSubscriptionsTable = true;
		} else {
			showClearPermissionsButton = checkIsProperVectraSystem({
				currentSystem,
				isCurrentSystemMain,
				isReadOnly
			});
		}
	}

	const clearPermissions = async (subscriberId) => {
		try {
			// clear permissions request
			await services.post(`/subscriber/esales/cache/${subscriberId}/reset`);

			// show success notification
			notificationHandler(
				t('messages:notifications.commissioned'),
				t('messages:notifications.refresh_subscriber_permissions_success')
			);

			// reload table
			dispatch(clearPanelState());
		} catch (error) {
			// show error notification
			notificationHandler(
				t('messages:notifications.error'),
				t('common:errors.common_error_message'),
				'error'
			);
		}
	};

	const handleClearPermissions = () => {
		messageConfirmClearHandler(id, clearPermissions);
	};

	const toggleFormModal = () => {
		setFormModalVisibility((prevState) => !prevState);
	};

	const onCancel = () => {
		toggleFormModal();
		message.info(t('messages:messages.operation_canceled'));
	};

	const handleSelectItem = (packetUuid) =>
		messageConfirmDeleteHandler(
			'provisioning',
			packetUuid,
			removeSubscriberPacket,
			{ subscriber_id: id, clearPanelState: () => dispatch(clearPanelState()) }
		);

	return {
		clearPermissions: handleClearPermissions,
		showClearPermissionsButton,
		showSubscriptionsTable,
		onCancel,
		toggleFormModal,
		formModalVisibility,
		handleSelectItem,
		isReadOnly
	};
};

export default useSubscribersPackets;
