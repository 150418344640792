import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const VodSubtitlesForm = ({
	history,
	error,
	isLoaded,
	initialValues,
	prevPath
}) => {
	const { t } = useTranslation();
	// Remove "/panel/" from prevPath;
	const redirectPath = prevPath.slice(7);

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		// Submit the form with field values
		let formData = new FormData();
		formData.append('file', values.file[0]);
		formData.append('language', values.language);
		formData.append('video_id', parseInt(itemID, 10));

		return await submitForm({
			history,
			isEdit,
			itemID,
			resources: formData,
			api: 'assets/subtitles',
			notificationName: 'subtitle',
			redirectPath
		});
	};

	return (
		<FormTemplate error={error} isLoaded={isLoaded}>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={{ ...initialValues }}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields submitting={submitting} />

							<FormButtons
								isButtonDisabled={submitting}
								path={redirectPath}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

VodSubtitlesForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	prevPath: PropTypes.string.isRequired
};

const mapStateToProps = ({
	agreements: {
		versions: { form }
	},
	previous_location: { pathname }
}) => ({
	initialValues: form.edit,
	error: form.error,
	isLoaded: form.isLoaded,
	prevPath: pathname
});

export default compose(
	connect(mapStateToProps),
	withRouter
)(VodSubtitlesForm);
