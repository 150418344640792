import i18n from 'i18next';

// Import helpers
// import { isActiveVisible } from 'components/helpers/vod_helpers/render_active_field';

export const defaultFieldValues = {
	vod_uuid: '',
	title: '',
	title_org: '',
	title_brief: '',
	title_brief_org: '',
	summary_short: '',
	summary_medium: '',
	summary_long: '',
	actors: [],
	writers: [],
	directors: [],
	rating: 0,
	country_of_origin: '',
	actors_display: '',
	writers_display: '',
	directors_display: '',
	channel_name: '',
	distributor_name: '',
	active: false,
	hide_title: false,
	adult: false,
	krrit_tags: []
};

export const BASE_FIELDS = ({ status, subtype, isEdit }) => [
	{
		name: 'vod_uuid',
		label: i18n.t('vod:form:fields.uuid'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'title',
		label: i18n.t('vod:form:fields.title'),
		type: 'text',
		required: true
	},
	{
		name: 'title_org',
		label: i18n.t('vod:form:fields.title_org'),
		type: 'text',
		required: true
	},
	{
		name: 'title_brief',
		label: i18n.t('vod:form:fields.title_brief'),
		type: 'text',
		required: true,
		isVisible: false
	},
	{
		name: 'title_brief_org',
		label: i18n.t('vod:form:fields.title_brief_org'),
		type: 'text',
		required: false,
		isVisible: false
	},
	{
		name: 'summary_short',
		label: i18n.t('vod:form:fields.summary_short'),
		type: 'textarea',
		required: true
	},
	{
		name: 'summary_medium',
		label: i18n.t('vod:form:fields.summary_medium'),
		type: 'textarea',
		required: false,
		isVisible: false
	},
	{
		name: 'summary_long',
		label: i18n.t('vod:form:fields.summary_long'),
		type: 'textarea',
		required: false
	},
	{
		name: 'active',
		label: i18n.t('vod:form:fields.active'),
		type: 'checkbox',
		required: false,
		// isVisible: isActiveVisible({ status, subtype })
		isVisible: true
	},
	{
		name: 'duration',
		label: i18n.t('vod:form:fields.duration'),
		type: 'text',
		required: false
	},
	{
		name: 'year',
		label: i18n.t('vod:form:fields.year'),
		type: 'text',
		required: false
	},
	{
		name: 'rating',
		label: i18n.t('vod:form:fields.rating'),
		type: 'number',
		required: false
	},
	{
		name: 'channel_name',
		label: i18n.t('vod:form:fields.channel_name'),
		type: 'text',
		required: false
	},
	{
		name: 'country_of_origin',
		label: i18n.t('vod:form:fields.country_of_origin'),
		type: 'text',
		required: false
	},
	{
		name: 'actors',
		label: i18n.t('vod:form:fields.actors'),
		type: 'multi-select',
		mode: 'tags',
		required: false
	},
	{
		name: 'actors_display',
		label: i18n.t('vod:form:fields.actors_display'),
		type: 'textarea',
		required: false
	},
	{
		name: 'writers',
		label: i18n.t('vod:form:fields.writers'),
		type: 'multi-select',
		mode: 'tags',
		required: false
	},
	{
		name: 'writers_display',
		label: i18n.t('vod:form:fields.writers_display'),
		type: 'textarea',
		required: false
	},
	{
		name: 'directors',
		label: i18n.t('vod:form:fields.directors'),
		type: 'multi-select',
		mode: 'tags',
		required: false
	},
	{
		name: 'directors_display',
		label: i18n.t('vod:form:fields.directors_display'),
		type: 'textarea',
		required: false
	},
	{
		name: 'provider_id',
		label: i18n.t('vod:form:fields.provider_id'),
		type: 'multi-select',
		mode: '',
		required: false,
		disabled: true
	},
	{
		name: 'provider',
		label: i18n.t('vod:form:fields.provider'),
		type: 'multi-select',
		mode: '',
		required: false,
		disabled: true
	},
	{
		name: 'genres',
		label: i18n.t('vod:form:fields.genres'),
		type: 'multi-select',
		mode: 'multiple',
		required: false
	},
	{
		name: 'payment_models',
		label: i18n.t('vod:form:fields.payment_models'),
		type: 'multi-select',
		mode: 'multiple',
		required: false,
		disabled: true
	},
	{
		name: 'hide_title',
		label: i18n.t('vod:form:fields.hide_title'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'adult',
		label: i18n.t('vod:form:fields.adult'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'krrit_tags',
		label: i18n.t('vod:form:fields.tags'),
		type: 'multi-select',
		required: false,
		mode: 'multiple',
		showSearch: true,
		allowClear: true
	}
];
