import i18n from 'i18n';

import { dateFormat } from './helpers';

export const periods = [
	{
		name: i18n.t('prices:always'),
		value: 0
	},
	{
		name: '24h',
		value: 24
	},
	{
		name: '48h',
		value: 48
	},
	{
		name: '72h',
		value: 72
	},
	{
		name: i18n.t('prices:recurring_payment'),
		value: -1
	}
];

export const BASE_FIELDS = [
	{
		name: 'template',
		type: 'select',
		label: i18n.t('payment_templates:fields.template'),
		showSearch: true,
		required: true
	},
	{
		name: 'period',
		type: 'select',
		label: i18n.t('payment_templates:fields.period'),
		required: true
	},
	{
		name: 'range',
		type: 'range',
		placeholder: [
			i18n.t('availability:fields.since'),
			i18n.t('availability:fields.till')
		],
		label: i18n.t('availability:fields.range'),
		required: true,
		showTime: true,
		rangeFormat: dateFormat
	}
];
