const CANCELLED = 'CANCELLED';

const getProgressProperties = ({ progress, status }) => {
	let progressValue;
	let color;
	let label;

	switch (status) {
		case CANCELLED:
			label = CANCELLED;
			color = 'danger';
			progress = 100;
			break;

		default:
			label = `${progress} %`;
			color = 'success';
			progressValue = progress;
			break;
	}

	return { color, progressValue, label };
};

export default getProgressProperties;
