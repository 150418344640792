// Import translation
import i18n from 'i18next';

export const productsTypes = [
	{
		title: i18n.t('common:products_tabs.provisioning'),
		apiUrl: 'packet/list?type=provision&',
		type: 'provisioning',
		api: 'packet',
		submitApiSlug: 'addDelProducts',
		idSelector: 'uuid',
		titleSelector: 'title'
	},
	{
		title: i18n.t('common:products_tabs.collections'),
		apiUrl: 'packet/list?type=collection&',
		type: 'collections',
		api: 'packet',
		submitApiSlug: 'addDelProducts',
		idSelector: 'uuid',
		titleSelector: 'title'
	},
	{
		title: i18n.t('common:products_tabs.main'),
		apiUrl: 'packet/list?type=main&',
		type: 'main',
		api: 'packet',
		submitApiSlug: 'addDelProducts',
		idSelector: 'uuid',
		titleSelector: 'title'
	},
	{
		title: i18n.t('common:products_tabs.sections'),
		apiUrl: '/section/list?',
		type: 'sections',
		api: 'section',
		submitApiSlug: 'addDelProducts',
		idSelector: 'id',
		titleSelector: 'name'
	},
	{
		title: i18n.t('common:products_tabs.promotions'),
		apiUrl: 'promotions/list?',
		type: 'promotions',
		api: 'promotions',
		submitApiSlug: 'addDelProducts',
		idSelector: 'id',
		titleSelector: 'name'
	}
];
