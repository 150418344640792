// Import select options
import { select_payment, select_period } from './select_field_values';

// Import variables
import { PAYMENT_TYPES } from 'components/helpers/variables';

const { SUBSCRIPTION } = PAYMENT_TYPES;

export const filterOptions = ({ isPacketMain }) => {
	const payments = select_payment.map((item) => ({
		name: item.toUpperCase(),
		value: item
	}));
	if (!isPacketMain) {
		const paymentOptions = payments.filter((option) => option !== SUBSCRIPTION);

		const periodOptions = select_period.filter(({ value }) => value !== '-1');

		return { paymentOptions, periodOptions };
	}

	return { paymentOptions: payments, periodOptions: select_period };
};
