import { combineReducers } from 'redux';
// Import reducers
import table from './subscribers_panel_reducer';
import form from './subscribers_form_reducer';
import devices from './subscriber_devices_reducer';
import packets from './subscribers_packets_reducer';
import coupons from './subscriber_coupons_reducer';
import devicesSn from './subscribers_devices_sn_reducer';
import invoice from './subscribers_invoice_reducer';
import consents from './subscribers_consents_reducer';
import verification from './subscriber_verify_reducer';
import address from './subscribers_address_reducer';

export default combineReducers({
	table,
	form,
	devices,
	devicesSn,
	packets,
	coupons,
	invoice,
	consents,
	verification,
	address
});
