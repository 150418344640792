import React from 'react';
import { Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { Label, FormGroup, Col, Row } from 'reactstrap';

// Import context
import { useMassChangesContext } from 'components/utilities/mass_changes/MassChangesProvider';

// Import helpers
import { renderAdditionalLabelData } from 'components/utilities/products_select/helpers';

const { Option } = Select;

const SelectProductType = () => {
	const { t } = useTranslation();

	const {
		selectedType,
		productsTypes,
		idSelector,
		titleSelector,
		labelSelector,
		fetching,
		handleSelectChange,
		data,
		selectedItem,
		fetchSelectAction,
		handleProductTypeChange,
		typeSelectLabel,
		productSelectLabel
	} = useMassChangesContext();

	const currentTab = productsTypes.find(({ type }) => type === selectedType);
	const title = currentTab?.title || selectedType.toUpperCase();

	const isLoading = () =>
		fetching ? (
			<div className="text-center">
				<Spin size="small" />
			</div>
		) : null;

	// renders fetched data as select options in combobox
	// sections must have label displayed before their name
	// vod must have additional data displayed after their title
	const renderSelectData = (item) => {
		switch (selectedType) {
			case 'sections':
				return `(${item[labelSelector]}) ${item[titleSelector]}`;

			case 'vod':
				const nameTags = ['subtype', 'provider', 'payment_models'];
				return `${item[titleSelector]} (${renderAdditionalLabelData(
					nameTags,
					item
				)}) `;

			default:
				return item[titleSelector];
		}
	};

	return (
		<div className="m-t-md">
			<FormGroup>
				<Row>
					<Col xs="2">
						<Label className="col-form-label col-form-label-lg">
							{typeSelectLabel}
						</Label>
					</Col>
					<Col xs="9">
						<Select
							style={{ width: '50%' }}
							placeholder={selectedType}
							onChange={handleProductTypeChange}
							value={selectedType}
						>
							{productsTypes.map(({ type, title }, index) => (
								<Option key={index} value={type}>
									{title}
								</Option>
							))}
						</Select>
					</Col>
				</Row>
			</FormGroup>
			<FormGroup>
				<Row>
					<Col xs="2">
						<Label className="col-form-label col-form-label-lg">
							{productSelectLabel}{' '}
							{t(`mass_changes:products_types.${selectedType}`)}
						</Label>
					</Col>
					<Col xs="9">
						<Select
							style={{ width: '50%' }}
							showSearch
							labelInValue
							value={{ key: selectedItem }}
							placeholder={title}
							notFoundContent={isLoading()}
							filterOption={false}
							onSearch={fetchSelectAction}
							onChange={handleSelectChange}
						>
							{data.map((item) => (
								<Option key={item[idSelector]} value={item[idSelector]}>
									{renderSelectData(item)}
								</Option>
							))}
						</Select>
					</Col>
				</Row>
			</FormGroup>
		</div>
	);
};

export default SelectProductType;
