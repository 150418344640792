import React, { Component } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash.debounce';
import services from 'services/services';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { forceFetchProvisioningProductsTable } from 'store/actions';

// Import helpers
import { PRODUCT_SELECT_VOD_LIST_LENGTH } from 'components/helpers/variables';
import { dataToSelect, dataToSelected, handleErrors } from './helpers/index';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

const ProvisioningProductsContext = React.createContext();
const ProductsConsumer = ProvisioningProductsContext.Consumer;

class ProvisioningProductsProvider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: {
				openModalText: '',
				tabs: [],
				selectedType: '',
				apiUrl: ''
			},
			select: {
				value: [],
				data: [],
				fetching: false,
				selected: {},
				error: false
			},
			buttonSubmitText: '',
			title: ''
		};

		this.idSelector = 'uuid';
		this.titleSelector = 'title';
		this.labelSelector = 'label';
		this.lastFetchId = 0;
		this.fetchSelectAction = debounce(this.fetchSelectAction, 800);
	}

	/* --- Tabs --- */
	setDefaultTab = () => {
		const { tabs } = this.props;

		this.setState((state) => ({
			modal: {
				...state.modal,
				selectedType: tabs[0].type,
				apiUrl: tabs[0].apiUrl
			}
		}));
	};

	handleTabChange = ({ target: { value: type } }) => {
		const {
			modal: { tabs },
			select: { selected }
		} = this.state;
		const { apiUrl } = tabs.find((item) => item.type === type);
		// Add data to select according to current tab
		const selectedTabData = dataToSelect(
			selected,
			type,
			this.idSelector,
			this.titleSelector
		);

		this.setState((state) => ({
			modal: {
				...state.modal,
				selectedType: type,
				apiUrl
			},
			select: {
				...state.select,
				value: selectedTabData,
				data: [],
				fetching: false,
				error: false
			}
		}));
	};
	/* --- Tabs end --- */

	/* --- Select --- */
	fetchSelectAction = async (value) => {
		try {
			if (value.length >= 2) {
				const {
					modal: { apiUrl, selectedType }
				} = this.state;

				this.lastFetchId += 1;
				const fetchId = this.lastFetchId;

				this.setState(({ select }) => ({
					select: { ...select, data: [], fetching: true, error: false }
				}));

				// choose proper url based on selectedType -> change this variable to switch when more options are needed
				const url =
					selectedType === 'vod'
						? `${apiUrl}order[0][column]=4&order[0][dir]=asc&start=0&length=${PRODUCT_SELECT_VOD_LIST_LENGTH}&columns[4][search][value]=${value}`
						: `${apiUrl}order[0][column]=1&order[0][dir]=asc&start=0&length=30&search[value]=${value}`;

				// Get data from server and add them to select list
				let {
					data: { data }
				} = await services.get(url);

				if (fetchId !== this.lastFetchId) return;

				// Add uuid, type, title to provider items
				if (selectedType === 'provider') {
					data.forEach((item) => {
						item.title = item.provider;
						item.uuid = item.provider_id;
						item.type = 'provider';
					});
				}

				// Remove not active products
				if (selectedType === 'vod') {
					data = data.filter(({ active }) => parseInt(active, 10) !== 0);
				}

				this.setState(({ select }) => ({
					select: { ...select, data, fetching: false }
				}));
			}
		} catch (error) {
			this.setState(({ select }) => ({
				select: { ...select, data: [], fetching: false, error: true }
			}));
		}
	};

	handleSelectChange = (value) => {
		const {
			modal: { selectedType },
			select: { data, selected }
		} = this.state;

		let newSelected = [];

		if (selected[selectedType]) {
			newSelected = dataToSelected(
				value,
				[...selected[selectedType], ...data],
				this.idSelector
			);
		} else {
			newSelected = dataToSelected(value, data, this.idSelector);
		}

		this.setState(({ select, select: { selected } }) => ({
			select: {
				...select,
				value,
				fetching: false,
				selected: { ...selected, [selectedType]: newSelected }
			}
		}));
	};

	clearSelectState = () =>
		this.setState({
			select: {
				value: [],
				data: [],
				fetching: false,
				selected: {},
				error: false
			}
		});
	/* --- Select End --- */

	/* --- Modal buttons --- */
	handleModalCancel = () => {
		// Remove value from select
		this.clearSelectState();

		// Activate the first tab
		this.setDefaultTab();
	};

	handleModalOk = async () => {
		try {
			const {
				createSubmitData,
				api,
				id,
				apiSlug,
				t,
				notificationName,
				clearPanelState,
				provisioningProducts,
				forceFetchProvisioningProductsTable
			} = this.props;
			const {
				select: { selected }
			} = this.state;

			const requestPayload = createSubmitData(selected);

			await services.post(`${api}/${id}/${apiSlug}`, requestPayload);

			// check if provisioning products table has any data
			const hasAnyData = provisioningProducts?.data?.length > 0;
			// if there is no data, reset table so it can accept and render new data
			// without it table is stuck on loading (clearing empty data [clearPanelState()] does not trigger data re-fetching)
			if (!hasAnyData) {
				forceFetchProvisioningProductsTable();
			}

			clearPanelState();

			notificationHandler(
				t('messages:notifications.edited'),
				`${t('messages:notifications.successfuly_edited')} ${t(
					`names:notification_names.${notificationName || api}`
				)}`
			);
		} catch (error) {
			return handleErrors(error);
		}

		// Remove value from select
		this.clearSelectState();

		// Activate the first tab
		this.setDefaultTab();
	};
	/* --- Modal buttons end --- */

	componentDidMount() {
		const { openModalText, tabs, buttonSubmitText, title } = this.props;
		this.setState({
			modal: {
				openModalText,
				tabs,
				selectedType: tabs[0].type,
				apiUrl: tabs[0].apiUrl
			},
			select: {
				value: [],
				data: [],
				fetching: false,
				selected: {},
				error: false
			},
			buttonSubmitText: buttonSubmitText,
			title: title
		});
	}

	render() {
		return (
			<ProvisioningProductsContext.Provider
				value={{
					...this.state,
					handleTabChange: this.handleTabChange,
					fetchSelectAction: this.fetchSelectAction,
					handleSelectChange: this.handleSelectChange,
					handleModalCancel: this.handleModalCancel,
					handleModalOk: this.handleModalOk,
					idSelector: this.idSelector,
					titleSelector: this.titleSelector,
					labelSelector: this.labelSelector
				}}
			>
				{this.props.children}
			</ProvisioningProductsContext.Provider>
		);
	}
}

const mapStateToProps = ({ provisioning: { products } }) => ({
	provisioningProducts: products
});

export { ProductsConsumer, ProvisioningProductsContext };

export default compose(
	connect(mapStateToProps, { forceFetchProvisioningProductsTable }),
	withRouter,
	withTranslation()
)(ProvisioningProductsProvider);
