import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	logo: {
		data: [],
		isLoaded: false,
		error: false
	},
	logo_inverted: {
		data: [],
		isLoaded: false,
		error: false
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** FETCH CHANNEL'S LOGO FILES ******************
			case types.FETCH_CHANNEL_LOGO_FILES_LOADING:
				draft.logo.data = [];
				draft.logo.isLoaded = false;
				draft.logo.error = false;
				return;

			case types.FETCH_CHANNEL_LOGO_FILES_SUCCESS:
				draft.logo.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.logo.isLoaded = true;
				return;

			case types.FETCH_CHANNEL_LOGO_FILES_ERROR:
				draft.logo.isLoaded = true;
				draft.logo.error = true;
				return;

			// ****************** FETCH CHANNEL'S LOGO_INVERTED FILES ******************
			case types.FETCH_CHANNEL_LOGO_INVERTED_FILES_LOADING:
				draft.logo_inverted.data = [];
				draft.logo_inverted.isLoaded = false;
				draft.logo_inverted.error = false;
				return;

			case types.FETCH_CHANNEL_LOGO_INVERTED_FILES_SUCCESS:
				draft.logo_inverted.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.logo_inverted.isLoaded = true;
				return;

			case types.FETCH_CHANNEL_LOGO_INVERTED_FILES_ERROR:
				draft.logo_inverted.isLoaded = true;
				draft.logo_inverted.error = true;
				return;

			default:
				return state;
		}
	});
