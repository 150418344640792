import i18n from 'i18n';
import { notificationHandler } from 'components/utilities/notifications/index';
import services from 'services/services';
import mime from 'mime-types';

// Import helpers
import {
	SYNC_ASSET_ORDER_API,
	FETCH_UPLOADED_FILES_API,
	CREATE_NEW_ASSET_API
} from 'components/helpers/api';
import { FILE_DATA } from 'components/helpers/variables';

export const updateAssetOrder = (data) => {
	const assets = data.map(({ id }, index) => ({
		id,
		order: index
	}));
	return services.put(SYNC_ASSET_ORDER_API, { assets });
};

export const onAssetUpdateSuccess = (refetch) => async () => {
	notificationHandler(
		i18n.t('documents:notifications.title'),
		i18n.t('documents:notifications.success')
	);
	await refetch();
};

export const onAssetUpdateFailure = () => {
	notificationHandler(
		i18n.t('documents:notifications.title'),
		i18n.t('documents:notifications.error'),
		'error'
	);
};

export const fetchAssets = (id) => () => {
	const url = FETCH_UPLOADED_FILES_API.replace('uuid', id)
		.replace('modelType', FILE_DATA.MODEL_TYPE)
		.replace('collectionName', FILE_DATA.COLLECTION);
	return services.get(url);
};

export const onAssetsFetchSuccess = (updateData) => ({ data }) =>
	updateData(
		data.map((item) => ({
			...item,
			index: item.id
		})) ?? []
	);

export const deleteAsset = async (id) => {
	try {
		await services.delete(`/assets/${id}`);
		notificationHandler(
			i18n.t('messages:notifications.deleted'),
			`${i18n.t('messages:notifications.successfuly_deleted')} ${i18n.t(
				'names:notification_names.asset'
			)} `
		);
	} catch {
		notificationHandler(
			i18n.t('messages:notifications.deleted'),
			i18n.t('common:upload_pictures.remove_error'),
			'error'
		);
	}
};

export const uploadAssetFunction = ({ file, id, system }) => {
	const data = new FormData();
	const fileToSend = file[0];
	const contentType = mime.contentType(fileToSend?.name);
	const formData = {
		pdf_file: fileToSend,
		file: fileToSend,
		collection: FILE_DATA.COLLECTION,
		model_id: id,
		model_type: FILE_DATA.MODEL_TYPE,
		system: system
	};
	if (contentType === 'application/pdf') {
		delete formData.file;
	} else {
		delete formData.pdf_file;
	}

	Object.entries(formData).forEach(([key, value]) => data.append(key, value));
	return services.post(CREATE_NEW_ASSET_API, data, {
		headers: { 'Content-Type': 'multipart/form-data' }
	});
};

export const onFileUploadSuccess = (refetch, clearField) => async () => {
	notificationHandler(
		`${i18n.t('messages:notifications.successfuly_created')} ${i18n.t(
			'names:notification_names.pdf'
		)}`
	);
	clearField([]);
	await refetch();
};

export const onFileUploadFailure = () => {
	notificationHandler(i18n.t('messages:notifications.error'), '', 'error');
	notificationHandler(i18n.t('messages:notifications.error', '', 'error'));
};
