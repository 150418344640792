import { useLocation } from 'react-router-dom';

// Import helpers
import { getLocationType } from 'components/helpers/convert';
import { convertLocationType } from '../tabs/helpers/helpers';
import { SYSTEM_SETTINGS_FIELDS } from 'components/helpers/system_settings_helpers/system_settings_fields';
import {
	checkIsAvailability,
	checkIsPrices,
	checkForEpg,
	checkIsCouponsSystemSettings
} from 'components/helpers/pathname';

// Import utilities
import { submitForm } from 'components/utilities/form';

const useSystemSettingsForm = ({ history, initialValues }) => {
	const { pathname } = useLocation();

	const isEpg = checkForEpg(pathname);
	const isAvailability = checkIsAvailability(pathname);
	const isPrices = checkIsPrices(pathname);
	const isCouponsSystemSettings = checkIsCouponsSystemSettings(pathname);

	const type = convertLocationType(
		getLocationType(pathname),
		isEpg,
		isAvailability,
		isPrices,
		isCouponsSystemSettings
	);

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		// We can't send this value, because system is sending as query parameter
		delete values.system;

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: `${type}/systemFields`,
			withoutSlug: true,
			notificationName: 'system'
		});
	};
	const formInitialValues = {
		...SYSTEM_SETTINGS_FIELDS[type].defaultFieldValues,
		...initialValues
	};

	return {
		type,
		formInitialValues,
		onSubmit: handleOnSubmit
	};
};

export default useSystemSettingsForm;
