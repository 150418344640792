import i18n from 'i18n';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

export const notifySuccess = (mode) => {
	notificationHandler(
		i18n.t(`messages:notifications.${mode}`),
		`${i18n.t(`messages:notifications.successfuly_${mode}`)} ${i18n.t(
			'names:notification_names.payment_template'
		)} `,
		'success',
		5
	);
};

export const notifyError = (mode) => {
	notificationHandler(
		i18n.t('messages:notifications.error'),
		i18n.t(`payment_templates:errors.${mode}_error`),
		'error',
		5
	);
};
