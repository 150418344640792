import i18n from 'i18n';

// Import select options
import { select_packet_types as type } from 'components/helpers/variables';

const DEFAULT_PACKET_TYPE_SELECT = 'provision';

const defaultType = type.find(
	(option) => option.value === DEFAULT_PACKET_TYPE_SELECT
)?.value;

export const defaultValues = {
	type: defaultType
};

export const BASE_INPUTS = ({ isEdit }) => [
	{
		name: 'type',
		type: 'select',
		label: i18n.t('provisioning:fields.type'),
		required: true,
		disabled: true
	},
	{
		name: 'external_ids',
		type: 'multi-select-no-resources',
		label: i18n.t('provisioning:fields.external_ids'),
		required: false,
		mode: 'tags'
	},
	{
		name: 'slug',
		type: 'text',
		label: i18n.t('provisioning:fields.slug'),
		required: false,
		isVisible: !isEdit
	},
	{
		name: 'title',
		type: 'text',
		label: i18n.t('provisioning:fields.title'),
		required: true
	},
	{
		name: 'description',
		type: 'textarea',
		label: i18n.t('provisioning:fields.description'),
		required: true
	},
	{
		name: 'short_description',
		type: 'textarea',
		label: i18n.t('provisioning:fields.short_description'),
		required: true
	},
	{
		name: 'free_vod',
		type: 'checkbox',
		label: i18n.t('provisioning:fields.free_vod'),
		required: false,
		fieldInfo: i18n.t('provisioning:info.free_vod')
	},
	{
		name: 'created_at',
		type: 'text',
		label: i18n.t('provisioning:fields.created_at'),
		required: false,
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'updated_at',
		type: 'text',
		label: i18n.t('provisioning:fields.updated_at'),
		required: false,
		disabled: true,
		isVisible: !isEdit
	}
];
