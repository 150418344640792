import i18n from 'i18n';

const layout = { labelColumn: 6, inputColumn: 6 };

export const initialValues = {
	campaign_id: null,
	targets: null,
	target_type: null,
	target_system: null,
	target_platforms: null
};

export const validate = ({ target_type, target_platforms, target_system }) => {
	let errors = {};

	if (!target_type) {
		errors.target_type = i18n.t(
			'marketing_campaigns:targets.errors.target_type_required'
		);
	}

	if (!target_platforms) {
		errors.target_platforms = i18n.t(
			'marketing_campaigns:targets.errors.target_platforms_required'
		);
	}

	if (!target_system) {
		errors.target_system = i18n.t(
			'marketing_campaigns:targets.errors.target_system_required'
		);
	}
	return errors;
};

export const BASE_FIELDS = [
	{
		name: 'campaign_id',
		label: i18n.t('marketing_campaigns:targets:fields.campaign_id'),
		type: 'number',
		isVisible: false,
		disabled: true,
		visable: false,
		...layout
	},
	{
		name: 'target_type',
		type: 'select',
		label: i18n.t('marketing_campaigns:targets:fields.target_type'),
		required: true,
		...layout
	},
	{
		name: 'target_platforms',
		type: 'select',
		label: i18n.t('marketing_campaigns:targets:fields.target_platforms'),
		required: true,
		...layout
	},
	{
		name: 'target_system',
		type: 'select',
		label: i18n.t('marketing_campaigns:targets:fields.target_system'),
		required: true,
		...layout
	},
	{
		name: 'targets',
		label: i18n.t('marketing_campaigns:targets:fields.csv'),
		type: 'file',
		fileType: '.csv',
		required: true,
		...layout
	}
];
