// Import translation
import i18n from 'i18next';
import moment from 'moment';

export default ({
	label,
	algorithm_id,
	main_limit,
	type,
	active_from_minute,
	active_to_minute
}) => {
	const errors = {};

	const timeFrom = moment(active_from_minute, 'HH:mm');
	const timeTo = moment(active_to_minute, 'HH:mm');

	const isTimeViolated =
		active_to_minute && active_from_minute && !timeTo.isAfter(timeFrom);

	// ------------------ Label ------------------
	if (!label) {
		errors.label = i18n.t('sections:validation.label_required');
	} else if (label.length < 2 || label.length > 200) {
		errors.label = i18n.t('sections:validation.label_length');
	}
	// ------------------ Algorithm_id ------------------
	if (!algorithm_id) {
		errors.algorithm_id = i18n.t('sections:validation.algorithm_id_required');
	}
	// ------------------ Main_limit ------------------
	if (!main_limit) {
		errors.main_limit = i18n.t('sections:validation.main_limit_required');
	} else if (parseInt(main_limit, 10) <= 0) {
		errors.main_limit = i18n.t('sections:validation.main_limit_length');
	}
	// ------------------ Type ------------------
	if (!type) {
		errors.type = i18n.t('sections:validation.type_required');
	} else if (type.length < 2 || type.length > 200) {
		errors.type = i18n.t('sections:validation.type_length');
	}

	// ------------------ Active ------------------
	if (active_from_minute && !active_to_minute) {
		errors.active_to_minute = i18n.t(
			'sections:validation.active_to_minute_required'
		);
	} else if (active_to_minute && !active_from_minute) {
		errors.active_from_minute = i18n.t(
			'sections:validation.active_from_minute_required'
		);
	} else if (isTimeViolated) {
		errors.active_to_minute = i18n.t(
			'sections:validation.active_to_minute_min'
		);
	}
	return errors;
};
