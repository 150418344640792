import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

export const createColumns = () => [
	{
		Header: i18n.t('providers:table_columns.name'),
		accessor: 'name',
		minWidth: 200,
		Cell: ({
			row: {
				_original: { name, uuid }
			}
		}) => <Link to={`/panel/providers/edit/${uuid}/metadata`}>{name}</Link>
	},
	{
		Header: i18n.t('providers:table_columns.immutable_name'),
		accessor: 'immutable_name',
		minWidth: 200
	},
	{
		Header: i18n.t('providers:table_columns.ingesting_path'),
		accessor: 'ingesting_path',
		minWidth: 200
	},
	{
		Header: i18n.t('providers:table_columns.is_active'),
		accessor: 'is_active',
		className: 'text-center',
		width: 75,
		Cell: ({
			row: {
				_original: { is_active }
			}
		}) => <ActiveIcon active={is_active} />
	},
	{
		Header: i18n.t('providers:table_columns.created_at'),
		accessor: 'created_at',
		width: 200
	},
	{
		Header: i18n.t('providers:table_columns.updated_at'),
		accessor: 'updated_at',
		width: 200
	}
];
