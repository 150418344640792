import React, { createContext } from 'react';
import useSubscriberVerification from './useSubscriberVerification';

export const VerificationContext = createContext();

export const VerificationContextProvider = ({ children, documentId }) => {
	const values = useSubscriberVerification({ id: documentId });
	return (
		<VerificationContext.Provider value={values}>
			{children}
		</VerificationContext.Provider>
	);
};
