import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	promotionsQueryParams
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH COUPONS - TABLE DATA ********************
export const fetchCoupons = (options) => async (dispatch, getState) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_COUPONS_LOADING,
			payload: true
		});

		const {
			coupons: {
				table: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		//Show only coupons! => get promotions with is_coupon: true flag
		const couponsQuery = 'columns[12][search][value]=1&';

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `/promotions/list?${couponsQuery}${filterUrlQuery}${promotionsQueryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex
		)}`;

		const { data } = await services.get(url);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_COUPONS_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_COUPONS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE COUPON ********************
export const deleteCoupon = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DELETE_COUPON_LOADING,
			payload: true
		});

		await services.delete(`/promotions/${id}`);
		// Dispatch an action with data
		dispatch({
			type: types.DELETE_COUPON_SUCCESS,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DELETE_COUPON_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET SEARCH QUERY ********************
export const setCouponsQuery = (query) => ({
	type: types.SET_COUPONS_SEARCH_QUERY,
	payload: query
});
