// ******************** SOFTWARE DATA TABLE ********************
export const FETCH_SOFTWARE_LOADING = 'FETCH_SOFTWARE_LOADING';
export const FETCH_SOFTWARE_SUCCESS = 'FETCH_SOFTWARE_SUCCESS';
export const FETCH_SOFTWARE_ERROR = 'FETCH_SOFTWARE_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_SOFTWARE_SEARCH_QUERY = 'SET_SOFTWARE_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_SOFTWARE_TABLE_SORTING = 'SAVE_SOFTWARE_TABLE_SORTING';

// ******************** DELETE SOFTWARE ********************
export const DELETE_SOFTWARE_LOADING = 'DELETE_SOFTWARE_LOADING';
export const DELETE_SOFTWARE_SUCCESS = 'DELETE_SOFTWARE_SUCCESS';
export const DELETE_SOFTWARE_ERROR = 'DELETE_SOFTWARE_ERROR';

// ******************** SOFTWARE ********************
export const FETCH_SOFTWARE_DETAIL_LOADING = 'FETCH_SOFTWARE_DETAIL_LOADING';
export const FETCH_SOFTWARE_DETAIL_SUCCESS = 'FETCH_SOFTWARE_DETAIL_SUCCESS';
export const FETCH_SOFTWARE_DETAIL_ERROR = 'FETCH_SOFTWARE_DETAIL_ERROR';
