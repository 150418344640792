import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { IboxContentTitle } from 'components/common/layout';
import { Table } from 'antd';

// Import columns
import { columns } from './columns/topology_files_info_columns';

// Import variables
import { TOPOLOGY_FILES } from 'components/helpers/variables';

const TopologyFilesInfo = ({ type, filesData }) => {
	const { t } = useTranslation();

	switch (type) {
		case TOPOLOGY_FILES.TOPOLOGY:
			return (
				<IboxContentTitle title={t(`topology:tables.topology`)}>
					{filesData && (
						<Table
							dataSource={[filesData]}
							columns={columns}
							pagination={false}
							scroll={{ x: 1300 }}
							expandIconAsCell={false}
							expandIconColumnIndex={-1}
						/>
					)}
				</IboxContentTitle>
			);
		case TOPOLOGY_FILES.PORTS:
			return (
				<IboxContentTitle title={t(`topology:tables.ports`)}>
					{filesData && (
						<Table
							dataSource={filesData}
							columns={columns}
							pagination={false}
							scroll={{ x: 1300 }}
							expandIconAsCell={false}
							expandIconColumnIndex={-1}
						/>
					)}
				</IboxContentTitle>
			);
		default:
			return null;
	}
};

TopologyFilesInfo.propTypes = {
	type: PropTypes.string.isRequired,
	filesData: PropTypes.object.isRequired
};

export default TopologyFilesInfo;
