import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetTableFilters, setFiltersDisable } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { Row, Col, Input, Form, Button, FormGroup } from 'reactstrap';

const Search = ({ setQueryValue, value }) => {
	const { t } = useTranslation();
	const [input, setInputValue] = useState(value);
	const dispatch = useDispatch();
	const { pathname, state } = useLocation();
	const {
		previous_location: { pathname: previousLocation },
		filters: { isFiltersDisable }
	} = useSelector((state) => state);

	useEffect(() => {
		// Reset table filters (if the flag doesn't state otherwise)
		!isFiltersDisable &&
			resetTableFilters({
				previousLocation,
				currentLocation: pathname,
				linkState: state
			})(dispatch);
		// reset filters disable on component unmount
		return () => setFiltersDisable(false)(dispatch);
		// eslint-disable-next-line
	}, [previousLocation]);

	// Update state with the new query
	const handleOnChange = ({ target: { value } }) => setInputValue(value);

	// Clear query
	const handleClearQuery = () => {
		// Clear state
		setInputValue('');
		// Dispatch an action to store
		setQueryValue('');
	};

	// Handle submit query
	const handleOnSubmit = (e) => {
		// Prevent default actions
		e.preventDefault();
		// Dispatch an action to store
		setQueryValue(input);
	};

	return (
		<div className="search m-b-xl">
			<Row>
				<Col sm="12" className="d-flex justify-content-end align-items-center">
					<Form inline onSubmit={handleOnSubmit}>
						<FormGroup className="mb-0">
							<Input
								data-testid="input-search"
								name="query"
								placeholder={t('common:search_box.placeholder')}
								value={input}
								className="search__input"
								onChange={handleOnChange}
							/>
							{input && (
								<div className="search__clear">
									<Button onClick={handleClearQuery} className="btn__clear">
										<i className="fa fa-times" />
									</Button>
								</div>
							)}
						</FormGroup>
						<Button className="m-l-sm">{t('common:search_box.button')}</Button>
					</Form>
				</Col>
			</Row>
		</div>
	);
};

Search.defaultProps = {
	value: ''
};

Search.propTypes = {
	setQueryValue: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired
};

export default Search;
