import React from 'react';
import PropTypes from 'prop-types';

import { Info } from 'components/utilities';

function InfoWrapper({ info, content, modalWidth, children }) {
	if (!info) return <>{children}</>;
	return (
		<Info content={content} modalWidth={modalWidth}>
			{children}
		</Info>
	);
}

InfoWrapper.defaultProps = {
	info: false,
	content: <div></div>,
	modalWidth: 520
};

InfoWrapper.propTypes = {
	info: PropTypes.bool.isRequired,
	content: PropTypes.node.isRequired,
	modalWidth: PropTypes.number
};

export default InfoWrapper;
