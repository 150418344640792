import React, { useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import services from 'services/services';
import { Select, Alert, Spin } from 'antd';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { addQueryParamMark } from 'services/helpers/helpers';

// Import components
import { FieldError } from 'components/common/inputs';

const { Option } = Select;

const FetchSelectLabel = ({
	placeholder,
	url,
	selectValue,
	selectName,
	input,
	disabled,
	meta: { touched, error, submitError }
}) => {
	const { t } = useTranslation();

	// component state
	const [dataList, setDataList] = useState([]);
	const [isFetching, setIsFetching] = useState(false);
	const [isError, setIsError] = useState(false);

	// variable checks if values from proper requests are shown
	// important when there's slower internet connection
	let lastFetchId = 0;

	// fetching data to populate select combobox
	const fetchData = debounce(async (value) => {
		try {
			// function works after two letters are passed
			if (value.length >= 2) {
				lastFetchId += 1;
				const fetchId = lastFetchId;

				setDataList([]);
				setIsFetching(true);

				// Get data from server and add them to select list
				const {
					data: { data }
				} = await services.get(
					`${url}${addQueryParamMark(url)}search[value]=${value}`
				);

				const convertedData = data
					? data.map((item) => ({
							name: item[selectName],
							value: item[selectValue]
					  }))
					: [];

				setDataList(convertedData);
				setIsFetching(false);

				if (fetchId !== lastFetchId) return;
			} else {
				setDataList([]);
				setIsFetching(false);
			}
		} catch (error) {
			setDataList([]);
			setIsFetching(false);
			setIsError(true);
		}
	}, 800);

	const showSpinner = () =>
		isFetching ? (
			<div className="text-center">
				<Spin size="small" />
			</div>
		) : null;

	const showError = () => (
		<Alert
			showIcon
			type="error"
			description={t('common:errors.data_retrieve')}
			className="m-b-md animated fadeIn"
		/>
	);

	return (
		<React.Fragment>
			{isError && showError()}
			<Select
				{...input}
				showSearch
				allowClear
				value={input.value || []}
				placeholder={placeholder}
				notFoundContent={showSpinner()}
				filterOption={false}
				onSearch={fetchData}
				style={{ width: '100%' }}
				disabled={disabled}
			>
				{dataList.map(({ name, value }) => (
					<Option key={value} value={value}>
						{name}
					</Option>
				))}
			</Select>
			<FieldError
				error={touched && (error || submitError)}
				inputName={input.name}
				content={error || submitError}
			/>
		</React.Fragment>
	);
};

FetchSelectLabel.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.array.isRequired
	]),
	placeholder: PropTypes.string,
	selectValue: PropTypes.string,
	selectName: PropTypes.string,
	url: PropTypes.string.isRequired,
	disabled: PropTypes.bool
};

export default FetchSelectLabel;
