import axios from 'axios';
import Cookies from 'js-cookie';
import store from 'store/store';
import { signOut } from 'store/actions';

// Import variables
import {
	COOKIE_USER_TOKEN,
	COOKIE_USER_SYSTEM
} from 'components/helpers/variables';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	}
});

instance.interceptors.request.use(
	async function(config) {
		const { auth } = store.getState();
		const { user } = auth.authenticate;
		const token = Cookies.get(COOKIE_USER_TOKEN);
		config.params = config.params || {};

		const searchParams = new URLSearchParams(config.url.split('?')[1]);

		config.params.op = user?.id;

		if (!searchParams.get('system')) {
			config.params.system =
				config.params.system ?? Cookies.get(COOKIE_USER_SYSTEM) ?? user?.system;
		}

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},
	function(error) {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	function(response) {
		return response;
	},
	function(error) {
		if (error?.response?.status === 401) {
			// Dispatch an signOut action in auth folder
			store.dispatch(signOut(false));
			return Promise.reject(error);
		} else {
			return Promise.reject(error);
		}
	}
);

export default instance;
