import { combineReducers } from 'redux';
import * as types from 'store/actions/types';

// Import reducers
import auth from './auth/auth_reducer';
import channels from './channels/channels_reducer';
import local_channels from './local_channels/local_channels_reducer';
import subscribers from './subscribers/subscribers_reducer';
import operators from './operators/operators_reducer';
import settings from './settings/settings_reducer';
import documents from './documents/documents_reducer';
import agreements from './agreements/agreements_reducer';
import categories from './categories/categories_reducer';
import epg from './epg/epg_reducer';
import vod from './vod/vod_reducer';
import provisioning from './provisioning/provisioning_reducer';
import packets from './packets/packets_reducer';
import main from './main/main_reducer';
import sections_groups from './sections_groups/sections_groups_reducer';
import sections from './sections/sections_reducer';
import geolocks from './geolocks/geolocks_reducer';
import banners from './banners/banners_reducer';
import limits from './limits/limits_reducer';
import devices from './devices/devices_reducer';
import devices_sn from './devices_sn/devices_sn_reducer';
import trackings from './trackings/trackings_reducer';
import previous_location from './location/location_reducer';
import ingester from './ingester/ingester_reducer';
import payments from './payments/payments_reducer';
import filters from './filters/filters_reducer';
import providers from './providers/providers_reducer';
import dictionary from './dictionary/dictionary_reducer';
import request_url from './request_url/request_url_reducer';
import csv from './csv/csv_reducer';
import software from './software/software_reducer';
import promotions from './promotions/promotions_reducer';
import cognito from './cognito/cognito_reducer';
import activity from './activity/activity_reducer';
import cognito_providers from './cognito_providers/cognito_providers_reducer';
import barker_channels from './barker_channels/barker_channels_reducer';
import coupons from './coupons/coupons_reducer';
import epg_global from './epg_global/epg_global_reducer';
import systems from './systems/systems_reducer';
import system_settings from './system_settings/system_settings_reducer';
import payment_templates from './payment_templates/payment_templates';
import marketing_campaigns from './marketing_campaigns/marketing_campaigns_reducer';
import tags from './tags/tags_reducer';
import affiliate_partners from './affiliate_partners/affiliate_partners_reducer';

// Import queries
import ip from 'store/query/ip';

const appReducer = combineReducers({
	previous_location,
	request_url,
	auth,
	channels,
	local_channels,
	subscribers,
	categories,
	documents,
	agreements,
	operators,
	settings,
	epg,
	vod,
	provisioning,
	packets,
	main,
	sections_groups,
	sections,
	geolocks,
	banners,
	limits,
	devices,
	devices_sn,
	trackings,
	ingester,
	payments,
	filters,
	providers,
	dictionary,
	csv,
	software,
	promotions,
	cognito,
	activity,
	cognito_providers,
	barker_channels,
	coupons,
	epg_global,
	systems,
	system_settings,
	payment_templates,
	tags,
	[ip.reducerPath]: ip.reducer,
	marketing_campaigns,
	affiliate_partners
});

const rootReducer = (state, action) => {
	if (action.type === types.SIGN_OUT_SUCCESS) {
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};

export default rootReducer;
