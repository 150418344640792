// ******************** SET TABLE COLUMNS ********************
export const SET_TABLE_COLUMNS = 'SET_TABLE_COLUMNS';

// ******************** SET FILTER URL QUERY ********************
export const SET_FILTER_URL_QUERY = 'SET_FILTER_URL_QUERY';

// ******************** SET FILTER FORM VALUES ********************
export const SET_FILTER_FORM_VALUES = 'SET_FILTER_FORM_VALUES';

// ******************** RESET FILTER ********************
export const FILTER_RESET = 'FILTER_RESET';

// ******************** CLEAR FILTERS ********************
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

// ******************** CREATE FILTERS SUGGESTIONS ********************
export const CREATE_FILTERS_SUGGESTIONS = 'CREATE_FILTERS_SUGGESTIONS';

// ******************** SET FILTERS DISABLE ********************
export const SET_FILTERS_DISABLE = 'SET_FILTERS_DISABLE';
