import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

// Import components
import StatNumber from 'components/views/ingester/helpers/stats_info/StatNumber';

// Import variables
import { STATS_TYPES } from './helpers';

/**
 * This function component is responsible for rendering ingester stats,
 * uses hooks to dispatch and get data from redux store.
 * @param {func} refreshData - function passed to RefreshButton that refreshes table data in Redux
 * @param {string} statsType - type of stats (assets | encoding)
 * @returns {*}
 */
const StatsInfo = ({ statsType, refreshData }) => {
	const { t } = useTranslation();

	const stats = useSelector(({ ingester }) => ingester.stats[statsType]);

	return (
		<Row className="m-b-md">
			{STATS_TYPES.map((type) => (
				<Col key={type} className="d-flex align-items-center">
					<StatNumber
						number={stats[type]}
						color="danger"
						label={t(`ingester:stats.${type}`)}
						statType={type}
					/>
				</Col>
			))}

			<Col className="text-right">
				<Button onClick={refreshData}>
					<i className="fa fa-refresh m-r-sm" />
					{t('common:buttons.refresh')}
				</Button>
			</Col>
		</Row>
	);
};

StatsInfo.propTypes = {
	refreshData: PropTypes.func.isRequired,
	statsType: PropTypes.string.isRequired
};

export default StatsInfo;
