import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import actions
import { fetchSubscriber } from 'store/actions';
import { fetchSubscriberAddress } from 'store/actions/subscribers/subscriber_address';

// Import components
import ReactTable from 'react-table';
import IboxContentTitle from 'components/common/layout/IboxContentTitle';

// Import helpers
import {
	addressColumns,
	createPersonalDataColumns
} from 'components/views/subscribers/subscribers_personal_data/subscribers_personal_data_columns';

const SubscribersPersonalData = ({
	address,
	personalData,
	match: {
		params: { id: userId }
	},
	fetchSubscriberAddress,
	fetchSubscriber,
	isLoaded
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		fetchSubscriber(userId);
		fetchSubscriberAddress(userId);
	}, [fetchSubscriber, fetchSubscriberAddress, userId]);

	return (
		<>
			<IboxContentTitle title={t('subscribers:personalData.title')}>
				<ReactTable
					data={[personalData]}
					columns={createPersonalDataColumns(personalData?.has_pesel_number)}
					pageSize={1}
					showPagination={false}
					loading={!isLoaded}
					sortable={false}
				/>
			</IboxContentTitle>

			<IboxContentTitle title={t('subscribers:address.title')}>
				<ReactTable
					data={[address]}
					columns={addressColumns}
					pageSize={1}
					showPagination={false}
					loading={!isLoaded}
					sortable={false}
				/>
			</IboxContentTitle>
		</>
	);
};

SubscribersPersonalData.propTypes = {
	address: PropTypes.object,
	personalData: PropTypes.shape({
		firstname: PropTypes.string,
		lastname: PropTypes.string,
		pesel_number: PropTypes.string,
		id_document_type: PropTypes.string,
		id_document: PropTypes.string,
		has_pesel_number: PropTypes.bool
	}),
	isLoaded: PropTypes.bool,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	}),
	fetchSubscriber: PropTypes.func,
	fetchSubscriberAddress: PropTypes.func
};

const mapStateToProps = ({
	subscribers: {
		address,
		form: {
			edit: {
				firstname,
				lastname,
				pesel_number,
				id_document_type,
				id_document,
				has_pesel_number
			},
			isLoaded: personalDataLoaded
		}
	}
}) => ({
	address: address.data,
	personalData: {
		firstname,
		lastname,
		pesel_number,
		id_document_type,
		id_document,
		has_pesel_number
	},
	isLoaded: personalDataLoaded && address.isLoaded
});

export default compose(
	connect(mapStateToProps, {
		fetchSubscriber,
		fetchSubscriberAddress
	}),
	withRouter
)(SubscribersPersonalData);
