import React from 'react';

// Import components
import PortsUpload from './topology_ports_upload/PortsUpload';
import TopologyUpload from './topology_upload/TopologyUpload';
import { Wraper } from 'components/common/layout';
import TopologyFilesInfo from './topology_files_info/TopologyFilesInfo';

// Import hooks
import useTopology from './useTopology';

// Import variables
import { TOPOLOGY_FILES } from 'components/helpers/variables';

const Topology = () => {
	const { topologyFilesData, portsFilesData, fetchFilesData } = useTopology();

	return (
		<Wraper error={false}>
			<TopologyFilesInfo
				type={TOPOLOGY_FILES.TOPOLOGY}
				filesData={topologyFilesData}
			/>
			<TopologyFilesInfo
				type={TOPOLOGY_FILES.PORTS}
				filesData={portsFilesData}
			/>
			<TopologyUpload onSuccessUpload={fetchFilesData} />
			<PortsUpload onSuccessUpload={fetchFilesData} />
		</Wraper>
	);
};

export default Topology;
