// ************** AGREEMENTS VERSIONS DATA TABLE ****************
export const FETCH_AGREEMENTS_VERSIONS_LOADING =
	'FETCH_AGREEMENTS_VERSIONS_LOADING';
export const FETCH_AGREEMENTS_VERSIONS_SUCCESS =
	'FETCH_AGREEMENTS_VERSIONS_SUCCESS';
export const FETCH_AGREEMENTS_VERSIONS_ERROR =
	'FETCH_AGREEMENTS_VERSIONS_ERROR';

// ************** DELETE AGREEMENTS VERSION ****************
export const DELETE_AGREEMENTS_VERSION_LOADING =
	'DELETE_AGREEMENTS_VERSION_LOADING';
export const DELETE_AGREEMENTS_VERSION_SUCCESS =
	'DELETE_AGREEMENTS_VERSION_SUCCESS';
export const DELETE_AGREEMENTS_VERSION_ERROR =
	'DELETE_AGREEMENTS_VERSION_ERROR';

// ************** ACTIVATE AGREEMENTS VERSION ****************
export const ACTIVATE_AGREEMENTS_VERSION_LOADING =
	'ACTIVATE_AGREEMENTS_VERSION_LOADING';
export const ACTIVATE_AGREEMENTS_VERSION_SUCCESS =
	'ACTIVATE_AGREEMENTS_VERSION_SUCCESS';
export const ACTIVATE_AGREEMENTS_VERSION_ERROR =
	'ACTIVATE_AGREEMENTS_VERSION_ERROR';

// ******************** AGREEMENT DATA FORM ********************
export const FETCH_AGREEMENTS_VERSION_LOADING =
	'FETCH_AGREEMENTS_VERSION_LOADING';
export const FETCH_AGREEMENTS_VERSION_SUCCESS =
	'FETCH_AGREEMENTS_VERSION_SUCCESS';
export const FETCH_AGREEMENTS_VERSION_ERROR = 'FETCH_AGREEMENTS_VERSION_ERROR';
