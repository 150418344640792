import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FileAddTwoTone, SyncOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

// Import components
import ImportTargetsForm from './ImportTargetsForm';

// Import hooks
import { useToggle } from 'hooks';

const ImportTargets = ({ refetchData }) => {
	const { t } = useTranslation();
	const [opened, toggle] = useToggle();
	const isLoading = useSelector(
		({ marketing_campaigns }) => marketing_campaigns.targets.loading
	);

	return (
		<div style={{ columnGap: '1rem', display: 'flex' }}>
			<Button
				type="dashed"
				icon={<SyncOutlined spin={isLoading} />}
				title={t('marketing_campaigns:targets.buttons.refresh')}
				disabled={isLoading || !refetchData}
				onClick={refetchData}
			/>
			<Button
				icon={
					<FileAddTwoTone
						twoToneColor="#13ce66"
						style={{ fontSize: '1.35em' }}
					/>
				}
				type="dashed"
				disabled={isLoading}
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
				title={t('marketing_campaigns:targets.buttons.import_csv')}
				onClick={toggle}
			>
				{t('marketing_campaigns:targets.buttons.import_csv')}
			</Button>
			{opened && (
				<Modal
					title={t('marketing_campaigns:targets.buttons.import_csv')}
					open={opened}
					footer={null}
					onCancel={toggle}
					width={800}
					destroyOnClose
					maskClosable={false}
				>
					<ImportTargetsForm toggle={toggle} refetchData={refetchData} />
				</Modal>
			)}
		</div>
	);
};

ImportTargets.propTypes = {
	refetchData: PropTypes.func
};

export default ImportTargets;
