import { useQuery } from 'react-query';
import services from 'services/services';

// Import api
import {
	GET_STATISTICS_PRODUCTISATION_API,
	QUERY_STATISTICS_PRODUCTISATION_DATA
} from 'components/helpers/api';

export default function useProductisationStatistics() {
	const { data } = useQuery({
		queryKey: QUERY_STATISTICS_PRODUCTISATION_DATA,
		queryFn: fetchProductisationList
	});

	function fetchProductisationList() {
		return services.get(GET_STATISTICS_PRODUCTISATION_API);
	}

	const productisationStatisticsData = data?.data
		? Object.entries(data.data)
		: [];

	return { productisationStatisticsData };
}
