import React from 'react';
import { number } from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import components
import { IboxTools } from 'components/common/layout';

// Import helpers
import { convertTypeToString } from 'components/helpers/genres_helpers/helpers';

const CategoriesAddButton = ({ type, title = '' }) => {
	const { t } = useTranslation('categories');

	return (
		<div className="custom-ibox-title">
			<h5>{title}</h5>
			<IboxTools>
				<Link
					data-testid={`create-categories-link`}
					className={`btn__link`}
					to={`/panel/categories/${convertTypeToString(type)}/create/metadata`}
				>
					{t('buttons.add')}
				</Link>
			</IboxTools>
		</div>
	);
};

CategoriesAddButton.propTypes = {
	type: number.isRequired
};

export default CategoriesAddButton;
