import React from 'react';
import { string, number } from 'prop-types';

// Import components
import { Spinner } from 'reactstrap';

// Import variables
import { FAILED, WAITING } from './helpers';

function StatIcon({ statType, number, color }) {
	const isFailed = statType === FAILED;

	if (isFailed && number !== 0) {
		return <i className="fa fa-times m-r-sm stat-icon stat-icon--error" />;
	} else if (number === 0) {
		return <i className="fa fa-check m-r-sm stat-icon stat-icon--success" />;
	} else if (statType === WAITING) {
		return <i className="fa fa-pause m-r-sm stat-icon" />;
	} else {
		return <Spinner color={color} />;
	}
}

StatIcon.propTypes = {
	statType: string,
	number: number,
	color: string
};

export default StatIcon;
