import i18n from 'i18next';

export const PACKET_SYSTEM_SETTINGS_FIELDS = {
	defaultFieldValues: {
		active: false,
		visible_primary: false,
		visible_secondary: false
	},
	fields: [
		{
			name: 'active',
			label: i18n.t('common:form.active'),
			type: 'checkbox'
		}
	],
	visibleFields: [
		{
			name: 'visible_primary',
			label: i18n.t('common:form.visible_primary'),
			type: 'checkbox'
		},
		{
			name: 'visible_secondary',
			label: i18n.t('common:form.visible_secondary'),
			type: 'checkbox'
		}
	]
};
