import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { debounce } from 'lodash';

// Import translation
import { withTranslation } from 'react-i18next';

// Import select table
import checkboxHOC from 'react-table/lib/hoc/selectTable';

// Import global helpers
import {
	createTableOptions,
	prepareDefaultSortedData,
	TABLE_LENGTH
} from 'components/helpers/table';

// Import table helpers
import {
	toggleCurrentSelection,
	toggleAllItems
} from '../react_table_checkbox_hoc/chackbox_table_helpers/index';

// Import utilities
import CustomPagination from 'components/utilities/table/pagination/CustomPagination';
import { notificationHandler } from 'components/utilities/notifications';

// Import component
import ControlButtons from './ControlButtons';

// Import variables
import {
	VOD_STATUS_VALUES,
	VOD_SUBTYPES_VALUES
} from 'components/helpers/variables';

// Define checkbox table
const CheckboxTable = checkboxHOC(ReactTable);

class ReactMassChangesTable extends React.PureComponent {
	static propTypes = {
		fetchResourcesAction: PropTypes.func.isRequired,
		resources: PropTypes.shape({
			data: PropTypes.array,
			options: PropTypes.shape({
				pages: PropTypes.number,
				filters: PropTypes.object
			}),
			loading: PropTypes.bool
		}).isRequired,
		notificationUpdateSuccessTxt: PropTypes.string,
		notificationUpdateErrorTxt: PropTypes.string,
		notificationDeleteSuccessTxt: PropTypes.string,
		notificationDeleteErrorTxt: PropTypes.string,
		itemId: PropTypes.number,
		defaultSorted: PropTypes.array,
		isReadOnly: PropTypes.bool.isRequired,
		tableType: PropTypes.string
	};

	static defaultProps = {
		selectItemColumns: [],
		defaultSorted: [],
		notificationUpdateSuccessTxt: '',
		notificationUpdateErrorTxt: '',
		notificationDeleteSuccessTxt: '',
		notificationDeleteErrorTxt: '',
		itemId: null,
		tableType: ''
	};

	state = {
		selection: [],
		selectAll: false,
		defSorted: prepareDefaultSortedData(
			this.props.defaultSorted,
			this.props.resources?.options?.sorted
		),
		pageSize: TABLE_LENGTH,
		disabledReload: false,
		isTableHasFirstReloading: false
	};

	// Fetch table data
	handleOnFetchData = (state, _, isRestricted = true) => {
		const {
			query,
			filterUrlQuery,
			status,
			subtype
		} = this.props.resources.options.filters;

		const isInitialRender =
			status === VOD_STATUS_VALUES.ACTIVE &&
			subtype === VOD_SUBTYPES_VALUES.ALL &&
			!query &&
			!filterUrlQuery;

		const isInitialRenderFromRedirect =
			status === VOD_STATUS_VALUES.ALL &&
			subtype === VOD_SUBTYPES_VALUES.ALL &&
			!query &&
			filterUrlQuery;

		// We need it so as not to block searching and filtering in first attempt
		if (isInitialRender || isInitialRenderFromRedirect) {
			this.setState({ isTableHasFirstReloading: true });
		}

		if (!this.state.isTableHasFirstReloading && this.state.disabledReload) {
			// We need it to enable changes in table after first loading
			this.setState({ disabledReload: false });
		}

		const {
			fetchResourcesAction,
			itemId,
			resourcesForFetchAction,
			tableType,
			resources: {
				options: { sorted: reduxSorted, page }
			}
		} = this.props;

		// Cancel extra request on pagination
		if (isRestricted && state.page !== 0 && state.page === page) return;

		// Create options
		const options = createTableOptions(state, reduxSorted, tableType);
		// Fetch resources
		// Dispatch an action
		fetchResourcesAction(options, itemId, resourcesForFetchAction);
		// Update local state with selectAll value set to false
		this.setState({ selectAll: false });
	};

	// React table can fire multiple fetch actions when its state is changing
	// This function is used to prevent multiple api requests.
	debounceFetch = debounce((state) => this.handleOnFetchData(state), 100);

	componentDidUpdate(prevProps) {
		const { state } = this.refReactTable.wrappedInstance;
		const { deleteItem, columns } = this.props.resources;
		const {
			resources: { deleteItem: prevDeleteItem, columns: prevColumns }
		} = prevProps;

		if (prevColumns.length !== columns.length) {
			this.handleOnFetchData(state);

			// We need it to limit too many request in first loading
			this.setState({ disabledReload: true });
		}

		// Compare prevProps success to current prop success
		if (prevDeleteItem.success === false && deleteItem.success === true) {
			this.fetchTableDataHandler(false);
		}
		// Compare prevProps error to current prop error
		if (prevDeleteItem.error !== deleteItem.error) {
			this.deleteTableItemHandler();
		}
	}

	fetchTableDataHandler = (isRestricted = true) => {
		const { state } = this.refReactTable.wrappedInstance;

		// Fetch table resources
		this.handleOnFetchData(state, undefined, isRestricted);

		// Add success notification
		notificationHandler(
			this.props.t('messages:notifications.deleted'),
			this.props.notificationDeleteSuccessTxt,
			'success'
		);
	};

	deleteTableItemHandler = () =>
		// Add error notification
		notificationHandler(
			this.props.t('messages:notifications.error'),
			this.props.notificationDeleteErrorTxt,
			'error'
		);

	// Toggle selection
	toggleSelection = (key, shift, row) => {
		let { selection, selectAll } = this.state;
		// Get new selected items, and selectAll value
		const { newSelection, newSelectAll } = toggleCurrentSelection({
			row,
			selection: [...selection],
			selectAll
		});
		// Update state data
		this.setState({ selectAll: newSelectAll, selection: newSelection });
	};

	// Toggle all selection
	toggleAll = () => {
		const { selectAll, selection } = this.state;
		// Get wrapped table instance
		const wrappedInstance = this.refReactTable.getWrappedInstance();
		// Get all the records
		const currentRecords = wrappedInstance.getResolvedState().sortedData;
		// Get new selected items, and selectAll value
		const { newSelectAll, newSelection } = toggleAllItems({
			selection,
			selectAll,
			isSelectedHandler: this.isSelected,
			currentRecords
		});
		// Update state with data
		this.setState({ selectAll: newSelectAll, selection: newSelection });
	};

	// Check if current item is elected in the table
	isSelected = (uuid) =>
		this.state.selection.find((item) => item.uuid === uuid) ? true : false;

	// Clear all selection from the table
	clearAllSelection = () => this.setState({ selectAll: false, selection: [] });

	handleGetTrProp = (state, rowInfo, column) => {
		if (rowInfo?.row._original[`${this.props.keyType}`]) {
			return {
				className: '-mass_changes'
			};
		}

		return {};
	};

	handleTableSize = (value) => {
		this.setState({ pageSize: value, disabledReload: false });
	};

	render() {
		const { selection } = this.state;
		const {
			columns,
			t,
			resources: {
				data,
				options: { pages, filters, page, total_results: totalResults },
				loading
			}
		} = this.props;

		const checkboxProps = {
			selectAll: this.state.selectAll,
			isSelected: this.isSelected,
			toggleSelection: this.toggleSelection,
			toggleAll: this.toggleAll,
			keyField: this.props.keyField,
			keyStyle: this.props.keyType,
			selectType: 'checkbox'
		};

		return (
			<div className="table-select">
				{!this.props.isReadOnly && (
					<ControlButtons
						selection={selection}
						redirectButtonTxt={t('mass_changes:control_buttons.redirect_btn')}
						clearButtonTxt={t('mass_changes:control_buttons.clear_btn')}
						clearAllSelection={this.clearAllSelection}
					/>
				)}

				<CheckboxTable
					PaginationComponent={(props) => (
						<CustomPagination
							tableProps={props}
							totalResults={totalResults}
							onChangeTableSize={this.handleTableSize}
						/>
					)}
					ref={(refReactTable) => (this.refReactTable = refReactTable)}
					manual
					data={data}
					columns={[...columns]}
					pages={pages}
					page={page}
					filtered={filters}
					showPageSizeOptions={false}
					pageSize={this.state.pageSize}
					loading={loading}
					defaultSorted={this.state.defSorted}
					noDataText={t('common:no_data')}
					previousText={t('common:table_buttons.prev')}
					nextText={t('common:table_buttons.next')}
					className="-striped -select"
					onFetchData={this.debounceFetch}
					{...checkboxProps}
					getTrProps={this.handleGetTrProp}
					showPagination={!!pages}
					showPaginationTop={!!pages}
					minRows={0}
				/>
			</div>
		);
	}
}

export default withTranslation()(ReactMassChangesTable);
