import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import context
import { MaterialsContext } from '../materials_context/MaterialsContext';

// Import utilities
import { renderFields } from 'components/utilities/form';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({ drmKey, selectResources }) => {
	const { t } = useTranslation();

	const { isEdit } = useContext(MaterialsContext);

	// display drm key as placeholder if we are editing material
	const drmPlaceholder = drmKey
		? drmKey
		: t('common:materials_table.form.drm_key_placeholder');

	return BASE_FIELDS({ isEdit, drmPlaceholder }).map((item, index) =>
		renderFields({ item, index, selectResources })
	);
};

Fields.propTypes = {
	drmKey: PropTypes.string,
	selectResources: PropTypes.object
};

export default Fields;
