import React from 'react';
import { PlusOutlined } from '@ant-design/icons';

const UploadButton = () => (
	<div>
		<PlusOutlined />
		<div className="ant-upload-text">Upload</div>
	</div>
);

export default UploadButton;
