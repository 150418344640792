import React from 'react';
import PropTypes from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { Spin } from 'antd';

/**
 * Functional component handling showing spinner and error when exporting data to CSV
 * @param {bool} loading - are data is being loaded
 * @param {bool} error - is there an error
 * @param {func} handleClick - starts fetching data to export
 * @returns {*}
 */

const PrepareCSV = ({ loading, error, handleClick }) => {
	const { t } = useTranslation();

	if (error) {
		return <p className="csv__error">{t('csv:download_error')}</p>;
	}

	return !loading ? (
		<p onClick={handleClick} className="csv__link">
			{t('csv:export_csv')}
		</p>
	) : (
		<>
			<Spin size="small" />
			<p className="m-l-sm">{t('csv:loading')}</p>
		</>
	);
};

PrepareCSV.propTypes = {
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	handleClick: PropTypes.func.isRequired
};

export default PrepareCSV;
