import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

const UploadModal = ({
	previewVisible,
	previewImage,
	closeModal,
	modalBodyStyle
}) => (
	<Modal
		open={previewVisible}
		footer={null}
		onCancel={closeModal}
		bodyStyle={modalBodyStyle}
	>
		<img alt="Logo" style={{ width: '100%' }} src={previewImage} />
	</Modal>
);

UploadModal.propTypes = {
	previewVisible: PropTypes.bool.isRequired,
	previewImage: PropTypes.string.isRequired,
	closeModal: PropTypes.func.isRequired,
	modalBodyStyle: PropTypes.object
};

export default UploadModal;
