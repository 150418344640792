// Import utilities
import { useToggle } from 'hooks';
import { useQuery } from 'react-query';

const useFetchDataModal = ({ queryKey, handleFetchData, id }) => {
	const [isOpened, toggle] = useToggle();

	const { data, status, refetch, error, remove } = useQuery({
		queryKey,
		queryFn: () => handleFetchData(id),
		enabled: false
	});

	const handleOpenModal = () => {
		refetch();
		toggle();
	};

	const handleCancel = () => {
		remove();
		toggle();
	};

	return {
		openModal: handleOpenModal,
		cancel: handleCancel,
		data,
		status,
		error,
		isOpened
	};
};

export default useFetchDataModal;
