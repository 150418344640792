import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import convert
import { convertPrice } from 'components/helpers/convert';
import { mapSingleToPlural } from 'components/helpers/name_mapping';

// Import components
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

export const createColumns = ({ subtype }) => {
	return [
		{
			Header: i18n.t('payments:table_columns.title'),
			accessor: 'product_title',
			width: 200,
			Cell: ({
				row: {
					_original: { product_uuid, product_title, product_type }
				}
			}) => {
				const path =
					product_type === 'vod'
						? `/panel/vod/${subtype}/edit/${product_uuid}/metadata`
						: `/panel/${mapSingleToPlural(
								product_type
						  )}/edit/${product_uuid}/metadata`;

				return (
					<Link to={path} title={product_title}>
						{product_title}
					</Link>
				);
			}
		},
		{
			Header: i18n.t('payments:table_columns.confirmed'),
			accessor: 'confirmed',
			width: 110,
			className: 'text-center',
			Cell: ({
				row: {
					_original: { confirmed }
				}
			}) => <ActiveIcon active={confirmed} />
		},
		{
			Header: i18n.t('payments:table_columns.provider'),
			accessor: 'provider',
			width: 200
		},
		{
			Header: i18n.t('payments:table_columns.price'),
			accessor: 'price',
			width: 150,
			Cell: ({
				row: {
					_original: { price }
				}
			}) => <span>{convertPrice(price)}</span>
		},
		{
			Header: i18n.t('payments:table_columns.payment_id'),
			accessor: 'payment_id',
			width: 150
		},
		{
			Header: i18n.t('payments:table_columns.payment_method'),
			accessor: 'payment_method',
			width: 200
		},
		{
			Header: i18n.t('payments:table_columns.period'),
			accessor: 'period',
			width: 150,
			Cell: ({
				row: {
					_original: { period }
				}
			}) => (
				<span>
					{parseInt(period) === -1
						? i18n.t('prices:recurring_payment')
						: period}
				</span>
			)
		},
		{
			Header: i18n.t('payments:table_columns.created_at'),
			accessor: 'created_at',
			width: 200
		},
		{
			Header: i18n.t('payments:table_columns.status'),
			accessor: 'status',
			width: 150,
			Cell: ({
				row: {
					_original: { status }
				}
			}) => <span>{i18n.t(`payments:status.${status}`)}</span>
		},
		{
			Header: i18n.t('payments:table_columns.external_invoice_number'),
			accessor: 'external_invoice_number',
			width: 150,
			Cell: ({
				row: {
					_original: { external_invoice_number, external_invoice_url }
				}
			}) => (
				<>
					{external_invoice_number ? (
						<>
							{external_invoice_url ? (
								<a
									href={external_invoice_url}
									alt={i18n.t('payments:invoice')}
									rel="noopener noreferrer"
									target="_blank"
								>
									{external_invoice_number}
								</a>
							) : (
								<span>{external_invoice_number}</span>
							)}
						</>
					) : (
						i18n.t('common:none')
					)}
				</>
			)
		},
		{
			Header: i18n.t('payments:table_columns.user_login'),
			accessor: 'user_login',
			width: 200,
			Cell: ({
				row: {
					_original: { user_id, user_login }
				}
			}) => (
				<Link to={`/panel/subscribers/edit/${user_id}/metadata`}>
					{user_login}
				</Link>
			)
		}
	];
};
