import React from 'react';
import i18n from 'i18next';

// Import components
import AffiliatePartnersMetadataForm from 'components/views/affiliate_partners/affiliate_partners_metadata_form/AffiliatePartnersMetadataForm';
import AffiliatePartnersCodeForm from 'components/views/affiliate_partners/affiliate_partners_code_form/AffiliatePartnersCodeForm';

export const affiliatePartnersTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <AffiliatePartnersMetadataForm />
	},
	{
		name: i18n.t('common:tabs.aff_codes'),
		path: 'aff_code',
		disableOnCreate: true,
		component: <AffiliatePartnersCodeForm />
	}
];
