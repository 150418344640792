import React from 'react';
import {
	string,
	element,
	object,
	number,
	node,
	oneOfType,
	arrayOf
} from 'prop-types';
import { Modal, Button } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';

// Import hooks
import useToggle from 'hooks/toggle/useToggle';

// Import translation
import { useTranslation } from 'react-i18next';

const Info = ({ children, content, modalWidth }) => {
	const { t } = useTranslation();
	const [on, toggle] = useToggle();

	const renderComponent = () => (
		<div className="info">
			<div className="children">{children}</div>
			<div className="infoModal">
				<InfoCircleTwoTone onClick={toggle} className="infoIcon" />
				{on && (
					<Modal
						title={t('common:form.info_title')}
						open={on}
						onCancel={toggle}
						footer={[
							<Button key="ok" type="primary" onClick={toggle}>
								{t('common:buttons.ok')}
							</Button>
						]}
						width={modalWidth}
						destroyOnClose={true}
					>
						{content}
					</Modal>
				)}
			</div>
		</div>
	);

	return content ? renderComponent() : children;
};

Info.defaultProps = {
	content: null,
	className: '',
	modalWidth: 520
};

Info.propTypes = {
	content: element,
	className: string,
	children: oneOfType([object, node, arrayOf(node)]),
	iconStyle: object,
	modalWidth: number
};

export default Info;
