import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup } from 'reactstrap';
import { Input } from 'antd';

import { FieldInfo, FieldError, Label } from 'components/common/inputs';

const InputLabel = ({
	input,
	id,
	label,
	labelColumn,
	inputColumn,
	children,
	fieldInfo,
	required,
	maxLength,
	meta: { touched, error, submitError },
	...inputProps
}) => {
	return (
		<FormGroup
			data-testid={`form-group-${input.name}`}
			row
			className={`${touched && error && 'has-error'}`}
		>
			<Label
				id={id}
				columnWidth={labelColumn}
				required={required}
				content={label}
			/>
			<Col sm={inputColumn}>
				<Input
					id={id}
					{...input}
					{...inputProps}
					showCount={!!maxLength}
					maxLength={maxLength}
				/>
				<FieldError
					error={touched && (error || submitError)}
					inputName={input.name}
					content={error || submitError}
				/>
				<FieldInfo info={fieldInfo} />
			</Col>
			{children}
		</FormGroup>
	);
};

InputLabel.defaultProps = {
	required: false,
	labelColumn: 2,
	inputColumn: 10
};

InputLabel.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string,
	id: PropTypes.string.isRequired,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	children: PropTypes.object,
	meta: PropTypes.object,
	fieldInfo: PropTypes.string,
	required: PropTypes.bool.isRequired
};

export default InputLabel;
