import { combineReducers } from 'redux';
// Import reducers
import table from './payment_templates_table_reducer';
import edit from './payment_templates_edit_reducer';
import dropdown from './payment_templates_dropdown_reducers';

export default combineReducers({
	table,
	edit,
	dropdown
});
