import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import helpers
import { infoContent } from './helpers';

// Import context
import { PaymentTemplateContext } from '../../PaymentTemplateContext';

//  Import components
import { Modal, Button, message } from 'antd';
import PaymentForm from '../form/PaymentForm';
import { Info } from 'components/utilities';

const PaymentTemplateTableHeader = ({ resources }) => {
	const {
		isVisible,
		toggleFormModal,
		isEdit,
		allUsed,
		clearModal
	} = useContext(PaymentTemplateContext);

	const { t } = useTranslation();

	const onCancel = () => {
		clearModal();
		message.info(t('messages:messages.operation_canceled'));
	};

	const title = isEdit
		? t('payment_templates:title.edit_payment_template')
		: t('payment_templates:title.add_payment_template');

	return (
		<div className="d-flex align-items-center justify-content-between">
			<h5 style={{ fontSize: '14px', margin: 0 }}>
				{t('payment_templates:title.schedules')}
			</h5>
			<div style={{ display: 'flex' }}>
				{allUsed && (
					<div style={{ marginRight: '1rem' }}>
						<Info content={infoContent} />
					</div>
				)}

				<Button
					className={'btn__link'}
					onClick={toggleFormModal}
					disabled={allUsed}
				>
					<i className={`fa fa-plus`} />
				</Button>
			</div>
			<Modal
				title={title}
				open={isVisible}
				footer={null}
				onCancel={onCancel}
				destroyOnClose={true}
				width={900}
			>
				<PaymentForm resources={resources} />
			</Modal>
		</div>
	);
};

PaymentTemplateTableHeader.propTypes = {
	resources: PropTypes.object
};

export default PaymentTemplateTableHeader;
