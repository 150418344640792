import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { InputFileLabel } from 'components/common/inputs';

const Fields = ({ submitting }) => {
	const { t } = useTranslation();

	return (
		<Field
			disabled={submitting}
			name="metadata_file"
			type="file"
			placeholder={t('menu_stb:fields.metadata_file')}
			label={t('menu_stb:fields.metadata_file')}
			id="metadata_file"
			required={true}
			component={InputFileLabel}
		/>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
