import React from 'react';
import { Link } from 'react-router-dom';

// Import helpers
import {
	convertPromotionValue,
	getPromotionType
} from 'components/helpers/promotion_helpers/helpers';

// Import translation
import i18n from 'i18next';

// Import components
import DeleteButton from 'components/utilities/delete_button/DeleteButton';
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('promotions:table_columns.active'),
		accessor: 'active',
		width: 75,
		headerClassName: 'text-center',
		className: 'text-center',
		Cell: ({
			row: {
				_original: { active }
			}
		}) => <ActiveIcon active={active} />
	},
	{
		Header: i18n.t('promotions:table_columns.name'),
		accessor: 'name',
		minWidth: 200,
		className: 'scroll-cell',
		Cell: ({
			row: {
				_original: { id, name }
			}
		}) => <Link to={`/panel/promotions/edit/${id}/metadata`}>{name}</Link>
	},
	{
		Header: i18n.t('promotions:table_columns.description'),
		width: 200,
		className: 'scroll-cell',
		accessor: 'description'
	},
	{
		Header: i18n.t('promotions:table_columns.promotion_type'),
		accessor: 'promotion_type',
		minWidth: 180,
		Cell: ({
			row: {
				_original: { promotion_type }
			}
		}) => i18n.t(getPromotionType(promotion_type))
	},
	{
		Header: i18n.t('promotions:table_columns.promotion_value'),
		accessor: 'promotion_value',
		width: 130,
		Cell: ({
			row: {
				_original: { promotion_type, promotion_value }
			}
		}) => convertPromotionValue(promotion_type, promotion_value)
	},
	{
		Header: i18n.t('promotions:table_columns.users_specification'),
		accessor: 'users_specification',
		minWidth: 200,
		Cell: ({
			row: {
				_original: { users_specification }
			}
		}) =>
			i18n.t(
				`promotions:form:select_values:user_specification.${users_specification}`
			)
	},
	{
		Header: i18n.t('promotions:table_columns.products_specification'),
		accessor: 'products_specification',
		minWidth: 200,
		Cell: ({
			row: {
				_original: { products_specification }
			}
		}) =>
			i18n.t(
				`promotions:form:select_values:product_specification.${products_specification}`
			)
	},
	{
		Header: i18n.t('promotions:table_columns.since'),
		width: 200,
		accessor: 'since'
	},
	{
		Header: i18n.t('promotions:table_columns.till'),
		width: 200,
		accessor: 'till'
	},
	{
		Header: i18n.t('promotions:table_columns.trigger'),
		accessor: 'trigger',
		minWidth: 200,
		Cell: ({
			row: {
				_original: { trigger }
			}
		}) => i18n.t(`promotions:form:select_values:trigger.${trigger}`)
	},
	{
		Header: i18n.t('promotions:table_columns.code'),
		accessor: 'code',
		minWidth: 150
	},
	{
		Header: i18n.t('common:table_columns.system'),
		minWidth: 150,
		accessor: 'system'
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id, system_id, source_system_id }
			}
		}) => (
			<DeleteButton
				id={id}
				productSystem={system_id}
				sourceSystem={source_system_id}
				onSelectItem={handleSelectItem}
				isReadOnly={isReadOnly}
			/>
		)
	}
];
