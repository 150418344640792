import {
	BLUEONLINE_MAIN_SYSTEM,
	CORE_PATHS,
	VECTRA_SYSTEMS
} from './variables';

const {
	METADATA,
	PICTURES,
	MATERIALS,
	PROMOTION_PRODUCTS_OUT_PREVIEW,
	PAYMENTS,
	PACKETS_ASSIGNED,
	PRODUCTS,
	STATIC_ELEMENTS,
	PROMOTIONS_USERS,
	PROMOTIONS_PRODUCTS_OUT,
	DELETE,
	SECTIONS_ASSIGNED,
	SYSTEM_SETTINGS
} = CORE_PATHS;

export const checkUserCanEditProduct = ({ currentSystem, productSystem }) => {
	const isCurrentSystemHasMainSystem = currentSystem === BLUEONLINE_MAIN_SYSTEM;

	const isProductSystemHasCurrentSystem = productSystem === currentSystem;

	const userCanEditProduct =
		isCurrentSystemHasMainSystem || isProductSystemHasCurrentSystem;

	return userCanEditProduct;
};

// Locations which are read only when system of product is main, and we have active other system than main
export const READ_ONLY_LOCATIONS_FOR_SUBSYSTEMS = {
	channels: [METADATA, PICTURES, MATERIALS],
	promotions: [
		METADATA,
		PROMOTION_PRODUCTS_OUT_PREVIEW,
		PROMOTIONS_USERS,
		PROMOTIONS_PRODUCTS_OUT
	],
	coupons: [METADATA, SYSTEM_SETTINGS],
	vod: [METADATA, PICTURES, MATERIALS, PAYMENTS, PACKETS_ASSIGNED],
	limits: [METADATA, PRODUCTS],
	provisioning: [METADATA],
	documents: [METADATA],
	agreements: [METADATA],
	sections_groups: [SECTIONS_ASSIGNED, METADATA],
	sections: [METADATA, PICTURES, STATIC_ELEMENTS],
	banners: [METADATA, PICTURES],
	categories: [METADATA, PICTURES],
	main: [METADATA, PICTURES, PRODUCTS],
	mass_changes: [DELETE]
};

export const checkIsProperVectraSystem = ({
	currentSystem,
	isCurrentSystemMain,
	isReadOnly
}) => {
	const isVectraSystem = VECTRA_SYSTEMS.some(
		(system) => system === currentSystem
	);

	// We can display some buttons only in systems of vectra or main system
	const isProperSystemToVisible = isVectraSystem || isCurrentSystemMain;

	return !isReadOnly && isProperSystemToVisible;
};
