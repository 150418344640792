// Import translation
import i18n from 'i18next';

export const convertErrors = (response) =>
	Object.keys(response).reduce((prev, next, index) => {
		prev[next] = Object.values(response)[index][0];
		return prev;
	}, {});

export const checkFetchErrors = (error) => {
	if (error.response) {
		return error.response.status === 401 ? false : true;
	}
};

// Translate error messages
// This is temporary solution to translate errors.
// In future create error handling api and translate messages based on proper error codes
export const translateError = (message) => {
	switch (message) {
		case 'Cannot activate vod':
			return i18n.t('errors:406.cannot_activate_vod');

		case 'An unexpected error has occurred':
			return i18n.t('errors:500.unexpected_error');

		case 'resource_exist':
			return i18n.t('errors:409.resource_exist');

		case 'npvr_max_depth value cannot be greater than channel npvr_max_depth value.':
			return i18n.t(
				'errors:409.npvr_max_depth_not_grater_than_channel_npvr_max_depth'
			);

		default:
			return message;
	}
};

export const API_ERRORS = {
	TOO_MANY_MAIN_PACKETS: 'TOO_MANY_MAIN_PACKETS',
	PACKET_ALREADY_ATTACHED_TO_PROMOTION: 'PACKET_ALREADY_ATTACHED_TO_PROMOTION'
};
