import React from 'react';
import PropTypes from 'prop-types';

// Import helpers
import { renderFields } from 'components/utilities/form';

// Import fields
import { SYSTEM_SETTINGS_FIELDS } from 'components/helpers/system_settings_helpers/system_settings_fields';
import AdditionalFields from './AdditionalFields';

const Fields = ({ submitting, type }) => {
	return (
		<>
			{SYSTEM_SETTINGS_FIELDS[type].fields.map((item, index) =>
				renderFields({ item, index, submitting })
			)}
			<AdditionalFields type={type} submitting={submitting} />
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired
};

export default Fields;
