import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	loading: true,
	error: false,
	data: [],
	columns: [],
	deleteItem: {
		success: false,
		error: false
	},
	options: {
		pages: 0,
		page: 0,
		total_results: 0,
		startIndex: 0,
		sorted: undefined,
		filters: {
			query: '',
			filterUrlQuery: ''
		}
	},
	packetId: null
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR DATA ******************
			case types.CLEAR_PANEL_STATE:
				return { ...INITIAL_STATE };

			// ******************** FETCH PROVISIONING ITEM PRODUCTS - TABLE DATA ********************
			case types.FETCH_PROVISIONING_ITEM_PRODUCTS_LOADING:
				draft.loading = true;
				draft.error = false;
				break;

			case types.FETCH_PROVISIONING_ITEM_PRODUCTS_SUCCESS:
				draft.loading = false;
				draft.data = action.payload.data;
				draft.columns = action.payload.input.columns;
				draft.options.pages = action.payload.pages;
				draft.options.total_results = action.payload.recordsFiltered;
				draft.options.startIndex = action.payload.startIndex;
				draft.options.page = action.payload.page;
				draft.packetId = action.payload.packetId;
				break;

			case types.FETCH_PROVISIONING_ITEM_PRODUCTS_ERROR:
				draft.error = action.payload;
				break;

			// ******************** DELETE PROVISIONING ITEM PRODUCT ********************
			case types.DELETE_PROVISIONING_ITEM_PRODUCTS_LOADING:
				draft.deleteItem.success = false;
				draft.deleteItem.error = false;
				break;

			case types.DELETE_PROVISIONING_ITEM_PRODUCTS_SUCCESS:
				draft.deleteItem.success = action.payload;
				draft.deleteItem.error = false;
				break;

			case types.DELETE_PROVISIONING_ITEM_PRODUCTS_ERROR:
				draft.deleteItem.error = action.payload;
				break;

			// --------------- RESET PROVISIONING ITEM PRODUCT TABLE ---------------
			// force table to fetch data by adding dummy data object before clearing panel state
			// DO NOT REUSE as it's quick workaround for infinite loader when
			// adding new data to empty table (table does not trigger fetch because data does not change
			// it's empty before clearing state)
			case types.FORCE_FETCH_PROVISIONING_ITEM_PRODUCTS_TABLE:
				draft.data = [
					{
						uuid: '',
						type: '',
						subtype: '',
						title: '',
						provider: null,
						provider_id: null,
						rating: null,
						order: null,
						rank: null,
						status: null,
						created_at: null,
						updated_at: null,
						images: null,
						image: null,
						active_ott: null,
						active_tvonline: null,
						active_obox: null
					}
				];
				break;

			// *************** SAVE SORTING OPTIONS ***************
			case types.SAVE_PROVISIONING_ITEM_PRODUCTS_TABLE_SORTING:
				draft.options.sorted = action.payload;
				break;

			// *************** SET SEARCH QUERY ***************
			case types.SET_PROVISIONING_ITEM_PRODUCTS_QUERY:
				draft.options.filters.query = action.payload;
				break;

			// --------------- FILTERS ---------------
			// *************** SET FILTER URL QUERY ***************
			case types.SET_FILTER_URL_QUERY:
				draft.options.filters.filterUrlQuery = action.payload;
				break;

			// *************** CLEAR FILTERS ***************
			case types.CLEAR_FILTERS:
				draft.options.filters.filterUrlQuery = '';
				break;

			default:
				return state;
		}
	});
