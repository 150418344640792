// Import translation
import i18n from 'i18next';

export default ({ range, template, period }) => {
	const errors = {};

	// ------------------ Range ------------------
	if (!range || !range.length) {
		errors.range = i18n.t('prices:validation.range_required');
	}

	// ------------------ Template ------------------
	if (!template) {
		errors.template = i18n.t('prices:validation.template_required');
	}

	// ------------------ Period ------------------
	if (period !== 0 && !period) {
		errors.period = i18n.t('prices:validation.period_required');
	}

	return errors;
};
