import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, useParams } from 'react-router-dom';
import { fetchSubscriberConsents } from 'store/actions';

// Import columns
import { createColumns } from './consents_columns';

// Import components
import { PanelPageTemplate } from 'components/common/templates';

const Consents = ({ error, resources, fetchSubscriberConsents }) => {
	const { id } = useParams();

	return (
		//Dispatch fetchConsent action
		<PanelPageTemplate
			itemId={id}
			error={error}
			type="agreements"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchSubscriberConsents}
		/>
	);
};

Consents.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchSubscriberConsents: PropTypes.func.isRequired
};

const mapStateToProps = ({ subscribers: { consents } }) => {
	return {
		error: consents.error,
		resources: consents
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchSubscriberConsents
	}),
	withRouter
)(Consents);
