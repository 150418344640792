import { useReducer } from 'react';

const DEFAULT_METADATA = {
	size: null,
	width: null,
	height: null,
	ratio: null
};

const DEFAULT_IMAGE_STATE = (requirements) => ({
	isLoading: false,
	isValid: false,
	rules: requirements ?? [],
	metadata: DEFAULT_METADATA,
	error: null
});

export const actions = {
	LOADING: 'loading',
	NO_VALID_URL: 'no_valid_url',
	SUCCESS: 'success',
	CORS: 'cors',
	NOT_FOUND: 'not_found'
};

const useImageReducer = (requirements) => {
	const imageReducer = (state, action) => {
		const payload = action.payload;
		const defaults = DEFAULT_IMAGE_STATE(requirements);
		switch (action.type) {
			case actions.LOADING:
				return { ...defaults, isLoading: true, error: null, isValid: false };
			case actions.NO_VALID_URL:
				return {
					...defaults,
					isLoading: false,
					error: 'invalid',
					isValid: false
				};
			case actions.CORS:
				return { ...defaults, isLoading: false, error: 'cors', isValid: false };
			case actions.NOT_FOUND:
				return { ...defaults, isLoading: false, isValid: false, error: '404' };
			case actions.SUCCESS:
				return {
					...defaults,
					rules: payload.rules,
					isValid: true,
					metadata: payload.metadata,
					error: null
				};
			default:
				return state;
		}
	};

	return useReducer(imageReducer, DEFAULT_IMAGE_STATE(requirements));
};

export default useImageReducer;
