import services from 'services/services';
import * as types from '../types';

// Import helpers
import {
	checkFetchErrors,
	convertDevicesSnData
} from '../helpers_actions/convert_helpers';

// ******************** FETCH DEVICE_SN TO EDIT ********************
export const fetchDeviceSn = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_DEVICE_SN_LOADING
		});

		const url = `/subscriber/serialnumbers/${id}`;
		const { data: deviceSnData } = await services.get(url);

		const tagsUrl = `/tags/${id}/getResourceTags`;
		const { data: tagsData } = await services.get(tagsUrl, {
			params: { resource_type: 'device' }
		});

		const convertedData = convertDevicesSnData(deviceSnData, tagsData);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_DEVICE_SN_SUCCESS,
			payload: convertedData
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_DEVICE_SN_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
