import React from 'react';
import { Tabs } from 'antd';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import GeolocksForm from '../geolocks_form/GeolocksForm';

const { TabPane } = Tabs;

const GeolockTabs = () => {
	const { t } = useTranslation();

	return (
		<Tabs animated={false} defaultActiveKey="1">
			<TabPane tab={t('common:tabs.metadata')} key="1">
				<GeolocksForm />
			</TabPane>
		</Tabs>
	);
};

export default GeolockTabs;
