export const BILLING_PERIOD_LENGTH = 30;

export const convertDaysToMonths = (days) => {
	if (!days || days <= 0) return null;
	return Math.floor(days / BILLING_PERIOD_LENGTH);
};

export const convertMonthsToDays = (months) => {
	if (!months || months <= 0) return null;
	return Math.floor(months * BILLING_PERIOD_LENGTH);
};
