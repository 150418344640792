import React from 'react';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import PrepareCSV from './PrepareCSV';
import { CSVLink } from 'react-csv';

// Import hooks
import useCSVExportLogic from './useCSVExportLogic';
import useAuthCheck from 'hooks/useAuthCheck';

/**
 * Functional component that enables downloading table as CSV file
 * @returns {*}
 */
const CSVExport = () => {
	const { t } = useTranslation();
	const {
		handlePrepare,
		handleDownload,
		tableType,
		data,
		loading,
		error
	} = useCSVExportLogic();

	// check if user have rights to export csv
	const { canExportCSV } = useAuthCheck();

	return canExportCSV ? (
		data.length ? (
			<CSVLink
				data={data}
				className="csv__export"
				onClick={handleDownload}
				filename={`${tableType}-table.csv`}
			>
				<i className="fa fa-download m-r-sm"></i>
				{t('csv:download_table')}
			</CSVLink>
		) : (
			<PrepareCSV handleClick={handlePrepare} loading={loading} error={error} />
		)
	) : null;
};

export default CSVExport;
