import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';

// Import utilities
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from '../fields/Fields';

// Import helpers
import {
	fetchTagIds,
	getParsedTags,
	submitCreateLocalChannelForm
} from '../helpers';
import { notificationHandler } from 'components/utilities/notifications';

const LocalChannelsForm = () => {
	const { t } = useTranslation();

	const handleOnSubmit = async ({
		counties,
		position,
		local_channel,
		voivodeship
	}) => {
		const { tagIds, tagWithoutTerc } = await fetchTagIds(counties, voivodeship);
		if (!tagIds) return;

		if (tagWithoutTerc) {
			return notificationHandler(
				t('messages:notifications.error'),
				t('local_channels:notifications.terc_required', {
					tagWithoutTerc
				}),
				'error',
				10
			);
		}

		const resources = {
			resource_type: 'channel',
			tags: getParsedTags(tagIds, position)
		};

		return await submitCreateLocalChannelForm({
			api: 'tags',
			apiSlug: 'addResourceTags',
			channel_uuid: local_channel,
			resources
		});
	};

	return (
		<FormTemplate iboxContentTitle={`${t('local_channels:form.title')}`}>
			{() => (
				<Form
					onSubmit={handleOnSubmit}
					validate={validate}
					render={({
						handleSubmit,
						submitting,
						values,
						form: { change },
						modified
					}) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								change={change}
								currentValues={values}
								modified={modified}
							/>
							<FormButtons
								isButtonDisabled={submitting}
								path={'localChannels'}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

export default LocalChannelsForm;
