import i18n from 'i18n';

export const BASE_FIELDS = [
	{
		name: 'action',
		type: 'text',
		label: i18n.t('agreements_versions:fields.action'),
		required: true
	},
	{
		name: 'description',
		type: 'tiny-text-editor',
		required: true
	},
	{
		name: 'applicable_from',
		type: 'date',
		label: i18n.t('agreements_versions:fields.applicable_from'),
		required: true,
		showTime: true
	}
];
