// Import translation
import i18n from 'i18next';

// Import helpers
import { checkIsInt } from 'components/helpers/convert';
import { PERCENTAGE } from 'components/helpers/promotion_helpers/helpers';

export default ({
	promotion_type,
	promotion_value,
	name,
	promotion_period
}) => {
	const errors = {};

	// ------------------ Name ------------------
	if (!name) {
		errors.name = i18n.t('promotions:form:validation.name_required');
	}

	// ------------------ Promotion Period ------------------
	if (!promotion_period || promotion_period.length === 0) {
		errors.promotion_period = i18n.t(
			'promotions:form:validation.promotion_period_required'
		);
	}

	// ------------------ Promotion value ------------------
	if (promotion_value === null || promotion_value === undefined) {
		errors.promotion_value = i18n.t(
			'promotions:form:validation.promotion_value_required'
		);
	} else if (promotion_value < 0) {
		errors.promotion_value = i18n.t(
			'promotions:form:validation.negative_value'
		);
	} else if (promotion_type === PERCENTAGE) {
		if (promotion_value > 100) {
			errors.promotion_value = i18n.t(
				'promotions:form:validation.over_percent'
			);
		}

		if (!checkIsInt(promotion_value)) {
			errors.promotion_value = i18n.t(
				'promotions:form:validation.fraction_percent'
			);
		}
	}

	return errors;
};
