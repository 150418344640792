import React from 'react';

// Import translation
import i18n from 'i18next';

// Import helpers
import { setFormLink } from 'components/helpers/link_selector';

// Import components
import { RepeatIngesterActionButton } from 'components/common/elements';
import { DeleteProductButton } from '../components';

export const createColumns = ({ refreshAction }) => (
	handleSelectItem,
	isReadOnly
) => [
	{
		Header: i18n.t('barker_channels:products.table_columns.title'),
		accessor: 'title',
		sortable: false,
		Cell: ({
			row: {
				_original: { product_uuid, title, subtype }
			}
		}) => setFormLink({ uuid: product_uuid, type: subtype, title })
	},
	{
		Header: i18n.t('barker_channels:products.table_columns.subtype'),
		accessor: 'subtype',
		sortable: false
	},
	{
		Header: i18n.t('barker_channels:products.table_columns.status'),
		accessor: 'status',
		sortable: false
	},
	{
		Header: i18n.t('common:table_columns.action'),
		accessor: 'repeat_ingestion',
		className: 'text-center',
		sortable: false,
		width: 120,
		Cell: ({
			row: {
				_original: { product_uuid }
			}
		}) =>
			!isReadOnly && (
				<RepeatIngesterActionButton
					url={`cognito/barkerchannel/products/${product_uuid}`}
					refreshAction={refreshAction}
				/>
			)
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { product_uuid, status }
			}
		}) => (
			<DeleteProductButton
				productId={product_uuid}
				productStatus={status}
				handleSelectItem={handleSelectItem}
				isReadOnly={isReadOnly}
			/>
		)
	}
];
