import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchBarkerChannel, dictionarySections } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import helpers
import { isCreatePath } from 'components/helpers/common';

// Validate
import validate from './validation';

// Default values
import { defaultFieldValues } from './fields/input_fields';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import { ExportButton } from '../components';

const BarkerChannelsForm = ({
	history,
	fetchBarkerChannel,
	dictionarySections,
	error,
	isLoaded,
	initialValues,
	sections,
	match: {
		params: { id }
	},
	location: { pathname }
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) =>
		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: 'cognito/barkerchannel',
			createSlug: false
		});

	const initialFormValues = {
		...defaultFieldValues,
		section_id: sections[0]?.id,
		...initialValues
	};

	const showExportButton = !isCreatePath(pathname);

	return (
		<>
			{showExportButton && <ExportButton id={id} />}
			<FormTemplate
				fetchFormResources={[dictionarySections]}
				fetchResourceToEdit={fetchBarkerChannel}
				error={error}
				isLoaded={isLoaded}
			>
				{({ isEdit, itemID }) => (
					<Form
						initialValues={initialFormValues}
						validate={(values) => validate({ ...values, isEdit })}
						onSubmit={handleOnSubmit({ isEdit, itemID })}
						render={({ handleSubmit, submitting }) => (
							<form onSubmit={handleSubmit}>
								<Fields submitting={submitting} sections={sections} />
								<FormButtons
									isButtonDisabled={submitting || error}
									path="barker_channels"
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						)}
					/>
				)}
			</FormTemplate>
		</>
	);
};

BarkerChannelsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchBarkerChannel: PropTypes.func.isRequired,
	dictionarySections: PropTypes.func.isRequired,
	sections: PropTypes.array.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string })
	}),
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired
	})
};

const mapStateToProps = ({
	barker_channels: { form },
	dictionary: { sections }
}) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded,
		sections
	};
};

export default compose(
	connect(mapStateToProps, { fetchBarkerChannel, dictionarySections }),
	withRouter
)(BarkerChannelsForm);
