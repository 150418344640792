import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchSystemsProvidersProducts } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import ProductsSelect from 'components/utilities/products_select/ProductsSelect';

// Import products tabs
import { providers } from './helpers/tabs';
import { PRODUCT_TYPES } from 'components/utilities/products_select/helpers';

const { PROVIDER_SYSTEM } = PRODUCT_TYPES;

const SystemsProvidersProducts = ({
	data,
	error,
	isLoaded,
	fetchSystemsProvidersProducts
}) => {
	const { t } = useTranslation();
	const tabs = [providers];

	const createSubmitData = (selectedData) => {
		const selection = { providers: [] };

		selection.providers = selectedData.map(({ uuid }) => ({
			id: uuid,
			available: true
		}));

		return selection;
	};

	return (
		<ProductsSelect
			api="products/providers"
			title={t('systems:providers')}
			tabs={tabs}
			openModalText={t('systems:buttons.add_provider')}
			buttonSubmitText={t('common:buttons.submit')}
			notificationName="products"
			productsTitle={t('systems:providers_products_title')}
			initialData={data}
			error={error}
			isLoaded={isLoaded}
			fetchResourcesAction={fetchSystemsProvidersProducts}
			createSubmitData={createSubmitData}
			isSystem
			type={PROVIDER_SYSTEM}
		/>
	);
};

SystemsProvidersProducts.propTypes = {
	data: PropTypes.array.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	fetchSystemsProvidersProducts: PropTypes.func.isRequired
};

const mapStateToProps = ({ systems: { providers } }) => ({
	data: providers.data,
	isLoaded: providers.isLoaded,
	error: providers.error
});

export default connect(mapStateToProps, { fetchSystemsProvidersProducts })(
	SystemsProvidersProducts
);
