import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	loading: true,
	error: false,
	data: [],
	columns: [],
	deleteItem: {
		success: false,
		error: false
	},
	activateItem: {
		success: false,
		error: false
	},
	options: {
		pages: 0,
		total_results: 0,
		filters: {
			query: ''
		}
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR DATA ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// *************** FETCH AGREEMENTS VERSIONS ***************
			case types.FETCH_AGREEMENTS_VERSIONS_LOADING:
				draft.loading = true;
				draft.error = false;
				return;

			case types.FETCH_AGREEMENTS_VERSIONS_SUCCESS:
				draft.loading = false;
				draft.data = action.payload.data;
				draft.columns = action.payload.input.columns;
				draft.options.pages = action.payload.pages;
				draft.options.total_results = action.payload.recordsFiltered;
				return;

			case types.FETCH_AGREEMENTS_VERSIONS_ERROR:
				draft.error = action.payload;
				return;

			// *************** DELETE AGREEMENTS VERSION ***************
			case types.DELETE_AGREEMENTS_VERSION_LOADING:
				draft.deleteItem.success = false;
				draft.deleteItem.error = false;
				return;

			case types.DELETE_AGREEMENTS_VERSION_SUCCESS:
				draft.deleteItem.success = action.payload;
				draft.deleteItem.error = false;
				return;

			case types.DELETE_AGREEMENTS_VERSION_ERROR:
				draft.deleteItem.error = action.payload;
				return;

			// *************** ACTIVATE AGREEMENTS VERSION ***************
			case types.ACTIVATE_AGREEMENTS_VERSION_LOADING:
				draft.activateItem.success = false;
				draft.activateItem.error = false;
				return;

			case types.ACTIVATE_AGREEMENTS_VERSION_SUCCESS:
				draft.activateItem.success = action.payload.success;
				draft.activateItem.error = false;
				draft.data.forEach((item) => {
					item.id === action.payload.id
						? (item.active = true)
						: (item.active = false);
				});
				return;

			case types.ACTIVATE_AGREEMENTS_VERSION_ERROR:
				draft.activateItem.error = action.payload;
				return;

			default:
				return state;
		}
	});
