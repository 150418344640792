import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Import redux actions
import {
	dictionaryCurrentSystemWithChildren,
	dictionarySystems,
	fetchSystem
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import default values
import { defaultFieldValues } from './fields/input_fields';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import hooks
import useSystem from 'hooks/useSystem';

const SystemsForm = ({
	history,
	fetchSystem,
	error,
	isLoaded,
	initialValues,
	parentSystem,
	dictionaryCurrentSystemWithChildren,
	currentSystemWithChildren,
	dictionarySystems,
	systems
}) => {
	const { t } = useTranslation();

	const { mainSystem } = useSystem();

	useEffect(() => {
		dictionaryCurrentSystemWithChildren(mainSystem);
		dictionarySystems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) =>
		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: 'system'
		});

	const formInitialValues = {
		...defaultFieldValues,
		parent_system: parentSystem,
		...initialValues
	};

	return (
		<FormTemplate
			fetchResourceToEdit={fetchSystem}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={formInitialValues}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								currentSystemWithChildren={currentSystemWithChildren}
								systems={systems}
								isEdit={isEdit}
							/>
							<FormButtons
								isButtonDisabled={submitting || error}
								path="systems"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

SystemsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchSystem: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	parentSystem: PropTypes.string.isRequired,
	dictionaryCurrentSystemWithChildren: PropTypes.func.isRequired,
	currentSystemWithChildren: PropTypes.array.isRequired,
	dictionarySystems: PropTypes.func.isRequired,
	systems: PropTypes.array.isRequired
};

const mapStateToProps = ({
	systems: { form },
	auth: {
		authenticate: { user }
	},
	dictionary: { currentSystemWithChildren, systems }
}) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded,
		parentSystem: user.system,
		currentSystemWithChildren,
		systems
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchSystem,
		dictionaryCurrentSystemWithChildren,
		dictionarySystems
	}),
	withRouter
)(SystemsForm);
