import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	edit: { subscriber_local_limitation: false },
	isLoaded: true,
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };
			// ****************** FETCH CHANNELS_SYSTEM ******************
			case types.FETCH_CHANNELS_SYSTEM_LOADING:
				draft.isLoaded = false;
				draft.error = false;
				draft.edit = {};
				return;

			case types.FETCH_CHANNELS_SYSTEM_SUCCESS:
				draft.edit = action.payload;
				// when creating channel first data that comes from API contains
				// subscriber_local_limitation: null but API does not accept
				// null in this key (passed received data unchanged). That's why null
				// is converted here to false
				if (action.payload.subscriber_local_limitation === null) {
					draft.edit.subscriber_local_limitation = false;
				}
				draft.isLoaded = true;
				return;

			case types.FETCH_CHANNELS_SYSTEM_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
