// Import translation
import i18n from 'i18next';

export default ({ section_id }) => {
	const errors = {};

	// ------------------ Name ------------------
	if (!section_id) {
		errors.section_id = i18n.t(
			'barker_channels:form.validation.section_id_required'
		);
	}

	return errors;
};
