import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	fetchChannelsSystem,
	dictionaryCastTypes,
	dictionaryPlatformsTypes
} from 'store/actions';

// Validate
import validate from './validation';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const ChannelsSystemsForm = ({
	history,
	fetchChannelsSystem,
	dictionaryCastTypes,
	error,
	isLoaded,
	initialValues,
	castList,
	platformList,
	ranksList,
	dictionaryPlatformsTypes
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		dictionaryCastTypes();
		dictionaryPlatformsTypes();
		// eslint-disable-next-line
	}, []);

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		// We receive this value from backend as initial value, but we can't send this value
		delete values.system;

		const ranksToNumber = values.ranks.map(Number);
		values.ranks = ranksToNumber;

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: 'channel/systemFields',
			notificationName: 'system'
		});
	};

	return (
		<FormTemplate
			fetchResourceToEdit={fetchChannelsSystem}
			error={error}
			isLoaded={isLoaded}
			iboxContentTitle={`${t('channels:system.config_title')}`}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={initialValues}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					validate={validate}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								casts={castList}
								platforms={platformList}
								ranks={ranksList ?? []}
							/>

							<FormButtons
								isButtonDisabled={submitting || error}
								path="channels"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

ChannelsSystemsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchChannelsSystem: PropTypes.func.isRequired,
	dictionaryCastTypes: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	castList: PropTypes.array.isRequired,
	dictionaryPlatformsTypes: PropTypes.func.isRequired,
	platformList: PropTypes.array.isRequired,
	ranksList: PropTypes.array
};

const mapStateToProps = ({
	channels: { system },
	dictionary: { castTypes, platformTypes }
}) => {
	return {
		initialValues: system.edit,
		error: system.error,
		isLoaded: system.isLoaded,
		castList: castTypes,
		platformList: platformTypes,
		ranksList: system.edit.ranks
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchChannelsSystem,
		dictionaryCastTypes,
		dictionaryPlatformsTypes
	}),
	withRouter
)(ChannelsSystemsForm);
