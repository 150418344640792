import services from 'services/services';
import * as types from '../types';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// Import actions
import { setRequestUrl } from 'store/actions';

// ******************** FETCH SECTIONS_PAGES - TABLE DATA ********************
export const fetchSectionsPages = (options, id) => async (
	dispatch,
	getState
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_SECTIONS_PAGES_LOADING,
			payload: true
		});

		const {
			sections: {
				pages: {
					columns,
					options: { startIndex: startTableIndex, page }
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `/section/${id}/listGroups?${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex,
			true
		)}`;

		const { data } = await services.get(url);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SECTIONS_PAGES_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		// Save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_SECTIONS_PAGES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
