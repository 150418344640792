import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import components
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

export const createColumns = () => [
	{
		Header: i18n.t('systems:table_columns.name'),
		accessor: 'name',
		minWidth: 160,
		Cell: ({
			row: {
				_original: { name }
			}
		}) => <Link to={`/panel/systems/edit/${name}/metadata`}>{name}</Link>
	},
	{
		Header: i18n.t('systems:table_columns.parent_system'),
		accessor: 'parent_system',
		minWidth: 160
	},
	{
		Header: i18n.t('systems:table_columns.display_name'),
		accessor: 'display_name',
		minWidth: 160
	},
	{
		Header: i18n.t('systems:table_columns.is_visible_at_login_screen'),
		accessor: 'is_visible_at_login_screen',
		width: 120,
		sortable: true,
		headerClassName: 'text-center',
		className: 'text-center',
		Cell: ({
			row: {
				_original: { is_visible_at_login_screen }
			}
		}) => <ActiveIcon active={is_visible_at_login_screen} />
	},
	{
		Header: i18n.t('systems:table_columns.login_screen_position'),
		accessor: 'login_screen_position',
		headerClassName: 'text-center',
		className: 'text-center',
		minWidth: 160
	},
	{
		Header: i18n.t('systems:table_columns.created_at'),
		accessor: 'created_at',
		width: 160
	},
	{
		Header: i18n.t('systems:table_columns.updated_at'),
		accessor: 'updated_at',
		width: 160
	}
];
