import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Import hooks
import useAuthSystemCheck from 'hooks/useAuthSystemCheck';

// Import helpers
import { mapAvailabilityProductTypeName } from 'components/helpers/name_mapping';

const EditButton = ({
	productType,
	productUuid,
	id,
	productSystem,
	isReadOnly
}) => {
	const {
		checkUserCanEditProduct,
		currentSystem,
		subLocationType
	} = useAuthSystemCheck();

	const mappedProductType = mapAvailabilityProductTypeName(productType);

	const canUserEditProduct = checkUserCanEditProduct({
		currentSystem,
		productSystem
	});

	if (isReadOnly) return null;

	return (
		<Link
			to={`/panel/${mappedProductType}/${productUuid}/${subLocationType}/edit/${id}`}
			className="btn btn-xs btn-primary"
			disabled={!canUserEditProduct}
		>
			<i className="fa fa-gear" />
		</Link>
	);
};

EditButton.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	productUuid: PropTypes.string.isRequired,
	productSystem: PropTypes.string.isRequired,
	productType: PropTypes.string.isRequired,
	isReadOnly: PropTypes.bool.isRequired
};

export default EditButton;
