import _ from 'lodash';
// Import translation
import i18n from 'i18next';

// Import helpers
import { checkIsInt } from 'components/helpers/convert';

export default (values) => {
	const errors = {};

	// ------------------ Catch Up Time ------------------
	const convertData = Object.entries(values);

	convertData.forEach(([platformName, platformData]) => {
		if (platformData.catch_up_time) {
			if (platformData.catch_up_time <= 0) {
				_.set(
					errors,
					`${platformName}.catch_up_time`,
					i18n.t('channels:platforms:validation.negative_value_or_zero')
				);
			} else if (!checkIsInt(platformData.catch_up_time)) {
				_.set(
					errors,
					`${platformName}.catch_up_time`,
					i18n.t('channels:platforms:validation.fraction_invalid')
				);
			}
		}
	});

	return errors;
};
