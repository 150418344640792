import i18n from 'i18n';

export const BASE_FIELDS = [
	{
		name: 'group_name',
		type: 'text',
		label: i18n.t('limits:fields.group_name'),
		required: true
	},
	{
		name: 'limit',
		type: 'number',
		label: i18n.t('limits:fields.limit'),
		required: true
	}
];
