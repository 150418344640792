import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getCSVData, clearCSVData } from 'store/actions';

// Import helpers
import { getSubLocationType } from 'components/helpers/convert';

/**
 * Custom hook to store logic for CSVExport
 */
const useCSVExportLogic = () => {
	const { converted_request_url } = useSelector((state) => state.request_url);
	const { error, loading, data } = useSelector((state) => state.csv);
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const tableType = getSubLocationType(pathname);

	const handlePrepare = () => {
		getCSVData(converted_request_url)(dispatch);
	};

	const handleDownload = () => {
		clearCSVData()(dispatch);
	};

	return {
		handlePrepare,
		handleDownload,
		tableType,
		data,
		loading,
		error
	};
};

export default useCSVExportLogic;
