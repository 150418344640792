// Import translation
import i18n from 'i18next';

// translate vod substatus from dictionary
export const translateSubStatuses = (substatuses) => {
	let translatedSubStatuses = [];
	if (substatuses) {
		translatedSubStatuses = Object.values(substatuses).map((substatus) => {
			switch (substatus) {
				case 'complete':
					return {
						name: i18n.t('vod:select:sub_status.complete'),
						value: substatus
					};
				case 'expired':
					return {
						name: i18n.t('vod:select:sub_status.expired'),
						value: substatus
					};
				default:
					return { name: substatus, value: substatus };
			}
		});
	}
	return translatedSubStatuses;
};
