import * as types from 'store/actions/types';
import produce from 'immer';

import { VERIFICATION } from 'components/helpers/variables';

export const INITIAL_STATE = {
	data: {
		document: null,
		status: VERIFICATION.UNVERIFIED,
		fileName: '',
		extension: '',
		verifiedAt: '',
		verifiedBy: ''
	},
	verify: {
		loading: false,
		error: false
	},
	unverify: {
		loading: false,
		error: false
	},
	isLoaded: true,
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR SUBSCRIBER STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// ****************** FETCH SUBSCRIBER'S DATA ******************
			case types.FETCH_SUBSCRIBER_DOCUMENT_LOADING:
				draft.isLoaded = false;
				return;

			case types.FETCH_SUBSCRIBER_DOCUMENT_SUCCESS:
				draft.data.document = action.payload.document;
				draft.data.status = action.payload.status;
				draft.data.fileName = action.payload.fileName;
				draft.data.extension = action.payload.extension;
				draft.isLoaded = true;
				draft.error = false;
				return;

			case types.FETCH_SUBSCRIBER_DOCUMENT_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				draft.data.document = null;
				draft.data.status = VERIFICATION.UNVERIFIED;
				return;

			// ****************** VERIFY ******************
			case types.FETCH_SUBSCRIBER_DOCUMENT_VERIFY_LOADING:
				draft.verify.loading = true;
				draft.verify.error = false;
				return;

			case types.FETCH_SUBSCRIBER_DOCUMENT_VERIFY_SUCCESS:
				draft.verify.loading = false;
				draft.verify.error = !action.payload;
				if (action.payload) {
					draft.data.status = VERIFICATION.VERIFIED;
				}
				return;

			case types.FETCH_SUBSCRIBER_DOCUMENT_VERIFY_ERROR:
				draft.verify.loading = false;
				draft.verify.error = true;
				return;

			// ****************** UNVERIFY ******************
			case types.FETCH_SUBSCRIBER_DOCUMENT_UNVERIFY_LOADING:
				draft.unverify.loading = true;
				draft.unverify.error = false;
				return;

			case types.FETCH_SUBSCRIBER_DOCUMENT_UNVERIFY_SUCCESS:
				draft.unverify.loading = false;
				draft.unverify.error = !action.payload;
				if (action.payload) {
					draft.data.status = VERIFICATION.UNVERIFIED;
				}
				return;

			case types.FETCH_SUBSCRIBER_DOCUMENT_UNVERIFY_ERROR:
				draft.unverify.loading = false;
				draft.unverify.error = true;
				return;

			case types.FETCH_SUBSCRIBER_VERIFICATION_DOCUMENT_DETAILS_SUCCESS:
				draft.data.document = null;
				draft.data.status = action.payload.status;
				draft.data.fileName = action.payload.fileName;
				draft.data.extension = action.payload.extension;
				draft.data.verifiedAt = action.payload.verifiedAt;
				draft.data.verifiedBy = action.payload.verifiedBy;
				draft.isLoaded = true;
				draft.error = false;
				return;

			default:
				return state;
		}
	});
