import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	fetchAgreementsVersions,
	deleteAgreementsVersion,
	activateAgreementsVersion,
	fetchAgreement
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { messageConfirmHandler } from 'components/utilities/message';

// Import columns
import { createColumns } from './agreements_versions_columns';

// Import components
import { TableTemplate } from 'components/common/templates';

// Import hooks
import useProductSystem from 'hooks/useProductSystem';

const AgreementsVersions = ({
	error,
	fetchAgreementsVersions,
	deleteAgreementsVersion,
	activateAgreementsVersion,
	resources,
	match: {
		params: { id }
	}
}) => {
	const { t } = useTranslation();

	const { productSystem } = useProductSystem();

	const createColumnsVersions = (handleSelectItem, isReadOnly) =>
		createColumns(
			handleSelectItem,
			id,
			handleActivateItem,
			isReadOnly,
			productSystem
		);

	const handleActivateItem = (versionId, resources) => {
		const callback = (id) => activateAgreementsVersion(id, resources);

		messageConfirmHandler(
			t('agreements_versions:activation_confirm.activate'),
			t('agreements_versions:activation_confirm.text'),
			versionId,
			callback,
			'confirm'
		);
	};

	return (
		// Dispatch fetchAgreementsVersions, deleteAgreementsVersion action in agreements_versions
		<TableTemplate
			itemId={id}
			error={error}
			type={`agreements/${id}/versions`}
			typeText="version"
			addLinkText={t('agreements_versions:buttons.add')}
			resources={resources}
			columns={createColumnsVersions}
			fetchResourcesAction={fetchAgreementsVersions}
			deleteItemAction={deleteAgreementsVersion}
			notificationDeleteSuccessTxt={t(
				'agreements_versions:notification.delete_success'
			)}
			notificationDeleteErrorTxt={t(
				'agreements_versions:notification.delete_error'
			)}
		/>
	);
};

AgreementsVersions.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchAgreementsVersions: PropTypes.func.isRequired,
	deleteAgreementsVersion: PropTypes.func.isRequired,
	activateAgreementsVersion: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	})
};

const mapStateToProps = ({
	agreements: {
		versions: { table }
	}
}) => ({
	error: table.error,
	resources: table
});

export default compose(
	connect(mapStateToProps, {
		fetchAgreementsVersions,
		deleteAgreementsVersion,
		activateAgreementsVersion,
		fetchAgreement
	}),
	withRouter
)(AgreementsVersions);
