import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscriberCoupons } from 'store/actions';
import services from 'services/services';
import { useMutation } from 'react-query';

// Import translation
import { useTranslation } from 'react-i18next';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';
import { useToggle } from 'hooks';

const useSubscribersCoupons = () => {
	const { t } = useTranslation();
	const { id: userId } = useParams();
	const dispatch = useDispatch();
	const { isReadOnly } = useAuthCheck();

	const [isOpened, toggle] = useToggle();

	const { data, error } = useSelector(({ subscribers }) => subscribers.coupons);

	useEffect(() => {
		fetchSubscriberCoupons(userId)(dispatch);
		// eslint-disable-next-line
	}, []);

	const handleCouponChange = async ({
		userId,
		promotionId,
		modificationType,
		toggleModal = false
	}) => {
		const url = `promotions/user/${userId}/coupon/${promotionId}/${modificationType}`;
		await services.post(url);

		return toggleModal;
	};

	const handleSuccess = (toggleModal) => {
		notificationHandler(t('common:success'), '', 'success');
		fetchSubscriberCoupons(userId)(dispatch);
		toggleModal && toggle();
	};

	const handleError = (toggleModal) => {
		notificationHandler(t('messages:notifications.error'), '', 'error');
		toggleModal && toggle();
	};

	const { mutate: handleSubscriberCouponChange } = useMutation({
		mutationFn: handleCouponChange,
		onSuccess: handleSuccess,
		onError: handleError
	});

	return {
		isReadOnly,
		isOpened,
		subscriberCouponChange: handleSubscriberCouponChange,
		toggle,
		userId,
		resources: data,
		error
	};
};

export default useSubscribersCoupons;
