import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Import components
import ForgotPasswordForm from './form/ForgotPasswordForm';
import SignInAlert from 'components/login/sign_in/SignInAlert';

// Import translation
import i18n from 'i18next';

const ForgotPasswordPage = () => {
	const { error, isSuccess } = useSelector(
		({ auth }) => auth.resetPassword.sendResetEmail
	);

	const descriptionText = isSuccess
		? i18n.t('login:reset_password.send_reset_mail.success')
		: i18n.t('login:top_content.text');

	return (
		<div className="login gray-bg">
			<div className="reset-password">
				{error && (
					<SignInAlert
						title={i18n.t('common:error')}
						message={i18n.t('login:reset_password.send_reset_mail.error')}
					/>
				)}
				<div className="reset-password__screen">
					<div className="reset-password__content">
						<h2 className="font-bold reset-password__header">
							{i18n.t('login:top_content.heading')}
						</h2>
						<p
							className={`reset-password--${
								isSuccess ? 'email-success' : 'email-primary'
							}`}
						>
							{descriptionText}
						</p>

						{!isSuccess && (
							<div className="row">
								<div className="col-lg-12">
									<ForgotPasswordForm />
								</div>
							</div>
						)}
						<Link to="/login/signin">
							<p>{i18n.t('login:reset_password.links.back_to_signin')}</p>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPasswordPage;
