// Import translation
import i18n from 'i18next';

// Import helpers
import {
	PERCENTAGE,
	DISCOUNT,
	STATIC_PRICE
} from 'components/helpers/promotion_helpers/helpers';

export const select_promotion_types = [
	{
		name: i18n.t('promotions:form:select_values:promotion_type.percentage'),
		value: PERCENTAGE
	},
	{
		name: i18n.t('promotions:form:select_values:promotion_type.discount'),
		value: DISCOUNT
	},
	{
		name: i18n.t('promotions:form:select_values:promotion_type.static_price'),
		value: STATIC_PRICE
	}
];
