import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH PROVISIONING - TABLE DATA ********************
export const fetchProvisioning = (options) => async (dispatch, getState) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_PROVISIONING_LOADING,
			payload: true
		});

		const {
			provisioning: {
				table: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `/packet/list?type=provision&${filterUrlQuery}${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex,
			true
		)}`;

		const { data } = await services.get(url);
		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_PROVISIONING_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_PROVISIONING_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE PROVISIONING_ITEM ********************
export const deleteProvisioning = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DELETE_PROVISIONING_ITEM_LOADING,
			payload: true
		});

		await services.delete(`/packet/${id}`);
		// Dispatch an action with data
		dispatch({
			type: types.DELETE_PROVISIONING_ITEM_SUCCESS,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DELETE_PROVISIONING_ITEM_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET SEARCH QUERY ********************
export const setProvisioningQuery = (query) => ({
	type: types.SET_PROVISIONING_SEARCH_QUERY,
	payload: query
});
