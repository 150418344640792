import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	loading: true,
	error: false,
	data: ''
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR DATA ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// ****************** FETCH VOD ENCODING ******************
			case types.FETCH_VOD_ADI_LOADING:
				draft.loading = true;
				draft.error = false;
				return;

			case types.FETCH_VOD_ADI_SUCCESS:
				draft.loading = false;
				draft.data = action.payload;
				return;

			case types.FETCH_VOD_ADI_ERROR:
				draft.error = action.payload;
				return;

			case types.FETCH_VOD_ADI_404_ERROR:
				draft.data = null;
				return;

			default:
				return state;
		}
	});
