import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import i18n from 'i18next';

// Import components
import { InputLabelLogin } from 'components/common/inputs';

const Fields = ({ submitting }) => (
	<React.Fragment>
		<div className="form-group">
			<Field
				component={InputLabelLogin}
				name="password"
				type="password"
				placeholder={i18n.t('login:reset_password:fields.password_placeholder')}
				className="form-control"
				required={true}
				label={i18n.t('login:reset_password:fields.password_placeholder')}
			/>
			<Field
				component={InputLabelLogin}
				name="password_confirmation"
				type="password"
				placeholder={i18n.t(
					'login:reset_password:fields.password_confirmation_placeholder'
				)}
				className="form-control"
				required={true}
				label={i18n.t(
					'login:reset_password:fields.password_confirmation_placeholder'
				)}
			/>
		</div>
	</React.Fragment>
);

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
