export const CATCHUP_RECORDING_REQUIRED_OPTIONS = [
	{
		name: 'false',
		value: '0'
	},
	{
		name: 'true',
		value: '1'
	}
];
