import React from 'react';
import PropTypes from 'prop-types';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import utilities
import { renderFields } from 'components/utilities/form';

const Fields = ({ submitting, roles, systems, isEdit }) => {
	const selectResources = { roles, systems };

	return (
		<>
			{BASE_FIELDS(isEdit).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	roles: PropTypes.array.isRequired,
	systems: PropTypes.array.isRequired,
	isEdit: PropTypes.bool.isRequired
};

export default Fields;
