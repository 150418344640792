import React from 'react';
import { bool, object, func } from 'prop-types';
import { connect } from 'react-redux';
import {
	fetchSoftware,
	deleteSoftware,
	setSoftwareQuery,
	dictionaryPlatformsTypes,
	createFiltersSuggestions
} from 'store/actions';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './software_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

function Software({
	error,
	resources,
	fetchSoftware,
	deleteSoftware,
	setSoftwareQuery,
	dictionaryPlatformsTypes,
	createFiltersSuggestions,
	filters
}) {
	const { t } = useTranslation();

	// Actions to fetch data for filters
	const filterActions = {
		dictionaryPlatformsTypes
	};

	// execute filters logic
	useFilterLogic({
		filterActions,
		createFiltersSuggestions,
		filters
	});

	const { columns } = resources;
	return (
		<PanelPageTemplate
			error={error}
			type="software"
			addLinkText={t('software:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchSoftware}
			deleteItemAction={deleteSoftware}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setSoftwareQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
}

Software.propTypes = {
	error: bool.isRequired,
	resources: object.isRequired,
	fetchSoftware: func.isRequired,
	deleteSoftware: func.isRequired,
	setSoftwareQuery: func.isRequired,
	dictionaryPlatformsTypes: func.isRequired,
	createFiltersSuggestions: func.isRequired,
	filters: object
};

const mapStateToProps = ({ software, dictionary: { platformTypes } }) => ({
	error: software.table.error,
	resources: software.table,
	filters: {
		platform: platformTypes
	}
});

export default connect(mapStateToProps, {
	fetchSoftware,
	deleteSoftware,
	setSoftwareQuery,
	dictionaryPlatformsTypes,
	createFiltersSuggestions
})(Software);
