// remove any special characters and leave only digits separated by comma
// if empty value return null
export const formatList = (data) => {
	// replace all non digit characters and multiple commas
	const formattedData = data.replace(/[^\d,]/gi, '').replace(/,+/g, ',');
	return formattedData.length ? formattedData : '';
};

// Remove whitespace and multiple commas
export const formatLoginList = (data) => {
	const formattedData = data.replace(/\s/g, '').replace(/,+/g, ',');
	return formattedData.length ? formattedData : '';
};
