import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

// Import hooks
import useSelectSystem from './useSelectSystem';

const { Option } = Select;

const SelectSystem = () => {
	const { t } = useTranslation();

	const { onChangeSystem, currentSystem, subSystems } = useSelectSystem();

	return (
		<div className="d-flex">
			<p className="mt-2 mr-2">{t('systems:select_system')}:</p>
			<Select
				defaultValue={currentSystem}
				onChange={onChangeSystem}
				className="m-r-sm"
				style={{ width: '150px' }}
			>
				{subSystems.map(({ name, value }) => (
					<Option value={value} key={value}>
						{name}
					</Option>
				))}
			</Select>
		</div>
	);
};

export default SelectSystem;
