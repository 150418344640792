import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setGeolocksQuery,
	fetchGeolocks,
	deleteGeolock,
	dictionaryIpRangeTypes,
	createFiltersSuggestions
} from 'store/actions';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './geolocks_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const Geolocks = ({
	error,
	setGeolocksQuery,
	fetchGeolocks,
	deleteGeolock,
	resources,
	dictionaryIpRangeTypes,
	createFiltersSuggestions,
	filters
}) => {
	const { t } = useTranslation();

	// Actions to fetch data for filters
	const filterActions = {
		dictionaryIpRangeTypes
	};

	// execute filters logic
	useFilterLogic({
		filterActions,
		createFiltersSuggestions,
		filters
	});

	const { columns } = resources;

	return (
		// Dispatch fetchGeolocks, deleteGeolock action in geolocks_panel
		<PanelPageTemplate
			title={t('geolocks:title')}
			error={error}
			type="geolocks"
			addLinkText={t('geolocks:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchGeolocks}
			deleteItemAction={deleteGeolock}
			notificationDeleteSuccessTxt={t('geolocks:notification.delete_success')}
			notificationDeleteErrorTxt={t('geolocks:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setGeolocksQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Geolocks.propTypes = {
	error: PropTypes.bool.isRequired,
	setGeolocksQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchGeolocks: PropTypes.func.isRequired,
	deleteGeolock: PropTypes.func.isRequired,
	dictionaryIpRangeTypes: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	filters: PropTypes.object
};

const mapStateToProps = (state) => ({
	error: state.geolocks.table.error,
	resources: state.geolocks.table,
	filters: { type: state.dictionary.ipRangeTypes }
});

export default connect(mapStateToProps, {
	setGeolocksQuery,
	fetchGeolocks,
	deleteGeolock,
	dictionaryIpRangeTypes,
	createFiltersSuggestions
})(Geolocks);
