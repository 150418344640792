// Import translation
import i18n from 'i18next';

export default ({ device_sn_id }) => {
	const errors = {};
	// ------------------ Device ID ------------------
	if (!device_sn_id) {
		errors.device_sn_id = i18n.t(
			'subscribers:devices.validation.device_sn_id_required'
		);
	}

	return errors;
};
