import { useState } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

// Import services
import services from 'services/services';

// Import helpers
import { convertLocationType } from '../tabs/helpers/helpers';
import { getLocationType } from 'components/helpers/convert';
import {
	checkIsAvailability,
	checkIsPrices
} from 'components/helpers/pathname';
import { notificationHandler } from '../notifications';

const useScheduleActivity = ({ id, isActive }) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	const [active, setActive] = useState(isActive);
	const [isLoading, setIsLoading] = useState(false);

	const isAvailability = checkIsAvailability(pathname);
	const isPrices = checkIsPrices(pathname);

	const locationType = getLocationType(pathname);

	const type = convertLocationType(
		locationType,
		false,
		isAvailability,
		isPrices
	);

	const handleChangeActivity = async ({ target: { checked } }) => {
		setIsLoading(true);

		const resources = {
			active: checked,
			availability_entry_id: id
		};

		try {
			const url = `${type}/${id}/systemFields`;

			await services.post(url, resources);

			setIsLoading(false);
			setActive(checked);

			notificationHandler(
				t('messages:notifications.edited'),
				`${t('messages:notifications.successfuly_edited')} ${t(
					`names:notification_names.activity`
				)}`
			);
		} catch (error) {
			setIsLoading(false);

			notificationHandler(
				t('messages:notifications.error'),
				t('common:errors.common_error_message'),
				'error'
			);
		}
	};

	return { active, isLoading, onChangeActivity: handleChangeActivity };
};

export default useScheduleActivity;
