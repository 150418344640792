// Import translation
import i18n from 'i18next';

// Validate the form
const validate = ({ password, password_confirmation }) => {
	const errors = {};

	// ------------------ Password ------------------
	if (!password) {
		errors.password = i18n.t('login:validation.password_required');
	} else if (password && (password.length < 6 || password.length > 100)) {
		errors.password = i18n.t('login:validation.password_length');
	}

	if (
		password !== '' &&
		password_confirmation !== '' &&
		password !== password_confirmation
	) {
		errors.password = i18n.t('login:validation.passwords_not_same');
		errors.password_confirmation = i18n.t(
			'login:validation.passwords_not_same'
		);
	}
	return errors;
};

export default validate;
