import services from 'services/services';
import * as types from 'store/actions/types';

// Import helpers
import { checkFetchErrors } from 'store/actions/helpers_actions/convert_helpers';

// ******************** FETCH INGESTER HISTORY - VOD DETAILS DATA ********************
export const fetchIngesterHistoryVodDetails = (vodId) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_INGESTER_HISTORY_VOD_DETAILS_LOADING
		});

		const url = `ingester/product/${vodId}/history`;

		const { data } = await services.get(url);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_INGESTER_HISTORY_VOD_DETAILS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_INGESTER_HISTORY_VOD_DETAILS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** REFRESH INGESTER HISTORY ********************
export const refreshIngesterHistory = () => (dispatch) =>
	dispatch({
		type: types.REFRESH_INGESTER_HISTORY
	});
