// Import translation
import i18n from 'i18next';
import moment from 'moment';

// Import helpers
import {
	switchInfo,
	PERCENTAGE
} from 'components/helpers/promotion_helpers/helpers';

export const defaultFieldValues = {
	description: null,
	active: false,
	promotions_period: null,
	promotion_type: PERCENTAGE,
	promotion_value: 0,
	since: null,
	till: null,
	active_obox: false,
	active_ott: false,
	active_tvonline: false
};

export const BASE_FIELDS = ({ isEdit, currentValues }) => [
	{
		name: 'name',
		label: i18n.t('promotions:form:fields.name'),
		type: 'text',
		required: true
	},
	{
		name: 'description',
		label: i18n.t('promotions:form:fields.description'),
		type: 'textarea',
		required: false
	},
	{
		name: 'active',
		label: i18n.t('promotions:form:fields.active'),
		type: 'checkbox',
		required: false
	},
	{
		// Promotion period is field that handles since and till dates in single date range picker
		name: 'promotion_period',
		label: i18n.t('promotions:form:fields.promotion_period'),
		placeholder: [
			i18n.t('promotions:form:placeholder.promotion_period_since'),
			i18n.t('promotions:form:placeholder.promotion_period_till')
		],
		type: 'range',
		required: true,
		showTime: {
			defaultValue: [
				moment('00:00:00', 'HH:mm:ss'),
				moment('23:59:59', 'HH:mm:ss')
			]
		},
		rangeFormat: 'YYYY-MM-DD HH:mm:ss'
	},
	{
		name: 'promotion_type',
		label: i18n.t('promotions:form:fields.promotion_type'),
		type: 'select',
		required: false,
		fieldInfo: i18n.t(`${switchInfo(currentValues['promotion_type'])}`)
	},
	{
		name: 'promotion_value',
		label: i18n.t('promotions:form:fields.promotion_value'),
		type: 'number',
		required: false
	},
	// only visible when editing existing promotion
	{
		name: 'created_at',
		label: i18n.t('promotions:form:fields.created_at'),
		type: 'text',
		required: false,
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'updated_at',
		label: i18n.t('promotions:form:fields.updated_at'),
		type: 'text',
		required: false,
		disabled: true,
		isVisible: !isEdit
	}
];
