import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

// Import custom hooks
import useFilterDrawerFormLogic from './useFiltersDrawerFormLogic';

// Import components
import Fields from './fields/Fields';
import FilterFormButtons from 'components/common/buttons/FilterFormButtons';

/**
 * Functional component that creates form with buttons for table filters
 * @param {func} toggle - toggle function switches drawer visibility
 * @returns {*}
 */
const FiltersDrawerForm = ({ toggle }) => {
	const {
		handleOnSubmit,
		handleOnCancel,
		handleClear,
		tableType,
		filterFormValues,
		filtersSuggestions
	} = useFilterDrawerFormLogic({ toggle });

	return (
		<Form
			initialValues={filterFormValues}
			onSubmit={handleOnSubmit}
			render={({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit}>
					<Fields
						submitting={submitting}
						tableType={tableType}
						filtersSuggestions={filtersSuggestions}
					/>
					<FilterFormButtons
						submitting={submitting}
						handleOnCancel={handleOnCancel}
						handleClear={handleClear}
					/>
				</form>
			)}
		/>
	);
};

FiltersDrawerForm.propTypes = {
	toggle: PropTypes.func.isRequired
};

export default FiltersDrawerForm;
