import i18n from 'i18next';
import moment from 'moment';

// Import helpers
import { MESSAGE_TYPES } from 'components/helpers/variables';
import { imageRequirements } from '../../helpers';

const { TEXT, WEBVIEW, INTERNAL, EXTERNAL } = MESSAGE_TYPES;

const layout = { labelColumn: 3, inputColumn: 9 };

export const defaultFieldValues = {
	campaign_range: null,
	notification_title: null,
	message_text: null,
	message_type: TEXT,
	notification_img: null,
	notification_enabled: true,
	notification_sound: false,
	notification_end: null,
	notification_autoclose: 0,
	notification_onstart: false,
	message_img: null,
	message_video: false,
	notification_body: '',
	message_scale: 0
};

export const BASE_FIELDS = ({ messageType, isEdit }) => [
	{
		name: 'campaign_range',
		label: i18n.t('marketing_campaigns:form:fields.campaign_range'),
		placeholder: [
			i18n.t('marketing_campaigns:form:fields.campaign_since'),
			i18n.t('marketing_campaigns:form:fields.campaign_till')
		],
		type: 'range',
		required: true,
		showTime: {
			defaultValue: [
				moment('00:00:00', 'HH:mm:ss'),
				moment('23:59:59', 'HH:mm:ss')
			]
		},
		rangeFormat: 'YYYY-MM-DD HH:mm:ss',
		...layout
	},
	{
		name: 'notification_title',
		label: i18n.t('marketing_campaigns:form:fields.notification_title'),
		type: 'text',
		maxLength: 256,
		required: true,
		...layout
	},
	{
		name: 'message_text',
		label: i18n.t('marketing_campaigns:form:fields.notification_text'),
		type: 'textarea',
		style: { height: 160 },
		maxLength: 1024,
		required: true,
		...layout
	},
	{
		name: 'message_type',
		label: i18n.t('marketing_campaigns:form:fields.message_types'),
		type: 'select',
		required: true,
		...layout
	},
	...getMessageTypeFields(messageType),
	{
		name: 'notification_img',
		label: i18n.t('marketing_campaigns:form:fields.notification_img'),
		type: 'imageUrl',
		required: false,
		requirements: imageRequirements,
		...layout
	},
	{
		name: 'notification_enabled',
		label: i18n.t('marketing_campaigns:form:fields.notification_enabled'),
		type: 'checkbox',
		required: false,
		...layout
	},
	{
		name: 'notification_sound',
		label: i18n.t('marketing_campaigns:form:fields.notification_sound'),
		type: 'checkbox',
		required: false,
		...layout
	},
	{
		name: 'notification_end',
		label: i18n.t('marketing_campaigns:form:fields.notification_till'),
		placeholder: i18n.t(
			'marketing_campaigns:form:fields.notification_end_date'
		),
		type: 'date',
		required: true,
		showTime: {
			defaultValue: moment('23:59:59', 'HH:mm:ss')
		},
		rangeFormat: 'YYYY-MM-DD HH:mm:ss',
		...layout
	},
	{
		name: 'notification_autoclose',
		label: i18n.t('marketing_campaigns:form:fields.notification_autoclose'),
		type: 'number',
		required: false,
		...layout
	},
	{
		name: 'notification_onstart',
		label: i18n.t('marketing_campaigns:form:fields.notification_onstart'),
		type: 'checkbox',
		required: false,
		...layout
	},
	{
		name: 'message_img',
		label: i18n.t('marketing_campaigns:form:fields.message_img'),
		type: 'imageUrl',
		required: false,
		requirements: imageRequirements,
		...layout
	},
	{
		name: 'created_at',
		label: i18n.t('promotions:form:fields.created_at'),
		type: 'date',
		required: false,
		disabled: true,
		isVisible: !isEdit,
		showTime: true,
		...layout
	},
	{
		name: 'updated_at',
		label: i18n.t('promotions:form:fields.updated_at'),
		type: 'date',
		required: false,
		disabled: true,
		isVisible: !isEdit,
		showTime: true,
		...layout
	}
];

const WEBVIEW_FIELDS = [
	{
		name: 'message_deeplink',
		label: i18n.t('marketing_campaigns:form:fields.message_deeplink'),
		type: 'text',
		required: true,
		maxLength: 256,
		...layout
	},
	{
		name: 'message_video',
		label: i18n.t('marketing_campaigns:form:fields.message_video'),
		type: 'checkbox',
		required: false,
		...layout
	},
	{
		name: 'message_scale',
		label: i18n.t('marketing_campaigns:form:fields.message_scale'),
		fieldInfo: i18n.t('marketing_campaigns:info.message_scale'),
		type: 'number',
		required: true,
		...layout
	}
];

const INTERNAL_FIELDS = [
	{
		name: 'message_deeplink',
		label: i18n.t('marketing_campaigns:form:fields.message_deeplink'),
		type: 'deeplink',
		required: true,
		maxLength: 256,
		fieldInfo: i18n.t('marketing_campaigns:info.message_deeplink_generate'),
		...layout
	}
];

const EXTERNAL_FIELDS = [
	{
		name: 'message_deeplink',
		label: i18n.t('marketing_campaigns:form:fields.message_deeplink'),
		type: 'text',
		required: true,
		maxLength: 256,
		...layout
	}
];

const getMessageTypeFields = (messageType) => {
	switch (messageType) {
		case WEBVIEW:
			return WEBVIEW_FIELDS;
		case INTERNAL:
			return INTERNAL_FIELDS;
		case EXTERNAL:
			return EXTERNAL_FIELDS;
		default:
			return [];
	}
};
