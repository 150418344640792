import React from 'react';
import i18n from 'i18next';

// Import helpers
import { setFormLink } from 'components/helpers/link_selector';

// Import components
import DeleteButton from 'components/utilities/delete_button/DeleteButton';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('channels:table_columns.logo_url'),
		accessor: 'images',
		sortable: false,
		width: 100,
		Cell: ({
			row: {
				_original: { uuid, type, image }
			}
		}) => (
			<React.Fragment>
				{image ? setFormLink({ uuid, type, image }) : <span>---</span>}
			</React.Fragment>
		)
	},
	{
		Header: i18n.t('channels:table_columns.name'),
		accessor: 'title',
		Cell: ({
			row: {
				_original: { uuid, type, title }
			}
		}) => setFormLink({ uuid, type, title })
	},
	{
		Header: i18n.t('channels:table_columns.created_at'),
		accessor: 'created_at'
	},
	{
		Header: i18n.t('channels:table_columns.updated_at'),
		accessor: 'updated_at'
	},

	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { uuid, system_id }
			}
		}) => (
			<DeleteButton
				id={uuid}
				productSystem={system_id}
				onSelectItem={handleSelectItem}
				isReadOnly={isReadOnly}
			/>
		)
	}
];

export const selectOptionColumns = [
	{
		header: i18n.t('common:search_box.all_columns'),
		name: ''
	},
	{
		header: i18n.t('channels:table_columns.name'),
		name: 'title'
	},
	{
		header: i18n.t('channels:table_columns.created_at'),
		name: 'created_at'
	},
	{
		header: i18n.t('channels:table_columns.updated_at'),
		name: 'updated_at'
	}
];
