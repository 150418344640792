// Import translation
import i18n from 'i18next';

// Cookies
export const COOKIE_USER_TOKEN = 'token';
export const COOKIE_USER_SYSTEM = 'system';

// Blueonline main system
export const BLUEONLINE_MAIN_SYSTEM = 'main';

// Ingester stat types (assets | encoding)
export const ASSETS = 'assets';
export const ENCODING = 'encoding';

export const VOD_SUBTYPES_VALUES = {
	ALL: 'all',
	VOD: 'vod',
	SERIES: 'series',
	SEASON: 'season',
	EPISODE: 'episode'
};

// list of select elementd to filter VOD table with
export const VOD_SUBTYPES = Object.values(VOD_SUBTYPES_VALUES).map((value) => ({
	name: i18n.t(`vod:select:subtypes.${value}`),
	value
}));

export const VOD_STATUS_VALUES = {
	ALL: 'all',
	NEW: 'new',
	ENCODED: 'encoded',
	COMPLETE: 'complete',
	ACTIVE: 'active',
	EXPIRED: 'expired',
	DELETED: 'deleted'
};

export const VOD_STATUS = Object.values(VOD_STATUS_VALUES).map((status) => ({
	name: i18n.t(`vod:select:status.${status}`),
	value: status
}));

// Names of platforms
export const PLATFORM_NAMES = {
	STB: 'STB'
};

// list of select elementd to filter Devices table
export const DEVICES_PLATFORM = () => [
	{ name: i18n.t('vod:select:status.all'), value: 'all' },
	{ name: 'STB', value: 'STB' },
	{ name: 'IOS', value: 'IOS' },
	{ name: 'ANDROID', value: 'ANDROID' },
	{ name: 'BROWSER', value: 'BROWSER' },
	{ name: 'BOX', value: 'BOX' }
];

export const TARGET_TYPES = {
	SN: 'serial_number',
	LOGIN: 'login',
	ID: 'id'
};

export const TARGET_STATUS = {
	READY: 'ready',
	SENT: 'sent',
	QUEUED: 'queued',
	FAILED: 'failed'
};

// provisioning status
export const PROVISIONING_STATUS = [
	'new',
	'encoded',
	'complete',
	'active',
	'expired'
];

// attach episodes modal list length
export const EPISODES_MODAL_SEARCH_LENGTH = 100;

// add products (Product Select) vod list length
export const PRODUCT_SELECT_VOD_LIST_LENGTH = 100;

// Packets types options for select type field in provisioning, main and collections
export const select_packet_types = [
	{
		name: 'PROVISION',
		value: 'provision'
	},
	{
		name: 'COLLECTION',
		value: 'collection'
	},
	{
		name: 'MAIN',
		value: 'main'
	},
	{
		name: 'PREMIUM',
		value: 'premium'
	}
];

export const PACKETS_CONFIGURATOR = {
	MAIN: 'main',
	PREMIUM: 'premium'
};

// List of table types for which export to csv option is disabled
// Names are based on url types
export const disabledCSVExportList = [
	'tracking',
	'trackings',
	'affiliate_partners'
];

// Products limit in Section Preview
export const SECTION_PREVIEW_LIMIT = 100;

// Products limit in Products Preview
export const PROMOTIONS_PRODUCTS_PREVIEW_LIMIT = 100;

// Products limit in Packets Products List
export const PACKETS_PRODUCTS_LIST_LIMIT = 100;

// Promotion Products types
export const PROMOTION_PRODUCTS_IN = 'in';
export const PROMOTION_PRODUCTS_OUT = 'out';
// materials table limits
export const CHANNELS_MATERIALS_LIMIT = 4;
export const VOD_MATERIALS_LIMIT = 3;

// language local storage value
export const LOCAL_STORAGE_LANGUAGE = 'language';

// ------------ SYSTEMS ------------
// system types
export const SYSTEM_TYPES = {
	ott: 'ott',
	tvonline: 'tvonline',
	obox: 'obox',
	main: 'main',
	pureott: 'pureott'
};
// system types custom names
export const SYSTEM_CUSTOM_NAMES = {
	[SYSTEM_TYPES.ott]: 'OTT',
	[SYSTEM_TYPES.tvonline]: 'DTV',
	[SYSTEM_TYPES.obox]: 'OBOX'
};

// array of systems defined in env file
export const SYSTEM_TYPES_FROM_ENV =
	process.env.REACT_APP_SYSTEMS.split(',') ?? [];

// ----------------------------------

// ------------ PLATFORM ------------
// platform types
export const PLATFORM_TYPES = {
	all: 'all',
	platformPrimary: 'platform_primary',
	platformSecondary: 'platform_secondary'
};
// ----------------------------------

// blackout variables for generating EPG table
export const BLACKOUT_TYPES = ['streaming', 'catchup', 'npvr'];
export const BLACKOUT_PLATFORMS = [
	{ name: 'BOX', value: 'primary' },
	{ name: 'Secondary Screens', value: 'secondary' }
];

// ------------ SETTINGS ------------
// block settings sections with these names
export const SETTINGS_TO_BLOCK_IN_OBOX = ['ESALES', 'NETFLIX'];
// block settings fields with these names
export const SETTINGS_FIELDS_TO_BLOCK_IN_OBOX = [
	'PLAYER_MULTICAST ESALES INTERNET_DEVICE_MODELS',
	'MULTICAST ESALES INTERNET_DEVICE_MODELS'
];
// ----------------------------------

// ------------ PAYMENT ------------
export const PAYMENT_TYPES = {
	CARD: 'card',
	EXTERNAL: 'external',
	INVOICE: 'invoice',
	BLUEMEDIA: 'bluemedia',
	SUBSCRIPTION: 'subscription_bluemedia',
	SUBSCRIPTION_APPSTORE: 'subscription_appstore',
	SUBSCRIPTION_GOOGLEPLAY: 'subscription_googleplay',
	GOOGLE_PLAY: 'googleplay',
	APPLE: 'appstore'
};

export const SUBSCRIPTION_PAYMENT_TYPES = [
	PAYMENT_TYPES.SUBSCRIPTION,
	PAYMENT_TYPES.SUBSCRIPTION_APPSTORE,
	PAYMENT_TYPES.SUBSCRIPTION_GOOGLEPLAY
];

// ----------------------------------

export const PAYMENT_TEMPLATE_TYPES = {
	CARD: 'card',
	INVOICE: 'invoice',
	OFFER: 'offer',
	APPSTORE: 'appstore',
	GOOGLEPLAY: 'googleplay',
	BLUEMEDIA: 'bluemedia',
	SUBSCRIPTION_APPSTORE: 'subscription_appstore',
	SUBSCRIPTION_GOOGLEPLAY: 'subscription_googleplay',
	SUBSCRIPTION_BLUEMEDIA: 'subscription_bluemedia'
};

// ------------ SUBSCRIBERS ------------
export const SUBSCRIBER_SOURCE_TYPES = {
	PURE_OTT: 'pureott'
};

// ------------ CORE PATHS ------------
export const CORE_PATHS = {
	AVAILABILITY: 'availability',
	PRICES: 'prices',
	METADATA: 'metadata',
	EPG: 'epg',
	PICTURES: 'pictures',
	MATERIALS: 'materials',
	PROMOTION_PRODUCTS_OUT_PREVIEW: 'promotions_products_out_preview',
	PAYMENTS: 'payments',
	PACKETS_ASSIGNED: 'packets_assigned',
	PRODUCTS: 'products',
	LIMITS: 'limits',
	PROVISIONING: 'provisioning',
	DOCUMENTS: 'documents',
	AGREEMENTS: 'agreements',
	STATIC_ELEMENTS: 'static-elements',
	CATEGORIES: 'categories',
	PROMOTIONS_USERS: 'promotions_users',
	PROMOTIONS_PRODUCTS_OUT: 'promotions_products_out',
	VOD: 'vod',
	CHANNELS: 'channels',
	PACKETS: 'packets',
	SECTIONS: 'sections',
	SECTIONS_GROUPS: 'sections_groups',
	BANNERS: 'banners',
	MAIN: 'main',
	PROMOTIONS: 'promotions',
	EPISODES: 'episodes',
	DELETE: 'delete',
	ADD_TO: 'add_to',
	DELETE_FROM: 'delete_from',
	SECTIONS_ASSIGNED: 'sections-assigned',
	COUPONS: 'coupons',
	SYSTEM_SETTINGS: 'system_settings'
};

export const VERIFICATION = {
	VERIFIED: 'verified',
	IN_PROGRESS: 'being_verified',
	UNVERIFIED: 'unverified',
	NOTHING_TO_VERIFY: 'nothing_to_verify'
};

// ------------ LAST SYSTEM DEPTH ------------
export const LAST_SYSTEM_DEPTH = 2;

// ----------- VECTRA SYSTEMS ----------------
export const VECTRA_SYSTEMS = [SYSTEM_TYPES.ott, SYSTEM_TYPES.tvonline];

// ----------- DOCUMENT FILE UPLOADS (EDIT FORM)----------------
export const FILE_DATA = {
	MODEL_TYPE: 'document',
	COLLECTION: 'document'
};
// ----------- SUBSCRIBER TYPES (INVOICES)----------------
export const SUBSCRIBER = {
	INDIVIDUAL: 'individual',
	BUSINESS: 'business'
};

export const MATERIALS_TYPES = {
	HLS: 'hls',
	DASH: 'dash',
	SS: 'ss',
	MULTICAST: 'multicast',
	FILE: 'file',
	DVB: 'dvb'
};

// ----------- SUBSCRIBER PACKETS ----------------
export const SUBSCRIBER_PACKETS_PAYMENT_STATUS = {
	PENDING: 'pending',
	SUCCEEDED: 'succeeded',
	FAILED: 'failed'
};

export const PROVISIONING_MAX_PERIOD_VALUE = 9999999;

//Topology file types
export const TOPOLOGY_FILES = {
	TOPOLOGY: 'topology',
	PORTS: 'ports'
};

export const LOCATION_TYPES = {
	VOD: 'vod',
	STATISTICS: 'statistics',
	EPG_GLOBAL: 'epg_global'
};

// ----------- TABLE TYPES ----------------
export const TABLE_TYPES = {
	LOCAL_CHANNELS: 'localChannels',
	EPG_GLOBAL: 'epg_global'
};

// -------- VOD PRODUCTIVE STB ----------
export const CHILDREN_KEY_SUBSTITUTE = 'assigned_children';

// -------- PRODUCT TYPES ----------
export const CHANNEL = 'channel';
export const VOD = 'vod';

export const PRODUCT_TYPES = {
	CHANNEL: 'channel',
	VOD: 'vod',
	COLLECTION: 'collection',
	MAIN: 'main',
	PREMIUM: 'premium',
	SECTION: 'section',
	BANNER: 'banner',
	PROVISIONING: 'provisioning',
	PROVIDER: 'provider',
	CATCHUP: 'catchup',
	PROGRAM: 'program',
	PACKET: 'packet'
};

export const PRODUCT_SUBTYPES = {
	EPISODE: 'episode',
	SEASON: 'season',
	SERIES: 'series'
};

// -------- TAGS ----------
export const TAGS_TYPES = {
	TEXT: 'TEXT',
	ICON: 'ICON',
	HIDDEN: 'HIDDEN'
};
export const TAGS_CATEGORIES = {
	VOD: 'VOD',
	DEFAULT: 'DEFAULT'
};

// -------- Marketing campaigns ---------
export const MARKETING_CAMPAIGNS_STATUS_VALUES = {
	ALL: 'all',
	READY: 'ready',
	SENT: 'sent',
	DELETED: 'deleted'
};

// list of select elementd to filter VOD table with
export const MARKETING_CAMPAIGNS_STATUS = Object.values(
	MARKETING_CAMPAIGNS_STATUS_VALUES
).map((value) => ({
	name: i18n.t(`marketing_campaigns:select:status.${value}`),
	value
}));

export const MIMES = {
	PNG: 'image/png',
	JPG: 'image/jpg',
	JPEG: 'image/jpeg',
	SVG: 'image/svg+xml',
	AVIF: 'image/avif',
	GIF: 'image/gif',
	WEBP: 'image/webp'
};

export const RASTER_IMAGE_MIMES = [
	MIMES.PNG,
	MIMES.JPEG,
	MIMES.JPG,
	MIMES.WEBP,
	MIMES.GIF
];

export const VECTOR_IMAGE_MIMES = [MIMES.SVG];

export const IMAGE_MIMES = [...RASTER_IMAGE_MIMES, ...VECTOR_IMAGE_MIMES];

export const MESSAGE_TYPES = {
	TEXT: 'text',
	WEBVIEW: 'webview',
	INTERNAL: 'internal',
	EXTERNAL: 'external'
};

export const MESSAGE_TYPES_VALUES = Object.values(MESSAGE_TYPES).map(
	(value) => ({
		name: i18n.t(`marketing_campaigns:select:message_types.${value}`),
		value
	})
);

export const MEDIA_TYPES = {
	VOD: 'vod',
	SERIES: 'series',
	SEASON: 'season',
	EPISODE: 'episode',
	CHANNEL: 'channel',
	CATCHUP: 'catchup',
	BOX: 'profil_box'
};

export const MEDIA_TYPES_VALUES = Object.values(MEDIA_TYPES).map((value) => ({
	name: i18n.t(`marketing_campaigns:select:media_types.${value}`),
	value
}));

export const TARGET_PLATFORMS = DEVICES_PLATFORM()
	?.filter(({ value }) => value !== 'all')
	?.reverse();

export const TARGET_TYPES_VALUES = Object.entries(TARGET_TYPES).map(
	([name, value]) => ({
		name,
		value
	})
);

export const REQUIRED_MARK = '*';

// ------------ AFFILIATE PARTNERS ------------
export const AFFILIATE_PARTNER_CODE_LENGTH = 10;

export const PACKETS_PROVIDERS = [
	{ name: i18n.t('common:none'), value: 'none' },
	{ name: 'HBO', value: 'HBO' }
];
