import React from 'react';
import { useDispatch } from 'react-redux';

// Import actions
import {
	fetchPacketCover,
	fetchPacketPoster,
	fetchPacketCoverLarge,
	fetchPacketTitle
	// fetchPacketCoverSmall,
	// fetchPacketCoverDetail,
	// fetchPacketCoverDetailLarge
} from 'store/actions';

// Import components
import Image from 'components/utilities/images/Image';
import InfoTitleCollapse from 'components/utilities/info/InfoTitleCollapse';

// Import helpers
import {
	PACKETS_IMAGE_SIZE_INFO,
	PACKETS_IMAGES_REQUIREMENTS
} from 'components/helpers/info_helpers/info_texts';

// Import translation
import { useTranslation } from 'react-i18next';

const PacketsImages = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	return (
		<>
			<InfoTitleCollapse
				title={PACKETS_IMAGE_SIZE_INFO}
				requirements={PACKETS_IMAGES_REQUIREMENTS}
			/>
			<Image
				title={t('common:images.cover')}
				fetchResourcesAction={(id) => fetchPacketCover(id)(dispatch)}
				collectionType="cover"
				mediaType="packet"
				storePathSelector="packets"
			/>
			<Image
				title={t('common:images.cover_large')}
				fetchResourcesAction={(id) => fetchPacketCoverLarge(id)(dispatch)}
				collectionType="cover_large"
				mediaType="packet"
				storePathSelector="packets"
			/>
			<Image
				title={t('common:images.poster')}
				fetchResourcesAction={(id) => fetchPacketPoster(id)(dispatch)}
				collectionType="poster"
				mediaType="packet"
				storePathSelector="packets"
			/>
			<Image
				title={t('common:images.title')}
				fetchResourcesAction={(id) => fetchPacketTitle(id)(dispatch)}
				collectionType="title"
				mediaType="packet"
				storePathSelector="packets"
			/>
			{/*
			<Info content={info_txt.cover_small} iconStyle={{ top: '18px' }}>
				<Image
					title={t('common:images.cover_small')}
					fetchResourcesAction={(id) => fetchPacketCoverSmall(id)(dispatch)}
					collectionType="cover_small"
					mediaType="packet"
					storePathSelector="packets"
				/>
			</Info>
			<Info content={info_txt.cover_detail} iconStyle={{ top: '18px' }}>
				<Image
					title={t('common:images.cover_detail')}
					fetchResourcesAction={(id) => fetchPacketCoverDetail(id)(dispatch)}
					collectionType="cover_detail"
					mediaType="packet"
					storePathSelector="packets"
				/>
			</Info>
			<Info content={info_txt.cover_detail_large} iconStyle={{ top: '18px' }}>
				<Image
					title={t('common:images.cover_detail_large')}
					fetchResourcesAction={(id) =>
						fetchPacketCoverDetailLarge(id)(dispatch)
					}
					collectionType="cover_detail_large"
					mediaType="packet"
					storePathSelector="packets"
				/>
			</Info> */}
		</>
	);
};

export default PacketsImages;
