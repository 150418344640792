import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	loading: true,
	error: false,
	data: [],
	columns: [],
	deleteItem: {
		success: false,
		error: false
	},
	options: {
		pages: 0,
		page: 0,
		total_results: 0,
		startIndex: 0,
		sorted: undefined,
		filters: {
			query: '',
			type: '', // type needed to filter data in payments table. Depends of table location (payments | subscribers | vod)
			subtype: '', // subtype is passed to link redirecting to proper vod (vod | series | season | episode)
			filterUrlQuery: ''
		}
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR DATA ******************
			case types.CLEAR_PANEL_STATE:
				return { ...INITIAL_STATE };

			// *************** FETCH PAYMENTS ***************
			case types.FETCH_PAYMENTS_LOADING:
				draft.loading = true;
				draft.error = false;
				break;

			case types.FETCH_PAYMENTS_SUCCESS:
				draft.loading = false;
				draft.data = action.payload.data;
				draft.columns = action.payload.input.columns;
				draft.options.pages = action.payload.pages;
				draft.options.total_results = action.payload.recordsFiltered;
				draft.options.startIndex = action.payload.startIndex;
				draft.options.page = action.payload.page;
				break;

			case types.FETCH_PAYMENTS_ERROR:
				draft.error = action.payload;
				break;

			// *************** SAVE SORTING OPTIONS ***************
			case types.SAVE_PAYMENTS_TABLE_SORTING:
				draft.options.sorted = action.payload;
				break;

			// *************** SET SEARCH QUERY ***************
			case types.SET_PAYMENTS_SEARCH_QUERY:
				draft.options.filters.query = action.payload;
				break;

			// *************** SET PAYMENTS ID TYPE ***************
			case types.SET_PAYMENTS_ID_TYPE:
				draft.options.filters.type = action.payload;
				break;

			// *************** SET PAYMENTS ID SUBTYPE ***************
			case types.SET_PAYMENTS_ID_SUBTYPE:
				draft.options.filters.subtype = action.payload;
				break;

			// --------------- FILTERS ---------------
			// *************** SET FILTER URL QUERY ***************
			case types.SET_FILTER_URL_QUERY:
				draft.options.filters.filterUrlQuery = action.payload;
				break;

			// *************** CLEAR FILTERS ***************
			case types.CLEAR_FILTERS:
				draft.options.filters.filterUrlQuery = '';
				break;

			default:
				return state;
		}
	});
