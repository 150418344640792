import React from 'react';

// Import Authorization
import RoleAuthorization from 'components/utilities/role_authorization/RoleAuthorization';
// Import roles
import {
	cms,
	crm,
	data,
	portal,
	ingestion,
	admin,
	support,
	main,
	system_operator
	// video
} from 'components/helpers/roles_variables';

// ***************** Products *****************

// --- Promotions ---
import Promotions from 'components/views/promotions/Promotions';
import PromotionsTabs from 'components/views/promotions/promotions_tabs/PromotionsTabs';

// --- Promotions ---
import Coupons from 'components/views/coupons/Coupons';
import CouponsTabs from 'components/views/coupons/coupons_tabs/CouponsTabs';

// --- Channels ---
import Channels from 'components/views/channels/Channels';
import ChannelsTabs from 'components/views/channels/channels_tabs/ChannelsTabs';
import EPGTabs from 'components/views/channels/channels_tabs/epg/epg_tabs/EPGTabs';

// --- EPG ---
import EPGGlobal from 'components/views/epg_global/EPGGlobal';

// --- Local Channels ---
import LocalChannels from 'components/views/local_channels/LocalChannels';
import LocalChannelsForm from 'components/views/local_channels/local_channels_form/LocalChannelsForm';

// --- Provisioning ---
import Provisioning from 'components/views/provisioning/Provisioning';
import ProvisioningTabs from 'components/views/provisioning/provisioning_tabs/ProvisioningTabs';

// --- Packets ---
import Packets from 'components/views/packets/Packets';
import PacketsTabs from 'components/views/packets/packets_tabs/PacketsTabs';

// --- Main ---
import Main from 'components/views/main/Main';
import MainTabs from 'components/views/main/main_tabs/MainTabs';

// --- Tags ---
import TagsTable from 'components/views/tags/TagsTable';
import TagsTabs from 'components/views/tags/tags_tabs/TagsTabs';

// --- Availability ---
import AvailabilityFormTemplate from 'components/common/templates/availability_form_template/AvailabilityFormTemplate';
import AvailabilitySettings from 'components/common/templates/availability_form_template/AvailabilitySettings';

// --- Price ---
import PriceFormTemplate from 'components/common/templates/price_form_template/PriceFormTemplate';

// --- Limits ---
import Limits from 'components/views/limits/Limits';
import LimitsTabs from 'components/views/limits/limits_tabs/LimitsTabs';

// --- Payments ---
import Payments from 'components/views/payments/Payments';

// ***************** Users *****************
// --- Subscribers ---
import Subscribers from 'components/views/subscribers/Subscribers';
import SubscribersTabs from 'components/views/subscribers/subscribers_tabs/SubscribersTabs';
// --- Devices ---
import Devices from 'components/views/devices/Devices';
// --- Devices ---
import DevicesSn from 'components/views/devices_sn/DevicesSn';
import DevicesSnTabs from 'components/views/devices_sn/devices_sn_tabs/DevicesSnTabs';

// ***************** CONTENT *****************
// --- Categories ---
import Categories from 'components/views/categories/Categories';
import CategoriesTabs from 'components/views/categories/categories_tabs/CategoriesTabs';
// --- Documents ---
import Documents from 'components/views/documents/Documents';
import DocumentsForm from 'components/views/documents/documents_form/DocumentsForm';
// --- Agreements ---
import Agreements from 'components/views/agreements/Agreements';
import AgreementsTabs from 'components/views/agreements/agreements_tabs/AgreementsTabs';
import AgreementsVersionsForm from 'components/views/agreements/agreements_versions/agreements_versions_form/AgreementsVersionsForm';
// --- VOD ---
import Vod from 'components/views/vod/Vod';
import VodTabs from 'components/views/vod/vod_tabs/VodTabs';
import VodSubtitlesForm from 'components/views/vod/vod_subtitles/vod_subtitles_form/VodSubtitlesForm';
// --- VOD Series---
import SeriesTabs from 'components/views/vod/vod_series/series_tabs/SeriesTabs';
// --- VOD Seasons---
import SeasonsTabs from 'components/views/vod/vod_seasons/seasons_tabs/SeasonsTabs';
// --- VOD Episodes---
import EpisodesTabs from 'components/views/vod/vod_episodes/episodes_tabs/EpisodesTabs';
// --- Sections groups ---
import SectionsGroups from 'components/views/sections_groups/SectionsGroups';
import SectionsGroupsTabs from 'components/views/sections_groups/sections_groups_tabs/SectionsGroupsTabs';
// --- Section ---
import Sections from 'components/views/sections/Sections';
import SectionsTabs from 'components/views/sections/sections_tabs/SectionsTabs';
// --- Banners ---
import Banners from 'components/views/banners/Banners';
import BannersTabs from 'components/views/banners/banners_tabs/BannersTabs';
// --- Mass changes ---
import MassChanges from 'components/views/mass_changes/MassChanges';
// --- Cognito categories ---
import Cognito from 'components/views/cognito/Cognito';
import CognitoTabs from 'components/views/cognito/cognito_tabs/CognitoTabs';
import CognitoForm from 'components/views/cognito/cognito_form/CognitoForm';
// --- Barker channels ---
import BarkerChannels from 'components/views/barker_channels/BarkerChannels';
import BarkerChannelsForm from 'components/views/barker_channels/barker_channels_form/BarkerChannelsForm';
import BarkerChannelsTabs from 'components/views/barker_channels/barker_channels_tabs/BarkerChannelsTabs';

// ***************** Ingester *****************
// --- Assets ---
import IngesterAssets from 'components/views/ingester/ingester_assets/IngesterAssets';
// --- Encoding ---
import IngesterEncoding from 'components/views/ingester/ingester_encoding/IngesterEncoding';
// --- Video Assets Params ---
import VideoAssetsParams from 'components/views/ingester/video_assets_params/VideoAssetsParams';
import VideoAssetsParamsForm from 'components/views/ingester/video_assets_params/video_assets_params_form/VideoAssetsParamsForm';

// ***************** Administration *****************
// --- Operators ---
import Operators from 'components/views/operators/Operators';
import OperatorsTabs from 'components/views/operators/operators_tabs/OperatorsTabs';
// --- Affiliate Partners ---
import AffiliatePartners from 'components/views/affiliate_partners/AffiliatePartners';
import AffiliatePartnersTabs from 'components/views/affiliate_partners/affiliate_partners_tabs/AffiliatePartnersTabs';
// --- Providers ---
import Providers from 'components/views/providers/Providers';
import ProvidersTabs from 'components/views/providers/providers_tabs/ProvidersTabs';
// --- Activity ---
import Activity from 'components/views/activity/Activity';
import ActivityForm from 'components/views/activity/activity_form/ActivityForm';
// --- Geolock ---
import Geolocks from 'components/views/geolocks/Geolocks';
import GeolockTabs from 'components/views/geolocks/geolocks_tabs/GeolocksTabs';
// --- Trackings ---
import Trackings from 'components/views/trackings/Trackings';
// --- Software ---
import SoftwareForm from 'components/views/software/software_form/SoftwareForm';
import Software from 'components/views/software/Software';
// --- Notifications ---
import NotificationsForm from 'components/views/notifications/notifications_form/NotificationsForm';
// --- Systems ---
import Systems from 'components/views/systems/Systems';
import SystemsTabs from 'components/views/systems/systems_tabs/SystemsTabs';
// --- IP verification ---
import IpVerification from 'components/views/ip_verification/IpVerification';

// ***************** Settings *****************
import SettingsTabs from 'components/views/settings/settings_tabs/SettingsTabs';
import StatisticsDashboard from 'components/views/statistics/StatisticsDashboard';
import PriceSettings from 'components/common/templates/price_form_template/PriceSettings';

// --- Topology ---
import Topology from 'components/views/topology/Topology';

// ***************** Payment Templates *****************
import PaymentTemplates from 'components/views/payment_templates/PaymentTemplates';
import PaymentTemplatesTabs from 'components/views/payment_templates/payment_templates_tabs/PaymentTemplatesTabs';
import UsePaymentTemplate from 'components/common/templates/price_template_use/UsePaymentTemplate';

// --- Marketing campaigns ---
import MarketingCampaigns from 'components/views/marketing_campaigns/MarketingCampaigns';
import MarketingCampaignsTabs from 'components/views/marketing_campaigns/marketing_campaigns_tabs/MarketingCampaignsTabs';

// Define roles
// const Admin = RoleAuthorization(['admin', 'super_admin']);
// --- All Roles Access ---
const FULL_ACCESS = [
	...cms,
	...crm,
	...data,
	...portal,
	...ingestion,
	...admin,
	...support,
	...system_operator
];
// --- Main Roles ---
const CMS = [...cms, ...support];
const CRM = [...crm, ...support];
const DATA = data;
const PORTAL = portal;
const INGESTION = ingestion;
const ADMIN = admin;
const SYSTEM_OPERATOR = system_operator;
// --- Mixed Roles ---
const AVALIBILITY = [...cms, ...portal];
const PRICES = [...new Set([...cms, ...portal])];
const IP_VERIFICATION = [...new Set([...admin, ...crm, ...support])];
// --- Other roles ---
// const VIDEO = RoleAuthorization(video);
// --- Systems ---
const MAIN = main;

const ROUTES_PATHS = [
	// ------ Availability ------
	{
		path: '/panel/:type/:id/availability/create',
		component: (
			<RoleAuthorization
				allowedRoles={AVALIBILITY}
				component={AvailabilityFormTemplate}
			/>
		)
	},
	{
		path: '/panel/:type/:productID/availability/edit/:id',
		component: (
			<RoleAuthorization
				allowedRoles={AVALIBILITY}
				component={AvailabilitySettings}
			/>
		)
	},

	// ------ Prices ------
	{
		path: '/panel/:type/:id/price/create',
		component: (
			<RoleAuthorization allowedRoles={PRICES} component={PriceFormTemplate} />
		)
	},
	{
		path: '/panel/:type/:productID/prices/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={PRICES} component={PriceSettings} />
		)
	},
	// ***************** Statistics dashboard *****************
	{
		path: '/panel/statistics',
		component: (
			<RoleAuthorization
				allowedRoles={FULL_ACCESS}
				component={StatisticsDashboard}
			/>
		)
	},
	// ***************** Products *****************
	// ------ Promotions ------
	{
		path: '/panel/promotions/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={CMS} component={PromotionsTabs} />
		)
	},
	{
		path: '/panel/promotions/create',
		component: (
			<RoleAuthorization allowedRoles={CMS} component={PromotionsTabs} />
		)
	},
	{
		path: '/panel/promotions',
		component: <RoleAuthorization allowedRoles={CMS} component={Promotions} />
	},
	// ------ Coupons ------
	{
		path: '/panel/coupons/edit/:id',
		component: <RoleAuthorization allowedRoles={CMS} component={CouponsTabs} />
	},
	{
		path: '/panel/coupons/create',
		component: <RoleAuthorization allowedRoles={CMS} component={CouponsTabs} />
	},
	{
		path: '/panel/coupons',
		component: <RoleAuthorization allowedRoles={CMS} component={Coupons} />
	},
	// ------ Channels ------
	{
		path: '/panel/channels/:channelId/epg/edit/:id',
		// component: CMS(EpgForm)
		component: <RoleAuthorization allowedRoles={CMS} component={EPGTabs} />
	},
	{
		path: '/panel/channels/edit/:id',
		component: <RoleAuthorization allowedRoles={CMS} component={ChannelsTabs} />
	},
	{
		path: '/panel/channels/create',
		component: <RoleAuthorization allowedRoles={CMS} component={ChannelsTabs} />
	},
	{
		path: '/panel/channels',
		component: <RoleAuthorization allowedRoles={CMS} component={Channels} />
	},

	// ------ EPG ------
	{
		path: '/panel/epg_global',
		component: <RoleAuthorization allowedRoles={CMS} component={EPGGlobal} />
	},

	// ------ Local Channels ------
	{
		path: '/panel/localChannels/create',
		component: (
			<RoleAuthorization allowedRoles={CMS} component={LocalChannelsForm} />
		)
	},
	{
		path: '/panel/localChannels',
		component: (
			<RoleAuthorization allowedRoles={CMS} component={LocalChannels} />
		)
	},
	// ------ Provisioning ------
	{
		path: '/panel/provisioning/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={CRM} component={ProvisioningTabs} />
		)
	},
	{
		path: '/panel/provisioning/create',
		component: (
			<RoleAuthorization allowedRoles={CRM} component={ProvisioningTabs} />
		)
	},
	{
		path: '/panel/provisioning',
		component: <RoleAuthorization allowedRoles={CRM} component={Provisioning} />
	},

	// ------ Collections (old packets) ------
	{
		path: '/panel/packets/edit/:id',
		component: <RoleAuthorization allowedRoles={CMS} component={PacketsTabs} />
	},
	{
		path: '/panel/packets/create',
		component: <RoleAuthorization allowedRoles={CMS} component={PacketsTabs} />
	},
	{
		path: '/panel/packets',
		component: <RoleAuthorization allowedRoles={CMS} component={Packets} />
	},

	// ------ Packets (old Main) ------
	{
		path: '/panel/main/edit/:id',
		component: <RoleAuthorization allowedRoles={PORTAL} component={MainTabs} />
	},
	{
		path: '/panel/main/create',
		component: <RoleAuthorization allowedRoles={PORTAL} component={MainTabs} />
	},
	{
		path: '/panel/main',
		component: <RoleAuthorization allowedRoles={PORTAL} component={Main} />
	},

	// ------ Tags ------
	{
		path: '/panel/tags/create',
		component: <RoleAuthorization allowedRoles={PORTAL} component={TagsTabs} />
	},
	{
		path: '/panel/tags/edit/:id',
		component: <RoleAuthorization allowedRoles={PORTAL} component={TagsTabs} />
	},
	{
		path: '/panel/tags',
		component: <RoleAuthorization allowedRoles={PORTAL} component={TagsTable} />
	},

	// ------ Limits ------
	{
		path: '/panel/limits/edit/:id',
		component: <RoleAuthorization allowedRoles={CMS} component={LimitsTabs} />
	},
	{
		path: '/panel/limits/create',
		component: <RoleAuthorization allowedRoles={CMS} component={LimitsTabs} />
	},
	{
		path: '/panel/limits',
		component: <RoleAuthorization allowedRoles={CMS} component={Limits} />
	},

	// ------ Payments ------
	{
		path: '/panel/payments',
		component: <RoleAuthorization allowedRoles={DATA} component={Payments} />
	},

	// ***************** Users *****************
	// ------ Subscribers ------
	{
		path: '/panel/subscribers/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={CRM} component={SubscribersTabs} />
		)
	},
	{
		path: '/panel/subscribers',
		component: <RoleAuthorization allowedRoles={CRM} component={Subscribers} />
	},
	{
		path: '/panel/devices',
		component: <RoleAuthorization allowedRoles={CRM} component={Devices} />
	},
	{
		path: '/panel/devices_sn/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={CRM} component={DevicesSnTabs} />
		)
	},
	{
		path: '/panel/devices_sn/create',
		component: (
			<RoleAuthorization allowedRoles={CRM} component={DevicesSnTabs} />
		)
	},
	{
		path: '/panel/devices_sn',
		component: <RoleAuthorization allowedRoles={CRM} component={DevicesSn} />
	},

	// ***************** CONTENT *****************
	// ------ Categories -----
	{
		path: '/panel/categories/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={CategoriesTabs} />
		)
	},
	{
		path: '/panel/categories/:type/create',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={CategoriesTabs} />
		)
	},
	{
		path: '/panel/categories',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={Categories} />
		)
	},
	// ------ Documents ------
	{
		path: '/panel/documents/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={DocumentsForm} />
		)
	},
	{
		path: '/panel/documents/create',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={DocumentsForm} />
		)
	},
	{
		path: '/panel/documents',
		component: <RoleAuthorization allowedRoles={PORTAL} component={Documents} />
	},
	// ------ Agreements ------
	{
		path: '/panel/agreements/:agreementId/versions/edit/:id/',
		component: (
			<RoleAuthorization
				allowedRoles={PORTAL}
				component={AgreementsVersionsForm}
			/>
		)
	},
	{
		path: '/panel/agreements/:agreementId/versions/create',
		component: (
			<RoleAuthorization
				allowedRoles={PORTAL}
				component={AgreementsVersionsForm}
			/>
		)
	},
	{
		path: '/panel/agreements/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={AgreementsTabs} />
		)
	},
	{
		path: '/panel/agreements/create',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={AgreementsTabs} />
		)
	},
	{
		path: '/panel/agreements',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={Agreements} />
		)
	},
	// ------ VOD Series------
	{
		path: '/panel/vod/series/edit/:id',
		component: <RoleAuthorization allowedRoles={CMS} component={SeriesTabs} />
	},
	{
		path: '/panel/vod/series/create',
		component: <RoleAuthorization allowedRoles={CMS} component={SeriesTabs} />
	},
	// ------ VOD Seasons------
	{
		path: '/panel/vod/season/edit/:id',
		component: <RoleAuthorization allowedRoles={CMS} component={SeasonsTabs} />
	},
	{
		path: '/panel/vod/season/create',
		component: <RoleAuthorization allowedRoles={CMS} component={SeasonsTabs} />
	},
	// ------ VOD Episodes------
	{
		path: '/panel/vod/episode/edit/:id',
		component: <RoleAuthorization allowedRoles={CMS} component={EpisodesTabs} />
	},
	// ------ VOD ------
	{
		path: '/panel/vod/:vodId/materials/:id/subtitles/create',
		component: (
			<RoleAuthorization allowedRoles={CMS} component={VodSubtitlesForm} />
		)
	},
	{
		path: '/panel/vod/vod/edit/:id',
		component: <RoleAuthorization allowedRoles={CMS} component={VodTabs} />
	},
	{
		path: '/panel/vod',
		component: <RoleAuthorization allowedRoles={CMS} component={Vod} />
	},
	// ------ Section groups ------
	{
		path: '/panel/sections_groups/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={SectionsGroupsTabs} />
		)
	},
	{
		path: '/panel/sections_groups/create',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={SectionsGroupsTabs} />
		)
	},
	{
		path: '/panel/sections_groups',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={SectionsGroups} />
		)
	},
	// ------ Sections ------
	{
		path: '/panel/sections/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={SectionsTabs} />
		)
	},
	{
		path: '/panel/sections/create',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={SectionsTabs} />
		)
	},
	{
		path: '/panel/sections',
		component: <RoleAuthorization allowedRoles={PORTAL} component={Sections} />
	},
	// ------ Banners ------
	{
		path: '/panel/banners/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={BannersTabs} />
		)
	},
	{
		path: '/panel/banners/create',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={BannersTabs} />
		)
	},
	{
		path: '/panel/banners',
		component: <RoleAuthorization allowedRoles={PORTAL} component={Banners} />
	},
	// ------ Mass changes ------
	{
		path: '/panel/mass_changes',
		component: <RoleAuthorization allowedRoles={CMS} component={MassChanges} />
	},
	// ------ Cognito ------
	{
		path: '/panel/menu_stb/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={CognitoTabs} />
		)
	},
	{
		path: '/panel/menu_stb/create',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={CognitoForm} />
		)
	},
	{
		path: '/panel/menu_stb',
		component: <RoleAuthorization allowedRoles={PORTAL} component={Cognito} />
	},
	// ------ Barker Channels ------
	{
		path: '/panel/barker_channels/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={BarkerChannelsTabs} />
		)
	},
	{
		path: '/panel/barker_channels/create',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={BarkerChannelsForm} />
		)
	},
	{
		path: '/panel/barker_channels',
		component: (
			<RoleAuthorization allowedRoles={PORTAL} component={BarkerChannels} />
		)
	},
	// ***************** Ingester *****************
	// ------ Assets ------
	{
		path: '/panel/ingester/assets',
		component: (
			<RoleAuthorization
				allowedRoles={INGESTION}
				allowedSystems={MAIN}
				component={IngesterAssets}
			/>
		)
	},
	// ------ Encoding ------
	{
		path: '/panel/ingester/encoding',
		component: (
			<RoleAuthorization
				allowedRoles={INGESTION}
				allowedSystems={MAIN}
				component={IngesterEncoding}
			/>
		)
	},
	// ------ Video assets params ------
	{
		path: '/panel/ingester/video-assets-params/edit/:id',
		component: (
			<RoleAuthorization
				allowedRoles={INGESTION}
				allowedSystems={MAIN}
				component={VideoAssetsParamsForm}
			/>
		)
	},
	{
		path: '/panel/ingester/video-assets-params/create',
		component: (
			<RoleAuthorization
				allowedRoles={INGESTION}
				allowedSystems={MAIN}
				component={VideoAssetsParamsForm}
			/>
		)
	},
	{
		path: '/panel/ingester/video-assets-params',
		component: (
			<RoleAuthorization
				allowedRoles={INGESTION}
				allowedSystems={MAIN}
				component={VideoAssetsParams}
			/>
		)
	},

	// ***************** Administration *****************
	// ------ Operators ------
	{
		path: '/panel/operators/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={ADMIN} component={OperatorsTabs} />
		)
	},
	{
		path: '/panel/operators/create',
		component: (
			<RoleAuthorization allowedRoles={ADMIN} component={OperatorsTabs} />
		)
	},
	{
		path: '/panel/operators',
		component: <RoleAuthorization allowedRoles={ADMIN} component={Operators} />
	},

	// ------ AFFILIATE PARTNERS ------
	{
		path: '/panel/affiliate_partners/edit/:id',
		component: (
			<RoleAuthorization
				allowedRoles={ADMIN}
				component={AffiliatePartnersTabs}
			/>
		)
	},
	{
		path: '/panel/affiliate_partners/create',
		component: (
			<RoleAuthorization
				allowedRoles={ADMIN}
				component={AffiliatePartnersTabs}
			/>
		)
	},
	{
		path: '/panel/affiliate_partners',
		component: (
			<RoleAuthorization allowedRoles={ADMIN} component={AffiliatePartners} />
		)
	},
	// ------ Providers ------
	{
		path: '/panel/providers/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={ADMIN} component={ProvidersTabs} />
		)
	},
	{
		path: '/panel/providers/create',
		component: (
			<RoleAuthorization allowedRoles={ADMIN} component={ProvidersTabs} />
		)
	},
	{
		path: '/panel/providers',
		component: <RoleAuthorization allowedRoles={ADMIN} component={Providers} />
	},
	// ------ Geolock ------
	{
		path: '/panel/geolocks/create',
		component: (
			<RoleAuthorization allowedRoles={ADMIN} component={GeolockTabs} />
		)
	},
	{
		path: '/panel/geolocks',
		component: <RoleAuthorization allowedRoles={ADMIN} component={Geolocks} />
	},
	// ------ Trackings ------
	{
		path: '/panel/trackings',
		component: <RoleAuthorization allowedRoles={DATA} component={Trackings} />
	},
	// ***************** Activity *****************
	{
		path: '/panel/activity/:id',
		component: (
			<RoleAuthorization allowedRoles={ADMIN} component={ActivityForm} />
		)
	},
	{
		path: '/panel/activity',
		component: <RoleAuthorization allowedRoles={ADMIN} component={Activity} />
	},
	// ------ Software ------
	{
		path: '/panel/software/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={ADMIN} component={SoftwareForm} />
		)
	},
	{
		path: '/panel/software/create',
		component: (
			<RoleAuthorization allowedRoles={ADMIN} component={SoftwareForm} />
		)
	},
	{
		path: '/panel/software',
		component: <RoleAuthorization allowedRoles={ADMIN} component={Software} />
	},

	// ***************** Systems *****************
	{
		path: '/panel/systems/edit/:id',
		component: (
			<RoleAuthorization
				allowedRoles={[...ADMIN, ...SYSTEM_OPERATOR]}
				allowedMaxSystemTreeDepth={1}
				component={SystemsTabs}
			/>
		)
	},
	{
		path: '/panel/systems/create',
		component: (
			<RoleAuthorization
				allowedRoles={[...ADMIN, ...SYSTEM_OPERATOR]}
				allowedMaxSystemTreeDepth={1}
				component={SystemsTabs}
			/>
		)
	},
	{
		path: '/panel/systems',
		component: (
			<RoleAuthorization
				allowedRoles={[...ADMIN, ...SYSTEM_OPERATOR]}
				allowedMaxSystemTreeDepth={1}
				component={Systems}
			/>
		)
	},

	// ***************** IP VERIFICATION *****************
	{
		path: '/panel/ip_verification',
		component: (
			<RoleAuthorization
				allowedRoles={IP_VERIFICATION}
				allowedMaxSystemTreeDepth={1}
				component={IpVerification}
			/>
		)
	},

	// ***************** Settings *****************
	{
		path: '/panel/settings',
		component: (
			<RoleAuthorization allowedRoles={ADMIN} component={SettingsTabs} />
		)
	},
	// ***************** Notifications *****************
	{
		path: '/panel/notifications/create',
		component: (
			<RoleAuthorization allowedRoles={ADMIN} component={NotificationsForm} />
		)
	},
	// ***************** Topology *****************
	{
		path: '/panel/topology',
		component: <RoleAuthorization allowedRoles={ADMIN} component={Topology} />
	},
	// **************** Payment Templates ****************
	{
		path: '/panel/payment_templates/create',
		component: (
			<RoleAuthorization allowedRoles={CMS} component={PaymentTemplatesTabs} />
		)
	},
	{
		path: '/panel/payment_templates/edit/:id',
		component: (
			<RoleAuthorization allowedRoles={CMS} component={PaymentTemplatesTabs} />
		)
	},
	{
		path: '/panel/payment_templates',
		component: (
			<RoleAuthorization allowedRoles={CMS} component={PaymentTemplates} />
		)
	},
	{
		path: '/panel/use_payment_template/:id',
		component: (
			<RoleAuthorization allowedRoles={CMS} component={UsePaymentTemplate} />
		)
	},
	// ------ Marketing campaigns ------
	{
		path: '/panel/marketing_campaigns/create',
		component: (
			<RoleAuthorization
				allowedRoles={ADMIN}
				component={MarketingCampaignsTabs}
			/>
		)
	},
	{
		path: '/panel/marketing_campaigns/edit/:id',
		component: (
			<RoleAuthorization
				allowedRoles={ADMIN}
				component={MarketingCampaignsTabs}
			/>
		)
	},
	{
		path: '/panel/marketing_campaigns',
		component: (
			<RoleAuthorization allowedRoles={ADMIN} component={MarketingCampaigns} />
		)
	}
];

export default ROUTES_PATHS;
