import React from 'react';
import PropTypes from 'prop-types';

// Import utilites
import { renderFields } from 'components/utilities/form';

// Import fields
import { BASE_FIELDS, VISIBLE_FIELDS } from './input_fields';

// Import translations
import { useTranslation } from 'react-i18next';

const Fields = ({ submitting, casts, platforms, ranks = [] }) => {
	const { t } = useTranslation();

	const selectResources = {
		casts,
		platforms,
		ranks
	};

	return (
		<React.Fragment>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
			<h3>{t('common:form:field_groups.visible_in_platforms')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{VISIBLE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	casts: PropTypes.array.isRequired,
	platforms: PropTypes.array.isRequired,
	ranks: PropTypes.array.isRequired
};

export default Fields;
