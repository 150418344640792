import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';

// Import hooks
import { useDeeplinkManual } from '../hooks';

// Import components
import { FormButtons } from 'components/common/buttons';

// Import helpers
import { renderFields } from 'components/utilities/form';
import { BASE_FIELDS } from './inputs';

const DeeplinkManual = ({ input, close }) => {
	const { t } = useTranslation();
	const { validate, handleUpdateValue, initialValues } = useDeeplinkManual({
		input,
		close
	});

	return (
		<Form
			initialValues={initialValues}
			onSubmit={handleUpdateValue}
			validate={validate}
			render={({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} className="deeplink_form">
					{BASE_FIELDS.map((item, index) =>
						renderFields({ item, index, submitting })
					)}
					<FormButtons
						isButtonDisabled={false}
						cancelButton={false}
						buttonText={t('common:buttons.submit')}
					/>
				</form>
			)}
		/>
	);
};

DeeplinkManual.propTypes = {
	input: PropTypes.object,
	close: PropTypes.func
};

export default DeeplinkManual;
