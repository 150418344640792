import services from 'services/services';
import * as types from '../types';

// Import actions
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

import {
	FETCH_PAYMENT_TEMPLATES_GET_ALL,
	FETCH_PAYMENT_TEMPLATES_DELETE
} from 'components/helpers/api';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH PAYMENT SCHEDULE - TABLE DATA ********************
export const fetchPaymentTemplates = (options) => async (
	dispatch,
	getState
) => {
	try {
		dispatch({
			type: types.FETCH_PAYMENT_TEMPLATES_LOADING,
			payload: true
		});

		const {
			payment_templates: {
				table: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `${FETCH_PAYMENT_TEMPLATES_GET_ALL}?${filterUrlQuery}${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex
		)}`;
		const { data } = await services.get(url);
		const records = data.data;
		const pages = Math.ceil(records.length / TABLE_LENGTH);

		dispatch({
			type: types.FETCH_PAYMENT_TEMPLATES_SUCCESS,
			payload: {
				...data,
				data: records.map((record) => ({
					...record,
					payment_methods: Object.keys(record.prices)
						.filter((key) => record.prices[key])
						.join(', ')
				})),
				pages,
				page: options.page,
				startIndex
			}
		});

		// Save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_PAYMENT_TEMPLATES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE PAYMENT SCHEDULE ********************
export const deletePaymentTemplate = (id) => async (dispatch) => {
	try {
		dispatch({
			type: types.DELETE_PAYMENT_TEMPLATES_LOADING,
			payload: true
		});

		const url = FETCH_PAYMENT_TEMPLATES_DELETE.replace(':id', id);
		await services.delete(url);

		dispatch({
			type: types.DELETE_PAYMENT_TEMPLATES_SUCCESS,
			payload: true
		});
	} catch (error) {
		dispatch({
			type: types.DELETE_PAYMENT_TEMPLATES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET SEARCH QUERY ********************
export const setPaymentTemplateQuery = (filter_params) => ({
	type: types.SET_PAYMENT_TEMPLATES_SEARCH_QUERY,
	payload: filter_params
});
