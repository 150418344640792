import { combineReducers } from 'redux';
// Import reducers
import products_list_out from './promotions_products_out_list_reducer';
import products_form_out from './promotions_products_out_form_reducer';
import products_list_in from './promotions_products_in_list_reducer';
import products_form_in from './promotions_products_in_form_reducer';

export default combineReducers({
	products_list_out,
	products_form_out,
	products_list_in,
	products_form_in
});
