import { useState, useEffect } from 'react';
import { string } from 'prop-types';

// import helpers
import { fetchPlaylistData } from '../helpers';

const useAudioPlayer = ({ playlist }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [audioSource, setAudioSource] = useState('');

	const handleFetchPlaylistData = async () => {
		const {
			playlistData: {
				sources: { FILE }
			}
		} = await fetchPlaylistData({
			playlist,
			isProtected: false
		});

		const materialExist = FILE?.length > 0;

		materialExist && setAudioSource(FILE[0].src);

		setIsLoading(false);
	};

	useEffect(() => {
		handleFetchPlaylistData();
		// eslint-disable-next-line
	}, []);

	return { isLoading, audioSource };
};

useAudioPlayer.propTypes = {
	playlist: string.isRequired
};

export default useAudioPlayer;
