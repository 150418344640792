import i18n from 'i18next';

// Vod images info text
export const IMAGE_SIZE_INFO = i18n.t('info_text:image_size');

// HBO logo info text
export const HBO_LOGO_INFO = i18n.t('info_text:hbo_logo');

// Packets - (provisioning | packets | main) - images info text and requirements
export const PACKETS_IMAGE_SIZE_INFO = i18n.t('info_text:packets_image_size');
export const PROVISIONING_IMAGES_REQUIREMENTS = [
	i18n.t('provisioning:info.cover'),
	i18n.t('provisioning:info.cover_large'),
	i18n.t('provisioning:info.cover_small'),
	i18n.t('provisioning:info.cover_detail'),
	i18n.t('provisioning:info.cover_detail_large')
];
export const PACKETS_IMAGES_REQUIREMENTS = [
	i18n.t('packets:info.cover'),
	i18n.t('packets:info.cover_large')
];
export const MAIN_IMAGES_REQUIREMENTS = [
	i18n.t('main:info.cover'),
	i18n.t('main:info.cover_large'),
	i18n.t('main:info.cover_small'),
	i18n.t('main:info.cover_detail'),
	i18n.t('main:info.cover_detail_large')
];
