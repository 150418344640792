import services from 'services/services';
import * as types from '../types';

// Import helpers
import * as api from 'components/helpers/api';
import { checkFetchErrors } from '../helpers_actions/convert_helpers';
import { checkAssignedProducts } from './helpers';

// ******************** FETCH SUBSCRIBER PACKETS - TABLE DATA ********************
export const fetchSubscriberPackets = (options, itemId) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_SUBSCRIBER_PACKETS_LOADING
		});
		// Provisionings can added automatically or manually, so we need two endpoints, and we have to do convert

		// Provisionings added automatically
		const {
			data: { products }
		} = await services.get(`subscriber/${itemId}/packets`);

		// Provisionings added manually
		const {
			data: { data: assignedProducts }
		} = await services.get(
			`/timetables/products/list?payment_method=free&user_id=${itemId}`
		);

		const payload = {
			// We have to compare products with list of assigned products to add them property - assigned: true /false
			products: checkAssignedProducts({ products, assignedProducts })
		};

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SUBSCRIBER_PACKETS_SUCCESS,
			payload
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SUBSCRIBER_PACKETS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** FETCH SUBSCRIBER SUBSCRIPTIONS (MAIN) - TABLE DATA ********************
export const fetchSubscriberSubscriptions = (options, subscriberId) => async (
	dispatch
) => {
	try {
		dispatch({
			type: types.FETCH_SUBSCRIBER_SUBSCRIPTIONS_LOADING
		});

		const url = api.FETCH_SUBSCRIBER_PACKETS.replace(':id', subscriberId);

		const {
			data: {
				current_additional_products,
				current_basic_product,
				pending_basic_product,
				billing_period_end_on,
				billing_period_start_on
			}
		} = await services.get(url);

		let data = [current_basic_product];

		if (pending_basic_product) {
			data = [...data, pending_basic_product];
		}

		data = [...data, ...current_additional_products].map((item) => ({
			...item,
			billing_period_end_on,
			billing_period_start_on
		}));

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SUBSCRIBER_SUBSCRIPTIONS_SUCCESS,
			payload: data
		});
	} catch (error) {
		if (error.response && error.response.status === 404) {
			dispatch({
				type: types.FETCH_SUBSCRIBER_SUBSCRIPTIONS_SUCCESS,
				payload: []
			});
		}

		dispatch({
			type: types.FETCH_SUBSCRIBER_SUBSCRIPTIONS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
