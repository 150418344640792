import React from 'react';
import PropTypes from 'prop-types';

function FieldError({ error, inputName, content }) {
	if (!error) return null;
	return (
		<span
			data-testid={`input-error-${inputName}`}
			className="input-error d-block"
		>
			{content}
		</span>
	);
}

FieldError.defaultProps = {
	error: false,
	inputName: '',
	content: ''
};

FieldError.propTypes = {
	error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	inputName: PropTypes.string,
	content: PropTypes.string
};

export default FieldError;
