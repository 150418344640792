import services from 'services/services';
import * as types from '../types';

import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams,
	getColumnId,
	getColumnParamName
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';
import { VOD_STATUS_VALUES } from 'components/helpers/variables';

const { ALL, ACTIVE, COMPLETE } = VOD_STATUS_VALUES;

// ******************** FETCH VOD - TABLE DATA ********************
export const fetchVod = (options, itemId, resources) => async (
	dispatch,
	getState
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_VOD_LOADING,
			payload: true
		});

		const {
			vod: {
				table: {
					columns,
					options: {
						filters: { subtype, status, filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const params = {
			withDeleted: !!resources?.isMainVodTable
		};

		const getIndex = getColumnId(columns);

		const setColumnParam = (name, value) => {
			const index = getIndex(name);
			const paramName = getColumnParamName(index);
			params[paramName] = value;
		};

		if (subtype !== ALL) {
			setColumnParam('subtype', subtype);
		}

		if (status === COMPLETE) {
			setColumnParam(ACTIVE, 0);
		}

		if (status === ACTIVE) {
			setColumnParam(ACTIVE, 1);
		} else if (status !== ALL) {
			setColumnParam('status', status);
		}

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `/vod/newList?${filterUrlQuery}${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex,
			true
		)}`;

		const { data } = await services.get(url, { params });

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_VOD_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_VOD_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE SERIES - TABLE DATA ********************
export const deleteVodSeries = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DELETE_VOD_SERIES_LOADING,
			payload: true
		});

		await services.delete(`/vod/series/${id}`);
		// Dispatch an action with data
		dispatch({
			type: types.DELETE_VOD_SERIES_SUCCESS,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DELETE_VOD_SERIES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE SEASON - TABLE DATA ********************
export const deleteVodSeason = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DELETE_VOD_SEASON_LOADING,
			payload: true
		});

		await services.delete(`/vod/season/${id}`);
		// Dispatch an action with data
		dispatch({
			type: types.DELETE_VOD_SEASON_SUCCESS,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DELETE_VOD_SEASON_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE VOD - TABLE DATA ********************
export const deleteVod = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DELETE_VOD_LOADING,
			payload: true
		});

		await services.delete(`/vod/${id}`);
		// Dispatch an action with data
		dispatch({
			type: types.DELETE_VOD_SUCCESS,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DELETE_VOD_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET VOD SUBTYPE ********************
export const setVodSubtype = (subtype) => ({
	type: types.SET_VOD_SUBTYPE,
	payload: subtype
});

// ******************** SET VOD STATUS ********************
export const setVodStatus = (status) => (dispatch) => {
	dispatch({
		type: types.SET_VOD_STATUS,
		payload: status
	});
};

// ******************** SET SEARCH QUERY ********************
export const setVodQuery = (query) => ({
	type: types.SET_VOD_SEARCH_QUERY,
	payload: query
});
