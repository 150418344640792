import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translations
import { default as resources } from './translations';

const languageDetector = new LanguageDetector();

i18n
	.use(languageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: 'pl',
		debug: false,
		detection: {
			order: ['localStorage', 'htmlTag'],
			caches: ['localStorage'],
			lookupLocalStorage: 'language'
		},
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
