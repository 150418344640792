import * as types from '../types';

// Import helpers
import { convertGenresToNames } from 'components/helpers/genres_helpers/helpers';
import {
	convertProvidersToValuePairs,
	convertPaymentModelsSuggestions,
	convertStatusToValuePairs,
	convertEntriesToValuePairs,
	convertCatchupStatesToValuePairs
} from 'store/actions/helpers_actions/convert_helpers';
import { getFilterSelectValues } from 'components/views/local_channels/helpers';

// Import variables
import { LOCATION_TYPES } from 'components/helpers/variables';

const { STATISTICS, VOD, EPG_GLOBAL } = LOCATION_TYPES;

// ******************** SET TABLE COLUMNS ********************
export const setTableColumns = (columns) => (dispatch) =>
	dispatch({
		type: types.SET_TABLE_COLUMNS,
		payload: columns
	});

// ******************** RESET FILTER ********************
export const resetTableFilters = ({
	previousLocation,
	currentLocation,
	linkState
}) => (dispatch) => {
	// check the type in pathname of location (vod | season | packets | subscribers ...)
	let prevType = previousLocation.split('/')[2];
	if (prevType === VOD) {
		if (previousLocation.split('/')[3])
			prevType = previousLocation.split('/')[3];
	}
	const currentLocationToArray = currentLocation.split('/');
	const currType = currentLocationToArray[2];

	const isPayments =
		currentLocationToArray[currentLocationToArray.lenght - 1] === 'payments';

	// linkState is here to reset filter state when you manually switch from /panel/statistics to either /panel/epg_global or /panel/vod
	const isStatisticsRedirect =
		linkState &&
		((prevType === STATISTICS && currType === VOD) ||
			(prevType === STATISTICS && currType === EPG_GLOBAL));

	const resetFilters =
		((previousLocation && prevType !== currType) || isPayments) &&
		!isStatisticsRedirect;

	// if types are different reset table columns filters
	return resetFilters
		? dispatch({
				type: types.FILTER_RESET,
				payload: null
		  })
		: null;
};

// ******************** SET FILTER URL QUERY ********************
export const setFilterUrlQuery = (urlQuery) => (dispatch) =>
	dispatch({
		type: types.SET_FILTER_URL_QUERY,
		payload: urlQuery
	});

// ******************** SET FILTER FORM VALUES ********************
export const setFilterFormValues = (values) => (dispatch) =>
	dispatch({
		type: types.SET_FILTER_FORM_VALUES,
		payload: values
	});

// ******************** CLEAR FILTERS ********************
export const clearFilters = () => (dispatch) =>
	dispatch({
		type: types.CLEAR_FILTERS,
		payload: null
	});

// ******************** CREATE FILTERS SUGGESTIONS ********************
export const createFiltersSuggestions = (filters) => (dispatch) => {
	const filtersSuggestions = { ...filters };

	if (filters.hasOwnProperty('genres')) {
		filtersSuggestions.genres = convertGenresToNames(filters.genres);
	}
	if (filters.hasOwnProperty('providers')) {
		filtersSuggestions.provider_id = convertProvidersToValuePairs(
			filters.providers
		);
		delete filtersSuggestions.providers;
	}
	if (filters.hasOwnProperty('payment_models')) {
		filtersSuggestions.payment_models = convertPaymentModelsSuggestions(
			filters.payment_models
		);
	}
	if (filters.hasOwnProperty('campaign_status')) {
		filtersSuggestions.status = filters.campaign_status;
	} else if (
		filters.hasOwnProperty('status') &&
		!filters.hasOwnProperty('target_type')
	) {
		filtersSuggestions.status = convertStatusToValuePairs(filters.status);
	}
	if (filters.hasOwnProperty('source')) {
		filtersSuggestions.source = convertEntriesToValuePairs(filters.source);
	}

	if (filters.hasOwnProperty('terytCodes')) {
		const { voivodeshipCodes, countyCodes } = getFilterSelectValues(
			filters.filterFormValues
		);

		filtersSuggestions.voivodeship = voivodeshipCodes;
		filtersSuggestions.county = countyCodes;
	}

	if (filters.hasOwnProperty('mc_status')) {
		// We don't need to convert array for this status
		filtersSuggestions.status = filters.mc_status;
	}

	if (filters.hasOwnProperty('catchup_recording_state')) {
		filtersSuggestions.catchup_recording_state = convertCatchupStatesToValuePairs(
			filters.catchup_recording_state
		);
	}

	return dispatch({
		type: types.CREATE_FILTERS_SUGGESTIONS,
		payload: filtersSuggestions
	});
};

// ******************** SET FILTERS DISABLE ********************
export const setFiltersDisable = (value) => (dispatch) => {
	dispatch({
		type: types.SET_FILTERS_DISABLE,
		payload: value
	});
};
