import i18n from 'i18next';

export const BASE_FIELDS = (isEdit) => [
	{
		name: 'email',
		label: i18n.t('operators:fields.email_label'),
		type: 'email',
		required: true
	},
	{
		name: 'firstName',
		label: i18n.t('operators:fields.first_name_label'),
		type: 'text',
		required: true
	},
	{
		name: 'lastName',
		label: i18n.t('operators:fields.last_name_label'),
		type: 'text',
		required: true
	},
	{
		name: 'password',
		label: i18n.t('operators:fields.password_label'),
		type: 'password',
		required: isEdit
	},
	{
		name: 'password_confirmation',
		label: i18n.t('operators:fields.password_confirmation_label'),
		type: 'password',
		required: isEdit
	},
	{
		name: 'roles',
		label: i18n.t('operators:fields.role_label'),
		type: 'multi-select',
		mode: 'tags',
		required: true
	},
	{
		name: 'systems',
		label: i18n.t('operators:fields.systems'),
		type: 'multi-select',
		mode: 'multiple',
		required: true
	},
	{
		name: 'active',
		label: i18n.t('operators:fields.active'),
		type: 'checkbox',
		required: false
	}
];
