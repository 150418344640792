import services from 'services/services';
import * as types from '../types';

// Import actions
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH AFFILIATE PARTNERS ********************
export const fetchAffiliatePartners = (options) => async (
	dispatch,
	getState
) => {
	try {
		dispatch({
			type: types.FETCH_AFFILIATE_PARTNERS_LOADING,
			payload: true
		});

		const {
			affiliate_partners: {
				table: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `/affiliate?${filterUrlQuery}${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex
		)}`;

		const {
			data: { data }
		} = await services.get(url);

		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		dispatch({
			type: types.FETCH_AFFILIATE_PARTNERS_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_AFFILIATE_PARTNERS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE AFFILIATE PARTNER ********************
export const deleteAffiliatePartner = (id) => async (dispatch) => {
	try {
		dispatch({
			type: types.DELETE_AFFILIATE_PARTNER_LOADING,
			payload: true
		});

		await services.delete(`/affiliate/${id}`);

		dispatch({
			type: types.DELETE_AFFILIATE_PARTNER_SUCCESS,
			payload: true
		});
	} catch (error) {
		dispatch({
			type: types.DELETE_AFFILIATE_PARTNER_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET SEARCH QUERY ********************
export const setAffiliatePartnersQuery = (query) => ({
	type: types.SET_AFFILIATE_PARTNERS_SEARCH_QUERY,
	payload: query
});
