import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	fetchIngesterEncoding,
	setIngesterEncodingQuery,
	refreshIngesterEncoding,
	fetchIngesterEncodingStats,
	createFiltersSuggestions
} from 'store/actions';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';
import useAuthCheck from 'hooks/useAuthCheck';

// Import columns
import { createColumns } from './ingester_encoding_columns';

// Import helpers
import { ENCODING } from 'components/helpers/variables';
import { MCStatus } from 'components/helpers/dictionary';
import { fetchEncoderIdData } from 'components/helpers/encoding_helpers/encoding_helpers';

// Import utilities
import Search from 'components/utilities/search/Search';
import MultiProgessBar from 'components/views/ingester/helpers/progress_bar/MultiProgressBar';
import StatsInfo from 'components/views/ingester/helpers/stats_info/StatsInfo';

// Import api
import { ENCODER_ID_DATA } from 'components/helpers/api';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const IngesterEncoding = ({
	error,
	resources,
	fetchIngesterEncoding,
	setIngesterEncodingQuery,
	refreshIngesterEncoding,
	fetchIngesterEncodingStats,
	createFiltersSuggestions,
	stats
}) => {
	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	useEffect(() => {
		fetchIngesterEncodingStats();
		// eslint-disable-next-line
	}, []);

	// pass filters suggestions data to redux
	const filters = { mc_status: MCStatus };

	// execute filters logic
	useFilterLogic({ createFiltersSuggestions, filters });

	const handleCreateColumns = () =>
		createColumns({
			isReadOnly,
			queryKey: ENCODER_ID_DATA,
			handleFetchData: fetchEncoderIdData,
			refreshAction: refreshIngesterEncoding
		});

	const handleRefreshData = async () => {
		await refreshIngesterEncoding();
		await fetchIngesterEncodingStats();
	};

	return (
		// Dispatch fetchIngesterEncoding action in ingester_panel
		<PanelPageTemplate
			error={error}
			type="ingester_encoding"
			resources={resources}
			columns={handleCreateColumns}
			fetchResourcesAction={fetchIngesterEncoding}
			defaultSorted={[{ id: 'created_at', desc: true }]}
		>
			<>
				<MultiProgessBar total={stats.total} finished={stats.finished} />
				<StatsInfo refreshData={handleRefreshData} statsType={ENCODING} />
				<div className="d-flex justify-content-end">
					<Search setQueryValue={setIngesterEncodingQuery} />
					<FilterButton tableColumns={resources.columns} />
				</div>
			</>
		</PanelPageTemplate>
	);
};

IngesterEncoding.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	setIngesterEncodingQuery: PropTypes.func.isRequired,
	fetchIngesterEncoding: PropTypes.func.isRequired,
	refreshIngesterEncoding: PropTypes.func.isRequired,
	fetchIngesterEncodingStats: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	stats: PropTypes.object.isRequired
};

const mapStateToProps = ({
	ingester: {
		encoding_table,
		stats: { encoding }
	}
}) => ({
	error: encoding_table.error,
	resources: encoding_table,
	stats: encoding
});

export default connect(mapStateToProps, {
	fetchIngesterEncoding,
	setIngesterEncodingQuery,
	refreshIngesterEncoding,
	fetchIngesterEncodingStats,
	createFiltersSuggestions
})(IngesterEncoding);
