import React from 'react';
import { Field } from 'react-final-form';
// Import fields
import FIELDS_VALUES from './helpers/fields';
// Import components
import { InputLabelLogin } from 'components/common/inputs';

const Fields = () =>
	Object.values(FIELDS_VALUES).map((field) => (
		<Field
			key={field.name}
			label={field.placeholder}
			placeholder={field.placeholder}
			id={field.name}
			name={field.name}
			type={field.type}
			className={`login__input ${field.className}`}
			autoComplete={field.autoComplete}
			required={field.required}
			component={InputLabelLogin}
			data-testid={field.test_id}
		/>
	));

export default Fields;
