import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	edit: {
		type: 'category',
		parent_uuid: null,
		Name: '',
		ShortSynopsis: '',
		Ordinal: '',
		IsAdult: false,
		active: 1,
		section_id: null,
		selectedProperties: [],
		translations: []
	},
	isLoaded: true,
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR BANNER STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// ****************** FETCH COGNITO CATEGORY ******************
			case types.FETCH_COGNITO_CATEGORY_LOADING:
				draft.isLoaded = false;
				return;

			case types.FETCH_COGNITO_CATEGORY_SUCCESS:
				draft.edit = action.payload;
				draft.isLoaded = true;
				return;

			case types.FETCH_COGNITO_CATEGORY_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
