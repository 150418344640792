import i18n from 'i18next';

export const SOFTWARE_FILTER_FIELDS = [
	{
		name: 'force',
		label: i18n.t('filters:fields.force'),
		type: 'checkbox',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'start_date',
		label: i18n.t('filters:fields.start_date'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'version_name',
		label: i18n.t('filters:fields.version_name'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'version_code',
		label: i18n.t('filters:fields.version_code'),
		type: 'number',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'sn_from',
		label: i18n.t('filters:fields.sn_from'),
		type: 'number',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'sn_to',
		label: i18n.t('filters:fields.sn_to'),
		type: 'number',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'platform',
		label: i18n.t('filters:fields.platform'),
		type: 'multi-select',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	}
];
