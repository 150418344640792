import { useMutation } from 'react-query';
import services from 'services/services';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';
import { useToggle } from 'hooks';

// Import translation
import { useTranslation } from 'react-i18next';

// Import api
import { EXPORT_BARKER_CHANNEL_API } from 'components/helpers/api';

export default function useExportButton({ id }) {
	const { t } = useTranslation();
	const [isOpened, toggle] = useToggle();

	const handleExport = (id) => {
		const url = EXPORT_BARKER_CHANNEL_API.replace(':uuid', id);
		return services.post(url);
	};

	const handleSuccess = () => {
		toggle();
		notificationHandler(
			t('messages:notifications.commissioned'),
			'',
			'success',
			5
		);
	};

	const handleError = () => {
		toggle();
		notificationHandler(
			t('common:errors.common_error_message'),
			'',
			'error',
			5
		);
	};

	const { mutate: exportBarkerChannel, isLoading } = useMutation({
		mutationFn: handleExport,
		onSuccess: handleSuccess,
		onError: handleError
	});

	const handleConfirm = () => {
		exportBarkerChannel(id);
	};

	return { confirm: handleConfirm, isLoading, toggle, isOpened };
}
