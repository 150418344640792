import { combineReducers } from 'redux';

// Import reducers
import table from './affiliate_partners_table_reducer';
import form from './affiliate_partners_form_reducer';

export default combineReducers({
	table,
	form
});
