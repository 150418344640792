// Urls
export const FETCH_DOCUMENT_API = '/documents/:id';
export const FETCH_UPLOADED_FILES_API =
	'assets/uuid/list?model_type=modelType&collection=collectionName';
export const SYNC_ASSET_ORDER_API = '/assets/updateOrder';
export const CREATE_NEW_ASSET_API = '/assets/create';

// Keys
export const FETCH_DOCUMENT_ASSETS_QUERY_KEY =
	'FETCH_DOCUMENT_ASSETS_QUERY_KEY';
export const UPDATE_DOCUMENT_ASSETS_QUERY_KEY =
	'UPDATE_DOCUMENT_ASSETS_QUERY_KEY';
export const UPLOAD_DOCUMENT_ASSET_QUERY_KEY =
	'UPLOAD_DOCUMENT_ASSET_QUERY_KEY';
