import React, { useEffect } from 'react';
import { object, bool, func, array, shape, string } from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Import actions
import {
	fetchSeason,
	fetchCategories,
	fetchProviders,
	fetchTagsByCategory
} from 'store/actions';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import ProductizationNote from 'components/utilities/info/ProductizationNote';

// Import helpers
import {
	vodCategoriesOptions,
	convertGenresToNames,
	convertToIds
} from 'components/helpers/genres_helpers/helpers';
import { convertProvidersToId } from 'components/views/vod/helpers/helpers';

const SeasonForm = ({
	history,
	fetchSeason,
	fetchCategories,
	fetchProviders,
	fetchTagsByCategory,
	error,
	isLoaded,
	initialValues,
	genresList,
	providersList,
	paymentsModelsList,
	location: { state },
	match: {
		params: { id }
	},
	categoriesTags
}) => {
	useEffect(() => {
		if (id) localStorage.setItem('season', id);
		// Fetch categories with default params to feed genres combobox
		fetchCategories(vodCategoriesOptions, null, null);
		// eslint-disable-next-line
	}, []);

	const { t } = useTranslation();

	let seriesId = localStorage.getItem('series');
	const redirectPath = seriesId ? `vod/series/edit/${seriesId}/seasons` : 'vod';

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const {
			type,
			genres,
			number,
			active,
			provider,
			provider_id,
			...metadata
		} = values;

		// selected genres mapped to array of ids
		const genresId = convertToIds(genresList, genres);

		// check if there is state and seriesID variable - fixes undefined error
		let path = state && state.seriesID ? state.seriesID : '';

		// seriesID is passed from series/seasons table
		// it's needed to assing created season to specified series
		// if seriesID is nonexistent there empty string is returned
		const createApi = `vod/series/${path}/season`;
		const updateApi = 'vod/season';

		const seasonApi = isEdit ? createApi : updateApi;

		// resources to submit
		const resources = {
			...initialValues,
			metadata,
			type,
			number,
			active,
			genres: genresId,
			provider,
			provider_id
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			notificationName: 'season',
			api: seasonApi,
			apiSlug: 'update',
			redirectPath
		});
	};

	const {
		metadata,
		type,
		genres,
		number,
		active,
		status,
		subtype,
		provider,
		provider_id,
		payment_models
	} = initialValues;
	const { actors, writers, directors } = metadata;

	// array of genres names that will be passed to multi-select
	// both initial values and data for combobox
	const genresNames = convertGenresToNames(genres);
	const genresListNames = convertGenresToNames(genresList);

	// arrays of provider id's passed to suggestion combobox for form fields
	const providersId = convertProvidersToId(providersList);

	// form initial values
	const formInitialValues = {
		...defaultFieldValues,
		...metadata,
		type,
		genres: genresNames,
		number,
		active,
		provider,
		provider_id,
		payment_models
	};

	return (
		// Dispatch fetchSeason actions in season_form
		<FormTemplate
			fetchResourceToEdit={fetchSeason}
			fetchFormResources={[fetchTagsByCategory, fetchProviders]}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<>
					<ProductizationNote />
					<Form
						initialValues={formInitialValues}
						validate={validate}
						onSubmit={handleOnSubmit({ isEdit, itemID })}
						render={({ handleSubmit, submitting, values }) => (
							<form onSubmit={handleSubmit}>
								<Fields
									isEdit={isEdit}
									submitting={submitting}
									actors={actors}
									writers={writers}
									directors={directors}
									type={type}
									genres={genresListNames}
									status={status}
									subtype={subtype}
									providersId={providersId}
									krritTags={categoriesTags.VOD}
									paymentsModelsList={paymentsModelsList}
									currentValues={values}
								/>
								<FormButtons
									isButtonDisabled={submitting || error}
									path={redirectPath}
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						)}
					/>
				</>
			)}
		</FormTemplate>
	);
};

SeasonForm.propTypes = {
	history: object.isRequired,
	initialValues: object.isRequired,
	fetchSeason: func.isRequired,
	fetchCategories: func.isRequired,
	fetchProviders: func.isRequired,
	fetchTagsByCategory: func.isRequired,
	error: bool.isRequired,
	isLoaded: bool.isRequired,
	genresList: array.isRequired,
	providersList: array.isRequired,
	paymentsModelsList: array.isRequired,
	location: shape({
		state: object
	}),
	match: shape({
		params: shape({ id: string })
	}),
	categoriesTags: object.isRequired
};

const mapStateToProps = ({
	vod: { season_form },
	categories: {
		table: { data }
	},
	providers,
	dictionary,
	tags: { categoriesTags }
}) => ({
	initialValues: season_form.edit,
	error: season_form.error,
	isLoaded: season_form.isLoaded,
	genresList: data,
	providersList: providers.table.data,
	paymentsModelsList: dictionary.paymentModels,
	categoriesTags
});

export default compose(
	connect(mapStateToProps, {
		fetchSeason,
		fetchCategories,
		fetchProviders,
		fetchTagsByCategory
	}),
	withRouter
)(SeasonForm);
