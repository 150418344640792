import services from 'services/services';
import * as types from 'store/actions/types';

// Import helpers
import { checkFetchErrors } from 'store/actions/helpers_actions/convert_helpers';

// ******************** FETCH VIDEO ASSETS PARAM TO EDIT ********************
export const fetchVideoAssetsParam = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_VIDEO_ASSETS_PARAM_LOADING,
			payload: true
		});

		const response = await services.get(
			`/player/product/video/asset/params/${id}`
		);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_VIDEO_ASSETS_PARAM_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_VIDEO_ASSETS_PARAM_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
