import React from 'react';
import { bool } from 'prop-types';

// Import utilities
import { renderFields } from 'components/utilities/form';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import variables
import { SELECT_RESOURCES } from './select_options';

const Fields = ({ submitting }) => {
	return (
		<>
			{BASE_FIELDS.map((item, index) =>
				renderFields({
					item,
					index,
					submitting,
					selectResources: SELECT_RESOURCES
				})
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: bool.isRequired
};

export default Fields;
