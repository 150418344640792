import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setDocumentsQuery,
	fetchDocuments,
	deleteDocument
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './documents_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const Documents = ({
	error,
	setDocumentsQuery,
	fetchDocuments,
	deleteDocument,
	resources
}) => {
	const { t } = useTranslation();

	const { columns } = resources;
	return (
		// Dispatch fetchDocuments, deleteDocument action in subscribers_panel
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="documents"
			addLinkText={t('documents:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchDocuments}
			deleteItemAction={deleteDocument}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setDocumentsQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Documents.propTypes = {
	error: PropTypes.bool.isRequired,
	setDocumentsQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchDocuments: PropTypes.func.isRequired,
	deleteDocument: PropTypes.func.isRequired
};

const mapStateToProps = ({ documents }) => {
	return {
		error: documents.table.error,
		resources: documents.table
	};
};

export default connect(mapStateToProps, {
	setDocumentsQuery,
	fetchDocuments,
	deleteDocument
})(Documents);
