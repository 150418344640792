import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilePdfOutlined } from '@ant-design/icons';

function PdfPreview() {
	const { t } = useTranslation();
	return (
		<div className="document_preview">
			<div className="document_preview_center">
				<div className="d-flex justify-content-center align-items-center">
					<FilePdfOutlined
						className="text-muted m-r-sm"
						style={{ fontSize: '3rem' }}
					/>
					<p className="font-bold text-muted h3 document_preview_title">
						{t('verification:error.pdf')}
					</p>
				</div>
			</div>
		</div>
	);
}

export default PdfPreview;
