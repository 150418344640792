// Import translation
import i18n from 'i18next';

// Import srvices
import services from 'services/services';

// Import components
import { notificationHandler } from 'components/utilities/notifications';

export const SET_ORDINAL_UP = 'SET_ORDINAL_UP';
export const SET_ORDINAL_DOWN = 'SET_ORDINAL_DOWN';

export const setOrdinal = async ({ uuid, ordinal, fetchCognitoTree }) => {
	try {
		await services.patch(`/cognito/categories/${uuid}/ordinal`, {
			ordinal
		});

		notificationHandler(
			i18n.t('messages:notifications.edited'),
			`${i18n.t('messages:notifications.successfuly_edited')} ${i18n.t(
				'names:notification_names.ordinal'
			)}`
		);
	} catch (e) {
		notificationHandler(
			i18n.t('messages:notifications.error'),
			i18n.t('common:errors.common_error_message'),
			'error'
		);
	}

	fetchCognitoTree();
};
