import services from 'services/services';
import { useMutation } from 'react-query';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { useToggle } from 'hooks';

const useRepeatIngesterActionButton = ({
	url,
	showConfirmationModal,
	refreshAction
}) => {
	const { t } = useTranslation();
	const [isOpened, toggle] = useToggle();

	const handleRepeat = () => {
		return services.post(url);
	};

	const handleSuccess = () => {
		notificationHandler(
			t('messages:notifications.commissioned'),
			'',
			'success',
			5
		);

		refreshAction();
	};

	const handleError = (error) => {
		const errorData = error?.response?.data;
		const errorMessage = errorData?.message ?? '';
		const errorContext = errorData?.context ?? '';

		const notificationMessage = JSON.stringify(
			`${errorMessage} ${errorContext}`
		);

		notificationHandler(
			t('common:errors.common_error_message'),
			notificationMessage,
			'error',
			10
		);
	};

	const { mutate: repeat, isLoading } = useMutation({
		mutationFn: handleRepeat,
		onSuccess: handleSuccess,
		onError: handleError
	});

	const handleConfirm = () => {
		repeat();
	};

	const handleButtonClick = () => {
		showConfirmationModal ? toggle() : repeat();
	};

	return {
		isLoading,
		isOpened,
		toggle,
		confirm: handleConfirm,
		buttonClick: handleButtonClick
	};
};

export default useRepeatIngesterActionButton;
