import * as types from 'store/actions/types';
import produce from 'immer';

// Import helpers
import {
	PROVISIONING_STATUS,
	SYSTEM_TYPES_FROM_ENV
} from 'components/helpers/variables';
import { translateSubStatuses } from 'components/helpers/translation_helpers';
import { createSystemNamesSuggestions } from 'components/helpers/name_mapping';
import { convertMainToValuePairs, convertEntriesToValuePairs } from './helpers';

export const INITIAL_STATE = {
	videoFormats: {},
	productTypes: {},
	videoTypes: {},
	assetsCollectionTypes: {},
	counterTypes: {},
	ipRangeTypes: {},
	packetsTypes: {},
	parentalControlRating: {},
	paymentMethods: {},
	playModes: {},
	reminderTypes: {},
	sectionTypes: {},
	sections: [],
	agreementTypes: {},
	platformTypes: [],
	paymentModels: [],
	castTypes: [],
	VODProviders: {},
	// status is hardcoded for now - more statuses will be added in future
	status: PROVISIONING_STATUS,
	// subscriber systems are taken from env file
	subscriberSystem: createSystemNamesSuggestions(SYSTEM_TYPES_FROM_ENV),
	VODSubStatus: [],
	customProperties: [],
	customPropertiesValues: [],
	subscriberSource: [],
	voivodeshipCodes: [],
	systems: [],
	subSystems: [],
	channels: [],
	providers: [],
	currentSystemWithChildren: [],
	provisioning: [],
	catchup_recording_state: [],
	drmTemplates: []
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************** VIDEO FORMATS ********************
			case types.DICTIONARY_VIDEO_FORMATS:
				draft.videoFormats = Object.values(action.payload);
				break;

			// ******************** PRODUCT TYPES ********************
			case types.DICTIONARY_PRODUCT_TYPES:
				draft.productTypes = Object.values(action.payload);
				break;

			// ******************** VIDEO TYPES ********************
			case types.DICTIONARY_VIDEO_TYPES:
				draft.videoTypes = Object.values(action.payload);
				break;

			// ******************** ASSETS COLLECTION TYPES ********************
			case types.DICTIONARY_ASSETS_COLLECTION_TYPES:
				draft.assetsCollectionTypes = Object.values(action.payload);
				break;

			// ******************** COUNTER TYPES ********************
			case types.DICTIONARY_COUNTER_TYPES:
				draft.counterTypes = Object.values(action.payload);
				break;

			// ******************** IP RANGE TYPES ********************
			case types.DICTIONARY_IP_RANGE_TYPES:
				draft.ipRangeTypes = Object.values(action.payload);
				break;

			// ******************** PACKETS TYPES ********************
			case types.DICTIONARY_PACKETS_TYPES:
				draft.packetsTypes = Object.values(action.payload);
				break;

			// ******************** PACKET PERMISSIONS ********************
			case types.DICTIONARY_PACKET_PERMISSIONS:
				draft.packetPermissions = Object.values(action.payload);
				break;

			// ******************** PARENTAL CONTROL RATING ********************
			case types.DICTIONARY_PARTENTAL_CONTROL_RATING:
				draft.parentalControlRating = Object.values(action.payload);
				break;

			// ******************** PAYMENT METHODS ********************
			case types.DICTIONARY_PAYMENT_METHODS:
				draft.paymentMethods = Object.values(action.payload);
				break;

			// ******************** PLAY MODES ********************
			case types.DICTIONARY_PLAY_MODES:
				draft.playModes = Object.values(action.payload);
				break;

			// ******************** REMINDER TYPES ********************
			case types.DICTIONARY_REMINDER_TYPES:
				draft.reminderTypes = Object.values(action.payload);
				break;

			// ******************** SECTION TYPES ********************
			case types.DICTIONARY_SECTION_TYPES:
				draft.sectionTypes = Object.values(action.payload);
				break;

			// ******************** AGREEMENTS TYPES ********************
			case types.DICTIONARY_AGREEMENTS_TYPES:
				draft.agreementTypes = Object.values(action.payload);
				break;

			// ******************** PLATFORMS TYPES ********************
			case types.DICTIONARY_PLATFORMS_TYPES:
				draft.platformTypes = Object.values(action.payload);
				break;

			// ******************** PAYMENT MODELS ********************
			case types.DICTIONARY_PAYMENT_MODELS:
				draft.paymentModels = Object.values(action.payload);
				break;

			// ******************** CAST TYPES ********************
			case types.DICTIONARY_CAST_TYPES:
				draft.castTypes = Object.values(action.payload);
				break;

			// ******************** VOD PROVIDERS ********************
			case types.DICTIONARY_VOD_PROVIDERS:
				draft.VODProviders = action.payload;
				break;

			// ******************** VOD SUBSTATUS ********************
			case types.DICTIONARY_VOD_SUBSTATUS:
				draft.VODSubStatus = translateSubStatuses(action.payload);
				break;

			// ******************** CUSTOM PROPERTIES ********************
			case types.DICTIONARY_CUSTOM_PROPERTIES:
				draft.customProperties = action.payload;
				break;

			// ******************** CUSTOM PROPERTIES VALUES ********************
			case types.DICTIONARY_CUSTOM_PROPERTIES_VALUES:
				draft.customPropertiesValues = action.payload;
				break;

			// ******************** SECTIONS ********************
			case types.DICTIONARY_SECTIONS:
				draft.sections = action.payload;
				break;

			// ******************** SUBSCRIBER SOURCE ********************
			case types.DICTIONARY_SUBSCRIBER_SOURCE:
				draft.subscriberSource = Object.entries(action.payload);
				break;

			// ******************** VOIVODESHIP_CODES ********************
			case types.DICTIONARY_VOIVODESHIP_CODES:
				draft.voivodeshipCodes = action.payload;
				break;

			// ******************** SYSTEMS ********************
			case types.DICTIONARY_SYSTEMS:
				draft.systems = action.payload;
				break;

			// ******************** SUBSYSTEMS ********************
			case types.DICTIONARY_SUBSYSTEMS:
				draft.subSystems = action.payload;
				break;

			// ******************** PROVISIONING ********************
			case types.DICTIONARY_PROVISIONING:
				draft.provisioning = convertMainToValuePairs(action.payload);
				break;

			// ******************** MODEL TYPES ********************
			case types.DICTIONARY_MODEL_TYPES:
				draft.model_types = convertEntriesToValuePairs(action.payload);
				break;

			// ******************** CHANNELS ********************
			case types.DICTIONARY_CHANNELS:
				draft.channels = action.payload;
				break;
			// ******************** PROVIDERS ********************
			case types.DICTIONARY_PROVIDERS:
				draft.providers = action.payload;
				break;
			// ******************** CURRENT SYSTEM WITH CHILDREN ********************
			case types.DICTIONARY_CURRENT_SYSTEM_WITH_CHILDREN:
				draft.currentSystemWithChildren = action.payload;
				break;
			// ******************** CATCHUP RECORDING STATES ********************
			case types.DICTIONARY_CATCHUP_RECORDING_STATES:
				draft.catchup_recording_state = action.payload;
				break;
			// ******************** DRM TEMPLATES ********************
			case types.DICTIONARY_DRM_TEMPLATES:
				draft.drmTemplates = action.payload;
				break;

			default:
				return state;
		}
	});
