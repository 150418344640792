import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { setSeasonsQuery, fetchSeasons, deleteSeason } from 'store/actions';
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './seasons_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const Seasons = ({
	error,
	setSeasonsQuery,
	fetchSeasons,
	deleteSeason,
	resources,
	match: {
		params: { id }
	}
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	return (
		// Dispatch fetchSeasons, deleteSeason action in seasons_table
		<PanelPageTemplate
			itemId={id}
			isPageHeadingVisible={false}
			error={error}
			type="vod/season"
			typeText="season"
			addLinkText={t('vod:buttons.add_season')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchSeasons}
			deleteItemAction={deleteSeason}
			linkParams={{ state: { seriesID: id } }}
			notificationDeleteSuccessTxt={t(
				'vod:seasons:notification.delete_success'
			)}
			notificationDeleteErrorTxt={t('vod:seasons:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search setQueryValue={setSeasonsQuery} />
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Seasons.propTypes = {
	error: PropTypes.bool.isRequired,
	setSeasonsQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchSeasons: PropTypes.func.isRequired,
	deleteSeason: PropTypes.func.isRequired
};

const mapStateToProps = ({ vod: { season_table } }) => ({
	error: season_table.error,
	resources: season_table
});

export default compose(
	connect(mapStateToProps, {
		setSeasonsQuery,
		fetchSeasons,
		deleteSeason
	}),
	withRouter
)(Seasons);
