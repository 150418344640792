import React from 'react';

import { useEffect, useState } from 'react';
import services from 'services/services';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

// Import helpers
import { checkFetchErrors } from 'components/helpers/error';

const useVodSubtitlesActions = (videoId) => {
	const isCurrent = React.createRef(false);
	const { t } = useTranslation();

	const [data, setData] = useState([]);
	const [fetchError, setFetchError] = useState(false);

	const fetchVodSubtitles = async () => {
		try {
			const { data } = await services.get(`/assets/video/${videoId}/subtitles`);

			isCurrent.current && setData(data.data);
		} catch (error) {
			isCurrent.current && setFetchError(checkFetchErrors(error));
		}
	};

	const deleteSubtitle = async (uuid) => {
		try {
			await services.delete(`/assets/subtitles/${uuid}`);

			setData(data.filter(({ uuid: itemUuid }) => itemUuid !== uuid));

			notificationHandler(
				t('messages:notifications.deleted'),
				t('vod_subtitles:notification.delete_success'),
				'success'
			);
		} catch (error) {
			if (error.response.status !== 401) {
				notificationHandler(
					t('messages:notifications.error'),
					t('vod_subtitles:notification.delete_error'),
					'error'
				);
			}
		}
	};

	useEffect(() => {
		isCurrent.current = true;
		return () => {
			isCurrent.current = false;
		};
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		fetchVodSubtitles();
		// eslint-disable-next-line
	}, []);

	return { deleteSubtitle, data, fetchError };
};

export default useVodSubtitlesActions;
