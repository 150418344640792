import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { InputLabel } from 'components/common/inputs';
import CustomPropertiesFields from './CustomPropertiesFields';

// Import utilites
import { renderFields } from 'components/utilities/form';

// Import fields
import { COGNITO_FIELDS } from './input_fields';

// Import helpers
import { getUrl } from './helpers';
import { getIsEmptyText } from '../helpers';

// Import variables
import { IS_EMPTY } from './input_fields';

const Fields = ({
	submitting,
	isEdit,
	customProperties,
	customPropertiesValues,
	currentSectionUrl,
	currentValues,
	change
}) => {
	const { t } = useTranslation();

	const sectionsUrl = getUrl({ url: '/section', column: 1 });

	const handleShowEmptyChange = () => {
		const emptyText = getIsEmptyText({ formValues: currentValues });
		change(IS_EMPTY, t(emptyText));
	};

	return (
		<>
			{COGNITO_FIELDS({
				currentValues,
				isEdit,
				currentSectionUrl,
				sectionsUrl,
				handleShowEmptyChange
			}).map((item, index) => renderFields({ item, index, submitting }))}

			<CustomPropertiesFields
				properties={customProperties}
				propertiesValues={customPropertiesValues}
			/>

			{!isEdit && (
				<>
					<Field
						disabled={true}
						name="created_at"
						type="text"
						placeholder={t('menu_stb:fields.created_at')}
						label={t('menu_stb:fields.created_at')}
						id="created_at"
						required={false}
						component={InputLabel}
					/>
					<Field
						disabled={true}
						name="updated_at"
						type="text"
						placeholder={t('menu_stb:fields.updated_at')}
						label={t('menu_stb:fields.updated_at')}
						id="updated_at"
						required={false}
						component={InputLabel}
					/>
				</>
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired,
	currentValues: PropTypes.object.isRequired,
	change: PropTypes.func.isRequired
};

export default Fields;
