import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchSectionsPages } from 'store/actions';

// Import components
import { PanelPageTemplate } from 'components/common/templates';

// Import columns
import { createColumns } from './sections_pages_columns';

// Import translation
import { useTranslation } from 'react-i18next';

const SectionsPages = ({
	error,
	resources,
	fetchSectionsPages,
	match: {
		params: { id }
	}
}) => {
	const { t } = useTranslation();

	return (
		<PanelPageTemplate
			itemId={id}
			title={t('common:list')}
			error={error}
			type="sections_pages"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchSectionsPages}
		/>
	);
};

SectionsPages.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchSectionsPages: PropTypes.func.isRequired
};

const mapStateToProps = ({ sections: { pages } }) => ({
	error: pages.error,
	resources: pages
});

export default compose(
	connect(mapStateToProps, {
		fetchSectionsPages
	}),
	withRouter
)(SectionsPages);
