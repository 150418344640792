import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	fetchOperatorRoles,
	fetchOperator,
	dictionarySubSystems
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const OperatorsForm = ({
	history,
	fetchOperatorRoles,
	fetchOperator,
	error,
	isLoaded,
	initialValues,
	roles,
	dictionarySubSystems,
	systems
}) => {
	const { t } = useTranslation();

	const systemsArray = systems.map(({ name }) => name);

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) =>
		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: 'operator',
			redirectPath: 'operators'
		});

	const defaultValues = { roles: [], systems: [] };

	const initialFormValues = { ...defaultValues, ...initialValues };

	useEffect(() => {
		dictionarySubSystems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		// Dispatch fetchOperatorRoles, fetchOperator actions in in operators_form
		<FormTemplate
			fetchFormResources={[fetchOperatorRoles]}
			fetchResourceToEdit={fetchOperator}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={initialFormValues}
					validate={(values) => validate({ ...values, isEdit })}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								roles={roles}
								systems={systemsArray}
								submitting={submitting}
								isEdit={isEdit}
							/>
							<FormButtons
								isButtonDisabled={submitting || error}
								path="operators"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

OperatorsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchOperatorRoles: PropTypes.func.isRequired,
	fetchOperator: PropTypes.func.isRequired,
	roles: PropTypes.array.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	dictionarySubSystems: PropTypes.func.isRequired,
	systems: PropTypes.array.isRequired
};

const mapStateToProps = ({ operators: { form }, dictionary }) => {
	return {
		initialValues: form.edit,
		roles: form.roles,
		systems: dictionary.subSystems,
		error: form.error,
		isLoaded: form.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchOperatorRoles,
		fetchOperator,
		dictionarySubSystems
	}),
	withRouter
)(OperatorsForm);
