// ******************** MAIN DATA TABLE ********************
export const FETCH_MAIN_LOADING = 'FETCH_MAIN_LOADING';
export const FETCH_MAIN_SUCCESS = 'FETCH_MAIN_SUCCESS';
export const FETCH_MAIN_ERROR = 'FETCH_MAIN_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_MAIN_SEARCH_QUERY = 'SET_MAIN_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_MAIN_TABLE_SORTING = 'SAVE_MAIN_TABLE_SORTING';

// ******************** DELETE MAIN_ITEM ********************
export const DELETE_MAIN_ITEM_LOADING = 'DELETE_MAIN_ITEM_LOADING';
export const DELETE_MAIN_ITEM_SUCCESS = 'DELETE_MAIN_ITEM_SUCCESS';
export const DELETE_MAIN_ITEM_ERROR = 'DELETE_MAIN_ITEM_ERROR';

// ******************** MAIN_ITEM ********************
export const FETCH_MAIN_ITEM_LOADING = 'FETCH_MAIN_ITEM_LOADING';
export const FETCH_MAIN_ITEM_SUCCESS = 'FETCH_MAIN_ITEM_SUCCESS';
export const FETCH_MAIN_ITEM_ERROR = 'FETCH_MAIN_ITEM_ERROR';

// ******************** FILES ********************
// -------------------- COVER --------------------
export const FETCH_MAIN_ITEM_COVER_FILES_LOADING =
	'FETCH_MAIN_ITEM_COVER_FILES_LOADING';
export const FETCH_MAIN_ITEM_COVER_FILES_SUCCESS =
	'FETCH_MAIN_ITEM_COVER_FILES_SUCCESS';
export const FETCH_MAIN_ITEM_COVER_FILES_ERROR =
	'FETCH_MAIN_ITEM_COVER_FILES_ERROR';
// -------------------- COVER_LARGE --------------------
export const FETCH_MAIN_ITEM_COVER_LARGE_FILES_LOADING =
	'FETCH_MAIN_ITEM_COVER_LARGE_FILES_LOADING';
export const FETCH_MAIN_ITEM_COVER_LARGE_FILES_SUCCESS =
	'FETCH_MAIN_ITEM_COVER_LARGE_FILES_SUCCESS';
export const FETCH_MAIN_ITEM_COVER_LARGE_FILES_ERROR =
	'FETCH_MAIN_ITEM_COVER_LARGE_FILES_ERROR';
// -------------------- COVER_SMALL --------------------
export const FETCH_MAIN_ITEM_COVER_SMALL_FILES_LOADING =
	'FETCH_MAIN_ITEM_COVER_SMALL_FILES_LOADING';
export const FETCH_MAIN_ITEM_COVER_SMALL_FILES_SUCCESS =
	'FETCH_MAIN_ITEM_COVER_SMALL_FILES_SUCCESS';
export const FETCH_MAIN_ITEM_COVER_SMALL_FILES_ERROR =
	'FETCH_MAIN_ITEM_COVER_SMALL_FILES_ERROR';
// -------------------- COVER_DETAIL --------------------
export const FETCH_MAIN_ITEM_COVER_DETAIL_FILES_LOADING =
	'FETCH_MAIN_ITEM_COVER_DETAIL_FILES_LOADING';
export const FETCH_MAIN_ITEM_COVER_DETAIL_FILES_SUCCESS =
	'FETCH_MAIN_ITEM_COVER_DETAIL_FILES_SUCCESS';
export const FETCH_MAIN_ITEM_COVER_DETAIL_FILES_ERROR =
	'FETCH_MAIN_ITEM_COVER_DETAIL_FILES_ERROR';
// -------------------- COVER_DETAIL_LARGE --------------------
export const FETCH_MAIN_ITEM_COVER_DETAIL_LARGE_FILES_LOADING =
	'FETCH_MAIN_ITEM_COVER_DETAIL_LARGE_FILES_LOADING';
export const FETCH_MAIN_ITEM_COVER_DETAIL_LARGE_FILES_SUCCESS =
	'FETCH_MAIN_ITEM_COVER_DETAIL_LARGE_FILES_SUCCESS';
export const FETCH_MAIN_ITEM_COVER_DETAIL_LARGE_FILES_ERROR =
	'FETCH_MAIN_ITEM_COVER_DETAIL_LARGE_FILES_ERROR';

// ******************** PRODUCTS ********************
export const FETCH_MAIN_ITEM_PRODUCTS_LOADING =
	'FETCH_MAIN_ITEM_PRODUCTS_LOADING';
export const FETCH_MAIN_ITEM_PRODUCTS_SUCCESS =
	'FETCH_MAIN_ITEM_PRODUCTS_SUCCESS';
export const FETCH_MAIN_ITEM_PRODUCTS_ERROR = 'FETCH_MAIN_ITEM_PRODUCTS_ERROR';
// ******************** MATERIALS ********************
// -------------------- FETCH --------------------
export const FETCH_MAIN_ITEM_MATERIALS_LOADING =
	'FETCH_MAIN_ITEM_MATERIALS_LOADING';
export const FETCH_MAIN_ITEM_MATERIALS_SUCCESS =
	'FETCH_MAIN_ITEM_MATERIALS_SUCCESS';
export const FETCH_MAIN_ITEM_MATERIALS_ERROR =
	'FETCH_MAIN_ITEM_MATERIALS_ERROR';
// -------------------- UPDATE --------------------
export const UPDATE_MAIN_ITEM_MATERIALS = 'UPDATE_MAIN_ITEM_MATERIALS';
export const UPDATE_MAIN_ITEM_MATERIALS_ERROR =
	'UPDATE_MAIN_ITEM_MATERIALS_ERROR';
// -------------------- UPDATE --------------------
export const DELETE_MAIN_ITEM_MATERIALS = 'DELETE_MAIN_ITEM_MATERIALS';
export const DELETE_MAIN_ITEM_MATERIALS_ERROR =
	'DELETE_MAIN_ITEM_MATERIALS_ERROR';

// ******************** PRICES ********************
// -------------------- FETCH --------------------
export const FETCH_MAIN_PRICES_LOADING = 'FETCH_MAIN_PRICES_LOADING';
export const FETCH_MAIN_PRICES_SUCCESS = 'FETCH_MAIN_PRICES_SUCCESS';
export const FETCH_MAIN_PRICES_ERROR = 'FETCH_MAIN_PRICES_ERROR';
// -------------------- DELETE --------------------
export const DELETE_MAIN_PRICE_LOADING = 'DELETE_MAIN_PRICE_LOADING';
export const DELETE_MAIN_PRICE_SUCCESS = 'DELETE_MAIN_PRICE_SUCCESS';
export const DELETE_MAIN_PRICE_ERROR = 'DELETE_MAIN_PRICE_ERROR';

// ******************** AVAILABILITY ********************
// -------------------- TABLE --------------------
// -------------------- FETCH --------------------
export const FETCH_MAIN_AVAILABILITY_TABLE_LOADING =
	'FETCH_MAIN_AVAILABILITY_TABLE_LOADING';
export const FETCH_MAIN_AVAILABILITY_TABLE_SUCCESS =
	'FETCH_MAIN_AVAILABILITY_TABLE_SUCCESS';
export const FETCH_MAIN_AVAILABILITY_TABLE_ERROR =
	'FETCH_MAIN_AVAILABILITY_TABLE_ERROR';
// -------------------- DELETE --------------------
export const DELETE_MAIN_AVAILABILITY_LOADING =
	'DELETE_MAIN_AVAILABILITY_LOADING';
export const DELETE_MAIN_AVAILABILITY_SUCCESS =
	'DELETE_MAIN_AVAILABILITY_SUCCESS';
export const DELETE_MAIN_AVAILABILITY_ERROR = 'DELETE_MAIN_AVAILABILITY_ERROR';
// -------------------- FORM --------------------
// -------------------- FETCH --------------------
export const FETCH_MAIN_AVAILABILITY_FORM_LOADING =
	'FETCH_MAIN_AVAILABILITY_FORM_LOADING';
export const FETCH_MAIN_AVAILABILITY_FORM_SUCCESS =
	'FETCH_MAIN_AVAILABILITY_FORM_SUCCESS';
export const FETCH_MAIN_AVAILABILITY_FORM_ERROR =
	'FETCH_MAIN_AVAILABILITY_FORM_ERROR';
