import React from 'react';

// Import components
import Tabs from 'components/utilities/tabs/Tabs';

// Import helpers
import { affiliatePartnersTabs } from './helpers';

const AffiliatePartnersTabs = () => <Tabs tabs={affiliatePartnersTabs} />;

export default AffiliatePartnersTabs;
