import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Import helpers
import { filterOptions } from './helpers';

// Import utilities
import { renderFields } from 'components/utilities/form';

import { BASE_FIELDS } from './input_fields';

const Fields = ({ submitting }) => {
	const { type } = useParams();
	const isPacketMain = type === 'main';
	const { paymentOptions, periodOptions } = filterOptions({ isPacketMain });

	const selectResources = {
		period: periodOptions,
		paymentMethod: paymentOptions
	};

	return (
		<React.Fragment>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
