import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	provisioning: {
		loading: true,
		error: false,
		data: [],
		columns: [],
		deleteItem: {
			success: false,
			error: false
		},
		options: {
			pages: 0,
			total_results: 0,
			filters: {
				query: ''
			}
		}
	},
	subscriptions: {
		loading: true,
		error: false,
		data: [],
		columns: [],
		deleteItem: {
			success: false,
			error: false
		},
		options: {
			pages: 0,
			total_results: 0,
			filters: {
				query: ''
			}
		}
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR DATA ******************
			case types.CLEAR_PANEL_STATE:
				return { ...INITIAL_STATE };

			// *************** FETCH SUBSCRIBER PACKETS ***************
			case types.FETCH_SUBSCRIBER_PACKETS_LOADING:
				draft.provisioning.loading = true;
				draft.provisioning.error = false;
				return;

			case types.FETCH_SUBSCRIBER_PACKETS_SUCCESS:
				draft.provisioning.loading = false;
				draft.provisioning.data = action.payload.products;
				return;

			case types.FETCH_SUBSCRIBER_PACKETS_ERROR:
				draft.provisioning.error = action.payload;
				return;
			// *************** FETCH SUBSCRIBER SUBSCRIPTIONS (MAIN) ***************
			case types.FETCH_SUBSCRIBER_SUBSCRIPTIONS_LOADING:
				draft.subscriptions.loading = true;
				draft.subscriptions.error = false;
				return;

			case types.FETCH_SUBSCRIBER_SUBSCRIPTIONS_SUCCESS:
				draft.subscriptions.loading = false;
				draft.subscriptions.data = action.payload;
				return;

			case types.FETCH_SUBSCRIBER_SUBSCRIPTIONS_ERROR:
				draft.subscriptions.error = action.payload;
				return;

			default:
				return state;
		}
	});
