import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

// Import components
import SubscribersCouponsForm from './SubscribersCouponsForm';

const SubscribersCouponsModal = ({
	isOpened,
	onCancel,
	userId,
	toggle,
	subscriberCouponChange
}) => {
	const { t } = useTranslation();
	return (
		<Modal
			title={t('subscribers:coupons.modal_title')}
			open={isOpened}
			footer={null}
			onCancel={onCancel}
			destroyOnClose={true}
			width={700}
		>
			<SubscribersCouponsForm
				userId={userId}
				toggle={toggle}
				subscriberCouponChange={subscriberCouponChange}
			/>
		</Modal>
	);
};

SubscribersCouponsModal.propTypes = {
	isOpened: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
	toggle: PropTypes.func.isRequired,
	subscriberCouponChange: PropTypes.func.isRequired
};

export default SubscribersCouponsModal;
