import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const NotFound = ({ history }) => {
	const handleBack = () => history.goBack();

	return (
		<div className="not_found gray-bg">
			<div className="middle-box text-center animated fadeIn">
				<h1>404</h1>
				<h3 className="font-bold">Page Not Found</h3>

				<div className="error-desc">
					Sorry, but the page you are looking for has note been found. Try
					checking the URL for error, then hit the refresh button on your
					browser or try found something else in our app.
					<div className="row d-flex justify-content-center">
						<Button className="m-t" onClick={handleBack}>
							Go Back
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

NotFound.propTypes = {
	history: PropTypes.object.isRequired
};

export default NotFound;
