export const getProductUrl = (model_type, model_id) => {
	switch (model_type) {
		case 'audio':
			return `/panel/${model_type}/series/edit/${model_id}/metadata`;

		case 'vod':
			return `/panel/${model_type}/vod/edit/${model_id}/metadata`;

		case 'live_event':
			return `/panel/events_live/edit/${model_id}/metadata`;

		case 'packet':
			return `/panel/main/edit/${model_id}/metadata`;

		case 'genre':
			return `/panel/categories/edit/${model_id}/metadata`;

		case 'products_group':
			return `/panel/institutions/edit/${model_id}/metadata`;

		case 'geoblock':
			return `/panel/geoblock_groups/edit/${model_id}/metadata`;

		default:
			return `/panel/${model_type}s/edit/${model_id}/metadata`;
	}
};

export const getProductType = (model_type) => {
	if (model_type === 'vod') {
		return 'video';
	}

	return model_type;
};
