import React, { useContext } from 'react';
import { Button, Col } from 'antd';

// Import translation
import { useTranslation } from 'react-i18next';

// Import context
import { ProductsContext } from '../ProductsProvider';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

const ProductsButtons = () => {
	const { t } = useTranslation();
	const {
		buttonSubmitText,
		handleOnCancel,
		handleOnSubmit,
		submitting
	} = useContext(ProductsContext);

	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	return (
		!isReadOnly && (
			<Col
				className="d-flex justify-content-end"
				sm={{ size: '10', offset: 2 }}
			>
				<Button
					className="m-r-md m-b-md"
					disabled={submitting}
					onClick={handleOnCancel}
				>
					{t('common:buttons.cancel')}
				</Button>
				<Button
					className="m-b-md"
					data-testid="button"
					disabled={submitting}
					type="primary"
					onClick={handleOnSubmit}
				>
					{buttonSubmitText}
				</Button>
			</Col>
		)
	);
};

export default ProductsButtons;
