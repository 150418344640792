import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Import
import { BASE_FIELDS } from './input_fields';

// Import utils
import { renderFields } from 'components/utilities/form';

// Import helpers
import { getCountiesForSelect, getVoivodeshipsForSelect } from '../helpers';

const Fields = ({ submitting, currentValues, change, modified }) => {
	const selectedVoivodeship = currentValues?.voivodeship;
	const isCountyDisabled = !selectedVoivodeship;

	useEffect(() => {
		modified.voivodeship && change('counties', '');
		//eslint-disable-next-line
	}, [selectedVoivodeship]);

	const selectResources = {
		voivodeship: getVoivodeshipsForSelect(),
		counties: getCountiesForSelect(selectedVoivodeship)
	};

	return (
		<>
			{BASE_FIELDS({ isCountyDisabled }).map((item, index) =>
				renderFields({
					item,
					index,
					submitting,
					selectResources
				})
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool,
	currentValues: PropTypes.object,
	change: PropTypes.func,
	modified: PropTypes.func
};

export default Fields;
