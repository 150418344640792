// Import translation
import i18n from 'i18next';

// Import helpers
import { isUrlValid } from 'components/helpers/validation_helpers';

export default ({
	title,
	description,
	group,
	text_button,
	url_pc,
	url_mobile
}) => {
	const errors = {};

	// ------------------ title ------------------
	if (!title) {
		errors.title = i18n.t('banners:validation.title');
	}

	// ------------------ description ------------------
	if (!description) {
		errors.description = i18n.t('banners:validation.description');
	}

	// ------------------ group ------------------
	if (!group) {
		errors.group = i18n.t('banners:validation.group');
	}

	// ------------------ text button ------------------
	if (text_button?.length > 25) {
		errors.text_button = i18n.t('banners:validation.text_button_length');
	}

	// ------------------ url pc ------------------
	if (!url_pc) {
		errors.url_pc = i18n.t('banners:validation.url_pc');
	} else if (!isUrlValid(url_pc)) {
		errors.url_pc = i18n.t('banners:validation.url_pc_error');
	}

	// ------------------ url_mobile ------------------
	if (!url_mobile) {
		errors.url_mobile = i18n.t('banners:validation.url_mobile');
	} else if (!isUrlValid(url_mobile)) {
		errors.url_mobile = i18n.t('banners:validation.url_mobile_error');
	}

	return errors;
};
