import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { checkResetPasswordHash } from 'store/actions';

// Import components
import ResetPasswordForm from './form/ResetPasswordForm';
import Spin from 'antd/es/spin';
import ResetPasswordContent from './ResetPasswordContent';

// Import translation
import i18n from 'i18next';

const ResetPasswordPage = ({
	match: {
		params: { hash }
	}
}) => {
	const dispatch = useDispatch();
	useEffect(() => {
		checkResetPasswordHash(hash)(dispatch);
		// eslint-disable-next-line
	}, []);

	const {
		checkHash: { isHashValid, loading: checkHashLoading },
		setNewPassword: { isSuccess, loading: setNewPasswordLoading }
	} = useSelector(({ auth }) => auth.resetPassword);

	const isContentLoading = checkHashLoading || setNewPasswordLoading;

	const renderContent = () => {
		if (isHashValid && !isSuccess) {
			return (
				<div className="reset-password__content-text">
					<ResetPasswordForm hash={hash} />
				</div>
			);
		} else if (isSuccess) {
			const successText = i18n.t(
				'login:reset_password.set_new_password.content_text.success'
			);
			return (
				<ResetPasswordContent isSuccess={true} contentText={successText} />
			);
		} else {
			const errorText = i18n.t(
				'login:reset_password.set_new_password.content_text.error'
			);
			return (
				<ResetPasswordContent
					isSuccess={false}
					contentText={errorText}
					linkPath="/login/forgot_password"
					buttonText={'back_to_reset'}
				/>
			);
		}
	};

	return (
		<div className="reset-password">
			<div className="reset-password__screen">
				<div className="reset-password__content">
					<h2 className="font-bold reset-password__header">
						{i18n.t('login:reset_password.set_new_password.heading')}
					</h2>
					{isContentLoading ? (
						<div className="reset_password__spinner-container">
							<Spin size="large" className="reset-password__spinner" />
						</div>
					) : (
						renderContent()
					)}
				</div>
			</div>
		</div>
	);
};
ResetPasswordPage.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({ hash: PropTypes.string.isRequired })
	})
};

export default withRouter(ResetPasswordPage);
