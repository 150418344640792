// Import variables
import { SYSTEM_TYPES_FROM_ENV } from 'components/helpers/variables';

// Import translation
import i18n from 'i18next';

/**
 * Function renders form fields for system visibility based on systems
 * included in env
 * @returns {(null|Array)} - returns array of fields or null, when systems are not specified in env
 */
export const renderSystemVisibilityFields = () => {
	if (SYSTEM_TYPES_FROM_ENV && SYSTEM_TYPES_FROM_ENV.length > 0) {
		return SYSTEM_TYPES_FROM_ENV.map((system) => ({
			name: `active_${system}`,
			label: i18n.t(`common:form.active_${system}`),
			type: 'checkbox',
			required: false
		}));
	} else return null;
};

/**
 * Function that creates default field values for systems fields
 * in availability form based on systems stated in env
 * @returns {object} - returns object with default values or empty object
 */
export const availabilitySystemsDefaultValues = () => {
	if (SYSTEM_TYPES_FROM_ENV && SYSTEM_TYPES_FROM_ENV.length > 0) {
		return SYSTEM_TYPES_FROM_ENV.reduce((obj, system) => {
			return {
				...obj,
				[`active_${system}`]: true
			};
		}, {});
	} else return {};
};
