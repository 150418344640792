import React from 'react';
import PropTypes from 'prop-types';

class Toggle extends React.Component {
	// Decleare propTypes
	static propTypes = {
		children: PropTypes.func.isRequired
	};

	state = {
		on: false
	};

	toggle = () => this.setState({ on: !this.state.on });

	render() {
		const { children } = this.props;
		return children({
			on: this.state.on,
			toggle: this.toggle
		});
	}
}

export default Toggle;
