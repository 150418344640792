import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

// Import components
import { FetchDataModal } from 'components/common/elements';

// BASIC TABLE COLUMNS
export const basicColumns = ({ queryKey, handleFetchData }) => [
	{
		Header: i18n.t('epg_global:table_columns.channel_name'),
		accessor: 'channel_title',
		minWidth: 160,
		Cell: ({
			row: {
				_original: { channel_title, channel_uuid }
			}
		}) => (
			<Link
				to={`/panel/channels/edit/${channel_uuid}/metadata`}
				title={channel_title}
			>
				{channel_title}
			</Link>
		)
	},
	{
		Header: i18n.t('epg_global:table_columns.title'),
		accessor: 'title',
		minWidth: 160,
		Cell: ({
			row: {
				_original: { uuid, title, channel_uuid }
			}
		}) => (
			<Link
				to={`/panel/channels/${channel_uuid}/epg/edit/${uuid}/metadata`}
				title={title}
			>
				{title}
			</Link>
		)
	},
	{
		Header: i18n.t('epg_global:table_columns.since'),
		accessor: 'since',
		minWidth: 160
	},
	{
		Header: i18n.t('epg_global:table_columns.till'),
		accessor: 'till',
		minWidth: 160
	},
	{
		Header: i18n.t('epg_global:table_columns.category'),
		accessor: 'category',
		sortable: false
	},
	{
		Header: i18n.t('epg_global:table_columns.star_rating'),
		accessor: 'star_rating',
		width: 70,
		sortable: false
	},
	{
		Header: i18n.t('epg_global:table_columns.catchup_recording_state'),
		accessor: 'catchup_recording_state',
		width: 120,
		sortable: false
	},
	{
		Header: i18n.t('epg_global:table_columns.catchup_recording_required'),
		accessor: 'catchup_recording_required',
		width: 130,
		sortable: false,
		Cell: ({ row }) => {
			const { uuid, catchup_recording_required } = row._original;
			const isVisible = !!catchup_recording_required;
			return (
				<div className="d-flex justify-content-center">
					<FetchDataModal
						id={uuid}
						queryKey={queryKey}
						handleFetchData={handleFetchData}
						showIcon={isVisible}
						isVisible={isVisible}
						dataAccessPath={'data.data.media_coder.data.fullMediaCoderResponse'}
					/>
				</div>
			);
		}
	}
];

export const createColumns = (channelId) => basicColumns(channelId);
