import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	fetchIngesterAssetsVodDetails,
	refreshIngesterAssets
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './ingester_assets_vod_details_columns';

// Import components
import ReactTable from 'react-table';
import { Empty } from 'antd';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

/**
 * Functional component responsible for rendering table with ingester assets data for specific vod
 */
function IngesterAssetsVodDetails({
	vodId,
	resources,
	fetchIngesterAssetsVodDetails,
	refreshIngesterAssets
}) {
	const { t } = useTranslation();

	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	useEffect(() => {
		fetchIngesterAssetsVodDetails(vodId);
		// eslint-disable-next-line
	}, [JSON.stringify(resources)]);

	return resources.length > 0 ? (
		<ReactTable
			data={resources}
			columns={createColumns({
				isReadOnly,
				refreshAction: refreshIngesterAssets
			})}
			pageSize={resources.length}
			showPagination={false}
		/>
	) : (
		<Empty description={t('common:no_data')} />
	);
}

IngesterAssetsVodDetails.propTypes = {
	vodId: PropTypes.string.isRequired,
	resources: PropTypes.array.isRequired,
	fetchIngesterAssetsVodDetails: PropTypes.func.isRequired,
	refreshIngesterAssets: PropTypes.func
};

const mapStateToProps = ({
	ingester: {
		assets_table: { data }
	}
}) => ({
	resources: data
});

export default connect(mapStateToProps, {
	fetchIngesterAssetsVodDetails,
	refreshIngesterAssets
})(IngesterAssetsVodDetails);
