// Import translation
import i18n from 'i18next';

export default ({
	firstName,
	roles,
	lastName,
	email,
	password,
	password_confirmation,
	isEdit,
	systems
}) => {
	const errors = {};

	// ------------------ Name ------------------
	if (!firstName) {
		errors.firstName = i18n.t('operators:validation.first_name_required');
	} else if (firstName.length < 2 || firstName.length > 200) {
		errors.firstName = i18n.t('operators:validation.first_name_length');
	}
	// ------------------ Last name ------------------
	if (!lastName) {
		errors.lastName = i18n.t('operators:validation.last_name_required');
	} else if (lastName.length < 2 || lastName.length > 200) {
		errors.lastName = i18n.t('operators:validation.last_name_length');
	}
	// ------------------ Email ------------------
	if (!email) {
		errors.email = i18n.t('operators:validation.email_required');
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
		errors.email = i18n.t('operators:validation.email_wrong');
	}
	// ------------------ Password ------------------
	if (!password && isEdit) {
		errors.password = i18n.t('operators:validation.password_required');
	} else if (password && (password.length < 6 || password.length > 100)) {
		errors.password = i18n.t('operators:validation.password_length');
	}

	if (
		password !== '' &&
		password_confirmation !== '' &&
		password !== password_confirmation
	) {
		errors.password = i18n.t('operators:validation.password_not_same');
		errors.password_confirmation = i18n.t(
			'operators:validation.password_not_same'
		);
	}
	// ------------------ Roles ------------------
	if (roles.length === 0) {
		errors.roles = i18n.t('operators:validation.role_select');
	}

	// ------------------ Roles ------------------
	if (systems.length === 0) {
		errors.systems = i18n.t('operators:validation.systems_select');
	}

	return errors;
};
