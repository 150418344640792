import { combineReducers } from 'redux';
// Import reducers
import table from './sections_groups_panel_reducer';
import form from './sections_group_form_reducer';
import sections_assigned from './sections_assigned_reducer';

export default combineReducers({
	table,
	form,
	sections_assigned
});
