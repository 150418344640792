import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import helpers
import { renderSystem } from 'components/helpers/system';

// Import components
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

export const createColumns = () => [
	{
		Header: i18n.t('devices_sn:table_columns.active'),
		accessor: 'active',
		width: 75,
		sortable: true,
		headerClassName: 'text-center',
		className: 'text-center',
		Cell: ({
			row: {
				_original: { active }
			}
		}) => <ActiveIcon active={active} />
	},
	{
		Header: i18n.t('devices_sn:table_columns.serial_number'),
		accessor: 'serial_number',
		minWidth: 250,
		Cell: ({
			row: {
				_original: { id, serial_number }
			}
		}) => (
			<Link to={`/panel/devices_sn/edit/${id}/metadata`}>{serial_number}</Link>
		)
	},
	{
		Header: i18n.t('devices_sn:table_columns.login'),
		accessor: 'login',
		className: 'scroll-cell'
	},
	{
		Header: i18n.t('devices_sn:table_columns.os_system'),
		accessor: 'system',
		className: 'scroll-cell',
		Cell: ({
			row: {
				_original: { system }
			}
		}) => <span>{renderSystem(system)}</span>
	},
	{
		Header: i18n.t('devices_sn:table_columns.source'),
		accessor: 'source',
		className: 'scroll-cell',
		width: 100
	}
];
