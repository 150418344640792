import { useSelector } from 'react-redux';

// Import roles
import {
	allRoles,
	cms,
	crm,
	data,
	portal,
	ingestion,
	admin,
	video,
	support,
	system_operator
} from 'components/helpers/roles_variables';

// Import hooks
import useAuthSystemCheck from './useAuthSystemCheck';

/**
 * Custom hook checking read/write authorization across app
 */
const useAuthCheck = () => {
	const userRoles = useSelector((state) => state.auth.authenticate.roles);

	const { isReadOnlyForCurrentSystem, locationType } = useAuthSystemCheck();

	// check roles based on location type
	let locationRoles = [];
	switch (locationType) {
		case 'channels':
		case 'epg_global':
		case 'localChannels':
		case 'vod':
		case 'packets':
		case 'promotions':
		case 'coupons':
		case 'limits':
		case 'payment_templates':
		case 'use_payment_template':
		case 'mass_changes':
			locationRoles = cms;
			break;

		case 'subscribers':
			locationRoles = [...crm, ...support];
			break;

		case 'devices':
		case 'devices_sn':
		case 'provisioning':
			locationRoles = crm;
			break;

		case 'payments':
		case 'trackings':
			locationRoles = data;
			break;

		case 'categories':
		case 'documents':
		case 'agreements':
		case 'sections_groups':
		case 'sections':
		case 'banners':
		case 'main':
		case 'tags':
		case 'menu_stb':
		case 'barker_channels':
			locationRoles = portal;
			break;

		case 'ingester':
			locationRoles = ingestion;
			break;

		case 'operators':
		case 'geolocks':
		case 'topology':
		case 'marketing_campaigns':
		case 'software':
		case 'settings':
		case 'notifications':
		case 'providers':
		case 'affiliate_partners':
			locationRoles = admin;
			break;

		case 'systems':
			locationRoles = [...admin, ...system_operator];
			break;

		default:
			break;
	}
	// look for write role in location and return it
	const locationWriteRoles = locationRoles.filter((role) =>
		role.includes('WRITE')
	);

	// look for video write role and return it
	const videoWriteRole = video.filter((role) => role.includes('WRITE'))[0];

	const isSystemOperator = userRoles.includes('SYSTEM_OPERATOR');

	const writeRoleException = [allRoles.CRM_READ];

	// check if there is specific write role in roles assigned to user
	// used in most cases exept video
	const isReadOnlyForUser = isSystemOperator
		? false
		: !userRoles.some((role) => locationWriteRoles.includes(role));
	// user has two types of restrictions: user restrictions and system restrictions
	// if user has editing permissions, also check their system permissions
	const isReadOnly = isReadOnlyForUser || isReadOnlyForCurrentSystem;

	const canManageUserDevices =
		!isReadOnly || userRoles.some((role) => writeRoleException.includes(role));

	// if user has only data_read role
	const hasOnlyDataReadRole =
		userRoles.length === 1 && userRoles[0] === allRoles.DATA_READ;

	// determine if support role should be active based on other roles
	const isSupportRoleActive = () => {
		const isCMSLocation = locationRoles.some((role) => cms.includes(role));
		const isCRMLocation = locationRoles.some((role) => crm.includes(role));
		const userHaveSupportRole = userRoles.includes(allRoles.SUPPORT_WRITE);
		const userHaveAnyCMSRole = userRoles.some((role) => cms.includes(role));
		const userHaveAnyCRMRole = userRoles.some((role) => crm.includes(role));

		// if there is only support role then it's active
		if (
			userRoles.length === 1 &&
			userRoles[0] === allRoles.SUPPORT_WRITE &&
			(isCMSLocation || isCRMLocation)
		) {
			return true;
		}
		// if there is support role with any cms role then it is active in crm auth locations (not active in cms)
		else if (userHaveSupportRole && userHaveAnyCMSRole && isCMSLocation) {
			return false;
		}
		// if there is support role with any crm role then it is active in cms auth locations (not active in crm)
		else if (userHaveSupportRole && userHaveAnyCRMRole && isCRMLocation) {
			return false;
		}
		// if there is  support role with any roles that are not cms or crm roles then it is active in cms or crm auth locations
		else if (
			userHaveSupportRole &&
			(!userHaveAnyCMSRole || !userHaveAnyCRMRole) &&
			(isCMSLocation || isCRMLocation)
		) {
			return true;
		} else return false;
	};

	// should material tab (with videos) be visible to user
	const isMaterialTabVisible =
		userRoles.includes(video[0]) || userRoles.includes(video[1]);

	// should encoding tab (with ingestion data) be visible to user
	const isEncodingTabVisible =
		userRoles.includes(ingestion[0]) || userRoles.includes(ingestion[1]);

	// should payments tab (with payments data) be visible to user
	const isPaymentsTabVisible =
		userRoles.includes(data[0]) || userRoles.includes(data[1]);

	// should tracking tab be visible to user
	const isTrackingTabVisible =
		userRoles.includes(data[0]) || userRoles.includes(data[1]);

	// can user edit video materials
	const canEditVideo =
		userRoles.includes(videoWriteRole) && !isReadOnlyForCurrentSystem;

	// can user export csv - support_write cannot export csv, data_read (only) cannot export CSV
	// same as canClearSubscriberLimits, but new variable is added for clear var names and code readability
	const canExportCSV = !isSupportRoleActive() && !hasOnlyDataReadRole;

	// can user clear subscriber limits - support_write cannot clear subscriber limits
	// same as canExportCSV, but new variable is added for clear var names and code readability
	const canClearSubscriberLimits = !isSupportRoleActive();

	const canEditImage = !isReadOnlyForCurrentSystem;

	return {
		isReadOnly,
		isMaterialTabVisible,
		isEncodingTabVisible,
		isPaymentsTabVisible,
		isTrackingTabVisible,
		canEditVideo,
		canExportCSV,
		canClearSubscriberLimits,
		canEditImage,
		canManageUserDevices
	};
};

export default useAuthCheck;
