import _ from 'lodash';
import services from 'services/services';
import i18n from 'i18n';

// Import types
import * as types from '../types';

// Import helpers
import {
	convertArrayToValuePairsByKeyNames,
	convertEntriesToValuePairs
} from '../helpers_actions/convert_helpers';
import { getCurrentSystemWithChildren } from './helpers';

// ******************** VIDEO FORMATS ********************
export const dictionaryVideoFormats = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getVideoFormats`);

	dispatch({
		type: types.DICTIONARY_VIDEO_FORMATS,
		payload: data
	});
};

// ******************** PRODUCT TYPES ********************
export const dictionaryProductTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getProductTypes`);

	dispatch({
		type: types.DICTIONARY_PRODUCT_TYPES,
		payload: data
	});
};

// ******************** VIDEO TYPES ********************
export const dictionaryVideoTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getVideoTypes`);

	dispatch({
		type: types.DICTIONARY_VIDEO_TYPES,
		payload: data
	});
};

// ******************** ASSETS COLLECTION TYPES ********************
export const dictionaryAssetsCollectionTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getAssetsCollections`);

	dispatch({
		type: types.DICTIONARY_ASSETS_COLLECTION_TYPES,
		payload: data
	});
};

// ******************** COUNTER TYPES ********************
export const dictionaryCounterTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getCounterTypes`);

	dispatch({
		type: types.DICTIONARY_COUNTER_TYPES,
		payload: data
	});
};

// ******************** IP RANGE TYPES ********************
export const dictionaryIpRangeTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getIpRangeTypes`);

	dispatch({
		type: types.DICTIONARY_IP_RANGE_TYPES,
		payload: data
	});
};

// ******************** PACKETS TYPES ********************
export const dictionaryPacketsTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getPacketTypes`);

	dispatch({
		type: types.DICTIONARY_PACKETS_TYPES,
		payload: data
	});
};

// ******************** PACKET PERMISSIONS ********************
export const dictionaryPacketPermissions = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getPacketPermissions`);

	const convertedData = convertEntriesToValuePairs(Object.entries(data));

	dispatch({
		type: types.DICTIONARY_PACKET_PERMISSIONS,
		payload: convertedData
	});
};

// ******************** PARENTAL CONTROL RATING ********************
export const dictionaryParentalControlRating = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getRatings`);

	dispatch({
		type: types.DICTIONARY_PARTENTAL_CONTROL_RATING,
		payload: data
	});
};

// ******************** PAYMENT METHODS ********************
export const dictionaryPaymentMethods = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getPaymentMethods`);

	dispatch({
		type: types.DICTIONARY_PAYMENT_METHODS,
		payload: data
	});
};

// ******************** PLAY MODES ********************
export const dictionaryPlayModes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getPlayModes`);

	dispatch({
		type: types.DICTIONARY_PLAY_MODES,
		payload: data
	});
};

// ******************** REMINDER TYPES ********************
export const dictionaryReminderTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getReminderTypes`);

	dispatch({
		type: types.DICTIONARY_REMINDER_TYPES,
		payload: data
	});
};

// ******************** SECTION TYPES ********************
export const dictionarySectionTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getSectionTypes`);

	dispatch({
		type: types.DICTIONARY_SECTION_TYPES,
		payload: data
	});
};

// ******************** AGREEMENTS TYPES ********************
export const dictionaryAgreementsTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getAgreementTypes`);

	dispatch({
		type: types.DICTIONARY_AGREEMENTS_TYPES,
		payload: data
	});
};

// ******************** PLATFORMS TYPES ********************
export const dictionaryPlatformsTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getPlatforms`);

	dispatch({
		type: types.DICTIONARY_PLATFORMS_TYPES,
		payload: data
	});
};

// ******************** PAYMENT MODELS ********************
export const dictionaryPaymentModels = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getPaymentModels`);

	dispatch({
		type: types.DICTIONARY_PAYMENT_MODELS,
		payload: data
	});
};

// ******************** CAST TYPES ********************
export const dictionaryCastTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getCastTypes`);

	dispatch({
		type: types.DICTIONARY_CAST_TYPES,
		payload: data
	});
};

// ******************** VOD PROVIDERS ********************
export const dictionaryVODProviders = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getVodProviders`);

	dispatch({
		type: types.DICTIONARY_VOD_PROVIDERS,
		payload: data
	});
};

// ******************** VOD SUBSTATUS ********************
export const dictionaryVODSubstatus = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getVodSubStatus`);

	dispatch({
		type: types.DICTIONARY_VOD_SUBSTATUS,
		payload: data
	});
};

// ******************** CUSTOM PROPERTIES ********************
export const dictionaryCustomProperties = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getCustomPropertiesHref`);

	const payload = Object.entries(data).map((item) => ({
		name: item[1],
		value: item[1]
	}));

	dispatch({
		type: types.DICTIONARY_CUSTOM_PROPERTIES,
		payload
	});
};

// ******************** CUSTOM PROPERTIES VALUES ********************
export const dictionaryCustomPropertiesValues = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getCustomPropertiesValues`);

	const payload = Object.entries(data).map((item) => ({
		name: item[1],
		value: item[1]
	}));

	dispatch({
		type: types.DICTIONARY_CUSTOM_PROPERTIES_VALUES,
		payload
	});
};

// ******************** SECTIONS ********************
export const dictionarySections = () => async (dispatch) => {
	const { data } = await services.get(
		`section/list?order[0][column]=1&order[0][dir]=asc`
	);

	dispatch({
		type: types.DICTIONARY_SECTIONS,
		payload: data.data
	});
};

// ******************** SUBSCRIBER SOURCE ********************
export const dictionarySubscriberSource = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getSubscriberSources`);

	dispatch({
		type: types.DICTIONARY_SUBSCRIBER_SOURCE,
		payload: data
	});
};

// ******************** VOIVODESHIP CODES ********************
export const dictionarytVoivodeshipCodes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getVoivodeshipCodes`);

	const convertedData = convertEntriesToValuePairs(Object.entries(data));

	dispatch({
		type: types.DICTIONARY_VOIVODESHIP_CODES,
		payload: convertedData
	});
};

// ******************** SYSTEMS ********************
export const dictionarySystems = () => async (dispatch) => {
	const {
		data: { data }
	} = await services.get(`system/list`);

	dispatch({
		type: types.DICTIONARY_SYSTEMS,
		payload: convertArrayToValuePairsByKeyNames(data, 'name', 'name')
	});
};

// ******************** SUBSYSTEMS ********************
export const dictionarySubSystems = () => async (dispatch, getState) => {
	const userSystems = getState().auth.authenticate.user.systems ?? [];

	const systems = await Promise.all(
		userSystems.map(async (parent_system) => {
			const response = await services.get('system/list', {
				params: { parent_system }
			});
			return response.data.data;
		})
	).then((data) => {
		const systems = _.uniqBy(data.flat(), 'name');
		return _.sortBy(systems, 'system_tree_depth');
	});

	dispatch({
		type: types.DICTIONARY_SUBSYSTEMS,
		payload: convertArrayToValuePairsByKeyNames(systems, 'name', 'name')
	});
};

// ******************** CHANNELS ********************
export const dictionaryChannels = () => async (dispatch) => {
	const {
		data: { data }
	} = await services.get('channel/list');

	dispatch({
		type: types.DICTIONARY_CHANNELS,
		payload: data
	});
};

// ******************** PROVIDERS ********************
export const dictionaryProviders = () => async (dispatch) => {
	const {
		data: { data }
	} = await services.get('products/providers/list');

	dispatch({
		type: types.DICTIONARY_PROVIDERS,
		payload: data
	});
};

// ******************** CURRENT SYSTEM WITH CHILDREN ********************
export const dictionaryCurrentSystemWithChildren = (currentSystem) => async (
	dispatch
) => {
	const {
		data: { data }
	} = await services.get(`system/list?system=${currentSystem}`);

	dispatch({
		type: types.DICTIONARY_CURRENT_SYSTEM_WITH_CHILDREN,
		payload: getCurrentSystemWithChildren({ systems: data, currentSystem })
	});
};

// ******************** Provisioning ********************
export const dictionaryProvisioning = (systemParameter) => async (dispatch) => {
	// We need only provisionings which are active in system of selected subscriber, so we have to add special parameter to request url
	const url = `/packet/list?type=provision&${systemParameter}&order[0][column]=6&order[0][dir]=asc`;

	const { data } = await services.get(url);

	dispatch({
		type: types.DICTIONARY_PROVISIONING,
		payload: data.data
	});
};

// ******************** MODEL TYPES ********************
export const dictionaryModelTypes = () => async (dispatch) => {
	const { data } = await services.get(`dictionary/getModelTypes`);

	dispatch({
		type: types.DICTIONARY_MODEL_TYPES,
		payload: Object.entries(data)
	});
};

// ******************** CATCHUP RECORDING STATES ********************
export const dictionaryCatchupRecordingStates = () => async (dispatch) => {
	const { data } = await services.get(`stats/catchup`);

	dispatch({
		type: types.DICTIONARY_CATCHUP_RECORDING_STATES,
		payload: data
	});
};

// ******************** DRM TEMPLATES ********************
export const dictionaryDRMTemplates = () => async (dispatch) => {
	const {
		data: { data }
	} = await services.get(`player/drmParamsTemplateKeys`);

	const drmTemplates = [
		{ name: i18n.t('common:none'), value: 'none' },
		...data.map((key) => ({ name: key, value: key }))
	];

	dispatch({
		type: types.DICTIONARY_DRM_TEMPLATES,
		payload: drmTemplates
	});
};
