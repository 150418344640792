import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { Input } from 'antd';
import Loader from 'components/common/loaders/Loader';

// Import hooks
import useScheduleActivity from './useScheduleActivity';

const ScheduleActivity = ({ id, isActive }) => {
	const { active, isLoading, onChangeActivity } = useScheduleActivity({
		id,
		isActive
	});

	return (
		<>
			{!isLoading ? (
				<Input type="checkbox" checked={active} onChange={onChangeActivity} />
			) : (
				<Loader size="small" />
			)}
		</>
	);
};

ScheduleActivity.propTypes = {
	id: PropTypes.number.isRequired,
	isActive: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired
};

export default ScheduleActivity;
