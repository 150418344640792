// Import translation
import i18n from 'i18next';

// Import helpers
import { checkIsInt } from 'components/helpers/convert';

export default ({ provider_id, os, os_version, max_bitrate, min_bitrate }) => {
	const errors = {};

	// ------------------ provider_id ------------------
	if (!provider_id) {
		errors.provider_id = i18n.t(
			'ingester:video_assets_params.form.validation.provider_id_required'
		);
	}
	// ------------------ os ------------------
	if (!os) {
		errors.os = i18n.t(
			'ingester:video_assets_params.form.validation.os_required'
		);
	}
	// ------------------ os_version ------------------
	if (!os_version) {
		errors.os_version = i18n.t(
			'ingester:video_assets_params.form.validation.os_version_required'
		);
	}
	// ------------------ max_bitrate ------------------
	if (max_bitrate && !checkIsInt(max_bitrate)) {
		errors.max_bitrate = i18n.t(
			'ingester:video_assets_params.form.validation.fraction_invalid'
		);
	}
	if (max_bitrate < 0) {
		errors.max_bitrate = i18n.t(
			'ingester:video_assets_params.form.validation.negative_value'
		);
	}

	// ------------------ min_bitrate ------------------
	if (min_bitrate && !checkIsInt(min_bitrate)) {
		errors.min_bitrate = i18n.t(
			'ingester:video_assets_params.form.validation.fraction_invalid'
		);
	}
	if (min_bitrate < 0) {
		errors.min_bitrate = i18n.t(
			'ingester:video_assets_params.form.validation.negative_value'
		);
	}

	// ------------------ min_bitrate && max_bitrate------------------
	if (max_bitrate && min_bitrate) {
		if (Number(max_bitrate) < Number(min_bitrate)) {
			errors.max_bitrate = i18n.t(
				'ingester:video_assets_params.form.validation.less_than_min'
			);
			errors.min_bitrate = i18n.t(
				'ingester:video_assets_params.form.validation.greater_than_max'
			);
		}
	}
	return errors;
};
