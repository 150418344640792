import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';
import { setUrl } from './helpers/helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH PAYMENTS - TABLE DATA ********************
export const fetchPayments = (options, id = null) => async (
	dispatch,
	getState
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_PAYMENTS_LOADING,
			payload: true
		});

		const {
			payments: {
				table: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		// type of id (payments | subscribers | vod)
		const {
			filtered: { type }
		} = options;

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		// request url
		const url = `/timetables/payments/list?${filterUrlQuery}${setUrl(
			type,
			id
		)}${queryParams(options, TABLE_LENGTH, columns, startIndex, true)}`;

		// don't send request if there is no type set in Redux
		// without type Payment component sends request with empty query strings and returns invalid data
		if (type) {
			const { data } = await services.get(url);

			// Calculate pages
			const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

			// Dispatch an action with data
			dispatch({
				type: types.FETCH_PAYMENTS_SUCCESS,
				payload: { ...data, pages, page: options.page, startIndex }
			});
		}

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_PAYMENTS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// *************** SET PAYMENTS ID TYPE ***************
export const setPaymentIdType = (type) => ({
	type: types.SET_PAYMENTS_ID_TYPE,
	payload: type
});

// *************** SET PAYMENTS ID SUBTYPE ***************
export const setPaymentIdSubtype = (subtype) => ({
	type: types.SET_PAYMENTS_ID_SUBTYPE,
	payload: subtype
});

// ******************** SET SEARCH QUERY ********************
export const setPaymentsQuery = (query) => ({
	type: types.SET_PAYMENTS_SEARCH_QUERY,
	payload: query
});
