import Cookies from 'js-cookie';
import {
	SYSTEM_TYPES,
	SYSTEM_CUSTOM_NAMES,
	COOKIE_USER_SYSTEM
} from 'components/helpers/variables';

const { tvonline } = SYSTEM_TYPES;

export const renderSystem = (system) => {
	switch (system) {
		case tvonline:
			return SYSTEM_CUSTOM_NAMES.tvonline;

		default:
			return system;
	}
};

export const mapSystemNames = (systems) => {
	return systems.map((system) => {
		const systemName =
			system === tvonline ? SYSTEM_CUSTOM_NAMES.tvonline : system.toUpperCase();

		return { name: systemName, value: system };
	});
};

export const getCurrentSystem = (systems = []) => {
	const system = Cookies.get(COOKIE_USER_SYSTEM) ?? systems[0];
	Cookies.set(COOKIE_USER_SYSTEM, system, { expires: 365 });
	return system;
};
