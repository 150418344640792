// Import translation
import i18n from 'i18next';

// Import helpers
import {
	PERCENTAGE,
	DISCOUNT,
	STATIC_PRICE,
	INHERITED,
	ALL_USERS,
	PACKETS_LIST,
	USERS_LIST,
	ALL_PRODUCTS,
	PRODUCTS_LIST,
	AUTO,
	CODE,
	NEW_USERS
} from 'components/helpers/promotion_helpers/helpers';

// Promotions select values to proper select fields
// Hardcoded based on postman dictionary
export const select_promotion_triggers = [
	{
		name: i18n.t('promotions:form:select_values:trigger.auto'),
		value: AUTO
	},
	{
		name: i18n.t('promotions:form:select_values:trigger.code'),
		value: CODE
	}
];

export const select_promotion_types = [
	{
		name: i18n.t('promotions:form:select_values:promotion_type.percentage'),
		value: PERCENTAGE
	},
	{
		name: i18n.t('promotions:form:select_values:promotion_type.discount'),
		value: DISCOUNT
	},
	{
		name: i18n.t('promotions:form:select_values:promotion_type.static_price'),
		value: STATIC_PRICE
	}
];

export const select_product_specification = [
	{
		name: i18n.t(
			'promotions:form:select_values:product_specification.all_products'
		),
		value: ALL_PRODUCTS
	},
	{
		name: i18n.t(
			'promotions:form:select_values:product_specification.products_list'
		),
		value: PRODUCTS_LIST
	}
];

export const select_user_specification = [
	{
		name: i18n.t('promotions:form:select_values:user_specification.all_users'),
		value: ALL_USERS
	},
	{
		name: i18n.t(
			'promotions:form:select_values:user_specification.packets_list'
		),
		value: PACKETS_LIST
	},
	{
		name: i18n.t('promotions:form:select_values:user_specification.users_list'),
		value: USERS_LIST
	},
	{
		name: i18n.t('promotions:form:select_values:user_specification.new_users'),
		value: NEW_USERS
	}
];

export const select_entry_condition_products_specification = [
	{
		name: i18n.t(
			'promotions:form:select_values:entry_condition_products_specification.inherited'
		),
		value: INHERITED
	},
	{
		name: i18n.t(
			'promotions:form:select_values:entry_condition_products_specification.all_products'
		),
		value: ALL_PRODUCTS
	},
	{
		name: i18n.t(
			'promotions:form:select_values:entry_condition_products_specification.products_list'
		),
		value: PRODUCTS_LIST
	}
];
