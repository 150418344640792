import React from 'react';
import { useSelector } from 'react-redux';

// Import translation
import { useTranslation } from 'react-i18next';

function AdiCode() {
	const { t } = useTranslation();
	const { data } = useSelector((state) => state.vod.encoding);

	if (data === null) {
		return <p>{t('common:no_data')}</p>;
	}

	return <pre className="adi-code">{data}</pre>;
}

export default AdiCode;
