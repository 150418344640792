import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH AFFILIATE PARTNER TO EDIT ********************
export const fetchAffiliatePartner = (id) => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_AFFILIATE_PARTNER_LOADING,
			payload: true
		});

		const {
			data: { data }
		} = await services.get(`/affiliate/${id}`);

		const convertedData = {
			...data,
			active: Number(data?.active) ? 1 : 0
		};

		dispatch({
			type: types.FETCH_AFFILIATE_PARTNER_SUCCESS,
			payload: convertedData
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_AFFILIATE_PARTNER_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** FETCH AFFILIATE PARTNER CODES ********************
export const fetchAffiliatePartnerCodes = (id) => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_AFFILIATE_PARTNER_CODES_LOADING,
			payload: true
		});

		const { data } = await services.get(`/affiliate/${id}/codes`);

		dispatch({
			type: types.FETCH_AFFILIATE_PARTNER_CODES_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_AFFILIATE_PARTNER_CODES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE AFFILIATE PARTNER CODES ********************
export const deleteAffiliatePartnerCode = (itemID, code) => async (
	dispatch
) => {
	try {
		dispatch({
			type: types.DELETE_AFFILIATE_PARTNER_CODES_LOADING,
			payload: true
		});

		await services.delete(`/affiliate/${itemID}/codes/${code}`);

		dispatch({
			type: types.DELETE_AFFILIATE_PARTNER_CODES_SUCCESS,
			payload: true
		});
	} catch (error) {
		dispatch({
			type: types.DELETE_AFFILIATE_PARTNER_CODES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
