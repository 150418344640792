import i18n from 'i18next';

export const SYSTEMS_FILTER_FIELDS = [
	{
		name: 'name',
		label: i18n.t('filters:fields.name'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'parent_system',
		label: i18n.t('filters:fields.parent_system'),
		type: 'multi-select',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'display_name',
		label: i18n.t('filters:fields.display_name'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	}
];
