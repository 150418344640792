import i18n from 'i18next';

export const defaultFieldValues = {
	active: false
};

export const BASE_FIELDS = [
	{
		name: 'active',
		label: i18n.t('mass_changes:active'),
		type: 'checkbox',
		required: false
	}
];
