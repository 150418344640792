import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup } from 'reactstrap';
import { DatePicker } from 'antd';
import moment from 'moment';

import { FieldInfo, FieldError, Label } from 'components/common/inputs';

const DatePickerLabel = ({
	input,
	id,
	label,
	placeholder,
	labelColumn,
	inputColumn,
	showTime,
	fieldInfo,
	format,
	meta: { touched, error, submitError },
	required,
	...inputProps
}) => {
	return (
		<FormGroup
			row
			className={`${touched && error ? 'has-error' : ''} form-group`}
		>
			<Label
				id={id}
				columnWidth={labelColumn}
				required={required}
				content={label}
			/>
			<Col sm={inputColumn}>
				<DatePicker
					{...input}
					{...inputProps}
					value={input.value ? moment(input.value) : null}
					id={id}
					placeholder={placeholder}
					showTime={showTime}
					format={showTime ? 'YYYY-MM-DD HH:mm:ss' : format}
					className="input-cursor-pointer"
				/>
				<FieldError
					error={touched && (error || submitError)}
					inputName={input.name}
					content={error || submitError}
				/>
				<FieldInfo info={fieldInfo} />
			</Col>
		</FormGroup>
	);
};

DatePickerLabel.defaultProps = {
	format: 'YYYY-MM-DD',
	required: false,
	showTime: false,
	labelColumn: 2,
	inputColumn: 10
};

DatePickerLabel.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	showTime: PropTypes.bool,
	format: PropTypes.string,
	meta: PropTypes.object,
	required: PropTypes.bool,
	fieldInfo: PropTypes.string
};

export default DatePickerLabel;
