// ******************** TREE ********************
// -------------------- FETCH COGNITO TREE DATA --------------------
export const FETCH_COGNITO_TREE_LOADING = 'FETCH_COGNITO_TREE_LOADING';
export const FETCH_COGNITO_TREE_SUCCESS = 'FETCH_COGNITO_TREE_SUCCESS';
export const FETCH_COGNITO_TREE_ERROR = 'FETCH_COGNITO_TREE_ERROR';
// -------------------- DELETE COGNITO CATEGORY --------------------
export const DELETE_COGNITO_CATEGORY_LOADING =
	'DELETE_COGNITO_CATEGORY_LOADING';
export const DELETE_COGNITO_CATEGORY_SUCCESS =
	'DELETE_COGNITO_CATEGORY_SUCCESS';
export const DELETE_COGNITO_CATEGORY_ERROR = 'DELETE_COGNITO_CATEGORY_ERROR';

// ******************** FORM ********************
// -------------------- FETCH COGNITO CATEGORY --------------------
export const FETCH_COGNITO_CATEGORY_LOADING = 'FETCH_COGNITO_CATEGORY_LOADING';
export const FETCH_COGNITO_CATEGORY_SUCCESS = 'FETCH_COGNITO_CATEGORY_SUCCESS';
export const FETCH_COGNITO_CATEGORY_ERROR = 'FETCH_COGNITO_CATEGORY_ERROR';

// ******************** FILES ********************
// -------------------- LOGO --------------------
export const FETCH_COGNITO_LOGO_FILE_LOADING =
	'FETCH_COGNITO_LOGO_FILE_LOADING';
export const FETCH_COGNITO_LOGO_FILE_SUCCESS =
	'FETCH_COGNITO_LOGO_FILE_SUCCESS';
export const FETCH_COGNITO_LOGO_FILE_ERROR = 'FETCH_COGNITO_LOGO_FILE_ERROR';

// -------------------- COVER LARGE --------------------
export const FETCH_COGNITO_COVER_LARGE_FILE_LOADING =
	'FETCH_COGNITO_COVER_LARGE_FILE_LOADING';
export const FETCH_COGNITO_COVER_LARGE_FILE_SUCCESS =
	'FETCH_COGNITO_COVER_LARGE_FILE_SUCCESS';
export const FETCH_COGNITO_COVER_LARGE_FILE_ERROR =
	'FETCH_COGNITO_COVER_LARGE_FILE_ERROR';

// -------------------- POSTER  --------------------
export const FETCH_COGNITO_POSTER_FILE_LOADING =
	'FETCH_COGNITO_POSTER_FILE_LOADING';
export const FETCH_COGNITO_POSTER_FILE_SUCCESS =
	'FETCH_COGNITO_POSTER_FILE_SUCCESS';
export const FETCH_COGNITO_POSTER_FILE_ERROR =
	'FETCH_COGNITO_POSTER_FILE_ERROR';
