import React from 'react';

// Import components
import ChannelsSystemForm from './channels_systems_form/ChannelsSystemsForm';
import ChannelsPlatformsForm from './channels_platforms_form/ChannelsPlatformsForm';

const ChannelsSystemSettings = () => (
	<>
		<ChannelsSystemForm />
		<ChannelsPlatformsForm />
	</>
);

export default ChannelsSystemSettings;
