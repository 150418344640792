import React, { useContext } from 'react';
import { Radio } from 'antd';

// Import context
import { ProductsContext } from '../../ProductsProvider';

const Tabs = () => {
	const {
		handleTabChange,
		modal: { selectedType, tabs }
	} = useContext(ProductsContext);
	return (
		<Radio.Group onChange={handleTabChange} value={selectedType}>
			{tabs.map((tab, index) => (
				<Radio.Button key={`title-${index}`} value={tab.type}>
					{tab.title}
				</Radio.Button>
			))}
		</Radio.Group>
	);
};

export default Tabs;
