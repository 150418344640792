import React from 'react';

// Import translation
import { useTranslation } from 'react-i18next';

// Import componentes
import { Button } from 'antd';

// Import hooks
import useESalesButton from './useESalesButton';

function ESalesButton() {
	const { t } = useTranslation();

	const {
		isVisible,
		synchronizeOffers,
		isLoading,
		isError,
		isSuccess
	} = useESalesButton();

	if (!isVisible) return null;

	return (
		<>
			<Button
				type="primary"
				htmlType="button"
				onClick={synchronizeOffers}
				className="m-r-sm"
				loading={isLoading}
			>
				{t('settings:buttons.sync_esales')}
			</Button>
			{isSuccess && (
				<span className={'text-success'}>
					{t('settings:esales.synchronize_success')}
				</span>
			)}
			{isError && (
				<span className={'text-danger'}>
					{t('common:errors.common_error_message')}
				</span>
			)}
		</>
	);
}

export default ESalesButton;
