import services from 'services/services';
import * as types from '../types';

// Import variables
import * as api from 'components/helpers/api';

// ******************** FETCH DEVICE_SN SERIAL NUMBER ********************
const fetchDeviceSerial = async (id) => {
	const url = api.FETCH_DEVICE_SN_SERIAL_NUMBER.replace(':id', id);
	const { data } = await services.get(url);
	return data;
};

// ******************** RESOLVE DEVICE PACKETS UUID ********************
const resolveDeviceUUID = async (serialnumber) => {
	const url = api.FETCH_DEVICE_SN_UUID.replace(':serialnumber', serialnumber);
	const { data } = await services.get(url);
	return data;
};

// ******************** RESOLVE DEVICE PACKETS DETAILS ********************
export const resolveDevicePackets = async (uuid) => {
	const url = api.FETCH_DEVICE_SN_PACKET_DETAILS.replace(':uuid', uuid);
	const { data } = await services.get(url);
	return data;
};

// ******************** FETCH DEVICE_SN SERIAL NUMBER ********************
export const fetchDeviceSnPacketDetails = (id) => async (dispatch) => {
	// Dispatch a loading action
	dispatch({
		type: types.FETCH_DEVICE_SN_PACKETS_LOADING
	});

	//Chaining the functions above
	await fetchDeviceSerial(id).then(({ serial_number }) =>
		resolveDeviceUUID(serial_number)
			.then((data) => {
				dispatch({
					type: types.FETCH_DEVICE_SN_PACKETS_SUCCESS,
					payload: {
						data: data
					}
				});
			})
			.catch(() => {
				dispatch({
					type: types.FETCH_DEVICE_SN_PACKETS_ERROR
				});
			})
	);
};
