import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import arrayMove from 'array-move';

// Import helpers
import {
	deleteAsset,
	updateAssetOrder,
	onAssetUpdateFailure,
	onAssetUpdateSuccess,
	fetchAssets,
	onAssetsFetchSuccess,
	onFileUploadFailure,
	onFileUploadSuccess,
	uploadAssetFunction
} from './index';
import {
	FETCH_DOCUMENT_ASSETS_QUERY_KEY,
	UPDATE_DOCUMENT_ASSETS_QUERY_KEY,
	UPLOAD_DOCUMENT_ASSET_QUERY_KEY
} from 'components/helpers/api';

// Import utilities
import useSystem from 'hooks/useSystem';

export const useDocumentUploads = ({ isEditPage }) => {
	const [dataSource, setDataSource] = useState([]);
	const [file, setFile] = useState([]);
	const { currentSystem: system } = useSystem();
	const { id } = useParams();

	const { refetch, isFetching } = useQuery({
		enabled: isEditPage,
		queryKey: FETCH_DOCUMENT_ASSETS_QUERY_KEY,
		queryFn: fetchAssets(id),
		onSuccess: onAssetsFetchSuccess(setDataSource)
	});

	const { mutate: updateAssets, isLoading: isUpdating } = useMutation({
		mutationKey: UPDATE_DOCUMENT_ASSETS_QUERY_KEY,
		enabled: isEditPage,
		mutationFn: updateAssetOrder,
		onSuccess: onAssetUpdateSuccess(refetch),
		onError: onAssetUpdateFailure
	});

	const { mutate: uploadAsset, isLoading: isUploading } = useMutation({
		mutationKey: UPLOAD_DOCUMENT_ASSET_QUERY_KEY,
		enabled: isEditPage,
		mutationFn: uploadAssetFunction,
		onSuccess: onFileUploadSuccess(refetch, setFile),
		onError: onFileUploadFailure
	});

	const updateTableOrder = async () => updateAssets(dataSource);
	const uploadDocument = async () => uploadAsset({ file, id, system });
	const deleteAssetAndRefetch = async (id) => {
		await deleteAsset(id);
		await refetch();
	};

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMove(dataSource.slice(), oldIndex, newIndex).filter(
				(el) => !!el
			);
			setDataSource(newData);
		}
	};

	const uploadProps = {
		fileList: file,
		maxCount: 1,
		accept: 'application/pdf,image/png,image/jpeg,image/jpg',
		onRemove: () => setFile([]),
		beforeUpload: (file) => {
			setFile([file]);
			return false; // To prevent auto upload on button click
		}
	};

	return {
		file,
		setFile,
		isLoading: isFetching || isUpdating || isUploading,
		uploadDocument,
		updateTableOrder,
		onSortEnd,
		deleteAssetAndRefetch,
		dataSource,
		uploadProps
	};
};
