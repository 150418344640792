import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchSectionsGroup } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const SectionsGroupsForm = ({
	history,
	fetchSectionsGroup,
	error,
	isLoaded,
	initialValues
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) =>
		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: 'section/group',
			redirectPath: 'sections_groups'
		});

	// Get name from initial values
	const { name } = initialValues;
	return (
		// Dispatch fetchSectionsGroup actions in sections_groups_form
		<FormTemplate
			fetchResourceToEdit={fetchSectionsGroup}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={{ name }}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields isEdit={isEdit} submitting={submitting} />

							<FormButtons
								isButtonDisabled={submitting || error}
								path="sections_groups"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

SectionsGroupsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.shape({ name: PropTypes.string }).isRequired,
	fetchSectionsGroup: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ sections_groups: { form } }) => ({
	initialValues: form.edit,
	error: form.error,
	isLoaded: form.isLoaded
});

export default compose(
	connect(
		mapStateToProps,
		{ fetchSectionsGroup }
	),
	withRouter
)(SectionsGroupsForm);
