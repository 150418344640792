// ******************** LIMITS DATA TABLE ********************
export const FETCH_LIMITS_LOADING = 'FETCH_LIMITS_LOADING';
export const FETCH_LIMITS_SUCCESS = 'FETCH_LIMITS_SUCCESS';
export const FETCH_LIMITS_ERROR = 'FETCH_LIMITS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_LIMITS_SEARCH_QUERY = 'SET_LIMITS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_LIMITS_TABLE_SORTING = 'SAVE_LIMITS_TABLE_SORTING';

// ******************** DELETE SECTION ********************
export const DELETE_LIMIT_LOADING = 'DELETE_LIMIT_LOADING';
export const DELETE_LIMIT_SUCCESS = 'DELETE_LIMIT_SUCCESS';
export const DELETE_LIMIT_ERROR = 'DELETE_LIMIT_ERROR';

// ******************** LIMITS PRODUCTS ********************
export const FETCH_LIMIT_PRODUCTS_LOADING = 'FETCH_LIMIT_PRODUCTS_LOADING';
export const FETCH_LIMIT_PRODUCTS_SUCCESS = 'FETCH_LIMIT_PRODUCTS_SUCCESS';
export const FETCH_LIMIT_PRODUCTS_ERROR = 'FETCH_LIMIT_PRODUCTS_ERROR';

// ******************** SECTION DATA FORM ********************
export const FETCH_LIMIT_LOADING = 'FETCH_LIMIT_LOADING';
export const FETCH_LIMIT_SUCCESS = 'FETCH_LIMIT_SUCCESS';
export const FETCH_LIMIT_ERROR = 'FETCH_LIMIT_ERROR';
