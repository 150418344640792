// Import translation
import i18n from 'i18next';

export default ({ ports_file, mappings_name }) => {
	const errors = {};

	if (!mappings_name) {
		errors.mappings_name = i18n.t(
			'topology:validaitons.mappings_name_required'
		);
	}

	if (!ports_file) {
		errors.ports_file = i18n.t('vod_subtitles:validation.file_required');
	}

	return errors;
};
