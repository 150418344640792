export const createMaterialOptions = (values, assetData) => {
	const { format, asset_url, drm_key, iv, platform, local_limited } = values;

	// check if there is iv. If undefined set empty string
	const isIv = iv || '';

	const isAssetData = assetData
		? { ...assetData.params, iv: isIv }
		: { seed: '', keyId: '', iv: isIv };
	const params = JSON.stringify(isAssetData);

	const options = {
		format,
		url: asset_url,
		params,
		platform: mapDefaultValue(platform),
		local_limited
	};
	// only send drm_key if it's typed, if it's not edited do not send it
	if (drm_key) {
		options.drm_key = drm_key;
	}

	return options;
};

export const createInitialValues = (isEdit, assetData) =>
	isEdit
		? {
				format: assetData.format,
				asset_url: assetData.src,
				drm_key: null,
				iv: assetData.params.iv,
				platform: assetData.platform,
				local_limited: assetData.local_limited,
				drm_params_template_key: assetData.drm_params_template_key ?? 'none'
		  }
		: {
				format: 'hls',
				asset_url: '',
				drm_key: null,
				iv: '',
				platform: 'DEFAULT',
				local_limited: false
		  };

export const convertAssets = ({ assets, assetId }) => {
	// video asset resources that are being edited
	const assetData = assets.find((asset) => asset.id === assetId);

	// formats of product assets that are in the table
	const videoAssetsFormats = assets.map(({ format }) => format);

	return { assetData, videoAssetsFormats };
};

// material platform types - default material platform value
//  is null and needs to be mapped to "DEFAULT"
export const MATERIAL_PLATFORM_TYPES = {
	default: 'DEFAULT'
};

const mapDefaultValue = (platform) =>
	platform === MATERIAL_PLATFORM_TYPES.default ? null : platform;

// Material format types
export const CONCURRENT = 'concurrent';
