import React from 'react';
import { array, bool, string } from 'prop-types';

// Import utils
import { renderFields } from 'components/utilities/form';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({
	actors,
	writers,
	directors,
	submitting,
	genres,
	status,
	subtype,
	providersNames,
	providersId,
	paymentsModelsList,
	isEdit,
	krritTags
}) => {
	const selectResources = {
		actors,
		writers,
		directors,
		genres,
		provider: providersNames,
		provider_id: providersId,
		payment_models: paymentsModelsList,
		krrit_tags: krritTags
	};

	return (
		<React.Fragment>
			<div className="hr-line-solid">&nbsp;</div>
			{BASE_FIELDS({ status, subtype, isEdit }).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	actors: array,
	writers: array,
	directors: array,
	genres: array,
	submitting: bool.isRequired,
	status: string,
	subtype: string,
	providersNames: array,
	providersId: array,
	paymentsModelsList: array,
	isEdit: bool,
	krritTags: array
};

export default Fields;
