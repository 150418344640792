import i18n from 'i18n';

export const BASE_FIELDS = (isEdit) => [
	{
		name: 'title',
		type: 'text',
		label: i18n.t('banners:fields.title'),
		required: true
	},
	{
		name: 'description',
		type: 'textarea',
		label: i18n.t('banners:fields.description'),
		required: true
	},
	{
		name: 'active',
		type: 'checkbox',
		label: i18n.t('banners:fields.active'),
		required: false
	},
	{
		name: 'adult',
		type: 'checkbox',
		label: i18n.t('banners:fields.adult'),
		required: false
	},
	{
		name: 'group',
		type: 'text',
		label: i18n.t('banners:fields.group'),
		required: true
	},
	{
		name: 'text_button',
		type: 'text',
		label: i18n.t('banners:fields.text_button'),
		required: false
	},
	{
		name: 'url_pc',
		type: 'text',
		label: i18n.t('banners:fields.url_pc'),
		required: true
	},
	{
		name: 'url_mobile',
		type: 'text',
		label: i18n.t('banners:fields.url_mobile'),
		required: true
	},
	{
		name: 'created_at',
		type: 'text',
		label: i18n.t('banners:fields.created_at'),
		required: false,
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'updated_at',
		type: 'text',
		label: i18n.t('banners:fields.updated_at'),
		required: false,
		disabled: true,
		isVisible: !isEdit
	}
];
