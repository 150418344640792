import { useEffect, useState } from 'react';

// Imprt sevices
import services from 'services/services';

// Import variables
import { TOPOLOGY_FILES } from 'components/helpers/variables';

const { TOPOLOGY, PORTS } = TOPOLOGY_FILES;

const useTopology = () => {
	const [topologyFilesData, setTopologyFilesData] = useState(null);
	const [portsFilesData, setPortsFilesData] = useState(null);

	const fetchFilesData = async (type) => {
		const isTypology = type === TOPOLOGY;
		const url = isTypology
			? '/cognito/topology/fileData'
			: '/cognito/ports/filesData';

		const { data } = await services.get(url);

		isTypology ? setTopologyFilesData(data) : setPortsFilesData(data);
	};

	useEffect(() => {
		fetchFilesData(TOPOLOGY);
		fetchFilesData(PORTS);
	}, []);

	return {
		topologyFilesData,
		portsFilesData,
		fetchFilesData
	};
};

export default useTopology;
