import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	edit: {
		free_vod: false,
		order: null,
		url: '',
		active: true,
		slug: null,
		external_ids: []
	},
	isLoaded: true,
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR PROVISIONING_ITEM STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// ****************** FETCH PROVISIONING_ITEM ******************
			case types.FETCH_PROVISIONING_ITEM_LOADING:
				draft.isLoaded = false;
				return;

			case types.FETCH_PROVISIONING_ITEM_SUCCESS:
				draft.edit = action.payload;
				draft.isLoaded = true;
				return;

			case types.FETCH_PROVISIONING_ITEM_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
