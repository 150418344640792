// Import variables
import { PLATFORM_TYPES } from 'components/helpers/variables';
export const convertTableData = ({ selection, value, platform }) => {
	const {
		all: allPlatforms,
		platformPrimary,
		platformSecondary
	} = PLATFORM_TYPES;

	const checkedItems = selection.reduce((data, item) => {
		data[item.uuid] = value;
		return data;
	}, {});

	const isAllPlatformSelected = platform === allPlatforms;

	const platform_primary =
		isAllPlatformSelected || platform === platformPrimary;
	const platform_secondary =
		isAllPlatformSelected || platform === platformSecondary;

	return {
		platform_primary,
		platform_secondary,
		data: checkedItems
	};
};

export const toggleCurrentSelection = ({ row, selection, selectAll }) => {
	// Find item keyIndex
	const keyIndex = selection.findIndex((item) => item.uuid === row.uuid);

	// Check to see if the key exists
	if (keyIndex >= 0) {
		// It does exist so we will remove it using destructing
		selection = [
			...selection.slice(0, keyIndex),
			...selection.slice(keyIndex + 1)
		];

		selectAll = false;
	} else {
		// It does not exist so add it
		selection.push(row);
	}

	return { newSelection: selection, newSelectAll: selectAll };
};

export const toggleAllItems = ({
	selection,
	selectAll,
	isSelectedHandler,
	currentRecords
}) => {
	let newSelection = [...selection];
	// Check if checkbox selectAll is active
	if (!selectAll) {
		currentRecords.forEach((item) => {
			// Check if current item's checkbox is active
			const isSelected = !isSelectedHandler(item._original.uuid);
			if (isSelected) {
				newSelection.push(item._original);
			}
		});
	} else {
		// If checkbox all is not active set all item's check values to false
		currentRecords.forEach((currentItem) => {
			const keyIndex = newSelection.findIndex(
				(item) => item.uuid === currentItem._original.uuid
			);
			newSelection = newSelection.filter((item, index) => index !== keyIndex);
		});
	}

	return { newSelectAll: !selectAll, newSelection };
};
