import * as types from 'store/actions/types';
import produce from 'immer';

// Import convert function
import { convertPriceToCents } from 'components/helpers/convert';

// edit keeps updated, in raw format, ready to fetch state in requested object form
// table keeps updated, transformed, ready to display state in array/row format
export const INITIAL_STATE = {
	// Data formated to be passed to a request
	edit: {
		id: null,
		name: '',
		prices: {},
		external_ids: {}
	},
	// Differently formated data to display inside the table
	table: [],
	loading: false,
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR SERIES FORM STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// ****************** FETCH PAYMENT TEMPLATE ******************
			case types.FETCH_PAYMENT_TEMPLATE_METADATA_LOADING:
				draft.loading = true;
				return;

			case types.FETCH_PAYMENT_TEMPLATE_METADATA_SUCCESS:
				draft.edit = action.payload;
				draft.loading = false;
				return;

			case types.FETCH_PAYMENT_TEMPLATE_METADATA_ERROR:
				draft.loading = false;
				draft.error = true;
				return;

			case types.SET_PAYMENT_TEMPLATE_TABLE_SUCCESS:
				draft.table = action.payload;
				return;

			// ****************** ADD PAYMENT TO A TABLE ******************
			case types.PAYMENT_TEMPLATE_ADD_PAYMENT_TO_TABLE:
				const data = action.payload;
				draft.edit.prices[data.payment_method] = convertPriceToCents(
					data.price
				);
				if (data.external_id) {
					draft.edit.external_ids[data.payment_method] = data.external_id;
				}
				draft.table = [...state.table, data];
				return;

			// ****************** DELETE PAYMENT FROM A TABLE ******************
			case types.PAYMENT_TEMPLATE_DELETE_PAYMENT_FROM_TABLE:
				const id = action.payload;
				delete draft.edit.prices[id];
				delete draft.edit.external_ids[id];
				draft.table = state.table.filter((t) => t.payment_method !== id);
				return;

			// ****************** EDIT PAYMENT FROM A TABLE ******************
			case types.PAYMENT_TEMPLATE_EDIT_PAYMENT_FROM_TABLE:
				const edit = action.payload;
				draft.edit.prices[edit.payment_method] = convertPriceToCents(
					edit.price
				);
				if (edit.external_id) {
					draft.edit.external_ids[edit.payment_method] = edit.external_id;
				} else {
					delete draft.edit.external_ids[edit.payment_method];
				}
				draft.table = state.table.map((row) => {
					if (row.payment_method === edit.payment_method) {
						const newData = {
							payment_method: edit.payment_method,
							price: edit.price,
							external_id: edit.external_id ?? ''
						};
						if (!edit.external_id) delete newData.external_id;
						return newData;
					}
					return row;
				});
				return;

			default:
				return state;
		}
	});
