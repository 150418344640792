import i18n from 'i18next';

export const BASE_FIELDS = [
	{
		name: 'name',
		label: i18n.t('affiliate_partners:fields.name'),
		type: 'text',
		required: true
	},
	{
		name: 'email',
		label: i18n.t('affiliate_partners:fields.email'),
		type: 'text',
		required: true
	},
	{
		name: 'active',
		label: i18n.t('affiliate_partners:fields.active'),
		type: 'checkbox',
		defaultValue: false
	}
];
