import React from 'react';
import { Alert } from 'antd';

const FetchError = () => (
	<Alert
		showIcon
		message="Error"
		type="error"
		description="An error has occurred and the data can not be retrieved"
		className="m-b-md animated fadeIn"
	/>
);

export default FetchError;
