import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	loading: true,
	error: false,
	data: [],
	columns: [],
	deleteItem: {
		success: false,
		error: false
	},
	options: {
		pages: 0,
		page: 0,
		total_results: 0,
		startIndex: 0,
		sorted: undefined,
		filters: {
			query: '',
			type: '',
			subtype: '',
			filterUrlQuery: ''
		}
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** FETCH SUBSCRIBER CONSENTS ******************
			case types.FETCH_SUBSCRIBER_CONSENTS_LOADING:
				draft.loading = true;
				draft.error = false;
				return;

			case types.FETCH_SUBSCRIBER_CONSENTS_SUCCESS:
				draft.loading = false;
				draft.data = action.payload.data;
				draft.columns = action.payload.input.columns;
				draft.options.total_results = action.payload.recordsFiltered;
				return;

			case types.FETCH_SUBSCRIBER_CONSENTS_ERROR:
				draft.error = action.payload;
				return;

			default:
				return state;
		}
	});
