// Import translation
import i18n from 'i18next';

export default ({ name, immutable_name }) => {
	const errors = {};

	// ------------------ NAME ------------------
	if (!name) {
		errors.name = i18n.t('providers:validation.name_required');
	}

	// ------------------ IMMUTABLE NAME ------------------
	if (!immutable_name) {
		errors.immutable_name = i18n.t(
			'providers:validation.immutable_name_required'
		);
	}

	if (!/^[a-zA-Z0-9_-]+$/.test(immutable_name)) {
		errors.immutable_name = i18n.t(
			'providers:validation.immutable_name_invalid_chars'
		);
	}

	return errors;
};
