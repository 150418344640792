import React from 'react';
import PropTypes from 'prop-types';

// Import components
import {
	Col,
	FormGroup,
	Input,
	InputGroupAddon,
	InputGroup,
	Button,
	Spinner
} from 'reactstrap';

// Import hooks
import useInputLabelButtonAsyncLogic from './utils/useInputLabelButtonAsyncLogic';

import { FieldInfo, FieldError, Label } from 'components/common/inputs';

const InputLabelButtonAsync = ({
	input,
	id,
	label,
	labelColumn,
	inputColumn,
	children,
	meta: { touched, error, submitError },
	buttonText,
	url,
	fieldInfo,
	required,
	...inputProps
}) => {
	const { loading, handleClick } = useInputLabelButtonAsyncLogic(url, id);
	return (
		<FormGroup
			data-testid={`form-group-${input.name}`}
			row
			className={`${touched && error && 'has-error'}`}
		>
			<Label
				id={id}
				columnWidth={labelColumn}
				required={required}
				content={label}
			/>
			<Col sm={inputColumn}>
				<InputGroup>
					<InputGroupAddon addonType="prepend">
						<Button onClick={handleClick} style={{ height: '100%' }}>
							{loading ? <Spinner color="light" /> : buttonText}
						</Button>
					</InputGroupAddon>
					<Input
						{...input}
						{...inputProps}
						invalid={touched && (error || submitError) ? true : false}
						id={id}
						value={input.value}
					/>
				</InputGroup>
				<FieldError
					error={touched && (error || submitError)}
					inputName={input.name}
					content={error || submitError}
				/>
				<FieldInfo info={fieldInfo} />
			</Col>
			{children}
		</FormGroup>
	);
};

InputLabelButtonAsync.defaultProps = {
	labelColumn: 2,
	inputColumn: 10,
	url: '',
	required: false
};

InputLabelButtonAsync.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	children: PropTypes.object,
	meta: PropTypes.object,
	url: PropTypes.string,
	buttonText: PropTypes.string.isRequired,
	required: PropTypes.string,
	fieldInfo: PropTypes.string
};

export default InputLabelButtonAsync;
