// Change edit values
export const updateValues = (edit, payload) => {
	edit[payload.name || payload.label] = payload.value;
	return edit;
};

// Convert resource to names
export const convertIDToNames = (IDs, resources) =>
	IDs.reduce((prev, next) => {
		const findItem = resources.find((item) => item.id === next);
		if (findItem) {
			prev.push(findItem.name);
		}
		return prev;
	}, []);

export const formatDateToCalendar = (daysAgo) => {
	const date = new Date();
	if (daysAgo) {
		const pastDate = date.getDate() - daysAgo;
		date.setDate(pastDate);
	}
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();
	return `${year}-${month}-${day}`;
};
