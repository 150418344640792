import services from 'services/services';
import * as types from '../types';

// Import helpers
import { convertSystemProvidersProducts } from './helpers';

// ******************** FETCH SYSTEM PROVIDERS PRODUCTS ********************
export const fetchSystemsProvidersProducts = (system) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_SYSTEM_PROVIDERS_PRODUCTS_LOADING
		});

		const {
			data: { data }
		} = await services.get(`/products/providers/list?system=${system}`);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SYSTEM_PROVIDERS_PRODUCTS_SUCCESS,
			payload: convertSystemProvidersProducts(data)
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SYSTEM_PROVIDERS_PRODUCTS_ERROR
		});
	}
};
