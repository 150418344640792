import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

export const createColumns = () => [
	{
		Header: i18n.t('sections:table_columns.name'),
		accessor: 'name',
		Cell: ({
			row: {
				_original: { id, name }
			}
		}) => <Link to={`/panel/sections/edit/${id}/metadata`}>{name}</Link>
	},
	{
		Header: i18n.t('sections:table_columns.label'),
		accessor: 'label'
	},
	{
		Header: i18n.t('sections:table_columns.type'),
		accessor: 'type'
	}
];
