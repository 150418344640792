import { useForm } from 'react-final-form';

// Import variables
import { AFFILIATE_PARTNER_CODE_LENGTH } from 'components/helpers/variables';

const useInputLabelButtonSyncLogic = (fieldName) => {
	const form = useForm();

	const handleClick = () => {
		const decToHex = (dec) => dec.toString(16).padStart(2, '0');

		const generateID = (length) => {
			const arr = new Uint8Array((length || 40) / 2);
			window.crypto.getRandomValues(arr);
			return Array.from(arr, decToHex).join('');
		};

		const newCode = generateID(AFFILIATE_PARTNER_CODE_LENGTH);

		form.change(fieldName, newCode);
	};

	return {
		handleClick
	};
};

export default useInputLabelButtonSyncLogic;
