import React from 'react';
import { object, bool, func } from 'prop-types';
import { connect } from 'react-redux';

// Import actions
import {
	setAffiliatePartnersQuery,
	fetchAffiliatePartners,
	deleteAffiliatePartner
} from 'store/actions';

// Import components
import { PanelPageTemplate } from 'components/common/templates';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './affiliate_partners_columns';

const AffiliatePartners = ({
	error,
	fetchAffiliatePartners,
	setAffiliatePartnersQuery,
	deleteAffiliatePartner,
	resources
}) => {
	const { t } = useTranslation();

	return (
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="affiliate_partners"
			addLinkText={t('affiliate_partners:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchAffiliatePartners}
			deleteItemAction={deleteAffiliatePartner}
			notificationDeleteSuccessTxt={t(
				'affiliate_partners:notification.delete_success'
			)}
			notificationDeleteErrorTxt={t(
				'affiliate_partners:notification.delete_error'
			)}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setAffiliatePartnersQuery}
					value={resources.options.filters.query}
				/>
			</div>
		</PanelPageTemplate>
	);
};

AffiliatePartners.propTypes = {
	resources: object.isRequired,
	error: bool.isRequired,
	setAffiliatePartnersQuery: func.isRequired,
	fetchAffiliatePartners: func.isRequired,
	deleteAffiliatePartner: func.isRequired
};

const mapStateToProps = (state) => ({
	error: state.affiliate_partners.table.error,
	resources: state.affiliate_partners.table
});

export default connect(mapStateToProps, {
	fetchAffiliatePartners,
	deleteAffiliatePartner,
	setAffiliatePartnersQuery
})(AffiliatePartners);
