import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	loading: true,
	error: false,
	data: [],
	details: []
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR DATA ******************
			case types.CLEAR_PANEL_STATE:
				return { ...INITIAL_STATE };

			// *************** FETCH PAYMENT TEMPLATES  DROPDOWN ***************
			case types.FETCH_PAYMENT_TEMPLATES_DROPDOWN_LOADING:
				draft.loading = true;
				draft.error = false;
				return;

			case types.FETCH_PAYMENT_TEMPLATES_DROPDOWN_SUCCESS:
				draft.loading = false;
				draft.data = action.payload;
				draft.error = false;
				return;

			case types.FETCH_PAYMENT_TEMPLATES_DROPDOWN_ERROR:
				draft.loading = false;
				draft.error = action.payload;
				return;

			// *************** FETCH PAYMENT TEMPLATES  DETAILS ***************
			case types.FETCH_PAYMENT_TEMPLATES_DETAILS_LOADING:
				draft.loading = true;
				draft.error = false;
				return;

			case types.FETCH_PAYMENT_TEMPLATES_DETAILS_SUCCESS:
				draft.loading = false;
				draft.details = action.payload;
				draft.error = false;
				return;

			case types.FETCH_PAYMENT_TEMPLATES_DETAILS_ERROR:
				draft.loading = false;
				draft.error = action.payload;
				return;

			default:
				return state;
		}
	});
