import { CORE_PATHS } from 'components/helpers/variables';

const {
	AVAILABILITY,
	PRICES,
	EPISODES,
	VOD,
	CHANNELS,
	METADATA,
	CATEGORIES
} = CORE_PATHS;

export const checkProductProperLocation = (pathname) => {
	const isProductLocation = pathname.includes('edit');
	const isAvailabilityLocation = pathname.includes(AVAILABILITY);
	const isPricesLocation = pathname.includes(PRICES);
	const isEpisodeLocation = pathname.includes(EPISODES);
	const isVodLocation = pathname.includes(VOD);
	const isChannelLocation = pathname.includes(CHANNELS);
	const isCategoriesLocation = pathname.includes(CATEGORIES);
	const isDocumentMetadataLocation = pathname.includes('documents/edit');
	const isMetaDataLocation =
		pathname.includes(METADATA) || isDocumentMetadataLocation;
	const isMarketingCampaignsLocation = pathname.includes('marketing_campaigns');

	const isProperLocation =
		isProductLocation &&
		!isMetaDataLocation &&
		!isAvailabilityLocation &&
		!isPricesLocation &&
		!isEpisodeLocation &&
		!isVodLocation &&
		!isChannelLocation &&
		!isCategoriesLocation &&
		!isMarketingCampaignsLocation;

	return { isProperLocation, isMetaDataLocation };
};
