import i18n from 'i18n';

// Import helpers
import { PRODUCT_SELECT_VOD_LIST_LENGTH } from 'components/helpers/variables';

export const URL_PARAMS =
	'?order[0][column]=1&order[0][dir]=asc&start=0&length=30&search[value]=:value';

export const VOD_URL_PARAMS = `?order[0][column]=4&order[0][dir]=asc&start=0&length=${PRODUCT_SELECT_VOD_LIST_LENGTH}&columns[4][search][value]=:value&columns[14][search][value]=1&columns[1][search][value]=:productType`;

export const CATCHUP_PARAMS = `?columns[1][search][value]=:value&order[0][column]=2&order[0][dir]=asc&start=0&length=30&search[value]`;

export const PRODUCT_TYPES = [
	{
		title: i18n.t('common:products_tabs.vod'),
		apiUrl: '/vod/newList?',
		type: 'vod'
	},
	{
		title: i18n.t('common:products_tabs.season'),
		apiUrl: '/vod/newList?',
		type: 'season'
	},
	{
		title: i18n.t('common:products_tabs.series'),
		apiUrl: '/vod/newList?',
		type: 'series'
	},
	{
		title: i18n.t('common:products_tabs.episode'),
		apiUrl: '/vod/newList?',
		type: 'episode'
	},
	{
		title: i18n.t('common:products_tabs.live'),
		apiUrl: '/channel/list?',
		type: 'channel'
	},
	{
		title: i18n.t('common:products_tabs.sections'),
		apiUrl: '/section/list?',
		type: 'sections'
	},
	{
		title: i18n.t('common:products_tabs.banners'),
		apiUrl: '/section/banners/list?',
		type: 'banners'
	},
	{
		title: i18n.t('common:products_tabs.provider'),
		apiUrl: '/vod/listProviders?',
		type: 'provider'
	},
	{
		title: i18n.t('common:products_tabs.provisioning'),
		apiUrl: 'packet/list?type=provision&',
		type: 'provisioning'
	},
	{
		title: i18n.t('common:products_tabs.collections'),
		apiUrl: 'packet/list?type=collection&',
		type: 'collections'
	},
	{
		title: i18n.t('common:products_tabs.main'),
		apiUrl: 'packet/list?type=main&',
		type: 'main'
	},
	{
		title: i18n.t('common:products_tabs.allPackets'),
		apiUrl: 'packet/list?type=main|premium&',
		type: 'main'
	},
	{
		title: i18n.t('common:products_tabs.catchup'),
		apiUrl: 'channel/:external_id/list/:date?',
		type: 'catchup'
	}
];
