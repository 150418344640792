import React from 'react';
import { string } from 'prop-types';
import useExportButton from './useExportButton';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { Button, Modal } from 'antd';

function ExportButton({ id }) {
	const { t } = useTranslation();

	const { confirm, isLoading, toggle, isOpened } = useExportButton({ id });

	return (
		<>
			<Button onClick={toggle} type="primary" htmlType="button">
				{t('barker_channels:buttons.export')}
			</Button>
			<Modal
				open={isOpened}
				onOk={confirm}
				onCancel={toggle}
				width={900}
				okText={t('common:buttons.submit')}
				cancelText={t('common:buttons.cancel')}
				title={t('common:warning')}
				confirmLoading={isLoading}
			>
				<p className="m-lg" style={{ fontSize: '1.8rem' }}>
					{t('messages:modal.heavy_resources')}
				</p>
			</Modal>
		</>
	);
}

ExportButton.propTypes = {
	id: string.isRequired
};

export default ExportButton;
