import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import shaka from 'shaka-player/dist/shaka-player.ui.js';
import axios from 'axios';

// Import helpers
import { getUserAgent } from 'components/helpers/common';
import { getPlayerConfiguration, UI_CONFIG } from '../helpers';

const useShakaPlayer = ({ playlistUrl, videoFormat }) => {
	const videoRef = useRef(null);
	const containerRef = useRef(null);
	const playerRef = useRef(null);
	const { id } = useParams();
	const [error, setError] = useState(null);

	const handleAddTextTrack = () => {
		const player = playerRef.current;
		data.playlist.subtitles.forEach(({ language: lng, url }) => {
			player.addTextTrackAsync(url, lng, 'subtitle', 'text/vtt', '', lng);
		});
	};

	const { data, isSuccess, remove } = useQuery({
		queryKey: ['playlist', id],
		queryFn: async () => {
			const { data } = await axios(playlistUrl);
			const certificate = await fetch(data.drm?.FAIRPLAY?.cert)
				.then((res) => res.arrayBuffer())
				.catch(() => undefined);

			return { playlist: data, certificate };
		},
		enabled: Boolean(playlistUrl)
	});

	useEffect(() => {
		if (shaka.Player.isBrowserSupported() && isSuccess) {
			const { playlist, certificate } = data;

			// Link to manifest
			const manifestUri = playlist.sources?.[videoFormat]?.[0]?.src;

			// Initialize shaka player
			playerRef.current = new shaka.Player(videoRef.current);

			// Setting up shaka player UI
			const ui = new shaka.ui.Overlay(
				playerRef.current,
				containerRef.current,
				videoRef.current
			);

			ui.configure(UI_CONFIG);
			ui.getControls();

			// player configure
			const playerConfig = getPlayerConfiguration(playlist.drm, certificate);
			playerRef.current.configure(playerConfig);

			// Try to asynchronous manifest load
			playerRef.current
				.load(manifestUri)
				.then(() => {
					const { isSafari } = getUserAgent();
					!isSafari && handleAddTextTrack();
					videoRef.current.play();
				})
				.catch(setError);
		}

		// eslint-disable-next-line
	}, [isSuccess]);

	useEffect(() => {
		shaka.polyfill.installAll();
		shaka.polyfill.PatchedMediaKeysApple.install();

		return () => {
			// eslint-disable-next-line
			playerRef.current?.destroy();
			remove();
		};
		// eslint-disable-next-line
	}, []);

	return { videoRef, containerRef, error, data, isSuccess };
};

export default useShakaPlayer;
