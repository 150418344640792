import React from 'react';
// Import router
import { Route, Switch, Redirect } from 'react-router-dom';

// Import components
import SignIn from './sign_in/SignIn';
import ForgotPassword from './forgot_password/ForgotPassword';
import ResetPassword from './reset_password/ResetPassword';
import NotFound from '../layouts/NotFound';

const LoginPage = () => (
	<Switch>
		<Redirect exact from="/login" to="/login/signin" />
		<Route path="/login/signin" component={SignIn} />
		<Route path="/login/forgot_password" component={ForgotPassword} />
		<Route path="/login/password/reset/:hash" component={ResetPassword} />
		<Route component={NotFound} />
	</Switch>
);

export default LoginPage;
