import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setBarkerChannelsQuery,
	fetchBarkerChannels,
	deleteBarkerChannel,
	createFiltersSuggestions
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './barker_channels_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const BarkerChannels = ({
	error,
	setBarkerChannelsQuery,
	fetchBarkerChannels,
	deleteBarkerChannel,
	resources
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	return (
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="barker_channels"
			addLinkText={t('barker_channels:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchBarkerChannels}
			deleteItemAction={deleteBarkerChannel}
			notificationDeleteSuccessTxt={t(
				'barker_channels:notification.delete_success'
			)}
			notificationDeleteErrorTxt={t(
				'barker_channels:notification.delete_error'
			)}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setBarkerChannelsQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

BarkerChannels.propTypes = {
	error: PropTypes.bool.isRequired,
	setBarkerChannelsQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchBarkerChannels: PropTypes.func.isRequired,
	deleteBarkerChannel: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	filters: PropTypes.object
};

const mapStateToProps = ({ barker_channels }) => {
	return {
		error: barker_channels.table.error,
		resources: barker_channels.table
	};
};

export default connect(mapStateToProps, {
	setBarkerChannelsQuery,
	fetchBarkerChannels,
	deleteBarkerChannel,
	createFiltersSuggestions
})(BarkerChannels);
