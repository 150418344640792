import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import components
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('barker_channels:table_columns.active'),
		accessor: 'active',
		width: 75,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { active }
			}
		}) => <ActiveIcon active={active} />
	},
	{
		Header: i18n.t('barker_channels:table_columns.uuid'),
		accessor: 'uuid',
		sortable: false,
		Cell: ({
			row: {
				_original: { uuid }
			}
		}) => (
			<Link to={`/panel/barker_channels/edit/${uuid}/metadata`}>{uuid}</Link>
		)
	},
	{
		Header: i18n.t('barker_channels:table_columns.section'),
		sortable: false,
		Cell: ({
			row: {
				_original: { section_id, section_name }
			}
		}) => (
			<Link to={`/panel/sections/edit/${section_id}/metadata`}>
				{section_name} ({section_id})
			</Link>
		)
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { uuid }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(uuid)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
