import i18n from 'i18n';

export const BASE_FIELDS = [
	{
		name: 'type',
		type: 'select',
		label: i18n.t('tags:fields.type'),
		required: true
	},
	{
		name: 'category',
		type: 'select',
		label: i18n.t('tags:fields.category'),
		required: true
	},
	{
		name: 'name',
		type: 'text',
		label: i18n.t('tags:fields.name'),
		required: true
	},
	{
		name: 'value',
		type: 'text',
		label: i18n.t('tags:fields.value'),
		required: true
	},
	{
		name: 'order',
		type: 'number',
		label: i18n.t('tags:fields.order'),
		required: false
	}
];
