import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Table } from 'antd';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Button } from 'reactstrap';

// Import utilities
import { createColumns } from './documents_uploaded_columns';

// Import styles
import { TableWrapper, SyncButtonWrapper, StyledSyncOutlined } from './styles';

// Components
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const DocumentUploadsTable = ({
	content,
	dataSource,
	onSortEnd,
	isLoading,
	deleteAsset,
	updateTableOrder,
	disabled
}) => {
	const { t } = useTranslation();

	const DraggableContainer = (props) => (
		<SortableBody
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={onSortEnd}
			{...props}
		/>
	);

	const DraggableBodyRow = ({ className, style, ...restProps }) => {
		const index = dataSource.findIndex(
			(x) => x.index === restProps['data-row-key']
		);
		return <SortableItem index={index} {...restProps} disabled={isLoading} />;
	};

	const components = {
		body: {
			wrapper: DraggableContainer,
			row: DraggableBodyRow
		}
	};
	const columns = createColumns({
		deleteAsset,
		metadata: { content }
	});

	return (
		<TableWrapper disabled={disabled}>
			<SyncButtonWrapper>
				<Button
					className="bg-primary m-r-sm"
					disabled={disabled}
					onClick={updateTableOrder}
				>
					<div className="d-flex">
						<StyledSyncOutlined spin={isLoading} />
						{t('documents:buttons.sync')}
					</div>
				</Button>
			</SyncButtonWrapper>
			<Table
				rowKey="index"
				pagination={false}
				dataSource={dataSource}
				columns={columns}
				components={components}
			/>
		</TableWrapper>
	);
};

DocumentUploadsTable.propTypes = {
	content: PropTypes.string,
	dataSource: PropTypes.array,
	onSortEnd: PropTypes.func,
	isLoading: PropTypes.bool,
	deleteAsset: PropTypes.func,
	updateTableOrder: PropTypes.func,
	disabled: PropTypes.bool
};

export default DocumentUploadsTable;
