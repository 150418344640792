import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH VOD MATERIALS ********************
export const fetchVodMaterials = (uuid) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_VOD_MATERIALS_LOADING,
			payload: true
		});

		const response = await services.get(
			`/player/${uuid}/list?with_assets=true`
		);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_VOD_MATERIALS_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_VOD_MATERIALS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
// ******************** UPDATE / DELETE VOD MATERIALS ********************
export const updateVodMaterials = (data, type, productId) => ({
	type: types.UPDATE_VOD_MATERIALS,
	payload: { data, type, productId }
});

export const updateVodMaterialsError = (error) => ({
	type: types.UPDATE_VOD_MATERIALS_ERROR,
	payload: error
});

export const deleteVodMaterials = (id, type) => ({
	type: types.DELETE_VOD_MATERIALS,
	payload: { id, type }
});

export const deleteVodMaterialsError = (error) => ({
	type: types.DELETE_VOD_MATERIALS_ERROR,
	payload: error
});

// ******************** ADD VIDEO TO VOD ********************
export const addVideoToVod = (uuid, videoType) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.ADD_VIDEO_TO_VOD_LOADING,
			payload: true
		});

		const resources = {
			product_uuid: uuid,
			product_type: 'vod',
			video_type: videoType
		};

		const response = await services.post(`/player/video/create`, resources);

		// add empty asset array to response data
		response.data.assets = [];

		// Dispatch an action with data
		dispatch({
			type: types.ADD_VIDEO_TO_VOD_SUCCESS,
			payload: { data: response.data, videoType }
		});
	} catch (error) {
		dispatch({
			type: types.ADD_VIDEO_TO_VOD_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
