// -------------------- LOCAL_CHANNELS DATA TABLE --------------------
export const FETCH_LOCAL_CHANNELS_LOADING = 'FETCH_LOCAL_CHANNELS_LOADING';
export const FETCH_LOCAL_CHANNELS_SUCCESS = 'FETCH_LOCAL_CHANNELS_SUCCESS';
export const FETCH_LOCAL_CHANNELS_ERROR = 'FETCH_LOCAL_CHANNELS_ERROR';

// -------------------- SET SEARCH QUERY --------------------
export const SET_LOCAL_CHANNELS_SEARCH_QUERY =
	'SET_LOCAL_CHANNELS_SEARCH_QUERY';

// -------------------- DELETE LOCAL CHANNEL --------------------
export const DELETE_LOCAL_CHANNEL_LOADING = 'DELETE_LOCAL_CHANNEL_LOADING';
export const DELETE_LOCAL_CHANNEL_SUCCESS = 'DELETE_LOCAL_CHANNEL_SUCCESS';
export const DELETE_LOCAL_CHANNEL_ERROR = 'DELETE_LOCAL_CHANNEL_ERROR';
