import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';
import { useTranslation } from 'react-i18next';

/**
 * Funciton component responsible for rendering progress bar of finished and
 * remaining materials in ingester (assets | encoding)
 * @param {number} total - Total number of materials
 * @param {number} finished - Number of processed materials
 * @returns {*}
 */
const MultiProgressBar = ({ total, finished }) => {
	const { t } = useTranslation();

	// calculating remaining items
	const remaining = total - finished;

	// until data is loaded show placeholder data
	const finishedPercent = total ? Math.floor((finished / total) * 100) : 0;
	const remainingPercent = finished ? 100 - finishedPercent : 100;

	return (
		<Progress multi className="multi-progress-bar">
			<Progress bar color="success" value={finishedPercent}>
				{t('ingester:stats.finished', { finished })}
			</Progress>
			<Progress bar color="secondary" value={remainingPercent}>
				{t('ingester:stats.remaining', { remaining })}
			</Progress>
		</Progress>
	);
};

MultiProgressBar.propTypes = {
	total: PropTypes.number.isRequired,
	finished: PropTypes.number.isRequired
};

export default MultiProgressBar;
