import React from 'react';
import PropTypes from 'prop-types';

// Import hooks
import useSystem from 'hooks/useSystem';

// Import utilites
import { renderFields } from 'components/utilities/form';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({ submitting, invoiceType }) => {
	const { currentSystem } = useSystem();
	return (
		<>
			{BASE_FIELDS({ currentSystem, invoiceType }).map((item, index) =>
				renderFields({ item, index, submitting })
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
