// Import translation
import i18n from 'i18next';

export const defaultFieldValues = {
	active: false
};

export const BASE_FIELDS = [
	{
		name: 'active',
		label: i18n.t('barker_channels:form.fields.active'),
		type: 'checkbox'
	},
	{
		name: 'section_id',
		label: i18n.t('barker_channels:form.fields.section_id'),
		type: 'select',
		required: true
	}
];
