import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import redux actions
import { fetchCognitoTree, deleteCognitoCategory } from 'store/actions';

// Import hooks
import useSelectSystem from 'components/layouts/top_header/SelectSystem/useSelectSystem';

// Import components
import { Menu } from 'antd';
import {
	Wraper,
	Ibox,
	IboxTitle,
	IboxTools,
	IboxContentBase
} from 'components/common/layout';
import MenuItemButtons from './MenuItemButtons';
import Loader from 'components/common/loaders/Loader';

const Cognito = ({ fetchCognitoTree, deleteCognitoCategory, resources }) => {
	const { t } = useTranslation();

	const [selectedKeys, setSelectedKeys] = useState(null);
	const [previousScrollPosition, setPreviousScrollPosition] = useState(null);

	const { currentSystem } = useSelectSystem();

	useEffect(() => {
		fetchCognitoTree();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (selectedKeys) {
			window.scroll({
				top: previousScrollPosition || 0,
				behavior: 'auto'
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resources]);

	const handleOpenChange = (openedKeys) => setSelectedKeys(openedKeys);

	// Build tree with recursion
	const getItems = (menuData) => {
		if (menuData && menuData.length > 0) {
			return menuData.map(
				({ id, Name, ChildCategories, section_id, Ordinal: ordinal }) => {
					const label = (
						<div className="d-flex justify-content-start subMenu">
							<div>
								{ChildCategories?.Category.length ? (
									<i className="fa fa-folder m-r-sm" aria-hidden="true"></i>
								) : (
									<i className="fa fa-circle-o m-r-sm" aria-hidden="true"></i>
								)}
								{Name}
							</div>

							<MenuItemButtons
								id={id}
								deleteCognitoCategory={deleteCognitoCategory}
								resources={resources}
								sectionId={section_id}
								ordinal={ordinal}
								selectedKeys={selectedKeys}
								fetchCognitoTree={fetchCognitoTree}
								setPreviousScrollPosition={setPreviousScrollPosition}
								lastChildCategory={menuData.length - 1}
								system={currentSystem}
							/>
						</div>
					);
					return {
						label,
						key: id,
						children: getItems(ChildCategories?.Category)
					};
				}
			);
		}
		return null;
	};

	const { error, data, loading } = resources;
	const initialKeys = selectedKeys || [resources?.data[0]?.id];

	return (
		<>
			{!loading ? (
				<Wraper lg="12" error={error}>
					<Ibox className="m-b-lg">
						<IboxTitle title={t('nav:name.cognito')}>
							<IboxTools>
								<Link
									className="btn__link"
									to={'/panel/menu_stb/create/metadata'}
								>
									{t('menu_stb:buttons.add')}
								</Link>
							</IboxTools>
						</IboxTitle>
						<IboxContentBase>
							<Menu
								mode="inline"
								inlineIndent="36"
								defaultOpenKeys={initialKeys} // to opening first submenu on start
								onOpenChange={handleOpenChange}
								items={getItems(data)}
							/>
						</IboxContentBase>
					</Ibox>
				</Wraper>
			) : (
				<div className="text-center">
					<Loader isLoaded={!loading} />
				</div>
			)}
		</>
	);
};

Cognito.propTypes = {
	fetchCognitoTree: PropTypes.func.isRequired,
	deleteCognitoCategory: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired
};

const mapStateToProps = ({ cognito: { tree } }) => {
	return {
		resources: tree
	};
};

export default connect(mapStateToProps, {
	fetchCognitoTree,
	deleteCognitoCategory
})(Cognito);
