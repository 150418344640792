import React from 'react';
import PropTypes from 'prop-types';

function FieldInfo({ info }) {
	if (!info) return null;
	return (
		<span className="input-info">
			<i className="fa fa-info-circle m-r-xs text-primary"></i>
			<span className="text-muted">{info}</span>
		</span>
	);
}

FieldInfo.defaultProps = {
	info: null
};

FieldInfo.propTypes = {
	info: PropTypes.string
};

export default FieldInfo;
