import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Import api
import { CHECK_IP_API, basicAuth } from 'components/helpers/api';

export default createApi({
	reducerPath: 'ip_verification',
	baseQuery: fetchBaseQuery({
		baseUrl: CHECK_IP_API,
		prepareHeaders: (headers) => {
			headers.set('Authorization', `Basic ${basicAuth}`);
			return headers;
		}
	}),
	endpoints: (builder) => ({
		verification: builder.query({
			query: ({ ip_address }) => ip_address
		})
	})
});
