import React from 'react';
import { NavLink } from 'react-router-dom';

const Links = ({
	tabs,
	url,
	isMaterialTabVisible,
	isEncodingTabVisible,
	isPaymentsTabVisible,
	isTrackingTabVisible
}) => {
	const isCreate = url.includes('create');

	const renderNavLink = () =>
		tabs.map(
			(
				{ path, name, disableOnCreate, isDisabled, className = '', state = {} },
				index
			) => {
				if (!isMaterialTabVisible && path === 'materials') {
					return null;
				} else if (!isEncodingTabVisible && path === 'encoding') {
					return null;
				} else if (!isPaymentsTabVisible && path === 'payments') {
					return null;
				} else if (!isTrackingTabVisible && path === 'tracking') {
					return null;
				} else if (isDisabled) {
					return null;
				} else {
					return (
						(!isCreate || !disableOnCreate) && (
							<NavLink
								key={`${name}-${index}`}
								to={{ pathname: `${url}/${path}`, state }}
								className={className}
							>
								{name}
							</NavLink>
						)
					);
				}
			}
		);

	return <nav className="links-list">{renderNavLink()}</nav>;
};

export default Links;
