import React from 'react';
import i18n from 'i18next';

// Import helpers
import { setFormLink } from 'components/helpers/link_selector';

// Import components
import DeleteButton from 'components/utilities/delete_button/DeleteButton';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('channels:table_columns.logo_url'),
		accessor: 'images',
		sortable: false,
		width: 100,
		Cell: ({
			row: {
				_original: { uuid, image, subtype }
			}
		}) => (
			<React.Fragment>
				{image ? setFormLink({ uuid, type: subtype, image }) : <span>---</span>}
			</React.Fragment>
		)
	},
	{
		Header: i18n.t('vod:table_columns.title'),
		accessor: 'title',
		Cell: ({
			row: {
				_original: { uuid, subtype, title, status }
			}
		}) => setFormLink({ uuid, type: subtype, title, status })
	},
	{
		Header: i18n.t('vod:table_columns.provider'),
		accessor: 'provider',
		width: 150
	},
	{
		Header: i18n.t('vod:table_columns.rating'),
		accessor: 'rating',
		width: 110
	},
	{
		Header: i18n.t('vod:table_columns.genres'),
		accessor: 'genres',
		sortable: false,
		width: 160,
		Cell: ({
			row: {
				_original: { genres }
			}
		}) => genres?.map((genre) => genre.name).join(', ') ?? '---'
	},
	{
		Header: i18n.t('vod:table_columns.payment_models'),
		accessor: 'payment_models',
		sortable: false,
		width: 160,
		Cell: ({
			row: {
				_original: { payment_models }
			}
		}) => payment_models?.join(', ') ?? '---'
	},
	{
		Header: i18n.t('vod:table_columns.status'),
		accessor: 'status',
		width: 150,
		Cell: ({
			row: {
				_original: { status }
			}
		}) => status && i18n.t([`vod:select:status.${status}`, status])
	},
	{
		Header: i18n.t('vod:table_columns.sub_status'),
		accessor: 'sub_status',
		width: 150,
		Cell: ({
			row: {
				_original: { sub_status }
			}
		}) => sub_status && i18n.t([`vod:select:status.${sub_status}`, sub_status])
	},
	{
		Header: i18n.t('vod:table_columns.subtype'),
		accessor: 'subtype',
		width: 100,
		Cell: ({
			row: {
				_original: { subtype }
			}
		}) => subtype && i18n.t([`vod:select:subtypes.${subtype}`, subtype])
	},
	{
		Header: i18n.t('vod:table_columns.updated_at'),
		accessor: 'updated_at'
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { uuid, subtype, status, system_id }
			}
		}) =>
			status !== 'deleted' && (
				<DeleteButton
					id={uuid}
					isReadOnly={isReadOnly}
					productSystem={system_id}
					additionalResources={{ type: subtype }}
					onSelectItem={handleSelectItem}
				/>
			)
	}
];

export const selectOptionColumns = [
	{
		header: i18n.t('common:search_box.all_columns'),
		name: ''
	},
	{
		header: i18n.t('vod:table_columns.title'),
		name: 'title'
	},
	{
		header: i18n.t('vod:table_columns.provider'),
		name: 'provider'
	},
	{
		header: i18n.t('vod:table_columns.rating'),
		name: 'rating'
	},
	{
		header: i18n.t('vod:table_columns.genres'),
		name: 'genres'
	},
	{
		header: i18n.t('vod:table_columns.status'),
		name: 'status'
	},
	{
		header: i18n.t('vod:table_columns.subtype'),
		name: 'subtype'
	}
];
