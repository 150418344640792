import React from 'react';
import { Breadcrumb, Skeleton } from 'antd';
import { Link } from 'react-router-dom';

// Import hooks
import useCognitoBreadcrumbs from './useCognitoBreadcrumb';

// Import helpers
import { BREADCRUMBS_SEPARATOR } from './helpers';

const CogntitoBreadcrumbs = () => {
	const { catalogs, loading } = useCognitoBreadcrumbs();

	return (
		<>
			{!loading ? (
				<Breadcrumb separator={BREADCRUMBS_SEPARATOR} className="ml-4">
					{catalogs.map(({ name, uuid }) => (
						<Breadcrumb.Item key={uuid}>
							<Link to={`/panel/menu_stb/edit/${uuid}/metadata`}>{name}</Link>
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
			) : (
				<div style={{ width: '400px' }}>
					<Skeleton
						active
						title
						paragraph={false}
						className="tabs-breadcrumbs__skeleton"
					/>
				</div>
			)}
		</>
	);
};

export default CogntitoBreadcrumbs;
