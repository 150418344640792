// Import translation
import i18n from 'i18next';

// Import variables
import { REGEX_VALIDATION_EMAIL } from 'components/helpers/validation_helpers';

export default ({ name, email }) => {
	const errors = {};

	const isEmailValid = REGEX_VALIDATION_EMAIL.test(email);

	// ------------------ name ------------------
	if (!name) {
		errors.name = i18n.t('affiliate_partners:validation.name');
	}

	// ------------------ email ------------------
	if (!email) {
		errors.email = i18n.t('affiliate_partners:validation.email');
	} else if (!isEmailValid) {
		errors.email = i18n.t('affiliate_partners:validation.email_wrong');
	}

	return errors;
};
