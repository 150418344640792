import styled from 'styled-components';
import { Card } from 'antd';

export const StyledCard = styled(Card)`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	z-index: 10;
`;

export const GridItemCover = styled.img`
	object-fit: contain;
	width: 100%;
	aspect-ratio: 16/9;
`;
