import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { fetchProviders, fetchVideoAssetsParam } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import helpers
import { convertProvidersToValuePairs } from 'store/actions/helpers_actions/convert_helpers';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

const VideoAssetsParamsForm = ({
	history,
	fetchVideoAssetsParam,
	fetchProviders,
	error,
	isLoaded,
	initialValues,
	providers
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		fetchProviders();
		// eslint-disable-next-line
	}, []);

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) =>
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: 'player/product/video/asset/params',
			redirect: true,
			notificationName: 'video-assets-params'
		});

	// converted providers data for fields combobox ({name: provider, value: provider_id})
	const convertedProviders = convertProvidersToValuePairs(providers);

	const formInitialValues = { ...defaultFieldValues, ...initialValues };

	return (
		// Dispatch fetchVideoAssetsParam actions
		<FormTemplate
			fetchResourceToEdit={fetchVideoAssetsParam}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={formInitialValues}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					validate={validate}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								providersIds={convertedProviders}
							/>

							<FormButtons
								isButtonDisabled={submitting || error}
								path="ingester/video-assets-params"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

VideoAssetsParamsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchVideoAssetsParam: PropTypes.func.isRequired,
	fetchProviders: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	providers: PropTypes.array.isRequired
};

const mapStateToProps = ({
	ingester: {
		video_assets_params: { form }
	},
	providers
}) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded,
		providers: providers.table.data
	};
};

export default connect(mapStateToProps, {
	fetchVideoAssetsParam,
	fetchProviders
})(VideoAssetsParamsForm);
