// ******************** DOCUMENTS DATA TABLE ********************
export const FETCH_DOCUMENTS_LOADING = 'FETCH_DOCUMENTS_LOADING';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_ERROR = 'FETCH_DOCUMENTS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_DOCUMENTS_SEARCH_QUERY = 'SET_DOCUMENTS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_DOCUMENTS_TABLE_SORTING = 'SAVE_DOCUMENTS_TABLE_SORTING';

// ******************** DELETE DOCUMENT ********************
export const DELETE_DOCUMENT_LOADING = 'DELETE_DOCUMENT_LOADING';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR';

// ******************** DOCUMENT ********************
export const FETCH_DOCUMENT_LOADING = 'FETCH_DOCUMENT_LOADING';
export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS';
export const FETCH_DOCUMENT_ERROR = 'FETCH_DOCUMENT_ERROR';

// ******************** FETCH DOCUMENT UPLOADED FILES ********************
export const FETCH_UPLOADED_FILES_LOADING = 'FETCH_UPLOADED_FILES_LOADING';
export const FETCH_UPLOADED_FILES_SUCCESS = 'FETCH_UPLOADED_FILES_SUCCESS';
export const FETCH_UPLOADED_FILES_ERROR = 'FETCH_UPLOADED_FILES_ERROR';

// ******************** UPDATE DOCUMENT UPLOADED FILES ********************
export const UPDATE_UPLOADED_FILES_LOADING = 'UPDATE_UPLOADED_FILES_LOADING';
export const UPDATE_UPLOADED_FILES_SUCCESS = 'UPDATE_UPLOADED_FILES_SUCCESS';
export const UPDATE_UPLOADED_FILES_ERROR = 'UPDATE_UPLOADED_FILES_ERROR';
