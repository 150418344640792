import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Import components
import { Button } from 'reactstrap';

// Import translation
import i18n from 'i18next';

const ResetPasswordContent = ({
	contentText,
	isSuccess,
	linkPath = '/login/signin',
	buttonText = 'back_to_signin',
	history
}) => {
	const handleOnClick = () => history.push(linkPath);
	return (
		<div>
			{contentText && (
				<p
					className={`reset-password__content-text reset-password--new-password-${
						isSuccess ? 'success' : 'error'
					}`}
				>
					{contentText}
				</p>
			)}

			<Button onClick={handleOnClick}>
				{i18n.t(`login:reset_password.buttons.${buttonText}`)}
			</Button>
		</div>
	);
};

ResetPasswordContent.propTypes = {
	contentText: PropTypes.string,
	isSuccess: PropTypes.bool.isRequired,
	linkPath: PropTypes.string.isRequired,
	buttonText: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(ResetPasswordContent);
