import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	edit: {},
	codes: [],
	isLoaded: true,
	error: false,
	deleteCodes: {
		success: false,
		error: false
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** FETCH AFFILIATE_PARTNER ******************
			case types.FETCH_AFFILIATE_PARTNER_LOADING:
				return { ...INITIAL_STATE, isLoaded: false };

			case types.FETCH_AFFILIATE_PARTNER_SUCCESS:
				draft.edit = action.payload;
				draft.isLoaded = true;
				return;

			case types.FETCH_AFFILIATE_PARTNER_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			// ****************** FETCH AFFILIATE_PARTNER_CODES ******************
			case types.FETCH_AFFILIATE_PARTNER_CODES_LOADING:
				return { ...INITIAL_STATE };

			case types.FETCH_AFFILIATE_PARTNER_CODES_SUCCESS:
				draft.codes = action.payload.data;
				draft.isLoaded = true;
				return;

			case types.FETCH_AFFILIATE_PARTNER_CODES_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			// ****************** DELETE AFFILIATE_PARTNER_CODES ******************
			case types.DELETE_AFFILIATE_PARTNER_CODES_LOADING:
				draft.deleteCodes.success = false;
				draft.deleteCodes.error = false;
				return;

			case types.DELETE_AFFILIATE_PARTNER_CODES_SUCCESS:
				draft.deleteCodes.success = action.payload;
				draft.deleteCodes.error = false;
				return;

			case types.DELETE_AFFILIATE_PARTNER_CODES_ERROR:
				draft.deleteCodes.error = action.payload;
				return;

			// ****************** CLEAR STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// ****************** DEFAULT ******************
			default:
				return state;
		}
	});
