// Import roles
import { allRolesGrouped } from 'components/helpers/roles_variables';

// Import nav links
import NAV_LINKS from 'components/layouts/nav/helpers/nav_links';

export const generateRedirectPath = (userRoles) => {
	// default path
	let generatedPath = '/panel/statistics';

	if (userRoles) {
		// array of roles group names
		const rolesGroupedNames = Object.keys(allRolesGrouped);
		// array of roles group names that user belong to based on its roles sorted by navigation order (same order like in dashboard nav menu)
		const userRolesGroups = Object.entries(allRolesGrouped)
			.map((rolesGroup) => {
				// check if userRoles are in rolesGroup (roles are grouped by type in role_variables.js)
				// if true return role group name
				if (userRoles.some((role) => rolesGroup[1].includes(role))) {
					return rolesGroup[0];
				} else return null;
			})
			// filter null values
			.filter((group) => group !== null)
			// sort by nav menu order (set in allRolesGroup in roles_variables)
			.sort(
				(a, b) => rolesGroupedNames.indexOf(a) - rolesGroupedNames.indexOf(b)
			);

		// index of first nav link that match first role group name of user
		const navIndex = NAV_LINKS.findIndex(
			(link) => link.rolesGroupName === userRolesGroups[0]
		);

		// if there is no nav link has assigned proper group name return default path
		if (navIndex === -1) return generatedPath;

		// return proper path defined in NAV_LINKS or don't change default path
		if (NAV_LINKS[navIndex].path) {
			generatedPath = NAV_LINKS[navIndex].path;
		}
	}

	return generatedPath;
};
