// Import variables
import { CORE_PATHS } from 'components/helpers/variables';

const { AVAILABILITY, PRICES, COUPONS, SYSTEM_SETTINGS } = CORE_PATHS;

export const checkIsAvailability = (pathname) =>
	pathname.includes(AVAILABILITY);

export const checkIsPrices = (pathname) => pathname.includes(PRICES);

export const checkIsCouponsSystemSettings = (pathname) =>
	pathname.includes(COUPONS) && pathname.includes(SYSTEM_SETTINGS);

// check if we are in epg location (needed for rendering program name in epg tabs)
export const checkForEpg = (pathname) => {
	const pathnameArray = pathname.split('/');
	const isEpg = pathnameArray[4] === 'epg';
	return isEpg;
};
