// Import translation
import i18n from 'i18next';

export default ({ parentId }) => {
	const errors = {};

	// ------------------ parentId ------------------
	if (!parentId) {
		errors.parentId = i18n.t('categories:validation.parent_id_required');
	}

	return errors;
};
