import services from 'services/services';
import * as types from '../types';

// *************** FETCH PROVIDERS NAMES BASED ON PROVIDER ID ***************
export const fetchProviderNamesById = (providerId) => async (dispatch) => {
	try {
		dispatch({
			type: types.CLEAR_PROVIDERS_NAMES_BY_ID
		});

		const url = `/vod/listProviders?provider_id=${providerId}`;
		const { data } = await services.get(url);

		dispatch({
			type: types.FETCH_PROVIDERS_NAMES_BY_ID,
			payload: data
		});
	} catch (error) {}
};
