import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';

// Import redux actions
import { fetchSubscriberPackets } from 'store/actions';

// Import components
import { Button } from 'reactstrap';
import Fields from './fields/Fields';
import { defaultFieldValues } from './fields/input_fields';

// Import helpers
import { addSubscriberPacket } from '../helpers';

// Import validations
import validate from './validation';

const SubscribersPacketsForm = ({ toggleFormModal }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { id } = useParams();

	const handleOnSubmit = async (values) => {
		await addSubscriberPacket({ values, id, toggleFormModal });
		fetchSubscriberPackets(null, id)(dispatch);
	};

	const { provisioning } = useSelector(({ dictionary }) => dictionary);

	const formInitialValues = {
		...defaultFieldValues,
		packet_uuid: provisioning?.length ? provisioning[0]?.value : ''
	};

	return (
		<Form
			onSubmit={handleOnSubmit}
			validate={validate}
			validateOnBlur
			initialValues={formInitialValues}
			render={({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit}>
					<Fields submitting={submitting} />
					<div className="d-flex justify-content-end mt-4">
						<Button type="submit" disabled={submitting}>
							{t('common:buttons.submit')}
						</Button>
					</div>
				</form>
			)}
		/>
	);
};

SubscribersPacketsForm.propTypes = {
	toggleFormModal: PropTypes.func.isRequired
};

export default SubscribersPacketsForm;
