import React from 'react';

// Import components
import AvailabilityFormTemplate from './AvailabilityFormTemplate';
import SystemSettingsForm from 'components/utilities/system_settings/SystemSettingsForm';

const AvailabilitySettings = () => {
	return (
		<>
			<AvailabilityFormTemplate />
			<SystemSettingsForm />
		</>
	);
};

export default AvailabilitySettings;
