import i18n from 'i18next';
import { selectFilterOption } from './helpers';

export const defaultFieldValues = {
	genres: [],
	slides: '',
	promoted: false,
	active: false,
	subscriber_local_limitation: false,
	audio_only: false,
	adult: false,
	catch_up_active: false,
	npvr_active: false,
	npvr_source_id: null,
	ccur_recording_active: false
};

export const BASE_FIELDS = [
	{
		name: 'external_uid',
		label: i18n.t('channels:form:fields.external_uid'),
		type: 'text',
		required: true
	},
	{
		name: 'external_stb_id',
		label: i18n.t('channels:form:fields.external_stb_id'),
		type: 'text',
		required: false
	},
	{
		name: 'title',
		label: i18n.t('channels:form:fields.title'),
		type: 'text',
		required: true
	},
	{
		name: 'alternate_names',
		label: i18n.t('channels:form:fields.alternate_names'),
		fieldInfo: i18n.t('channels:form:fields_info.alternate_names'),
		type: 'text',
		required: false
	},
	{
		name: 'description',
		label: i18n.t('channels:form:fields.description'),
		type: 'textarea',
		required: false
	},
	{
		name: 'genres',
		label: i18n.t('channels:form:fields.genres'),
		type: 'multi-select',
		required: false
	},
	{
		name: 'slides',
		label: i18n.t('channels:form:fields.slides'),
		type: 'text',
		required: false
	},
	{
		name: 'deep_link',
		label: i18n.t('channels:form:fields.deep_link'),
		type: 'text',
		required: false
	},
	{
		name: 'cities',
		label: i18n.t('channels:form:fields.cities'),
		type: 'text',
		fieldInfo: i18n.t('channels:form:fields_info.cities'),
		required: false
	},
	{
		name: 'subdivisions',
		label: i18n.t('channels:form:fields.subdivisions'),
		type: 'custom-names',
		required: false,
		mode: 'multiple',
		filterOption: selectFilterOption
	},
	{
		name: 'is_regional',
		label: i18n.t('channels:form:fields.local_channel'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'audio_only',
		label: i18n.t('channels:form:fields.audio_only'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'adult',
		label: i18n.t('channels:form:fields.adult'),
		type: 'checkbox',
		required: false
	}
];

export const CCUR_FIELDS = [
	{
		name: 'ccur_recording_source_uri',
		label: i18n.t('channels:form:fields.ccur_recording_source_uri'),
		type: 'text',
		required: false
	},
	{
		name: 'sid',
		label: i18n.t('channels:form:fields.sid'),
		type: 'text',
		required: false
	},
	{
		name: 'ccur_recording_bitrate',
		label: i18n.t('channels:form:fields.ccur_recording_bitrate'),
		type: 'text',
		required: false
	},
	{
		name: 'ccur_recording_active',
		label: i18n.t('channels:form:fields.ccur_recording_active'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'ccur_recording_time',
		label: i18n.t('channels:form:fields.ccur_recording_time'),
		type: 'number',
		required: false
	}
];

export const ATENDE_FIELDS = [
	{
		name: 'atende_id',
		label: i18n.t('channels:form:fields.atende_id'),
		type: 'number',
		required: false
	},
	{
		name: 'catch_up_active',
		label: i18n.t('channels:form:fields.catch_up_active'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'catch_up_time',
		label: i18n.t('channels:form:fields.catch_up_time'),
		type: 'number',
		required: false
	},
	{
		name: 'npvr_active',
		label: i18n.t('channels:form:fields.npvr_active'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'npvr_max_depth',
		label: i18n.t('channels:form:fields.npvr_max_depth'),
		type: 'number',
		required: false
	}
];

export const ATEME_FIELDS = ({ isNpvrActive }) => [
	{
		name: 'npvr_source_id',
		label: i18n.t('channels:form:fields.npvr_source_id'),
		type: 'text',
		required: isNpvrActive
	}
];
