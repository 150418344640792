import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH BANNER COVER FILES TO EDIT ********************
export const fetchBannerCover = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_BANNER_COVER_FILES_LOADING,
			payload: true
		});

		const response = await services.get(
			`/assets/${id}/list?model_type=banner&collection=cover`
		);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_BANNER_COVER_FILES_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_BANNER_COVER_FILES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** FETCH BANNER COVER SMALL FILES TO EDIT ********************
export const fetchBannerCoverSmall = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_BANNER_COVER_SMALL_FILES_LOADING,
			payload: true
		});

		const response = await services.get(
			`/assets/${id}/list?model_type=banner&collection=cover_small`
		);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_BANNER_COVER_SMALL_FILES_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_BANNER_COVER_SMALL_FILES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
