import React from 'react';
import PropTypes from 'prop-types';

// Import select options
import { select_promotion_types } from './select_type_options';

// Import utilities
import { renderFields } from 'components/utilities/form';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({ submitting, currentValues, isEdit }) => {
	// apply select values to proper select fields and theirs comboboxes
	const selectResources = {
		promotion_type: select_promotion_types
	};

	return (
		<React.Fragment>
			{BASE_FIELDS({ isEdit, currentValues }).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	currentValues: PropTypes.object.isRequired,
	isEdit: PropTypes.bool.isRequired
};

export default Fields;
